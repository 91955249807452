import { useState, useCallback, useEffect } from 'react';
import {
  InteractiveFeedbackCardProps,
  InteractiveFeedbackCardType,
} from '../components/InteractiveFeedbackCard';
import { InteractiveFeedbackMenuProps } from '../components/InteractiveFeedbackMenu';
import {
  GetMyStrengthScoresDetails,
  GetMyTeamStrengthsScoresDetails,
  getStrengthScores,
  getTeamStrengthsScores,
} from '../InteractiveFeedbackApi';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';
import IdeaImage from 'assets/apply.clevry/IF_MyStrengths_StrengthDesc_02.jpg';
import ThoughtImage from 'assets/apply.clevry/IF_MyStrengths_StrengthDesc_04.jpg';
import ActionImage from 'assets/apply.clevry/IF_MyStrengths_StrengthDesc_01.jpg';
import InfluenceImage from 'assets/apply.clevry/IF_MyStrengths_StrengthDesc_05.jpg';
import VisionaryImage from 'assets/apply.clevry/IF_MyTeamStrengths_StrengthDesc_01.jpg';
import ExplorerImage from 'assets/apply.clevry/IF_MyTeamStrengths_StrengthDesc_02.jpg';
import PerfectorImage from 'assets/apply.clevry/IF_MyTeamStrengths_StrengthDesc_03.jpg';
import PragmatistImage from 'assets/apply.clevry/IF_MyTeamStrengths_StrengthDesc_04.jpg';
import ProducerImage from 'assets/apply.clevry/IF_MyTeamStrengths_StrengthDesc_05.jpg';
import CatalystImage from 'assets/apply.clevry/IF_MyTeamStrengths_StrengthDesc_06.jpg';
import HarmoniserImage from 'assets/apply.clevry/IF_MyTeamStrengths_StrengthDesc_07.jpg';
import GuideImage from 'assets/apply.clevry/IF_MyTeamStrengths_StrengthDesc_08.jpg';
import { useSelector } from 'react-redux';
import { selectDashboardInfo } from 'reducers/dashboardInfo';
import { selectAuthStatus, selectRedirectValues } from 'reducers/auth';
import { selectAssessmentId } from 'reducers/virtualAssessments';

export type MyTeamStrengthsCardInnerOptions = Pick<
  InteractiveFeedbackCardProps,
  'bottomColor' | 'cardHTML' | 'heading' | 'type' | 'noButtons'
> & { topImage?: string };

export type MyTeamStrengthsCardOptions = {
  teamStrengthsCards: MyTeamStrengthsCardInnerOptions[];
};
export type MyTeamStrengthsGraphCardOptions = {
  teamStrengthsGraphCards: MyTeamStrengthsCardInnerOptions[];
};

export type ReportDownloadFlags = {
  isReportDownloadable?: boolean;
};

export const useGetMyTeamStrengthsDetails = () => {
  const { t } = useTranslation('Interactive+Feedback', { i18n });
  const [data, setData] = useState<{
    teamStrengthsMenuCards: MyTeamStrengthsCardOptions;
    teamStrengthsGraphCards: MyTeamStrengthsGraphCardOptions;
    teamStrengthsWheelImage: string;
    reportCode: string;
    isCandidateReport: boolean;
    reportDownloadFlags: ReportDownloadFlags;
  } | null>(null);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState(false);
  const redirectValues = useSelector(selectRedirectValues);
  const dashboardInfo = useSelector(selectDashboardInfo);
  const myHistoryRouteAssessmentId = useSelector(selectAssessmentId);
  const authStatus = useSelector(selectAuthStatus);

  let cultureCode: string | null = null;
  if (dashboardInfo && dashboardInfo.cultureCode) {
    cultureCode = dashboardInfo.cultureCode;
  }

  let assessmentId: number | null = null;
  if (redirectValues && redirectValues.length > 0) {
    assessmentId = redirectValues.find(
      ({ label, value }) => label === 'AssessmentID'
    )?.value;
  } else if (myHistoryRouteAssessmentId) {
    assessmentId = myHistoryRouteAssessmentId;
  }

  const parseStrengthMenuCardOptions = () => {
    const teamStrengthsCards = [
      {
        type: InteractiveFeedbackCardType.IMAGE,
        heading: t('SSCandidate_Interactive_Feedback_Idea'),
        color: '#31C3EC',
        borderColor: '#F18229',
        bottomColor: '#F18229',
        topImage: IdeaImage,
        cardHTML: t('SSCandidate_Interactive_Feedback_Idea_Description'),
      },
      {
        type: InteractiveFeedbackCardType.IMAGE,
        heading: t('SSCandidate_Interactive_Feedback_Thought'),
        color: '#31C3EC',
        borderColor: '#3CC1EA',
        bottomColor: '#3CC1EA',
        topImage: ThoughtImage,
        cardHTML: t('SSCandidate_Interactive_Feedback_Thought_Description'),
      },
      {
        type: InteractiveFeedbackCardType.IMAGE,
        heading: t('SSCandidate_Interactive_Feedback_Action'),
        color: '#31C3EC',
        borderColor: '#C82544',
        bottomColor: '#C82544',
        topImage: ActionImage,
        cardHTML: t('SSCandidate_Interactive_Feedback_Action_Description'),
      },
      {
        type: InteractiveFeedbackCardType.IMAGE,
        heading: t('SSCandidate_Interactive_Feedback_Influence'),
        color: '#31C3EC',
        borderColor: '#08A053',
        bottomColor: '#08A053',
        topImage: InfluenceImage,
        cardHTML: t('SSCandidate_Interactive_Feedback_Influence_Description'),
      },
    ];

    return { teamStrengthsCards };
  };

  const parseStrengthGraphCardOptions = (
    details: GetMyTeamStrengthsScoresDetails
  ) => {
    const MyTeamStrengthsScaleMaps = {
      'The Perfector': {
        color: '#31C3EC',
        borderColor: '#0D6492',
        bottomColor: '#0D6492',
        topImage: PerfectorImage,
        cardHTML: t('SSCandidate_Interactive_Feedback_Perfector_Description'),
      },
      'The Pragmatist': {
        color: '#31C3EC',
        borderColor: '#3CC1EA',
        bottomColor: '#3CC1EA',
        topImage: PragmatistImage,
        cardHTML: t('SSCandidate_Interactive_Feedback_Pragmatist_Description'),
      },
      'The Producer': {
        color: '#EACD60',
        borderColor: '#762572',
        bottomColor: '#762572',
        topImage: ProducerImage,
        cardHTML: t('SSCandidate_Interactive_Feedback_Producer_Description'),
      },
      'The Catalyst': {
        color: '#EACD60',
        borderColor: '#C82544',
        bottomColor: '#C82544',
        topImage: CatalystImage,
        cardHTML: t('SSCandidate_Interactive_Feedback_Catalyst_Description'),
      },
      'The Harmoniser': {
        color: '#732571',
        borderColor: '#08A053',
        bottomColor: '#08A053',
        topImage: HarmoniserImage,
        cardHTML: t('SSCandidate_Interactive_Feedback_Harmoniser_Description'),
      },
      'The Guide': {
        color: '#732571',
        borderColor: '#82C343',
        bottomColor: '#82C343',
        topImage: GuideImage,
        cardHTML: t('SSCandidate_Interactive_Feedback_Guide_Description'),
      },
      'The Visionary': {
        color: '#4FAC80',
        borderColor: '#FBB62A',
        bottomColor: '#FBB62A',
        topImage: VisionaryImage,
        cardHTML: t('SSCandidate_Interactive_Feedback_Visionary_Description'),
      },
      'The Explorer': {
        color: '#146594',
        borderColor: '#F18229',
        bottomColor: '#F18229',
        topImage: ExplorerImage,
        cardHTML: t('SSCandidate_Interactive_Feedback_Explorer_Description'),
      },
    };

    const teamStrengthsGraphCards = details.scores.map(
      ({ name, teamStrengthCode }) => ({
        type: InteractiveFeedbackCardType.IMAGE,
        heading: name,
        cardHTML:
          MyTeamStrengthsScaleMaps[
            teamStrengthCode as keyof typeof MyTeamStrengthsScaleMaps
          ].cardHTML,
        bottomColor:
          MyTeamStrengthsScaleMaps[
            teamStrengthCode as keyof typeof MyTeamStrengthsScaleMaps
          ].bottomColor,
        topImage:
          MyTeamStrengthsScaleMaps[
            teamStrengthCode as keyof typeof MyTeamStrengthsScaleMaps
          ].topImage,
      })
    );
    return { teamStrengthsGraphCards };
  };

  const getOptions = useCallback(async () => {
    const result = await getTeamStrengthsScores({
      ...(assessmentId ? { AssessmentId: assessmentId } : {}),
      ...(cultureCode ? { LanguageCultureInfo: cultureCode } : {}),
    });
    if (result.response && result.response.details) {
      setLoading(false);

      const isCandidateReport = result.response.details.isCandidateReport;
      const reportCode = result.response.details.reportCode;

      const teamStrengthsMenuCards = parseStrengthMenuCardOptions();

      const teamStrengthsGraphCards = parseStrengthGraphCardOptions(
        result.response.details
      );

      const teamStrengthsWheelImage = result.response.details.wheelDownloadUri;

      const reportDownloadFlags = {
        isReportDownloadable: result.response.details.isReportDownloadable,
      };      

      setData({
        teamStrengthsMenuCards,
        teamStrengthsGraphCards,
        teamStrengthsWheelImage,
        reportCode,
        isCandidateReport,
        reportDownloadFlags,
      });
    }

    if (result.errors) {
      setLoading(false);
      setErrors(true);
    }
  }, []);

  useEffect(() => {
    if (authStatus === "signed_in") {
      getOptions();
    }
  }, [authStatus]);

  return [data, loading, errors];
};
