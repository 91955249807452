import React, { useCallback, useRef, useState } from 'react';
import NotificationsIcon from 'assets/apply.clevry/Icon_Header_Notifications_DarkGreen.png';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NotificationsApi } from 'components/pages/stackNotifications/NotificationsApi';
import {
  NotificationData,
  NotificationUIUpdateAction,
} from 'components/pages/stackNotifications/notificationUIUpdateReducer';
import {
  selectLatestNotification,
  selectNotificationsCount,
  setLatestNotification,
} from 'reducers/notifications';
import { SignalRNotificationMessage } from 'components/pages/stackNotifications/models';
import { useCheckSignedIn } from 'helpers/useCheckSignedIn';

type LoggedInHeaderNotificationsIconProps = {};

const LoggedInHeaderNotificationsIcon =
  ({}: LoggedInHeaderNotificationsIconProps) => {
    const updatedNotificationsCount = useSelector(selectNotificationsCount);
    const latestNotification = useSelector(selectLatestNotification);
    const [count, setCount] = useState(0);
    const latestNotificationId = useRef<number | null>(null);
    const isSignedIn = useCheckSignedIn();

    const accessLevel = window.localStorage.getItem('accessLevel');

    const hasAccess =
      accessLevel !== 'projectID' && accessLevel !== 'jobApplicationID';

    enum NotificationUIUpdateActionType {
      ADD_UI_UPDATE = 'ADD_UI_UPDATE',
      COMPLETE_UI_UPDATE = 'COMPLETE_UI_UPDATE',
    }

    const setUIUpdate = (
      payload: NotificationData
    ): NotificationUIUpdateAction => {
      return {
        type: NotificationUIUpdateActionType.ADD_UI_UPDATE,
        payload: payload,
      };
    };

    const isNewNotification =
      latestNotification &&
      'id' in latestNotification &&
      latestNotification.id !== latestNotificationId.current;

    if (isNewNotification && latestNotification && 'id' in latestNotification) {
      latestNotificationId.current = latestNotification?.id;
      setCount((prevCount) => prevCount + 1);
    }

    const getAndSetInitialNotification = useCallback(async () => {
      const response = await NotificationsApi.getNotificationsStack();
      if (NotificationsApi.isSuccessData(response)) {
        setCount(response.data.count);
      } else {
      }
    }, []);

    useEffect(() => {
      if (isSignedIn && hasAccess) {
        getAndSetInitialNotification();
      }
    }, [isSignedIn]);

    useEffect(() => {
      if (updatedNotificationsCount !== null) {
        setCount(updatedNotificationsCount);
      }
    }, [updatedNotificationsCount]);

    return (
      <div className="LoggedInHeaderNotificationsIcon">
        {count > 0 ? (
          <div className="LoggedInHeaderNotificationsIcon__notifications">
            {count > 99 ? '99+' : count}
          </div>
        ) : (
          ''
        )}
        <img src={NotificationsIcon} alt="icon"></img>
      </div>
    );
  };

export default LoggedInHeaderNotificationsIcon;
