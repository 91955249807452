export const isProduction = process.env.REACT_APP_BUILD_TYPE === 'production';
export const isStaging = process.env.REACT_APP_BUILD_TYPE === 'staging';
export const isUAT =
  process.env.REACT_APP_BUILD_TYPE !== 'staging' &&
  process.env.REACT_APP_BUILD_TYPE !== 'production';

let baseURL = 'https://joy-api.clevry.com';

if (isStaging) {
  baseURL = 'https://staging-joy-api.clevry.com';
} else if (isUAT) {
  baseURL = 'https://clevry-findyourjoy-api-v1.dev.avamae.co.uk';
}

export const BASE_URL = baseURL;

export const endpoints = {
  auth: {
    login: '/api/v1/publicrole/authmodule/login',
    candidateAutoLogin: `${BASE_URL}/api/v1/publicrole/authmodule/candidateloginwithtoken`,
    assessorAutoLogin: `${BASE_URL}/api/v1/publicrole/authmodule/assessorloginwithtoken`,
    linkedInRedirect: `${BASE_URL}/api/v1/publicrole/linkedinapimodule/getrequesteaccessurl`,
    linkedInSignIn: `${BASE_URL}/api/v1/publicrole/linkedinapimodule/linkedinsignin`,
    refreshAccessToken: `${BASE_URL}/api/v1/publicrole/authmodule/refresh`,
    candidateRedirectTokenLogin: `${BASE_URL}/api/v1/publicrole/authmodule/candidateredirecttokenlogin`,
    getTokenAssessmentId: `${BASE_URL}/api/v1/publicrole/authmodule/gettokenassessmentid`,
  },
  assessmentList: {
    getLiveApplicationsList: `${BASE_URL}/api/v1/candidaterole/assessmentlistmodule/getliveapplicationslist`,
    getHistoricApplicationsList: `${BASE_URL}/api/v1/candidaterole/assessmentlistmodule/gethistoricapplicationslist`,
  },
  webskin: {
    getWebskinDetails: `${BASE_URL}/api/v1/publicrole/skinningmodule/getskin`,
  },
  featureFlags: {
    getAll: '/api/v1/candidaterole/featureflagsmodule/getall',
    test: '/api/v1/candidaterole/featureflagsmodule/test',
  },
  jobBoard: {
    getJobBoardSettings:
      '/api/v1/publicrole/jobboardmodule/getjobboardsettings',
    jobDetails: '/api/v1/publicrole/jobboardmodule/jobdetails',
    jobList: `${BASE_URL}/api/v1/candidaterole/browseopportunitiesmodule/getlistofcandidateopportunities`,
    jobBoardDetails:
      '/api/v1/candidaterole/browseopportunitiesmodule/getopportunitydetails',
    applyNow: '/api/v1/candidaterole/browseopportunitiesmodule/applynow',
    getProjectIdToken: `${BASE_URL}/api/v1/candidaterole/browseopportunitiesmodule/getprojectidtoken`,
    getProjectIdTokenPublic: `${BASE_URL}/api/v1/publicrole/browseopportunitiesmodule/getprojectidtoken`,
    saveSoftSkillsQuiz: '/api/v1/publicrole/jobboardmodule/savesoftskillsquiz',
    softSkillsQuiz: '/api/v1/publicrole/jobboardmodule/softskillsquiz',
    publicJobList: `${BASE_URL}/api/v1/publicrole/browseopportunitiesmodule/getlistofcandidateopportunities`,
    publicjobBoardDetails:
      '/api/v1/publicrole/browseopportunitiesmodule/getopportunitydetails',
    jobBoardDetailsFromToken:
      '/api/v1/publicrole/browseopportunitiesmodule/getopportunitydetailsfromtoken',
    getJobMatchDetails:
      '/api/v1/candidaterole/browseopportunitiesmodule/gethiringmanagersearchdetails',
    getListOfCandidateOpportunities: `${BASE_URL}/api/v1/candidaterole/browseopportunitiesmodule/getlistofcandidateopportunities`,
    publicGetJobMatchDetails: `/api/v1/publicrole/browseopportunitiesmodule/gethiringmanagersearchdetails`,
    expressInterest: `/api/v1/candidaterole/browseopportunitiesmodule/expressinterest`,
    getClientLogo: `/api/v1/publicrole/browseopportunitiesmodule/getclientlogo`,
    getApplicationStatus: `${BASE_URL}/api/v1/candidaterole/browseopportunitiesmodule/getapplicationstatus`,
  },
  language: {
    setlanguage: '/api/v1/publicrole/languagemodule/setlanguage',
    getLanguageList: '/api/v1/publicrole/languagemodule/languages',
    getLanguageResources:
      '/api/v1/candidaterole/languagemodule/GetLanguageResources?page=[[ns]]&getAllLanguages=false',
    getPublicLanguageResources:
      '/api/v1/publicrole/languagemodule/GetLanguageResources?page=[[ns]]&getAllLanguages=false',
  },
  registration: {
    signup: '/api/v1/publicrole/registrationmodule/signup',
  },
  candidateSignUp: {
    getCandidateDetails:
      '/api/v1/candidaterole/registrationmodule/getexistingcandidatedetails',
    registerCandidate: '/api/v1/candidaterole/registrationmodule/signup',
  },
  dashboard: {
    getInfo: '/api/v1/candidaterole/dashboardmodule/getdashboardinfo',
    getDetails: '/api/v1/candidaterole/dashboardmodule/getdashboarddetails',
    updateTeamPartner:
      '/api/v1/candidaterole/dashboardmodule/updateteampartner',
    getLiveApplicationsList:
      '/api/v1/candidaterole/dashboardmodule/getliveapplicationslist',
    getHistoricApplicationsList:
      '/api/v1/candidaterole/dashboardmodule/gethistoricapplicationslist',
    getMyDevelopmentList:
      '/api/v1/candidaterole/dashboardmodule/getmydevelopmentlist',
    getMatchConnectionsList: `/api/v1/candidaterole/dashboardmodule/gethiringmanagerconnectionslist`,
    getMatchConnectionDetails: `/api/v1/candidaterole/dashboardmodule/gethiringmanagerconnectiondetails`,
    expressInterest: `/api/v1/candidaterole/dashboardmodule/expressinterest`,
    updateCandidateConsent: `/api/v1/candidaterole/dashboardmodule/updatecandidateconsent`,
    getHiringManagerProfileDetails:
      '/api/v1/candidaterole/dashboardmodule/gethiringmanagerprofiledetails',
  },
  languages: {
    setLanguage: '/api/v1/candidaterole/languagemodule/setlanguage',
    getLanguageList: '/api/v1/candidaterole/languagemodule/languages',
  },
  account: {
    getPQUrl:
      '/api/v1/candidaterole/accountmodule/getpersonalityquestionnaireurl',
    getListOfCandidateAssessments:
      '/api/v1/candidaterole/accountmodule/getlistofcandidateassessments',
    getProfileInformation:
      '/api/v1/candidaterole/accountmodule/getprofileinformation',
    getExperienceInformation:
      '/api/v1/candidaterole/accountmodule/getexperienceinformation',
    getEducationInformation:
      '/api/v1/candidaterole/accountmodule/geteducationinformation',
    getReferenceInformation:
      '/api/v1/candidaterole/accountmodule/getreferenceinformation',
    viewAboutInformation:
      '/api/v1/candidaterole/accountmodule/viewaboutinformation',
    viewContactInformation:
      '/api/v1/candidaterole/accountmodule/viewcontactinformation',
    updateAboutInfo: '/api/v1/candidaterole/accountmodule/updateabout',
    updateContactInfo:
      '/api/v1/candidaterole/accountmodule/updatecontactinformation',
    updateExperienceInfo:
      '/api/v1/candidaterole/accountmodule/updateexperience',
    updateEducationInfo: '/api/v1/candidaterole/accountmodule/updateeducation',
    updateReferenceInfo: '/api/v1/candidaterole/accountmodule/updatereference',
    inviteToFindYourJoy:
      '/api/v1/candidaterole/accountmodule/invitetofindyourjoy',
    updatePassword: '/api/v1/candidaterole/accountmodule/updatepassword',
    deleteAccount: '/api/v1/candidaterole/accountmodule/deleteaccount',
    viewAccountInfo:
      '/api/v1/candidaterole/accountmodule/viewcaccountinformation',
    updateAccountInfo:
      '/api/v1/candidaterole/accountmodule/updateaccountinformation',
    getReportsInfo:
      '/api/v1/candidaterole/accountmodule/getpersonalityquestionnairereport',
    getAccountRoles: '/api/v1/candidaterole/accountmodule/getaccountroles',
    getEmploymentTypes:
      '/api/v1/candidaterole/accountmodule/getemploymenttypes',
    getJobTitles: '/api/v1/candidaterole/accountmodule/getjobtitles',
    getRoleLevels: '/api/v1/candidaterole/accountmodule/getrolelevels',
    getIndustries: '/api/v1/candidaterole/accountmodule/getindustries',
    getHeadcountOptions:
      '/api/v1/candidaterole/accountmodule/getheadcountvalues',
    getLocationSuggestions:
      '/api/v1/candidaterole/accountmodule/getlocationsuggestions',
    updateAccountRoles:
      '/api/v1/candidaterole/accountmodule/updateaccountroles',
    getPersonalityQuestionnaireGroupReport: `/api/v1/candidaterole/accountmodule/getpersonalityquestionnairegroupreport`,
  },
  myDevelopment: {
    getAccountType: '/api/v1/candidaterole/accountmodule/getaccounttype',
    getAbilityTestSummary:
      '/api/v1/candidaterole/developmentmodule/getabilitytestsummary',
    updateAccountType: '/api/v1/candidaterole/accountmodule/updateaccounttype',
  },
  forgottenPassword: {
    requestPasswordReset:
      '/api/v1/publicrole/forgottenpasswordmodule/requestpasswordreset',
    resetPassword: '/api/v1/publicrole/forgottenpasswordmodule/resetpassword',
  },
  profile: {
    mockGetProfileInformation:
      'https://avamae.stoplight.io/mocks/avamae/criterion-cloud-web-softskillsengine-v1/2207661/accountmodule/getprofileinformation',
  },
  about: {
    mockGetAbout:
      'https://avamae.stoplight.io/mocks/avamae/criterion-cloud-web-softskillsengine-v1/2207661/accountmodule/getabout',
    mockUpdateAbout:
      'https://avamae.stoplight.io/mocks/avamae/criterion-cloud-web-softskillsengine-v1/2207661/accountmodule/updateabout',
  },
  experience: {
    mockGetExperience:
      'https://avamae.stoplight.io/mocks/avamae/criterion-cloud-web-softskillsengine-v1/2207661/accountmodule/getexperience',
    mockUpdateExperience:
      'https://avamae.stoplight.io/mocks/avamae/criterion-cloud-web-softskillsengine-v1/2207661/accountmodule/updateexperience',
  },
  findYourJoy: {
    getQuestions: `${BASE_URL}/api/v1/publicrole/jobboardmodule/softskillsquiz`,
    results: `${BASE_URL}/api/v1/publicrole/jobboardmodule/softskillsresultbyanswers`,
    saveAnswers: `${BASE_URL}/api/v1/candidaterole/accountmodule/savesoftskillsquiz`,
  },
  abilityPracticeTest: {
    getQuestions: `${BASE_URL}/api/v1/publicrole/jobboardmodule/abilitytest`,
    testResults: `${BASE_URL}/api/v1/publicrole/jobboardmodule/abilitytestresultbyanswers`,
  },
  abilityTest: {
    getATDetails:
      'https://stoplight.io/mocks/avamae/criterion-cloud-web-softskillsengine-v1/2207661/abilitytests/verbal',
    setATAnswers:
      'https://stoplight.io/mocks/avamae/criterion-cloud-web-softskillsengine-v1/2207661/abilitytests/setatanswers',
    completeATAssessment:
      'https://stoplight.io/mocks/avamae/criterion-cloud-web-softskillsengine-v1/2207661/abilitytests/submitverbalassessment',

    getDetails: `${BASE_URL}/api/v1/candidaterole/AbilityTestmodule/getDetails`,
    setAnswers: `${BASE_URL}/api/v1/candidaterole/AbilityTestmodule/setanswers`,
    completeTest: `${BASE_URL}/api/v1/candidaterole/AbilityTestmodule/completetest`,
    getResonableAdjustmentReasons: `${BASE_URL}/api/v1/candidaterole/AbilityTestmodule/getresonableadjustmentreasons`,
    requestResonableAdjustment: `${BASE_URL}/api/v1/candidaterole/AbilityTestmodule/requestresonableadjustment`,
    getResetReasons: `${BASE_URL}/api/v1/candidaterole/AbilityTestmodule/getresetreasons`,
    requestReset: `${BASE_URL}/api/v1/candidaterole/AbilityTestmodule/requestreset`,
    startTest: `${BASE_URL}/api/v1/candidaterole/AbilityTestmodule/starttest`,
  },
  sjt: {
    getDetails: `${BASE_URL}/api/v1/candidaterole/SJTmodule/getDetails`,
    setRegularAnswers: `${BASE_URL}/api/v1/candidaterole/SJTmodule/setregularanswers`,
    setOneToFiveAnswers: `${BASE_URL}/api/v1/candidaterole/SJTmodule/set1_5answers`,
    completeTest: `${BASE_URL}/api/v1/candidaterole/SJTmodule/completetest`,
    getResonableAdjustmentReasons: `${BASE_URL}/api/v1/candidaterole/SJTmodule/getresonableadjustmentreasons`,
    requestResonableAdjustment: `${BASE_URL}/api/v1/candidaterole/SJTmodule/requestresonableadjustment`,
    getResetReasons: `${BASE_URL}/api/v1/candidaterole/SJTmodule/getresetreasons`,
    requestReset: `${BASE_URL}/api/v1/candidaterole/SJTmodule/requestreset`,
    startTest: `${BASE_URL}/api/v1/candidaterole/SJTmodule/starttest`,
  },
  blendedTest: {
    getDetails: `${BASE_URL}/api/v1/candidaterole/blendedtestmodule/getDetails`,
    startTest: `${BASE_URL}/api/v1/candidaterole/blendedtestmodule/starttest`,
    setPQAnswers: `${BASE_URL}/api/v1/candidaterole/blendedtestmodule/setpqanswers`,
    setATAnswers: `${BASE_URL}/api/v1/candidaterole/blendedtestmodule/setabilityanswers`,
    setSJTRegularAnswers: `${BASE_URL}/api/v1/candidaterole/blendedtestmodule/setsjtregularanswers`,
    setSJT1_5Answers: `${BASE_URL}/api/v1/candidaterole/blendedtestmodule/setsjt1_5answers`,
    setMMAnswers: `${BASE_URL}/api/v1/candidaterole/blendedtestmodule/setchatanswers`,
    completeTest: `${BASE_URL}/api/v1/candidaterole/blendedtestmodule/completetest`,
    getAssessmentPracticeQuestions: `${BASE_URL}/api/v1/candidaterole/blendedtestmodule/getPracticeQuestions`,
  },
  strengthsReport: {
    getSoftSkillsResults: `${BASE_URL}/api/v1/candidaterole/accountmodule/getsoftskillsquizresult`,
    getCandidateComparison: `${BASE_URL}/api/v1/candidaterole/accountmodule/getcandidatecomparision`,
    getTeamPartners: `${BASE_URL}/api/v1/candidaterole/accountmodule/getlistofcandidateteampartners`,
    // TODO(HC): Update Sharable Report URL
    getSharableReport: `${BASE_URL}/api/v1/publicrole/publicreportmodulecontroller/publicreport`,
  },
  resourcesModule: {
    getResources: `${BASE_URL}/api/v1/candidaterole/resourcesmodule/getresources`,
  },
  sharing: {
    teamStrenghts: `${BASE_URL}/api/v1/candidaterole/accountmodule/referfriendtofindyourjoy`,
    findYourJoy: `${BASE_URL}/api/v1/candidaterole/accountmodule/sharefindyourjoyresults`,
    shareProfile: `${BASE_URL}/api/v1/candidaterole/accountmodule/sharefindyourjoyresults`,
    getSharableUrl: `${BASE_URL}/api/v1/candidaterole/accountmodule/getsoftskillssharereporturl`,
    inviteToFYJ: `${BASE_URL}/api/v1/candidaterole/accountmodule/invitetofindyourjoy`,
  },
  jobApplication: {
    saveContactInformation: `${BASE_URL}/api/v1/candidaterole/jobapplicationmodule/savecontactinformation`,
    saveAbout: `${BASE_URL}/api/v1/candidaterole/jobapplicationmodule/saveabout`,
    saveJobInformation: `${BASE_URL}/api/v1/candidaterole/jobapplicationmodule/savejobinformation`,
    saveExperienceInformation: `${BASE_URL}/api/v1/candidaterole/jobapplicationmodule/saveexperienceinformation`,
    saveEducationInformation: `${BASE_URL}/api/v1/candidaterole/jobapplicationmodule/saveeducationinformation`,
    saveReferenceInformation: `${BASE_URL}/api/v1/candidaterole/jobapplicationmodule/savereferenceinformation`,
    uploadCV: `${BASE_URL}/api/v1/candidaterole/jobapplicationmodule/uploadcv`,
    saveDiversityInformation: `${BASE_URL}/api/v1/candidaterole/jobapplicationmodule/savediversityinformation`,
    saveHardSkillsInformation: `${BASE_URL}/api/v1/candidaterole/jobapplicationmodule/savehardskillsinformation`,
    getListOfApplications: `${BASE_URL}/api/v1/candidaterole/jobapplicationmodule/getlistofapplications`,
    getLiveApplicationDetails: `${BASE_URL}/api/v1/candidaterole/jobapplicationmodule/getliveapplicationdetails`,
    getHistoricApplicationDetails: `${BASE_URL}/api/v1/candidaterole/jobapplicationmodule/gethistoricapplicationdetails`,
    getApplicationWorkflow: `${BASE_URL}/api/v1/candidaterole/jobapplicationmodule/getapplicationworkflow`,
    withdrawApplication: `${BASE_URL}/api/v1/candidaterole/jobapplicationmodule/withdraw`,
    getWithdrawReasons: `${BASE_URL}/api/v1/candidaterole/jobapplicationmodule/getwithdrawreasons`,
    getApplicationFormDetails: `${BASE_URL}/api/v1/candidaterole/jobapplicationmodule/getapplicationformdetails`,
    getAllCandidateDocuments: `${BASE_URL}/api/v1/candidaterole/jobapplicationmodule/getallcandidatedocuments`,
    saveCustomElements: `${BASE_URL}/api/v1/candidaterole/jobapplicationmodule/savecustomanswers`,
    saveAndSendAnswers: `${BASE_URL}/api/v1/candidaterole/jobapplicationmodule/saveandsendanswers`,
    getEthnicities: `${BASE_URL}/api/v1/candidaterole/jobapplicationmodule/getethnicities`,
    contactEmployer: `${BASE_URL}/api/v1/candidaterole/jobapplicationmodule/contactemployer`,
    getContactEmployerDetails: `${BASE_URL}/api/v1/candidaterole/jobapplicationmodule/getcontactemployerdetails`,
    showApplicationForm: `${BASE_URL}/api/v1/candidaterole/jobapplicationmodule/showapplicationform`,
    undoWithdrawApplication: `${BASE_URL}/api/v1/candidaterole/jobapplicationmodule/undowithdraw`,
    getJobTitles: `${BASE_URL}/api/v1/candidaterole/jobapplicationmodule/getjobtitles`,
    getRoleLevels: `${BASE_URL}/api/v1/candidaterole/jobapplicationmodule/getrolelevels`,
    getIndustries: `${BASE_URL}/api/v1/candidaterole/jobapplicationmodule/getindustries`,
    getHeadcountValues: `${BASE_URL}/api/v1/candidaterole/jobapplicationmodule/getheadcountvalues`,
    getLocationSuggestions: `${BASE_URL}/api/v1/candidaterole/jobapplicationmodule/getlocationsuggestions`,
    getEducationLevels: `${BASE_URL}/api/v1/candidaterole/jobapplicationmodule/geteducationlevel`,
    getEducationSubjects: `${BASE_URL}/api/v1/candidaterole/jobapplicationmodule/geteducationsubjects`,
    getHardSkills: `${BASE_URL}/api/v1/candidaterole/jobapplicationmodule/gethardskills`,
    candidateGetJobDocumentList: `${BASE_URL}/api/v1/candidaterole/jobapplicationmodule/GetDocuments`,
    candidateCreateJobDocuments: `${BASE_URL}/api/v1/candidaterole/jobapplicationmodule/CreateDocuments`,
    candidateDeleteJobDocuments: `${BASE_URL}/api/v1/candidaterole/jobapplicationmodule/DeleteDocuments`,
    getLiveApplicationsList: `${BASE_URL}/api/v1/candidaterole/jobapplicationmodule/getliveapplicationslist`,
    getHistoricApplicationsList: `${BASE_URL}/api/v1/candidaterole/jobapplicationmodule/gethistoricapplicationslist`,
    hideProjectIntroVideo: `${BASE_URL}/api/v1/candidaterole/jobapplicationmodule/hideprojectintrovideo`,
    getfaq: `${BASE_URL}/api/v1/candidaterole/jobapplicationmodule/getfaq`,
    resendScores: `${BASE_URL}/api/v1/candidaterole/jobapplicationmodule/resendscores`,
  },
  assessment: {
    closeAssessment: '/api/v1/assessorrole/assessmentmodule/CloseAssessment',
  },
  videoGroupExercise: {
    details: '/api/v1/candidaterole/videogroupexercisemodule/details',
  },
  twilioVideo: {
    getToken: `${BASE_URL}/api/v1/candidaterole/twiliovideomodule/GetToken`,
    getInterviewDetails: `${BASE_URL}/api/v1/candidaterole/twiliovideointerviewmodule/GetInterviewDetails`,
    createVideoInterviewRoom: `${BASE_URL}/api/v1/candidaterole/twiliovideointerviewmodule/createvideointerviewroom`,
    createPracticeVideoInterviewRoom: `${BASE_URL}/api/v1/candidaterole/twiliovideointerviewmodule/createpracticevideointerviewroom`,
    finishVideoInterview: `${BASE_URL}/api/v1/candidaterole/twiliovideointerviewmodule/FinishVideoInterview`,
  },
  presentationActivity: {
    getPresentationActivityDetails: `${BASE_URL}/api/v1/candidaterole/recordedpresentationmodule/getrecordedpresentationdetails`,
    createPresentationActivityRoom: `${BASE_URL}/api/v1/candidaterole/recordedpresentationmodule/createrecordedpresentationroom`,
    finishPresentationActivity: `${BASE_URL}/api/v1/candidaterole/recordedpresentationmodule/finishrecordedpresentation`,
    getToken: '/api/v1/candidaterole/twiliovideomodule/GetToken',
  },
  businessCase: {
    getBusinessCaseDetails: `${BASE_URL}/api/v1/candidaterole/businesscasemodule/getbusinesscasedetails`,
    saveCandidateAnswers: `${BASE_URL}/api/v1/candidaterole/businesscasemodule/savecandidateanswers`,
    completeBusinessCase: `${BASE_URL}/api/v1/candidaterole/businesscasemodule/completebusinesscase`,
  },
  virtualTeamDiscussion: {
    getVirtualTeamDiscussionDetails: `${BASE_URL}/api/v1/candidaterole/virtualteamdiscussionmodule/details`,
    getVirtualTeamDiscussionMaterialsDetails: `${BASE_URL}/api/v1/candidaterole/virtualteamdiscussionmodule/materialsdetails`,
    getVirtualTeamDiscussionRoomDetails: `${BASE_URL}/api/v1/candidaterole/virtualteamdiscussionmodule/roomdetails`,
    recordVirtualTeamDiscussionRoom: `${BASE_URL}/api/v1/candidaterole/virtualteamdiscussionmodule/recordcandidateparticipation`,
    finishVirtualTeamDiscussion: `${BASE_URL}/api/v1/candidaterole/virtualteamdiscussionmodule/finishassessment`,
    getToken: '/api/v1/candidaterole/twiliovideomodule/GetToken',
  },
  dailyJoyTracker: {
    updateDailyJoyTracker:
      '/api/v1/candidaterole/developmentmodule/updatedailyjoytracker',
    getDailyJoyTracker:
      '/api/v1/candidaterole/developmentmodule/getdailyjoytracker',
  },
  myGrowth: {
    getMyGrowthSummary:
      '/api/v1/candidaterole/developmentmodule/getmygrowthsummary',
    registerForCampaign:
      '/api/v1/candidaterole/developmentmodule/registerforcampaign',
    dismissTeamStrengthsAndSales:
      '/api/v1/candidaterole/developmentmodule/dismissteamssalesprompt',
    dismissLeadership:
      '/api/v1/candidaterole/developmentmodule/dismissleadershipprompt',
    dismissPersonalFeedback:
      '/api/v1/candidaterole/developmentmodule/dismissperonalfeedbackprompt',
    downloadReport: '/api/v1/candidaterole/developmentmodule/downloadreport',
    downloadGroupReport:
      '/api/v1/candidaterole/developmentmodule/downloadgroupreport',
    getSoftSkillsReflectionQuestions:
      'api/v1/candidaterole/developmentmodule/GetReflectionQuestions',
    setSoftSkillsReflectionAnswers:
      'api/v1/candidaterole/developmentmodule/SetSoftSkillsReflectionCandidateAnswers',
    getSoftSkillsReflectionResult:
      'api/v1/candidaterole/developmentmodule/GetSoftSkillsReflectionResult',
  },
  joy: {
    getJoySurveyQuestions:
      '/api/v1/candidaterole/joymodule/getjoysurveyquestions',
    getLatestJoySurveyResults:
      '/api/v1/candidaterole/joymodule/getlatestjoysurveyresult',
    setJoySurveyAnswers: '/api/v1/candidaterole/joymodule/setjoysurveyanswers',
  },
  messages: {
    accept: '/api/communicationsmodule/chats/accept',
    decline: '/api/communicationsmodule/chats/decline',
    details: '/api/communicationsmodule/chats/details',
    getMessageAttachment:
      '/api/communicationsmodule/chats/getmessageattachment',
    recentChats: '/api/communicationsmodule/chats/recent',
    sendChatMessage: '/api/communicationsmodule/chats/sendmessage',
    createForContact: '/api/communicationsmodule/chats/createforcontact',
    getUnreadMessageCount: '/api/communicationsmodule/chats/unreadmessagecount',
    getUnreadMessageDetails:
      '/api/communicationsmodule/chats/unreadmessagedetails',
  },
  chats: {
    getCandidateConsentDetails:
      '/api/v1/candidaterole/chatsmodule/getcandidateconsentdetails',
    updateCandidateConsent:
      '/api/v1/candidaterole/chatsmodule/updatecandidateconsent',
  },
  virtualMeeting: {
    getDetails: `${BASE_URL}/api/v1/candidaterole/virtualmeetingmodule/details`,
    getMaterialsDetails: `${BASE_URL}/api/v1/candidaterole/virtualmeetingmodule/materialsdetails`,
    getRoomDetails: `${BASE_URL}/api/v1/candidaterole/virtualmeetingmodule/roomdetails`,
    recordRoom: `${BASE_URL}/api/v1/candidaterole/virtualmeetingmodule/recordcandidateparticipation`,
    finish: `${BASE_URL}/api/v1/candidaterole/virtualmeetingmodule/finishassessment`,
    getToken: '/api/v1/candidaterole/twiliovideomodule/GetToken',
  },
  calendarModule: {
    searchContactsUrl: `/api/calendarmodule/events/usercontacts`,
    createUrl: `/api/calendarmodule/events/create`,
    editUrl: `/api/calendarmodule/events/edit`,
    eventsSummaryUrl: `/api/calendarmodule/events/summaries`,
    cancelMeetingUrl: `/api/calendarmodule/events/cancel`,
    declineMeetingUrl: `/api/calendarmodule/events/decline`,
    acceptMeetingUrl: `/api/calendarmodule/events/accept`,
    createAvailability: `/api/calendarmodule/availabilities/create`,
    editAvailability: `/api/calendarmodule/availabilities/edit`,
    getAvailability: `/api/calendarmodule/availabilities/summaries`,
  },
  bookings: {
    getEventDetails: '/api/v1/candidaterole/bookingmodule/getbookableevents',
    bookEvent: '/api/v1/candidaterole/bookingmodule/bookevent',
    cancelEvent: '/api/v1/candidaterole/bookingmodule/cancelevent',
  },
  liveInterview: {
    getLiveInterviewDetails: `${BASE_URL}/api/v1/candidaterole/liveinterviewmodule/details`,
    getLiveInterviewMaterialsDetails: `${BASE_URL}/api/v1/candidaterole/liveinterviewmodule/materialsdetails`,
    getLiveInterviewRoomDetails: `${BASE_URL}/api/v1/candidaterole/liveinterviewmodule/roomdetails`,
    recordLiveInterviewRoom: `${BASE_URL}/api/v1/candidaterole/liveinterviewmodule/recordcandidateparticipation`,
    finishLiveInterview: `${BASE_URL}/api/v1/candidaterole/liveinterviewmodule/finishassessment`,
    getToken: '/api/v1/candidaterole/twiliovideomodule/GetToken',
  },
  myHistory: {
    getJobApplicationFeedback: `${BASE_URL}/api/v1/candidaterole/feedbackmodule/getjobapplicationfeedback`,
    getAbilitySJTScores: `${BASE_URL}/api/v1/candidaterole/feedbackmodule/abilityandsjtscores`,
    getJobFitSummary: `${BASE_URL}/api/v1/candidaterole/feedbackmodule/jobfitsummary`,
    getInsightsScores: `${BASE_URL}/api/v1/candidaterole/feedbackmodule/insightsscores`,
    getLeadershipScores: `${BASE_URL}/api/v1/candidaterole/feedbackmodule/leadershipscores`,
    getSaleScores: `${BASE_URL}/api/v1/candidaterole/feedbackmodule/salesscores`,
    getTeamStrengthScores: `${BASE_URL}/api/v1/candidaterole/feedbackmodule/teamstrengthsscores`,
  },
  matchingProcess: {
    getCandidateCurrentSituation: `${BASE_URL}/api/v1/candidaterole/marketplacemodule/getcandidatestatus`,
    setCandidateCurrentSituation: `${BASE_URL}/api/v1/candidaterole/marketplacemodule/setcandidatestatus`,
    getCandidateRoleTitleOptions: `${BASE_URL}/api/v1/candidaterole/marketplacemodule/getjobtitles`,
    getCandidateRoleLevelOptions: `${BASE_URL}/api/v1/candidaterole/marketplacemodule/getrolelevels`,
    getCandidateIndustryOptions: `${BASE_URL}/api/v1/candidaterole/marketplacemodule/getindustries`,
    getHeadcountValues: `${BASE_URL}/api/v1/candidaterole/marketplacemodule/getheadcountvalues`,
    getCandidateDreamJobs: `${BASE_URL}/api/v1/candidaterole/marketplacemodule/getcandidatedreamjobs`,
    setCandidateDreamJobs: `${BASE_URL}/api/v1/candidaterole/marketplacemodule/setcandidatedreamjobs`,
    getCandidateHowLikeToWork: `${BASE_URL}/api/v1/candidaterole/marketplacemodule/getcandidateworktype`,
    setCandidateHowLikeToWork: `${BASE_URL}/api/v1/candidaterole/marketplacemodule/setcandidateworktype`,
    getCandidateGlobalSoftSkillsOptions: `${BASE_URL}/api/v1/candidaterole/marketplacemodule/getglobalsoftskills`,
    getCandidateGlobalSoftSkills: `${BASE_URL}/api/v1/candidaterole/marketplacemodule/getcandidateglobalsoftskills`,
    setCandidateGlobalSoftSkills: `${BASE_URL}/api/v1/candidaterole/marketplacemodule/setcandidateglobalsoftskills`,
    getCandidateSalaryAspirations: `${BASE_URL}/api/v1/candidaterole/marketplacemodule/getcandidatesalaryaspriations`,
    setCandidateSalaryAspirations: `${BASE_URL}/api/v1/candidaterole/marketplacemodule/setcandidatesalaryaspriations`,
    getCandidateWhereToWorkOptions: `${BASE_URL}/api/v1/candidaterole/marketplacemodule/getjoblocations`,
    getCandidateWhereToWork: `${BASE_URL}/api/v1/candidaterole/marketplacemodule/getworklocations`,
    setCandidateWhereToWork: `${BASE_URL}/api/v1/candidaterole/marketplacemodule/setworklocation`,
    getCandidateEducationOptions: `${BASE_URL}/api/v1/candidaterole/marketplacemodule/geteducationlevel`,
    getCandidateEducation: `${BASE_URL}/api/v1/candidaterole/marketplacemodule/getcandidateeducation`,
    getCandidateEducationSubjects: `${BASE_URL}/api/v1/candidaterole/marketplacemodule/geteducationsubjects`,
    setCandidateEducation: `${BASE_URL}/api/v1/candidaterole/marketplacemodule/setcandidateeducation`,
    getCandidateExperiences: `${BASE_URL}/api/v1/candidaterole/marketplacemodule/getcandidateexperience`,
    setCandidateExperiences: `${BASE_URL}/api/v1/candidaterole/marketplacemodule/setcandidateexperience`,
    getCandidateSpokenLanguageNameOptions: `${BASE_URL}/api/v1/candidaterole/marketplacemodule/getspokenlanguages`,
    getCandidateSpokenLanguageProficiencyOptions: `${BASE_URL}/api/v1/candidaterole/marketplacemodule/getspokenlanguagesproficiency`,
    getCandidateSpokenLanguages: `${BASE_URL}/api/v1/candidaterole/marketplacemodule/getcandidatelanguages`,
    setCandidateSpokenLanguages: `${BASE_URL}/api/v1/candidaterole/marketplacemodule/setcandidatelanguages`,
    getCandidateBio: `${BASE_URL}/api/v1/candidaterole/accountmodule/viewaboutinformation`,
    setCandidateBio: `${BASE_URL}/api/v1/candidaterole/accountmodule/updateabout`,
    getCountries: `${BASE_URL}/api/v1/candidaterole/marketplacemodule/getcountries`,
    getCandidateContact: `${BASE_URL}/api/v1/candidaterole/marketplacemodule/getcandidatecontactdetails`,
    setCandidateContact: `${BASE_URL}/api/v1/candidaterole/marketplacemodule/setcandidatecontactdetails`,
    getHardSkills: `${BASE_URL}/api/v1/candidaterole/marketplacemodule/gethardskills`,
    getCandidateHardSkills: `${BASE_URL}/api/v1/candidaterole/marketplacemodule/getcandidatehardskills`,
    setCandidateHardSkills: `${BASE_URL}/api/v1/candidaterole/marketplacemodule/setcandidatehardskills`,
    getLocationSuggestions: `${BASE_URL}/api/v1/candidaterole/marketplacemodule/getlocationsuggestions`,
    publicGetLocationSuggestions: `${BASE_URL}/api/v1/publicrole/browseopportunitiesmodule/getlocationsuggestions`,
    getEmailAddressForContactDetails: `${BASE_URL}/api/v1/candidaterole/marketplacemodule/getemailaddressforcontactdetails`,
    getCandidateSoftSkillsStrengths: `${BASE_URL}/api/v1/candidaterole/marketplacemodule/getcandidatesoftskillsstrengths`,
    setCandidateProfilePicture: `${BASE_URL}/api/v1/candidaterole/marketplacemodule/setcandidateprofilepicture`,
    setLastPageVisited: `${BASE_URL}/api/v1/candidaterole/marketplacemodule/setlastmatchingpagevisited`,
    getMatchingProfileProgressBars: `${BASE_URL}/api/v1/candidaterole/marketplacemodule/getprogressbars`,
    getIsSearchable: `${BASE_URL}/api/v1/candidaterole/marketplacemodule/getissearchable`,
    setIsSearchable: `${BASE_URL}/api/v1/candidaterole/marketplacemodule/setissearchable`,
  },
  jobInterview: {
    getRoomDetails: `${BASE_URL}/api/v1/candidaterole/bookingmodule/roomdetails`,
  },
  notificationsmodule: {
    getForUser: `/api/v1/hiringmanagerrole/notificationsmodule/getforuser`,
    userStack: `/api/notificationsmodule/notifications/userstack`,
    acknowledgeSingle: `/api/notificationsmodule/notifications/acknowledgesingle`,
    acknowledgeAll: `/api/notificationsmodule/notifications/acknowledgeall`,
    getUserPreferences: `/api/v1/customerrole/notificationsauxmodule/getforuser`,
    updateSinglePreference: `/api/notificationsmodule/userpreferences/updatesingle`,
    signalR: `/nm-hub`,
  },
  interactiveFeedback: {
    getStrengthScores:
      '/api/v1/candidaterole/interactivefeedbackmodule/strengthsscores',
    getDevelopmentScores:
      '/api/v1/candidaterole/interactivefeedbackmodule/developmentscores',
    getCapabilityScores:
      '/api/v1/candidaterole/interactivefeedbackmodule/capabilityscores',
    getTeamStrengthScores:
      '/api/v1/candidaterole/interactivefeedbackmodule/teamstrengthsscores',
    getLeadershipScores:
      '/api/v1/candidaterole/interactivefeedbackmodule/leadershipscores',
    getSalesScores:
      '/api/v1/candidaterole/interactivefeedbackmodule/salesscores',
    getReportLanguageOptions:
      '/api/v1/candidaterole/interactivefeedbackmodule/reportlanguageoptions',
    downloadReport:
      '/api/v1/candidaterole/interactivefeedbackmodule/downloadreport',
  },
  personalityQuestionnaire: {
    getPQDetails:
      '/api/v1/candidaterole/personalityquestionnairemodule/details',
    setPQAnswers:
      '/api/v1/candidaterole/personalityquestionnairemodule/setpqanswers',
    completepq:
      '/api/v1/candidaterole/personalityquestionnairemodule/completepq',
  },
};

let base_images_url =
  'https://psycruitplatform.blob.core.windows.net/findyourjoy-public/';

if (isStaging) {
  base_images_url =
    'https://stagingclevry.blob.core.windows.net/findyourjoy-public/';
} else if (isUAT) {
  base_images_url =
    'https://uatblobstorage2019.blob.core.windows.net/criterion-softskillsengine-public/';
}

export type StringObjects = {
  [key: string]: string;
};
export const images: {
  reports: StringObjects;
  teamStrengthsReports: StringObjects;
  leadershipStrengthsReports: StringObjects;
  salesStrengthsReports: StringObjects;
  insightsReports: StringObjects;
  interactiveFeedback: StringObjects;

  myStrengthsReportTiles: StringObjects;
  myDevelopmentReportTiles: StringObjects;
  myCapabilitiesReportTiles: StringObjects;
  myTeamStrengthsReportTiles: StringObjects;
  myLeadershipStrengthsReportTiles: StringObjects;
  mySalesStrengthsReportTiles: StringObjects;
} = {
  reports: {
    rateMeter0: `${base_images_url}images/InterviewReports/RateMeters/ratemeter0.jpg`,
    rateMeter1: `${base_images_url}images/InterviewReports/RateMeters/ratemeter1.jpg`,
    rateMeter2: `${base_images_url}images/InterviewReports/RateMeters/ratemeter2.jpg`,
    rateMeter3: `${base_images_url}images/InterviewReports/RateMeters/ratemeter3.jpg`,
    rateMeter4: `${base_images_url}images/InterviewReports/RateMeters/ratemeter4.jpg`,
    rateMeter5: `${base_images_url}images/InterviewReports/RateMeters/ratemeter5.jpg`,
    rateMeter6: `${base_images_url}images/InterviewReports/RateMeters/ratemeter6.jpg`,
    rateMeter7: `${base_images_url}images/InterviewReports/RateMeters/ratemeter7.jpg`,
    rateMeter8: `${base_images_url}images/InterviewReports/RateMeters/ratemeter8.jpg`,
    rateMeter9: `${base_images_url}images/InterviewReports/RateMeters/ratemeter9.jpg`,
    rateMeter10: `${base_images_url}images/InterviewReports/RateMeters/ratemeter10.jpg`,
  },
  teamStrengthsReports: {
    rateMeter_blue_0: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Blue_00.jpg`,
    rateMeter_blue_1: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Blue_01.jpg`,
    rateMeter_blue_2: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Blue_02.jpg`,
    rateMeter_blue_3: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Blue_03.jpg`,
    rateMeter_blue_4: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Blue_04.jpg`,
    rateMeter_blue_5: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Blue_05.jpg`,
    rateMeter_blue_6: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Blue_06.jpg`,
    rateMeter_blue_7: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Blue_07.jpg`,
    rateMeter_blue_8: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Blue_08.jpg`,
    rateMeter_blue_9: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Blue_09.jpg`,
    rateMeter_blue_10: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Blue_10.jpg`,
    rateMeter_green_0: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Green_00.jpg`,
    rateMeter_green_1: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Green_01.jpg`,
    rateMeter_green_2: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Green_02.jpg`,
    rateMeter_green_3: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Green_03.jpg`,
    rateMeter_green_4: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Green_04.jpg`,
    rateMeter_green_5: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Green_05.jpg`,
    rateMeter_green_6: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Green_06.jpg`,
    rateMeter_green_7: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Green_07.jpg`,
    rateMeter_green_8: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Green_08.jpg`,
    rateMeter_green_9: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Green_09.jpg`,
    rateMeter_green_10: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Green_10.jpg`,
    rateMeter_olive_0: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Olive_00.jpg`,
    rateMeter_olive_1: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Olive_01.jpg`,
    rateMeter_olive_2: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Olive_02.jpg`,
    rateMeter_olive_3: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Olive_03.jpg`,
    rateMeter_olive_4: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Olive_04.jpg`,
    rateMeter_olive_5: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Olive_05.jpg`,
    rateMeter_olive_6: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Olive_06.jpg`,
    rateMeter_olive_7: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Olive_07.jpg`,
    rateMeter_olive_8: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Olive_08.jpg`,
    rateMeter_olive_9: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Olive_09.jpg`,
    rateMeter_olive_10: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Olive_10.jpg`,
    rateMeter_orange_0: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Orange_00.jpg`,
    rateMeter_orange_1: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Orange_01.jpg`,
    rateMeter_orange_2: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Orange_02.jpg`,
    rateMeter_orange_3: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Orange_03.jpg`,
    rateMeter_orange_4: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Orange_04.jpg`,
    rateMeter_orange_5: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Orange_05.jpg`,
    rateMeter_orange_6: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Orange_06.jpg`,
    rateMeter_orange_7: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Orange_07.jpg`,
    rateMeter_orange_8: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Orange_08.jpg`,
    rateMeter_orange_9: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Orange_09.jpg`,
    rateMeter_orange_10: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Orange_10.jpg`,
    rateMeter_purple_0: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Purple_00.jpg`,
    rateMeter_purple_1: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Purple_01.jpg`,
    rateMeter_purple_2: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Purple_02.jpg`,
    rateMeter_purple_3: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Purple_03.jpg`,
    rateMeter_purple_4: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Purple_04.jpg`,
    rateMeter_purple_5: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Purple_05.jpg`,
    rateMeter_purple_6: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Purple_06.jpg`,
    rateMeter_purple_7: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Purple_07.jpg`,
    rateMeter_purple_8: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Purple_08.jpg`,
    rateMeter_purple_9: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Purple_09.jpg`,
    rateMeter_purple_10: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Purple_10.jpg`,
    rateMeter_red_0: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Red_00.jpg`,
    rateMeter_red_1: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Red_01.jpg`,
    rateMeter_red_2: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Red_02.jpg`,
    rateMeter_red_3: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Red_03.jpg`,
    rateMeter_red_4: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Red_04.jpg`,
    rateMeter_red_5: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Red_05.jpg`,
    rateMeter_red_6: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Red_06.jpg`,
    rateMeter_red_7: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Red_07.jpg`,
    rateMeter_red_8: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Red_08.jpg`,
    rateMeter_red_9: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Red_09.jpg`,
    rateMeter_red_10: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Red_10.jpg`,
    rateMeter_turquoise_0: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Turquoise_00.jpg`,
    rateMeter_turquoise_1: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Turquoise_01.jpg`,
    rateMeter_turquoise_2: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Turquoise_02.jpg`,
    rateMeter_turquoise_3: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Turquoise_03.jpg`,
    rateMeter_turquoise_4: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Turquoise_04.jpg`,
    rateMeter_turquoise_5: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Turquoise_05.jpg`,
    rateMeter_turquoise_6: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Turquoise_06.jpg`,
    rateMeter_turquoise_7: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Turquoise_07.jpg`,
    rateMeter_turquoise_8: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Turquoise_08.jpg`,
    rateMeter_turquoise_9: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Turquoise_09.jpg`,
    rateMeter_turquoise_10: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Turquoise_10.jpg`,
    rateMeter_yellow_0: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Yellow_00.jpg`,
    rateMeter_yellow_1: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Yellow_01.jpg`,
    rateMeter_yellow_2: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Yellow_02.jpg`,
    rateMeter_yellow_3: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Yellow_03.jpg`,
    rateMeter_yellow_4: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Yellow_04.jpg`,
    rateMeter_yellow_5: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Yellow_05.jpg`,
    rateMeter_yellow_6: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Yellow_06.jpg`,
    rateMeter_yellow_7: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Yellow_07.jpg`,
    rateMeter_yellow_8: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Yellow_08.jpg`,
    rateMeter_yellow_9: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Yellow_09.jpg`,
    rateMeter_yellow_10: `${base_images_url}images/TeamStrengthsReports/RateMeters/RateMeter_Yellow_10.jpg`,

    icon_Catalyst: `${base_images_url}images/TeamStrengthsReports/TeamStrengthIcons/Catalyst_Summary_Icon_1.png`, // TODO: remove the "_1" in the images repo
    icon_Explorer: `${base_images_url}images/TeamStrengthsReports/TeamStrengthIcons/Explorer_Summary_Icon.png`,
    icon_Guide: `${base_images_url}images/TeamStrengthsReports/TeamStrengthIcons/Guide_Summary_Icon.png`,
    icon_Harmoniser: `${base_images_url}images/TeamStrengthsReports/TeamStrengthIcons/Harmoniser_Summary_Icon.png`,
    icon_Perfector: `${base_images_url}images/TeamStrengthsReports/TeamStrengthIcons/Perfector_Summary_Icon.png`,
    icon_Pragmatist: `${base_images_url}images/TeamStrengthsReports/TeamStrengthIcons/Pragmatist_Summary_Icon.png`,
    icon_Producer: `${base_images_url}images/TeamStrengthsReports/TeamStrengthIcons/Producer_Summary_Icon.png`,
    icon_Visionary: `${base_images_url}images/TeamStrengthsReports/TeamStrengthIcons/Visionary_Summary_Icon.png`,

    wheelIcon_1: `${base_images_url}images/TeamStrengthsReports/WheelIcons/TSR_Icon_Middle_01.jpg`,
    wheelIcon_2: `${base_images_url}images/TeamStrengthsReports/WheelIcons/TSR_Icon_Middle_02.jpg`,
    wheelIcon_3: `${base_images_url}images/TeamStrengthsReports/WheelIcons/TSR_Icon_Middle_03.jpg`,
    wheelIcon_4: `${base_images_url}images/TeamStrengthsReports/WheelIcons/TSR_Icon_Middle_04.jpg`,
  },
  leadershipStrengthsReports: {
    icon_People: `${base_images_url}images/LeadershipStrengthsReports/LeadershipStrengthIcons/People_Summary_Icon.png`,
    icon_Tasks: `${base_images_url}images/LeadershipStrengthsReports/LeadershipStrengthIcons/Tasks_Summary_Icon.png`,
    icon_TheWay: `${base_images_url}images/LeadershipStrengthsReports/LeadershipStrengthIcons/TheWay_Summary_Icon.png`,
    icon_Yourself: `${base_images_url}images/LeadershipStrengthsReports/LeadershipStrengthIcons/Yourself_Summary_Icon.png`,

    scoreMarker_1_Fill: `${base_images_url}images/LeadershipStrengthsReports/ScoreMarkers/Area_1_Marker_Fill.jpg`,
    scoreMarker_1_NoFill: `${base_images_url}images/LeadershipStrengthsReports/ScoreMarkers/Area_1_Marker_NoFill.jpg`,
    scoreMarker_1_Ring: `${base_images_url}images/LeadershipStrengthsReports/ScoreMarkers/Area_1_Marker_Ring.jpg`,
    scoreMarker_2_Fill: `${base_images_url}images/LeadershipStrengthsReports/ScoreMarkers/Area_2_Marker_Fill.jpg`,
    scoreMarker_2_NoFill: `${base_images_url}images/LeadershipStrengthsReports/ScoreMarkers/Area_2_Marker_NoFill.jpg`,
    scoreMarker_2_Ring: `${base_images_url}images/LeadershipStrengthsReports/ScoreMarkers/Area_2_Marker_Ring.jpg`,
    scoreMarker_3_Fill: `${base_images_url}images/LeadershipStrengthsReports/ScoreMarkers/Area_3_Marker_Fill.jpg`,
    scoreMarker_3_NoFill: `${base_images_url}images/LeadershipStrengthsReports/ScoreMarkers/Area_3_Marker_NoFill.jpg`,
    scoreMarker_3_Ring: `${base_images_url}images/LeadershipStrengthsReports/ScoreMarkers/Area_3_Marker_Ring.jpg`,
    scoreMarker_4_Fill: `${base_images_url}images/LeadershipStrengthsReports/ScoreMarkers/Area_4_Marker_Fill.jpg`,
    scoreMarker_4_NoFill: `${base_images_url}images/LeadershipStrengthsReports/ScoreMarkers/Area_4_Marker_NoFill.jpg`,
    scoreMarker_4_Ring: `${base_images_url}images/LeadershipStrengthsReports/ScoreMarkers/Area_4_Marker_Ring.jpg`,
  },
  salesStrengthsReports: {
    icon_Grit: `${base_images_url}images/SalesStrengthsReports/SalesStrengthsIcons/Grit_Summary_Icon.png`,
    icon_Empathy: `${base_images_url}images/SalesStrengthsReports/SalesStrengthsIcons/Empathy_Summary_Icon.png`,
    icon_Appetite: `${base_images_url}images/SalesStrengthsReports/SalesStrengthsIcons/Appetite_Summary_Icon.png`,
    icon_Focus: `${base_images_url}images/SalesStrengthsReports/SalesStrengthsIcons/Focus_Summary_Icon.png`,
  },
  insightsReports: {
    score_marker_1: `${base_images_url}images/InsightsReports/Score_Marker_01.jpg`,
    score_marker_2: `${base_images_url}images/InsightsReports/Score_Marker_02.jpg`,
    score_marker_3: `${base_images_url}images/InsightsReports/Score_Marker_03.jpg`,
    score_marker_4: `${base_images_url}images/InsightsReports/Score_Marker_04.jpg`,
    score_marker_5: `${base_images_url}images/InsightsReports/Score_Marker_05.jpg`,
    score_marker_6: `${base_images_url}images/InsightsReports/Score_Marker_06.jpg`,
    score_marker_7: `${base_images_url}images/InsightsReports/Score_Marker_07.jpg`,
    score_marker_8: `${base_images_url}images/InsightsReports/Score_Marker_08.jpg`,
    score_marker_9: `${base_images_url}images/InsightsReports/Score_Marker_09.jpg`,
    score_marker_10: `${base_images_url}images/InsightsReports/Score_Marker_10.jpg`,
    marker_green_1: `${base_images_url}images/InsightsReports/Green_Marker_01.jpg`,
    marker_green_2: `${base_images_url}images/InsightsReports/Green_Marker_02.jpg`,
    marker_green_3: `${base_images_url}images/InsightsReports/Green_Marker_03.jpg`,
    marker_green_4: `${base_images_url}images/InsightsReports/Green_Marker_04.jpg`,
    marker_green_5: `${base_images_url}images/InsightsReports/Green_Marker_05.jpg`,
    marker_green_6: `${base_images_url}images/InsightsReports/Green_Marker_06.jpg`,
    marker_green_7: `${base_images_url}images/InsightsReports/Green_Marker_07.jpg`,
    marker_green_8: `${base_images_url}images/InsightsReports/Green_Marker_08.jpg`,
    marker_green_9: `${base_images_url}images/InsightsReports/Green_Marker_09.jpg`,
    marker_green_10: `${base_images_url}images/InsightsReports/Green_Marker_10.jpg`,
    marker_blue_1: `${base_images_url}images/InsightsReports/Blue_Marker_01.jpg`,
    marker_blue_2: `${base_images_url}images/InsightsReports/Blue_Marker_02.jpg`,
    marker_blue_3: `${base_images_url}images/InsightsReports/Blue_Marker_03.jpg`,
    marker_blue_4: `${base_images_url}images/InsightsReports/Blue_Marker_04.jpg`,
    marker_blue_5: `${base_images_url}images/InsightsReports/Blue_Marker_05.jpg`,
    marker_blue_6: `${base_images_url}images/InsightsReports/Blue_Marker_06.jpg`,
    marker_blue_7: `${base_images_url}images/InsightsReports/Blue_Marker_07.jpg`,
    marker_blue_8: `${base_images_url}images/InsightsReports/Blue_Marker_08.jpg`,
    marker_blue_9: `${base_images_url}images/InsightsReports/Blue_Marker_09.jpg`,
    marker_blue_10: `${base_images_url}images/InsightsReports/Blue_Marker_10.jpg`,
    marker_darkblue_1: `${base_images_url}images/InsightsReports/DarkBlue_Marker_01.jpg`,
    marker_darkblue_2: `${base_images_url}images/InsightsReports/DarkBlue_Marker_02.jpg`,
    marker_darkblue_3: `${base_images_url}images/InsightsReports/DarkBlue_Marker_03.jpg`,
    marker_darkblue_4: `${base_images_url}images/InsightsReports/DarkBlue_Marker_04.jpg`,
    marker_darkblue_5: `${base_images_url}images/InsightsReports/DarkBlue_Marker_05.jpg`,
    marker_darkblue_6: `${base_images_url}images/InsightsReports/DarkBlue_Marker_06.jpg`,
    marker_darkblue_7: `${base_images_url}images/InsightsReports/DarkBlue_Marker_07.jpg`,
    marker_darkblue_8: `${base_images_url}images/InsightsReports/DarkBlue_Marker_08.jpg`,
    marker_darkblue_9: `${base_images_url}images/InsightsReports/DarkBlue_Marker_09.jpg`,
    marker_darkblue_10: `${base_images_url}images/InsightsReports/DarkBlue_Marker_10.jpg`,
    marker_orange_1: `${base_images_url}images/InsightsReports/Orange_Marker_01.jpg`,
    marker_orange_2: `${base_images_url}images/InsightsReports/Orange_Marker_02.jpg`,
    marker_orange_3: `${base_images_url}images/InsightsReports/Orange_Marker_03.jpg`,
    marker_orange_4: `${base_images_url}images/InsightsReports/Orange_Marker_04.jpg`,
    marker_orange_5: `${base_images_url}images/InsightsReports/Orange_Marker_05.jpg`,
    marker_orange_6: `${base_images_url}images/InsightsReports/Orange_Marker_06.jpg`,
    marker_orange_7: `${base_images_url}images/InsightsReports/Orange_Marker_07.jpg`,
    marker_orange_8: `${base_images_url}images/InsightsReports/Orange_Marker_08.jpg`,
    marker_orange_9: `${base_images_url}images/InsightsReports/Orange_Marker_09.jpg`,
    marker_orange_10: `${base_images_url}images/InsightsReports/Orange_Marker_10.jpg`,
    marker_purple_1: `${base_images_url}images/InsightsReports/Purple_Marker_01.jpg`,
    marker_purple_2: `${base_images_url}images/InsightsReports/Purple_Marker_02.jpg`,
    marker_purple_3: `${base_images_url}images/InsightsReports/Purple_Marker_03.jpg`,
    marker_purple_4: `${base_images_url}images/InsightsReports/Purple_Marker_04.jpg`,
    marker_purple_5: `${base_images_url}images/InsightsReports/Purple_Marker_05.jpg`,
    marker_purple_6: `${base_images_url}images/InsightsReports/Purple_Marker_06.jpg`,
    marker_purple_7: `${base_images_url}images/InsightsReports/Purple_Marker_07.jpg`,
    marker_purple_8: `${base_images_url}images/InsightsReports/Purple_Marker_08.jpg`,
    marker_purple_9: `${base_images_url}images/InsightsReports/Purple_Marker_09.jpg`,
    marker_purple_10: `${base_images_url}images/InsightsReports/Purple_Marker_10.jpg`,
    marker_red_1: `${base_images_url}images/InsightsReports/Red_Marker_01.jpg`,
    marker_red_2: `${base_images_url}images/InsightsReports/Red_Marker_02.jpg`,
    marker_red_3: `${base_images_url}images/InsightsReports/Red_Marker_03.jpg`,
    marker_red_4: `${base_images_url}images/InsightsReports/Red_Marker_04.jpg`,
    marker_red_5: `${base_images_url}images/InsightsReports/Red_Marker_05.jpg`,
    marker_red_6: `${base_images_url}images/InsightsReports/Red_Marker_06.jpg`,
    marker_red_7: `${base_images_url}images/InsightsReports/Red_Marker_07.jpg`,
    marker_red_8: `${base_images_url}images/InsightsReports/Red_Marker_08.jpg`,
    marker_red_9: `${base_images_url}images/InsightsReports/Red_Marker_09.jpg`,
    marker_red_10: `${base_images_url}images/InsightsReports/Red_Marker_10.jpg`,
  },
  interactiveFeedback: {
    defaultScaleImage: `${base_images_url}images/InteractiveFeedback/IF_MyStrengths_StrengthDesc_01.jpg`,
    defaultScaleColour: '#998064',
    defaultScaleIcon: '#5D5642',
    defaultMyStrengthsMenuIcon: `${base_images_url}images/InteractiveFeedback/Icons/IF_MyStrengths_07.png`,
    defaultMyStrengthsCardImage: `${base_images_url}images/InteractiveFeedback/Img_MyDevelopment_Adapting_03.jpg`,
  },
  myStrengthsReportTiles: {
    tile_01: `${base_images_url}images/InteractiveFeedback/IF_MyStrengths_StrengthDesc_01.jpg`,
    tile_02: `${base_images_url}images/InteractiveFeedback/IF_MyStrengths_StrengthDesc_02.jpg`,
    tile_03: `${base_images_url}images/InteractiveFeedback/IF_MyStrengths_StrengthDesc_03.jpg`,
    tile_04: `${base_images_url}images/InteractiveFeedback/IF_MyStrengths_StrengthDesc_04.jpg`,
    tile_05: `${base_images_url}images/InteractiveFeedback/IF_MyStrengths_StrengthDesc_05.jpg`,
  },
  /// TODO: update image pools (and add to images repo) once the client provides them
  myDevelopmentReportTiles: {
    tile_01: `${base_images_url}images/InteractiveFeedback/IF_MyStrengths_StrengthDesc_01.jpg`,
    tile_02: `${base_images_url}images/InteractiveFeedback/IF_MyStrengths_StrengthDesc_02.jpg`,
    tile_03: `${base_images_url}images/InteractiveFeedback/IF_MyStrengths_StrengthDesc_03.jpg`,
    tile_04: `${base_images_url}images/InteractiveFeedback/IF_MyStrengths_StrengthDesc_04.jpg`,
    tile_05: `${base_images_url}images/InteractiveFeedback/IF_MyStrengths_StrengthDesc_05.jpg`,
  },
  myCapabilitiesReportTiles: {
    tile_01: `${base_images_url}images/InteractiveFeedback/IF_MyStrengths_StrengthDesc_01.jpg`,
    tile_02: `${base_images_url}images/InteractiveFeedback/IF_MyStrengths_StrengthDesc_02.jpg`,
    tile_03: `${base_images_url}images/InteractiveFeedback/IF_MyStrengths_StrengthDesc_03.jpg`,
    tile_04: `${base_images_url}images/InteractiveFeedback/IF_MyStrengths_StrengthDesc_04.jpg`,
    tile_05: `${base_images_url}images/InteractiveFeedback/IF_MyStrengths_StrengthDesc_05.jpg`,
  },
  myTeamStrengthsReportTiles: {
    tile_01: `${base_images_url}images/InteractiveFeedback/IF_MyStrengths_StrengthDesc_01.jpg`,
    tile_02: `${base_images_url}images/InteractiveFeedback/IF_MyStrengths_StrengthDesc_02.jpg`,
    tile_03: `${base_images_url}images/InteractiveFeedback/IF_MyStrengths_StrengthDesc_03.jpg`,
    tile_04: `${base_images_url}images/InteractiveFeedback/IF_MyStrengths_StrengthDesc_04.jpg`,
    tile_05: `${base_images_url}images/InteractiveFeedback/IF_MyStrengths_StrengthDesc_05.jpg`,
  },
  myLeadershipStrengthsReportTiles: {
    tile_01: `${base_images_url}images/InteractiveFeedback/IF_MyStrengths_StrengthDesc_01.jpg`,
    tile_02: `${base_images_url}images/InteractiveFeedback/IF_MyStrengths_StrengthDesc_02.jpg`,
    tile_03: `${base_images_url}images/InteractiveFeedback/IF_MyStrengths_StrengthDesc_03.jpg`,
    tile_04: `${base_images_url}images/InteractiveFeedback/IF_MyStrengths_StrengthDesc_04.jpg`,
    tile_05: `${base_images_url}images/InteractiveFeedback/IF_MyStrengths_StrengthDesc_05.jpg`,
  },
  mySalesStrengthsReportTiles: {
    tile_01: `${base_images_url}images/InteractiveFeedback/IF_MyStrengths_StrengthDesc_01.jpg`,
    tile_02: `${base_images_url}images/InteractiveFeedback/IF_MyStrengths_StrengthDesc_02.jpg`,
    tile_03: `${base_images_url}images/InteractiveFeedback/IF_MyStrengths_StrengthDesc_03.jpg`,
    tile_04: `${base_images_url}images/InteractiveFeedback/IF_MyStrengths_StrengthDesc_04.jpg`,
    tile_05: `${base_images_url}images/InteractiveFeedback/IF_MyStrengths_StrengthDesc_05.jpg`,
  },
  ///
};
