import * as Yup from 'yup';
import { TextInput } from 'components/formFields/TextInput';
import { FormSingleSelectCustomField } from '../CustomFormFields/FormSingleSelectCustomField';
import { Form, Formik } from 'formik';
import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import ProcessStepTextAndImageRow from '../ProcessStepTextAndImageRow';
import RowIcon from 'assets/apply.clevry/MP_Skills_Salary_01.png';
import { Spinner } from 'components/spinner/Spinner';
import {
  getSalaryAspirationDetails,
  parseSalaryAspirationDetails,
  parseSalaryAspirationPayload,
  submitSalaryAspirationPayload,
} from '../../MatcingProcessApi';
import i18n from 'i18n/i18n';
import { useTranslation } from 'react-i18next';
import {
  MatchingProcessState,
  selectMatchingProcess,
  setMatchingProcessDataSubmittedToggle,
  setMatchingProcessSubmitSuccess,
} from 'reducers/matchingProcess';
import { useDispatch, useSelector } from 'react-redux';
import { GAMatchingProcessLatest } from 'helpers/gaEvents';

type SalaryAspirationPageProps = {
  outerSubmitForm: MutableRefObject<
    (() => Promise<void>) & (() => Promise<any>)
  >;
  setNextStepDisabled: Dispatch<SetStateAction<boolean>>;
  setGeneralError: Dispatch<SetStateAction<string | undefined>>;
};

type formikValues = {
  minimumSalaryAspiration: number | null;
  maximumSalaryAspiration: number | null;
  salaryAspirationCurrencyId: currencyObjValues | null;
};

type currencyObjValues = {
  optionId: string;
  optionText: string;
};

const SalaryAspirationPage: React.FC<SalaryAspirationPageProps> = ({
  outerSubmitForm,
  setNextStepDisabled,
  setGeneralError,
}) => {
  const latestLocation = useRef('');

  if (
    window &&
    window.location &&
    window.location.href !== latestLocation.current
  ) {
    latestLocation.current = window.location.href;
    GAMatchingProcessLatest(window.location.href);
  }

  const { t } = useTranslation('Matching+Process', { i18n });

  const [currencyOptions, setCurrencyOptions] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [initialValues, setInitialValues] = useState<formikValues>({
    minimumSalaryAspiration: null,
    maximumSalaryAspiration: null,
    salaryAspirationCurrencyId: null,
  });
  const [menuOptions, setMenuOptions] = useState([
    {
      label: null,
      value: null,
      name: null,
    },
  ]);

  const validationSchema = Yup.object({
    minimumSalaryAspiration: Yup.number().positive().required(),
    maximumSalaryAspiration: Yup.number()
      .positive()
      .required()
      .moreThan(Yup.ref('minimumSalaryAspiration')),
    salaryAspirationCurrencyId: Yup.number().required(),
  });

  const dispatch = useDispatch();

  const matchingProcessDetails: MatchingProcessState = useSelector(
    selectMatchingProcess
  );

  const handleSubmit = (values: formikValues) => {
    const payload = parseSalaryAspirationPayload(values);

    return (async () => {
      const result = await submitSalaryAspirationPayload(payload);
      if (result.response) {
        setGeneralError('');

        dispatch(setMatchingProcessSubmitSuccess(true));

        dispatch(
          setMatchingProcessDataSubmittedToggle(
            !matchingProcessDetails.dataSubmittedToggle
          )
        );

        return true;
      } else {
        dispatch(setMatchingProcessSubmitSuccess(false));

        setGeneralError(t('SSCandidate_Matching_Process_General_Error'));
        return false;
      }
    })();
  };

  const fetchDetails = async () => {
    const result = await getSalaryAspirationDetails();
    if (result.response) {
      const parsedResult = parseSalaryAspirationDetails(result.response);

      if (parsedResult.salaryAspirations) {
        setInitialValues(parsedResult.salaryAspirations);
      }
      setMenuOptions(parsedResult.currencies);

      setGeneralError('');
      setLoading(false);
    } else {
      setLoading(false);
      setGeneralError(t('SSCandidate_Matching_Process_General_Error_Reload'));
    }
  };

  useEffect(() => {
    fetchDetails();
  }, []);

  if (loading) return <Spinner />;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
      validateOnMount
    >
      {({
        setFieldValue,
        values,
        errors,
        touched,
        setValues,
        submitForm,
        isValid,
      }) => {
        outerSubmitForm.current = submitForm;
        if (isValid) {
          setNextStepDisabled(false);
        } else {
          setNextStepDisabled(true);
        }

        return (
          <>
            <Form className="SalaryAspirationPage MatchingProcessForm MatchingProcessInnerPage">
              <div className="MatchingProcess__sectionHeader">
                <div className="MatchingProcess__sectionHeading">
                  {t('SSCandidate_Matching_Process_Salary_Aspiration_Heading')}
                </div>
                <div className="MatchingProcess__sectionSubheading">
                  <span>
                    {t(
                      'SSCandidate_Matching_Process_Salary_Aspiration_SubHeading1'
                    )}
                  </span>
                  <span>
                    {t(
                      'SSCandidate_Matching_Process_Salary_Aspiration_SubHeading2'
                    )}
                  </span>
                </div>
              </div>
              <ProcessStepTextAndImageRow icon={RowIcon}>
                <TextInput
                  name={`minimumSalaryAspiration`}
                  label={t(
                    'SSCandidate_Matching_Process_Salary_Aspiration_Field_Label1'
                  )}
                  maxLength={10}
                  type={'number'}
                />

                <TextInput
                  name={`maximumSalaryAspiration`}
                  label={t(
                    'SSCandidate_Matching_Process_Salary_Aspiration_Field_Label2'
                  )}
                  maxLength={10}
                  type={'number'}
                />

                <FormSingleSelectCustomField
                  fieldName={`salaryAspirationCurrencyId`}
                  label={t(
                    'SSCandidate_Matching_Process_Salary_Aspiration_Field_Label3'
                  )}
                  options={menuOptions.map((item: any) => {
                    return { label: item.label, value: item.value };
                  })}
                  useNumberForValue={true}
                />
              </ProcessStepTextAndImageRow>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

export default SalaryAspirationPage;
