/** @jsxImportSource @emotion/react */
import { navigate, Redirect } from '@reach/router';
import { paths } from 'appConstants';
import { ThreeBounce } from 'better-react-spinkit';
import { Button } from 'components/button/Button';
import { CheckboxInput } from 'components/formFields/CheckboxInput';
import { PasswordStrengthMeter } from 'components/formFields/PasswordStrengthMeter';
import { TextInput } from 'components/formFields/TextInput';
import { handleProjectToken } from 'components/projectToken/projectToken';
import { Form, Formik, FormikProps } from 'formik';
import React, { useContext, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearAuthMessage,
  selectApplicationLinkStarted,
  selectApplicationLinkToken,
  selectAuthMessage,
  selectAuthStatus,
  selectHasApplications,
  selectInvitedBy,
  setApplicationLinkStarted,
  setInviteType,
  SignInError,
  signUp,
  SignUpAttempt,
} from 'reducers/auth';
import * as Yup from 'yup';
import { AbilityPracticeTestQuizContext } from '../abilityPracticeTest/AbilityPracticeTest';
import { AbilityPracticeTestContext } from '../abilityPracticeTest/models';
import { FindYourJoyQuizContext } from '../findYourJoy/FindYourJoy';
import { applyForJob } from '../jobBoard/JobBoardApi';
import { decryptApplicationLink } from './signUpApi';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';
import {
  selectSignInRedirectURL,
  setCloseLandingPageForm,
} from 'reducers/landingPage';
import {
  selectApplicationType,
  selectProjectId,
} from 'reducers/virtualAssessments';

type SignUpFormProps = {
  switchToSignIn: () => void;
  hasCompletedQuiz?: boolean;
  inviteType?: string | null;
  isGenericSignUp?: boolean;
};

const SignUpForm: React.FC<SignUpFormProps> = ({
  switchToSignIn,
  hasCompletedQuiz,
  inviteType,
  isGenericSignUp = false,
}) => {
  const { t } = useTranslation('Sign+Up+Form', { i18n });

  const dispatch = useDispatch();
  const authStatus = useSelector(selectAuthStatus);
  const signInErrors = useSelector(selectAuthMessage);
  const invitedById = useSelector(selectInvitedBy);
  const applicationLinkToken = useSelector(selectApplicationLinkToken);
  const applicationLinkStarted = useSelector(selectApplicationLinkStarted);
  const hasApplications = useSelector(selectHasApplications);
  const applicationType = useSelector(selectApplicationType);
  const projectID = useSelector(selectProjectId);
  const landingRedirectURL = useSelector(selectSignInRedirectURL);

  const closeLandingPageForm = () => dispatch(setCloseLandingPageForm());

  const formikRef =
    useRef<FormikProps<SignUpAttempt & { agreeToShare: boolean }>>(null);

  const findYourJoyContext = useContext(FindYourJoyQuizContext);
  const abilityPracticeTestQuizContext = useContext(
    AbilityPracticeTestQuizContext
  ) as AbilityPracticeTestContext;

  useEffect(() => {
    dispatch(clearAuthMessage());
  }, [dispatch]);

  useEffect(() => {
    if (
      formikRef.current &&
      signInErrors &&
      Array.isArray(signInErrors) &&
      signInErrors.length > 0
    ) {
      signInErrors.forEach((errorItem: SignInError) => {
        const parsedFieldName =
          errorItem.fieldName[0].toLowerCase() +
          (errorItem.fieldName.length > 1 ? errorItem.fieldName.slice(1) : '');

        const translatedMessage = errorMessages[
          errorItem.messageCode as keyof typeof errorMessages
        ]
          ? errorMessages[errorItem.messageCode as keyof typeof errorMessages]
          : errorItem.messageCode;

        formikRef.current?.setFieldError(parsedFieldName, translatedMessage);
      });
    }
  }, [signInErrors]);

  useEffect(() => {
    handleProjectToken(
      authStatus,
      landingRedirectURL,
      applicationLinkToken,
      applicationLinkStarted,
      setApplicationLinkStarted,
      dispatch,
      hasApplications,
      closeLandingPageForm, // prevent sign up form rendering again after submit
      applicationType,
      null,
      projectID,
      null,
      null,
      false
    );
  }, [authStatus, applicationLinkStarted, hasApplications]);

  const handleSubmit = (values: SignUpAttempt) => {
    dispatch(
      signUp({
        ...values,
        inviteToken: invitedById ? invitedById : undefined,
        inviteType: inviteType ? inviteType : null,
      })
    );
  };

  const checkPreTestTaken = () => {
    let testTaken = '';

    if (abilityPracticeTestQuizContext) {
      testTaken = 'AbilityTest';
    }

    if (findYourJoyContext) {
      testTaken = 'SoftSkillsQuiz';
    }

    return testTaken;
  };

  const initialValues: SignUpAttempt & { agreeToShare: boolean } = {
    firstName: '',
    lastName: '',
    emailAddress: '',
    password: '',
    questionAnswers: findYourJoyContext ? findYourJoyContext.answers : [],
    preRegistrationTestType: checkPreTestTaken(),
    agreeToShare: false,
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required(
      t('SSCandidate_Sign_Up_Form_Validation_Errors_Required')
    ),
    lastName: Yup.string().required(
      t('SSCandidate_Sign_Up_Form_Validation_Errors_Required')
    ),
    emailAddress: Yup.string()
      .required(t('SSCandidate_Sign_Up_Form_Validation_Email_Address_Required'))
      .email(t('SSCandidate_Sign_Up_Form_Validation_Email_Address_Valid')),
    password: Yup.string().required(
      t('SSCandidate_Sign_Up_Form_Validation_Password')
    ),
    agreeToShare: Yup.boolean(),
  });

  const errorMessages = {
    required: t('SSCandidate_Sign_Up_Form_Errors_Required'),
    generic: t('SSCandidate_Sign_Up_Form_Errors_Generic'),
    applicationError: t('SSCandidate_Sign_Up_Form_Errors_Application_Error'),
    Account_In_Use: t('SSCandidate_Sign_Up_Form_Errors_Account_In_Use'),
    Invalid_Password_Length: t(
      'SSCandidate_Sign_Up_Form_Errors_Invalid_Password_Length'
    ),
    Password_Policy_No_Match: t(
      'SSCandidate_Sign_Up_Form_Errors_Password_Policy_No_Match'
    ),
    Account_Locked: t('SSCandidate_Sign_Up_Form_Errors_Account_Locked'),
    Username_Or_Password_Incorrect: t(
      'SSCandidate_Sign_Up_Form_Errors_Username_Or_Password_Incorrect'
    ),
    EmailAddress_In_Use: t(
      'SSCandidate_Sign_Up_Form_Errors_Email_Address_In_Use'
    ),
  };

  const parseClientName = (clientName: string | null) => {
    if (!clientName) return null;

    let parsedClientName = clientName.replaceAll('-', ' ');

    parsedClientName = parsedClientName.replace(
      /(^\w{1})|(\s+\w{1})/g,
      (letter) => letter.toUpperCase()
    );

    return parsedClientName;
  };

  const jobRedirectClientName = parseClientName(
    localStorage.getItem('jobRedirectClientName')
  );

  return (
    <div className="LoginTable">
      <div className="LoginCell SignUpCell">
        <h2 className="FormTitle">
          {hasCompletedQuiz
            ? t('SSCandidate_Sign_Up_Form_Sign_Up_Panel_Title_1')
            : !jobRedirectClientName
            ? t('SSCandidate_Sign_Up_Form_Sign_Up_Panel_Title_2')
            : t('SSCandidate_Sign_Up_Form_Sign_Up_Panel_Title_3', {
                clientName: jobRedirectClientName,
              })}
        </h2>
        {hasCompletedQuiz && (
          <p className="">
            {t('SSCandidate_Sign_Up_Form_Sign_Up_Panel_Completed_Quiz_Text')}
          </p>
        )}
        <div className="LoginForm">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            innerRef={formikRef}
          >
            {(props) => (
              <Form>
                <div className="FormBox SideBySide">
                  <TextInput
                    className="EditBox"
                    name="firstName"
                    label={t(
                      'SSCandidate_Sign_Up_Form_Sign_Up_Panel_First_Name'
                    )}
                  />
                  <TextInput
                    className="EditBox"
                    name="lastName"
                    label={t(
                      'SSCandidate_Sign_Up_Form_Sign_Up_Panel_Last_Name'
                    )}
                  />
                </div>
                <div className="FormBox">
                  <TextInput
                    autoComplete="email"
                    className="EditBox"
                    name="emailAddress"
                    label={t(
                      'SSCandidate_Sign_Up_Form_Sign_Up_Panel_Email_Address'
                    )}
                  />
                </div>
                <div className="FormBox">
                  <TextInput
                    autoComplete="current-password"
                    className="EditBox"
                    name="password"
                    type="password"
                    label={t('SSCandidate_Sign_Up_Form_Sign_Up_Panel_Password')}
                    isPassword={true}
                  />
                  <PasswordStrengthMeter fieldName="password" />
                </div>
                {/* <div>
                  <p className="SignInErrorMessage">
                    {message &&
                    errorMessages[message as keyof typeof errorMessages]
                      ? errorMessages[message as keyof typeof errorMessages]
                      : message}
                  </p>
                </div> */}
                <div className="CheckboxContainer">
                  <div>
                    <CheckboxInput label="" name="agreeToShare" />
                  </div>
                  <div>
                    <span className="Disclaimer">
                      {applicationType === 'PRACTICE_TEST_APPLICATION'
                        ? t('SSCandidate_Sign_Up_Form_Sign_In_Sign_Up_Terms_2')
                        : t(
                            'SSCandidate_Sign_Up_Form_Sign_In_Sign_Up_Terms_1',
                            {
                              clientName: jobRedirectClientName
                                ? jobRedirectClientName
                                : 'Clevry',
                            }
                          )}
                    </span>
                  </div>
                </div>
                <Button
                  priority="primary"
                  type="submit"
                  disabled={
                    authStatus === 'pending' ||
                    !props.isValid ||
                    !props.values.agreeToShare
                  }
                  className="Btn SignUpButton"
                  isPublic
                >
                  {authStatus === 'pending' ? (
                    <ThreeBounce color="white" size={15} />
                  ) : (
                    t('SSCandidate_Sign_Up_Form_Sign_Up_Panel_Button')
                  )}
                </Button>
                <div
                  className="DisclaimerContainer"
                  dangerouslySetInnerHTML={{
                    __html: t(
                      'SSCandidate_Sign_Up_Form_Sign_Up_Panel_Disclaimer',
                      {
                        clientName: jobRedirectClientName
                          ? jobRedirectClientName
                          : 'Clevry',
                      }
                    ),
                  }}
                />
              </Form>
            )}
          </Formik>

          {!isGenericSignUp && (
            <div className="SignUpLinkContainer">
              <p>{t('SSCandidate_Sign_Up_Form_Sign_Up_Panel_Have_Account')}</p>
              <button onClick={switchToSignIn} className="InlineLink">
                {t('SSCandidate_Sign_Up_Form_Sign_Up_Panel_Sign_In')}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export { SignUpForm };
