import { Button } from 'components/button/Button';
import React, { SetStateAction } from 'react';
import { FormType } from '../signIn/Landing';
import Illustration from 'assets/new_skin/Img_NewFindYourJoy_02.png';
import LockIcon from 'assets/new_skin/lock.svg';
import UnlockIcon from 'assets/new_skin/unlock.svg';
import Pointer from 'assets/new_skin/pointer.svg';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';

type JourneyPromptProps = {
  setCurrentlyOpenForm: React.Dispatch<SetStateAction<FormType | null>>;
};

export const FindYourJoyJourneyPrompt: React.FC<JourneyPromptProps> = ({
  setCurrentlyOpenForm,
}) => {
  const { t } = useTranslation('Find+Your+Joy+Journey+Prompt', { i18n });

  const prompt_items_public = [
    t('SSCandidate_Find_Your_Joy_Journey_Prompt_Items_Public_1'),
    t('SSCandidate_Find_Your_Joy_Journey_Prompt_Items_Public_2'),
    t('SSCandidate_Find_Your_Joy_Journey_Prompt_Items_Public_3'),
    t('SSCandidate_Find_Your_Joy_Journey_Prompt_Items_Public_4'),
    t('SSCandidate_Find_Your_Joy_Journey_Prompt_Items_Public_5'),
  ];

  return (
    <div className={`JourneyPromptSection Section Public`}>
      {/* <div className="WidthContent"> */}
      <div className="JourneyPrompt BreakColorTheme">
        <div className="FlexContainer JourneyFlexContainer">
          <div className="IllustrationContainer">
            <img
              src={Illustration}
              alt={t(
                'SSCandidate_Find_Your_Joy_Journey_Prompt_Alt_Illustration'
              )}
              className="Illustration"
            />
          </div>
          <div className="TextSection">
            <h2 className="JourneyPromptTitle">
              {t('SSCandidate_Find_Your_Joy_Journey_Prompt_Heading_Public')}
            </h2>
            <p className="JourneyPromptDescription">
              {t('SSCandidate_FInd_Your_Joy_Journey_Prompt_Description_Public')}
              {/* {t('SSCandidate_Find_Your_Joy_Journey_Prompt_Prompt_Public')} */}
            </p>
          </div>
          <div>
            <div className="ButtonContainer">
              <Button
                priority="primary"
                isPublic
                className="SignUpButton"
                onClick={() => setCurrentlyOpenForm(FormType.SIGN_UP)}
              >
                {t('SSCandidate_Find_Your_Joy_Journey_Prompt_Button_Public')}
              </Button>
              <img src={Pointer} className="Pointer" />
            </div>
            <p className="SignInText">
              {t('SSCandidate_Find_Your_Joy_Journey_Prompt_Sign_In_Text')}{' '}
              <button
                className="InlineLink SignInText"
                onClick={() => setCurrentlyOpenForm(FormType.SIGN_IN)}
              >
                {t('SSCandidate_Find_Your_Joy_Journey_Prompt_Sign_In')}
              </button>
            </p>
          </div>
        </div>
        <p className="JourneyPromptDescription">
          {t('SSCandidate_Find_Your_Joy_Journey_Prompt_Items_Header')}
        </p>
        <div className="ProgressItems">
          {prompt_items_public.map((item, index) => (
            <div className="JourneyItem" key={`journey-item-${index}`}>
              <div className="BarContainer" key={`bar-container-${index}`}>
                <hr />
                {index === 0 && (
                  <div className="FilledBar" key={`filled-bar-${index}`} />
                )}
              </div>
              <div className="TextContainer" key={`text-container-${index}`}>
                <div
                  className="StepTextContainer"
                  key={`step-text-container-${index}`}
                >
                  <img
                    src={index === 0 ? UnlockIcon : LockIcon}
                    className="Icon"
                    key={`icon-${index}`}
                  />
                  <p className="StepText" key={`step-text-${index}`}>
                    {`${t('SSCandidate_Find_Your_Joy_Journey_Prompt_Step')} ${
                      index + 1
                    }`}
                    .
                  </p>
                </div>
                <p>{item}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};
