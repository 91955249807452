import { isFuture } from 'date-fns';

const ttl: number = 24;

export const getExpiryDate = () => {
  const currentTime = new Date();
  currentTime.setHours(currentTime.getHours() + ttl);
  return currentTime.toISOString();
};

export const addExpiryDate = (content: {}) => {
  const expiryDate = getExpiryDate();

  return {
    ...content,
    expiryDate,
  };
};

export const addExpiryDateAndCultureCode = (
  content: {},
  cultureCode: string
) => {
  const expiryDate = getExpiryDate();

  return {
    ...content,
    cultureCode,
    expiryDate,
  };
};

export const storeInLocalStorage = (content: any, name: string) => {
  content = JSON.stringify(content);
  window.localStorage.setItem(name, content);
};

export const useLocalStorageContentOnlyWithExpiryDate = (content: any) => {
  if (content && isFuture(new Date(content.expiryDate))) return true;
  return false;
};

export const useLocalStorageContent = (
  content: any,
  cultureCode: string | null
) => {
  if (
    content &&
    cultureCode &&
    content.cultureCode === cultureCode &&
    isFuture(new Date(content.expiryDate))
  )
    return true;
  return false;
};

export const getItemFromLocalStorage = <T = any>(item: string): T | null => {
  const content = window.localStorage.getItem(item);
  return content && JSON.parse(content);
};

export const storeCultureCodeInLocalStorage = (cultureCode: string) =>
  window.localStorage.setItem('cultureCode', cultureCode);

export const getCultureCodeFromLocalStorage = () =>
  window.localStorage.getItem('cultureCode');
