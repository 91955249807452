import React from "react";
import MatchingEditDetailsSidePanel from "./MatchingEditDetailsSidePanel";

type MatchingEditDetailsModalProps = {
  data: any;
};

const MatchingEditDetailsModal: React.FC<MatchingEditDetailsModalProps> = ({
  data,
}) => {
  return (
    <MatchingEditDetailsSidePanel
      data={data}
    />
  );
};

export { MatchingEditDetailsModal };
