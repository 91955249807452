import instance from 'api';
import { AxiosRequestConfig } from 'axios';
import { endpoints } from 'config';

// Get PQ questions
export const getBlendedTestDetails = async (AssessmentProngID: number) => {
  const config: AxiosRequestConfig = {
    params: {
      AssessmentProngID,
    },
  };

  try {
    const res = await instance.get(endpoints.blendedTest.getDetails, config);
    if (res.data.status === '1') {
      return {
        response: res.data,
        errors: null,
      };
    } else {
      return {
        response: null,
        errors: res.data.errors,
      };
    }
  } catch (res) {
    const error: any = res;

    if (error.response && error.response.data && error.response.data.errors) {
      return {
        response: null,
        errors: error.response.data.errors,
      };
    } else {
      return {
        response: null,
        errors: [],
      };
    }
  }
};

// Confirm test started to note start for assessment timer
export const startBlendedTest = async (payload: any) => {
  try {
    const res = await instance.post(endpoints.blendedTest.startTest, payload);
    if (res.data.status === '1') {
      return {
        response: res.data,
        errors: null,
      };
    } else {
      return {
        response: null,
        errors: res.data.errors,
      };
    }
  } catch (res) {
    const error: any = res;

    if (error.response && error.response.data && error.response.data.errors) {
      return {
        response: null,
        errors: error.response.data.errors,
      };
    } else {
      return {
        response: null,
        errors: [],
      };
    }
  }
};

// Submit PQ answers on given PQ page of blended test
export const submitPQAnswers = async (payload: any) => {
  try {
    const res = await instance.post(
      endpoints.blendedTest.setPQAnswers,
      payload
    );
    if (res.data.status === '1') {
      return {
        response: res.data,
        errors: null,
      };
    } else {
      return {
        response: null,
        errors: res.data.errors,
      };
    }
  } catch (res) {
    const error: any = res;

    if (error.response && error.response.data && error.response.data.errors) {
      return {
        response: null,
        errors: error.response.data.errors,
      };
    } else {
      return {
        response: null,
        errors: [],
      };
    }
  }
};

// Submit AT answers on given AT page of blended test
export const submitATAnswers = async (payload: any) => {
  try {
    const res = await instance.post(
      endpoints.blendedTest.setATAnswers,
      payload
    );
    if (res.data.status === '1') {
      return {
        response: res.data,
        errors: null,
      };
    } else {
      return {
        response: null,
        errors: res.data.errors,
      };
    }
  } catch (res) {
    const error: any = res;

    if (error.response && error.response.data && error.response.data.errors) {
      return {
        response: null,
        errors: error.response.data.errors,
      };
    } else {
      return {
        response: null,
        errors: [],
      };
    }
  }
};

// Submit SJT answers on given regular SJT page (choose least/most effective approach) of blended test
export const submitSJTRegularAnswers = async (payload: any) => {
  try {
    const res = await instance.post(
      endpoints.blendedTest.setSJTRegularAnswers,
      payload
    );
    if (res.data.status === '1') {
      return {
        response: res.data,
        errors: null,
      };
    } else {
      return {
        response: null,
        errors: res.data.errors,
      };
    }
  } catch (res) {
    const error: any = res;

    if (error.response && error.response.data && error.response.data.errors) {
      return {
        response: null,
        errors: error.response.data.errors,
      };
    } else {
      return {
        response: null,
        errors: [],
      };
    }
  }
};

// Submit SJT answers on given alternative SJT page (rate individual approaches - similar style to PQ) of blended test
export const submitSJT1_5Answers = async (payload: any) => {
  try {
    const res = await instance.post(
      endpoints.blendedTest.setSJT1_5Answers,
      payload
    );
    if (res.data.status === '1') {
      return {
        response: res.data,
        errors: null,
      };
    } else {
      return {
        response: null,
        errors: res.data.errors,
      };
    }
  } catch (res) {
    const error: any = res;

    if (error.response && error.response.data && error.response.data.errors) {
      return {
        response: null,
        errors: error.response.data.errors,
      };
    } else {
      return {
        response: null,
        errors: [],
      };
    }
  }
};

// Submit MM answers on given alternative SJT page (rate individual approaches - similar style to PQ) of blended test
export const submitMMAnswers = async (payload: any) => {
  try {
    const res = await instance.post(
      endpoints.blendedTest.setMMAnswers,
      payload
    );
    if (res.data.status === '1') {
      return {
        response: res.data,
        errors: null,
      };
    } else {
      return {
        response: null,
        errors: res.data.errors,
      };
    }
  } catch (res) {
    const error: any = res;

    if (error.response && error.response.data && error.response.data.errors) {
      return {
        response: null,
        errors: error.response.data.errors,
      };
    } else {
      return {
        response: null,
        errors: [],
      };
    }
  }
};

// Fully submit test once all questions are completed and end the assessment
export const completeBlendedTest = async (payload: any) => {
  try {
    const res = await instance.post(
      endpoints.blendedTest.completeTest,
      payload
    );
    if (res.data.status === '1') {
      return {
        response: res.data,
        errors: null,
      };
    } else {
      return {
        response: null,
        errors: res.data.errors,
      };
    }
  } catch (res) {
    const error: any = res;

    if (error.response && error.response.data && error.response.data.errors) {
      return {
        response: null,
        errors: error.response.data.errors,
      };
    } else {
      return {
        response: null,
        errors: [],
      };
    }
  }
};
