import React from 'react';

type ProcessStepTextAndImageRowProps = {
  icon: string;
};

const ProcessStepTextAndImageRow: React.FC<ProcessStepTextAndImageRowProps> = ({
  icon,
  children,
}) => {
  return (
    <div className="ProcessStepTextAndImageRow">
      <div className="ProcessStepTextAndImageRow__content">{children}</div>
      <div className="ProcessStepTextAndImageRow__image">
        <img src={icon} alt="Row Icon" />
      </div>
    </div>
  );
};

export default ProcessStepTextAndImageRow;
