// CHANGE MODEL
import { TestsListSearchPayload } from 'components/pages/buyPQTests/models';
import { Store } from './rootReducer';

//Types
export enum FilterAction {
  SET_FILTERS = 'SET_FILTERS',
  SET_SINGLE_FILTER = 'SET_SINGLE_FILTER',
  CLEAR_ALL_FILTERS = 'CLEAR_ALL_FILTERS',
}

export type FilterPayload = any[];
const initialSearchState: FilterPayload = [];

// Actions
export type Action = {
  type:
    | FilterAction.SET_FILTERS
    | FilterAction.SET_SINGLE_FILTER
    | FilterAction.CLEAR_ALL_FILTERS;
  payload: Partial<FilterPayload>;
};

// Reducer
export default function setFilterReducer(
  state = initialSearchState,
  action: Action
): FilterPayload {
  switch (action.type) {
    case FilterAction.SET_FILTERS:
      return {
        ...state,
        ...action.payload,
      };
    case FilterAction.SET_SINGLE_FILTER:
      return {
        ...action.payload,
      };
    case FilterAction.CLEAR_ALL_FILTERS:
      return [{}];
    default:
      return state;
  }
}

// Action creators
export const setFilter = (payload: any) => ({
  type: FilterAction.SET_FILTERS,
  payload,
});
export const setSingleFilter = (payload: any) => ({
  type: FilterAction.SET_SINGLE_FILTER,
  payload,
});

export const clearAllFilters = () => ({
  type: FilterAction.CLEAR_ALL_FILTERS,
});

// Selectors
export const getFilter = (state: Store) => state.setFilter;
