import { ProgressIndicator } from 'components/progressIndicator/progressIndicator';
import useMedia from 'use-media';
import { useSelector } from 'react-redux';
import { selectTrackerData } from 'reducers/assessment';
import { selectAccessibilityData } from 'reducers/accessibility';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';

export const AssessmentHeaderTracker = () => {
  const trackerData = useSelector(selectTrackerData);
  const isPhone = useMedia('(max-width: 768px)');
  const accessibilityData = useSelector(selectAccessibilityData);
  const { t } = useTranslation('Candidate+Journey+-+PQTest', { i18n });

  const getMessage = (question: number, totalQuestions: number) => {
    if (totalQuestions === 0) return t('pPQTest_lblStart');
    const percentageComplete = (question / totalQuestions) * 100;
    const isFinalPage = question === totalQuestions;

    if (percentageComplete === 0) return t('pPQTest_lblStart');
    if (percentageComplete > 0 && percentageComplete <= 25)
      return t('pPQTest_hidKeepGoing1');
    if (percentageComplete > 25 && percentageComplete <= 50)
      return t('pPQTest_hidMoreThanQuarter');
    if (percentageComplete > 50 && percentageComplete <= 60)
      return t('pPQTest_hidHalfwayThere');
    if (percentageComplete > 60 && percentageComplete <= 75)
      return t('pPQTest_hidKeepGoing2');
    if (percentageComplete > 75 && percentageComplete < 100)
      return t('pPQTest_hidNearlyThere');
    if (percentageComplete === 100) return t('pPQTest_lblFinish');
  };

  if (!trackerData) return <div></div>;

  const areQuestions =
    trackerData.currentQuestion !== null &&
    trackerData.currentQuestion !== undefined &&
    trackerData.totalQuestions !== null &&
    trackerData.totalQuestions !== undefined;
  const questionDetails = isPhone
    ? `${trackerData.currentQuestion} / ${trackerData.totalQuestions}`
    : `Question ${trackerData.currentQuestion} out of ${trackerData.totalQuestions}`;

  const motivationalMessage = getMessage(
    trackerData.currentQuestion,
    trackerData.totalQuestions
  );

  return (
    <div className="AssessmentHeaderTracker">
      {!trackerData.onlyShowProgress && (
        <div className="VirtualAssessmentsHeaderTracker__motivationalMessage">
          {motivationalMessage && !isPhone && motivationalMessage}
        </div>
      )}
      {(areQuestions || trackerData.message) && (
        <ProgressIndicator
          value={trackerData.currentQuestion}
          total={trackerData.totalQuestions}
          strokeColor={
            accessibilityData.isSet ? accessibilityData.textColor : '#429CCC'
          }
          radius={25}
          fill={
            accessibilityData.isSet
              ? accessibilityData.backgroundColor
              : '#F5F5F5'
          }
        />
      )}
    </div>
  );
};
