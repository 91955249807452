import { Store } from 'reducers/rootReducer';

type opportunityDetails = {
  name: string;
  jobType: string;
  workSchedule: string;
  salary: string | null;
  location: string;
  currency: string;
  description: string;
  logoURL: string | undefined;
  isAssessmentOnly: boolean;
  isPastClosingDate: boolean | null;
};

export type State = {
  opportunityDetails: opportunityDetails
};

const initialState: State = {
  opportunityDetails: {
    name: "",
    jobType: "",
    workSchedule: "",
    salary: null,
    location: "",
    currency: "",
    description: "",
    logoURL: undefined,
    isAssessmentOnly: false,
    isPastClosingDate: false
  }
};

// Actions
const SET_OPPORTUNITY_DETAILS = 'app/SET_OPPORTUNITY_DETAILS';

// Reducer
type Action =
  | {
      type: typeof SET_OPPORTUNITY_DETAILS;
      payload: any;
    };

export default function reducer(state = initialState, action: Action): State {
  switch (action.type) {
    case SET_OPPORTUNITY_DETAILS:
      return {
        ...state,
        opportunityDetails: action.payload,
      };
    default:
      return state;
  }
}

// Action creators
export const setOpportunityDetails = (payload: any) => ({
  type: SET_OPPORTUNITY_DETAILS,
  payload,
});

// Selectors
export const getOpportunityDetails = (app: Store) => app.opportunityDetails;
