import axios, { ErrorMessage2 } from '../../../api';
import { endpoints } from '../../../config';
import { RegisterCandidatePayload } from './models';
import { errors } from 'helpers/errors';

export type ApiReturn2<T = any> = {
  response: T | null;
  errors: ErrorMessage2[] | null;
  id?: number;
};

const registerCandidateUrl = endpoints.candidateSignUp.registerCandidate;

export const registerCandidateApi = async (
  payload: RegisterCandidatePayload
): Promise<ApiReturn2> => {
  try {
    const res = await axios.post(registerCandidateUrl, payload);
    if (res.data.status === '1') {
      return {
        response: res.data.id,
        errors: null,
      };
    } else {
      return {
        response: res.data.id,
        errors: res.data.errors,
      };
    }
  } catch (error) {
    const importedErrors = errors;
    return {
      response: null,
      errors: [
        {
          errorType: 'BadRequest',
          fieldName: 'Generic',
          messageCode:
            importedErrors && importedErrors.generic
              ? importedErrors.generic
              : 'There was an error, please try again later.',
        },
      ],
    };
  }
};
