import * as Yup from 'yup';
import { FieldArray, Form, Formik } from 'formik';
import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import MP_Skills_Dream from 'assets/apply.clevry/MP_Profile_Education_01.png';
import IconAdd from 'assets/apply.clevry/Icon_Add_DarkBlue.png';
import {
  getWhatHaveYouStudiedDetails,
  getWhatHaveYouStudiedOptions,
  parseWhatHaveYouStudiedDetails,
  parseWhatHaveYouStudiedOptions,
  parseWhatHaveYouStudiedPayload,
  submitWhatHaveYouStudiedPayload,
} from '../../MatcingProcessApi';
import { Spinner } from 'components/spinner/Spinner';
import WhatHaveYouStudiedPageRow from './WhatHaveYouStudiedPageRow';
import { Button } from 'components/button/Button';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';
import { useDispatch, useSelector } from 'react-redux';
import {
  MatchingProcessState,
  selectMatchingProcess,
  setMatchingProcessDataSubmittedToggle,
  setMatchingProcessSubmitSuccess,
} from 'reducers/matchingProcess';
import { GAMatchingProcessLatest } from 'helpers/gaEvents';

type WhatHaveYouStudiedPageProps = {
  outerSubmitForm: MutableRefObject<
    (() => Promise<void>) & (() => Promise<any>)
  >;
  setNextStepDisabled: Dispatch<SetStateAction<boolean>>;
  setGeneralError: Dispatch<SetStateAction<string | undefined>>;
};

export type formikValues = {
  educationLevels: [
    {
      educationLevelID: number | null;
      // educationSubjectsID: string | null;
      educationSubjectsID: number | null;
      yearAwarded: number | null;
    }
  ];
};

export type MenuOption = {
  value: number | null;
  label: string;
};

export type MenuOptions = {
  educationLevels: MenuOption[];
  educationSubjects: MenuOption[];
};

const WhatHaveYouStudiedPage: React.FC<WhatHaveYouStudiedPageProps> = ({
  outerSubmitForm,
  setNextStepDisabled,
  setGeneralError,
}) => {
  const latestLocation = useRef('');

  if (
    window &&
    window.location &&
    window.location.href !== latestLocation.current
  ) {
    latestLocation.current = window.location.href;
    GAMatchingProcessLatest(window.location.href);
  }

  const { t } = useTranslation('Matching+Process', { i18n });

  const blankRow = {
    educationLevelID: null,
    educationSubjectsID: null,
    yearAwarded: null,
  };

  const [highSchoolDiplomaIDData, setHighSchoolDiplomaIDData] =
    useState<MenuOption | null>(null);
  const [generalHighSchoolStudiesIDData, setGeneralHighSchoolStudiesIDData] =
    useState<MenuOption | null>(null); // TODO: Change to null once backend value has been added

  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState<formikValues>({
    educationLevels: [
      {
        educationLevelID: null,
        educationSubjectsID: null,
        yearAwarded: null,
      },
    ],
  });
  const [menuOptions, setMenuOptions] = useState<MenuOptions>({
    educationLevels: [
      {
        value: null,
        label: '',
      },
    ],
    educationSubjects: [
      {
        value: null,
        label: '',
      },
    ],
  });

  const areRowsUnique = (values: formikValues['educationLevels']) => {
    const roleTitles = values.map(
      ({ educationLevelID, educationSubjectsID, yearAwarded }, index) => ({
        educationLevelID,
        educationSubjectsID,
        yearAwarded,
        index,
      })
    );
    let isUnique = true;
    values.forEach((item, outerIdx) => {
      roleTitles.forEach((role) => {
        if (
          role.index !== outerIdx &&
          role.educationLevelID === item.educationLevelID &&
          role.educationSubjectsID === item.educationSubjectsID &&
          role.yearAwarded === item.yearAwarded
        ) {
          isUnique = false;
        }
      });
    });
    return isUnique;
  };

  const validationSchema = Yup.object().shape({
    educationLevels: Yup.array()
      .of(
        Yup.object({
          educationSubjectsID: Yup.number().required(''),
          educationLevelID: Yup.number().required(''),
          yearAwarded: Yup.number()
            .required(t('SSCandidate_Matching_Process_Please_Enter_A_Value'))
            .positive(),
        })
      )
      .test('areRowsUnique', 'Rows need te be unique', (values: any) => {
        const uniqueRows = areRowsUnique(values);
        if (!uniqueRows) {
          setGeneralError(
            t('SSCandidate_Matching_Process_Remove_Identical_Rows')
          );
        } else {
          setGeneralError('');
        }
        return uniqueRows;
      }),
  });

  const dispatch = useDispatch();

  const matchingProcessDetails: MatchingProcessState = useSelector(
    selectMatchingProcess
  );

  const handleSubmit = (values: formikValues) => {
    const payload = parseWhatHaveYouStudiedPayload(values);
    return (async () => {
      const result = await submitWhatHaveYouStudiedPayload(payload);
      if (result.response) {
        setGeneralError('');

        dispatch(setMatchingProcessSubmitSuccess(true));

        dispatch(
          setMatchingProcessDataSubmittedToggle(
            !matchingProcessDetails.dataSubmittedToggle
          )
        );

        return true;
      } else {
        dispatch(setMatchingProcessSubmitSuccess(false));

        setGeneralError(t('SSCandidate_Matching_Process_General_Error'));
        return false;
      }
    })();
  };

  const fetchEducationDetails = async () => {
    const result = await getWhatHaveYouStudiedDetails();

    if (result.response) {
      const parsedResult = parseWhatHaveYouStudiedDetails(result.response);
      if (parsedResult) {
        setInitialValues({ educationLevels: parsedResult });
      }
      setGeneralError('');
      setLoading(false);
    } else {
      setLoading(false);
      setGeneralError(t('SSCandidate_Matching_Process_General_Error_Reload'));
    }
  };

  const fetchMenuOptions = async () => {
    const result = await getWhatHaveYouStudiedOptions();
    if (result.response) {
      const parsedResult = parseWhatHaveYouStudiedOptions(result.response);

      const highSchoolDiplomaIDData = [
        ...result.response.educationLevels,
      ].filter((item: any) => item.educationLevelCode === 'HighSchoolDiploma');

      if (
        highSchoolDiplomaIDData.length === 1 &&
        typeof highSchoolDiplomaIDData[0] === 'object' &&
        highSchoolDiplomaIDData[0].educationLevelName &&
        highSchoolDiplomaIDData[0].educationLevelID
      ) {
        setHighSchoolDiplomaIDData({
          label: highSchoolDiplomaIDData[0].educationLevelName,
          value: highSchoolDiplomaIDData[0].educationLevelID,
        });
      }

      const generalHighSchoolStudiesIDData = [
        ...result.response.educationSubjects,
      ].filter(
        (item: any) => item.educationSubjectsCode === 'HighSchoolGeneralStudies'
      );

      if (
        generalHighSchoolStudiesIDData.length === 1 &&
        typeof generalHighSchoolStudiesIDData[0] === 'object' &&
        generalHighSchoolStudiesIDData[0].educationSubjectName &&
        generalHighSchoolStudiesIDData[0].educationSubjectsID
      ) {
        setGeneralHighSchoolStudiesIDData({
          label: generalHighSchoolStudiesIDData[0].educationSubjectName,
          value: generalHighSchoolStudiesIDData[0].educationSubjectsID,
        });
      }

      setMenuOptions({
        educationLevels: parsedResult.levels,
        educationSubjects: parsedResult.subjects,
      });
    }
  };

  useEffect(() => {
    fetchEducationDetails();
    fetchMenuOptions();
  }, []);

  if (loading) return <Spinner />;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
      validateOnMount
    >
      {({
        setFieldValue,
        values,
        errors,
        touched,
        setValues,
        submitForm,
        isValid,
      }) => {
        outerSubmitForm.current = submitForm;
        if (isValid) {
          setNextStepDisabled(false);
        } else {
          setNextStepDisabled(true);
        }

        return (
          <div className="WhatHaveYouStudiedPage MatchingProcessInnerPage">
            <div className="MatchingProcess__sectionHeader">
              <div className="MatchingProcess__sectionHeading">
                {t(
                  'SSCandidate_Matching_Process_What_Have_You_Studied_Heading'
                )}
              </div>
              <div className="MatchingProcess__sectionSubheading">
                <span>
                  {t(
                    'SSCandidate_Matching_Process_What_Have_You_Studied_Subheading'
                  )}
                </span>
              </div>
            </div>
            <Form className="JobApplicationForm MatchingProcessForm">
              <FieldArray
                name="educationLevels"
                render={({ push, remove }) => {
                  return (
                    <>
                      {values.educationLevels &&
                        Array.isArray(values.educationLevels) &&
                        values.educationLevels.map((item, idx) => (
                          <WhatHaveYouStudiedPageRow
                            rowNumber={idx}
                            remove={remove}
                            menuOptions={menuOptions}
                            values={values}
                            setFieldValue={setFieldValue}
                            highSchoolDiplomaIDData={highSchoolDiplomaIDData}
                            generalHighSchoolStudiesIDData={
                              generalHighSchoolStudiesIDData
                            }
                            key={`WhatHaveYouStudiedRow${idx}`}
                          />
                        ))}
                      <Button
                        icon={IconAdd}
                        priority={'secondary'}
                        onClick={(e) => {
                          e.preventDefault();
                          push(blankRow);
                        }}
                      >
                        {t(
                          'SSCandidate_Matching_Process_What_Have_You_Studied_Button'
                        )}
                      </Button>
                    </>
                  );
                }}
              />
            </Form>
            <img
              src={MP_Skills_Dream}
              alt=""
              className="WhatHaveYouStudiedPage__icon"
            />
          </div>
        );
      }}
    </Formik>
  );
};

export default WhatHaveYouStudiedPage;
