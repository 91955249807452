import React, { useState } from 'react';
import { InteractiveFeedbackMenuHeading } from './InteractiveFeedbackMenuHeading';
import useMedia from 'use-media';
import {
  InteractiveFeedbackMenuScoreRow,
  InteractiveFeedbackMenuScoreRowProps,
} from './InteractiveFeedbackMenuScoreRow';

export type InteractiveFeedbackMenuScoresProps = {
  rows: Pick<
    InteractiveFeedbackMenuScoreRowProps,
    | 'leftText'
    | 'onLeftButtonClick'
    | 'onRightButtonClick'
    | 'rightText'
    | 'score'
  >[];
  title: string;
  active: number | null;
  height: number;
  setActive: React.Dispatch<React.SetStateAction<number | null>>;
  headingIcon?: string;
  headingCloseClickHandler?: () => void;
  backArrowClickHandler?: () => void;
  numberOfItems: number;
  previousMenuSectionHandler?: () => void;
  nextMenuSectionHandler?: () => void;
  sectionData?: any;
};

export const InteractiveFeedbackMenuScores = ({
  rows,
  title,
  headingCloseClickHandler,
  active,
  setActive,
  headingIcon,
  height,
  backArrowClickHandler,
  numberOfItems,
  previousMenuSectionHandler,
  nextMenuSectionHandler,
  sectionData,
}: InteractiveFeedbackMenuScoresProps) => {
  const isTablet = useMedia('(max-width: 768px)');

  return (
    <div className="InteractiveFeedbackMenu">
      {isTablet && <div style={{ height: '20px', width: '100%' }} />}
      <InteractiveFeedbackMenuHeading
        title={title}
        icon={headingIcon}
        headingCloseClickHandler={headingCloseClickHandler}
        backArrowClickHandler={backArrowClickHandler}
        previousSectionHandler={previousMenuSectionHandler}
        nextSectionHandler={nextMenuSectionHandler}
        sectionData={sectionData}
      />
      {rows.map(
        (
          { leftText, onLeftButtonClick, onRightButtonClick, rightText, score },
          idx
        ) => (
          <InteractiveFeedbackMenuScoreRow
            key={idx}
            leftText={leftText}
            onLeftButtonClick={onLeftButtonClick}
            rightText={rightText}
            onRightButtonClick={onRightButtonClick}
            setActive={setActive}
            primaryButtonActive={active === idx * 2}
            secondaryButtonActive={active === idx * 2 + 1}
            rowNumber={idx}
            score={score}
            numberOfItems={numberOfItems}
            noScaleNames={title === 'Response style' ? true : false}
          />
        )
      )}
      <div
        className="InteractiveFeedbackMenu__Spacer"
        style={{ ...(isTablet ? { height: height + 161 } : {}) }}
      />
    </div>
  );
};
