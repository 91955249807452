import { navigate } from '@reach/router';
import { Button } from 'components/button/Button';
import { Dropdown } from 'components/dropdown';
import { ProgressIndicator } from 'components/progressIndicator/progressIndicator';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { closeModal, openModal } from 'reducers/modal';
import { FormRadioButtonsField } from '../matchingProcess/matchingProcessPages/CustomFormFields/FormRadioButtonsField';
import useCountdown from './useCountdown';
import * as Yup from 'yup';
import { ErrorM } from 'helpers/ErrorM';
import { Spinner } from 'components/spinner/Spinner';
import { getReasonableAdjustmentReasons } from '../abilityTests/AbilityTestApi';

export const VirtualAssessmentsAdjustmentModal = () => {
  const [loading, setLoading] = useState(true);
  const [adjustmentReasons, setAdjustmentReasons] = useState([]);
  const [selected, setSelected] = useState('');
  const dispatch = useDispatch();

  const initialTime = 610;

  const onTimeEnd = () => {
    console.log('Finished');
  };

  const { minutes, seconds, remainingSeconds } = useCountdown({
    initialSeconds: initialTime,
    onTimeEnd,
  });

  const handleSubmit = (values: { adjustmentReason: string | null }) => {
    console.log('values', values);
  };

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const validationSchema = Yup.object({
    adjustmentReason: Yup.string()
      .required('Please select a reason for the assessment adjustment')
      .typeError('Please select a reason for the assessment adjustment'),
  });

  useEffect(() => {
    const fetchAdjustmentReasons = async () => {
      const result = await getReasonableAdjustmentReasons();

      if (result.response) {
        setAdjustmentReasons(result.response);

        setLoading(false);
      } else {
        setLoading(false);
      }
    };

    fetchAdjustmentReasons();
  }, []);

  const options = [
    { label: 'one', value: 1 },
    { label: 'two', value: 2 },
    { label: 'three', value: 3 },
    { label: 'four', value: 4 },
    { label: 'five', value: 5 },
  ];

  if (loading) return <Spinner />;

  return (
    <div className="VirtualAssessmentsAdjustmentModal">
      <Formik
        initialValues={{ adjustmentReason: null }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ isValid, isSubmitting, values, setFieldValue }) => (
          <Form>
            <div className="VirtualAssessmentsAdjustmentModal__content VirtualAssessmentsModalContent Content">
              <div className="VirtualAssessmentsAdjustmentModal__title">
                Request adjustment to assessment
              </div>

              <div className="Paragraph">
                Are you sure you wish to request an adjustment to this
                assessment?
              </div>

              <div className="VirtualAssessmentsAdjustmentModal__dropdown">
                <Field
                  label="Please selected the reason you wish to adjust your assessment"
                  name="adjustmentReason"
                  component={Dropdown}
                  options={options}
                  value={
                    values.adjustmentReason
                      ? options.find(
                          ({ value }) => value === values.adjustmentReason
                        )
                      : values.adjustmentReason
                  }
                  onChange={({
                    label,
                    value,
                  }: {
                    label: string;
                    value: number;
                  }) => {
                    setFieldValue('adjustmentReason', value);
                  }}
                  roundedRectangle={false}
                />
                <ErrorM name="adjustmentReason" />
              </div>
            </div>
            <div className="ModalFooter">
              <Button
                priority="tertiary"
                type="button"
                onClick={handleCloseModal}
              >
                Cancel
              </Button>
              <Button
                className="Discard"
                priority="primary"
                type="submit"
                onClick={() => {}}
              >
                Submit
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
