import { JobBoardFilters } from 'components/pages/jobBoard/JobBoardFilters';
import React from 'react';
import { useDispatch } from 'react-redux';

type LeftPanelFiltersProps = {
  data: any;
};

const LeftPanelFilters: React.FC<LeftPanelFiltersProps> = ({
  children,
  data,
}) => {
  const dispatch = useDispatch();
  return <JobBoardFilters data={data} />;
};

export { LeftPanelFilters };
