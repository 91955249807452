import { Contact } from 'components/pages/updateContact/models';
import { FormikProps } from 'formik';
import { RefObject } from 'react';
import { Store } from './rootReducer';

export enum FooterType {
  'PUBLIC_ALL_ELEMENTS' = 'PUBLIC_ALL_ELEMENTS',
  'JOB_BOARD' = 'JOB_BOARD',
  'LOGGED_IN' = 'LOGGED_IN',
  'COPYRIGHT_ONLY' = 'COPYRIGHT_ONLY',
  'COPYRIGHT_ONLY_RIGHT' = 'COPYRIGHT_ONLY_RIGHT',
  'NAVIGATION_FOOTER' = 'NAVIGATION_FOOTER',
  'UPDATE_PROFILE' = 'UPDATE_PROFILE',
  'NONE' = 'NONE',
}

export type NavigationFooterData = {
  primaryButtonCopy: string;
  primaryButtonHandler: () => void;
  primaryButtonIsActive: boolean;
};
export type UpdateProfileFooterData = {
  secondaryButtonHandler: () => void;
  formikRef?: RefObject<any>;
};

// State
export type FooterState = {
  footerType: FooterType | null;
  data: NavigationFooterData | UpdateProfileFooterData | null;
};

export const initialState: FooterState = {
  footerType: null,
  data: null,
};

enum ActionType {
  CHANGE_FOOTER_TYPE = 'CHANGE_FOOTER_TYPE',
}

// Actions
export type Action = {
  type: ActionType.CHANGE_FOOTER_TYPE;
  payload: FooterState;
};

// Reducer
export default function footerReducer(
  state = initialState,
  action: Action
): FooterState {
  switch (action.type) {
    case ActionType.CHANGE_FOOTER_TYPE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

// Action creators
export const changeFooterType = (payload: FooterState) => ({
  type: ActionType.CHANGE_FOOTER_TYPE,
  payload,
});

//Selectors
export const selectFooter = (state: Store) => state.footer;
