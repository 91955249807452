import React from 'react';
import './toggleSwitch.scss';

const ToggleSwitch = ({ onChange, value }) => {
  return (
    <label className="ToggleSwitch">
      <input type="checkbox" onChange={onChange} checked={value} />

      <span></span>
    </label>
  );
};

export default ToggleSwitch;
