import ClevryLogo from 'assets/apply.clevry/Logo2.png';
import IdeagenLogo from 'assets/apply.clevry/Logo_Ideagen.png';

import ClevryLandingImage from 'assets/new_skin/Img_NewFindYourJoy_LandingPage_03.png';
import IdeagenLandingImage from 'assets/new_skin/LandingPage_Ideagen.png';
import IdeagenLandingBlockImage1 from 'assets/new_skin/LandingPageBlockImage1_Ideagen.png';
import IdeagenLandingBlockImage2 from 'assets/new_skin/LandingPageBlockImage2_Ideagen.png';
import IdeagenLandingBlockImage3 from 'assets/new_skin/LandingPageBlockImage2_Ideagen.png';

export const themes: any = {
  defaultTheme: {
    one: '#731447',
    two: '#FAED33',
    three: '#036C8F',
    four: '#CCE1E8',
    five: '#FAED33',
    six: '#731447',
    seven: '#B9E887',
    eight: '#FFFFFF',
    nine: '#D5D5D5',
    ten: '#6A6A6A',
    logo: ClevryLogo,
    landingImage: ClevryLandingImage,
  },
  Ideagen: {
    one: '#731447',
    two: '#FAED33',
    three: '#429CCC',
    four: '#ECF5FA',
    five: '#FAED33',
    six: '#731447',
    seven: '#B9E887',
    eight: '#FFFFFF',
    nine: '#D5D5D5',
    ten: '#6A6A6A',
    logo: IdeagenLogo,
    landingImage: IdeagenLandingImage,
    landingBlockImage1: IdeagenLandingBlockImage1,
    landingBlockImage2: IdeagenLandingBlockImage2,
    landingBlockImage3: IdeagenLandingBlockImage3,
  },
};
