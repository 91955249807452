import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Spinner } from 'components/spinner/Spinner';
import i18n from 'i18n/i18n';
import { useTranslation } from 'react-i18next';
import { Dropzone, Dropzone2 } from 'components/dropzone/Dropzone';
import { UpdateContactFormData } from 'components/pages/updateContact/models';
import { UpdateContactApi } from 'components/pages/updateContact/updateContactApi';
import { CheckboxInput } from 'components/formFields/CheckboxInput';
import { FILE } from 'dns';
import {
  configureFormData,
  submitProfilePicture,
} from '../../MatcingProcessApi';
import { useDispatch, useSelector } from 'react-redux';
import {
  MatchingProcessState,
  selectMatchingProcess,
  setMatchingProcessDataSubmittedToggle,
  setMatchingProcessSubmitSuccess,
} from 'reducers/matchingProcess';

type formikValues = {
  profileImage: string | null;
  profilePictureURL: string | null;
  consentToShareProfilePicture: boolean;
  IsDeleteProfilePicture: boolean;
};

type PicturePageProps = {
  outerSubmitForm: MutableRefObject<
    (() => Promise<void>) & (() => Promise<any>)
  >;
  setNextStepDisabled: Dispatch<SetStateAction<boolean>>;
  setGeneralError: Dispatch<SetStateAction<string | undefined>>;
};

const ProfilePicturePage: React.FC<PicturePageProps> = ({
  outerSubmitForm,
  setNextStepDisabled,
  setGeneralError,
}) => {
  const { t } = useTranslation('Matching+Process', { i18n });

  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState<formikValues>({
    profileImage: null,
    profilePictureURL: null,
    consentToShareProfilePicture: false,
    IsDeleteProfilePicture: false,
  });
  const [image, setImage] = useState<any>(null);
  const [imageCleared, setImageCleared] = useState<boolean>(false);

  const dispatch = useDispatch();

  const matchingProcessDetails: MatchingProcessState = useSelector(
    selectMatchingProcess
  );

  const validationSchema = Yup.object({});

  const handleSubmit = (values: formikValues) => {
    let payload: any = {
      consentToShareProfilePicture: values.consentToShareProfilePicture,
      IsDeleteProfilePicture: values.IsDeleteProfilePicture,
    };

    if (values.profileImage) {
      payload = {
        ...payload,
        Profilepicture: values.profileImage,
      };
    }
    const formDataPayload = configureFormData(payload);

    return (async () => {
      const result = await submitProfilePicture(formDataPayload);
      if (result.response) {
        setGeneralError('');

        dispatch(setMatchingProcessSubmitSuccess(true));

        dispatch(
          setMatchingProcessDataSubmittedToggle(
            !matchingProcessDetails.dataSubmittedToggle
          )
        );

        return true;
      } else {
        dispatch(setMatchingProcessSubmitSuccess(false));

        setGeneralError(t('SSCandidate_Matching_Process_General_Error'));
        return false;
      }
    })();
  };

  const fetchDetails = async () => {
    const result = await UpdateContactApi.getContactInfo(); // For retrieving profile name and picture

    if (
      result.data &&
      result.data.profilePictureURL !== undefined &&
      result.data.consentToShareProfilePicture !== undefined
    ) {
      const parsedPictureURLResult = result.data.profilePictureURL;

      const parsedSharingConsentResult =
        result.data.consentToShareProfilePicture;

      setInitialValues({
        ...initialValues,
        profilePictureURL: parsedPictureURLResult,
        consentToShareProfilePicture: parsedSharingConsentResult,
      });

      setLoading(false);
    } else {
      setLoading(false);
      setGeneralError(t('SSCandidate_Matching_Process_General_Error_Reload'));
    }
  };

  useEffect(() => {
    fetchDetails();
  }, []);

  const onImageDrop = React.useCallback(
    (acceptedFiles) => {
      let fileName = '';
      const reader = new FileReader();

      reader.onabort = () => console.log('File reading was aborted');
      reader.onerror = () => console.log('File reading has failed');
      // NOTE: As these are console logs I don't think we need the translations
      // reader.onabort = () => console.log(t("SSCandidate_Update_Contact_On_Abort"));
      // reader.onerror = () => console.log(t("SSCandidate_Update_Contact_On_Error"));

      if (acceptedFiles.length > 1) {
        //show warning message
        setTimeout(() => {
          //hide warning message
        }, 2500);
        return;
      }

      acceptedFiles.forEach((file: File) => {
        fileName = file.name;
        setImage({ data: file as File, fileName });
      });
    },
    [setImage]
  );

  if (loading) return <Spinner />;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
      validateOnMount
    >
      {({
        setFieldValue,
        values,
        errors,
        touched,
        setValues,
        submitForm,
        isValid,
      }) => {
        outerSubmitForm.current = submitForm;

        if (isValid) {
          setNextStepDisabled(false);
        } else {
          setNextStepDisabled(true);
        }

        return (
          <>
            <Form className="ProfilePicturePage MatchingProcessForm MatchingProcessInnerPage">
              <div className="DropzoneHeader">
                <span>{t('SSCandidate_Matching_Process_Profile_Picture')}</span>
                <span className="OptionalLabel">{` - ${t(
                  'SSCandidate_Matching_Process_Optional'
                )}`}</span>
              </div>
              <Dropzone2
                onDrop={(files, _, event) => {
                  if (files[0] && files[0].size) {
                    setFieldValue('profileImage', files[0]);
                    setFieldValue('IsDeleteProfilePicture', false);
                  }
                  onImageDrop(files);
                }}
                name="ProfilePicture"
                dropzoneTitle="Image file"
                uploadedFile={image?.data as any}
                previouslyUploadedImage={
                  values.profilePictureURL ? values.profilePictureURL : ''
                }
                fullSizePreviousImage={true}
              />
              <div className="DropzoneFooter">
                <div className="ProfilePictureGuidelines">
                  <div>
                    {t(
                      'SSCandidate_Matching_Process_Profile_Picture_Guideline1'
                    )}
                  </div>
                  <div>
                    {t(
                      'SSCandidate_Matching_Process_Profile_Picture_Guideline2'
                    )}
                  </div>
                  <div>
                    {t(
                      'SSCandidate_Matching_Process_Profile_Picture_Guideline3'
                    )}
                  </div>
                </div>
                <button
                  className="ClearImageButton"
                  onClick={() => {
                    setImage(null);
                    setFieldValue('profileImage', null);
                    setFieldValue('profilePictureURL', null);
                    setFieldValue('IsDeleteProfilePicture', true);
                  }}
                  type="button"
                >
                  {t('SSCandidate_Matching_Process_Clear_Image')}
                </button>
              </div>
              <CheckboxInput
                label={
                  'I give permission for my image to be shared with those involved in the recruiting process'
                }
                name="consentToShareProfilePicture"
                className="UpdateContactCheckboxInput UpdateImageCheckbox"
                isBordered={true}
              />
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

export default ProfilePicturePage;
