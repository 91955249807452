import { Store } from './rootReducer';

//Types
export enum AutoLogin {
  'UPDATE_AUTO_LOGIN' = 'UPDATE_AUTO_LOGIN',
  'ASSESSOR' = 'ASSESSOR',
  'CANDIDATE' = 'CANDIDATE',
}

export type AutoLoginDetails = {
  personID: number;
  testID: number;
  prongCode: string;
  roomId?: string;
};

//State
export type AutoLoginState =
  | {
      autoLogin: null;
      data: null;
    }
  | {
      autoLogin: AutoLogin.CANDIDATE;
      data: AutoLoginDetails;
    }
  | {
      autoLogin: AutoLogin.ASSESSOR;
      data: AutoLoginDetails;
    };

export const initialState: AutoLoginState = {
  autoLogin: null,
  data: null,
};

//Actions
export type Action = {
  type: AutoLogin.UPDATE_AUTO_LOGIN;
  payload: UpdateAutoLogin;
};

export type UpdateAutoLogin = {
  autoLogin: any | null;
  data: AutoLoginDetails | null;
};

// Reducer
export default function navReducer(
  state = initialState,
  action: Action
): AutoLoginState {
  switch (action.type) {
    case AutoLogin.UPDATE_AUTO_LOGIN:
      return {
        ...state,
        autoLogin: action.payload.autoLogin,
        data: action.payload.data,
      };
    default:
      return state;
  }
}

// Action creators
export const updateAutoLogin = (payload: UpdateAutoLogin) => ({
  type: AutoLogin.UPDATE_AUTO_LOGIN,
  payload,
});

//Selectors
export const selectAutoLoginDetails = (state: Store) => state.autoLogin.data;
export const selectAutoLoginType = (state: Store) => state.autoLogin.autoLogin;
