import { endpoints } from "config";
import { get, post } from "helpers/newMakeEndpointRequest";
import { BaseApi } from "api";
import {
  NotificationsStackResponse,
  NotificationsUserPreferences,
  NotificationUserPreference,
} from "./models";

export class NotificationsApi extends BaseApi {
  public static getNotificationsConfig() {
    return this.makeRequest<NotificationsUserPreferences>({
      url: endpoints.notificationsmodule.getForUser,
    });
  }

  public static updateSingleConfig(preference: NotificationUserPreference) {
    return this.makeRequest({
      url: endpoints.notificationsmodule.updateSinglePreference,
      method: "POST",
      data: preference,
    });
  }

  public static getNotificationsStack() {
    const IANATimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return this.makeRequest<NotificationsStackResponse>({
      url: endpoints.notificationsmodule.userStack,
      method: "GET",
      params: { timeZone: IANATimezone },
    });
  }

  public static acknowledgeNotification(id: number) {
    return this.makeRequest<NotificationsStackResponse>({
      url: endpoints.notificationsmodule.acknowledgeSingle,
      method: "POST",
      data: { notificationsId: id },
    });
  }
  
  public static acknowledgeAllNotifications() {
    return this.makeRequest<NotificationsStackResponse>({
      url: endpoints.notificationsmodule.acknowledgeAll,
      method: "POST",
    });
  }
}

export const parseGetUnreadNotificationsCount = (details: any) => {
  if (Array.isArray(details))
    return details
      .filter((notification) => notification.count)
      .reduce((sum, notification) => sum + notification.count, 0);
  else return 0;
};

export const getExistingNotifications = async () => {
  const result = await get(endpoints.notificationsmodule.getForUser, {});
  return result;
};
