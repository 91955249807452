import { ApiResponse } from '@avamae/formbuilder/dist/FormBuilder';
import instance from 'api';
import { AxiosResponse } from 'axios';
import { BaseApi } from 'baseApi';
import { ComparisonTableData } from 'components/PQComparisonChart/PQComparisonChart';
import { TeamStrengthsData } from 'components/teamStrengthsChart/TeamStrengthsChart';
import { endpoints } from 'config';
import { Word } from 'react-wordcloud';
import { scaleWordcloudValue } from '../findYourJoy/findYourJoyApi';
import {
  FindYourJoyQuizResults,
  ParsedFindYourJoyQuizResults,
} from '../findYourJoy/models';

const fetchJoyAtWorkResults = (id: number) => {
  return instance.get<ApiResponse<any, FindYourJoyQuizResults>>(
    endpoints.strengthsReport.getSoftSkillsResults,
    { withCredentials: true }
  );
};

const parseJoyAtWorkResults = (
  results: FindYourJoyQuizResults
): Pick<ParsedFindYourJoyQuizResults, 'profile' | 'wordcloud'> => {
  const wordcloud = results.wordcloud.map<Word>(({ label, value }) => ({
    text: label,
    value: scaleWordcloudValue(value),
  }));

  return { wordcloud, profile: results.profile };
};

type PersonalityScores = {
  category: 'PQ';
  candidates: {
    name: string;
    seriesData: { name: string; score: number; nameRight: string }[];
  }[];
};

type PersonalityScoresSoftSkills = {
  category: 'PQ';
  candidates: {
    name: string;
    candidateId: number;
    seriesData: { name: string; score: number; nameRight: string }[];
  }[];
};

type TeamScores = {
  category: 'Team';
  candidates: {
    name: string;
    seriesData: { name: string; score: number; nameRight: null }[];
  };
};
type SalesScores = {
  category: 'Sales';
  candidates: {
    name: string;
    seriesData: { name: string; score: number; nameRight: null }[];
  };
};
type LeadershipScores = {
  category: 'Leadership';
  candidates: {
    name: string;
    seriesData: { name: string; score: number; nameRight: null }[];
  };
};
type PersonalFeedbackScores = {
  category: 'Personal Feedback';
  candidates: {
    name: string;
    seriesData: { name: string; score: number; nameRight: null }[];
  };
};

type PQResultsResponse = {
  categories: (
    | PersonalityScores
    | TeamScores
    | SalesScores
    | LeadershipScores
    | PersonalFeedbackScores
  )[];
};

type PQCandidates = {
  name: string;
  results: number[];
}[];

export const getPQResults = (comparisonIds?: number[]) => {
  return instance.post<ApiResponse<PQResultsResponse>>(
    endpoints.strengthsReport.getCandidateComparison,
    {
      candidateTeamPartnerIds: comparisonIds ? comparisonIds : [],
    },
    { withCredentials: true }
  );
};

export const parsePQResultsResponseToComparisonTable = (
  response: PQResultsResponse
): ComparisonTableData => {
  const PQResults = response.categories.find(
    (category) => category.category === 'PQ'
  ) as PersonalityScores;
  const labels = PQResults.candidates[0].seriesData.map((row) => ({
    left: row.name,
    right: row.nameRight,
  }));

  const candidates = PQResults.candidates.map((candidate) => ({
    name: candidate.name,
    results: candidate.seriesData.map((row) => row.score),
  }));

  return { labels, candidates };
};

export const parseSoftSkillsPQResultsResponseToComparisonTable = (
  response: PQResultsResponse
): any => {
  const PQResults = response.categories.find(
    (category) => category.category === 'PQ'
  ) as PersonalityScoresSoftSkills;
  let labels: any = [];
  if (PQResults.candidates[0].seriesData) {
    labels = PQResults.candidates[0].seriesData.map((row) => ({
      left: row.name,
      right: row.nameRight,
    }));
  }

  let candidates: any = [];
  if (PQResults.candidates[0].seriesData) {
    candidates = PQResults.candidates
      .filter((candidate) => candidate.seriesData)
      .map((candidate) => ({
        name: candidate.name,
        id: candidate.candidateId,
        results: candidate.seriesData.map((row) => row.score),
      }));
  }

  return { labels, candidates };
};

export const parsePQResultsResponseToTeamStrengths = (
  response: PQResultsResponse
): TeamStrengthsData => {
  const strengths = response.categories.find(
    (category) => category.category === 'Team'
  ) as PersonalityScores;
  return (
    strengths &&
    strengths.candidates[0].seriesData.map((seriesItem) => ({
      label: seriesItem.name,
      strength: seriesItem.score,
    }))
  );
};
export const parsePQResultsResponseToSales = (
  response: PQResultsResponse
): TeamStrengthsData => {
  const strengths = response.categories.find(
    (category) => category.category === 'Sales'
  ) as PersonalityScores;
  return (
    strengths &&
    strengths.candidates[0].seriesData.map((seriesItem) => ({
      label: seriesItem.name,
      strength: seriesItem.score,
    }))
  );
};
export const parsePQResultsResponseToLeadership = (
  response: PQResultsResponse
): TeamStrengthsData => {
  const strengths = response.categories.find(
    (category) => category.category === 'Leadership'
  ) as PersonalityScores;
  return (
    strengths &&
    strengths.candidates[0].seriesData.map((seriesItem) => ({
      label: seriesItem.name,
      strength: seriesItem.score,
    }))
  );
};

export const parsePQResultsResponseToPersonalFeedback = (
  response: PQResultsResponse
): TeamStrengthsData => {
  const strengths = response.categories.find(
    (category) => category.category === 'Personal Feedback'
  ) as PersonalityScores;
  return (
    strengths &&
    strengths.candidates[0].seriesData.map((seriesItem) => ({
      label: seriesItem.name,
      strength: seriesItem.score,
    }))
  );
};

type TeamPartners = { teamPartnerId: number; name: string }[];
export type ParsedTeamPartners = { label: string; value: number }[];

export const getUsersInTeam = () => {
  return instance.get<ApiResponse<any, { teamPartners: TeamPartners }>>(
    endpoints.strengthsReport.getTeamPartners
  );
};

type Reports = {
  data: string | null;
  errors: any[] | null;
};

export const parseTeamPartnersToSelectValues = (
  partners: TeamPartners,
  loggedInUserId?: number,
  isPQDropdown?: boolean
) => {
  const selectValuesMap = partners.map(({ name, teamPartnerId }) => ({
    label: name,
    value: teamPartnerId,
  }));
  let selectValues = selectValuesMap;
  if (isPQDropdown) {
    selectValues = selectValues.filter((item) => item.value !== loggedInUserId);
  }
  return selectValues;
};

export class LearnYourStrengthsApi extends BaseApi {
  public static getReportsInfo = async (payload: any): Promise<any> => {
    try {
      const result: AxiosResponse<ApiResponse<any, any>> = await instance.post(
        endpoints.account.getReportsInfo,
        payload,
        {
          withCredentials: true,
        }
      );
      if (LearnYourStrengthsApi.isResponseSuccessful(result.data)) {
        return LearnYourStrengthsApi.successResponse(result.data.details);
      }
    } catch (error) {
      if ((error as any).response) {
        const errors = (error as any).response.errors;
        if (errors) {
        }
      }
    }
  };

  public static getIndividualReportsInfo = async (
    payload: any
  ): Promise<Reports> => {
    try {
      const result: AxiosResponse<ApiResponse<any, any>> = await instance.post(
        endpoints.account.getReportsInfo,
        payload,
        {
          withCredentials: true,
        }
      );

      if (
        result &&
        result.data &&
        result.data.status === '1' &&
        result.data.details &&
        result.data.details.reportURL
      ) {
        return {
          data: result.data.details.reportURL,
          errors: null,
        };
      } else {
        return {
          data: null,
          errors: [],
        };
      }
    } catch (error) {
      return {
        data: null,
        errors: [error],
      };
    }
  };

  public static getGroupReportsInfo = async (
    payload: any
  ): Promise<Reports> => {
    try {
      const result: AxiosResponse<ApiResponse<any>> = await instance.post(
        endpoints.account.getPersonalityQuestionnaireGroupReport,
        payload,
        { withCredentials: true }
      );

      if (
        result &&
        result.data &&
        result.data.status === '1' &&
        result.data.details &&
        result.data.details.reportURL
      ) {
        return {
          data: result.data.details.reportURL,
          errors: null,
        };
      } else {
        return {
          data: null,
          errors: [],
        };
      }
    } catch (error) {
      return {
        data: null,
        errors: [error],
      };
    }
  };
}

export { fetchJoyAtWorkResults, parseJoyAtWorkResults };
