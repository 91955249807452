import Button from 'components/ui/Button';
import React from 'react';
import Select, { FormatOptionLabelMeta } from 'react-select';
import { DropdownProps, SelectOption } from './model';

const Dropdown: React.FC<DropdownProps> = ({
  options,
  value,
  roundedRectangle,
  small,
  defaultMenuIsOpen,
  isMulti,
  isDisabled,
  placeholder,
  extraClassName,
  isSearchable = false,
  inPortal,
  label,
  onChange,
  formatOptionLabel,
}) => {
  const className = roundedRectangle
    ? small
      ? 'CustomSelect RoundedRect Small'
      : 'CustomSelect RoundedRect'
    : small
    ? 'CustomSelect Small'
    : 'CustomSelect';

  return (
    <>
      {label && <label>{label}</label>}
      <Select
        className={
          extraClassName ? `${className} ${extraClassName}` : className
        }
        isDisabled={isDisabled}
        isMulti={isMulti}
        placeholder={placeholder}
        defaultMenuIsOpen={defaultMenuIsOpen}
        classNamePrefix="CustomSelect"
        options={options}
        formatOptionLabel={formatOptionLabel}
        value={value}
        menuPortalTarget={
          inPortal ? document.getElementById('dropdown-portal') : undefined
        }
        isSearchable={isSearchable}
        onChange={onChange}
        menuPlacement="auto"
      />
    </>
  );
};

export { Dropdown };
