import React, { ReactElement, useLayoutEffect, useRef } from 'react';
import { MyCapabilitiesScoreMaps } from '../hooks/useGetMyCapabilitiesDetails';
import { InteractiveFeedbackCardButtons } from './InteractiveFeedbackCardButtons';
import { InteractiveFeedbackScoreIcon } from './InteractiveFeedbackScoreIcon';

export enum InteractiveFeedbackCardType {
  IMAGE = 'image',
  ICON_LARGE = 'icon_large',
  ICON_SMALL = 'icon_small',
}

export type InteractiveFeedbackCardProps = {
  type: InteractiveFeedbackCardType;
  topImage?: string;
  heading?: string;
  numberOfItems: number;
  cardText?: string;
  cardHTML?: string;
  active?: number | null;
  setActive?: React.Dispatch<React.SetStateAction<number | null>>;
  topColor?: string;
  bottomColor: string;
  bottomFont?: string;
  bottomFontSize?: string;
  score?: number;
  noButtons?: boolean;
  setHeight: React.Dispatch<React.SetStateAction<number>>;
  hideStenScores?: boolean;
};

// Link card contents to active number in same way as row

export const InteractiveFeedbackCard: React.FC<
  InteractiveFeedbackCardProps
> = ({
  type,
  topImage,
  heading,
  active,
  setActive,
  topColor,
  bottomColor,
  bottomFont,
  bottomFontSize,
  score,
  cardText,
  cardHTML,
  noButtons,
  numberOfItems,
  setHeight,
  children,
  hideStenScores,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  let updateRef = useRef<NodeJS.Timeout | null>(null);

  useLayoutEffect(() => {
    const updateSize = () => {
      if (updateRef.current) {
        clearTimeout(updateRef.current);
      }
      updateRef.current = setTimeout(() => {
        if (ref.current) {
          setHeight(ref.current.clientHeight);
        }
      }, 500);
    };
    updateSize();
    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return (
    <div ref={ref} className={"InteractiveFeedbackCard" + ((score && hideStenScores) ? " TopRoundCorners" : "")}>
      {!(score && hideStenScores) &&
        <div
          className={`InteractiveFeedbackCard__Top ${
            type === InteractiveFeedbackCardType.IMAGE
              ? 'Image'
              : type === InteractiveFeedbackCardType.ICON_LARGE
              ? 'IconLarge'
              : type === InteractiveFeedbackCardType.ICON_SMALL
              ? 'IconSmall'
              : ''
          }
          `}
          style={{ ...(topColor ? { backgroundColor: topColor } : {}) }}
        >
          {type !== InteractiveFeedbackCardType.ICON_SMALL && (
            <img src={topImage} alt="Card Image" />
          )}
          {type == InteractiveFeedbackCardType.ICON_SMALL && score && !hideStenScores && (
            <InteractiveFeedbackScoreIcon score={score} />
          )}
        </div>
      }
      {/* No heading for development suggestions etc. */}
      {heading && (
        <div
          style={{ backgroundColor: bottomColor }}
          className="InteractiveFeedbackCard__BottomHeading"
        >
          <span>{heading}</span>
          {!noButtons && (
            <InteractiveFeedbackCardButtons
              setActive={setActive}
              numberOfItems={numberOfItems}
            />
          )}
        </div>
      )}
      <div
        style={{
          backgroundColor: bottomColor,
          ...(bottomFont ? { fontFamily: bottomFont } : {}),
          ...(bottomFontSize ? { fontSize: bottomFontSize } : {}),
        }}
        className="InteractiveFeedbackCard__BottomContent"
      >
        {cardText && cardText}
        {cardHTML && (
          <div
            className="Paragraphs"
            dangerouslySetInnerHTML={{ __html: cardHTML }}
          ></div>
        )}
        {!cardText && !cardHTML && children && children}
      </div>
    </div>
  );
};
