import { FieldAttributes, useField } from 'formik';
import { capitalizeFirstLetter } from 'helpers/capitalizeFirstLetter';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';
import React, { useEffect, useState } from 'react';
import { defaultIfEmpty } from 'rxjs';

type FormikInputField = React.DetailedHTMLProps<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
> &
  FieldAttributes<string>;

type TextInputProps = {
  label: string;
  labelLocation?: 'top' | 'left';
  charLimit?: number;
  charText?: boolean;
  rows?: number;
  textValue?: string;
  defaultValue?: string | null;
  wordLimit?: number | null;
} & FieldAttributes<{}>;

export const TextArea: React.FC<TextInputProps> = ({
  label,
  labelLocation = 'top',
  charText = true,
  textValue,
  defaultValue,
  ...props
}) => {
  const { t } = useTranslation('Text+Area', { i18n });

  const [isFocused, setisFocused] = useState(false);
  const [wordsRemaining, setWordsRemaining] = useState<number | null>(null);
  const [field, { error, touched }] = useField(props);
  const errorText = error && touched ? error : '';
  const inputClassName = `TextArea ${props.className ? props.className : ''} ${
    errorText ? 'Error' : ''
  }`;
  const containerClassName = `TextAreaContainer Label${capitalizeFirstLetter(
    labelLocation
  )}`;

  useEffect(() => {
    if (props.wordLimit) {
      setWordsRemaining(props.wordLimit);
    }
  }, []);

  const labelStyle = `Label ${isFocused ? 'Focused' : ''} ${
    errorText && !isFocused ? 'Error' : ''
  }`;

  const onFocus = () => setisFocused(true);
  const onBlur = () => setisFocused(false);

  const keyCheck = (e: any) => {
    if (props.wordLimit && wordsRemaining !== null) {
      // const currentWords = e.target.value.trim().split(' ');
      const currentWords = e.target.value.split(' ');
      const currentNumberOfWords = currentWords.length;
      const tempWordsRemaining = props.wordLimit - currentNumberOfWords;

      setWordsRemaining(tempWordsRemaining);

      if (tempWordsRemaining < 0 && e.key && e.key !== 'Backspace') {
        e.preventDefault(); // Stop onChange from occuring
      }
    }
  };

  return (
    <div className={containerClassName}>
      <div className="TextAreaInfo">
        <label className={labelStyle} htmlFor={field.name}>
          {label}
        </label>

        {props.charLimit && charText ? (
          <p className="CharLimit">
            {t('SSCandidate_Text_Area_Maximum_Text', { val1: props.charLimit })}
            {/* OLD: */}
            {/* {t('SSCandidate_Text_Area_Maximum_Text')} {props.charLimit}{' '}
            {t('SSCandidate_Text_Area_Characters')} */}
          </p>
        ) : null}

        {props.wordLimit && (
          <p
            className={`WordLimit ${
              (wordsRemaining || wordsRemaining !== null) && wordsRemaining <= 0
                ? 'WordLimitReached'
                : ''
            }`}
          >
            {(wordsRemaining || wordsRemaining !== null) &&
              wordsRemaining >= 0 &&
              `${t(
                'SSCandidate_Text_Area_Words_Remaining'
              )} : ${wordsRemaining}`}

            {(wordsRemaining || wordsRemaining !== null) &&
              wordsRemaining < 0 &&
              `${t('SSCandidate_Text_Area_Words_Remaining')} : ${0}`}
          </p>
        )}
      </div>
      <div className="InputAndError">
        <textarea
          {...(field as any)}
          {...(textValue ? { value: textValue } : {})}
          className={inputClassName}
          maxLength={props.charLimit ? props.charLimit : ''}
          onBlur={onBlur}
          onFocus={onFocus}
          rows={props?.rows}
          defaultValue={defaultValue}
          onKeyDown={props.wordLimit ? keyCheck : undefined}
        />
        <p className="ErrorText">{errorText}</p>
      </div>
    </div>
  );
};
