import { combineReducers } from 'redux';
import { combineEpics } from 'redux-observable';
import auth, { authEpic } from 'reducers/auth';
import modal from 'reducers/modal';
import language from 'reducers/language';
import navbar from 'reducers/navbar';
import footer from 'reducers/footer';
import leftPanel from 'reducers/leftPanel';
import buyPQTestsSearchParams from 'reducers/buyPQTestsSearch';
import dashboardInfo from 'reducers/dashboardInfo';
import developmentAssessment from 'reducers/developmentAssessment';
import abilityTestSummary from 'reducers/abilityTestSummary';
import landingPage from 'reducers/landingPage';
import autoLogin from 'reducers/autoLogin';
import iframedProng from 'reducers/iframedProng';
import rightPanel from 'reducers/rightPanel';
import setFilter from 'reducers/setFilter';
import linkedInSignIn from 'reducers/linkedInSignIn';
import featureFlags from 'reducers/featureFlags';
import { calendar, toast, calendarRightMenu } from '@avamae/calendar-module';
import { chats, calls, translations } from '@avamae/communications-module';
import virtualAssessments from 'reducers/virtualAssessments';
import abilityTests from 'reducers/abilityTests';
import matchingProcess from './matchingProcess';
import accessibility from './accessibility';
import application from './application';
import { notificationsReducer as notifications } from 'reducers/notifications';
import opportunityDetails from 'reducers/opportunityDetails';
import assessment from './assessment';

export const rootReducer = combineReducers({
  auth,
  featureFlags,
  language,
  modal,
  leftPanel,
  rightPanel,
  navbar,
  footer,
  buyPQTestsSearchParams,
  dashboardInfo,
  developmentAssessment,
  abilityTestSummary,
  abilityTests,
  opportunityDetails,
  landingPage,
  setFilter,
  iframedProng,
  autoLogin,
  linkedInSignIn,
  calendar,
  toast,
  calendarRightMenu,
  virtualAssessments,
  chats,
  calls,
  translations,
  matchingProcess,
  accessibility,
  application,
  notifications,
  assessment,
});
export const rootEpic = combineEpics(authEpic);

export type Store = ReturnType<typeof rootReducer>;
