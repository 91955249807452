import React from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';

export enum InteractiveFeedbackMyDevelopmentCardType {
  DEVELOPMENT = 'DEVELOPMENT',
  CAREERS = 'CAREERS',
  ADAPTING = 'ADAPTING',
}

export const InteractiveFeedbackMyDevelopmentCard = ({
  active,
  myDevelopmentCardDetails,
  type,
}: {
  active?: number;
  myDevelopmentCardDetails: { par2: string; par1: string }[];
  type: InteractiveFeedbackMyDevelopmentCardType;
}) => {
  const { t } = useTranslation('Interactive+Feedback', { i18n });

  const isValid =
    active !== undefined &&
    active !== null &&
    active < myDevelopmentCardDetails.length
      ? true
      : false;
  const title1 =
    type === InteractiveFeedbackMyDevelopmentCardType.DEVELOPMENT
      ? t('SSCandidate_Interactive_Feedback_Area_Of_Personality')
      : type === InteractiveFeedbackMyDevelopmentCardType.CAREERS
      ? t('SSCandidate_Interactive_Feedback_May_Thrive')
      : t('SSCandidate_Interactive_Feedback_Hints_Tips');

  const title2 =
    type === InteractiveFeedbackMyDevelopmentCardType.DEVELOPMENT
      ? t('SSCandidate_Interactive_Feedback_Development_Possiblities')
      : type === InteractiveFeedbackMyDevelopmentCardType.CAREERS
      ? t('SSCandidate_Interactive_Feedback_Work_Harder')
      : '';

  const getClassType = () => {
    return type === InteractiveFeedbackMyDevelopmentCardType.DEVELOPMENT
      ? t('SSCandidate_Interactive_Feedback_Development')
      : type === InteractiveFeedbackMyDevelopmentCardType.CAREERS
      ? t('SSCandidate_Interactive_Feedback_Careers')
      : t('SSCandidate_Interactive_Feedback_Adapting');
  };

  return (
    <div className="InteractiveFeedbackMyDevelopmentCard">
      {isValid && myDevelopmentCardDetails[active as number].par1 && (
        <>
          <div className="InteractiveFeedbackMyDevelopmentCard__title">
            {title1}
          </div>
          <div
            className={`InteractiveFeedbackMyDevelopmentCard__par1 ${getClassType()}`}
          >
            {myDevelopmentCardDetails[active as number].par1}
          </div>
        </>
      )}
      {type !== InteractiveFeedbackMyDevelopmentCardType.ADAPTING &&
        isValid &&
        myDevelopmentCardDetails[active as number].par2 && (
          <>
            <div className="InteractiveFeedbackMyDevelopmentCard__title">
              {title2}
            </div>
            <div className="InteractiveFeedbackMyDevelopmentCard__par2">
              {myDevelopmentCardDetails[active as number].par2}
            </div>
          </>
        )}
    </div>
  );
};
