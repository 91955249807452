import React, { useState } from 'react';
import { useField, useFormikContext } from 'formik';
import { Dropdown } from 'components/helpers/Dropdown';
import { ErrorM } from 'components/helpers/ErrorM';
export type SelectOption = { value: any; label: string };

export interface FormSingleSelectFieldProps<T> {
  fieldName: keyof T & string;
  options: SelectOption[];
  label: string;
  readOnly?: boolean;
  dropdownProps?: any;
  useNumberForValue?: boolean;
  styles?: any;
  showError?: boolean;
  allowEmptyLabels?: boolean;
  setSelected?: (e: SelectOption) => void;
  placeholder?: string;
  showTooltip?: boolean;
  tooltipText?: string;
}

const FormSingleSelectCustomField = function <T>(
  props: FormSingleSelectFieldProps<T>
) {
  const {
    fieldName,
    label,
    options,
    readOnly,
    dropdownProps,
    styles,
    useNumberForValue = false,
    showError = true,
    allowEmptyLabels = false,
    setSelected,
    placeholder,
    showTooltip = false,
    tooltipText,
  } = props;
  const formik = useFormikContext<T>();
  const [field, meta, helpers] = useField(fieldName.toString());

  const handleChange = (e: SelectOption) => {
    if (!useNumberForValue)
      formik.setFieldValue(fieldName, {
        optionId: e.value,
        optionText: e.label,
      });
    else formik.setFieldValue(fieldName, e.value);
  };

  let value = options.find((o) => o.value === field.value?.optionId);
  if (useNumberForValue) value = options.find((o) => o.value === field.value);
  if (allowEmptyLabels)
    value = options.find((o) => o.value === field.value) ?? {
      value: null,
      label: '',
    };

  const [isFocused, setIsFocused] = useState(false);

  const onFocus = () => setIsFocused(true);
  const onBlur = () => setIsFocused(false);

  return (
    <div className="FormBox FormSideBySide">
      <div
        className={`FormLabel ${isFocused ? 'Focused' : ''}
      ${meta.error && showError ? 'ErrorLabelColor' : ''}
      `}
      >
        {label}
      </div>
      <div className="FormField">
        <Dropdown
          onChange={setSelected ? setSelected : handleChange}
          options={options}
          value={value}
          readOnly={readOnly}
          roundedRectangle
          dropdownProps={dropdownProps}
          onFocus={onFocus}
          onBlur={onBlur}
          styles={styles}
          placeholder={placeholder}
          showTooltip={showTooltip}
          tooltipText={tooltipText}
        />
        {showError && <ErrorM name={fieldName.toString()} />}
      </div>
    </div>
  );
};

export { FormSingleSelectCustomField };
