import HiringManagerProfileSidePanel from 'components/connectionsBlock/SidePanels/HiringManagerProfileSidePanel';
import MatchConnectionSidePanel from 'components/connectionsBlock/SidePanels/MatchConnectionSidePanel';
import MatchConnectionViewMessageSidePanel from 'components/connectionsBlock/SidePanels/MatchConnectionViewMessageSidePanel';
import { RightPanelFilters } from 'components/pages/jobBoard/RightPanelFilters';
import { MatchingEditDetailsModal } from 'components/pages/matchingProcess/MatchingEditDetailsModal';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectRightPanelState } from 'reducers/rightPanel';
import { RightPanel } from './RightPanel';
import { RightPanelSignIn } from './RightPanelSignIn';

const RightPanelRouter = () => {
  const rightPanelType = useSelector(selectRightPanelState);
  const [panel, setPanel] = useState<any>(null);
  const [width, setWidth] = useState<string>('');
  const [header, setHeader] = useState<string>('');
  const [panelOpen, setPanelOpen] = useState<any>(null);
  React.useEffect(() => {
    switch (rightPanelType.panelType) {
      case 'JOB_BOARD_SIGN_IN':
        setPanel(<RightPanelSignIn />);
        break;
      case 'JOB_BOARD_FILTERS':
        setPanel(<RightPanelFilters data={rightPanelType.data} />);
        break;
      case 'MATCHING_EDIT_DETAILS':
        setPanel(<MatchingEditDetailsModal data={rightPanelType.data} />);
        setWidth('Wide');
        setHeader(rightPanelType.data.header);
        break;
      case 'MATCH_CONNECTION_VIEW_MESSAGE':
        setPanel(
          <MatchConnectionViewMessageSidePanel data={rightPanelType.data} />
        );
        setWidth('ExtraWide');
        setHeader(rightPanelType.data.header);
        break;
      case 'HIRING_MANAGER_PROFILE':
        setPanel(<HiringManagerProfileSidePanel data={rightPanelType.data} />);
        setWidth('Wide');
        setHeader(rightPanelType.data.header);
        break;
      case 'MATCH_CONNECTION':
        setPanel(<MatchConnectionSidePanel data={rightPanelType.data} />);
        setWidth('ExtraWide');
        setHeader(rightPanelType.data.header);
        break;
      default:
        setPanel(null);
        break;
    }
  }, [rightPanelType.panelType, rightPanelType.data]);

  return (
    <>
      {panel ? (
        <RightPanel panelOpen={true} width={width} header={header}>
          {panel}
        </RightPanel>
      ) : (
        <RightPanel panelOpen={false}>{panel}</RightPanel>
      )}
    </>
  );
};

export { RightPanelRouter };
