import React from 'react';
import Button from 'components/ui/Button';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';

export interface PanelButtonsProps {
  submitButtonText?: string;
  noSubmit?: boolean;
  noCancel?: boolean;
  clearFilters?(): void;
  close(payload?: any): void;
}

// Panel Buttons - Declare as its own component - useFormikContext must be declared in a FC that is rendered inside <Formik>
const PanelButtons: React.FC<PanelButtonsProps> = ({
  submitButtonText = 'Submit',
  noSubmit,
  noCancel,
  close,
  clearFilters,
}) => {
  const { t } = useTranslation('Panel+Buttons', { i18n });

  const formik = useFormikContext();

  // Custom error to warn user that this must be rendered inside Formik
  if (!formik && !noSubmit) {
    throw new Error(`This component must be rendered inside Formik.`);
  }

  return (
    <div className="Buttons">
      {noCancel ? (
        ''
      ) : (
        <div className="Btn BtnCancel" onClick={close}>
          {t('SSCandidate_Panel_Buttons_Cancel')}
        </div>
      )}

      {clearFilters ? (
        <Button className="Btn BtnCancel" type="button" onClick={clearFilters}>
          {t('SSCandidate_Panel_Buttons_Clear_Filters')}
        </Button>
      ) : null}

      {noSubmit ? (
        <Button type="button" onClick={close}>
          {t('SSCandidate_Panel_Buttons_Close')}
        </Button>
      ) : (
        <Button type="submit">{submitButtonText}</Button>
      )}
    </div>
  );
};

export { PanelButtons };
