import { Link } from '@reach/router';
import { AppPath } from 'appConstants';
import Icon from 'assets/apply.clevry/Img_ProfileEmpty.jpg';
import { getDashboardInfo } from 'components/pages/joyAtWork/JoyAtWorkApi';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsAssessmentOnlyCandidate, signOut } from 'reducers/auth';
import { selectDashboardInfo, setDashboardInfo } from 'reducers/dashboardInfo';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';
import { ref } from 'yup';
import { PublicNavItem } from './LoggedInNavBar';

export const UserDropdownMenu = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const currentWebskin = window.sessionStorage.getItem('webskin')
    ? window.sessionStorage.getItem('webskin')
    : 'defaultTheme';

  const dashboardInfo = useSelector(selectDashboardInfo);
  const isAssessmentOnlyCandidate =
    useSelector(selectIsAssessmentOnlyCandidate) ||
    currentWebskin !== 'defaultTheme';

  const { t } = useTranslation('User+Dropdown+Menu', { i18n });

  const toggleIsOpen = () => {
    setIsOpen((oldIsOpen) => !oldIsOpen);
  };

  const handleSignOut = () => {
    dispatch(signOut());
  };

  const userMenuRef = useRef<any>();

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (userMenuRef.current && !userMenuRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  useEffect(() => {
    if (!dashboardInfo.isFetched) {
      getDashboardInfo().then((result) => {
        if (result.response) {
          dispatch(setDashboardInfo(result.response));
        }
      });
    }
  }, [userMenuRef]);

  const userDropdownMenuItems: any[] = [
    {
      path: `${AppPath.MATCHING_PROCESS}?page=your-matching-profile`,
      label: t('SSCandidate_User_Dropdown_Menu_Matching_Profile'),
    },
    {
      path: AppPath.PROFILE,
      label: t('SSCandidate_User_Dropdown_Menu_CV'),
    },
    {
      path: AppPath.ACCOUNT,
      label: t('SSCandidate_User_Dropdown_Menu_Account'),
    },
    {
      path: AppPath.SETTINGS,
      label: t('SSCandidate_User_Dropdown_Menu_Settings'),
    },
  ];

  const assessmentOnlyUserDropdownMenuItems: any[] = [
    {
      path: AppPath.ACCOUNT,
      label: t('SSCandidate_User_Dropdown_Menu_Account'),
    },
  ];

  const selectedNavItems = isAssessmentOnlyCandidate
    ? assessmentOnlyUserDropdownMenuItems
    : userDropdownMenuItems;

  return (
    <div
      className={`UserMenu ${isOpen ? 'Highlighted' : ''} Bordered`}
      ref={userMenuRef}
      onClick={toggleIsOpen}
    >
      <button className="UserMenuOpen">
        <img
          src={dashboardInfo.profilePictureURL ?? Icon}
          alt={t('SSCandidate_User_Dropdown_Menu_Alt_Icon')}
        />
        <div className={`UserMenuContent ${isOpen ? 'Open' : ''}`}>
          {selectedNavItems
            ? selectedNavItems.map((item: any) => {
                return (
                  <Link to={item.path} className="MenuLink">
                    {item.label}
                  </Link>
                );
              })
            : null}
          <div onClick={handleSignOut} className="MenuLink" tabIndex={0}>
            {t('SSCandidate_User_Dropdown_Menu_Sign_Out')}
          </div>
        </div>
      </button>
    </div>
  );
};
