import instance, { ApiResponse } from 'api';
import axios from 'api';
import { AxiosResponse } from 'axios';
import { BaseApi } from 'baseApi';
import { endpoints } from 'config';

type LanguageListAPIResponse = {
  response: any | null;
  errors: any[] | null;
};

/*
export const getSettings = async (): Promise<SettingsDetailsResponse> => {
  try {
    const response: AxiosResponse<SettingsDetailsResponse> = await axiosInstance.get(
      endpoints.profile.mockGetProfileInformation
    );
    return response.data;
  } catch (exception) {
    // TODO(HC): Determine how to handle axios error.
    throw new Error(exception);
  }
};
*/

export class LanguageSearchDropdownApi extends BaseApi {
  public static setLanguage = async (payload: any): Promise<any> => {
    try {
      const result: AxiosResponse<ApiResponse<any>> = await instance.post(
        endpoints.languages.setLanguage,
        null,
        {
          params: { cultureInfo: payload },
        }
      );
      if (LanguageSearchDropdownApi.isResponseSuccessful(result.data)) {
        return LanguageSearchDropdownApi.successResponse(result.data.status);
      }
    } catch (error) {
      if ((error as any).response) {
        const errors = (error as any).response.errors;
        if (errors) {
          return LanguageSearchDropdownApi.errorResponse(errors);
        }
      }
    }
  };

  public static getLanguageList =
    async (): Promise<LanguageListAPIResponse> => {
      try {
        const response = await axios.get(endpoints.languages.getLanguageList, {
          withCredentials: true,
        });

        if (
          response.data.status === '1' &&
          response.data.details &&
          response.data.details.length > 0
        ) {
          return {
            response: response.data.details,
            errors: null,
          };
        } else {
          return {
            response: null,
            errors: response.data.errors,
          };
        }
      } catch (err: any) {
        return {
          response: null,
          errors: err,
        };
      }
    };
}
