import { Link, Match, useLocation } from '@reach/router';
import React from 'react';

type NavLinkProps = {
  path: string;
  onClick: () => void;
  disabled?: boolean;
  isDropdownMenu?: boolean;
};

export const NavLink: React.FC<NavLinkProps> = ({
  path,
  children,
  onClick,
  disabled,
  isDropdownMenu = false,
}) => {
  const location = useLocation();

  if (isDropdownMenu === true) {
    return (
      <Match path={location.pathname.includes(path) ? location.pathname : path}>
        {/* Allows dropdown parent to have highlighted border when on subpage */}
        {({ match }) => (
          <div
            className={`NavLink Dropdown ${match ? 'Active ' : ''}${
              disabled ? 'Disabled' : ''
            }`}
            onClick={onClick}
          >
            {children}
          </div>
        )}
      </Match>
    );
  } else {
    return (
      <Match path={path}>
        {({ match }) => (
          <Link
            to={path}
            className={`NavLink ${match ? 'Active ' : ''}${
              disabled ? 'Disabled' : ''
            }`}
            onClick={onClick}
          >
            {children}
          </Link>
        )}
      </Match>
    );
  }
};
