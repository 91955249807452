import React, { useState } from 'react';
import { InteractiveFeedbackMenuHeading } from './InteractiveFeedbackMenuHeading';
import {
  InteractiveFeedbackMenuRow,
  InteractiveFeedbackMenuRowProps,
} from './InteractiveFeedbackMenuRow';
import useMedia from 'use-media';
import { Button } from 'components/button/Button';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';

export type InteractiveFeedbackMenuProps = {
  rows: Pick<
    InteractiveFeedbackMenuRowProps,
    'icon' | 'text' | 'rowClick' | 'type' | 'score' | 'scoreType'
  >[];
  title: string;
  active: number | null;
  height: number;
  setActive: React.Dispatch<React.SetStateAction<number | null>>;
  headingIcon?: string;
  headingCloseClickHandler?: () => void;
  backArrowClickHandler?: () => void;
  score?: number;
  downloadReport?: boolean;
  fullProfile?: boolean;
  setAlternateView?: React.Dispatch<React.SetStateAction<boolean>>;
  assessmentID?: number;
  reportCode?: string;
  isCandidateReport?: boolean;
  hideStenScores?: boolean;
  sectionData?: any;
};

export const InteractiveFeedbackMenu = ({
  rows,
  title,
  headingCloseClickHandler,
  active,
  setActive,
  headingIcon,
  height,
  score,
  downloadReport,
  fullProfile,
  setAlternateView,
  reportCode,
  assessmentID,
  isCandidateReport,
  hideStenScores,
  sectionData,
}: InteractiveFeedbackMenuProps) => {
  const isTablet = useMedia('(max-width: 768px)');
  const { t } = useTranslation('Interactive+Feedback', { i18n });

  return (
    <div className="InteractiveFeedbackMenu">
      {isTablet && <div style={{ height: '20px', width: '100%' }} />}
      <InteractiveFeedbackMenuHeading
        title={title}
        icon={headingIcon}
        headingCloseClickHandler={headingCloseClickHandler}
        score={score}
        reportCode={reportCode}
        assessmentID={assessmentID}
        isCandidateReport={isCandidateReport}
        hideStenScores={hideStenScores}
        sectionData={sectionData}
      />
      {rows.map(({ icon, text, rowClick, type, score, scoreType }, idx) => (
        <InteractiveFeedbackMenuRow
          key={`${text}${idx}`}
          icon={icon}
          text={text}
          rowClick={rowClick}
          type={type}
          scoreType={scoreType}
          score={score}
          rowNumber={idx}
          setActive={setActive}
          active={idx === active}
          hideStenScores={hideStenScores}
        />
      ))}
      <div
        className="InteractiveFeedbackMenu__Spacer"
        style={{ ...(isTablet ? { height: height + 161 } : {}) }}
      />
      {fullProfile && (
        <Button
          className="InteractiveFeedbackMenu_FullProfileButton"
          priority="primary"
          type="submit"
          onClick={() => {
            if (setAlternateView) setAlternateView(true);
          }}
        >
          {t('SSCandidate_Interactive_Feedback_See_Full_Profile')}
        </Button>
      )}
    </div>
  );
};
