import React, { useState } from 'react';
import { InteractiveFeedbackMenuCardHub } from '../components/InteractiveFeedbackMenuCardHub';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';
import { Spinner } from 'components/spinner/Spinner';
import ErrorFallback from 'components/fallbacks/ErrorFallback';
import {
  MySalesStyleCardOptions,
  useGetMySalesStyleDetails,
} from '../hooks/useGetMySalesStyleDetails';
import useMedia from 'use-media';

type InteractiveFeedbackMySalesStyleProps = {
  sectionData: any;
};

export const InteractiveFeedbackMySalesStyle: React.FC<InteractiveFeedbackMySalesStyleProps> = ({
  sectionData,
}) => {
  const [animateDismount, setAnimateDismount] = useState(false);
  const [alternateView, setAlternateView] = useState(false);
  const isTablet = useMedia('(max-width: 768px)');

  const { t } = useTranslation('Interactive+Feedback', { i18n });

  const [data, loading, errors] = useGetMySalesStyleDetails();

  if (loading || data === undefined || data === null) return <Spinner />;

  if (errors) return <ErrorFallback />;

  const {
    salesStyleWheelImage,
    salesStyleMenuCards,
    reportCode,
    isCandidateReport,
  } = data as {
    salesStyleWheelImage: string;
    salesStyleMenuCards: MySalesStyleCardOptions;
    reportCode: string;
    isCandidateReport: boolean;
  };

  return (
    <div className="InteractiveFeedbackMySalesStyle WidthContent HeightContent">
      <InteractiveFeedbackMenuCardHub
        title={t('SSCandidate_Interactive_Feedback_My_Sales_Style')}
        cardDetails={salesStyleMenuCards.salesStyleCards}
        rows={[]}
        setAnimateDismount={setAnimateDismount}
        animateDismount={animateDismount}
        onAnimationEnd={() => {
          setAlternateView(true);
        }}
        reportCode={reportCode}
        isCandidateReport={isCandidateReport}
        wheelImage={salesStyleWheelImage}
        graphType={'SALES'}
        sectionData={sectionData}
      />
    </div>
  );
};
