import React, { useEffect, useState } from 'react';
import MessagesIcon from 'assets/apply.clevry/Icon_Header_Messages_DarkGreen.png';
import { useSelector } from 'react-redux';
import { selectAuthStatus } from 'reducers/auth';
import {
  getUnreadMessageCount,
  parseGetUnreadMessageCount,
} from 'components/pages/messages/MessagesApi';

type LoggedInHeaderMessagesIconProps = {};

const LoggedInHeaderMessagesIcon: React.FC<
  LoggedInHeaderMessagesIconProps
> = ({}) => {
  const [numberOfUnreadMessages, setNumberOfUnreadMessages] = useState('');

  const authStatus = useSelector(selectAuthStatus);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    (async () => {
      if (authStatus === 'signed_in') {
        const unreadMessages = await getUnreadMessageCount({});
        if (unreadMessages && unreadMessages.response) {
          setNumberOfUnreadMessages(
            parseGetUnreadMessageCount(unreadMessages.response).toString()
          );
        }
      }
    })();
    intervalId = setInterval(() => {
      (async () => {
        const unreadMessages = await getUnreadMessageCount({});
        if (unreadMessages && unreadMessages.response) {
          setNumberOfUnreadMessages(
            parseGetUnreadMessageCount(unreadMessages.response).toString()
          );
        }
      })();
    }, 90000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div
      className="LoggedInHeaderMessagesIcon"
      onClick={() => {
        setNumberOfUnreadMessages('');
      }}
    >
      {numberOfUnreadMessages && numberOfUnreadMessages !== '0' ? (
        <div className="LoggedInHeaderMessagesIcon__notifications">
          {numberOfUnreadMessages}
        </div>
      ) : (
        ''
      )}
      <img src={MessagesIcon} alt="icon"></img>
    </div>
  );
};

export default LoggedInHeaderMessagesIcon;
