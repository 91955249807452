import { useState, useEffect } from 'react';

type CountdownHookProps = {
  initialSeconds: number;
  onTimeEnd: () => void;
};

const useCountdown = ({ initialSeconds, onTimeEnd }: CountdownHookProps) => {
  const [remainingSeconds, setRemainingSeconds] =
    useState<number>(initialSeconds);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    if (remainingSeconds > 0) {
      interval = setInterval(() => {
        setRemainingSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    }

    if (remainingSeconds === 0) {
      onTimeEnd();
    }

    return () => clearInterval(interval!);
  }, [remainingSeconds]);

  const minutes = Math.floor(remainingSeconds / 60);
  const seconds = remainingSeconds % 60;

  return { minutes, seconds, remainingSeconds };
};

export default useCountdown;
