import i18n from 'i18n/i18n';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InteractiveFeedbackGraphType } from './InteractiveFeedbackGraph';
import { isFirefox } from 'react-device-detect';
import { useWindowDimensions } from 'helpers/useGetWindowDimensions';

export type InteractiveFeedbackGraphRimOverlayProps = {
  active: number | null;
  setActive: React.Dispatch<React.SetStateAction<number | null>>;
  graphActive: boolean;
  setGraphActive: React.Dispatch<React.SetStateAction<boolean>>;
  graphType: string;
};

export const InteractiveFeedbackGraphRimOverlay = ({
  active,
  setActive,
  graphActive,
  setGraphActive,
  graphType,
}: InteractiveFeedbackGraphRimOverlayProps) => {
  const [visibleSegment, setVisibleSegment] = useState<number | null>(null);
  const [isLowerHalfSegmentT, setIsLowerHalfSegmentT] =
    useState<boolean>(false);

  const { t } = useTranslation('Interactive+Feedback', { i18n });

  const { width } = useWindowDimensions();

  useEffect(() => {
    if (graphActive === false) {
      setVisibleSegment(null);
    } else {
      setVisibleSegment(active);
    }
  }, [graphActive, active]);

  //the paths
  const colors = [
    'red',
    'green',
    'blue',
    'yellow',
    'purple',
    'orange',
    'lightblue',
    'lightred',
    'lightgreen',
  ];
  const teamStrengthsText = [
    'The Perfector',
    'The Pragmatist',
    'The Producer',
    'The Catalyst',
    'The Harmoniser',
    'The Guide',
    'The Visionary',
    'The Explorer',
  ];
  const leadershipStrengthsText = [
    { line1: 'Decision making', line2: 'and ambiguity' },
    { line1: 'Achievement' },
    { line1: 'Ethical stance' },
    { line1: 'Coping with', line2: 'pressure' },
    { line1: 'Confidence and', line2: 'self belief' },
    { line1: 'Impact' },
    { line1: 'Openness to', line2: 'change' },
    { line1: 'Looking ahead' },
    { line1: 'Learning and', line2: 'developing' },
    { line1: 'Working with', line2: 'others' },
    { line1: 'Connecting and', line2: 'networking' },
    { line1: 'Supporting others' },
  ];

  //the circle's radius
  let r = 140;

  //points used for the start and end of every arc
  let points = [];

  // SVG textpath vertical offset - slight adjustment needed for Team Strengths page
  let dy = 0;

  let numberOfSegments = 0;
  let strokeWidth = 0;
  let fontSize = 8;

  const trimText = (text: string, threshold: number) => {
    if (text.length <= threshold) return text;
    return text.substring(0, threshold).concat('...');
  };

  if (graphType === InteractiveFeedbackGraphType.TEAM) {
    r = 140;
    dy = 1;
    numberOfSegments = 8;
    strokeWidth = 18;
    fontSize = 10;
  }
  if (graphType === InteractiveFeedbackGraphType.LEADERSHIP) {
    r = 133.5;
    dy = 0;
    numberOfSegments = 12;
    strokeWidth = 31;
    fontSize = 7;
  }

  //calculating the points used for the start and end of every arc
  for (
    let angle = -Math.PI / 2;
    angle <= 2 * Math.PI - Math.PI / 2;
    angle += (2 * Math.PI) / numberOfSegments
  ) {
    // console.log(
    //   'angle endpoint in degrees is ',
    //   (2 * Math.PI - Math.PI / 2) * 57.2958
    // );

    // console.log('angle in radians is ', angle);

    // console.log(angle * 57.2958); // Converts angle in radians to degrees
    let point = {
      x: 0,
      y: 0,
    };
    point.x = r * Math.cos(angle);
    point.y = r * Math.sin(angle);
    points.push(point);
  }

  const svgSegmentStyles = [];
  //   const svgSegmentStylesLower = [];

  //defining the valueof the d attribute of every path as an arc with the given radius, starting at the previous point and ending at the actual point
  for (let i = 0, l = points.length; i < l - 1; i++) {
    let point = points[i + 1];
    let prev = points[i];

    let d = `M${prev.x},${prev.y} A${r},${r},0,0,1,${point.x},${point.y}`;

    if (i > (l - 2) / 4 && i < (l - 1) * 0.75) {
      d = `M${prev.x},${prev.y} A${r},${r},0,0,0,${point.x},${point.y}`;
    } // Changes 5th elliptical arc curve creation parameter from 1 to 0 to instead create an anti-clockwise turning arc - applies to the bottom-half segments of circle being built

    // setting the d attribute

    svgSegmentStyles.push(d);
  }

  const upperDy = !isFirefox
    ? 0
    : graphType === InteractiveFeedbackGraphType.LEADERSHIP &&
      visibleSegment !== undefined &&
      visibleSegment !== null &&
      leadershipStrengthsText[visibleSegment] &&
      leadershipStrengthsText[visibleSegment].line2
    ? `0`
    : graphType === InteractiveFeedbackGraphType.LEADERSHIP &&
      isLowerHalfSegmentT
    ? '-1.5%'
    : graphType === InteractiveFeedbackGraphType.LEADERSHIP
    ? '1%'
    : graphType === InteractiveFeedbackGraphType.TEAM && isLowerHalfSegmentT
    ? '-4%'
    : graphType === InteractiveFeedbackGraphType.TEAM
    ? '1%'
    : '';

  
  useEffect(() => {
    if (
      active &&
      active + 1 > numberOfSegments / 4 &&
      active < numberOfSegments * 0.75
    ) {
      setIsLowerHalfSegmentT(true);
    } else {
      setIsLowerHalfSegmentT(false);
    }
  }, [active]);

  return (
    <div className="InteractiveFeedbackGraphRim">
      <svg
        viewBox="-150 -150 300 300"
        width={width <= 768 ? '350' : '400'}
        fill="none"
        strokeWidth={strokeWidth}
      >
        {svgSegmentStyles.map((style, idx) => {
          const isLowerHalfSegment =
            idx > (svgSegmentStyles.length - 1) / 4 &&
            idx < (svgSegmentStyles.length - 1) * 0.75;

          return (
            <path
              className={`${isLowerHalfSegment ? 'LowerHalfSegment' : ''}`}
              key={idx}
              id={idx.toString()}
              stroke={
                idx === visibleSegment ? '#edf5fa' : 'transparent'
                //   idx === visibleSegment ? colors[visibleSegment] : 'transparent'
              }
              d={style}
              style={{ cursor: 'pointer', color: 'white' }}
              onClick={(e) => {
                if (visibleSegment === idx) {
                  setVisibleSegment(null);
                  setGraphActive(false);
                  setActive(null);
                } else {
                  setVisibleSegment(idx);
                  setGraphActive(true);
                  setActive(idx);

                  // if (isLowerHalfSegment) {
                  //   setIsLowerHalfSegmentT(true);
                  // } else {
                  //   setIsLowerHalfSegmentT(false);
                  // }
                }
              }}
            />
          );
        })}

        <text
          className={`${
            isLowerHalfSegmentT ? 'LowerHalfSegment' : ''
          } ${visibleSegment}`}
          width="500"
          fill="#39a5cf"
          fontSize={fontSize}
          fontFamily="TTFirs-ExtraBold"
          fontWeight="500"
          dominantBaseline="auto"
          baselineShift={`${
            graphType === InteractiveFeedbackGraphType.LEADERSHIP &&
            visibleSegment !== undefined &&
            visibleSegment !== null &&
            leadershipStrengthsText[visibleSegment] &&
            leadershipStrengthsText[visibleSegment].line2
              ? '20%'
              : graphType === InteractiveFeedbackGraphType.TEAM &&
                isLowerHalfSegmentT
              ? '-10%'
              : !isLowerHalfSegmentT
              ? '-20%'
              : ''
          }`}
          dy={upperDy}
          textAnchor="middle" // Combined with startOffset of 51% (50% looks more off) to horizontally centre text independent of text length
        >
          <textPath startOffset="51%" href={`#${visibleSegment}`}>
            {visibleSegment !== undefined &&
              visibleSegment !== null &&
              (graphType === InteractiveFeedbackGraphType.TEAM
                ? teamStrengthsText[visibleSegment]
                : graphType === InteractiveFeedbackGraphType.LEADERSHIP
                ? leadershipStrengthsText[visibleSegment].line1
                : '')}
          </textPath>
        </text>
        {graphType === InteractiveFeedbackGraphType.LEADERSHIP &&
          visibleSegment !== undefined &&
          visibleSegment !== null &&
          leadershipStrengthsText[visibleSegment] &&
          leadershipStrengthsText[visibleSegment].line2 && (
            <text
              className={`${
                isLowerHalfSegmentT ? 'LowerHalfSegment' : ''
              } ${visibleSegment}`}
              width="500"
              fill="#39a5cf"
              fontSize={fontSize}
              fontFamily="TTFirs-ExtraBold"
              fontWeight="500"
              dominantBaseline="hanging"
              baselineShift="-5%"
              dy={`${
                isFirefox && isLowerHalfSegmentT
                  ? '-15px'
                  : isFirefox
                  ? '2px'
                  : '0'
              }`}
              textAnchor="middle" // Combined with startOffset of 51% (50% looks more off) to horizontally centre text independent of text length
            >
              <textPath startOffset="51%" href={`#${visibleSegment}`}>
                {visibleSegment !== undefined &&
                  visibleSegment !== null &&
                  leadershipStrengthsText[visibleSegment].line2}
              </textPath>
            </text>
          )}
      </svg>
    </div>
  );
};
