import React from 'react';

type MessagesBodyProps = {
  children: React.ReactNode;
};

const MessagesBody: React.FC<MessagesBodyProps> = ({ children }) => {
  return <div className="MessagesBody">{children}</div>;
};

export default MessagesBody;
