import i18n from 'i18n/i18n';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { closeRightPanel, openRightPanel } from 'reducers/rightPanel';
import {
  expressInterest,
  getMatchConnectionDetails,
  updateCandidateConsent,
} from '../ConnectionAPI';
import defaultIcon from 'assets/apply.clevry/Img_ProfileEmpty.jpg';
import { Spinner } from 'components/spinner/Spinner';
import { Button } from 'components/button/Button';
import JobTypeLogo from 'assets/apply.clevry/Icon_Label_JobType.png';
import LocationLogo from 'assets/apply.clevry/Icon_Label_Location.png';
import SalaryLogo from 'assets/apply.clevry/Icon_Label_Salary.png';
import { Toast } from '../../toastMessages/toast';
import MessagesBody from 'components/pages/messages/MessagesBody';
import MessagesCommunicationsModule from 'components/pages/messages/MessagesCommunicationsModule';
import Messages from 'components/pages/messages/Messages';
import ToggleSwitch from 'components/toggleSwitch/ToggleSwitch';

type MatchingConnectionReviewSidePanelProps = {
  data: any;
};

const MatchConnectionReviewMatchSidePanel: React.FC<
  MatchingConnectionReviewSidePanelProps
> = ({ data }) => {
  const { t } = useTranslation('Connection+Match+Side+Panel', { i18n });
  const dispatch = useDispatch();

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');
  const [connectionDetails, setConnectionDetails] = useState<any>();
  const [connectionAccepted, setConnectionAccepted] = useState<boolean>(false);
  const [isSharingDetails, setIsSharingDetails] = useState<boolean>();

  const handleClosePanel = () => {
    dispatch(closeRightPanel());
  };

  const handleSubmit = async (
    candidateInterest: boolean,
    shareConsent: boolean
  ) => {
    const payload = {
      hiringManagerSearchCandidateInterestId: data.interestId,
      isCandidateInterest: candidateInterest,
      consentToShareData: shareConsent,
    };

    const expressInterestResult = await expressInterest(payload);

    if (
      expressInterestResult.response &&
      expressInterestResult.response.details.chatsID
    ) {
      candidateInterest
        ? Toast.openToastSuccessMessage(
            t('SSCandidate_Connection_Match_Side_Panel_Toast_Accept_Success')
          )
        : Toast.openToastSuccessMessage(
            t('SSCandidate_Connection_Match_Side_Panel_Toast_Block_Success')
          );
      handleClosePanel();
    } else {
      Toast.openToastErrorMessage(
        t('SSCandidate_Connection_Match_Side_Panel_Toast_Error')
      );
    }
  };

  const handleShareDetailsToggle = async () => {
    const updatedSharingState = !isSharingDetails;
    setIsSharingDetails(updatedSharingState);

    const payload = {
      hiringManagerSearchCandidateInterestId: data.interestId,
      consentToShareData: updatedSharingState,
    };

    const updateCandidateConsentResult = await updateCandidateConsent(payload);

    if (updateCandidateConsentResult.response) {
      console.log('Consent to share data updated');
    } else {
      Toast.openToastErrorMessage(
        t('SSCandidate_Connection_Match_Side_Panel_Toast_Error')
      );
    }
  };

  useEffect(() => {
    const fetchDetails = async () => {
      const connectionDetailsResult = await getMatchConnectionDetails(
        data.interestId
      );

      if (
        connectionDetailsResult.response &&
        connectionDetailsResult.response.details
      ) {
        setConnectionDetails(connectionDetailsResult.response.details);

        if (
          connectionDetailsResult.response.details.candidateConsentToShareData
        ) {
          setIsSharingDetails(
            connectionDetailsResult.response.details.candidateConsentToShareData
          );
        }

        if (connectionDetailsResult.response.details.chatsId !== 0) {
          setConnectionAccepted(true);
        }
        setLoading(false);
      } else {
        setLoading(false);
        setError('Something went wrong, please reload the page.');
      }
    };

    fetchDetails();
  }, [data.interestId]);

  if (loading) return <Spinner />;

  return (
    <div
      className={`MatchConnectionSidePanel ${
        connectionAccepted ? 'ViewMessage' : 'Review'
      }`}
    >
      <div className="ReviewContainer">
        <div className="ConnectionHeader">
          <div className="HiringManagerDetails">
            <div className="HiringManagerProfilePic">
              <img
                src={
                  connectionDetails?.profileLogoURL
                    ? connectionDetails.profileLogoURL
                    : defaultIcon
                }
                alt="Hiring manager avatar"
              ></img>
            </div>
            <div className="HiringManagerTitle">
              <span className="HiringManagerName">
                {connectionDetails?.hiringManagerFullName}
              </span>
              <span className="HiringManagerCompany">
                works for {connectionDetails?.companyName}
              </span>
            </div>
          </div>
          <div className="HiringManagerButton">
            <Button
              priority="secondary"
              onClick={() => {
                handleClosePanel();
                setTimeout(() => {
                  dispatch(
                    openRightPanel({
                      panelType: 'HIRING_MANAGER_PROFILE',
                      data: {
                        header: 'Hiring manager',
                        HiringManagerProfileId:
                          connectionDetails?.hiringManagerProfileId,
                      },
                    })
                  );
                }, 250);
              }}
            >
              {t(
                'SSCandidate_Connection_Match_Side_Panel_View_Hiring_Manager_Details'
              )}
            </Button>
          </div>
        </div>

        <div className="ConnectionDetails">
          <div className="ConnectionDetailsHeader">
            <h1>
              {connectionDetails?.searchName
                ? connectionDetails.searchName
                : t(
                    'SSCandidate_Connection_Match_Side_Panel_Job_Title_Not_Set'
                  )}
            </h1>
            <div className="JobFeatureContainer">
              <p className="JobFeature" style={{ marginLeft: '0' }}>
                <img
                  src={JobTypeLogo}
                  alt={t('SSCandidate_Job_Description_Alt_Icon')}
                ></img>
                {connectionDetails?.workSchedulesName
                  ? connectionDetails.workSchedulesName
                  : t(
                      'SSCandidate_Connection_Match_Side_Panel_WorkType_Not_Set'
                    )}
              </p>
              {connectionDetails?.salary && (
                <p className="JobFeature">
                  <img
                    src={SalaryLogo}
                    alt={t('SSCandidate_Job_Description_Alt_Icon')}
                  ></img>
                  {connectionDetails?.salary
                    ? connectionDetails.salary
                    : t(
                        'SSCandidate_Connection_Match_Side_Panel_Salary_Not_Set'
                      )}
                </p>
              )}
              <p className="JobFeature">
                <img
                  src={LocationLogo}
                  alt={t('SSCandidate_Job_Description_Alt_Icon')}
                ></img>
                {connectionDetails?.location
                  ? connectionDetails.location
                  : t(
                      'SSCandidate_Connection_Match_Side_Panel_Location_Not_Set'
                    )}
              </p>
            </div>

            {connectionAccepted && (
              <div className="Section ToggleSection">
                <div className="TextContainer">
                  <div className="Text">
                    {t(
                      'SSCandidate_Connection_Match_Side_Panel_Share_Contact_Details'
                    )}
                  </div>
                </div>

                <div className="ToggleContainer">
                  <ToggleSwitch
                    onChange={() => handleShareDetailsToggle()}
                    value={isSharingDetails}
                  />
                </div>
              </div>
            )}
          </div>

          <div
            className="ConnectionDescription"
            dangerouslySetInnerHTML={{
              __html: connectionDetails?.description
                ? connectionDetails.description
                : t(
                    'SSCandidate_Connection_Match_Side_Panel_Job_Description_Not_Set'
                  ),
            }}
          />
        </div>

        {!connectionAccepted && (
          <div className="ModalFooter">
            <div className="Error">
              <div className="Text">{error && error}</div>
            </div>

            <Button
              className="Block"
              priority="primary"
              type="submit"
              onClick={() => handleSubmit(false, false)}
            >
              {t('SSCandidate_Connection_Match_Side_Panel_Block_Contact')}
            </Button>
            <Button
              className="Accept"
              priority="tertiary"
              type="submit"
              onClick={() => handleSubmit(true, false)}
            >
              {t('SSCandidate_Connection_Match_Side_Panel_No_Share')}
            </Button>
            <Button
              className="Accept_Share"
              priority="primary"
              type="submit"
              onClick={() => handleSubmit(true, true)}
            >
              {t('SSCandidate_Connection_Match_Side_Panel_Yes_Share')}
            </Button>
          </div>
        )}
      </div>

      {connectionAccepted && (
        <div className="MessagesContainer">
          <div className="MessagesChatOnly">
            <div className="MessagesMain">
              <div className="Messages">
                <div className="MessagesBody">
                  <MessagesBody>
                    <MessagesCommunicationsModule
                      selectedChat={connectionDetails.chatsId}
                      SMSPush={false}
                    />
                  </MessagesBody>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MatchConnectionReviewMatchSidePanel;
