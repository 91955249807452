import { useState, useCallback, useEffect } from 'react';
import {
  InteractiveFeedbackCardProps,
  InteractiveFeedbackCardType,
} from '../components/InteractiveFeedbackCard';
import { InteractiveFeedbackMenuProps } from '../components/InteractiveFeedbackMenu';
import {
  AdaptingStrengthType,
  CareerSuggestionType,
  DevelopmentSuggestionType,
  getDevelopmentScores,
} from '../InteractiveFeedbackApi';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';
import { selectAuthStatus, selectRedirectValues } from 'reducers/auth';
import { useSelector } from 'react-redux';
import { selectDashboardInfo } from 'reducers/dashboardInfo';
import { selectAssessmentId } from 'reducers/virtualAssessments';
import { images } from 'config';

export const useGetMyDevelopmentDetails = () => {
  const { t } = useTranslation('Interactive+Feedback', { i18n });
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState(false);
  const redirectValues = useSelector(selectRedirectValues);
  const dashboardInfo = useSelector(selectDashboardInfo);
  const myHistoryRouteAssessmentId = useSelector(selectAssessmentId);
  const authStatus = useSelector(selectAuthStatus);

  let cultureCode: string | null = null;
  if (dashboardInfo && dashboardInfo.cultureCode) {
    cultureCode = dashboardInfo.cultureCode;
  }

  let assessmentId: number | null = null;
  if (redirectValues && redirectValues.length > 0) {
    assessmentId = redirectValues.find(
      ({ label, value }) => label === 'AssessmentID'
    )?.value;
  } else if (myHistoryRouteAssessmentId) {
    assessmentId = myHistoryRouteAssessmentId;
  }

  const parseMenuOptions = (
    details:
      | DevelopmentSuggestionType[]
      | CareerSuggestionType[]
      | AdaptingStrengthType[],
    title: string
  ): Omit<
    InteractiveFeedbackMenuProps,
    'height' | 'setHeight' | 'active' | 'setActive'
  > => {
    const rows = details.map(({ englishScaleName, scaleName, iconURL }) => ({
      text: scaleName ?? englishScaleName,
      icon: iconURL ?? images.interactiveFeedback.defaultScaleIcon, // replace with network icon
      rowClick: (active: boolean) => {},
    }));
    return {
      rows,
      title,
    };
  };

  type ParseDevelopmentInnerCardOptionsResponse = {
    cardsGeneral: Omit<
      InteractiveFeedbackCardProps,
      'numberOfItems' | 'height' | 'setHeight'
    >[];
    cardsSpecific: { par1: string | null; par2?: string | null }[];
  };

  const parseDevelopmentCardOptions = (
    details: DevelopmentSuggestionType[]
  ): ParseDevelopmentInnerCardOptionsResponse => {
    const cardsGeneral = details.map(
      ({ englishScaleName, scaleName, imageURL, colour }) => ({
        type: InteractiveFeedbackCardType.IMAGE,
        heading: scaleName ?? englishScaleName,
        topImage: imageURL ?? images.interactiveFeedback.defaultScaleImage,
        bottomColor: colour ?? images.interactiveFeedback.defaultScaleColour,
      })
    );
    const cardsSpecific = details.map(
      ({ areaOfPersonality, developmentPossibilities }) => ({
        par2: developmentPossibilities,
        par1: areaOfPersonality,
      })
    );
    return { cardsGeneral, cardsSpecific };
  };

  const parseCareerCardOptions = (
    details: CareerSuggestionType[]
  ): ParseDevelopmentInnerCardOptionsResponse => {
    const cardsGeneral = details.map(
      ({ englishScaleName, scaleName, imageURL, colour }) => ({
        type: InteractiveFeedbackCardType.IMAGE,
        heading: scaleName ?? englishScaleName,
        topImage: imageURL ?? images.interactiveFeedback.defaultScaleImage,
        bottomColor: colour ?? images.interactiveFeedback.defaultScaleColour,
      })
    );
    const cardsSpecific = details.map(({ strengthText, concernText }) => ({
      par1: strengthText,
      par2: concernText,
    }));
    return { cardsGeneral, cardsSpecific };
  };

  const parseAdaptingCardOptions = (
    details: AdaptingStrengthType[]
  ): ParseDevelopmentInnerCardOptionsResponse => {
    const cardsGeneral = details.map(
      ({ englishScaleName, scaleName, imageURL, colour }) => ({
        type: InteractiveFeedbackCardType.IMAGE,
        heading: scaleName ?? englishScaleName,
        topImage: imageURL ?? images.interactiveFeedback.defaultScaleImage,
        bottomColor: colour ?? images.interactiveFeedback.defaultScaleColour,
      })
    );
    const cardsSpecific = details.map(({ hintsAndTips }) => ({
      par1: hintsAndTips,
    }));
    return { cardsGeneral, cardsSpecific };
  };

  const getOptions = useCallback(async () => {
    const result = await getDevelopmentScores({
      ...(assessmentId ? { AssessmentId: assessmentId } : {}),
      ...(cultureCode ? { LanguageCultureInfo: cultureCode } : {}),
    });
    if (result.response && result.response.details) {
      setLoading(false);

      const isCandidateReport = result.response.details.isCandidateReport;
      const reportCode = result.response.details.reportCode;

      const developmentMenuOptions = parseMenuOptions(
        result.response.details.developmentSuggestions,
        t('SSCandidate_Interactive_Feedback_Development_Suggestions')
      );

      const careerMenuOptions = parseMenuOptions(
        result.response.details.careerSuggestions,
        t('SSCandidate_Interactive_Feedback_Your_Career_Suggestions')
      );

      const adaptingMenuOptions = parseMenuOptions(
        result.response.details.adaptingStrengths,
        t('SSCandidate_Interactive_Feedback_Adapting_Your_Strengths')
      );

      const developmentMenuCards = parseDevelopmentCardOptions(
        result.response.details.developmentSuggestions
      );

      const careerMenuCards = parseCareerCardOptions(
        result.response.details.careerSuggestions
      );

      const adaptingMenuCards = parseAdaptingCardOptions(
        result.response.details.adaptingStrengths
      );

      const reportDownloadFlags = {
        isReportDownloadable: result.response.details.isReportDownloadable,
      };        

      setData({
        developmentMenuOptions,
        adaptingMenuOptions,
        careerMenuOptions,
        developmentMenuCards,
        careerMenuCards,
        adaptingMenuCards,
        reportCode,
        isCandidateReport,
        reportDownloadFlags,
      });
    }

    if (result.errors) {
      setLoading(false);
      setErrors(true);
    }
  }, []);

  useEffect(() => {
    if (authStatus === "signed_in") {
      getOptions();
    }
  }, [authStatus]);

  return [data, loading, errors];
};
