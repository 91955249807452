import React, { useState } from 'react';
import Icon_Back from 'assets/job-board-widget/Icon_Back.png';
import clearIcon from 'assets/apply.clevry/Icon_FAQ_02.png';
import { InteractiveFeedbackScoreIcon } from './InteractiveFeedbackScoreIcon';

export enum InteractiveFeedbackMenuRowType {
  TALL = 'TALL',
  NORMAL = 'NORMAL',
}

export type InteractiveFeedbackMenuRowProps = {
  icon?: string;
  text: string;
  rowClick: (active: boolean) => void;
  rowNumber: number;
  active: boolean;
  setActive: React.Dispatch<React.SetStateAction<number | null>>;
  score?: number;
  type?: string;
  scoreType?: string;
  hideStenScores?: boolean;
};

export const InteractiveFeedbackMenuRow = ({
  icon,
  text,
  rowClick,
  rowNumber,
  active,
  scoreType,
  setActive,
  score,
  type = 'NORMAL',
  hideStenScores,
}: InteractiveFeedbackMenuRowProps) => {
  return (
    <div
      className={`InteractiveFeedbackMenuRow ${type === 'TALL' ? 'Tall' : ''} ${
        active ? 'Active' : ''
      } ${
        scoreType === 'SJT'
          ? 'SJTRow'
          : scoreType === 'LAST_NON_SJT'
          ? 'LastNonSJT'
          : ''
      }`}
      onClick={() => {
        if (active) {
          setActive(null);
        } else {
          setActive(rowNumber);
        }
        if (rowClick) {
          rowClick(active);
        }
      }}
    >
      {icon && !hideStenScores && (
        <img
          className="InteractiveFeedbackMenuRow__Icon"
          src={icon}
          alt="End of Row Icon"
        />
      )}
      {score && !hideStenScores && <InteractiveFeedbackScoreIcon score={score} />}
      <div className="InteractiveFeedbackMenuRow__Text">{text}</div>
      <img
        className="InteractiveFeedbackMenuRow__Chevron"
        src={active ? clearIcon : Icon_Back}
      />
    </div>
  );
};
