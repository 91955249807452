import { Button } from 'components/button/Button';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { closeModal } from 'reducers/modal';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';
import ReactPlayer from 'react-player';
import { hideProjectIntroVideo } from './ViewApplicationApi';

type ProjectIntroVideoModalProps = {
  data: {
    title: string;
    jobApplicationId: number;
    projectIntroVideoURL: string;
    campaignWelcomeText: string;
  };
};

const ProjectIntroVideoModal: React.FC<ProjectIntroVideoModalProps> = ({
  data,
}) => {
  const { t } = useTranslation('Withdraw+Application+Modal', { i18n });
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const handleDontShowAgain = async () => {
    const payload = {
      jobApplicationID: data.jobApplicationId,
      isProjectIntroVideoHidden: true,
    };
    const result = await hideProjectIntroVideo(payload);

    if (result) {
      handleCloseModal();
    } else {
      console.log(
        'Error setting project introduction video to not show again.'
      );

      handleCloseModal();
    }
  };

  return (
    <div className="ProjectIntroVideoModal FullWidth">
      <div className="Content">
        {ReactPlayer.canPlay(data.projectIntroVideoURL) ? (
          <ReactPlayer
            className="react-player"
            url={data.projectIntroVideoURL}
            width="100%"
            height="auto"
            controls
          />
        ) : (
          'There was an error loading the project introduction video, please try again later.'
        )}
        {data.campaignWelcomeText ? (
          <div className="CampaignWelcomeText">{data.campaignWelcomeText}</div>
        ) : null}
      </div>
      <div className="ModalFooter">
        <Button priority="tertiary" type="button" onClick={handleDontShowAgain}>
          Don't show again
        </Button>
        <Button
          className=""
          priority="primary"
          type="submit"
          onClick={handleCloseModal}
        >
          Got it
          {/* {t('SSCandidate_Withdraw_Application_Modal_Confirm')} */}
        </Button>
      </div>
    </div>
  );
};

export { ProjectIntroVideoModal };
