import i18n from 'i18n/i18n';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { closeRightPanel } from 'reducers/rightPanel';

type MatchingConnectionViewMessageSidePanelProps = {
  data: any;
};

const MatchConnectionViewMessageSidePanel: React.FC<
  MatchingConnectionViewMessageSidePanelProps
> = ({ data }) => {
  const { t } = useTranslation('Matching+Process', { i18n });
  const dispatch = useDispatch();

  const handleClosePanel = () => {
    dispatch(closeRightPanel());
  };

  const handleSubmit = () => {};

  useEffect(() => {}, []);

  return <div className="MatchConnectionViewMessageSidePanel"></div>;
};

export default MatchConnectionViewMessageSidePanel;
