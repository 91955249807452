import React from 'react';
import IconDelete from 'assets/apply.clevry/Icon_Delete.png';

type MatchingProcessDeleteIconProps = {
  onClick: () => void;
};

export const MatchingProcessDeleteIcon = ({
  onClick,
}: MatchingProcessDeleteIconProps) => {
  return (
    <div className="MatchingProcessDeleteIcon" onClick={onClick}>
      <img src={IconDelete} alt="Delete icon" />
    </div>
  );
};
