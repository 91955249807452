import { Link } from '@reach/router';
import { AppPath } from 'appConstants';
import Icon from 'assets/apply.clevry/Icon_Header_Matching_Profile_DarkGreen.png';
import { getDashboardInfo } from 'components/pages/joyAtWork/JoyAtWorkApi';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { signOut } from 'reducers/auth';
import { selectDashboardInfo, setDashboardInfo } from 'reducers/dashboardInfo';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';

export const MatchingProfileDropdownMenu = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const dashboardInfo = useSelector(selectDashboardInfo);

  const { t } = useTranslation('Matching+Profile+Dropdown+Menu', { i18n });

  const toggleIsOpen = () => {
    setIsOpen((oldIsOpen) => !oldIsOpen);
  };

  const handleSignOut = () => {
    dispatch(signOut());
  };

  const matchingProfileMenuRef = useRef<any>();

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (
        matchingProfileMenuRef.current &&
        !matchingProfileMenuRef.current.contains(e.target)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [matchingProfileMenuRef]);

  useEffect(() => {
    if (!dashboardInfo.isFetched) {
      getDashboardInfo().then((result) => {
        if (result.response) {
          dispatch(setDashboardInfo(result.response));
        }
      });
    }
  }, []);

  return (
    <div
      className={`UserMenu ${isOpen ? 'Highlighted' : ''} Matching Profile`}
      ref={matchingProfileMenuRef}
      onClick={toggleIsOpen}
    >
      <button className="UserMenuOpen">
        <img src={Icon} alt={t('SSCandidate_Joy_Dropdown_Menu_Alt_Icon')} />
        <div className={`UserMenuContent ${isOpen ? 'Open' : ''}`}>
          <Link
            to={`${AppPath.MATCHING_PROFILE_OVERVIEW}`}
            className="MenuLink"
          >
            {t('SSCandidate_Joy_Dropdown_Menu_Overview')}
          </Link>
          <Link
            to={AppPath.MATCHING_PROFILE + '/bio-contact'}
            className="MenuLink"
          >
            {t('SSCandidate_Joy_Dropdown_Menu_Bio_Contact')}
          </Link>
          <Link
            to={AppPath.MATCHING_PROFILE + '/my-situation'}
            className="MenuLink"
          >
            {t('SSCandidate_Joy_Dropdown_Menu_My_Situation')}
          </Link>
          <Link
            to={AppPath.MATCHING_PROFILE + '/personality-questionnaire'}
            className="MenuLink"
          >
            {t('SSCandidate_Joy_Dropdown_Menu_Personality_Questionnaire')}
          </Link>
          <Link
            to={AppPath.MATCHING_PROFILE + '/soft-hard-skills'}
            className="MenuLink"
          >
            {t('SSCandidate_Joy_Dropdown_Menu_Soft_Hard_Skills')}
          </Link>
          <Link to={AppPath.MATCHING_PROFILE + '/cv'} className="MenuLink">
            {t('SSCandidate_Joy_Dropdown_Menu_CV_Info')}
          </Link>
        </div>
      </button>
    </div>
  );
};
