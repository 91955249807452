import { Button } from 'components/button/Button';
import { Dropzone2 } from 'components/dropzone/Dropzone';
import i18n from 'i18n/i18n';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  MatchingProcessState,
  selectMatchingProcess,
  setMatchingProcessPanelType,
} from 'reducers/matchingProcess';
import { closeRightPanel } from 'reducers/rightPanel';
import ContactPage from './matchingProcessPages/Contact/ContactPage';
import CriticalHardSkillsPage from './matchingProcessPages/CriticalHardSkills/CriticalHardSkillsPage';
import CurrentSituationPage from './matchingProcessPages/CurrentSituation/CurrentSituationPage';
import ExperiencePage from './matchingProcessPages/Experience/ExperiencePage';
import GlobalSoftSkillsPage from './matchingProcessPages/GlobalSoftSkills/GlobalSoftSkillsPage';
import HowLikeToWorkPage from './matchingProcessPages/HowLikeToWork/HowLikeToWorkPage';
import LanguagesYouSpeakPage from './matchingProcessPages/LanguagesYouSpeak/LanguagesYouSpeakPage';
import ProfilePicturePage from './matchingProcessPages/ProfilePicture/ProfilePicturePage';
import SalaryAspirationPage from './matchingProcessPages/SalaryAspiration/SalaryAspirationPage';
import UltimateDreamJobPage from './matchingProcessPages/UltimateDreamJob/UltimateDreamJobPage';
import WhatHaveYouStudiedPage from './matchingProcessPages/WhatHaveYouStudied/WhatHaveYouStudiedPage';
import WhereToWorkPage from './matchingProcessPages/WhereToWork/WhereToWorkPage';
import WriteBioPage from './matchingProcessPages/WriteBio/WriteBioPage';

type MatchingEditDetailsSidePanelProps = {
  data: any;
};

export enum DataPanelTypes {
  'CONTACT' = 'CONTACT',
  'PICTURE' = 'PICTURE',
  'BIO' = 'BIO',
  'CURRENT_SITUATION' = 'CURRENT_SITUATION',
  'ULTIMATE_DREAM_JOB' = 'ULTIMATE_DREAM_JOB',
  'HOW_LIKE_WORK' = 'HOW_LIKE_WORK',
  'SALARY_ASPIRATION' = 'SALARY_ASPIRATION',
  'WHERE_TO_WORK' = 'WHERE_TO_WORK',
  'GLOBAL_SOFT_SKILLS' = 'GLOBAL_SOFT_SKILLS',
  'LANGUAGES_YOU_SPEAK' = 'LANGUAGES_YOU_SPEAK',
  'CRITICAL_HARD_SKILLS' = 'CRITICAL_HARD_SKILLS',
  'EXPERIENCE' = 'EXPERIENCE',
  'WHAT_HAVE_YOU_STUDIED' = 'WHAT_HAVE_YOU_STUDIED',
}

const MatchingEditDetailsSidePanel: React.FC<
  MatchingEditDetailsSidePanelProps
> = ({ data }) => {
  const { t } = useTranslation('Matching+Process', { i18n });
  const outerSubmitForm = useRef<any>();
  const [disableUpdateButton, setDisableUpdateButton] = useState(true);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [generalError, setGeneralError] = useState<string | undefined>(
    undefined
  );

  const dispatch = useDispatch();
  const handleClosePanel = () => {
    dispatch(closeRightPanel());
  };

  const matchingProcessDetails: MatchingProcessState = useSelector(
    selectMatchingProcess
  );
  const submitSuccess = matchingProcessDetails.submitSuccess;

  const handleSubmit = () => {
    outerSubmitForm.current();
    setSubmitted(true);
  };

  useEffect(() => {
    dispatch(setMatchingProcessPanelType(data.panelType));

    if (submitted && submitSuccess) {
      handleClosePanel();
    }

    // if (submitted && !submitSuccess) {
    //   setGeneralError("Something went wrong, please try again later.")
    // }
  }, [submitted, submitSuccess]);

  return (
    <div className="MatchingDetailsSidePanel">
      {data.panelType === DataPanelTypes.CONTACT && (
        <ContactPage
          outerSubmitForm={outerSubmitForm}
          setNextStepDisabled={setDisableUpdateButton}
          // setGeneralError={() => {}}
          setGeneralError={setGeneralError}
        />
      )}
      {data.panelType === DataPanelTypes.PICTURE && (
        <ProfilePicturePage
          outerSubmitForm={outerSubmitForm}
          setNextStepDisabled={setDisableUpdateButton}
          setGeneralError={setGeneralError}
        />
      )}
      {data.panelType === DataPanelTypes.BIO && (
        <WriteBioPage
          outerSubmitForm={outerSubmitForm}
          setNextStepDisabled={setDisableUpdateButton}
          setGeneralError={setGeneralError}
        />
      )}
      {data.panelType === DataPanelTypes.CURRENT_SITUATION && (
        <CurrentSituationPage
          outerSubmitForm={outerSubmitForm}
          setNextStepDisabled={setDisableUpdateButton}
          setGeneralError={setGeneralError}
        />
      )}
      {data.panelType === DataPanelTypes.ULTIMATE_DREAM_JOB && (
        <UltimateDreamJobPage
          outerSubmitForm={outerSubmitForm}
          setNextStepDisabled={setDisableUpdateButton}
          setGeneralError={setGeneralError}
        />
      )}
      {data.panelType === DataPanelTypes.HOW_LIKE_WORK && (
        <HowLikeToWorkPage
          outerSubmitForm={outerSubmitForm}
          setNextStepDisabled={setDisableUpdateButton}
          setGeneralError={setGeneralError}
        />
      )}
      {data.panelType === DataPanelTypes.SALARY_ASPIRATION && (
        <SalaryAspirationPage
          outerSubmitForm={outerSubmitForm}
          setNextStepDisabled={setDisableUpdateButton}
          setGeneralError={setGeneralError}
        />
      )}
      {data.panelType === DataPanelTypes.WHERE_TO_WORK && (
        <WhereToWorkPage
          outerSubmitForm={outerSubmitForm}
          setNextStepDisabled={setDisableUpdateButton}
          setGeneralError={setGeneralError}
        />
      )}
      {data.panelType === DataPanelTypes.GLOBAL_SOFT_SKILLS && (
        <GlobalSoftSkillsPage
          outerSubmitForm={outerSubmitForm}
          setNextStepDisabled={setDisableUpdateButton}
          setGeneralError={setGeneralError}
        />
      )}
      {data.panelType === DataPanelTypes.LANGUAGES_YOU_SPEAK && (
        <LanguagesYouSpeakPage
          outerSubmitForm={outerSubmitForm}
          setNextStepDisabled={setDisableUpdateButton}
          setGeneralError={setGeneralError}
        />
      )}
      {data.panelType === DataPanelTypes.CRITICAL_HARD_SKILLS && (
        <CriticalHardSkillsPage
          outerSubmitForm={outerSubmitForm}
          setNextStepDisabled={setDisableUpdateButton}
          setGeneralError={setGeneralError}
        />
      )}
      {data.panelType === DataPanelTypes.EXPERIENCE && (
        <ExperiencePage
          outerSubmitForm={outerSubmitForm}
          setNextStepDisabled={setDisableUpdateButton}
          setGeneralError={setGeneralError}
        />
      )}
      {data.panelType === DataPanelTypes.WHAT_HAVE_YOU_STUDIED && (
        <WhatHaveYouStudiedPage
          outerSubmitForm={outerSubmitForm}
          setNextStepDisabled={setDisableUpdateButton}
          setGeneralError={setGeneralError}
        />
      )}

      <div className="ModalFooter">
        <div className="Error">
          <div className="Text">{generalError && generalError}</div>
        </div>

        <Button priority="tertiary" type="button" onClick={handleClosePanel}>
          {t('SSCandidate_Matching_Process_Edit_Cancel')}
        </Button>
        <Button
          disabled={disableUpdateButton}
          priority="primary"
          type="submit"
          onClick={handleSubmit}
        >
          {t('SSCandidate_Matching_Process_Edit_Update')}
        </Button>
      </div>
    </div>
  );
};

export default MatchingEditDetailsSidePanel;
