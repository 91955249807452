import { Button } from 'components/button/Button';
import { TextInput } from 'components/formFields/TextInput';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { closeModal } from 'reducers/modal';
import Icon_Warning from 'assets/apply.clevry/Icon_Warning.png';
import { AccountApi } from './accountApi';
import { IAccountDelete } from './models';
import { signOut } from 'reducers/auth';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';

type AccountDeleteModalProps = {
  data: { buttonText: string };
};

const AccountDeleteModal: React.FC<AccountDeleteModalProps> = ({ data }) => {
  const [initialValues, setInitialValues] = useState<IAccountDelete>({
    password: '',
  });
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { t } = useTranslation('Account+Delete+Modal', { i18n });

  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const handleDeleteAccount = (payload: IAccountDelete) => {
    (async () => {
      const result = await AccountApi.deleteAccount(payload);
      if (result.data) {
        //if call succeeds, close modal
        dispatch(closeModal());
        dispatch(signOut());
      }
      if (result.error) {
        setErrorMessage(result.error.message[0].messageCode);
      }
    })();
  };

  return (
    <div className="AccountDeleteModal FullWidth">
      <Formik
        initialValues={initialValues}
        onSubmit={handleDeleteAccount}
        enableReinitialize
      >
        <Form className="FullWidth">
          <div className="Content">
            <h4>{t('SSCandidate_Account_Delete_Modal_Permanently_Delete')}</h4>
            <p>
              {t('SSCandidate_Account_Delete_Modal_Are_You_Sure')} <br />
              {t('SSCandidate_Account_Delete_Modal_You_Will_Lose')}
            </p>
            <p className="RedText">
              <img
                src={Icon_Warning}
                alt={t('SSCandidate_Account_Delete_Modal_Alt_Warning_Icon')}
              />
              {t('SSCandidate_Account_Delete_Modal_Action_Undone')}
            </p>
            <TextInput
              name="password"
              label={t('SSCandidate_Account_Delete_Modal_Enter_Password')}
              isPassword
            />
            {errorMessage && <p className="ErrorLabel">{errorMessage}</p>}
          </div>
          <div className="ModalFooter">
            <Button
              priority="tertiary"
              type="button"
              onClick={handleCloseModal}
            >
              {t('SSCandidate_Account_Delete_Modal_Misc_Cancel')}
            </Button>
            <Button priority="red" type="submit">
              {data.buttonText}
            </Button>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export { AccountDeleteModal };
