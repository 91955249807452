import { useField } from 'formik';
import { ErrorM } from 'helpers/ErrorM';
import React from 'react';

export interface FormRadioButtonsFieldProps<T> {
  fieldName: keyof T;
  label: string;
  readOnly?: boolean;
  inputProps?: JSX.IntrinsicElements['input'];
  options: any;
}

const FormRadioButtonsField = function <T>({
  options,
  label,
  readOnly,
  inputProps,
  fieldName,
}: FormRadioButtonsFieldProps<T>) {
  const [field, meta, helpers] = useField(fieldName.toString());
  return (
    <div className="FormBox">
      {label && (
        <div className="FormLabel">
          <label>{label}</label>
        </div>
      )}
      <div className="CheckboxFieldContainer CheckboxesField">
        {options.map((o: any, idx: number) => {
          const isChecked = field.value
            ? field.value.includes(o.optionId.toString())
            : false;

          const isTile = 'tileColour' in o;
          return (
            <label className={`Label ${isChecked ? 'Selected' : ''}`} key={idx}>
              <input
                {...inputProps}
                {...field}
                className={'CheckboxInput'}
                type="radio"
                value={o.optionId}
                checked={isChecked}
              />
              <span className="Checkmark Radio"></span>
              {!isTile && o.optionText}
              {isTile && (
                <div
                  className="CheckboxFieldContainer__tile"
                  style={{ backgroundColor: o.tileColour, color: o.textColour }}
                >
                  {o.optionText}
                </div>
              )}
            </label>
          );
        })}
        <ErrorM name={fieldName.toString()} />
      </div>
    </div>
  );
};

export { FormRadioButtonsField };
