import { TestsListSearchPayload } from 'components/pages/buyPQTests/models';
import { Store } from './rootReducer';

//Types
export enum TestsContentAction {
  SET_TESTS_SEARCH_PARAMS = 'SET_TESTS_SEARCH_PARAMS',
}

const initialSearchState: TestsListSearchPayload = {
  filters: {
    types: [],
  },
  // TODO(hari): set this based on query param?
  clientId: 1001,
  sortById: 0,
  searchString: '',
  location: '',
  distance: 0,
  pageNumber: 1,
  pageSize: 2,
};

// Actions
export type Action = {
  type: TestsContentAction.SET_TESTS_SEARCH_PARAMS;
  payload: Partial<TestsListSearchPayload>;
};

// Reducer
export default function buyPQTestsSearchReducer(
  state = initialSearchState,
  action: Action
): TestsListSearchPayload {
  if (action.type === TestsContentAction.SET_TESTS_SEARCH_PARAMS) {
    return { ...state, ...action.payload };
  }
  return state;
}

export type OpenLeftPanel = {
  panelType: any | null;
  data: any | null;
};

// Action creators
export const setTestsSearchParams = (
  payload: Partial<TestsListSearchPayload>
) => ({
  type: TestsContentAction.SET_TESTS_SEARCH_PARAMS,
  payload,
});

// Selectors
export const selectTestsSearchParams = (state: Store) =>
  state.buyPQTestsSearchParams;
