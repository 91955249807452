import { Button } from 'components/button/Button';
import { Form, Formik } from 'formik';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { closeModal } from 'reducers/modal';
import { setInstructionsUnderstood } from 'reducers/virtualAssessments';

export const VirtualAssessmentsBeforeYouBeginModal = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setInstructionsUnderstood(false));
  }, []);

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const handleStartPQ = () => {
    dispatch(setInstructionsUnderstood(true));
    dispatch(closeModal());
  };

  const modalStyle = {
    '--modalTitleColor': '#036C8F',
    '--footerBackgroundColor': '#f5f5f5',
    '--footerBorderColor': '#f5f5f5',
    '--primaryButtonBackgroundColor': '#731447',
    '--tertiaryButtonBackgroundColor': '#d5d5d5',
  } as React.CSSProperties;

  return (
    <div className="VirtualAssessmentsBeforeYouBeginModal" style={modalStyle}>
      <Formik initialValues={{}} onSubmit={() => {}}>
        <Form>
          <div className="VirtualAssessmentsBeforeYouBeginModal__content VirtualAssessmentsModalContent">
            <div className="VirtualAssessmentsBeforeYouBeginModal__message">
              Remember that your ability will be reassessed later on in the
              assessment process. This re-assessment may take the form of an
              online verification test, supervised test or other measure of your
              ability. If you understand and are happy to continue then you can
              start the test now
            </div>
          </div>
          <div className="ModalFooter">
            <Button
              className="Rounded"
              priority="tertiary"
              type="button"
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
            <Button
              className="Discard Rounded"
              priority="primary"
              type="submit"
              onClick={handleStartPQ}
            >
              I understand
            </Button>
          </div>
        </Form>
      </Formik>
    </div>
  );
};
