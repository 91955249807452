import { useState, useCallback, useEffect } from 'react';
import {
  InteractiveFeedbackCardProps,
  InteractiveFeedbackCardType,
} from '../components/InteractiveFeedbackCard';
import {
  getCapabilitiesScores,
  GetCapabilitiesScoresDetails,
} from '../InteractiveFeedbackApi';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';
import { useSelector } from 'react-redux';
import { selectAuthStatus, selectRedirectValues } from 'reducers/auth';
import { selectDashboardInfo } from 'reducers/dashboardInfo';
import { selectAssessmentId } from 'reducers/virtualAssessments';

export type MyCapabilitiesMenuOptions = {
  rows: {
    text: string;
    icon?: string;
    rowClick: (active: boolean) => void;
    score?: number;
  }[];
  cards?: MyCapabilitiesCardInnerOptions[];
  reportCode?: string;
  assessmentID?: number;
  candidateReport?: boolean;
  title: string;
};

export type ReportDownloadFlags = {
  isAbilityReportDownloadable?: boolean;
  isSJTReportDownloadable?: boolean;
};

export type MyCapabilitiesCardInnerOptions = Pick<
  InteractiveFeedbackCardProps,
  'bottomColor' | 'cardHTML' | 'heading' | 'type' | 'noButtons'
> & { score?: number; topImage?: string };

export type MyCapabilitiesCardOptions = {
  capabilityCards: MyCapabilitiesCardInnerOptions[];
  sjtCards: MyCapabilitiesCardInnerOptions[];
};

export const MyCapabilitiesScoreMaps = {
  1: {
    color: '#146594',
    borderColor: '#146594',
    topColor: '#DCE8EF',
    bottomColor: '#146594',
  },
  2: {
    color: '#897015',
    borderColor: '#897015',
    topColor: '#FBF7E6',
    bottomColor: '#897015',
  },
  3: {
    color: '#732571',
    borderColor: '#732571',
    topColor: '#E9DEE9',
    bottomColor: '#732571',
  },
  4: {
    color: '#31C3EC',
    borderColor: '#31C3EC',
    topColor: '#DFF5FB',
    bottomColor: '#31C3EC',
  },
  5: {
    color: '#4FAC80',
    borderColor: '#4FAC80',
    topColor: '#E5F3EC',
    bottomColor: '#4FAC80',
  },
  6: {
    color: '#D50100',
    borderColor: '#D50100',
    topColor: '#F9D9D9',
    bottomColor: '#D50100',
  },
  7: {
    color: '#AE5954',
    borderColor: '#AE5954',
    topColor: '#f7e7e6',
    bottomColor: '#AE5954',
  },
  8: {
    color: '#48303F',
    borderColor: '#48303F',
    topColor: '#faedf5',
    bottomColor: '#48303F',
  },
  9: {
    color: '#425756',
    borderColor: '#425756',
    topColor: '#e9f7f7',
    bottomColor: '#425756',
  },
  10: {
    color: '#653822',
    borderColor: '#653822',
    topColor: '#f7ece6',
    bottomColor: '#653822',
  },
};

export const useGetMyCapabilitiesDetails = (
  setInnerRowName: React.Dispatch<React.SetStateAction<string | null>>,
  setAnimateDismount: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const { t } = useTranslation('Interactive+Feedback', { i18n });
  const redirectValues = useSelector(selectRedirectValues);
  const dashboardInfo = useSelector(selectDashboardInfo);
  const myHistoryRouteAssessmentId = useSelector(selectAssessmentId);
  const authStatus = useSelector(selectAuthStatus);

  let cultureCode: string | null = null;
  if (dashboardInfo && dashboardInfo.cultureCode) {
    cultureCode = dashboardInfo.cultureCode;
  }
  let AssessmentId: number | null = null;
  if (redirectValues && redirectValues.length > 0) {
    AssessmentId = redirectValues.find(
      ({ label, value }) => label === 'AssessmentID'
    )?.value;
  } else if (myHistoryRouteAssessmentId) {
    AssessmentId = myHistoryRouteAssessmentId;
  }

  const [data, setData] = useState<{
    capabilityMenuOptions: MyCapabilitiesMenuOptions;
    sjtMenuOptionsList: Record<string, MyCapabilitiesMenuOptions>;
    capabilityMenuCards: MyCapabilitiesCardOptions;
    reportDownloadFlags: ReportDownloadFlags;
  } | null>(null);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState(false);

  const parseOuterMenuOptions = (
    details: GetCapabilitiesScoresDetails,
    title: string
  ): MyCapabilitiesMenuOptions => {
    let upperRows: {
      text: string;
      score: number;
      rowClick: (active: boolean) => void;
    }[] = [];
    if (details.abilityTests && Array.isArray(details.abilityTests)) {
      upperRows = details.abilityTests.map(
        (
          { testName, narrativeText, testDescription, testScore },
          idx,
          arr
        ) => ({
          text: testName ?? testDescription,
          score: testScore,
          ...(idx === arr.length - 1 ? { scoreType: 'LAST_NON_SJT' } : {}),
          rowClick: (active: boolean) => {},
        })
      );
    }
    let lowerRows: {
      text: string;
      score: number;
      rowClick: (active: boolean) => void;
    }[] = [];
    if (details.sjTs && Array.isArray(details.sjTs)) {
      lowerRows = details.sjTs.map(({ sjtName, sjtScore, testType }) => ({
        text: sjtName ?? testType,
        score: sjtScore,
        scoreType: 'SJT',
        rowClick: (active: boolean) => {
          setInnerRowName(sjtName);
          setAnimateDismount(true);
        },
      }));
    }
    const rows = [...upperRows, ...lowerRows];
    return {
      rows,
      title,
    };
  };

  const parseInnerMenuCardOptionsList = (
    details: GetCapabilitiesScoresDetails
  ) => {
    const innerRowMap: Record<string, MyCapabilitiesMenuOptions> = {};
    if (details.sjTs && Array.isArray(details.sjTs)) {
      details.sjTs.forEach(
        ({ sjtName, sjtScore, testType, scales, reportCode, assessmentID }) => {
          innerRowMap[sjtName] = {
            rows: scales.map(({ scaleScore, scaleName }) => ({
              text: scaleName,
              score: scaleScore,
              rowClick: (active: boolean) => {},
            })),
            title: sjtName ?? testType,
            reportCode,
            assessmentID,
            cards: scales.map(
              ({
                developmentText,
                narrativeText,
                scaleID,
                scaleName,
                scaleScore,
              }) => ({
                type: InteractiveFeedbackCardType.ICON_SMALL,
                heading: scaleName,
                cardHTML: narrativeText,
                score: scaleScore,
                bottomColor:
                  MyCapabilitiesScoreMaps[
                    scaleScore as keyof typeof MyCapabilitiesScoreMaps
                  ].bottomColor,
                topColor:
                  MyCapabilitiesScoreMaps[
                    scaleScore as keyof typeof MyCapabilitiesScoreMaps
                  ].topColor,
              })
            ),
          };
        }
      );
    }
    return innerRowMap;
  };

  const parseCapabilityCardOptions = (
    details: GetCapabilitiesScoresDetails
  ) => {
    let capabilityCards: {
      type: InteractiveFeedbackCardType;
      heading: string;
      cardHTML: string;
      score: number;
      bottomColor: string;
      topColor: string;
    }[] = [];
    if (details.abilityTests && Array.isArray(details.abilityTests)) {
      capabilityCards = details.abilityTests.map(
        ({ narrativeText, testDescription, testName, testScore }) => ({
          type: InteractiveFeedbackCardType.ICON_SMALL,
          heading: testName ?? testDescription,
          cardHTML: narrativeText,
          score: testScore,
          bottomColor:
            MyCapabilitiesScoreMaps[
              testScore as keyof typeof MyCapabilitiesScoreMaps
            ].bottomColor,
          topColor:
            MyCapabilitiesScoreMaps[
              testScore as keyof typeof MyCapabilitiesScoreMaps
            ].topColor,
        })
      );
    }
    let sjtCards: {
      type: InteractiveFeedbackCardType;
      heading: string;
      cardHTML: string;
      score: number;
      bottomColor: string;
      topColor: string;
    }[] = [];
    if (details.sjTs && Array.isArray(details.sjTs)) {
      sjtCards = details.sjTs.map(
        ({ sjtScore, sjtName, testType, overallPerformanceText }) => ({
          type: InteractiveFeedbackCardType.ICON_SMALL,
          heading: sjtName ?? testType,
          cardHTML: overallPerformanceText,
          score: sjtScore,
          bottomColor:
            MyCapabilitiesScoreMaps[
              sjtScore as keyof typeof MyCapabilitiesScoreMaps
            ].bottomColor,
          topColor:
            MyCapabilitiesScoreMaps[
              sjtScore as keyof typeof MyCapabilitiesScoreMaps
            ].topColor,
        })
      );
    }
    return { capabilityCards, sjtCards };
  };

  const getOptions = useCallback(async () => {
    const result = await getCapabilitiesScores({
      ...(AssessmentId ? { AssessmentId } : {}),
      ...(cultureCode ? { LanguageCultureInfo: cultureCode } : {}),
    });
    if (
      result.response &&
      result.response.details &&
      (result.response.details.abilityTests || result.response.details.sjTs)
    ) {
      setLoading(false);

      const capabilityMenuOptions = parseOuterMenuOptions(
        result.response.details,
        t('SSCandidate_Interactive_Feedback_My_Capabilities')
      );
      const sjtMenuOptionsList = parseInnerMenuCardOptionsList(
        result.response.details
      );
      const capabilityMenuCards = parseCapabilityCardOptions(
        result.response.details
      );
      const reportDownloadFlags = {
        isAbilityReportDownloadable: result.response.details.isAbilityReportDownloadable,
        isSJTReportDownloadable: result.response.details.isSJTReportDownloadable,
      };

      setData({
        capabilityMenuOptions,
        sjtMenuOptionsList,
        capabilityMenuCards,
        reportDownloadFlags,
      });
    }

    if (result.errors) {
      setLoading(false);
      setErrors(true);
    }
  }, []);

  useEffect(() => {
    if (authStatus === "signed_in") {
      getOptions();
    }
  }, [authStatus]);

  return [data, loading, errors];
};
