import i18n from 'i18n/i18n';

const getErrors = () => {
  try {
    i18n
      .loadNamespaces('Errors')
      .then(() => {
        return {
          required: i18n.t('SSCandidate_Errors_Required'),
          generic: i18n.t('SSCandidate_Errors_Generic'),
          applicationError: i18n.t('SSCandidate_Errors_Application_Error'),
          Account_In_Use: i18n.t('SSCandidate_Errors_Account_In_Use'),
          Invalid_Password_Length: i18n.t(
            'SSCandidate_Errors_Invalid_Password_Length'
          ),
          Password_Policy_No_Match: i18n.t(
            'SSCandidate_Errors_Password_Policy_No_Match'
          ),
          Account_Locked: i18n.t('SSCandidate_Errors_Account_Locked'),
          Username_Or_Password_Incorrect: i18n.t(
            'SSCandidate_Errors_Username_Or_Password_Incorrect'
          ),
          EmailAddress_In_Use: i18n.t(
            'SSCandidate_Errors_Email_Address_In_Use'
          ),
        };
      })
      .catch(() => {
        console.log('Failed to load errors languages data');

        return {
          required: 'Required',
          generic: 'There was an error, please try again later.',
          applicationError: 'Application Error',
          Account_In_Use: 'Email is already in use.',
          Invalid_Password_Length: 'Minimum password length is 8 characters',
          Password_Policy_No_Match:
            'Passwords needs a lowercase, uppercase, special and number character.',
          Account_Locked: 'Account is locked.',
          Username_Or_Password_Incorrect:
            'Email address or password incorrect.',
          EmailAddress_In_Use: 'E-mail address is already in use.',
        };
      });
  } catch {
    console.log('Failed to load errors languages data');

    return {
      required: 'Required',
      generic: 'There was an error, please try again later.',
      applicationError: 'Application Error',
      Account_In_Use: 'Email is already in use.',
      Invalid_Password_Length: 'Minimum password length is 8 characters',
      Password_Policy_No_Match:
        'Passwords needs a lowercase, uppercase, special and number character.',
      Account_Locked: 'Account is locked.',
      Username_Or_Password_Incorrect: 'Email address or password incorrect.',
      EmailAddress_In_Use: 'E-mail address is already in use.',
    };
  }
};

export const errors = getErrors();
