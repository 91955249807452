import { navigate, RouteComponentProps } from '@reach/router';
import { AppPath } from 'appConstants';
import Pointer from 'assets/new_skin/pointer.svg';
import { Button } from 'components/button/Button';
import { getQueryParam } from 'helpers/getQueryParam';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAuthStatus,
  selectWebskin,
  setApplicationLinkToken,
  setInvitedBy,
  setInviteType,
  setIsAssessmentOnlyCandidate,
} from 'reducers/auth';
import {
  selectSignInFormState,
  setOpenSignInForm,
  setOpenSignUpForm,
} from 'reducers/landingPage';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';
import { addHubspotTracking } from 'helpers/addHubspotTracking';
import ClevryLandingImage from 'assets/new_skin/Img_NewFindYourJoy_LandingPage_03.png';

export enum FormType {
  SIGN_IN = 1,
  SIGN_UP,
}

const Landing: React.FC<RouteComponentProps> = () => {
  const { t } = useTranslation('Landing', { i18n });

  const dispatch = useDispatch();
  const signInFormState = useSelector(selectSignInFormState);
  const authStatus = useSelector(selectAuthStatus);
  const selectedWebskin = useSelector(selectWebskin);

  const currentWebskin = window.sessionStorage.getItem('webskin')
    ? window.sessionStorage.getItem('webskin')
    : 'defaultTheme';

  const mainLandingImage =
    selectedWebskin && selectedWebskin.siteImages
      ? selectedWebskin.siteImages.find(
          (item: any) => item.code === 'SI_Landing_Main'
        ).imageDownloadURI
      : '';
  const landingBlockImage1 =
    selectedWebskin && selectedWebskin.siteImages
      ? selectedWebskin.siteImages.find(
          (item: any) => item.code === 'SI_Landing_Block_1'
        ).imageDownloadURI
      : '';
  const landingBlockImage2 =
    selectedWebskin && selectedWebskin.siteImages
      ? selectedWebskin.siteImages.find(
          (item: any) => item.code === 'SI_Landing_Block_2'
        ).imageDownloadURI
      : '';
  const landingBlockImage3 =
    selectedWebskin && selectedWebskin.siteImages
      ? selectedWebskin.siteImages.find(
          (item: any) => item.code === 'SI_Landing_Block_3'
        ).imageDownloadURI
      : '';

  useEffect(() => {
    const inviteType = getQueryParam('inviteType');
    if (inviteType) {
      dispatch(setInviteType(inviteType));
    }
    const script = addHubspotTracking();

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // useEffect(() => {
  //   const applicationLinkToken = getQueryParam('jobtoken');
  //   if (applicationLinkToken) {
  //     dispatch(setApplicationLinkToken(applicationLinkToken));

  //     if (currentWebskin !== 'defaultTheme') {
  //       navigate(AppPath.SIGN_UP_PAGE);
  //     }
  //   }
  // }, []);

  useEffect(() => {
    const invitedById = getQueryParam('invitedBy');
    if (invitedById) {
      dispatch(setInvitedBy(invitedById));
    }
  }, []);

  useEffect(() => {
    dispatch(setIsAssessmentOnlyCandidate(false));
    localStorage.removeItem('assessmentOnlyCandidate');
  }, []);

  return (
    <div className="LandingPage">
      {currentWebskin === 'defaultTheme' ? (
        <div className="SignIn WidthContent HeightContent">
          <div className="Content Body">
            <h1 className="Title">
              {t('SSCandidate_Landing_Sign_In_Title_Restructure')}
            </h1>
            <h2 className="Subtitle">
              {t('SSCandidate_Landing_Sign_In_Subtitle_Restructure_1')}
            </h2>
            <div className="ButtonContainer">
              <Button
                priority="primary"
                isPublic
                className=""
                onClick={() =>
                  authStatus === 'signed_in'
                    ? navigate(AppPath.BROWSE_OPPORTUNITIES)
                    : navigate(AppPath.PUBLIC_BROWSE_OPPORTUNITIES)
                }
              >
                {t('SSCandidate_Landing_Sign_In_Open_Vacancies')}
              </Button>
            </div>
            <h2 className="Subtitle">
              {t('SSCandidate_Landing_Sign_In_Subtitle_Restructure_2')}
            </h2>
            <div className="ButtonContainer">
              {/* <Button
                priority="primary"
                isPublic
                className="LandingButton"
                onClick={() => navigate(AppPath.FIND_YOUR_JOY)}
              >
                {t('SSCandidate_Landing_Sign_In_Joy_At_Work_Button')}
              </Button>
              <img src={Pointer} className="Pointer" /> */}
              <Button
                priority="green"
                isPublic
                className=""
                onClick={() =>
                  authStatus === 'signed_in'
                    ? navigate(AppPath.MATCHING_PROFILE)
                    : dispatch(setOpenSignUpForm(AppPath.MATCHING_PROFILE))
                }
              >
                {t('SSCandidate_Landing_Sign_In_Create_Profile')}
              </Button>
              <Button
                priority="green"
                isPublic
                className=""
                onClick={() =>
                  authStatus === 'signed_in'
                    ? navigate(AppPath.MY_DEVELOPMENT)
                    : dispatch(setOpenSignUpForm(AppPath.MY_DEVELOPMENT))
                }
              >
                {t('SSCandidate_Landing_Sign_In_Practice_Tests')}
              </Button>
            </div>
          </div>
          <div className="LandingImageContainer">
            <img
              src={ClevryLandingImage}
              alt={t('SSCandidate_Landing_Alt_Find_Your_Joy')}
              className="LandingImage"
            />
          </div>
        </div>
      ) : (
        <div className="SignIn Webskin WidthContent">
          <div className="Content Top">
            <div className="Content Body">
              <h1 className="Title">
                Welcome to your {currentWebskin} Assessment
              </h1>
              <h2 className="Subtitle">Sign up to take an assessment</h2>

              <Button
                priority="secondary"
                isPublic
                onClick={() => navigate(AppPath.SIGN_UP_PAGE)}
                // disabled={true}
              >
                Sign up
              </Button>

              <div className="SignInPrompt">
                <h2 className="Subtitle">Already signed-up?</h2>
                <h2
                  className="Subtitle__Link"
                  onClick={() => {
                    dispatch(setOpenSignInForm());
                  }}
                >
                  Sign in
                </h2>
              </div>
            </div>
            <img
              src={mainLandingImage}
              // src={themes[`${currentWebskin}`][`landingImage`]}
              alt={t('SSCandidate_Landing_Alt_Find_Your_Joy')}
              className="LandingImage"
            />
          </div>
          <div className="Content Bottom">
            <div className="Content Body">
              <h1 className="Title">Taking a test</h1>
              <h2 className="Subtitle">Sign up to take an assessment</h2>

              <Button
                priority="secondary"
                isPublic
                onClick={() => {}}
                disabled={true}
              >
                See more
              </Button>
            </div>
            <div className="AssessmentBlockList">
              <div className="AssessmentBlockItem">
                <img
                  src={landingBlockImage1}
                  // src={themes[`${currentWebskin}`][`landingBlockImage1`]}
                  alt="AssessmentBlockImage"
                />
                <span>Advice for taking tests</span>
              </div>
              <div className="AssessmentBlockItem">
                <img src={landingBlockImage2} alt="AssessmentBlockImage" />
                <span>Practice for a numerical test</span>
              </div>
              <div className="AssessmentBlockItem">
                <img src={landingBlockImage3} alt="AssessmentBlockImage" />
                <span>Practice for a verbal test</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Landing;
