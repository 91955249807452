export const extractFileName = (contentDisposition: any) => {
  const fileNameFirstIndex = contentDisposition.indexOf('filename');
  const fileNameLastIndex = contentDisposition.lastIndexOf('filename');
  const fileNameUnstripped = contentDisposition.substr(
    fileNameFirstIndex,
    fileNameLastIndex - fileNameFirstIndex
  );
  const indexOfEqualSymbol = fileNameUnstripped.indexOf('=');
  const fileNameStripSemiColon = fileNameUnstripped.substr(
    0,
    fileNameUnstripped.length - 2
  );
  const fileNameStripped = fileNameStripSemiColon.substr(
    indexOfEqualSymbol + 1,
    fileNameStripSemiColon.length - 1
  );
  return fileNameStripped;
};
