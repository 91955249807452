import React from 'react';
import { Button } from 'components/button/Button';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';

type InteractiveFeedbackCardButtonsProps = {
  setActive?: React.Dispatch<React.SetStateAction<number | null>>;
  numberOfItems: number;
};

export const InteractiveFeedbackCardButtons = ({
  setActive,
  numberOfItems,
}: InteractiveFeedbackCardButtonsProps) => {
  const { t } = useTranslation('Interactive+Feedback', { i18n });

  return (
    <div className="InteractiveFeedbackCardButtons">
      <Button
        priority="tertiary"
        onClick={() => {
          if (setActive) {
            setActive((activeItem) => {
              return ((activeItem as number) - 1) % numberOfItems >= 0
                ? ((activeItem as number) - 1) % numberOfItems
                : numberOfItems - 1;
            });
          }
        }}
      >
        {t('SSCandidate_Interactive_Feedback_Previous')}
      </Button>
      <Button
        priority="tertiary"
        onClick={() => {
          if (setActive) {
            setActive(
              (activeItem) => ((activeItem as number) + 1) % numberOfItems
            );
          }
        }}
      >
        {t('SSCandidate_Interactive_Feedback_Next')}
      </Button>
    </div>
  );
};
