export const GASignIn = () => {
  gtag('event', 'login');
};

export const GARegularSignUp = () => {
  gtag('event', 'regular_sign_up');
};

export const GARedirectSignUp = () => {
  gtag('event', 'redirect_sign_up');
};

export const GASignUpAfterQuiz = () => {
  gtag('event', 'sign_up_after_quiz');
};

export const GACompletedQuizSelectionStep = (stepNumber: number) => {
  gtag('event', 'image_selection', {
    value: stepNumber,
  });
};

export const GACompletedQuizOrderingStep = () => {
  gtag('event', 'image_ordering_step');
};

export const GAPQStarted = () => {
  gtag('event', 'pq_started');
};

export const GAPQCompleted = () => {
  gtag('event', 'pq_completed');
};

export const GAInviteAFriend = (numberOfEmails: number) => {
  gtag('event', 'invite_a_friend', {
    value: numberOfEmails,
  });
};
export const GAInviteOthersToJoinTeam = (numberOfEmails: number) => {
  gtag('event', 'invite_others_to_join_team', {
    value: numberOfEmails,
  });
};

export const GAShareMyProfile = (numberOfEmails: number) => {
  gtag('event', 'share_my_profile', {
    value: numberOfEmails,
  });
};

export const GATeamReportGeneration = () => {
  gtag('event', 'generate_team_report');
};

export const GASignUpFromInvite = () => {
  gtag('event', 'signed_up_after_friend_invite');
};
export const GASignUpFromTeamInvite = () => {
  gtag('event', 'signed_up_after_join_team_invite');
};

export const GAMatchingProcessLatest = (page: string) => {
  gtag('event', 'matching_process_latest_page', {
    value: page,
  });
};

export const GAMatchingProcessComplete = () => {
  gtag('event', 'matching_process_complete');
};

export const GAMatchingProcessStart = () => {
  gtag('event', 'matching_process_start');
};

export const GAMatchingProcessProgress = (progress: number) => {
  gtag('event', 'matching_process_progress', {
    value: `${progress}%`,
  });
};
