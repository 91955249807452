import { navigate, RouteComponentProps } from '@reach/router';
import { Spinner } from 'components/spinner/Spinner';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectCultureCode } from 'reducers/dashboardInfo';

interface IFramedProngProps extends RouteComponentProps {
  redirectUrl: string;
  iframeUrl: string;
  redirectUrlProps?: any;
}

const IFramedProng: React.FC<IFramedProngProps> = ({
  redirectUrl,
  iframeUrl,
  redirectUrlProps,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const cultureCode = useSelector(selectCultureCode);
  const addLanguageToUrl = iframeUrl + `&language=${cultureCode}`;

  React.useEffect(() => {
    window.addEventListener('message', function (e) {
      if (e.data.closeStagesProng) {
        if (
          redirectUrlProps &&
          typeof redirectUrlProps === 'object' &&
          Object.keys(redirectUrlProps).length > 0
        ) {
          navigate(redirectUrl, { state: { ...redirectUrlProps } });
        } else {
          navigate(redirectUrl);
        }
      }
    });
  }, [redirectUrl, redirectUrlProps]);

  return (
    <>
      {isLoading && (
        <div className="FullScreenCover CenterContent">
          <Spinner />
        </div>
      )}
      <div style={{ maxHeight: '100vh', width: '100vw' }}>
        <iframe
          src={addLanguageToUrl}
          title="iframed-content"
          style={{ height: '100vh', width: '100%' }}
          onLoad={() => setIsLoading(false)}
        />
      </div>
    </>
  );
};

export default IFramedProng;
