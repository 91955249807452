import { Button } from 'components/button/Button';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from 'reducers/modal';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';
import {
  selectHideScenarioModal,
  setHideScenarioModal,
} from 'reducers/assessment';
import { Tooltip } from 'components/helpers/Tooltip';
import ClevryRobot from 'assets/apply.clevry/Img_Robot.png';
import InfoIcon from 'assets/apply.clevry/Icon_Password_Info.png';
import { selectAccessibilityData } from 'reducers/accessibility';

type ModalProps = {
  data: {
    title: string;
    scenarioText: string;
    scenarioImage?: string | null;
    prongCode: string;
    testType: string;
    hasSeenAllScenarios: boolean;
  };
};

export const AssessmentScenarioModal: React.FC<ModalProps> = ({ data }) => {
  const scenarioModal = useRef<HTMLInputElement>(null);
  const [generalError, setGeneralError] = useState<string>();

  const dispatch = useDispatch();
  const accessibilityData = useSelector(selectAccessibilityData);

  const { t } = useTranslation('Assessment+Scenario+Modal', { i18n });

  const hideScenarioModal = useSelector(selectHideScenarioModal);

  const handleCloseModal = () => {
    const accessibilityBtn = document.getElementsByClassName("AccessibilityBtn")[0] as HTMLElement;
    if(accessibilityBtn){
      accessibilityBtn.focus();
    }
    dispatch(closeModal());
  };

  const handleDontShowAgain = () => {
    dispatch(
      setHideScenarioModal({
        ...hideScenarioModal,
        all: true,
        // [data.testType]: true,
      })
    );
    handleCloseModal();
  };

  useEffect(() => {
    scenarioModal.current?.focus();
  }, [scenarioModal]);  

  const modalStyle = {
    '--modalTitleColor': '#036C8F',
    '--footerBackgroundColor': '#f5f5f5',
    '--footerBorderColor': '#f5f5f5',
    '--primaryButtonBackgroundColor': '#36705B',
    '--tertiaryButtonBackgroundColor': '#d5d5d5',
  } as React.CSSProperties;

  return (
    <div
      className={`AssessmentModal Scenario ${accessibilityData.classStr}`}
      style={modalStyle}
    >
      <div className="Content">
        <div className="Image">
          <img src={data.scenarioImage ?? ClevryRobot} alt="Scenario" />
        </div>

        <div
          className="SpeechBubble"
          dangerouslySetInnerHTML={{
            __html: data.scenarioText,
          }}
          ref={scenarioModal}
          tabIndex={1}
        />
      </div>
      <div className="ModalFooter">
        {generalError && <div className="GeneralError">{generalError}</div>}
        {data.hasSeenAllScenarios && (
          <Button
            className="Rounded"
            priority="tertiary"
            type="button"
            onClick={handleDontShowAgain}
          >
            {t('SSCandidate_Scenario_Modal_Dont_Show_Again')}
            <Tooltip
              text={t('SSCandidate_Scenario_Modal_Dont_Show_Again_Tooltip')}
              image={InfoIcon}
            />
          </Button>
        )}

        <Button
          className="Discard Rounded"
          priority="primary"
          type="submit"
          onClick={handleCloseModal}
          addTabIndex={2}
        >
          {t('SSCandidate_Scenario_Modal_Understand')}
        </Button>
      </div>
    </div>
  );
};
