import { useField } from 'formik';
import React from 'react';
import zxcvbn from 'zxcvbn';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';

export type PasswordStrengthProps = {
  fieldName: string;
  showRatingBar?: boolean;
};
export type PasswordStrengthDetails = {
  className: string;
  text: string;
};

const PasswordStrengthMeter: React.FC<PasswordStrengthProps> = ({
  fieldName,
  showRatingBar,
}) => {
  const { t } = useTranslation('Password+Strength+Meter', { i18n });

  const [field] = useField(fieldName);
  const result = zxcvbn(field.value);

  const passwordStrengthDetails = (): PasswordStrengthDetails => {
    switch (result.score) {
      case 1:
        return {
          className: 'Strength_Weak',
          text: t(
            'SSCandidate_Account_Change_Password_Modal_Password_Strength_Meter_Weak'
          ),
        };
      case 2:
        return {
          className: 'Strength_SoSo',
          text: t('SSCandidate_Password_Strength_Meter_So_So'),
        };
      case 3:
        return {
          className: 'Strength_Good',
          text: t('SSCandidate_Password_Strength_Meter_Good'),
        };
      case 4:
        return {
          className: 'Strength_Great',
          text: t('SSCandidate_Password_Strength_Meter_Great'),
        };
      default:
        return {
          className: 'Strength_VeryWeak',
          text: t('SSCandidate_Password_Strength_Meter_Very_Weak'),
        };
    }
  };
  return (
    <div className="PasswordStrengthHelper">
      <div className="PasswordStrengthInfo">
        {showRatingBar && (
          <div
            className={`PasswordStrengthMeterContainer ${
              passwordStrengthDetails().className
            }`}
          >
            <div className="PasswordStrengthMeter"></div>
            <div className="PasswordStrengthMeter"></div>
            <div className="PasswordStrengthMeter"></div>
            <div className="PasswordStrengthMeter"></div>
          </div>
        )}
        <div className="StrengthDetails">
          <div className="StrengthTxt">{passwordStrengthDetails().text}</div>
          <div className="InfoContainer">
            <div className="MoreInfo"></div>
            <div className="Message">
              {t('SSCandidate_Password_Strength_Meter_Misc_Password_Message')}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export { PasswordStrengthMeter };
