import { Button } from 'components/button/Button';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { closeModal } from 'reducers/modal';
import {
  contactEmployer,
  getContactEmployerDetails,
  ParseGetContactEmployerDetails,
} from './ContactEmployerApi';
import { FormSingleSelectField, FormTextAreaField } from '@avamae/formbuilder';
import { Spinner } from 'components/spinner/Spinner';
import * as Yup from 'yup';

type ContactEmployerModalProps = {
  data: {
    title: string;
    modalClasses: string;
    modalHeaderClasses: string;
    assessmentID: number;
  };
};

const ContactEmployerModal: React.FC<ContactEmployerModalProps> = ({
  data,
}) => {
  const [submissionDetails, setSubmissionDetails] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [subjectOptions, setSubjectOptions] = useState<
    { label: string; value: string }[]
  >([]);

  useEffect(() => {
    (async () => {
      const result = await getContactEmployerDetails({
        assessmentID: data.assessmentID,
      });
      if (result.response) {
        const { subjectOptions } = ParseGetContactEmployerDetails(
          result.response
        );
        setSubjectOptions(subjectOptions);
        setLoading(false);
      } else {
        setLoading(false);
      }
    })();
  }, []);

  const initialValues = {
    subjectID: '',
    message: '',
  };

  const validationSchema = Yup.object({
    subjectID: Yup.string().required('Please choose a subject'),
    message: Yup.string().required('A message is required'),
  });

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const handleContactEmployer = ({
    subjectID,
    message,
  }: {
    subjectID: string;
    message: string;
  }) => {
    const payload = {
      subjectID: parseInt(subjectID),
      message,
      assessmentID: data.assessmentID,
    };
    (async () => {
      const result = await contactEmployer(payload);
      if (result.response) {
        //if call succeeds, close modal
        dispatch(closeModal());
      }
      if (result.errors) {
        setErrorMessage('Error: Message not sent, please try again later');
      }
    })();
  };

  if (loading) return <Spinner />;
  return (
    <div className="ContactEmployerModal FullWidth">
      <Formik
        initialValues={initialValues}
        onSubmit={handleContactEmployer}
        validationSchema={validationSchema}
      >
        <Form className="FullWidth">
          <div className="Content">
            <FormSingleSelectField
              label="Subject"
              fieldName="subjectID"
              options={subjectOptions}
            />
            <FormTextAreaField fieldName="message" label="Message" />
          </div>
          {errorMessage && <span className="ErrorLabel">{errorMessage}</span>}

          <div className="ModalFooter">
            <Button
              priority="tertiary"
              type="button"
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
            <Button priority="primary" type="submit">
              Send message
            </Button>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export { ContactEmployerModal };
