import React, { createContext } from 'react';
import { LoggedInNavBar } from './LoggedInNavBar';
import { useSelector } from 'react-redux';
import { selectNavType, NavType, selectNavData } from 'reducers/navbar';
import { LoginNavBar } from './LoginNavBar';
import { TitleAndBackNavBar } from './TitleAndBackNavBar';
import {
  GenericActivityHeader,
  GenericActivityHeaderType,
} from 'components/activityComponents/genericActivityHeader/GenericActivityHeader';
import { StandardNavBar } from './StandardNavBar';
import { LogoNavBar } from './LogoNavBar';
import { selectFeatureFlags } from 'reducers/featureFlags';

interface NavBarProps {
  isThemed: boolean;
}

export const NavBarContext = createContext<boolean | null>(null);

export const NavBar: React.FC<NavBarProps> = ({ isThemed }) => {
  const navType = useSelector(selectNavType);
  const navData = useSelector(selectNavData);
  return (
    <NavBarContext.Provider value={isThemed}>
      <div className={isThemed ? 'Themed' : ''}>
        {navType === NavType.LOGIN ? (
          <LoginNavBar />
        ) : navType === NavType.CANDIDATE_SIGN_UP ? (
          <StandardNavBar />
        ) : navType === NavType.TITLE_AND_BACK ? (
          <TitleAndBackNavBar title={navData?.title as string} />
        ) : navType === NavType.APPLY_PSYCRUIT ? (
          <LoggedInNavBar />
        ) : navType === NavType.ACTIVITY ? (
          <GenericActivityHeader
            title={navData?.title as string}
            activityType={navData?.activityType as string}
          />
        ) : navType === NavType.VIRTUAL_ASSESSMENT ? (
          <GenericActivityHeader
            title="Personality Questionnaire"
            activityType={navData?.activityType as string}
            type={GenericActivityHeaderType.VIRTUAL_ASSESSMENT}
          />
        ) : navType === NavType.ABILITY_TEST ? (
          <GenericActivityHeader
            title="Ability Test"
            activityType={navData?.activityType as string}
            type={GenericActivityHeaderType.ABILITY_TEST}
          />
        ) : navType === NavType.SJT ? (
          <GenericActivityHeader
            title="Situational Judgement Test"
            activityType={navData?.activityType as string}
            type={GenericActivityHeaderType.SJT}
          />
        ) : navType === NavType.ASSESSMENT ? (
          <GenericActivityHeader
            title="Blended Assessment"
            activityType={navData?.activityType as string}
            type={GenericActivityHeaderType.BLENDED_TEST}
          />
        ) : navType === NavType.ASSESSMENT_PRACTICE ? (
          <GenericActivityHeader
            title="Practice Test"
            activityType={navData?.activityType as string}
            type={GenericActivityHeaderType.ASSESSMENT_PRACTICE}
          />
        ) : navType === NavType.LOGO ? (
          <LogoNavBar />
        ) : null}
      </div>
    </NavBarContext.Provider>
  );
};
