import { capitalizeFirstLetter } from 'helpers/capitalizeFirstLetter';
import React from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';
interface ButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  priority: 'primary' | 'secondary' | 'tertiary' | 'red' | 'green';
  size?: 'regular' | 'small';
  fontSize?: 'regular' | 'medium' | 'bold';
  icon?: string;
  isPublic?: boolean;
  disabled?: boolean;
  style?: React.CSSProperties;
  roundedRectangle?: boolean;
  addTabIndex?: number;
}

export const Button: React.FC<ButtonProps> = ({
  priority,
  size,
  fontSize,
  icon,
  className,
  children,
  disabled,
  isPublic = false,
  style,
  roundedRectangle = false,
  addTabIndex,
  ...props
}) => {
  const { t } = useTranslation('Button', { i18n });

  const classNameProp = className ? className : '';
  const priorityClass = `Btn${capitalizeFirstLetter(priority)}`;
  const sizeClass = size ? `Btn${capitalizeFirstLetter(size)}` : '';
  const fontSizeClass = fontSize
    ? `Font${capitalizeFirstLetter(fontSize)}`
    : '';

  return (
    <button
      {...props}
      className={`Btn CustomBtn ${classNameProp} ${priorityClass} ${sizeClass} ${fontSizeClass} ${
        isPublic ? 'PublicBtn' : ''
      } ${roundedRectangle ? 'RoundedRect' : ''} ${icon ? 'FixedIcon' : ''}`}
      disabled={disabled}
      style={style}
      tabIndex={addTabIndex ? addTabIndex : undefined}
    >
      {icon && (
        <img
          src={icon}
          alt={t('SSCandidate_Button_Alt_Button_Icon')}
          className="ButtonIcon"
        />
      )}
      {children}
    </button>
  );
};
