import { Button } from 'components/button/Button';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { closeModal } from 'reducers/modal';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';
import Icon_Tick_White from 'assets/apply.clevry/Icon_Tick_White.png';
import { postExpressInterest } from './JobBoardApi';
import { Toast } from 'components/toastMessages/toast';
import { setExpressedInterest } from 'reducers/matchingProcess';

type ExpressInterestModalProps = {
  data: {
    title: string;
    modalClasses: string;
    modalHeaderClasses: string;
    searchID: number;
  };
};

const ExpressInterestModal: React.FC<ExpressInterestModalProps> = ({
  data,
}) => {
  const { t } = useTranslation('Express+Interest+Modal', { i18n });

  const [shareDetails, setShareDetails] = useState<boolean>(true);

  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const handleSubmit = async () => {
    const result = await postExpressInterest({
      hiringManagerSearchId: data.searchID,
      isCandidateInterest: true,
      consentToShareData: shareDetails,
    });

    if (result.response && result.response.status === '1') {
      Toast.openToastSuccessMessage(
        t('SSCandidate_Express_Interest_Toast_Success')
      );
      dispatch(setExpressedInterest(true));

      handleCloseModal();
    }
    if (result.errors) {
      Toast.openToastErrorMessage(
        t('SSCandidate_Express_Interest_Toast_Error')
      );
      console.log('reached');
    }
  };

  return (
    <div className="ExpressInterestModal FullWidth">
      <div className="Content">
        <div
          className={`RadioCard ${shareDetails === true ? 'Active' : ''}`}
          onClick={() => setShareDetails(true)}
        >
          <div className="RadioCard__Button">
            <img src={Icon_Tick_White} alt="Radio button" />
          </div>
          <div className="RadioCard__Text">
            {t('SSCandidate_Express_Interest_Modal_Share')}
          </div>
        </div>

        <div
          className={`RadioCard ${shareDetails === false ? 'Active' : ''}`}
          onClick={() => setShareDetails(false)}
        >
          <div className="RadioCard__Button">
            <img src={Icon_Tick_White} alt="Radio button" />
          </div>
          <div className="RadioCard__Text">
            {t('SSCandidate_Express_Interest_Modal_Dont_Share')}
          </div>
        </div>
      </div>

      <div className="ModalFooter">
        <Button priority="tertiary" type="button" onClick={handleCloseModal}>
          {t('SSCandidate_Express_Interest_Modal_Cancel')}
        </Button>
        <Button
          className="Discard"
          priority="primary"
          type="submit"
          onClick={handleSubmit}
        >
          {t('SSCandidate_Express_Interest_Modal_Apply')}
        </Button>
      </div>
    </div>
  );
};

export { ExpressInterestModal };
