import Icon_Tick_White from 'assets/apply.clevry/Icon_Tick_White.png';

type MatchingProcessCardProps = {
  image: string;
  text: string;
  subtext?: string;
};

const MatchingProcessRadioCard: React.FC<MatchingProcessCardProps> = (
  props
) => {
  return (
    <div className="MatchingProcessCard">
      <div className="RadioButton">
        <img src={Icon_Tick_White} alt="Tick" />
      </div>

      <div className="MatchingProcessCardImageContainer">
        <img src={props.image} alt="" />
      </div>

      <div className="MatchingProcessCardText">
        <h2>{props.text}</h2>
        <p>{props.subtext && props.subtext}</p>
      </div>
    </div>
  );
};

export default MatchingProcessRadioCard;
