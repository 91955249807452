import React, { useState } from 'react';
import { InteractiveFeedbackMenuHeading } from './InteractiveFeedbackMenuHeading';
import {
  InteractiveFeedbackMenuRow,
  InteractiveFeedbackMenuRowProps,
} from './InteractiveFeedbackMenuRow';
import useMedia from 'use-media';
import { Button } from 'components/button/Button';
import { images } from 'config';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';
import { InteractiveFeedbackGraphRimOverlay } from './InteractiveFeedbackGraphRimOverlay';
import { useWindowDimensions } from 'helpers/useGetWindowDimensions';

export enum InteractiveFeedbackGraphType {
  TEAM = 'TEAM',
  LEADERSHIP = 'LEADERSHIP',
  SALES = 'SALES',
}

export type InteractiveFeedbackMenuProps = {
  title: string;
  active: number | null;
  height: number;
  setActive: React.Dispatch<React.SetStateAction<number | null>>;
  headingIcon?: string;
  headingCloseClickHandler?: () => void;
  backArrowClickHandler?: () => void;
  score?: number;
  downloadReport?: boolean;
  wheelImage: string;
  graphActive: boolean;
  setGraphActive: React.Dispatch<React.SetStateAction<boolean>>;
  graphType: string;
  reportCode: string | undefined;
  isCandidateReport: boolean;
  sectionData?: any;
};

const teamIconKeys = {
  1: images.leadershipStrengthsReports.icon_Yourself,
  2: images.teamStrengthsReports.icon_Harmoniser,
  3: images.teamStrengthsReports.icon_Catalyst,
  4: images.teamStrengthsReports.icon_Pragmatist,
};
const leadershipIconKeys = {
  1: images.leadershipStrengthsReports.icon_People,
  2: images.leadershipStrengthsReports.icon_Tasks,
  3: images.leadershipStrengthsReports.icon_TheWay,
  4: images.leadershipStrengthsReports.icon_Yourself,
};
const salesIconKeys = {
  1: images.salesStrengthsReports.icon_Grit,
  2: images.salesStrengthsReports.icon_Empathy,
  3: images.salesStrengthsReports.icon_Appetite,
  4: images.salesStrengthsReports.icon_Focus,
};

export const InteractiveFeedbackGraph = ({
  title,
  headingCloseClickHandler,
  active,
  setActive,
  headingIcon,
  height,
  score,
  downloadReport,
  wheelImage,
  graphActive,
  setGraphActive,
  graphType,
  reportCode,
  isCandidateReport,
  sectionData,
}: InteractiveFeedbackMenuProps) => {
  const isTablet = useMedia('(max-width: 768px)');

  const { t } = useTranslation('Interactive+Feedback', { i18n });

  const { width } = useWindowDimensions();

  return (
    <div className="InteractiveFeedbackMenu">
      <InteractiveFeedbackMenuHeading
        title={title}
        icon={headingIcon}
        headingCloseClickHandler={headingCloseClickHandler}
        score={score}
        reportCode={reportCode}
        isCandidateReport={isCandidateReport}
        sectionData={sectionData}
      />

      <div className="TeamStrengthsListSidePanelTab__upper">
        <div className="StrengthsLabels">
          <div className={`LabelsLeft TopLeft ${graphType}`}>
            <img
              className="StrengthsLabelIcon"
              src={
                graphType === InteractiveFeedbackGraphType.TEAM
                  ? teamIconKeys[1]
                  : graphType === InteractiveFeedbackGraphType.LEADERSHIP
                  ? leadershipIconKeys[1]
                  : graphType === InteractiveFeedbackGraphType.SALES
                  ? salesIconKeys[1]
                  : ''
              }
              alt="StrengthIcon1"
            />
            <div className={`LabelText`}>
              <div className="LabelText__Upper">
                {width <= 768 && (
                  <img
                    className="StrengthsLabelIcon Small"
                    src={
                      graphType === InteractiveFeedbackGraphType.TEAM
                        ? teamIconKeys[1]
                        : graphType === InteractiveFeedbackGraphType.LEADERSHIP
                        ? leadershipIconKeys[1]
                        : graphType === InteractiveFeedbackGraphType.SALES
                        ? salesIconKeys[1]
                        : ''
                    }
                    alt="StrengthIcon1"
                  />
                )}
                <span>
                  {graphType === InteractiveFeedbackGraphType.TEAM
                    ? t('SSCandidate_Interactive_Feedback_Idea')
                    : graphType === InteractiveFeedbackGraphType.LEADERSHIP
                    ? t('SSCandidate_Interactive_Feedback_Leading_People')
                    : graphType === InteractiveFeedbackGraphType.SALES
                    ? t('SSCandidate_Interactive_Feedback_Grit')
                    : ''}
                </span>
              </div>

              {/* <p>{t('TeamStrengths_IDEA_Description')}</p> */}

              <Button
                className={`InteractiveFeedbackGraphButton ${
                  !graphActive &&
                  active !== undefined &&
                  active !== null &&
                  active === 0
                    ? 'Active'
                    : ''
                }`}
                priority="tertiary"
                onClick={() => {
                  if (graphActive) {
                    setGraphActive(false);
                    setActive(0);
                  } else if (
                    active !== undefined &&
                    active !== null &&
                    active === 0
                  ) {
                    setActive(null);
                  } else {
                    setActive(0);
                  }
                }}
              >
                View more
              </Button>
            </div>
          </div>

          <div className={`LabelsRight TopRight ${graphType}`}>
            <div className={`LabelText`}>
              <div className="LabelText__Upper">
                {width <= 768 && (
                  <img
                    className="StrengthsLabelIcon Small"
                    src={
                      graphType === InteractiveFeedbackGraphType.TEAM
                        ? teamIconKeys[2]
                        : graphType === InteractiveFeedbackGraphType.LEADERSHIP
                        ? leadershipIconKeys[2]
                        : graphType === InteractiveFeedbackGraphType.SALES
                        ? salesIconKeys[2]
                        : ''
                    }
                    alt="StrengthIcon2"
                  />
                )}
                <span>
                  {graphType === InteractiveFeedbackGraphType.TEAM
                    ? t('SSCandidate_Interactive_Feedback_Thought')
                    : graphType === InteractiveFeedbackGraphType.LEADERSHIP
                    ? t('SSCandidate_Interactive_Feedback_Leading_Tasks')
                    : graphType === InteractiveFeedbackGraphType.SALES
                    ? t('SSCandidate_Interactive_Feedback_Empathy')
                    : ''}
                </span>
              </div>
              {/* <p>{t('TeamStrengths_THOUGHT_Description')}</p> */}

              <Button
                className={`InteractiveFeedbackGraphButton ${
                  !graphActive && active && active === 1 ? 'Active' : ''
                }`}
                priority="tertiary"
                onClick={() => {
                  if (graphActive) {
                    setGraphActive(false);
                    setActive(1);
                  } else if (active && active === 1) {
                    setActive(null);
                  } else {
                    setActive(1);
                  }
                }}
              >
                View more
              </Button>
            </div>
            <img
              className="StrengthsLabelIcon"
              src={
                graphType === InteractiveFeedbackGraphType.TEAM
                  ? teamIconKeys[2]
                  : graphType === InteractiveFeedbackGraphType.LEADERSHIP
                  ? leadershipIconKeys[2]
                  : graphType === InteractiveFeedbackGraphType.SALES
                  ? salesIconKeys[2]
                  : ''
              }
              alt="Team Strengths Thought"
            />
          </div>
        </div>

        <div className="StrengthsImageContainer">
          <img
            className="StrengthsImageChart"
            src={wheelImage ? wheelImage : ''}
            alt="Strength chart"
          />

          <InteractiveFeedbackGraphRimOverlay
            active={active}
            setActive={setActive}
            graphActive={graphActive}
            setGraphActive={setGraphActive}
            graphType={graphType}
          />
        </div>

        <div className="StrengthsLabels">
          <div className={`LabelsLeft BottomLeft ${graphType}`}>
            <img
              className="StrengthsLabelIcon"
              src={
                graphType === InteractiveFeedbackGraphType.TEAM
                  ? teamIconKeys[3]
                  : graphType === InteractiveFeedbackGraphType.LEADERSHIP
                  ? leadershipIconKeys[3]
                  : graphType === InteractiveFeedbackGraphType.SALES
                  ? salesIconKeys[3]
                  : ''
              }
              alt="Team Strengths Influence"
            />
            <div className={`LabelText`}>
              <div className="LabelText__Upper">
                {width <= 768 && (
                  <img
                    className="StrengthsLabelIcon Small"
                    src={
                      graphType === InteractiveFeedbackGraphType.TEAM
                        ? teamIconKeys[3]
                        : graphType === InteractiveFeedbackGraphType.LEADERSHIP
                        ? leadershipIconKeys[3]
                        : graphType === InteractiveFeedbackGraphType.SALES
                        ? salesIconKeys[3]
                        : ''
                    }
                    alt="StrengthIcon1"
                  />
                )}
                <span>
                  {graphType === InteractiveFeedbackGraphType.TEAM
                    ? t('SSCandidate_Interactive_Feedback_Influence')
                    : graphType === InteractiveFeedbackGraphType.LEADERSHIP
                    ? t('SSCandidate_Interactive_Feedback_Leading_Way')
                    : graphType === InteractiveFeedbackGraphType.SALES
                    ? t('SSCandidate_Interactive_Feedback_Appetite')
                    : ''}
                </span>
              </div>
              {/* <p>{t('TeamStrengths_INFLUENCE_Description')}</p> */}
              <Button
                className={`InteractiveFeedbackGraphButton ${
                  !graphActive && active && active === 3 ? 'Active' : ''
                }`}
                priority="tertiary"
                onClick={() => {
                  if (graphActive) {
                    setGraphActive(false);
                    setActive(3);
                  } else if (active && active === 3) {
                    setActive(null);
                  } else {
                    setActive(3);
                  }
                }}
              >
                View more
              </Button>
            </div>
          </div>

          <div className={`LabelsRight BottomRight ${graphType}`}>
            <div className={`LabelText`}>
              <div className="LabelText__Upper">
                {width <= 768 && (
                  <img
                    className="StrengthsLabelIcon Small"
                    src={
                      graphType === InteractiveFeedbackGraphType.TEAM
                        ? teamIconKeys[4]
                        : graphType === InteractiveFeedbackGraphType.LEADERSHIP
                        ? leadershipIconKeys[4]
                        : graphType === InteractiveFeedbackGraphType.SALES
                        ? salesIconKeys[4]
                        : ''
                    }
                    alt="StrengthIcon1"
                  />
                )}
                <span>
                  {graphType === InteractiveFeedbackGraphType.TEAM
                    ? t('SSCandidate_Interactive_Feedback_Action')
                    : graphType === InteractiveFeedbackGraphType.LEADERSHIP
                    ? t('SSCandidate_Interactive_Feedback_Leading_Yourself')
                    : graphType === InteractiveFeedbackGraphType.SALES
                    ? t('SSCandidate_Interactive_Feedback_Focus')
                    : ''}
                </span>
              </div>
              {/* <p>{t('TeamStrengths_ACTION_Description')}</p> */}
              <Button
                className={`InteractiveFeedbackGraphButton ${
                  !graphActive && active && active === 2 ? 'Active' : ''
                }`}
                priority="tertiary"
                onClick={() => {
                  if (graphActive) {
                    setGraphActive(false);
                    setActive(2);
                  } else if (active && active === 2) {
                    setActive(null);
                  } else {
                    setActive(2);
                  }
                }}
              >
                View more
              </Button>
            </div>
            <img
              className="StrengthsLabelIcon"
              src={
                graphType === InteractiveFeedbackGraphType.TEAM
                  ? teamIconKeys[4]
                  : graphType === InteractiveFeedbackGraphType.LEADERSHIP
                  ? leadershipIconKeys[4]
                  : graphType === InteractiveFeedbackGraphType.SALES
                  ? salesIconKeys[4]
                  : ''
              }
              alt="Team Strengths Action"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
