import instance from 'api';
import { AxiosRequestConfig } from 'axios';
import { ApiReturn } from 'components/forgottenPassword/forgottenPasswordApi';
import { endpoints } from 'config';
import { IUpdateAccountType, IUpdateRoleType } from '../myDevelopment/models';

const getAssessmentListUrl = endpoints.account.getListOfCandidateAssessments;
const getApplicationsListUrl = endpoints.jobApplication.getListOfApplications;

const applicationListUrl = endpoints.jobApplication.getListOfApplications;

export const getApplicationListData = async (
  payload: any
): Promise<ApiReturn> => {
  try {
    const config: AxiosRequestConfig = {
      params: {
        ...payload,
      },
      withCredentials: true,
    };
    const res = await instance.get(applicationListUrl, config);
    if (res.data.details.status === '1') {
      return {
        response: res.data.details,
        errors: null,
      };
    } else {
      return {
        response: res.data.details,
        errors: res.data.errors,
      };
    }
  } catch (err: any) {
    return {
      response: null,
      errors: err,
    };
  }
};
export const getAssessmentList = async (): Promise<ApiReturn> => {
  try {
    const res = await instance.get(getAssessmentListUrl);
    if (res.data.status === '1') {
      return {
        errors: null,
        response: res.data.details,
      };
    } else {
      return {
        response: res.data.details,
        errors: res.data.errors,
      };
    }
  } catch (error: any) {
    return {
      response: null,
      errors: error.response.data.errors,
    };
  }
};
export const getDashboardInfo = async (): Promise<ApiReturn> => {
  try {
    const res = await instance.get(endpoints.dashboard.getInfo);
    if (res.data.status === '1') {
      return {
        errors: null,
        response: res.data.details,
      };
    } else {
      return {
        response: res.data.details,
        errors: res.data.errors,
      };
    }
  } catch (error) {
    return {
      response: null,
      errors: error,
    };
  }
};
export const getAccountType = async (): Promise<ApiReturn> => {
  try {
    const res = await instance.get(endpoints.myDevelopment.getAccountType);
    if (res.data.status === '1') {
      return {
        errors: null,
        response: res.data.details,
      };
    } else {
      return {
        response: res.data.details,
        errors: res.data.errors,
      };
    }
  } catch (error: any) {
    return {
      response: null,
      errors: error.response.data.errors,
    };
  }
};
export const updateAccountType = async (
  payload: IUpdateAccountType
): Promise<ApiReturn> => {
  try {
    const res = await instance.post(
      endpoints.myDevelopment.updateAccountType,
      payload
    );
    if (res.data.status === '1') {
      return {
        errors: null,
        response: res.data,
      };
    } else {
      return {
        response: res.data,
        errors: res.data.errors,
      };
    }
  } catch (error: any) {
    return {
      response: null,
      errors: error.response.data.errors,
    };
  }
};
export const updateRoleType = async (
  payload: IUpdateRoleType
): Promise<ApiReturn> => {
  try {
    const res = await instance.post(
      endpoints.account.updateAccountRoles,
      payload
    );
    if (res.data.status === '1') {
      return {
        errors: null,
        response: res.data,
      };
    } else {
      return {
        response: res.data,
        errors: res.data.errors,
      };
    }
  } catch (error: any) {
    return {
      response: null,
      errors: error.response.data.errors,
    };
  }
};
