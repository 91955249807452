import instance from 'api';
import axios, { AxiosRequestConfig } from 'axios';
import { ApiReturn } from 'components/forgottenPassword/forgottenPasswordApi';
import { endpoints } from 'config';
import { StringLiteral } from 'typescript';
import { get, post } from '../../../helpers/newMakeEndpointRequest';

// My strengths
export type GetMyStrengthScoresResponse = {
  id: number;
  details: GetMyStrengthScoresDetails;
  status: string;
  errors: any[];
};

export type GetMyStrengthScoresDetails = {
  candidateGender: string;
  candidateFirstName: string;
  headingName: string;
  topFiveStrengths: StrengthsSummary[];
  strengthScales: StrengthScaleType[];
  isCandidateReport: boolean;
  reportCode: string;
  isReportDownloadable?: boolean;
  isAbilityReportDownloadable?: boolean;
  isSJTReportDownloadable?: boolean;
};

export type StrengthsSummary = {
  scaleName: string;
  scaleDescription: string;
  imageURL: string;
  colour: string;
  iconURL: string;
};
export type StrengthScaleType = {
  scaleScore: number;
  scaleNameLow: string;
  scaleNameHigh: string;
  scaleDescriptionLow: string;
  scaleDescriptionHigh: string;
};

export const getStrengthScores = async (params: {
  AssessmentId?: number;
  LanguageCultureInfo?: string;
}): Promise<
  | {
      response: GetMyStrengthScoresResponse;
      errors: null;
    }
  | {
      response: null;
      errors: any;
    }
> => {
  const config: AxiosRequestConfig = {
    params,
  };
  try {
    const res = await instance.get(
      endpoints.interactiveFeedback.getStrengthScores,
      config
    );
    if (res.data.status === '1') {
      return {
        response: res.data,
        errors: null,
      };
    } else {
      return {
        response: null,
        errors: res.data.errors,
      };
    }
  } catch (res) {
    const error: any = res;

    if (error.response && error.response.data && error.response.data.errors) {
      return {
        response: null,
        errors: error.response.data.errors,
      };
    } else {
      return {
        response: null,
        errors: [],
      };
    }
  }
};

// My Development
export type GetMyDevelopmentScoresResponse = {
  id: number;
  details: GetMyDevelopmentScoresDetails;
  status: string;
  errors: any[];
};

export type GetMyDevelopmentScoresDetails = {
  candidateGender: string;
  candidateFirstName: string;
  reportCode: string;
  isCandidateReport: boolean;
  developmentSuggestions: DevelopmentSuggestionType[];
  careerSuggestions: CareerSuggestionType[];
  adaptingStrengths: AdaptingStrengthType[];
  isReportDownloadable?: boolean;
};

export type AdaptingStrengthType = {
  scaleName: string;
  englishScaleName: string | null;
  hintsAndTips: string | null;
  imageURL: string;
  iconURL: string;
  colour: string;
};

export type CareerSuggestionType = {
  scaleName: string;
  englishScaleName: string;
  strengthText: string | null;
  concernText: string | null;
  imageURL: string;
  iconURL: string;
  colour: string;
};

export type DevelopmentSuggestionType = {
  areaOfPersonality: string;
  scaleName: string;
  englishScaleName: string;
  developmentPossibilities: string;
  imageURL: string;
  iconURL: string;
  colour: string;
};

export const getDevelopmentScores = async (params: {
  AssessmentId?: number;
  LanguageCultureInfo?: string;
}): Promise<
  | {
      response: GetMyDevelopmentScoresResponse;
      errors: null;
    }
  | {
      response: null;
      errors: any;
    }
> => {
  const config: AxiosRequestConfig = {
    params,
  };
  // TO DO REPLACE WHEN ENDPOINT IS PUBLISHED
  try {
    const res = await instance.get(
      endpoints.interactiveFeedback.getDevelopmentScores,
      config
    );
    if (res.data.status === '1') {
      return {
        response: res.data,
        errors: null,
      };
    } else {
      return {
        response: null,
        errors: res.data.errors,
      };
    }
  } catch (res) {
    const error: any = res;

    if (error.response && error.response.data && error.response.data.errors) {
      return {
        response: null,
        errors: error.response.data.errors,
      };
    } else {
      return {
        response: null,
        errors: [],
      };
    }
  }
};

// My capabilities
export type GetCapabilitiesScoresResponse = {
  id: number;
  details: GetCapabilitiesScoresDetails;
  status: string;
  errors: any[];
};

export type GetCapabilitiesScoresDetails = {
  candidateFirstName: string;
  candidateLastName: string;
  candidateGender: string;
  abilityTests: GetCapabilitiesScoresAbilityTest[];
  sjTs: GetCapabilitiesScoresSJT[];
  isAbilityReportDownloadable?: boolean;
  isSJTReportDownloadable?: boolean;
};

export type ReportDownloadFlags = {
  isAbilityReportDownloadable?: boolean;
  isSJTReportDownloadable?: boolean;
};

export type GetCapabilitiesScoresAbilityTest = {
  testName: string;
  testDescription: string;
  testScore: number;
  narrativeText: string;
};

export type GetCapabilitiesScoresSJT = {
  sjtName: string;
  sjtScore: number;
  reportCode: string;
  overallPerformanceText: string;
  testTypeShortName: string;
  testType: string;
  overriddenProngDisplayName: string;
  stenCombined: string;
  normGroupName: string;
  sjtStenDesc: string;
  testMinutes: number;
  candidateTime: number;
  averageTime: number;
  roleName: string;
  assessmentID: number;
  scales: GetCapabilitiesScoresScale[];
};

export type GetCapabilitiesScoresScale = {
  scaleID: number;
  scaleName: string;
  scaleScore: number;
  narrativeText: string;
  developmentText: string;
};

export const getCapabilitiesScores = async (params: {
  AssessmentId?: number;
  LanguageCultureInfo?: string;
}): Promise<
  | {
      response: GetCapabilitiesScoresResponse;
      errors: null;
    }
  | {
      response: null;
      errors: any;
    }
> => {
  try {
    const config: AxiosRequestConfig = {
      params,
    };
    const res = await instance.get(
      endpoints.interactiveFeedback.getCapabilityScores,
      config
    );
    if (res.data.status === '1') {
      return {
        response: res.data,
        errors: null,
      };
    } else {
      return {
        response: null,
        errors: res.data.errors,
      };
    }
  } catch (res) {
    const error: any = res;

    if (error.response && error.response.data && error.response.data.errors) {
      return {
        response: null,
        errors: error.response.data.errors,
      };
    } else {
      return {
        response: null,
        errors: [],
      };
    }
  }
};

export type GetTeamStrengthsScoresResponse = {
  id: number;
  details: GetMyTeamStrengthsScoresDetails;
  status: string;
  errors: any[];
};

export type GetMyTeamStrengthsScoresDetails = {
  candidateId: number;
  candidateFirstName: string;
  candidateSiteUserId: number;
  wheelDownloadUri: string;
  scores: TeamStrengthsScoreType[];
  reportCode: string;
  isCandidateReport: boolean;
  isReportDownloadable?: boolean;
};

export type TeamStrengthsScoreType = {
  teamStrengthCode: string;
  name: string;
  score: number;
};

export const getTeamStrengthsScores = async (params: {
  AssessmentId?: number;
  LanguageCultureInfo?: string;
}): Promise<
  | {
      response: GetTeamStrengthsScoresResponse;
      errors: null;
    }
  | {
      response: null;
      errors: any;
    }
> => {
  try {
    const config: AxiosRequestConfig = {
      params,
    };
    const res = await instance.get(
      endpoints.interactiveFeedback.getTeamStrengthScores,
      config
    );
    if (res.data.status === '1') {
      return {
        response: res.data,
        errors: null,
      };
    } else {
      return {
        response: null,
        errors: res.data.errors,
      };
    }
  } catch (res) {
    const error: any = res;

    if (error.response && error.response.data && error.response.data.errors) {
      return {
        response: null,
        errors: error.response.data.errors,
      };
    } else {
      return {
        response: null,
        errors: [],
      };
    }
  }
};

export type GetLeadershipStrengthsScoresResponse = {
  id: number;
  details: GetMyLeadershipStrengthsScoresDetails;
  status: string;
  errors: any[];
};

export type GetMyLeadershipStrengthsScoresDetails = {
  candidateId: number;
  candidateFirstName: string;
  candidateSiteUserId: number;
  wheelDownloadUri: string;
  scores: LeadershipStrengthsScoreType[];
  reportCode: string;
  isCandidateReport: boolean;
  isReportDownloadable?: boolean;
};

export type LeadershipStrengthsScoreType = {
  leadershipCode: string;
  name: string;
  score: number;
};

export const getLeadershipStrengthsScores = async (params: {
  AssessmentId?: number;
  LanguageCultureInfo?: string;
}): Promise<
  | {
      response: GetLeadershipStrengthsScoresResponse;
      errors: null;
    }
  | {
      response: null;
      errors: any;
    }
> => {
  try {
    const config: AxiosRequestConfig = {
      params,
    };
    const res = await instance.get(
      endpoints.interactiveFeedback.getLeadershipScores,
      config
    );
    if (res.data.status === '1') {
      return {
        response: res.data,
        errors: null,
      };
    } else {
      return {
        response: null,
        errors: res.data.errors,
      };
    }
  } catch (res) {
    const error: any = res;

    if (error.response && error.response.data && error.response.data.errors) {
      return {
        response: null,
        errors: error.response.data.errors,
      };
    } else {
      return {
        response: null,
        errors: [],
      };
    }
  }
};

export type GetSalesStyleScoresResponse = {
  id: number;
  details: GetMySalesStyleScoresDetails;
  status: string;
  errors: any[];
};

export type GetMySalesStyleScoresDetails = {
  candidateId: number;
  candidateFirstName: string;
  candidateSiteUserId: number;
  wheelDownloadUri: string;
  scores: SalesStyleScoreType[];
  reportCode: string;
  isCandidateReport: boolean;
  isReportDownloadable?: boolean;
};

export type SalesStyleScoreType = {
  salesCode: string;
  name: string;
  score: number;
};

export const getSalesStyleScores = async (params: {
  AssessmentId?: number;
  LanguageCultureInfo?: string;
}): Promise<
  | {
      response: GetSalesStyleScoresResponse;
      errors: null;
    }
  | {
      response: null;
      errors: any;
    }
> => {
  try {
    const config: AxiosRequestConfig = {
      params,
    };
    const res = await instance.get(
      endpoints.interactiveFeedback.getSalesScores,
      config
    );
    if (res.data.status === '1') {
      return {
        response: res.data,
        errors: null,
      };
    } else {
      return {
        response: null,
        errors: res.data.errors,
      };
    }
  } catch (res) {
    const error: any = res;

    if (error.response && error.response.data && error.response.data.errors) {
      return {
        response: null,
        errors: error.response.data.errors,
      };
    } else {
      return {
        response: null,
        errors: [],
      };
    }
  }
};

export const getReportLanguageOptions = async (params: {
  assessmentId?: number;
}): Promise<
  ApiReturn<{
    reportLanguageOptions: { languageId: number; languageName: string }[];
  }>
> => {
  const url = endpoints.interactiveFeedback.getReportLanguageOptions;
  return await get(url, params);
};

export const getInteractiveFeedbackAccessToken = async (params: {
  Token: string;
}) => {
  const url = endpoints.auth.getTokenAssessmentId;
  try {
    const config: AxiosRequestConfig = {
      params,
    };
    const res = await axios.get(url, config);
    if (res && res.data && res.data.status === '1') {
      if (res.data.details) {
        return {
          response: res.data,
          errors: null,
        };
      } else {
        return {
          response: res.data,
          errors: null,
        };
      }
    } else {
      return {
        response: null,
        errors: res.data.errors,
      };
    }
  } catch (err: any) {
    return {
      response: null,
      errors: err,
    };
  }
};
