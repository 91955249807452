import React, { useCallback, useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import searchIcon from 'assets/apply.clevry/Icon_Search.png';
import _ from 'lodash';
import { endpoints } from 'config';
import instance from 'api';
import uniqid from 'uniqid';

type LocationSelectorProps = {
  locationChoice: string;
  updateLocation: (
    label: string,
    longitude: any,
    latitude: any,
    push: (obj: any) => void
  ) => void;
  readOnly: boolean;
  push: (obj: any) => void;
  isPublic?: boolean;
  isApplication?: boolean;
  isCV?: boolean;
  icon?: string;
  placeholderText?: string;
};

let DEFAULT_PLACEHOLDER = 'Type your search here';

const LocationSelector: React.FC<LocationSelectorProps> = ({
  locationChoice,
  updateLocation,
  readOnly,
  push,
  isPublic = false,
  isApplication = false,
  isCV = false,
  icon,
  placeholderText,
}) => {
  const [placeholder, setPlaceholder] = useState<string>('Test');
  const [uid, setUid] = useState<string>(uniqid());

  useEffect(() => {
    if (locationChoice !== '') {
      setPlaceholder(locationChoice);
    } else {
      setPlaceholder(DEFAULT_PLACEHOLDER);
      setUid(uniqid());
    }
  }, [locationChoice]);

  const setLocationData = (data: any) => {
    const matchedLocations = data.map((location: any) => {
      const { streetName, municipality, country, countrySubdivisionName } =
        location.address;
      const label = `${
        streetName && streetName !== null ? streetName + ' - ' : ''
      } ${municipality && municipality !== null ? municipality + ' - ' : ''}  ${
        countrySubdivisionName !== null ? `${countrySubdivisionName},` : ''
      } ${country && country}`.replace(/  +/g, ' ');
      const { lat, lon } = location.position;
      const value = { latitude: lat, longitude: lon };
      return { label, value };
    });
    return matchedLocations;
  };

  const getLocationSuggestions = (inputValue: any, callback: any) => {
    if (inputValue) {
      const endpoint = isPublic
        ? endpoints.matchingProcess.publicGetLocationSuggestions
        : isApplication
        ? endpoints.jobApplication.getLocationSuggestions
        : isCV
        ? endpoints.account.getLocationSuggestions
        : endpoints.matchingProcess.getLocationSuggestions;
      instance
        .get(endpoint, {
          params: { partialLocation: inputValue },
        })
        .then((result: any) => {
          if (
            result &&
            result.data &&
            result.data.status === '1' &&
            result.data.details &&
            result.data.details.results
          ) {
            callback(setLocationData(result.data.details.results));
          }
        });
    } else {
      return callback([]);
    }
  };

  const handleGetLocationSuggestions = useCallback(
    _.debounce(getLocationSuggestions, 500),
    []
  );

  const handleChange = (e: any) => {
    if (e && e.value && e.label) {
      const data = e;

      const location = data.label;

      const { longitude, latitude } = data.value;

      updateLocation(location, longitude, latitude, push);
    }
  };

  return (
    <div className="FormField FormFieldWithIcon LocationSelect">
      <span className={`Icon_Field Icon_Field_25`}></span>
      <AsyncSelect
        key={uid}
        id="Location"
        className={`DropDown SelectDropdown`}
        classNamePrefix="Select"
        placeholder={placeholder}
        onMenuOpen={() => {
          setPlaceholder(placeholder);
          updateLocation('', null, null, push);
        }}
        onChange={handleChange}
        loadOptions={handleGetLocationSuggestions}
        value={undefined}
        isDisabled={readOnly}
        noOptionsMessage={() => 'Please search for a location'}
        loadingMessage={() => 'Loading...'}
      />

      <img className="SearchIcon" src={icon ? icon : searchIcon} alt="search" />
    </div>
  );
};

export default LocationSelector;
