import React, { useState } from 'react';
import { useField, useFormikContext } from 'formik';
import { Dropdown } from 'components/helpers/Dropdown';
import { ErrorM } from 'components/helpers/ErrorM';
export type SelectOption = { value: any; label: string };

export interface FormSingleSelectFieldProps<T> {
  fieldName: keyof T & string;
  options: SelectOption[];
  label: string;
  readOnly?: boolean;
  dropdownProps?: any;
  useNumberForValue?: boolean;
  placeholder?: string;
  optionalChangeHandler?: (
    fieldName: string,
    label: string,
    value: any
  ) => void;
  showErrors?: boolean;
}

const FormSingleSelectCustomField = function <T>(
  props: FormSingleSelectFieldProps<T>
) {
  const {
    fieldName,
    label,
    options,
    readOnly,
    dropdownProps,
    useNumberForValue = false,
    placeholder,
    optionalChangeHandler,
    showErrors = true,
  } = props;
  const formik = useFormikContext<T>();
  const [field, meta, helpers] = useField(fieldName.toString());

  const handleChange = (e: SelectOption) => {
    if (optionalChangeHandler) {
      optionalChangeHandler(fieldName, e.label, e.value);
      return;
    }
    if (!useNumberForValue)
      formik.setFieldValue(fieldName, {
        optionId: e.value,
        optionText: e.label,
      });
    else formik.setFieldValue(fieldName, e.value);
  };

  let value = options.find((o) => o.value === field.value?.optionId);
  if (useNumberForValue) value = options.find((o) => o.value === field.value);

  const [isFocused, setIsFocused] = useState(false);

  const onFocus = () => setIsFocused(true);
  const onBlur = () => setIsFocused(false);

  // console.log('fieldName', fieldName);
  return (
    <div className="FormBox FormSideBySide">
      <div className={`FormLabel ${isFocused ? 'Focused' : ''}`}>{label}</div>
      <div className="FormField">
        <Dropdown
          onChange={handleChange}
          options={options}
          value={value}
          readOnly={readOnly}
          roundedRectangle
          dropdownProps={dropdownProps}
          onFocus={onFocus}
          onBlur={onBlur}
          {...(placeholder ? { placeholder } : {})}
        />
        {showErrors && <ErrorM name={fieldName.toString()} />}
      </div>
    </div>
  );
};

export { FormSingleSelectCustomField };
