import { ColumnDetail } from '@avamae/table';
import React from 'react';
import {
  AmountField,
  DateTimeRangeField,
  FilterMetadata,
  MultiChoiceField,
  SingleChoiceField,
  TextContainsField,
} from './FilterBuilder';
import FilterBuilderMulti from './FilterBuilderMulti';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';

export interface FilterBuilderInnerProps {
  columns: ColumnDetail<any>[];
  hidden: string[];
  keyVal: number;
  metadata: Record<string, FilterMetadata>;
  resetFlag?: boolean;
  defaultOperators?: { [key: string]: { value: string; label: string } };
}

const FilterBuilderInner: React.FC<FilterBuilderInnerProps> = ({
  columns,
  hidden,
  keyVal,
  metadata,
  resetFlag,
  defaultOperators,
}) => {
  const { t } = useTranslation('Filter+Builder', { i18n });

  const isVisible = (x: ColumnDetail<any>) =>
    x.bFilterable && !hidden.includes(x.columnKey.toString().toLowerCase());

  return (
    <div className="Filters">
      {columns.filter(isVisible).map((x) => {
        let defaultOperator;
        if (
          defaultOperators &&
          x.columnKey.toLocaleString() in defaultOperators
        ) {
          defaultOperator = defaultOperators[x.columnKey.toLocaleString()];
        }
        switch (x.filterMetadata.filterType) {
          case 'TextContains':
            return (
              <div key={x.columnKey.toString()}>
                <TextContainsField
                  name={x.columnKey.toString()}
                  label={x.labelValue}
                  defaultOperator={defaultOperator}
                />
              </div>
            );
          case 'Amount':
            return (
              <div key={x.columnKey.toString()}>
                <AmountField
                  name={x.columnKey.toString()}
                  label={x.labelValue}
                  defaultOperator={defaultOperator}
                />
              </div>
            );
          case 'SingleChoice':
            return (
              <div key={x.columnKey.toString()}>
                <SingleChoiceField
                  name={x.columnKey.toString()}
                  label={x.labelValue}
                  keyVal={keyVal}
                  possibleValues={
                    x.filterMetadata.details?.PossibleValues ?? []
                  }
                />
              </div>
            );
          case 'MultipleChoice':
            return (
              <MultiChoiceField
                name={x.columnKey.toString()}
                label={x.labelValue}
                resetFlag={resetFlag}
                keyVal={keyVal}
                possibleValues={x.filterMetadata.details?.PossibleValues ?? []}
              />
            );
          case 'DateTime:Range':
            return (
              <div key={x.columnKey.toString()}>
                <DateTimeRangeField
                  name={x.columnKey.toString()}
                  label={x.labelValue}
                  placeholderText1={t(
                    'SSCandidate_Filter_Builder_Placeholder_Text_Starting_At'
                  )}
                  placeholderText2={t(
                    'SSCandidate_Filter_Builder_Placeholder_Text_Ending_At'
                  )}
                />
              </div>
            );

          default:
            return (
              <div key={x.columnKey.toString()}>
                {x.columnKey} - {metadata[x.columnKey.toString()].filterType}
              </div>
            );
        }
      })}
    </div>
  );
};

export { FilterBuilderInner };
