import { endpoints } from 'config';
import axios from 'api';
import { AxiosRequestConfig } from 'axios';
import { config } from 'process';
import { get } from 'helpers/newMakeEndpointRequest';
import { ExpressInterestPayload, JobListPayload } from './models';

type Error = {};

type ApiReturn<T = any> = {
  response: T | null;
  errors: Error[] | null;
};

const jobListUrl = endpoints.jobBoard.jobList;
const applyNow = endpoints.jobBoard.applyNow;
const expressInterest = endpoints.jobBoard.expressInterest;
const clientLogo = endpoints.jobBoard.getClientLogo;
const applicationStatus = endpoints.jobBoard.getApplicationStatus;

export const getJobDetails = async (
  payload: any,
  isPublic?: boolean
): Promise<ApiReturn> => {
  try {
    const config: AxiosRequestConfig = {
      params: {
        ...payload,
      },
      withCredentials: true,
    };

    const res = await axios.get(
      isPublic
        ? endpoints.jobBoard.publicjobBoardDetails
        : endpoints.jobBoard.jobBoardDetails,
      config
    );
    if (res.data.details.status === '1') {
      return {
        response: res.data.details,
        errors: null,
      };
    } else {
      return {
        response: res.data.details,
        errors: res.data.errors,
      };
    }
  } catch (err: any) {
    return {
      response: null,
      errors: err,
    };
  }
};
export const getMatchDetails = async (
  payload: any,
  isPublic: boolean
): Promise<ApiReturn> => {
  const url = isPublic
    ? endpoints.jobBoard.publicGetJobMatchDetails
    : endpoints.jobBoard.getJobMatchDetails;
  try {
    const config: AxiosRequestConfig = {
      params: {
        ...payload,
      },
      withCredentials: true,
    };

    const res = await axios.get(url, config);
    if (res.data.details.status === '1') {
      return {
        response: res.data.details,
        errors: null,
      };
    } else {
      return {
        response: res.data.details,
        errors: res.data.errors,
      };
    }
  } catch (err: any) {
    return {
      response: null,
      errors: err,
    };
  }
};

// export const getJobDetailsForMatch = async (
//   HiringManagerSearchId: number,
//   isPublic: boolean
// ): Promise<any> => {
//   const url = isPublic
//     ? endpoints.jobBoard.publicGetJobMatchDetails
//     : endpoints.jobBoard.getJobMatchDetails;
//   return await get(url, { params: HiringManagerSearchId });
// };

export const getJobListData = async (payload: any): Promise<ApiReturn> => {
  try {
    const config: AxiosRequestConfig = {
      params: {
        ...payload,
      },
    };
    const res = await axios.get(jobListUrl, config);
    if (res.data.details.status === '1') {
      return {
        response: res.data.details,
        errors: null,
      };
    } else {
      return {
        response: res.data.details,
        errors: res.data.errors,
      };
    }
  } catch (err: any) {
    return {
      response: null,
      errors: err,
    };
  }
};

export const postJobListData = async (
  payload: JobListPayload
): Promise<ApiReturn> => {
  try {
    const res = await axios.post(jobListUrl, payload);
    if (res.data.details.status === '1') {
      return {
        response: res.data.details,
        errors: null,
      };
    } else {
      return {
        response: res.data.details,
        errors: res.data.errors,
      };
    }
  } catch (err: any) {
    return {
      response: null,
      errors: err,
    };
  }
};

export const postExpressInterest = async (
  payload: ExpressInterestPayload
): Promise<ApiReturn> => {
  try {
    const res = await axios.post(expressInterest, payload);

    if (res.data.status === '1') {
      return {
        response: res.data,
        errors: null,
      };
    } else {
      return {
        response: res.data,
        errors: res.data.errors,
      };
    }
  } catch (err: any) {
    return {
      response: null,
      errors: err,
    };
  }
};

export const applyForJob = async (payload: {
  ProjectId: number;
}): Promise<ApiReturn> => {
  try {
    const res = await axios.post(applyNow, payload);

    if (
      res &&
      res.data &&
      res.data.details &&
      res.data.details.status === '1'
    ) {
      return {
        response: res.data.details,
        errors: null,
      };
    } else if (res && res.data && res.data.status === '1') {
      return {
        response: res.data,
        errors: null,
      };
    } else {
      return {
        response: null,
        errors: null,
      };
    }
  } catch (err: any) {
    if (err && err.response && err.response.data && err.response.data.errors) {
      return {
        response: null,
        errors: err.response.data.errors,
      };
    } else {
      return {
        response: null,
        errors: null,
      };
    }
  }
};

export const getClientLogo = async (payload: any): Promise<ApiReturn> => {
  try {
    const config: AxiosRequestConfig = {
      params: {
        ...payload,
      },
      responseType: 'blob',
    };
    const res = await axios.get(clientLogo, config);

    if (res.status === 200) {
      return {
        response: res.data,
        errors: null,
      };
    } else {
      return {
        response: res.data,
        errors: res.data.request,
      };
    }
  } catch (err: any) {
    return {
      response: null,
      errors: err,
    };
  }
};

export const getApplicationStatus = async (
  payload: any
): Promise<ApiReturn> => {
  try {
    const config: AxiosRequestConfig = {
      params: {
        ...payload,
      },
      withCredentials: true,
    };

    const res = await axios.get(applicationStatus, config);
    if (res.data.details.status === '1') {
      return {
        response: res.data.details,
        errors: null,
      };
    } else {
      return {
        response: res.data.details,
        errors: res.data.errors,
      };
    }
  } catch (err: any) {
    return {
      response: null,
      errors: err,
    };
  }
};
