import { LabeledValue } from 'components/pages/findYourJoy/models';
import React, { useEffect, useState } from 'react';

type HorizontalBarChart = {
  data: LabeledValue[];
  max?: number;
  min?: number;
  rowClass?: string;
  barContainerClass?: string;
  labelClass?: string;
};

const ANIMATION_DELAY = 500;

export const HorizontalBarChart: React.FC<HorizontalBarChart> = ({
  data,
  min = 0,
  max = 10,
  rowClass = '',
  barContainerClass = '',
  labelClass = '',
}) => {
  const [isAnimating, setIsAnimating] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsAnimating(false);
    }, ANIMATION_DELAY);
  });
  return (
    <div className="HorizontalBarChart">
      {data.map((item) => (
        <div className={`Row ${rowClass}`}>
          <div className="TextContainer">
            <span className={`Label ${labelClass}`}>{item.label}</span>
          </div>
          <div className={`BarContainer ${barContainerClass}`}>
            <div
              className="Bar"
              style={
                isAnimating
                  ? { width: 0 }
                  : { width: `${(item.value / (max - min)) * 100}%` }
              }
            />
          </div>
        </div>
      ))}
    </div>
  );
};
