import { PublicModal } from 'components/publicModal/PublicModal';
import React, { useContext, useEffect, useState } from 'react';
import { FormType } from '../signIn/Landing';
import { SignInForm } from '../signIn/SignInForm';
import { SignUpForm } from '../signUp/SignUpForm';
import { AbilityPracticeTestQuizContext } from './AbilityPracticeTest';
import { AbilityPracticeTestApi } from './abilityPracticeTestApi';
import { AbilityPracticeTestContext } from './models';
import { Button } from 'components/button/Button';
import { Spinner } from 'components/spinner/Spinner';
import Pointer from 'assets/new_skin/pointer.svg';
import DOMPurify from 'dompurify';
import RightAnswer from 'assets/apply.clevry/Right_Answer.png';
import WrongAnswer from 'assets/apply.clevry/Wrong_Answer.png';
import { useDispatch } from 'react-redux';
import { setApplicationType } from 'reducers/virtualAssessments';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';

export const AbilityPracticeTestResults: React.FC = () => {
  const quizContext = useContext(
    AbilityPracticeTestQuizContext
  ) as AbilityPracticeTestContext;
  const [errorMessage, setErrorMessage] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [resultsData, setResultsData] = useState<any>(null);
  const [signUpPopUp, setSignUpPopUp] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { t } = useTranslation('Ability+Practice+Test+Results', { i18n });

  const [currentlyOpenForm, setCurrentlyOpenForm] = useState<FormType | null>(
    null
  );

  useEffect(() => {
    if (quizContext?.answers) {
      AbilityPracticeTestApi.fetchTestResults(
        1,
        quizContext.quiz.id,
        quizContext?.answers
      )
        .then((response) => {
          setLoading(false);
          if (response.data.status === '1') {
            setResultsData(response.data.details);
          }
        })
        .catch((error) => {
          setLoading(false);
          setErrorMessage(true);
        });
    }
  }, [quizContext]);

  useEffect(() => {
    setTimeout(function () {
      setSignUpPopUp(true);
    }, 5000);
  }, []);

  if (errorMessage) {
    return (
      <div className="MsgBlock">
        <div className="Inner">
          <h1>
            {t('SSCandidate_Ability_Practice_Test_Results_Application_Error')}
          </h1>
          <p>{t('SSCandidate_Ability_Practice_Test_Results_Generic_Error')}</p>
          <Button
            priority="primary"
            isPublic
            onClick={() => window.location.reload()}
          >
            {t('SSCandidate_Ability_Practice_Test_Results_Try_Again')}
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="AbilityPracticeTest HeightContent">
      <div className="AbilityPracticeTestResults WidthContent">
        {loading ? (
          <Spinner
            color={
              quizContext.isCustomer
                ? '#429CC'
                : quizContext.themes.defaultTheme.one
            }
          />
        ) : (
          <React.Fragment>
            {resultsData && (
              <React.Fragment>
                <div className="ResultBlocks">
                  <div className="LeftCol">
                    {resultsData.questionTypes.map(function (
                      results_question: any,
                      q_index: number
                    ) {
                      return (
                        <React.Fragment key={`Results_Question_${q_index}`}>
                          <h2>{results_question.header}</h2>

                          {results_question.answers.map(function (
                            results_answer: any,
                            a_index: number
                          ) {
                            return (
                              <React.Fragment
                                key={`Results_Answer_${q_index}_${a_index}`}
                              >
                                <div className="TestAnswerDetails">
                                  <p>
                                    {a_index + 1}: {results_answer.question}
                                  </p>

                                  <div className="PracticeTestAnswer">
                                    <div className="Answer">
                                      <p>
                                        {results_question.answerLabel}{' '}
                                        <strong>
                                          {results_answer.candidateAnswer}
                                        </strong>
                                      </p>
                                    </div>
                                    <div className="Marker">
                                      {results_answer.isCorrect && (
                                        <img
                                          src={RightAnswer}
                                          alt={`Answer_Icon_${q_index}_${a_index}`}
                                        />
                                      )}

                                      {!results_answer.isCorrect && (
                                        <img
                                          src={WrongAnswer}
                                          alt={`Answer_Icon_${q_index}_${a_index}`}
                                        />
                                      )}
                                    </div>
                                  </div>

                                  <div className="AnswerExplain">
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(
                                          results_answer.explanation!
                                        ),
                                      }}
                                    ></div>
                                  </div>
                                </div>
                              </React.Fragment>
                            );
                          })}
                        </React.Fragment>
                      );
                    })}
                  </div>
                  <div className="RightCol">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(resultsData.advice),
                      }}
                    ></div>
                  </div>
                </div>

                <div className="AbilityTestInfo">
                  <h1 className="ResultsTitle">
                    {t(
                      'SSCandidate_Ability_Practice_Test_Results_Review_Answers'
                    )}
                  </h1>

                  <h2>
                    {t(
                      'SSCandidate_Ability_Practice_Test_Results_Review_Want_To_Learn'
                    )}
                  </h2>

                  <div className="ButtonContainer">
                    <button
                      className="Btn CustomBtn SignUpButton BtnPrimary PublicBtn"
                      onClick={() => {
                        setSignUpPopUp(false);
                        setCurrentlyOpenForm(FormType.SIGN_UP);
                        dispatch(
                          setApplicationType('PRACTICE_TEST_APPLICATION')
                        );
                      }}
                    >
                      {t('SSCandidate_Ability_Practice_Test_Results_Sign_Up')}
                    </button>
                    <img
                      src={Pointer}
                      className="Pointer"
                      alt={t(
                        'SSCandidate_Ability_Practice_Test_Results_Pointer_Image'
                      )}
                    />
                  </div>
                </div>
              </React.Fragment>
            )}

            {currentlyOpenForm && (
              <PublicModal
                handleClose={() => {
                  setCurrentlyOpenForm(null);
                  dispatch(setApplicationType(''));
                }}
                className="FormModal"
              >
                {currentlyOpenForm === FormType.SIGN_IN ? (
                  <SignInForm
                    switchToSignUp={() =>
                      setCurrentlyOpenForm(FormType.SIGN_UP)
                    }
                  />
                ) : (
                  <SignUpForm
                    switchToSignIn={() =>
                      setCurrentlyOpenForm(FormType.SIGN_IN)
                    }
                    hasCompletedQuiz
                  />
                )}
              </PublicModal>
            )}

            {signUpPopUp && (
              <PublicModal
                handleClose={() => setSignUpPopUp(false)}
                className="FormModal"
              >
                <div className="AbilityTestInfo">
                  <h1 className="ResultsTitle">
                    {t(
                      'SSCandidate_Ability_Practice_Test_Results_Review_Answers'
                    )}
                  </h1>

                  <h2>
                    {t(
                      'SSCandidate_Ability_Practice_Test_Results_Review_Want_To_Learn'
                    )}
                  </h2>

                  <div className="ButtonContainer">
                    <button
                      className="Btn CustomBtn SignUpButton BtnPrimary PublicBtn"
                      onClick={() => {
                        setSignUpPopUp(false);
                        setCurrentlyOpenForm(FormType.SIGN_UP);
                        dispatch(
                          setApplicationType('PRACTICE_TEST_APPLICATION')
                        );
                      }}
                    >
                      {t('SSCandidate_Ability_Practice_Test_Results_Sign_Up')}
                    </button>
                    <img
                      src={Pointer}
                      className="Pointer"
                      alt={t(
                        'SSCandidate_Ability_Practice_Test_Results_Pointer_Image'
                      )}
                    />
                  </div>
                </div>
              </PublicModal>
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  );
};
