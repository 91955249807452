import React, { useState } from 'react';
import { InteractiveFeedbackCardType } from '../components/InteractiveFeedbackCard';
import { InteractiveFeedbackMenuCardHub } from '../components/InteractiveFeedbackMenuCardHub';
import SupportiveImage from 'assets/apply.clevry/IF_MyStrengths_FullProfile_Desc_01.jpg';
import AssertiveImage from 'assets/apply.clevry/IF_MyStrengths_FullProfile_Desc_02.jpg';
import TalkativeImage from 'assets/apply.clevry/IF_MyStrengths_FullProfile_Desc_03.jpg';
import ListeningImage from 'assets/apply.clevry/IF_MyStrengths_FullProfile_Desc_04.jpg';
import SolitaryImage from 'assets/apply.clevry/IF_MyStrengths_FullProfile_Desc_05.jpg';
import GregariousImage from 'assets/apply.clevry/IF_MyStrengths_FullProfile_Desc_06.jpg';
import AcceptingImage from 'assets/apply.clevry/IF_MyStrengths_FullProfile_Desc_07.jpg';
import InfluentialImage from 'assets/apply.clevry/IF_MyStrengths_FullProfile_Desc_08.jpg';
import ReticentImage from 'assets/apply.clevry/IF_MyStrengths_FullProfile_Desc_09.jpg';
import PoisedImage from 'assets/apply.clevry/IF_MyStrengths_FullProfile_Desc_10.jpg';
import ConsistentImage from 'assets/apply.clevry/IF_MyStrengths_FullProfile_Desc_11.jpg';
import AdaptableImage from 'assets/apply.clevry/IF_MyStrengths_FullProfile_Desc_12.jpg';
import { MyStrengthsProfileScaleMaps } from '../hooks/useGetMyStrengthsDetails';

export const InteractiveFeedbackFullProfile = ({
  backArrowClickHandler,
  scoreDetails,
}: {
  backArrowClickHandler: () => void;
  scoreDetails: any;
}) => {
  const strengthStyles = [
    'INTERPERSONAL',
    'THINKING',
    'EMOTIONAL',
    'MOTIVATION',
    'CULTURE',
    'RESPONSE',
  ];

  const [currentStrengthStyleNumber, setCurrentStrengthStyleNumber] =
    useState<number>(0);
  const [currentStrengthStyle, setCurrentStrengthStyle] = useState<string>(
    strengthStyles[0]
  );

  const previousMenuSectionHandler = () => {
    if (currentStrengthStyleNumber > 0) {
      const newPageNumber = currentStrengthStyleNumber - 1;
      setCurrentStrengthStyleNumber(newPageNumber);
      setCurrentStrengthStyle(strengthStyles[newPageNumber]);
    } else {
      const newPageNumber = strengthStyles.length - 1;
      setCurrentStrengthStyleNumber(newPageNumber);
      setCurrentStrengthStyle(strengthStyles[newPageNumber]);
    }
  };

  const nextMenuSectionHandler = () => {
    if (currentStrengthStyleNumber < strengthStyles.length - 1) {
      const newPageNumber = currentStrengthStyleNumber + 1;
      setCurrentStrengthStyleNumber(newPageNumber);
      setCurrentStrengthStyle(strengthStyles[newPageNumber]);
    } else {
      setCurrentStrengthStyleNumber(0);
      setCurrentStrengthStyle(strengthStyles[0]);
    }
  };

  /// Probably best to have this data parsing in useGetMyStengthsDetails - move there if there's time
  const flattenMappedScoreRowsCards = (details: any) => {
    if(details.length > 0){
      return details.reduce((a: any, b: any) => {
        return a.concat(b);
      });
    } else {
      return [];
    }
  };

  const parseScoreRowsCards = (details: any) => {
    const mappedDetails = details.map((item: any) => [
      {
        type: InteractiveFeedbackCardType.IMAGE,
        heading: item.scaleNameLow,
        bottomColor: item.lowColour,
        // MyStrengthsProfileScaleMaps[
        //   item.scaleNameLow as keyof typeof MyStrengthsProfileScaleMaps
        // ]?.bottomColor,
        topImage: item.lowImageURL,
        // MyStrengthsProfileScaleMaps[
        //   item.scaleNameLow as keyof typeof MyStrengthsProfileScaleMaps
        // ]?.topImage,
        cardText: item.scaleDescriptionLow,
      },
      {
        type: InteractiveFeedbackCardType.IMAGE,
        heading: item.scaleNameHigh,
        bottomColor: item.highColour,
        // MyStrengthsProfileScaleMaps[
        //   item.scaleNameHigh as keyof typeof MyStrengthsProfileScaleMaps
        // ]?.bottomColor,
        topImage: item.highImageURL,
        // MyStrengthsProfileScaleMaps[
        //   item.scaleNameHigh as keyof typeof MyStrengthsProfileScaleMaps
        // ]?.topImage,
        cardText: item.scaleDescriptionHigh,
      },
    ]);

    return flattenMappedScoreRowsCards(mappedDetails);
  };

  const mapScoreRows = (details: any) => {
    return details.map((item: any) => {
      return {
        leftText: item.scaleNameLow
          ? item.scaleNameLow
          : item.scaleDescriptionLow,
        onLeftButtonClick: () => {},
        rightText: item.scaleNameHigh
          ? item.scaleNameHigh
          : item.scaleDescriptionHigh,
        score: item.scaleScore,
        onRightButtonClick: () => {},
      };
    });
  };

  const parsedInterpersonalScoreCards = parseScoreRowsCards(
    scoreDetails.interpersonalScales
  );
  const mappedInterpersonalScoreRows = mapScoreRows(
    scoreDetails.interpersonalScales
  );

  const parsedThinkingScoreCards = parseScoreRowsCards(
    scoreDetails.thinkingScales
  );
  const mappedThinkingScoreRows = mapScoreRows(scoreDetails.thinkingScales);

  const parsedEmotionalScoreCards = parseScoreRowsCards(
    scoreDetails.emotionalScales
  );
  const mappedEmotionalScoreRows = mapScoreRows(scoreDetails.emotionalScales);

  const parsedMotivationScoreCards = parseScoreRowsCards(
    scoreDetails.motivationScales
  );
  const mappedMotivationScoreRows = mapScoreRows(scoreDetails.motivationScales);

  const parsedCultureScoreCards = parseScoreRowsCards(
    scoreDetails.cultureFitScales
  );
  const mappedCultureScoreRows = mapScoreRows(scoreDetails.cultureFitScales);

  const parsedResponseScoreCards = parseScoreRowsCards(
    scoreDetails.responseScales
  );
  const mappedResponseScoreRows = mapScoreRows(scoreDetails.responseScales);
  ///

  // console.log('scoreRowsMapped', scoreRowsMapped);

  // console.log('scoreRowsCardsMapped', scoreRowsCardsMapped);
  // console.log('flattenedScoreRowsCardsMapped', flattenedScoreRowsCardsMapped);

  return (
    <div className="InteractiveFeedbackFullProfile">
      {currentStrengthStyle === 'INTERPERSONAL' && (
        <InteractiveFeedbackMenuCardHub
          title="Interpersonal style"
          cardDetails={parsedInterpersonalScoreCards}
          backArrowClickHandler={backArrowClickHandler}
          scoreRowDetails={mappedInterpersonalScoreRows}
          previousMenuSectionHandler={previousMenuSectionHandler}
          nextMenuSectionHandler={nextMenuSectionHandler}
        />
      )}
      {currentStrengthStyle === 'THINKING' && (
        <InteractiveFeedbackMenuCardHub
          title="Thinking style"
          cardDetails={parsedThinkingScoreCards}
          backArrowClickHandler={backArrowClickHandler}
          scoreRowDetails={mappedThinkingScoreRows}
          previousMenuSectionHandler={previousMenuSectionHandler}
          nextMenuSectionHandler={nextMenuSectionHandler}
        />
      )}
      {currentStrengthStyle === 'EMOTIONAL' && (
        <InteractiveFeedbackMenuCardHub
          title="Emotional style"
          cardDetails={parsedEmotionalScoreCards}
          backArrowClickHandler={backArrowClickHandler}
          scoreRowDetails={mappedEmotionalScoreRows}
          previousMenuSectionHandler={previousMenuSectionHandler}
          nextMenuSectionHandler={nextMenuSectionHandler}
        />
      )}
      {currentStrengthStyle === 'MOTIVATION' && (
        <InteractiveFeedbackMenuCardHub
          title="Motivation"
          cardDetails={parsedMotivationScoreCards}
          backArrowClickHandler={backArrowClickHandler}
          scoreRowDetails={mappedMotivationScoreRows}
          previousMenuSectionHandler={previousMenuSectionHandler}
          nextMenuSectionHandler={nextMenuSectionHandler}
        />
      )}
      {currentStrengthStyle === 'CULTURE' && (
        <InteractiveFeedbackMenuCardHub
          title="Culture fit"
          cardDetails={parsedCultureScoreCards}
          backArrowClickHandler={backArrowClickHandler}
          scoreRowDetails={mappedCultureScoreRows}
          previousMenuSectionHandler={previousMenuSectionHandler}
          nextMenuSectionHandler={nextMenuSectionHandler}
        />
      )}
      {currentStrengthStyle === 'RESPONSE' && (
        <InteractiveFeedbackMenuCardHub
          title="Response style"
          cardDetails={[]}
          backArrowClickHandler={backArrowClickHandler}
          scoreRowDetails={mappedResponseScoreRows}
          previousMenuSectionHandler={previousMenuSectionHandler}
          nextMenuSectionHandler={nextMenuSectionHandler}
        />
      )}
    </div>
  );
};
