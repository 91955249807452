import { useOnClickOutside } from '@avamae/hooks/dist/hooks/useOnClickOutside';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from 'reducers/modal';
import Icon_Close from 'assets/job-board-widget/Icon_Close.png';
import { selectAccessibilityData } from 'reducers/accessibility';

type ModalProps = {
  open?: boolean;
  title?: string;
  type?: string;
  closeModal?: () => void;
  actionButton?: React.ReactNode;
  modalHeaderClasses?: string;
  modalClasses?: string;
  width?: string;
  height?: string;
  isNotCloseable?: boolean;
  hideHeader?: boolean;
};

const Modal: React.FC<ModalProps> = ({
  children,
  title,
  type,
  modalHeaderClasses,
  modalClasses,
  width,
  height,
  isNotCloseable,
  hideHeader = false,
}) => {
  const dispatch = useDispatch();
  const accessibilityData = useSelector(selectAccessibilityData);

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const bind = useOnClickOutside(
    isNotCloseable ? () => {} : handleCloseModal,
    true
  );

  return (
    <div className="ModalBackground">
      <div
        className={`Modal ${modalClasses ? modalClasses : ''} ${
          type ? type : ''
        }
        ${accessibilityData.classStr} ${width ? width : ''} ${
          height ? height : ''
        }`}
        style={
          {
            '--backgroundColor': accessibilityData.backgroundColor,
            '--fontColor': accessibilityData.textColor,
            '--titleColor': accessibilityData.titleColor,
            borderColor: accessibilityData.textColor,
          } as React.CSSProperties
        }
        {...bind}
      >
        {!hideHeader && (
          <div
            className={`ModalHeader ${
              modalHeaderClasses ? modalHeaderClasses : ''
            }`}
          >
            <p className="ModalHeading">{title}</p>
            <div className="ExitImg">
              <img
                src={Icon_Close}
                alt="CloseIcon"
                onClick={() => {
                  dispatch(closeModal());
                }}
              />
            </div>
          </div>
        )}

        {children}
      </div>
    </div>
  );
};

export { Modal };
