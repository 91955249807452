import * as Yup from 'yup';
import { FieldArray, Form, Formik } from 'formik';
import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  parseExperiencePayload,
  submitExperiencePayload,
  getCriticalHardSkillsOptions,
  parseGetCriticalHardSkillsOptions,
  getCriticalHardSkillsDetails,
  parseGetCriticalHardSkillsDetails,
  parseCriticalHardSkillsPayload,
  submitCriticalHardSkillsPayload,
} from '../../MatcingProcessApi';
import { Spinner } from 'components/spinner/Spinner';
import { FormSingleSelectCustomField } from '../CustomFormFields/FormSingleSelectCustomField';
import CriticalHardSkillsPageRow from './CriticalHardSkillsPageRow';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';
import { useDispatch, useSelector } from 'react-redux';
import {
  MatchingProcessState,
  selectMatchingProcess,
  setMatchingProcessDataSubmittedToggle,
  setMatchingProcessSubmitSuccess,
} from 'reducers/matchingProcess';
import { GAMatchingProcessLatest } from 'helpers/gaEvents';

type CriticalHardSkillsPageProps = {
  outerSubmitForm: MutableRefObject<
    (() => Promise<void>) & (() => Promise<any>)
  >;
  setNextStepDisabled: Dispatch<SetStateAction<boolean>>;
  setGeneralError: Dispatch<SetStateAction<string | undefined>>;
};

const blankRow = {
  label: '',
  level: null,
  skillId: null,
  candidateHardSkillsId: null,
};

export type CriticalHardSkillsPageFormikValues = {
  hardSkills: (typeof blankRow)[];
};

export type CriticalHardSkillsMenuOptions = {
  skills: { label: string; value: number }[];
  level: { optionText: string; optionId: number }[];
};

const CriticalHardSkillsPage: React.FC<CriticalHardSkillsPageProps> = ({
  outerSubmitForm,
  setNextStepDisabled,
  setGeneralError,
}) => {
  const latestLocation = useRef('');

  if (
    window &&
    window.location &&
    window.location.href !== latestLocation.current
  ) {
    latestLocation.current = window.location.href;
    GAMatchingProcessLatest(window.location.href);
  }

  const { t } = useTranslation('Matching+Process', { i18n });

  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState({
    hardSkills: [],
  });
  const [menuOptions, setMenuOptions] = useState<CriticalHardSkillsMenuOptions>(
    { skills: [], level: [] }
  );

  const validationSchema = Yup.object().shape({
    hardSkills: Yup.array()
      .of(
        Yup.object({
          level: Yup.string().required(''),
          skillId: Yup.number().required(''),
        })
      )
      .min(1)
      .required(),
  });

  const dispatch = useDispatch();

  const matchingProcessDetails: MatchingProcessState = useSelector(
    selectMatchingProcess
  );

  const handleSubmit = (values: CriticalHardSkillsPageFormikValues) => {
    const payload = parseCriticalHardSkillsPayload(values);
    return (async () => {
      const result = await submitCriticalHardSkillsPayload(payload);
      if (result && result.response) {
        setGeneralError('');

        dispatch(setMatchingProcessSubmitSuccess(true));

        dispatch(
          setMatchingProcessDataSubmittedToggle(
            !matchingProcessDetails.dataSubmittedToggle
          )
        );

        return true;
      } else {
        dispatch(setMatchingProcessSubmitSuccess(false));

        setGeneralError('Something went wrong, please try again.');
        return false;
      }
    })();
  };

  const fetchDetails = async () => {
    const result = await getCriticalHardSkillsDetails();
    if (result.response) {
      setInitialValues(parseGetCriticalHardSkillsDetails(result.response));
      setGeneralError('');
      setLoading(false);
    } else if (result.errors) {
      setLoading(false);
      setGeneralError('Something went wrong, please reload the page.');
    } else {
      setLoading(false);
    }
  };

  const fetchOptions = async () => {
    const result: any = await getCriticalHardSkillsOptions();
    if (result && result.response) {
      setMenuOptions(parseGetCriticalHardSkillsOptions(result.response));
    }
  };

  useEffect(() => {
    fetchDetails();
    fetchOptions();
  }, []);

  if (loading) return <Spinner />;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
      validateOnMount
    >
      {({
        setFieldValue,
        values,
        errors,
        touched,
        setValues,
        submitForm,
        isValid,
      }) => {
        let filteredSkillsOptions = menuOptions.skills;
        let chosenIds: number[] = [];
        if (values && Array.isArray(values.hardSkills)) {
          chosenIds = values.hardSkills.map(
            ({ skillId }: { skillId: number }) => skillId
          );
          filteredSkillsOptions = menuOptions.skills.filter(
            ({ value }) => !chosenIds.includes(value)
          );
        }

        outerSubmitForm.current = submitForm;
        if (isValid) {
          setNextStepDisabled(false);
        } else {
          setNextStepDisabled(true);
        }

        return (
          <div className="CriticalHardSkillsPage MatchingProcessInnerPage">
            <div className="MatchingProcess__sectionHeader">
              <div className="MatchingProcess__sectionHeading">
                {t('SSCandidate_Matching_Process_Critical_Hard_Skills_Heading')}
              </div>
              <div className="MatchingProcess__sectionSubheading">
                <span>
                  {t(
                    'SSCandidate_Matching_Process_Critical_Hard_Skills_Subheading_1'
                  )}
                </span>
                <span>
                  {t(
                    'SSCandidate_Matching_Process_Critical_Hard_Skills_Subheading_2'
                  )}
                </span>
              </div>
            </div>
            <Form>
              <FieldArray
                name="hardSkills"
                render={({ push, remove }) => {
                  return (
                    <>
                      <div className="CriticalHardSkillsSearchBar">
                        <FormSingleSelectCustomField
                          fieldName={`hardSkills`}
                          label={''}
                          placeholder="Type your search here"
                          options={filteredSkillsOptions}
                          optionalChangeHandler={(fieldName, label, value) => {
                            push({ label, skillId: value, level: null });
                          }}
                          showErrors={false}
                        />
                      </div>

                      <div className="JobApplicationForm">
                        {values.hardSkills &&
                          Array.isArray(values.hardSkills) &&
                          values.hardSkills.map((item, idx) => (
                            <CriticalHardSkillsPageRow
                              key={`hardSkils${idx}`}
                              remove={remove}
                              rowNumber={idx}
                              levels={menuOptions.level}
                              fieldValueName={'hardSkills'}
                              valueLabelFieldName={'label'}
                              valueSkillIdFieldName={'skillId'}
                              valueLevelIdFieldName={'level'}
                            />
                          ))}
                      </div>
                    </>
                  );
                }}
              />
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

export default CriticalHardSkillsPage;
