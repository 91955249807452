import { FilterBuilder } from 'components/filters/FilterBuilder';
import { useDispatch } from 'react-redux';
import React, { useMemo } from 'react';
import { closeRightPanel } from 'reducers/rightPanel';
import { FilterPayload, setFilter } from 'reducers/setFilter';
import { serializeFilters } from '@avamae/parse-filter';

const JobBoardFilters: React.FC<any> = ({
  data: { table, search, filterOperators, sideHiddenFields, locationFilter },
}) => {
  const dispatch = useDispatch();

  const removeUnwantedFilters = (table: any) => {
    if (table.data) {
      return {
        ...table,
        data: {
          ...table.data,
          details: {
            ...table.data?.details,
            columns: table.data?.details.columns.filter(
              (entry: any) => entry.columnKey !== 'Project__ProjectLogoURL'
            ),
          },
        },
      };
    }
    return table;
  };

  const buildFilters = (filtersToSerialize: FilterPayload) => {
    const serializedFilters = serializeFilters(
      Object.entries(filtersToSerialize).map(([_, value]) => ({
        columnKey: value.columnKey,
        filters: [value],
      }))
    );
    return serializedFilters;
  };

  const modifiedTable = useMemo(() => removeUnwantedFilters(table), [table]);

  return (
    <div className="JobBoardMain">
      <div className="JobBoardFilters">
        <div className="FiltersContainer">
          <FilterBuilder
            table={modifiedTable}
            hiddenFields={sideHiddenFields}
            defaultOperators={filterOperators}
            onDone={(payload: any) => {
              const newFilters = buildFilters(payload);
              table.data?.actions.setQueryParams({
                search: search,
                filters: newFilters,
              });
              dispatch(setFilter(payload));
              dispatch(closeRightPanel());
            }}
            additionalData={{ locationFilter, search }}
            noCancel
          />
        </div>
      </div>
    </div>
  );
};

export { JobBoardFilters };
