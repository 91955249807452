import { SignalRNotificationMessage } from "components/pages/stackNotifications/models";
import { produce } from "immer";
import { Store } from "reducers/rootReducer";

/* Constants */
const SET_LATEST_NOTIFICATION = "app/notifications/set_latest_notification";
const SET_NOTIFICATIONS_COUNT = "app/notifications/set_notifications_count";

type NotificationsState = {
  latestNotification: SignalRNotificationMessage | null;
  notificationsCount: number | null;
};

type SetLatestNotificationAction = {
  type: typeof SET_LATEST_NOTIFICATION;
  payload: SignalRNotificationMessage | null;
};

type SetNotificationsCountAction = {
  type: typeof SET_NOTIFICATIONS_COUNT;
  payload: number | null;
};

/* Reducer */
const initialRegisterState: NotificationsState = {
  latestNotification: null,
  notificationsCount: null,
};

export const notificationsReducer = (
  state: NotificationsState = initialRegisterState,
  action: SetLatestNotificationAction | SetNotificationsCountAction
): NotificationsState =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_LATEST_NOTIFICATION:
        draft.latestNotification = action.payload;
        break;
      case SET_NOTIFICATIONS_COUNT:
        draft.notificationsCount = action.payload;
        break;
    }
  });

/* Action Creators */
export const setLatestNotification = (
  payload: SignalRNotificationMessage | null
): SetLatestNotificationAction => ({
  type: SET_LATEST_NOTIFICATION,
  payload,
});

export const setNotificationsCount = (
  payload: number | null
): SetNotificationsCountAction => ({
  type: SET_NOTIFICATIONS_COUNT,
  payload,
});

/* Selectors */
export const selectLatestNotification = (state: Store) =>
  state.notifications.latestNotification;

export const selectNotificationsCount = (state: Store) =>
  state.notifications.notificationsCount;
