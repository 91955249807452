import { toast, ToastOptions } from 'react-toastify';

export enum ToastMessageReason {
  ERROR = 1,
  VALID,
  PENDING,
}

(window as any).toast = toast;

export class Toast {
  public static openToastSuccessMessage(
    message: string,
    options?: ToastOptions
  ) {
    toast.success(message, options);
  }
  public static openToastPendingMessage(
    message: string,
    options?: ToastOptions
  ) {
    toast.info(message, options);
  }
  public static openToastErrorMessage(message: string, options?: ToastOptions) {
    toast.error(message, options);
  }

  public static openGenericErrorToast(options?: ToastOptions) {
    toast.error('There was an error. Please try again later.', options);
  }
}
