import React, { Suspense } from 'react';
import Private from 'components/Private';
import ErrorBoundary from 'components/ErrorBoundary';
import LoadingFallback from 'components/fallbacks/LoadingFallback';
import ErrorFallback from 'components/fallbacks/ErrorFallback';
import { useDispatch } from 'react-redux';
import { changeNavType } from 'reducers/navbar';
import { changeFooterType, FooterType } from 'reducers/footer';
import { NavBar } from 'components/pageComponents/navBar/NavBar';
import { Footer } from 'components/pageComponents/footer/Footer';

const Page: React.FC<{
  isPublic?: boolean;
  navType: string;
  footerType: FooterType;
  navData: { title: string; activityType?: string } | null;
  themed?: boolean;
}> = ({ children, navType, navData, footerType, isPublic, themed = true }) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(changeNavType({ navType, data: navData }));
    dispatch(changeFooterType({ footerType, data: null }));
  }, []);

  if (isPublic)
    return (
      <ErrorBoundary fallback={<ErrorFallback />}>
        <Suspense fallback={<LoadingFallback />}>
          <>
            <NavBar isThemed={themed} />
            <div className={`${themed ? 'Themed' : ''}`}>{children}</div>
            <Footer isThemed={themed} />
          </>
        </Suspense>
      </ErrorBoundary>
    );

  return (
    <Private>
      <ErrorBoundary fallback={<ErrorFallback />}>
        <>
          <NavBar isThemed={false} />
          <Suspense fallback={<LoadingFallback />}>{children}</Suspense>
          <Footer isThemed={false} />
        </>
      </ErrorBoundary>
    </Private>
  );
};

export default Page;
