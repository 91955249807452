import React from 'react';
import { Button } from 'components/button/Button';
import Icon_Pagination_Left from 'assets/apply.clevry/Icon_Pagination_02.png';
import Icon_Pagination_Right from 'assets/apply.clevry/Icon_Pagination_03.png';

type InteractiveFeedbackNavigationButtonsProps = {
  leftNavigationButtonText: string;
  rightNavigationButtonText: string;
  incrementPage: () => void;
  decrementPage: () => void;
};

export const InteractiveFeedbackNavigationButtons = ({
  leftNavigationButtonText,
  rightNavigationButtonText,
  incrementPage,
  decrementPage,
}: InteractiveFeedbackNavigationButtonsProps) => {
  return (
    <>
      <div
        className="InteractiveFeedbackNavigationButton Left"
        onClick={() => {
          setTimeout(() => {
            decrementPage();
          }, 100);
        }}
      >
        <div className="FeedbackNavigationIconContainer">
          <img src={Icon_Pagination_Left} alt="" />
        </div>
        <div className="FeedbackNavigationText">{leftNavigationButtonText}</div>
      </div>

      <div
        className="InteractiveFeedbackNavigationButton Right"
        onClick={() => {
          setTimeout(() => {
            incrementPage();
          }, 100);
        }}
      >
        <div className="FeedbackNavigationText">
          {rightNavigationButtonText}
        </div>

        <div className="FeedbackNavigationIconContainer">
          <img src={Icon_Pagination_Right} alt="" />
        </div>
      </div>
    </>
  );
};
