import { Store } from './rootReducer';
import produce from 'immer';

export type Nullable<T> = T | null;

//Types
export enum TYPES {
  'SET_ACCESSIBILITY' = 'SET_ACCESSIBILITY',
}

// State
export type AccessibilityState = {
  titleColor: string;
  textColor: string;
  backgroundColor: string;
  borderColor: string;
  errorTextColor: string;
  optionNumber: number;
  classStr?: string;
  isSet: boolean;
};

export const initialState: AccessibilityState = {
  titleColor: '#036C8F',
  textColor: '#000000',
  backgroundColor: '#ffffff',
  borderColor: "#D5D5D5",
  errorTextColor: '#d83838',
  optionNumber: 0,
  classStr: "",
  isSet: false,
};

// Actions
export type Action = {
  type: TYPES.SET_ACCESSIBILITY;
  payload: AccessibilityPayload;
};

// Reducer
export default function modalReducer(
  state = initialState,
  action: Action
): AccessibilityState {
  return produce(state, (draft) => {
    switch (action.type) {
      case TYPES.SET_ACCESSIBILITY:
        draft.textColor = action.payload.textColor;
        draft.titleColor = action.payload.titleColor;
        draft.borderColor = action.payload.borderColor;  
        draft.errorTextColor = action.payload.errorTextColor;
        draft.backgroundColor = action.payload.backgroundColor;
        draft.optionNumber = action.payload.optionNumber;
        draft.classStr = action.payload.classStr;
        draft.isSet = action.payload.isSet;
        break;
      default:
        break;
    }
  });
}

export type AccessibilityPayload = {
  textColor: string;
  titleColor: string;
  borderColor: string;
  errorTextColor: string;
  backgroundColor: string;
  optionNumber: number;
  classStr?: string;
  isSet: boolean;
};

// Action creators
export const setAccessibilityData = (payload: AccessibilityPayload) => ({
  type: TYPES.SET_ACCESSIBILITY,
  payload,
});

// Selectors
export const selectAccessibilityData = (state: Store) => state.accessibility;
