import { useOnClickOutside } from '@avamae/hooks';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Transition } from 'react-transition-group';
import Cross from '../../../assets/apply.clevry/Icon_Close.png';
import { closeRightPanel } from 'reducers/rightPanel';

type RightPanelProps = {
  panelOpen: boolean;
  wide?: boolean;
  extraWide?: boolean;
  header?: string;
};

const RightPanel: React.FC<any> = ({
  children,
  panelOpen,
  width,
  header = '',
}) => {
  const dispatch = useDispatch();
  const handleClosePanel = () => {
    dispatch(closeRightPanel());
  };

  //   transitionState
  const bind = useOnClickOutside(handleClosePanel, true);
  return (
    <Transition in={panelOpen} timeout={200} mountOnEnter unmountOnExit>
      {(transitionState) => (
        <div
          className={`PanelBackground ${transitionState}`}
          onClick={closeRightPanel}
        >
          <div
            className={`RightPanel ${transitionState} ${width ? width : ''} `}
            {...bind}
          >
            {header && (
              <div className="RightPanelHeader">
                <h2>{header}</h2>
                <div className="Cross">
                  <img src={Cross} alt="Cross" onClick={handleClosePanel} />
                </div>
              </div>
            )}
            {children}
          </div>
        </div>
      )}
    </Transition>
  );
};

export { RightPanel };
