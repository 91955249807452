import { InteractiveFeedbackDownloadReportModalData } from 'components/pages/interactiveFeedback/components/InteractiveFeedbackDownloadReportModal';
import { Store } from './rootReducer';

export type Nullable<T> = T | null;

//Types
export enum TYPES {
  'DELETE' = 'DELETE',
  'QUESTION' = 'QUESTION',
  'DASHBOARD' = 'DASHBOARD',
  'SETTINGS' = 'SETTINGS',
  'ACCOUNT_CHANGE_PASSWORD' = 'ACCOUNT_CHANGE_PASSWORD',
  'ICON_GUIDE' = 'ICON_GUIDE',
  'WITHDRAW_APPLICATION' = 'WITHDRAW_APPLICATION',
  'ACCOUNT_DELETE' = 'ACCOUNT_DELETE',
  'CONTACT_EMPLOYER' = 'CONTACT_EMPLOYER',
  'EXPRESS_INTEREST' = 'EXPRESS_INTEREST',
  'OPEN_MODAL' = 'OPEN_MODAL',
  'CLOSE_MODAL' = 'CLOSE_MODAL',
  'MATCHING_EDIT_DETAILS' = 'MATCHING_EDIT_DETAILS',
  'UNDO_WITHDRAW_APPLICATION' = 'UNDO_WITHDRAW_APPLICATION',
  'DOWNLOAD_REPORT' = 'DOWNLOAD_REPORT',
  'ACCESSIBILITY' = 'ACCESSIBILITY',
  'VIRTUAL_ASSESSMENTS_BEFORE_YOU_BEGIN' = 'VIRTUAL_ASSESSMENTS_BEFORE_YOU_BEGIN',
  'VIRTUAL_ASSESSMENTS_TIMEOUT' = 'VIRTUAL_ASSESSMENTS_TIMEOUT',
  'VIRTUAL_ASSESSMENTS_ADJUSTMENT' = 'VIRTUAL_ASSESSMENTS_ADJUSTMENT',
  'ABILITY_TESTS_ACCESSIBILITY' = 'ABILITY_TESTS_ACCESSIBILITY',
  'ABILITY_TESTS_INSTRUCTIONS_MODAL_TEXT' = 'ABILITY_TESTS_INSTRUCTIONS_MODAL_TEXT',
  'ABILITY_TESTS_PRACTICE_MODAL_TEXT' = 'ABILITY_TESTS_PRACTICE_MODAL_TEXT',
  'ABILITY_TESTS_TEST_MODAL_TEXT' = 'ABILITY_TESTS_TEST_MODAL_TEXT',
  'ABILITY_TESTS_BEFORE_YOU_BEGIN' = 'ABILITY_TESTS_BEFORE_YOU_BEGIN',
  'ABILITY_TESTS_TIMEOUT' = 'ABILITY_TESTS_TIMEOUT',
  'ABILITY_TESTS_ADJUSTMENT' = 'ABILITY_TESTS_ADJUSTMENT',
  'ABILITY_TESTS_RESET' = 'ABILITY_TESTS_RESET',
  'SJT_TESTS_ACCESSIBILITY' = 'SJT_TESTS_ACCESSIBILITY',
  'SJT_TESTS_INSTRUCTIONS_MODAL_TEXT' = 'SJT_TESTS_INSTRUCTIONS_MODAL_TEXT',
  'SJT_TESTS_TEST_MODAL_TEXT' = 'SJT_TESTS_TEST_MODAL_TEXT',
  'SJT_TESTS_BEFORE_YOU_BEGIN' = 'SJT_TESTS_BEFORE_YOU_BEGIN',
  'SJT_TEST_TIMEOUT' = 'SJT_TEST_TIMEOUT',
  'SJT_TESTS_ADJUSTMENT' = 'SJT_TESTS_ADJUSTMENT',
  'PROJECT_INTRO_VIDEO' = 'PROJECT_INTRO_VIDEO',
  'ASSESSMENT_BEFORE_YOU_BEGIN' = 'ASSESSMENT_BEFORE_YOU_BEGIN',
  'ASSESSMENT_ACCESSIBILITY' = 'ASSESSMENT_ACCESSIBILITY',
  'ASSESSMENT_SCENARIO' = 'ASSESSMENT_SCENARIO',
  'ASSESSMENT_LOADING_ANTI_CHEATING' = 'ASSESSMENT_LOADING_ANTI_CHEATING',
}

export type QuestionItem = {
  image: any;
  text: string;
  buttonText: string;
};

export type QuestionModalData = {
  title: string;
  questions: QuestionItem[];
};

//temporary
export type DeleteModalData = any;

export type DashboardModalData = {
  video: any;
  buttonText: string;
};

export type ButtonTextModalData = {
  buttonText: string;
};

export type IconGuideModalData = {
  title: string;
  modalClasses: string;
  modalHeaderClasses: string;
};

export type WithdrawApplicationModalData = {
  title: string;
  modalClasses: string;
  modalHeaderClasses: string;
  projectId: number;
};

export type MatchingEditDetailsData = {
  title: string;
  modalClasses: string;
  modalHeaderClasses: string;
  panelType: string;
};
export type ContactEmployerModalData = {
  title: string;
  modalClasses: string;
  modalHeaderClasses: string;
  assessmentID: number;
};

export type ExpressInterestModalData = {
  title: string;
  modalClasses: string;
  modalHeaderClasses: string;
  searchID: number;
  isDeclining?: boolean;
};

export type AbilityTestModalData = {
  title: string;
  info: string;
};

export type BeforeYouBeginModalData = {
  title: string;
  info: string;
  prongID: number;
  prongCode: string;
  understoodInstructions?: boolean;
};

export type ReasonableAdjustmentModalData = {
  title: string;
  modalHeaderClasses: string;
  prongID: number;
  assessmentId: number;
  projectId: number;
};

export type TitleOnly = {
  title: string;
};

export type UndoWithdrawApplicationModalData = {
  title: string;
  projectId: number;
  assessmentId: number;
};

export type ProjectIntroVideoModalData = {
  title: string;
  jobApplicationId: number;
  projectIntroVideoURL: string;
  campaignWelcomeText: string;
};

export type AssessmentScenarioData = {
  title: string;
  scenarioText: string;
  scenarioImage?: string | null;
  prongCode: string;
  testType: string;
  hasSeenAllScenarios: boolean;
};

export type AssessmentLoadingAntiCheatingModalData = {
  timeoutFunction: () => void;
  overrideCloseModal?: boolean;
};

// State
export type ModalState =
  | {
      modalType: null;
      data: null;
    }
  | {
      modalType: TYPES.DELETE;
      data: DeleteModalData;
    }
  | {
      modalType: TYPES.QUESTION;
      data: QuestionModalData;
    }
  | {
      modalType: TYPES.DASHBOARD;
      data: DashboardModalData;
    }
  | {
      modalType: TYPES.SETTINGS;
      data: ButtonTextModalData;
    }
  | {
      modalType: TYPES.ACCOUNT_CHANGE_PASSWORD;
      data: ButtonTextModalData;
    }
  | {
      modalType: TYPES.ICON_GUIDE;
      data: IconGuideModalData;
    }
  | {
      modalType: TYPES.WITHDRAW_APPLICATION;
      data: WithdrawApplicationModalData;
    }
  | {
      modalType: TYPES.CONTACT_EMPLOYER;
      data: ContactEmployerModalData;
    }
  | {
      modalType: TYPES.EXPRESS_INTEREST;
      data: ExpressInterestModalData;
    }
  | {
      modalType: TYPES.ACCOUNT_DELETE;
      data: ButtonTextModalData;
    }
  | {
      modalType: TYPES.MATCHING_EDIT_DETAILS;
      data: MatchingEditDetailsData;
    }
  | {
      modalType: TYPES.UNDO_WITHDRAW_APPLICATION;
      data: UndoWithdrawApplicationModalData;
    }
  | {
      modalType: TYPES.DOWNLOAD_REPORT;
      data: InteractiveFeedbackDownloadReportModalData;
    }
  | {
      modalType: TYPES.ACCESSIBILITY;
      data: TitleOnly;
    }
  | {
      modalType: TYPES.VIRTUAL_ASSESSMENTS_BEFORE_YOU_BEGIN;
      data: TitleOnly;
    }
  | {
      modalType: TYPES.VIRTUAL_ASSESSMENTS_TIMEOUT;
      data: TitleOnly;
    }
  | {
      modalType: TYPES.VIRTUAL_ASSESSMENTS_ADJUSTMENT;
      data: TitleOnly;
    }
  | {
      modalType: TYPES.ABILITY_TESTS_ACCESSIBILITY;
      data: TitleOnly;
    }
  | {
      modalType: TYPES.ABILITY_TESTS_TIMEOUT;
      data: TitleOnly;
    }
  | {
      modalType: TYPES.ABILITY_TESTS_ADJUSTMENT;
      data: ReasonableAdjustmentModalData;
    }
  | {
      modalType: TYPES.ABILITY_TESTS_INSTRUCTIONS_MODAL_TEXT;
      data: AbilityTestModalData;
    }
  | {
      modalType: TYPES.ABILITY_TESTS_PRACTICE_MODAL_TEXT;
      data: AbilityTestModalData;
    }
  | {
      modalType: TYPES.ABILITY_TESTS_TEST_MODAL_TEXT;
      data: AbilityTestModalData;
    }
  | {
      modalType: TYPES.ABILITY_TESTS_BEFORE_YOU_BEGIN;
      data: BeforeYouBeginModalData;
    }
  | {
      modalType: TYPES.ABILITY_TESTS_RESET;
      data: ReasonableAdjustmentModalData;
    }
  | {
      modalType: TYPES.SJT_TESTS_ACCESSIBILITY;
      data: TitleOnly;
    }
  | {
      modalType: TYPES.SJT_TEST_TIMEOUT;
      data: TitleOnly;
    }
  | {
      modalType: TYPES.SJT_TESTS_INSTRUCTIONS_MODAL_TEXT;
      data: AbilityTestModalData;
    }
  | {
      modalType: TYPES.SJT_TESTS_TEST_MODAL_TEXT;
      data: AbilityTestModalData;
    }
  | {
      modalType: TYPES.SJT_TESTS_BEFORE_YOU_BEGIN;
      data: BeforeYouBeginModalData;
    }
  | {
      modalType: TYPES.SJT_TESTS_ADJUSTMENT;
      data: ReasonableAdjustmentModalData;
    }
  | {
      modalType: TYPES.PROJECT_INTRO_VIDEO;
      data: ProjectIntroVideoModalData;
    }
  | {
      modalType: TYPES.ASSESSMENT_BEFORE_YOU_BEGIN;
      data: BeforeYouBeginModalData;
    }
  | {
      modalType: TYPES.ASSESSMENT_ACCESSIBILITY;
      data: TitleOnly;
    }
  | {
      modalType: TYPES.ASSESSMENT_SCENARIO;
      data: AssessmentScenarioData;
    }
  | {
      modalType: TYPES.ASSESSMENT_LOADING_ANTI_CHEATING;
      data: AssessmentLoadingAntiCheatingModalData;
    };

export const initialState: ModalState = {
  modalType: null,
  data: null,
};

// Actions
export type Action =
  | {
      type: TYPES.OPEN_MODAL;
      payload: OpenModal;
    }
  | {
      type: TYPES.CLOSE_MODAL;
      payload: null;
    };

// Reducer
export default function modalReducer(
  state = initialState,
  action: Action
): ModalState {
  switch (action.type) {
    case TYPES.OPEN_MODAL:
      return {
        ...state,
        modalType: action.payload.modalType,
        data: action.payload.data,
      };
    case TYPES.CLOSE_MODAL:
      return {
        ...state,
        modalType: null,
        data: null,
      };
    default:
      return state;
  }
}

export type OpenModal = {
  modalType: any | null;
  data: any | null;
};

// Action creators
export const openModal = (payload: OpenModal) => ({
  type: TYPES.OPEN_MODAL,
  payload,
});

export const closeModal = () => ({ type: TYPES.CLOSE_MODAL });

// Selectors
export const selectModalState = (state: Store) => state.modal;
