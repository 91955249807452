import { Link } from '@reach/router';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  FooterType,
  NavigationFooterData,
  selectFooter,
  UpdateProfileFooterData,
} from 'reducers/footer';
import LinkedinIcon from '../../../assets/apply.clevry/Icon_Social_01.png';
import TwitterIcon from '../../../assets/apply.clevry/Icon_Social_02.png';
import FacebookIcon from '../../../assets/apply.clevry/Icon_Social_04.png';
import InstagramIcon from '../../../assets/apply.clevry/Icon_Social_05.png';
import { NavigationFooter } from './NavigationFooter';
import { UpdateProfileFooter } from './UpdateProfileFooter';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';

export const FooterSocials: React.FC = () => {
  const { t } = useTranslation('Footer', { i18n });

  return (
    <div className="Socials">
      <a
        href="https://www.linkedin.com/company/clevry/"
        className="Social"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={LinkedinIcon} alt={t('SSCandidate_Footer_Alt_Social_Icon')} />
      </a>
      <a
        href="https://twitter.com/ClevryGroup"
        className="Social"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={TwitterIcon} alt={t('SSCandidate_Footer_Alt_Social_Icon')} />
      </a>
      <a
        href="https://www.instagram.com/clevry.group/"
        className="Social"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={InstagramIcon}
          alt={t('SSCandidate_Footer_Alt_Social_Icon')}
        />
      </a>
      <a
        href="https://www.facebook.com/clevryCOM"
        className="Social"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={FacebookIcon} alt={t('SSCandidate_Footer_Alt_Social_Icon')} />
      </a>
    </div>
  );
};

export const FooterCopyright: React.FC = () => {
  const { t } = useTranslation('Footer', { i18n });

  return (
    <div className="FooterCopyright">
      <p className="Copyright">
        <span>
          {t('SSCandidate_Footer_Copyright', {
            val1: new Date().getFullYear(),
          })}

          {/* OLD: */}
          {/* {t('SSCandidate_Footer_Copyright')} &#169;{new Date().getFullYear()}{' '}
          {t('SSCandidate_Footer_Clevry_Rights')} */}
        </span>
      </p>
    </div>
  );
};

export const FooterCopyrightRight: React.FC = () => (
  <div className="FooterCopyrightRight">
    <p className="Copyright">
      <span>
        Copyright &#169;{new Date().getFullYear()} Clevry. All rights reserved.
      </span>
    </p>
  </div>
);

export const FooterLegalLinks: React.FC = () => {
  const { t } = useTranslation('Footer', { i18n });

  return (
    <>
      <a
        className="FooterLink"
        href="/terms-and-conditions"
        target="_blank"
        rel="noopener noreferrer"
      >
        {t('SSCandidate_Footer_Terms_And_Conditions')}
      </a>

      <a
        className="FooterLink"
        href="https://www.clevry.com/en/about/privacy"
        target="_blank"
        rel="noopener noreferrer"
      >
        {t('SSCandidate_Footer_Privacy_Policy')}
      </a>

      <a
        className="FooterLink"
        href="https://www.clevry.com/en/about/cookies"
        target="_blank"
        rel="noopener noreferrer"
      >
        {t('SSCandidate_Footer_Cookies_Policy')}
      </a>
    </>
  );
};

interface FooterProps {
  isThemed: boolean;
}

const Footer: React.FC<FooterProps> = ({ isThemed }) => {
  const { data, footerType } = useSelector(selectFooter);

  // Don't include NavigationFooter in Footer div as it doesn't obey the same
  // syling rules.
  if (footerType === FooterType.NAVIGATION_FOOTER) {
    return (
      <div className={`NavigationFooterContainer  ${isThemed ? 'Themed' : ''}`}>
        <NavigationFooter {...(data as NavigationFooterData)} />
      </div>
    );
  }
  if (footerType === FooterType.NONE) {
    return null;
  }

  return (
    <footer className={`Footer ${isThemed ? 'Themed' : ''} WidthContent`}>
      {footerType === FooterType.LOGGED_IN ? (
        <div className="FooterLinks LoggedInFooter">
          <div className="LeftContainer">
            <FooterSocials />
          </div>
          <FooterCopyright />
        </div>
      ) : footerType === FooterType.COPYRIGHT_ONLY ? (
        <FooterCopyright />
      ) : footerType === FooterType.COPYRIGHT_ONLY_RIGHT ? (
        <FooterCopyrightRight />
      ) : footerType === FooterType.PUBLIC_ALL_ELEMENTS ? (
        <>
          <div className="FooterLinks">
            <FooterSocials />
            <FooterLegalLinks />
          </div>
          <div className="FooterRight">
            <FooterCopyright />
          </div>
        </>
      ) : footerType === FooterType.UPDATE_PROFILE ? (
        <UpdateProfileFooter {...(data as UpdateProfileFooterData)} />
      ) : null}
    </footer>
  );
};

export { Footer };
