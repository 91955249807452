import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import MP_Skills_Dream from 'assets/apply.clevry/MP_Skills_Dream-job_01.png';
import UltimateDreamJobPageRow from './UltimateDreamJobPageRow';
import {
  getDreamJobsDetails,
  getRoleLevelOptions,
  getRoleTitleOptions,
  parseDreamJobsDetails,
  parseGetRoleLevelOptions,
  parseGetRoleTitleOptions,
  parseUltimateDreamJobPayload,
  submitUltimateDreamJobPayload,
} from '../../MatcingProcessApi';
import { Spinner } from 'components/spinner/Spinner';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';
import {
  MatchingProcessState,
  selectMatchingProcess,
  setMatchingProcessDataSubmittedToggle,
  setMatchingProcessSubmitSuccess,
} from 'reducers/matchingProcess';
import { useDispatch, useSelector } from 'react-redux';
import { GAMatchingProcessLatest } from 'helpers/gaEvents';

type UltimateDreamJobPageProps = {
  outerSubmitForm: MutableRefObject<
    (() => Promise<void>) & (() => Promise<any>)
  >;
  setNextStepDisabled: Dispatch<SetStateAction<boolean>>;
  setGeneralError: Dispatch<SetStateAction<string | undefined>>;
  generalError?: string | undefined;
};

export type UltimateDreamJobsFormDetails = {
  RoleLevel1: number | null;
  RoleLevel2: number | null;
  RoleLevel3: number | null;
  RoleTitle1: number | null;
  RoleTitle2: number | null;
  RoleTitle3: number | null;
  RoleId1: number | null;
  RoleId2: number | null;
  RoleId3: number | null;
};

// type formikValues = {
//   RoleLevel1: number | null;
//   RoleLevel2: number | null;
//   RoleLevel3: number | null;
//   RoleTitle1: number | null;
//   RoleTitle2: number | null;
//   RoleTitle3: number | null;
// };

type formikValues = UltimateDreamJobsFormDetails;

const UltimateDreamJobPage: React.FC<UltimateDreamJobPageProps> = ({
  outerSubmitForm,
  setNextStepDisabled,
  setGeneralError,
  generalError,
}) => {
  const latestLocation = useRef('');

  if (
    window &&
    window.location &&
    window.location.href !== latestLocation.current
  ) {
    latestLocation.current = window.location.href;
    GAMatchingProcessLatest(window.location.href);
  }

  const { t } = useTranslation('Matching+Process', { i18n });

  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState<formikValues>({
    RoleLevel1: null,
    RoleLevel2: null,
    RoleLevel3: null,
    RoleTitle1: null,
    RoleTitle2: null,
    RoleTitle3: null,
    RoleId1: null,
    RoleId2: null,
    RoleId3: null,
  });
  const [menuOptions, setMenuOptions] = useState({
    roleTitle: [],
    roleLevel: [],
  });
  const candidateDreamJobId = useRef();

  const areRowsUnique = (values: {
    RoleLevel1: number | null;
    RoleLevel2: number | null;
    RoleLevel3: number | null;
    RoleTitle1: number | null;
    RoleTitle2: number | null;
    RoleTitle3: number | null;
  }) => {
    const {
      RoleLevel1,
      RoleLevel2,
      RoleLevel3,
      RoleTitle1,
      RoleTitle2,
      RoleTitle3,
    } = values;
    let isUnique = true;
    const roles = [
      { role: RoleLevel1, level: RoleTitle1 },
      { role: RoleLevel2, level: RoleTitle2 },
      { role: RoleLevel3, level: RoleTitle3 },
    ];
    roles.forEach(({ role, level }, idx) => {
      roles.forEach((roleInner, innerIdx) => {
        if (
          innerIdx !== idx &&
          roleInner.role === role &&
          role !== null &&
          roleInner.role !== null &&
          level !== null &&
          roleInner.level !== null &&
          roleInner.level === level
        ) {
          isUnique = false;
        }
      });
    });

    return isUnique;
  };

  const uniqueRowsTest = (values: any) => {
    const uniqueRows = areRowsUnique(values);
    if (!uniqueRows) {
      setGeneralError(t('SSCandidate_Matching_Process_Remove_Identical_Rows'));
    } else {
      setGeneralError('');
    }
    return uniqueRows;
  };

  const correspondingRowsAreNotEmpty = (values: {
    RoleLevel1: number | null;
    RoleLevel2: number | null;
    RoleLevel3: number | null;
    RoleTitle1: number | null;
    RoleTitle2: number | null;
    RoleTitle3: number | null;
  }) => {
    let correspondingRowsAreNotEmptyResult = true;

    const {
      RoleLevel1,
      RoleLevel2,
      RoleLevel3,
      RoleTitle1,
      RoleTitle2,
      RoleTitle3,
    } = values;

    const roles = [
      { role: RoleLevel1, level: RoleTitle1 },
      { role: RoleLevel2, level: RoleTitle2 },
      { role: RoleLevel3, level: RoleTitle3 },
    ];

    roles.map(({ role, level }) => {
      if (
        (role !== null && level === null) ||
        (role === null && level !== null)
      ) {
        correspondingRowsAreNotEmptyResult = false;
      }
    });

    return correspondingRowsAreNotEmptyResult;
  };

  const correspondingRowsAreNotEmptyTest = (values: any) => {
    const correspondingRowsAreNotEmptyResult =
      correspondingRowsAreNotEmpty(values);

    if (!correspondingRowsAreNotEmptyResult) {
      setGeneralError(
        t('SSCandidate_Matching_Process_Rows_Both_Values_Or_Empty')
      );
    } else {
      setGeneralError('');
    }
    return correspondingRowsAreNotEmptyResult;
  };

  const validationSchema = Yup.object()
    .shape({
      RoleLevel1: Yup.number().nullable(),
      RoleLevel2: Yup.number().nullable(),
      RoleLevel3: Yup.number().nullable(),
      RoleTitle1: Yup.number().nullable(),
      RoleTitle2: Yup.number().nullable(),
      RoleTitle3: Yup.number().nullable(),
    })
    .test('areRowsUnique', 'Rows need to be unique', uniqueRowsTest)
    .test(
      'areCorrespondingRowsNotEmpty',
      'Either both values in a row need to be selected or both need to be empty',
      correspondingRowsAreNotEmptyTest
    );

  const dispatch = useDispatch();

  const matchingProcessDetails: MatchingProcessState = useSelector(
    selectMatchingProcess
  );

  const handleSubmit = (values: formikValues) => {
    const payload = parseUltimateDreamJobPayload(values);
    return (async () => {
      const result = await submitUltimateDreamJobPayload(payload);
      if (result && result.response) {
        setGeneralError('');

        dispatch(setMatchingProcessSubmitSuccess(true));

        dispatch(
          setMatchingProcessDataSubmittedToggle(
            !matchingProcessDetails.dataSubmittedToggle
          )
        );

        return true;
      } else {
        dispatch(setMatchingProcessSubmitSuccess(false));

        setGeneralError(t('SSCandidate_Matching_Process_General_Error'));
        return false;
      }
    })();
  };

  const fetchDetails = async () => {
    const result = await getDreamJobsDetails();
    if (result && result.response && result.response.details) {
      const parsedResult = parseDreamJobsDetails(result.response.details);
      if (parsedResult) {
        setInitialValues(parsedResult);
      }

      setGeneralError('');
      setLoading(false);
    } else if (result && result.errors) {
      setLoading(false);
      setGeneralError(t('SSCandidate_Matching_Process_General_Error_Reload'));
    } else if (result) {
      setLoading(false);
    }
  };

  const fetchMenuOptions = async () => {
    const roleTitleOptions = await getRoleTitleOptions();
    const roleLevelOptions = await getRoleLevelOptions();
    if (roleTitleOptions.response && roleLevelOptions.response) {
      const parsedRoleTitleOptions = parseGetRoleTitleOptions(
        roleTitleOptions.response
      );
      const parsedRoleLevelOptions = parseGetRoleLevelOptions(
        roleLevelOptions.response
      );

      setMenuOptions({
        roleTitle: parsedRoleTitleOptions,
        roleLevel: parsedRoleLevelOptions,
      });
    }
  };

  useEffect(() => {
    fetchDetails();
    fetchMenuOptions();
  }, []);

  if (loading) return <Spinner />;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
      validateOnMount
    >
      {({
        setFieldValue,
        values,
        errors,
        touched,
        setValues,
        submitForm,
        isValid,
      }) => {
        outerSubmitForm.current = submitForm;
        if (isValid && !generalError) {
          setNextStepDisabled(false);
        } else {
          setNextStepDisabled(true);
        }

        return (
          <div className="UltimateDreamJobPage MatchingProcessInnerPage">
            <div className="MatchingProcess__sectionHeader">
              <div className="MatchingProcess__sectionHeading">
                {t('SSCandidate_Matching_Process_Dream_Job_Heading')}
              </div>
              <div className="MatchingProcess__sectionSubheading">
                <span>
                  {t('SSCandidate_Matching_Process_Dream_Job_SubHeading1')}
                </span>
                <span>
                  {t('SSCandidate_Matching_Process_Dream_Job_SubHeading2')}
                </span>
              </div>
            </div>
            <Form className="JobApplicationForm MatchingProcessForm">
              <UltimateDreamJobPageRow
                rowNumber={1}
                menuOptions={menuOptions}
              />
              <UltimateDreamJobPageRow
                rowNumber={2}
                menuOptions={menuOptions}
              />
              <UltimateDreamJobPageRow
                rowNumber={3}
                menuOptions={menuOptions}
              />
            </Form>
            <img
              src={MP_Skills_Dream}
              alt=""
              className="UltimateDreamJobPage__icon"
            />
          </div>
        );
      }}
    </Formik>
  );
};

export default UltimateDreamJobPage;
