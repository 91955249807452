import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { TextInput } from 'components/formFields/TextInput';
import ProcessStepTextAndImageRow from '../ProcessStepTextAndImageRow';
import RowIcon from 'assets/apply.clevry/MP_Profile_Contact_01.png';
import { Spinner } from 'components/spinner/Spinner';
import {
  getContactDetails,
  getCountryOptions,
  getData,
  parseContactDetails,
  parseContactPayload,
  parseCountryOptions,
  submitContactPayload,
} from '../../MatcingProcessApi';
import { FormSingleSelectCustomField } from '../CustomFormFields/FormSingleSelectCustomField';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';
import { init } from 'ramda';
import PhoneNumberField from 'components/PhoneNumberField/PhoneNumberField';
import { useDispatch, useSelector } from 'react-redux';
import {
  MatchingProcessState,
  selectMatchingProcess,
  setMatchingProcessDataSubmittedToggle,
  setMatchingProcessSubmitSuccess,
} from 'reducers/matchingProcess';
import { GAMatchingProcessLatest } from 'helpers/gaEvents';
import { endpoints } from 'config';
import { setDashboardInfoMatchingProfileComplete } from 'reducers/dashboardInfo';
import { CheckboxInput } from 'components/formFields/CheckboxInput';

type ContactPageProps = {
  outerSubmitForm: MutableRefObject<
    (() => Promise<void>) & (() => Promise<any>)
  >;
  setNextStepDisabled: Dispatch<SetStateAction<boolean>>;
  setGeneralError: Dispatch<SetStateAction<string | undefined>>;
};

export type ContactPageFormikValues = {
  emailAddress: null;
  mobileNumber: null;
  city: string;
  postalCode: string;
  countryID: number;
  bConsidersSelfDisabled: boolean;
};

const ContactPage: React.FC<ContactPageProps> = ({
  outerSubmitForm,
  setNextStepDisabled,
  setGeneralError,
}) => {
  const latestLocation = useRef('');

  if (
    window &&
    window.location &&
    window.location.href !== latestLocation.current
  ) {
    latestLocation.current = window.location.href;
    GAMatchingProcessLatest(window.location.href);
  }

  const { t } = useTranslation('Matching+Process', { i18n });

  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState<{
    emailAddress: string | null;
    mobileNumber: number | null;
    city: string | null;
    postalCode: string | null;
    countryID: number | null;
    bConsidersSelfDisabled: boolean;
  }>({
    emailAddress: null,
    mobileNumber: null,
    city: null,
    postalCode: null,
    countryID: null,
    bConsidersSelfDisabled: false,
  });
  const [menuOptions, setMenuOptions] = useState({
    countries: [
      {
        value: null,
        label: '',
      },
    ],
  });
  const [userEmailAddress, setUserEmailAddress] = useState<string | null>(null);

  useEffect(() => {
    if (!initialValues.emailAddress && userEmailAddress) {
      setInitialValues({ ...initialValues, emailAddress: userEmailAddress });
    }
  }, [userEmailAddress]);

  const validationSchema = Yup.object({
    emailAddress: Yup.string().email().required(),
    mobileNumber: Yup.string()
      .matches(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g)
      .nullable(),
    city: Yup.string().required(),
    postalCode: Yup.string().required(),
    countryID: Yup.number().required(),
    bConsidersSelfDisabled: Yup.boolean(),
  });

  const dispatch = useDispatch();

  const matchingProcessDetails: MatchingProcessState = useSelector(
    selectMatchingProcess
  );

  const handleSubmit = (values: typeof initialValues) => {
    const payload = parseContactPayload(values);

    return (async () => {
      const result = await submitContactPayload(payload);
      if (result.response) {
        setGeneralError('');

        dispatch(setDashboardInfoMatchingProfileComplete(true));

        dispatch(setMatchingProcessSubmitSuccess(true));

        dispatch(
          setMatchingProcessDataSubmittedToggle(
            !matchingProcessDetails.dataSubmittedToggle
          )
        );

        return true;
      } else {
        dispatch(setMatchingProcessSubmitSuccess(false));

        setGeneralError(t('SSCandidate_Matching_Process_General_Error'));
        return false;
      }
    })();
  };

  const fetchDetails = async () => {
    const result = await getContactDetails();
    const countryOptions = await getCountryOptions();
    const userEmailAddressResult = await getData(
      endpoints.matchingProcess.getEmailAddressForContactDetails
    );

    if (
      userEmailAddressResult.response &&
      userEmailAddressResult.response.emailAddress &&
      typeof userEmailAddressResult.response.emailAddress === 'string' &&
      !userEmailAddressResult.errors
    ) {
      setUserEmailAddress(userEmailAddressResult.response.emailAddress);
    }

    if (result.response && countryOptions.response) {
      const parsedCountryOptions = parseCountryOptions(countryOptions.response);
      setMenuOptions({ countries: parsedCountryOptions });

      const parsedContactDetails = parseContactDetails(
        result.response,
        parsedCountryOptions
      );
      if (parsedContactDetails) {
        setInitialValues(parsedContactDetails);
      }

      setGeneralError('');
      setLoading(false);
    } else {
      setLoading(false);
      setGeneralError(t('SSCandidate_Matching_Process_General_Error_Reload'));
    }
  }; // Need to fetch details and options in same function, so can country id from getCountryOptions can be used to convert country string from getContactDetails to corresponding id

  useEffect(() => {
    fetchDetails();
  }, []);

  if (loading) return <Spinner />;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
      validateOnMount
    >
      {({
        setFieldValue,
        values,
        errors,
        setErrors,
        touched,
        setValues,
        submitForm,
        isValid,
        validateForm,
      }) => {
        outerSubmitForm.current = submitForm;

        if (isValid) {
          setNextStepDisabled(false);
        } else {
          setNextStepDisabled(true);
        }

        return (
          <>
            <Form className="ContactPage MatchingProcessForm MatchingProcessInnerPage">
              <div className="FormContainer">
                <div className="MatchingProcess__sectionHeader">
                  <div className="MatchingProcess__sectionHeading">
                    {t('SSCandidate_Matching_Process_Contact_Heading')}
                  </div>
                  <div className="MatchingProcess__sectionSubheading">
                    {t('SSCandidate_Matching_Process_Contact_SubHeading1')}
                  </div>
                </div>

                <ProcessStepTextAndImageRow icon={RowIcon}>
                  <TextInput
                    name={`emailAddress`}
                    label={t(
                      'SSCandidate_Matching_Process_Contact_Field_Label1'
                    )}
                    maxLength={320}
                    readOnly={true}
                    value={
                      userEmailAddress
                        ? userEmailAddress
                        : 'No email address data'
                    }
                    placeholder={
                      userEmailAddress
                        ? userEmailAddress
                        : 'No email address data'
                    }
                  />
                  {/* <TextInput
                    name={`mobileNumber`}
                    label={t(
                      'SSCandidate_Matching_Process_Contact_Field_Label2'
                    )}
                    maxLength={30}
                  /> */}

                  <PhoneNumberField
                    name={`mobileNumber`}
                    label={t(
                      'SSCandidate_Matching_Process_Contact_Field_Label2'
                    )}
                    maxLength={30}
                    errors={errors}
                    setErrors={setErrors}
                    setFieldValue={setFieldValue}
                    values={values}
                  />

                  <div className="ContactTextInput_Lower">
                    <TextInput
                      name={`city`}
                      label={t(
                        'SSCandidate_Matching_Process_Contact_Field_Label3'
                      )}
                      maxLength={30}
                    />
                    <TextInput
                      name={`postalCode`}
                      label={t(
                        'SSCandidate_Matching_Process_Contact_Field_Label4'
                      )}
                      maxLength={12}
                    />
                    <FormSingleSelectCustomField
                      fieldName={`countryID`}
                      label={t(
                        'SSCandidate_Matching_Process_Contact_Field_Label5'
                      )}
                      options={menuOptions.countries}
                      useNumberForValue={true}
                    />
                  </div>

                  <CheckboxInput
                    label={t(
                      'SSCandidate_Matching_Process_Contact_Field_Label6'
                    )}
                    name="bConsidersSelfDisabled"
                    className="UpdateContactCheckboxInput"
                    isBordered={true}
                  />
                </ProcessStepTextAndImageRow>
              </div>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

export default ContactPage;
