import { ProfileApi } from '../profile/profileApi';
import { UpdateContactApi } from '../updateContact/updateContactApi';
import { endpoints } from '../../../config';
import instance from 'api';
import { get, post } from '../../../helpers/newMakeEndpointRequest';
import { UltimateDreamJobsFormDetails } from './matchingProcessPages/UltimateDreamJob/UltimateDreamJobPage';
import { CriticalHardSkillsMenuOptions } from './matchingProcessPages/CriticalHardSkills/CriticalHardSkillsPage';
import { LanguagesYouSpeakFomValues } from './matchingProcessPages/LanguagesYouSpeak/LanguagesYouSpeakPage';
import { Item } from '../viewApplication/ViewApplicationReadOnlyItems';
import { Label } from '@mui/icons-material';

export const configureFormData = (params: any) => {
  const formData = new FormData();

  Object.entries(params).forEach(([key, value]: any) => {
    if (Array.isArray(value)) {
      value.forEach((innerValue, idx) =>
        formData.append(`${key}[${idx}]`, innerValue)
      );
    } else if (
      typeof value === 'object' &&
      value !== null &&
      key === 'Profilepicture'
    ) {
      formData.append(key, value);
    } else if (typeof value === 'object' && value !== null) {
      Object.entries(value).forEach(([innerKey, innerValue]: any) => {
        formData.append(`${[key]}[${innerKey}]`, innerValue);
      });
    } else {
      formData.append(key, value);
    }
  });
  return formData;
};

// Get progress percentages for each section of the matching profile
export const getMatchingProfileProgressBars = async () => {
  try {
    const res = await instance.get(
      endpoints.matchingProcess.getMatchingProfileProgressBars
    );
    if (res.data.status === '1' && res.data && res.data.details) {
      return {
        response: res.data.details,
        errors: null,
      };
    } else {
      return {
        response: null,
        errors: res.data.errors,
      };
    }
  } catch (res) {
    const error: any = res;

    if (error.response && error.response.data && error.response.data.errors) {
      return {
        response: null,
        errors: error.response.data.errors,
      };
    } else {
      return {
        response: null,
        errors: [],
      };
    }
  }
};

// Current Situation page
export const getCurrentSituationDetails = async () => {
  try {
    const res = await instance.get(
      endpoints.matchingProcess.getCandidateCurrentSituation
    );
    if (res.data.status === '1') {
      return {
        response: res.data,
        errors: null,
      };
    } else {
      return {
        response: null,
        errors: res.data.errors,
      };
    }
  } catch (res) {
    const error: any = res;

    if (error.response && error.response.data && error.response.data.errors) {
      return {
        response: null,
        errors: error.response.data.errors,
      };
    } else {
      return {
        response: null,
        errors: [],
      };
    }
  }
};

export const parseCurrentSituationDetails = (values: any) => {
  return values.details;
};
export const parseCurrentSituationPayload = (values: any) => {
  return {
    candidateStatusTypeId: parseInt(values.candidateStatusTypeId),
  };
};

export const submitCurrentSituationPayload = async (payload: any) => {
  try {
    const res = await instance.post(
      endpoints.matchingProcess.setCandidateCurrentSituation,
      payload
    );
    if (res.data.status === '1') {
      return {
        response: res.data,
        errors: null,
      };
    } else {
      return {
        response: null,
        errors: res.data.errors,
      };
    }
  } catch (res) {
    const error: any = res;

    if (error.response && error.response.data && error.response.data.errors) {
      return {
        response: null,
        errors: error.response.data.errors,
      };
    } else {
      return {
        response: null,
        errors: [],
      };
    }
  }
};

// Ultimate Dream Job page
export const getRoleTitleOptions = async () => {
  try {
    const res = await instance.get(
      endpoints.matchingProcess.getCandidateRoleTitleOptions
    );
    if (res.data.status === '1') {
      return {
        response: res.data,
        errors: null,
      };
    } else {
      return {
        response: null,
        errors: res.data.errors,
      };
    }
  } catch (res) {
    const error: any = res;

    if (error.response && error.response.data && error.response.data.errors) {
      return {
        response: null,
        errors: error.response.data.errors,
      };
    } else {
      return {
        response: null,
        errors: [],
      };
    }
  }
};
export const parseGetRoleTitleOptions = (values: any) => {
  if (values.details && Array.isArray(values.details.jobTitles)) {
    return values.details.jobTitles.map((item: any) => {
      return {
        label: item.name,
        value: item.id,
      };
    });
  }
  return [];
};
export const getRoleLevelOptions = async () => {
  try {
    const res = await instance.get(
      endpoints.matchingProcess.getCandidateRoleLevelOptions
    );
    if (res.data.status === '1') {
      return {
        response: res.data,
        errors: null,
      };
    } else {
      return {
        response: null,
        errors: res.data.errors,
      };
    }
  } catch (res) {
    const error: any = res;

    if (error.response && error.response.data && error.response.data.errors) {
      return {
        response: null,
        errors: error.response.data.errors,
      };
    } else {
      return {
        response: null,
        errors: [],
      };
    }
  }
};
export const getIndustryOptions = async () => {
  try {
    const res = await instance.get(
      endpoints.matchingProcess.getCandidateIndustryOptions
    );
    if (res.data.status === '1') {
      return {
        response: res.data,
        errors: null,
      };
    } else {
      return {
        response: null,
        errors: res.data.errors,
      };
    }
  } catch (res) {
    const error: any = res;

    if (error.response && error.response.data && error.response.data.errors) {
      return {
        response: null,
        errors: error.response.data.errors,
      };
    } else {
      return {
        response: null,
        errors: [],
      };
    }
  }
};
export const getHeadcountValues = async () => {
  try {
    const res = await instance.get(
      endpoints.matchingProcess.getHeadcountValues
    );
    if (res.data.status === '1') {
      return {
        response: res.data,
        errors: null,
      };
    } else {
      return {
        response: null,
        errors: res.data.errors,
      };
    }
  } catch (res) {
    const error: any = res;

    if (error.response && error.response.data && error.response.data.errors) {
      return {
        response: null,
        errors: error.response.data.errors,
      };
    } else {
      return {
        response: null,
        errors: [],
      };
    }
  }
};
export const getDreamJobsDetails = async () => {
  try {
    const res = await instance.get(
      endpoints.matchingProcess.getCandidateDreamJobs
    );
    if (res.data.status === '1') {
      return {
        response: res.data,
        errors: null,
      };
    } else {
      return {
        response: null,
        errors: res.data.errors,
      };
    }
  } catch (res) {
    const error: any = res;

    if (error.response && error.response.data && error.response.data.errors) {
      return {
        response: null,
        errors: error.response.data.errors,
      };
    } else {
      return {
        response: null,
        errors: [],
      };
    }
  }
};
export const parseDreamJobsDetails = (
  values: any
): UltimateDreamJobsFormDetails => {
  const { candidateDreamJobs } = values;
  const details: { [key: string]: number } = {};
  candidateDreamJobs.forEach((item: any, idx: number) => {
    details[`RoleLevel${idx + 1}`] = item.roleLevelId;
    details[`RoleTitle${idx + 1}`] = item.jobTitleId;
    details[`RoleId${idx + 1}`] = item.candidateDreamJobId;
  });
  return details as UltimateDreamJobsFormDetails;
};
export const parseGetRoleLevelOptions = (values: any) => {
  if (values.details && Array.isArray(values.details.roleLevels)) {
    return values.details.roleLevels
      .sort((item1: any, item2: any) => item2.id - item1.id)
      .map((item: any) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
  }
  return [];
};
export const parseGetIndustriesOptions = (values: any) => {
  if (values.details && Array.isArray(values.details.industries)) {
    return values.details.industries.map((item: any) => {
      return {
        label: item.name,
        value: item.sectorID,
      };
    });
  }
  return [];
};
export const parseUltimateDreamJobPayload = (values: any) => {
  let payload = {
    candidateDreamJobs: [
      {
        orderNumber: 1,
        ...(values.RoleId1 ? { candidateDreamJobId: values.RoleId1 } : {}),
        jobTitleId: values.RoleTitle1,
        roleLevelId: values.RoleLevel1,
      },
      {
        orderNumber: 2,
        ...(values.RoleId2 ? { candidateDreamJobId: values.RoleId2 } : {}),
        jobTitleId: values.RoleTitle2,
        roleLevelId: values.RoleLevel2,
      },
      {
        orderNumber: 3,
        ...(values.RoleId3 ? { candidateDreamJobId: values.RoleId3 } : {}),
        jobTitleId: values.RoleTitle3,
        roleLevelId: values.RoleLevel3,
      },
    ],
  };

  payload.candidateDreamJobs = payload.candidateDreamJobs.filter(
    (item) =>
      item.jobTitleId &&
      item.jobTitleId !== null &&
      item.roleLevelId &&
      item.roleLevelId !== null
  );

  return payload;
};

// Add in endpoint call when endpoint exists
export const submitUltimateDreamJobPayload = async (payload: any) => {
  const result = await post(
    endpoints.matchingProcess.setCandidateDreamJobs,
    payload
  );
  return result;
};

// How Like To Work page
// Add in endpoint call when endpoint exists
export const getHowLikeToWorkDetails = async () => {
  try {
    const res = await instance.get(
      endpoints.matchingProcess.getCandidateHowLikeToWork
    );
    if (res.data.status === '1') {
      return {
        response: res.data,
        errors: null,
      };
    } else {
      return {
        response: null,
        errors: res.data.errors,
      };
    }
  } catch (res) {
    const error: any = res;

    if (error.response && error.response.data && error.response.data.errors) {
      return {
        response: null,
        errors: error.response.data.errors,
      };
    } else {
      return {
        response: null,
        errors: [],
      };
    }
  }
};

export const parseHowLikeToWorkDetails = (values: any) => {
  return {
    candidateWorkSchedules: Array.isArray(values.details.candidateWorkSchedules)
      ? values.details.candidateWorkSchedules
      : [],
    previousySelectedSchedules: Array.isArray(
      values.details.candidateWorkSchedules
    )
      ? values.details.candidateWorkSchedules.map((item: any) => {
          return item.workSchedulesId.toString();
        })
      : [],
    workSchedules: values.details.workSchedules,
  };
};

export const submitHowLikeToWorkPayload = async (payload: any) => {
  const result = await post(
    endpoints.matchingProcess.setCandidateHowLikeToWork,
    payload
  );
  return result;
};
export const parseHowLikeToWorkPayload = (values: any) => {
  const parsedData = values.selectedSchedules.map((item: any) => {
    const filteredCandidateWorkSchedulesID =
      values.candidateWorkSchedules.filter(
        (obj: any) => obj.workSchedulesId.toString() === item
      );

    return {
      candidateWorkSchedulesId: filteredCandidateWorkSchedulesID[0]
        ? filteredCandidateWorkSchedulesID[0].candidateWorkSchedulesId
        : 0,
      workSchedulesId: item,
    };
  });

  return {
    candidateWorkSchedules: parsedData,
  };
};

// Global Soft Skills page
export const getGlobalSoftSkillsDetails = async () => {
  try {
    const res = await instance.get(
      endpoints.matchingProcess.getCandidateGlobalSoftSkills
    );
    if (res.data.status === '1') {
      return {
        response: res.data.details,
        errors: null,
      };
    } else {
      return {
        response: null,
        errors: res.data.errors,
      };
    }
  } catch (res) {
    const error: any = res;

    if (error.response && error.response.data && error.response.data.errors) {
      return {
        response: null,
        errors: error.response.data.errors,
      };
    } else {
      return {
        response: null,
        errors: [],
      };
    }
  }
};
export const parseGlobalSoftSkillsDetails = (values: any) => {
  if (values && Array.isArray(values.candidateGlobalSoftSkills)) {
    const forDisplay = values.candidateGlobalSoftSkills.map(
      ({ globalSoftSkillsId, name, description }: any) => ({
        description,
        id: globalSoftSkillsId,
        name,
      })
    );

    const forForm = values.candidateGlobalSoftSkills.map(
      ({ globalSoftSkillsId, candidateGlobalSoftSkillsId }: any) => ({
        answerId: globalSoftSkillsId,
        candidateGlobalSoftSkillsId,
      })
    );
    return { forForm, forDisplay };
  }
  return null;
};

export const getGlobalSoftSkillsOptions = async () => {
  try {
    const res = await instance.get(
      endpoints.matchingProcess.getCandidateGlobalSoftSkillsOptions
    );
    if (res.data.status === '1') {
      return {
        response: res.data,
        errors: null,
      };
    } else {
      return {
        response: null,
        errors: res.data.errors,
      };
    }
  } catch (res) {
    const error: any = res;

    if (error.response && error.response.data && error.response.data.errors) {
      return {
        response: null,
        errors: error.response.data.errors,
      };
    } else {
      return {
        response: null,
        errors: [],
      };
    }
  }
};
export const parseGlobalSoftSkillsOptions = (values: any) => {
  return values.details.globalSoftSkills;
};
export const submitGlobalSoftSkillsPayload = async (payload: any) => {
  const result = await post(
    endpoints.matchingProcess.setCandidateGlobalSoftSkills,
    payload
  );
  return result;
};
// Update and type when have actual endpoint payload
export const parseGlobalSoftSkillsPayload = (values: any) => {
  if (values && Array.isArray(values.globalSoftSkills)) {
    const candidateGlobalSoftSkills = values.globalSoftSkills.map(
      (
        {
          answerId,
          candidateGlobalSoftSkillsId,
        }: {
          answerId: number;
          candidateGlobalSoftSkillsId: number;
        },
        idx: number
      ) => ({
        ...(candidateGlobalSoftSkillsId ? { candidateGlobalSoftSkillsId } : {}),
        globalSoftSkillsId: answerId,
        orderNumber: idx + 1,
      })
    );
    return { candidateGlobalSoftSkills };
  }
  return [];
};

// My Salary Aspiration page
export const getSalaryAspirationDetails = async () => {
  try {
    const res = await instance.get(
      endpoints.matchingProcess.getCandidateSalaryAspirations
    );
    if (res.data.status === '1') {
      return {
        response: res.data,
        errors: null,
      };
    } else {
      return {
        response: null,
        errors: res.data.errors,
      };
    }
  } catch (res) {
    const error: any = res;

    if (error.response && error.response.data && error.response.data.errors) {
      return {
        response: null,
        errors: error.response.data.errors,
      };
    } else {
      return {
        response: null,
        errors: [],
      };
    }
  }
};
export const parseSalaryAspirationDetails = (values: any) => {
  return {
    salaryAspirations: {
      minimumSalaryAspiration: values.details.minimumSalaryAspiration,
      maximumSalaryAspiration: values.details.maximumSalaryAspiration,
      salaryAspirationCurrencyId: values.details.salaryAspirationCurrencyId,
    },
    currencies: values.details.currencies.map((item: any) => {
      return {
        label: item.code,
        value: item.id,
        name: item.name,
      };
    }),
  };
};
export const parseSalaryAspirationPayload = (values: any) => {
  return {
    minimumSalaryAspiration: values.minimumSalaryAspiration,
    maximumSalaryAspiration: values.maximumSalaryAspiration,
    salaryAspirationCurrencyId: values.salaryAspirationCurrencyId,
  };
};
export const submitSalaryAspirationPayload = async (payload: any) => {
  try {
    const res = await instance.post(
      endpoints.matchingProcess.setCandidateSalaryAspirations,
      payload
    );
    if (res.data.status === '1') {
      return {
        response: res.data,
        errors: null,
      };
    } else {
      return {
        response: null,
        errors: res.data.errors,
      };
    }
  } catch (res) {
    const error: any = res;

    if (error.response && error.response.data && error.response.data.errors) {
      return {
        response: null,
        errors: error.response.data.errors,
      };
    } else {
      return {
        response: null,
        errors: [],
      };
    }
  }
};

// Where do you want work page
export const getWhereToWorkDetails = async () => {
  try {
    const res = await instance.get(
      endpoints.matchingProcess.getCandidateWhereToWork
    );
    if (res.data.status === '1') {
      return {
        response: res.data,
        errors: null,
      };
    } else {
      return {
        response: null,
        errors: res.data.errors,
      };
    }
  } catch (res) {
    const error: any = res;

    if (error.response && error.response.data && error.response.data.errors) {
      return {
        response: null,
        errors: error.response.data.errors,
      };
    } else {
      return {
        response: null,
        errors: [],
      };
    }
  }
};
export const parseWhereToWorkDetails = (values: any) => {
  let locations = [];
  if (values && values.details && Array.isArray(values.details.locations)) {
    locations = values.details.locations.map((item: any) => ({
      value: item.locationID,
      label: item.locationName,
    }));
  }
  const canWorkRemotely = values.details.canWorkRemotely;
  return { locations, canWorkRemotely };
};
export const submitWhereToWorkPayload = async (payload: any) => {
  try {
    const res = await instance.post(
      endpoints.matchingProcess.setCandidateWhereToWork,
      payload
    );
    if (res.data.status === '1') {
      return {
        response: res.data,
        errors: null,
      };
    } else {
      return {
        response: null,
        errors: res.data.errors,
      };
    }
  } catch (res) {
    const error: any = res;

    if (error.response && error.response.data && error.response.data.errors) {
      return {
        response: null,
        errors: error.response.data.errors,
      };
    } else {
      return {
        response: null,
        errors: [],
      };
    }
  }
};
export const parseWhereToWorkPayload = (values: any) => {
  const parsedLocation = values.locations.map(
    (item: {
      label: string;
      data: { longitude: string; latitude: string };
    }) => {
      return {
        location: item.label,
        longitude: item.data.longitude,
        latitude: item.data.latitude,
      };
    }
  );

  return {
    canWorkRemotely: values.canWorkRemotely,
    locations: parsedLocation,
  };
};

export const getWhereToWorkOptions = async () => {
  try {
    const res = await instance.get(
      endpoints.matchingProcess.getCandidateWhereToWorkOptions
    );
    if (res.data.status === '1') {
      return {
        response: res.data,
        errors: null,
      };
    } else {
      return {
        response: null,
        errors: res.data.errors,
      };
    }
  } catch (res) {
    const error: any = res;

    if (error.response && error.response.data && error.response.data.errors) {
      return {
        response: null,
        errors: error.response.data.errors,
      };
    } else {
      return {
        response: null,
        errors: [],
      };
    }
  }
};

export const parseWhereToWorkOptions = (data: any) => {
  // console.log('parseWhereToWorkOptions data: ', data);
  let locationsData = [];

  if (data.locations) {
    locationsData = data.locations.map((item: any) => ({
      label: item.location,
      data: { longitude: item.longitude, latitude: item.latitude },
    }));
  }

  return {
    canWorkRemotely: data.canWorkRemotely,
    locations: locationsData,
  };
};

// What Have You Studied Page
export const getWhatHaveYouStudiedDetails = async () => {
  try {
    const res = await instance.get(
      endpoints.matchingProcess.getCandidateEducation
    );
    if (res.data.status === '1') {
      return {
        response: res.data,
        errors: null,
      };
    } else {
      return {
        response: null,
        errors: res.data.errors,
      };
    }
  } catch (res) {
    const error: any = res;

    if (error.response && error.response.data && error.response.data.errors) {
      return {
        response: null,
        errors: error.response.data.errors,
      };
    } else {
      return {
        response: null,
        errors: [],
      };
    }
  }
};

export const parseWhatHaveYouStudiedDetails = (values: any) => {
  return values.details.educationLevels;
};

export const getWhatHaveYouStudiedOptions = async () => {
  try {
    const res = await instance.get(
      endpoints.matchingProcess.getCandidateEducationOptions
    );
    const subjectsRes = await instance.get(
      endpoints.matchingProcess.getCandidateEducationSubjects
    );
    if (res.data.status === '1' && subjectsRes.data.status === '1') {
      return {
        response: { ...res.data.details, ...subjectsRes.data.details },
        errors: null,
      };
    } else {
      return {
        response: null,
        errors: res.data.errors,
      };
    }
  } catch (res) {
    const error: any = res;

    if (error.response && error.response.data && error.response.data.errors) {
      return {
        response: null,
        errors: error.response.data.errors,
      };
    } else {
      return {
        response: null,
        errors: [],
      };
    }
  }
};
export const parseWhatHaveYouStudiedOptions = (values: any) => {
  const levels = values.educationLevels.map((item: any) => {
    return {
      label: item.educationLevelName,
      value: item.educationLevelID,
    };
  });
  const subjects = values.educationSubjects.map((item: any) => {
    return {
      label: item.educationSubjectName,
      value: item.educationSubjectsID,
    };
  });
  return { levels, subjects };
};

export const parseWhatHaveYouStudiedPayload = (values: any) => {
  return {
    educationIDs: values.educationLevels.map((item: any) => {
      return {
        educationLevelID: item.educationLevelID,
        educationSubjectsID: item.educationSubjectsID,
        yearAwarded: item.yearAwarded.toString(),
      };
    }),
  };
};

export const submitWhatHaveYouStudiedPayload = async (payload: any) => {
  try {
    const res = await instance.post(
      endpoints.matchingProcess.setCandidateEducation,
      payload
    );
    if (res.data.status === '1') {
      return {
        response: res.data,
        errors: null,
      };
    } else {
      return {
        response: null,
        errors: res.data.errors,
      };
    }
  } catch (res) {
    const error: any = res;

    if (error.response && error.response.data && error.response.data.errors) {
      return {
        response: null,
        errors: error.response.data.errors,
      };
    } else {
      return {
        response: null,
        errors: [],
      };
    }
  }
};

// Experience Page
export const getExperienceDetails = async () => {
  try {
    const res = await instance.get(
      endpoints.matchingProcess.getCandidateExperiences
    );
    if (res.data.status === '1') {
      return {
        response: res.data,
        errors: null,
      };
    } else {
      return {
        response: null,
        errors: res.data.errors,
      };
    }
  } catch (res) {
    const error: any = res;

    if (error.response && error.response.data && error.response.data.errors) {
      return {
        response: null,
        errors: error.response.data.errors,
      };
    } else {
      return {
        response: null,
        errors: [],
      };
    }
  }
};
export const parseGetExperienceDetails = (values: any) => {
  let experience = [];
  if (
    values &&
    values.details &&
    Array.isArray(values.details.candidateExperiences)
  ) {
    experience = values.details.candidateExperiences.map(
      ({
        jobTitleID,
        roleLevelID,
        numberOfYears,
        companyName,
        companyHeadcount,
        sectorID,
        location,
        longitude,
        latitude,
        isCurrentPosition,
      }: {
        jobTitleID: number;
        roleLevelID: number;
        numberOfYears: number;
        companyName: string;
        companyHeadcount: string | null;
        sectorID: number;
        location: string;
        longitude: number;
        latitude: number;
        isCurrentPosition: boolean;
      }) => ({
        roleLevel: roleLevelID,
        roleTitle: jobTitleID,
        years: numberOfYears,
        companyName: companyName,
        companyHeadcount: companyHeadcount ? companyHeadcount : null,
        sectorID: sectorID,
        location: location,
        longitude: longitude,
        latitude: latitude,
        isCurrentPosition: isCurrentPosition,
      })
    );
  }
  return { experience };
};
export const parseExperiencePayload = (values: any) => {
  return {
    experienceItems: values.experience.map((item: any) => {
      return {
        jobTitleID: item.roleTitle,
        roleLevelID: item.roleLevel,
        numberOfYears: item.years,
        companyName: item.companyName,
        companyHeadcount: item.companyHeadcount,
        sectorID: item.sectorID,
        location: item.location,
        longitude: item.longitude,
        latitude: item.latitude,
        isCurrentPosition: item.isCurrentPosition,
      };
    }),
  };
};
export const submitExperiencePayload = async (payload: any) => {
  try {
    const res = await instance.post(
      endpoints.matchingProcess.setCandidateExperiences,
      payload
    );
    if (res.data.status === '1') {
      return {
        response: res.data,
        errors: null,
      };
    } else {
      return {
        response: null,
        errors: res.data.errors,
      };
    }
  } catch (res) {
    const error: any = res;

    if (error.response && error.response.data && error.response.data.errors) {
      return {
        response: null,
        errors: error.response.data.errors,
      };
    } else {
      return {
        response: null,
        errors: [],
      };
    }
  }
};

type LanguagesYouSpeakDetailsResponse = {
  candidateLanguages: {
    spokenLanguagesID: number;
    spokenLanguageName: string;
    spokenLanguagesProficiencyID: number;
    spokenLanguagesProficiencylName: string;
  }[];
};

// Languages You Speak Page
export const getLanguagesYouSpeakDetails = async () => {
  try {
    const res = await instance.get(
      endpoints.matchingProcess.getCandidateSpokenLanguages
    );
    if (res.data.status === '1') {
      return {
        response: res.data,
        errors: null,
      };
    } else {
      return {
        response: null,
        errors: res.data.errors,
      };
    }
  } catch (res) {
    const error: any = res;

    if (error.response && error.response.data && error.response.data.errors) {
      return {
        response: null,
        errors: error.response.data.errors,
      };
    } else {
      return {
        response: null,
        errors: [],
      };
    }
  }
};

export const parseLanguagesYouSpeakDetails = (
  values: LanguagesYouSpeakDetailsResponse
) => {
  let languagesSpoken: LanguagesYouSpeakFomValues = [];
  if (values && Array.isArray(values.candidateLanguages)) {
    languagesSpoken = values.candidateLanguages.map(
      ({ spokenLanguagesProficiencyID, spokenLanguagesID }) => ({
        proficiency: spokenLanguagesProficiencyID,
        languageRequirement: spokenLanguagesID,
      })
    );
  }
  return { languagesSpoken };
};

export const getLanguagesYouSpeakNameOptions = async () => {
  try {
    const res = await instance.get(
      endpoints.matchingProcess.getCandidateSpokenLanguageNameOptions
    );
    if (res.data.status === '1') {
      return {
        response: res.data,
        errors: null,
      };
    } else {
      return {
        response: null,
        errors: res.data.errors,
      };
    }
  } catch (res) {
    const error: any = res;

    if (error.response && error.response.data && error.response.data.errors) {
      return {
        response: null,
        errors: error.response.data.errors,
      };
    } else {
      return {
        response: null,
        errors: [],
      };
    }
  }
};
export const parseLanguagesYouSpeakNameOptions = (details: any) => {
  return details.details.spokenLanguages.map((item: any) => {
    return {
      label: item.spokenLanguageName,
      value: item.spokenLanguageID,
    };
  });
};
export const getLanguagesYouSpeakProficiencyOptions = async () => {
  try {
    const res = await instance.get(
      endpoints.matchingProcess.getCandidateSpokenLanguageProficiencyOptions
    );
    if (res.data.status === '1') {
      return {
        response: res.data,
        errors: null,
      };
    } else {
      return {
        response: null,
        errors: res.data.errors,
      };
    }
  } catch (res) {
    const error: any = res;

    if (error.response && error.response.data && error.response.data.errors) {
      return {
        response: null,
        errors: error.response.data.errors,
      };
    } else {
      return {
        response: null,
        errors: [],
      };
    }
  }
};
export const parseLanguagesYouSpeakProficiencyOptions = (details: any) => {
  return details.details.spokenLanguagesProficiency.map((item: any) => {
    return {
      label: item.spokenLanguagesProficiencyName,
      value: item.spokenLanguagesProficiencyID,
    };
  });
};
export const parseLanguagesYouSpeakPayload = (values: any) => {
  return {
    languageItems: values.languagesSpoken.map((item: any) => {
      return {
        spokenLanguageID: item.languageRequirement,
        spokenLanguageProficiencyID: item.proficiency,
      };
    }),
  };
};
export const submitLanguagesYouSpeakPayload = async (payload: any) => {
  try {
    const res = await instance.post(
      endpoints.matchingProcess.setCandidateSpokenLanguages,
      payload
    );
    if (res.data.status === '1') {
      return {
        response: res.data,
        errors: null,
      };
    } else {
      return {
        response: null,
        errors: res.data.errors,
      };
    }
  } catch (res) {
    const error: any = res;

    if (error.response && error.response.data && error.response.data.errors) {
      return {
        response: null,
        errors: error.response.data.errors,
      };
    } else {
      return {
        response: null,
        errors: [],
      };
    }
  }
};

// CriticalHardSkills Page
// Add in endpoint call when endpoint exists
export const submitCriticalHardSkillsPayload = async (payload: any) => {
  const result = await post(
    endpoints.matchingProcess.setCandidateHardSkills,
    payload
  );
  return result;
};

// Update and type when have actual endpoint payload
export const parseCriticalHardSkillsPayload = (values: any) => {
  if (values && Array.isArray(values.hardSkills)) {
    const candidateHardSkills = values.hardSkills.map(
      ({
        skillId,
        level,
        candidateHardSkillsId,
      }: {
        skillId: number;
        level: string;
        candidateHardSkillsId: number;
      }) => ({
        hardSkillsId: skillId,
        hardSkillsCompetencyLevelId: parseInt(level),
        candidateHardSkillsId,
      })
    );
    return { candidateHardSkills };
  }
  return [];
};

// Add in endpoint call when endpoint exists
export const getCriticalHardSkillsDetails = async () => {
  const result = await get(
    endpoints.matchingProcess.getCandidateHardSkills,
    {}
  );
  return result;
};

// Update and type when have actual endpoint response
export const parseGetCriticalHardSkillsDetails = (values: any) => {
  let hardSkills: any = [];
  if (values && Array.isArray(values.candidateHardSkillsResults)) {
    hardSkills = values.candidateHardSkillsResults.map(
      ({
        hardSkillsId,
        hardSkillsCompetencyLevelId,
        hardSkillsName,
        candidateHardSkillsId,
      }: {
        hardSkillsId: number;
        hardSkillsCompetencyLevelId: number;
        candidateHardSkillsId: number;
        hardSkillsName: string;
      }) => ({
        skillId: hardSkillsId,
        candidateHardSkillsId: candidateHardSkillsId,
        level: hardSkillsCompetencyLevelId.toString(),
        label: hardSkillsName,
      })
    );
  }
  return { hardSkills };
};

// Add in endpoint call when endpoint exists
export const getCriticalHardSkillsOptions = async () => {
  const result = await get(endpoints.matchingProcess.getHardSkills, {});
  return result;
};

// Update and type when have actual endpoint response
export const parseGetCriticalHardSkillsOptions = (
  details: any
): CriticalHardSkillsMenuOptions => {
  if (
    details &&
    Array.isArray(details.hardSkills) &&
    Array.isArray(details.hardSkillCompetencyLevels)
  ) {
    const fullDetails = [...details.hardSkills];
    const skills = fullDetails.map(
      ({ id, name }: { id: number; name: string }) => ({
        label: name,
        value: id,
      })
    );
    const level = details.hardSkillCompetencyLevels.map(
      ({ id, name }: { id: number; name: string }) => ({
        optionText: name,
        optionId: id,
      })
    );
    return { skills, level };
  }
  return { skills: [], level: [] };
};

// WriteBio page
export const getWriteBioDetails = async () => {
  try {
    const res = await instance.get(endpoints.matchingProcess.getCandidateBio);
    if (res.data.status === '1') {
      return {
        response: res.data,
        errors: null,
      };
    } else {
      return {
        response: null,
        errors: res.data.errors,
      };
    }
  } catch (res) {
    const error: any = res;

    if (error.response && error.response.data && error.response.data.errors) {
      return {
        response: null,
        errors: error.response.data.errors,
      };
    } else {
      return {
        response: null,
        errors: [],
      };
    }
  }
};

export const parseGetWriteBioDetails = (details: any) => {
  return {
    about: details.details.about,
  };
};

export const submitWriteBioPayload = async (payload: any) => {
  try {
    const res = await instance.post(
      endpoints.matchingProcess.setCandidateBio,
      payload
    );
    if (res.data.status === '1') {
      return {
        response: res.data,
        errors: null,
      };
    } else {
      return {
        response: null,
        errors: res.data.errors,
      };
    }
  } catch (res) {
    const error: any = res;

    if (error.response && error.response.data && error.response.data.errors) {
      return {
        response: null,
        errors: error.response.data.errors,
      };
    } else {
      return {
        response: null,
        errors: [],
      };
    }
  }
};

// Contact page
export const getContactDetails = async () => {
  try {
    const res = await instance.get(
      endpoints.matchingProcess.getCandidateContact
    );
    if (res.data.status === '1') {
      return {
        response: res.data,
        errors: null,
      };
    } else {
      return {
        response: null,
        errors: res.data.errors,
      };
    }
  } catch (res) {
    const error: any = res;

    if (error.response && error.response.data && error.response.data.errors) {
      return {
        response: null,
        errors: error.response.data.errors,
      };
    } else {
      return {
        response: null,
        errors: [],
      };
    }
  }
};
export const parseContactDetails = (
  values: any,
  parsedCountryOptions?: any
) => {
  if (parsedCountryOptions && values.details) {
    const filteredCountryName = parsedCountryOptions.filter(
      (obj: any) => obj.label === values.details.country
    );

    const countryID = filteredCountryName[0].value;

    return {
      emailAddress: values.details.emailAddress,
      mobileNumber: values.details.mobileNumber,
      city: values.details.city,
      postalCode: values.details.postalCode,
      countryID: countryID,
      bConsidersSelfDisabled: values.details.bConsidersSelfDisabled
        ? values.details.bConsidersSelfDisabled
        : false,
    };
  } else {
    return values.details;
  }
};

export const parseContactPayload = (details: any) => {
  return {
    candidateEmailAddress: details.emailAddress,
    candidatePhoneNumber: details.mobileNumber,
    city: details.city,
    postalCode: details.postalCode,
    countryID: details.countryID,
    bConsidersSelfDisabled: details.bConsidersSelfDisabled,
  };
};
export const submitContactPayload = async (payload: any) => {
  try {
    const res = await instance.post(
      endpoints.matchingProcess.setCandidateContact,
      payload
    );
    if (res.data.status === '1') {
      return {
        response: res.data,
        errors: null,
      };
    } else {
      return {
        response: null,
        errors: res.data.errors,
      };
    }
  } catch (res) {
    const error: any = res;

    if (error.response && error.response.data && error.response.data.errors) {
      return {
        response: null,
        errors: error.response.data.errors,
      };
    } else {
      return {
        response: null,
        errors: [],
      };
    }
  }
};

export const getCountryOptions = async () => {
  try {
    const res = await instance.get(endpoints.matchingProcess.getCountries);
    if (res.data.status === '1') {
      return {
        response: res.data,
        errors: null,
      };
    } else {
      return {
        response: null,
        errors: res.data.errors,
      };
    }
  } catch (res) {
    const error: any = res;

    if (error.response && error.response.data && error.response.data.errors) {
      return {
        response: null,
        errors: error.response.data.errors,
      };
    } else {
      return {
        response: null,
        errors: [],
      };
    }
  }
};

export const parseCountryOptions = (values: any) => {
  return values.details.countries.map((item: any) => {
    return {
      label: item.country,
      value: item.countryId,
    };
  });
};

export const getData = async (endpoint: string) => {
  try {
    const res = await instance.get(endpoint);
    if (res.data.status === '1' && res.data && res.data.details) {
      return {
        response: res.data.details,
        errors: null,
      };
    } else {
      return {
        response: null,
        errors: res.data.errors,
      };
    }
  } catch (res) {
    const error: any = res;

    if (error.response && error.response.data && error.response.data.errors) {
      return {
        response: null,
        errors: error.response.data.errors,
      };
    } else {
      return {
        response: null,
        errors: [],
      };
    }
  }
};

export const submitProfilePicture = async (payload: any) => {
  try {
    const res = await instance.post(
      endpoints.matchingProcess.setCandidateProfilePicture,
      payload
    );
    if (res.data.status === '1') {
      return {
        response: res.data,
        errors: null,
      };
    } else {
      return {
        response: null,
        errors: res.data.errors,
      };
    }
  } catch (res) {
    const error: any = res;

    if (error.response && error.response.data && error.response.data.errors) {
      return {
        response: null,
        errors: error.response.data.errors,
      };
    } else {
      return {
        response: null,
        errors: [],
      };
    }
  }
};

export const setLastPageVisited = async (
  lastPageVisitedURL: string
): Promise<any> => {
  const url = endpoints.matchingProcess.setLastPageVisited;
  return await post(url, {
    lastPageVisitedURL,
  });
};
