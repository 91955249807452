import { Store } from './rootReducer';

//Types
export enum IFramedProngAction {
  'SET_IFRAMED_PRONG_DATA' = 'SET_IFRAMED_PRONG_DATA',
}

export interface IFramedProng {
  iframeUrl: string;
  redirectUrl: string;
  redirectUrlProps?: any;
}

const initialState: IFramedProng = {
  iframeUrl: '',
  redirectUrl: '',
  redirectUrlProps: {},
};

// Actions
export type Action = {
  type: IFramedProngAction.SET_IFRAMED_PRONG_DATA;
  payload: IFramedProng;
};

// Reducer
export default function IFramedProngReducer(
  state = initialState,
  action: Action
): IFramedProng {
  if (action.type === IFramedProngAction.SET_IFRAMED_PRONG_DATA) {
    return { ...state, ...action.payload };
  }
  return state;
}

// Action creators
export const setIFramedProng = (payload: IFramedProng) => ({
  type: IFramedProngAction.SET_IFRAMED_PRONG_DATA,
  payload,
});

// Selectors
export const selectIFramedProngState = (state: Store) => state.iframedProng;
