import React from 'react';
import clearIcon from 'assets/apply.clevry/Icon_FAQ_02.png';
import Icon_Pagination_02 from 'assets/apply.clevry/Icon_Pagination_02.png';
import { InteractiveFeedbackScoreIcon } from './InteractiveFeedbackScoreIcon';
import { InteractiveFeedbackDownloadReportButton } from './InteractiveFeedbackDownloadReportButton';
import { Button } from 'components/button/Button';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';

type InteractiveFeedbackMenuHeadingProps = {
  title: string;
  headingCloseClickHandler?: () => void;
  icon?: string;
  score?: number;
  assessmentID?: number;
  backArrowClickHandler?: () => void;
  reportCode?: string;
  isCandidateReport?: boolean;
  previousSectionHandler?: () => void;
  nextSectionHandler?: () => void;
  hideStenScores?: boolean;
  sectionData?: any;
};

export const InteractiveFeedbackMenuHeading = ({
  title,
  icon,
  score,
  headingCloseClickHandler,
  backArrowClickHandler,
  reportCode,
  assessmentID,
  isCandidateReport,
  previousSectionHandler,
  nextSectionHandler,
  hideStenScores,
  sectionData,
}: InteractiveFeedbackMenuHeadingProps) => {
  const { t } = useTranslation('Interactive+Feedback', { i18n });
  const reportDownloadFlags = sectionData && sectionData.reportDownloadFlags ? sectionData.reportDownloadFlags : false;

  return (
    <div className="InteractiveFeedbackMenuHeading">
      {icon && (
        <img
          className="InteractiveFeedbackMenuHeading__Icon"
          src={icon}
          alt="Menu Heading Icon"
        />
      )}
      {score && !hideStenScores && <InteractiveFeedbackScoreIcon score={score} />}
      {backArrowClickHandler && (
        <div
          onClick={backArrowClickHandler}
          className={`InteractiveFeedbackMenuHeading__BackArrow`}
        >
          <img src={Icon_Pagination_02} alt="Back arrow" />
        </div>
      )}
      <div className="InteractiveFeedbackMenuHeading__Title">{title}</div>
      {reportCode && 
        (reportDownloadFlags.isReportDownloadable || reportDownloadFlags.isAbilityReportDownloadable || reportDownloadFlags.isSJTReportDownloadable) 
        && (
        <div className="InteractiveFeedbackMenuHeading__Button">
          <InteractiveFeedbackDownloadReportButton
            text={t('SSCandidate_Interactive_Feedback_DownloadReport')}
            reportCode={reportCode as string}
            assessmentID={assessmentID as number}
            isCandidateReport={isCandidateReport}
          />
        </div>
      )}
      {previousSectionHandler && nextSectionHandler && (
        <div className="InteractiveFeedbackMenuHeading__NavButtons">
          <Button
            className={`InteractiveFeedbackGraphButton`}
            priority="tertiary"
            onClick={previousSectionHandler}
          >
            Previous style
          </Button>
          <Button
            className={`InteractiveFeedbackGraphButton`}
            priority="tertiary"
            onClick={nextSectionHandler}
          >
            Next style
          </Button>
        </div>
      )}
      {headingCloseClickHandler && (
        <img
          className="InteractiveFeedbackMenuHeading__CloseIcon"
          src={clearIcon}
          alt="Close Menu Heading"
          onClick={headingCloseClickHandler}
        />
      )}
    </div>
  );
};
