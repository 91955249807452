import { useState, useCallback, useEffect } from 'react';
import { IUserAccount } from './models';
import { AccountApi } from './accountApi';

export const useGetAccountInfo = () => {
  const [accountInfo, setAccountInfo] = useState<IUserAccount | null>(null);

  const getAccountInfo = useCallback(async () => {
    const result = await AccountApi.getAccountInfo();

    if (result.data) {
      setAccountInfo(result.data);
    }

    if (result.error) {
      return { error: result.error };
    }
  }, []);
  useEffect(() => {
    getAccountInfo();
  }, [getAccountInfo]);
  return accountInfo;
};
