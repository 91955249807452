import React, { useMemo } from 'react';

type LineProgressIndicatorProps = {
  steps: number;
  currentStep: number;
  className?: string;
};

export const LineProgressIndicator: React.FC<LineProgressIndicatorProps> = ({
  currentStep,
  steps,
  className,
}) => {
  const arrayToMap = useMemo(() => new Array(steps).fill(null), [steps]);

  return (
    <div className={`LineProgressIndicator ${className ?? ''}`}>
      <div className="StepNumberContainer">
        <h1 className="StepNumber">
          {currentStep}/{steps}
        </h1>
      </div>
      <div className="LinesContainer">
        {arrayToMap.map((_, index) => {
          const isBarFilled = index + 1 <= currentStep;
          return <div className={`Line ${isBarFilled ? 'Filled' : ''}`} />;
        })}
      </div>
    </div>
  );
};
