import { Link, navigate } from '@reach/router';
import React, { useContext } from 'react';
import Logo from 'assets/apply.clevry/Logo2.png';
import LogoWhite from 'assets/apply.clevry/Logo_White.png';
import Icon_Back from 'assets/job-board-widget/Icon_Back.png';
import Icon_Menu from 'assets/job-board-widget/Icon_Menu.png';
import { NavType, selectNavData, selectNavType } from 'reducers/navbar';
import { useDispatch, useSelector } from 'react-redux';
import { openLeftPanel } from 'reducers/leftPanel';
import { NavBarContext } from './NavBar';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';

export const handleNavigate = (path: string) => () => {
  navigate(path);
};

const PublicNavBar = () => {
  const { t } = useTranslation('Public+Nav+Bar', { i18n });

  const dispatch = useDispatch();

  const isThemed = useContext(NavBarContext);

  const navType = useSelector(selectNavType);
  const navData = useSelector(selectNavData);

  const handleBackNavBtn = () => {
    window.scrollTo(0, 0);
  };

  const handleBurgerOpen = () => {
    dispatch(openLeftPanel({ panelType: 'JOB_BOARD_SIGN_IN', data: null }));
  };

  return navType === NavType.TITLE_AND_BACK ? (
    <div className="SubPageNavbar">
      <Link to="/" onClick={handleBackNavBtn}>
        <img
          src={Icon_Back}
          alt={t('SSCandidate_Public_Nav_Bar_Alt_Company_Logo')}
        ></img>
      </Link>
      <p className="PageTitle">{navData && navData.title}</p>
    </div>
  ) : (
    <>
      <div className="MobileNav">
        <img
          src={Icon_Menu}
          alt={t('SSCandidate_Public_Nav_Bar_Alt_Burger_Icon')}
          onClick={handleBurgerOpen}
          style={{ height: '100%' }}
          className="BurgerLink"
        />
        <Link to="/">
          <img
            src={isThemed ? LogoWhite : Logo}
            alt={t('SSCandidate_Public_Nav_Bar_Alt_Company_Logo')}
            style={{ height: '3rem' }}
          />
        </Link>
      </div>
    </>
  );
};

export { PublicNavBar };
