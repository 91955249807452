import * as Yup from 'yup';
import { FieldArray, Form, Formik } from 'formik';
import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import MP_Profile_Experience from 'assets/apply.clevry/MP_Profile_Experience_01.png';
import IconAdd from 'assets/apply.clevry/Icon_Add_DarkBlue.png';
import {
  getExperienceDetails,
  parseGetExperienceDetails,
  parseExperiencePayload,
  submitExperiencePayload,
  getRoleTitleOptions,
  getRoleLevelOptions,
  parseGetRoleTitleOptions,
  parseGetRoleLevelOptions,
  getIndustryOptions,
  parseGetIndustriesOptions,
  getHeadcountValues,
} from '../../MatcingProcessApi';
import { Spinner } from 'components/spinner/Spinner';
import { Button } from 'components/button/Button';
import ExperienceRow from './ExperienceRow';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';
import { useDispatch, useSelector } from 'react-redux';
import {
  MatchingProcessState,
  selectMatchingProcess,
  setMatchingProcessDataSubmittedToggle,
  setMatchingProcessSubmitSuccess,
} from 'reducers/matchingProcess';
import { GAMatchingProcessLatest } from 'helpers/gaEvents';

type ExperiencePageProps = {
  outerSubmitForm: MutableRefObject<
    (() => Promise<void>) & (() => Promise<any>)
  >;
  setNextStepDisabled: Dispatch<SetStateAction<boolean>>;
  setGeneralError: Dispatch<SetStateAction<string | undefined>>;
};

export type ExperienceValues = {
  roleTitle: number | null;
  roleLevel: number | null;
  years: number | null;
  companyName: string;
  companyHeadcount: string | null;
  sectorID: number | null;
  location: string;
  longitude: number | null;
  latitude: number | null;
  isCurrentPosition: boolean;
};

type formikValues = {
  experience: ExperienceValues[];
};

const ExperiencePage: React.FC<ExperiencePageProps> = ({
  outerSubmitForm,
  setNextStepDisabled,
  setGeneralError,
}) => {
  const latestLocation = useRef('');

  if (
    window &&
    window.location &&
    window.location.href !== latestLocation.current
  ) {
    latestLocation.current = window.location.href;
    GAMatchingProcessLatest(window.location.href);
  }

  const { t } = useTranslation('Matching+Process', { i18n });

  const blankRow = {
    roleTitle: null,
    roleLevel: null,
    years: null,
    companyName: '',
    companyHeadcount: '',
    sectorID: null,
    location: '',
    longitude: null,
    latitude: null,
    isCurrentPosition: false,
  };

  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState({
    experience: [blankRow],
  });
  const [menuOptions, setMenuOptions] = useState({
    experience: {
      roleTitle: [],
      roleLevel: [],
      industries: [],
      headcountValues: [],
      years: null,
    },
  });

  const areRowsUnique = (values: ExperienceValues[]) => {
    const roleTitles = values.map(({ roleTitle, roleLevel }, index) => ({
      roleTitle,
      roleLevel,
      index,
    }));
    let isUnique = true;
    values.forEach((item, outerIdx) => {
      roleTitles.forEach((role) => {
        if (
          role.roleTitle === item.roleTitle &&
          role.roleLevel === item.roleLevel &&
          role.index !== outerIdx
        ) {
          isUnique = false;
        }
      });
    });
    return isUnique;
  };

  const validationSchema = Yup.object().shape({
    experience: Yup.array()
      .of(
        Yup.object({
          roleTitle: Yup.number().required(''),
          roleLevel: Yup.number().required(''),
          years: Yup.number()
            .typeError('Please enter a value')
            .required('Please enter a value'),
          companyName: Yup.string().required(''),
          companySize: Yup.number().nullable(),
          sectorID: Yup.number().required(''),
        }).test(
          'noMoreThan70',
          'Years of experience must be no more than 70',
          (values) => {
            if (values && values?.years > 70) {
              setGeneralError(
                t('SSCandidate_Matching_Process_Experience_Years_Too_High')
              );
              return false;
            }
            return true;
          }
        )
      )
      .test('areRowsUnique', 'Rows need te be unique', (values: any) => {
        const uniqueRows = areRowsUnique(values);
        if (!uniqueRows) {
          setGeneralError(
            t('SSCandidate_Matching_Process_Experience_Duplicate_Error')
          );
        } else {
          setGeneralError('');
        }
        return uniqueRows;
      })
      .min(1),
  });

  const dispatch = useDispatch();

  const matchingProcessDetails: MatchingProcessState = useSelector(
    selectMatchingProcess
  );

  const handleSubmit = (values: formikValues) => {
    const payload = parseExperiencePayload(values);
    return (async () => {
      const result = await submitExperiencePayload(payload);
      if (result.response) {
        setGeneralError('');

        dispatch(setMatchingProcessSubmitSuccess(true));

        dispatch(
          setMatchingProcessDataSubmittedToggle(
            !matchingProcessDetails.dataSubmittedToggle
          )
        );

        return true;
      } else {
        dispatch(setMatchingProcessSubmitSuccess(false));

        setGeneralError(t('SSCandidate_Matching_Process_General_Error'));
        return false;
      }
    })();
  };

  const fetchDetails = async () => {
    const result = await getExperienceDetails();
    if (result.response) {
      const parsedResult = parseGetExperienceDetails(result.response);
      if (
        parsedResult &&
        Array.isArray(parsedResult.experience) &&
        parsedResult.experience.length > 0
      ) {
        setInitialValues(parsedResult);
      }

      setGeneralError('');
      setLoading(false);
    } else {
      setLoading(false);
      setGeneralError(t('SSCandidate_Matching_Process_General_Error_Reload'));
    }
  };

  const fetchOptions = async () => {
    const roleTitleOptions = await getRoleTitleOptions();
    const roleLevelOptions = await getRoleLevelOptions();
    const industryOptions = await getIndustryOptions();
    const headcountValues = await getHeadcountValues();
    if (
      roleTitleOptions.response &&
      roleLevelOptions.response &&
      industryOptions.response &&
      headcountValues.response
    ) {
      const parsedRoleTitleOptions = parseGetRoleTitleOptions(
        roleTitleOptions.response
      );
      const parsedRoleLevelOptions = parseGetRoleLevelOptions(
        roleLevelOptions.response
      );
      const parsedIndustryOptions = parseGetIndustriesOptions(
        industryOptions.response
      );

      const headcountValuesList =
        headcountValues.response.details.headcountValues.map((item: any) => {
          return {
            label: item.headcountValue.replace('Company headcount: ', ''),
            value: item.headcountValue.replace('Company headcount: ', ''),
          };
        });

      setMenuOptions({
        experience: {
          ...menuOptions.experience,
          roleTitle: parsedRoleTitleOptions,
          roleLevel: parsedRoleLevelOptions,
          industries: parsedIndustryOptions,
          headcountValues: headcountValuesList,
        },
      });
    }
  };

  useEffect(() => {
    fetchDetails();
    fetchOptions();
  }, []);

  if (loading) return <Spinner />;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
      validateOnMount
    >
      {({
        setFieldValue,
        values,
        errors,
        touched,
        setValues,
        submitForm,
        isValid,
      }) => {
        outerSubmitForm.current = submitForm;
        if (isValid) {
          setNextStepDisabled(false);
        } else {
          setNextStepDisabled(true);
        }

        return (
          <div className="ExperiencePage MatchingProcessInnerPage">
            <div className="MatchingProcess__sectionHeader">
              <div className="MatchingProcess__sectionHeading">
                {t('SSCandidate_Matching_Process_Experience_Heading')}
              </div>
              <div className="MatchingProcess__sectionSubheading">
                <span>
                  {t('SSCandidate_Matching_Process_Experience_SubHeading1')}
                </span>
              </div>
            </div>
            <Form className="JobApplicationForm MatchingProcessForm">
              <FieldArray
                name="experience"
                render={({ push, remove }) => {
                  return (
                    <>
                      {values.experience &&
                        Array.isArray(values.experience) &&
                        values.experience.map((item, idx) => (
                          <ExperienceRow
                            key={`experience${idx}`}
                            rowNumber={idx}
                            remove={remove}
                            menuOptions={menuOptions.experience}
                            location={values.experience[idx].location}
                            setFieldValue={setFieldValue}
                            values={values}
                          />
                        ))}
                      <Button
                        icon={IconAdd}
                        priority={'secondary'}
                        onClick={(e) => {
                          e.preventDefault();
                          push(blankRow);
                        }}
                      >
                        {t('SSCandidate_Matching_Process_Experience_Button')}
                      </Button>
                    </>
                  );
                }}
              />
            </Form>
            <img
              src={MP_Profile_Experience}
              alt=""
              className="ExperiencePage__icon"
            />
          </div>
        );
      }}
    </Formik>
  );
};

export default ExperiencePage;
