import { Button } from 'components/button/Button';

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { closeModal } from 'reducers/modal';

import { Spinner } from 'components/spinner/Spinner';

import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';
import { post } from 'helpers/newMakeEndpointRequest';
import { endpoints } from 'config';
import { id } from 'date-fns/locale';
import { Toast } from 'components/toastMessages/toast';
import { navigate } from '@reach/router';

import {
  setApplicationAssessmentId,
  setApplicationProjectId,
  setApplicationType,
} from 'reducers/application';

type UndoWithdrawApplicationModalProps = {
  data: {
    projectId: number;
    assessmentId: number;
  };
};

const UndoWithdrawApplicationModal: React.FC<
  UndoWithdrawApplicationModalProps
> = ({ data }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [endpointSuccess, setEndpointSuccess] = useState<boolean>(false);

  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const undoWithdrawApplication = async (
    projectId: number,
    assessmentId: number
  ) => {
    setSubmitting(true);
    const payload = {
      projectId: projectId,
    };
    const result = await post(
      endpoints.jobApplication.undoWithdrawApplication,
      payload
    );

    if (result.response && !result.errors) {
      dispatch(setApplicationProjectId(projectId));
      dispatch(setApplicationAssessmentId(assessmentId));
      dispatch(setApplicationType('live'));
      setEndpointSuccess(true);
      setErrorMessage(null);
      setSubmitting(false);
      navigate(`/view-application/${assessmentId}/live/stages`, {
        state: { projectId: projectId },
      });

      handleCloseModal();
    } else {
      setErrorMessage('Something went wrong, please try again later');
    }
  };

  useEffect(() => {
    if (errorMessage) {
      Toast.openToastSuccessMessage(errorMessage);
    }
  }, [errorMessage]);

  useEffect(() => {
    if (endpointSuccess) {
      Toast.openToastSuccessMessage(
        t(
          'SSCandidate_Undo_Withdraw_Application_Undo_Withdraw_Application_Success'
        )
      );
    }
  }, [endpointSuccess]);

  const handleSubmit = () => {
    if (data.projectId && data.assessmentId) {
      undoWithdrawApplication(data.projectId, data.assessmentId);
    }
  };

  const { t } = useTranslation('Undo+Withdraw+Application', { i18n });

  return (
    <div className="UndoWithdrawApplicationModal FullWidth">
      {loading && <Spinner />}

      {!loading && (
        <div className="Content">
          <div className="Text">
            {t(
              'SSCandidate_Undo_Withdraw_Application_Are_You_Sure_Undo_Application_Withdrawal'
            )}
          </div>
        </div>
      )}
      <div className="ModalFooter">
        <Button priority="tertiary" type="button" onClick={handleCloseModal}>
          Cancel
        </Button>

        <Button
          priority="primary"
          type="button"
          onClick={handleSubmit}
          disabled={
            loading ||
            submitting ||
            (data && !data.projectId) ||
            errorMessage !== null
          }
        >
          Confirm
        </Button>
      </div>
    </div>
  );
};

export { UndoWithdrawApplicationModal };
