import React, { useState } from 'react';
import { InteractiveFeedbackMenuCardHub } from '../components/InteractiveFeedbackMenuCardHub';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';
import { Spinner } from 'components/spinner/Spinner';
import ErrorFallback from 'components/fallbacks/ErrorFallback';
import useMedia from 'use-media';
import {
  MyTeamStrengthsCardOptions,
  MyTeamStrengthsGraphCardOptions,
  useGetMyTeamStrengthsDetails,
} from '../hooks/useGetMyTeamStrengthsDetails';

type InteractiveFeedbackMyTeamStrengthsProps = {
  sectionData: any;
};

export const InteractiveFeedbackMyTeamStrengths: React.FC<InteractiveFeedbackMyTeamStrengthsProps> = ({
  sectionData,
}) => {
  const [animateDismount, setAnimateDismount] = useState(false);
  const [alternateView, setAlternateView] = useState(false);
  const isTablet = useMedia('(max-width: 768px)');

  const { t } = useTranslation('Interactive+Feedback', { i18n });

  const [data, loading, errors] = useGetMyTeamStrengthsDetails();

  if (loading || data === undefined || data === null) return <Spinner />;

  if (errors) return <ErrorFallback />;

  const {
    teamStrengthsWheelImage,
    teamStrengthsMenuCards,
    teamStrengthsGraphCards,
    reportCode,
    isCandidateReport,
  } = data as {
    teamStrengthsWheelImage: string;
    teamStrengthsMenuCards: MyTeamStrengthsCardOptions;
    teamStrengthsGraphCards: MyTeamStrengthsGraphCardOptions;
    reportCode: string;
    isCandidateReport: boolean;
  };

  return (
    <div className="InteractiveFeedbackMyTeamStrengths WidthContent HeightContent">
      <InteractiveFeedbackMenuCardHub
        title={t('SSCandidate_Interactive_Feedback_My_Team_Strengths')}
        cardDetails={teamStrengthsMenuCards.teamStrengthsCards}
        graphCardDetails={teamStrengthsGraphCards.teamStrengthsGraphCards}
        rows={[]}
        setAnimateDismount={setAnimateDismount}
        animateDismount={animateDismount}
        onAnimationEnd={() => {
          setAlternateView(true);
        }}
        reportCode={reportCode}
        isCandidateReport={isCandidateReport}
        wheelImage={teamStrengthsWheelImage}
        graphType="TEAM"
        sectionData={sectionData}
      />
    </div>
  );
};
