import { navigate } from '@reach/router';
import { AppPath } from 'appConstants';
import Logo from 'assets/apply.clevry/Logo2.png';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectSignedIn } from 'reducers/auth';
import { NamedFeatureFlag, selectFeatureFlags } from 'reducers/featureFlags';

export const LogoNavBar: React.FC = () => {
  const isSignedIn = useSelector(selectSignedIn);
  const featureFlags = useSelector(selectFeatureFlags);
  const [featureFlaggedAllowed, setFeatureFlaggedAllowed] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    try {
      setLoading(true);

      const softSkillsCandidateFeature =
        featureFlags.allFeatureFlags &&
        featureFlags.allFeatureFlags.find(
          (item: NamedFeatureFlag) => item.feature === 'softSkillsCandidate'
        );

      if (softSkillsCandidateFeature && softSkillsCandidateFeature.bAllowed) {
        setFeatureFlaggedAllowed(true);
      } else {
        setFeatureFlaggedAllowed(false);
      }

      setLoading(false);
    } catch {
      setFeatureFlaggedAllowed(false);

      setLoading(false);
    }
  }, [featureFlags]);

  const handleLogoClick = () => {
    if (!isSignedIn) {
      navigate(AppPath.ROOT);
    } else if (featureFlaggedAllowed) {
      navigate(AppPath.HOME);
    } else {
      navigate(AppPath.MY_JOY);
    }
  };

  return (
    <>
      <nav className="Nav LoggedOut">
        <div className="PageNavBar LogoNav">
          {!loading && (
            <button onClick={handleLogoClick} className="UnstyledButton">
              <img src={Logo} alt="Company Logo" className="CompanyLogo" />
            </button>
          )}
        </div>
      </nav>
    </>
  );
};
