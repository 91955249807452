import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAccessibilityData,
  setAccessibilityData,
} from 'reducers/accessibility';
import { selectWebskin } from 'reducers/auth';
import { Form, Formik } from 'formik';
import { closeModal } from 'reducers/modal';
import { Button } from 'components/button/Button';
import { FormRadioButtonsField } from '../pages/matchingProcess/matchingProcessPages/CustomFormFields/FormRadioButtonsField';

export const AssessmentAccessibilityModal = () => {
  const dispatch = useDispatch();
  const accessibilityData = useSelector(selectAccessibilityData);
  const webSkinDetails = useSelector(selectWebskin);

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const options = [
    {
      optionText: 'AaBbCcDdEeFf',
      optionId: 0,
      tileColour: '#ffffff',
      textColour: '#124a57',
      classStr: 'Standard',
    },
    {
      optionId: 1,
      optionText: 'AaBbCcDdEeFf',
      tileColour: '#000000',
      textColour: '#FFFF00',
      classStr: 'Yellow',
    },
    {
      optionId: 2,
      optionText: 'AaBbCcDdEeFf',
      tileColour: '#000000',
      textColour: '#ffffff',
      classStr: 'White',
    },
    {
      optionId: 3,
      optionText: 'AaBbCcDdEeFf',
      tileColour: '#FFFF99',
      textColour: '#000000',
      classStr: 'Black',
    },
  ];

  const handleSubmit = (values: any) => {
    const { textColour, tileColour, classStr } =
      options[parseInt(values.accessibilityChoice)];

    if(values.accessibilityChoice === "0" && webSkinDetails && webSkinDetails !== "defaultTheme"){
      let webSkinTxtColour = "";
      let txtColourArr = webSkinDetails.textColours;

      for(let i = 0; i < txtColourArr.length; i++){
        if(txtColourArr[i].code === "TC_General"){
          webSkinTxtColour = txtColourArr[i].colourValue;
        }
      }

      dispatch(
        setAccessibilityData({
          textColor: webSkinTxtColour ? webSkinTxtColour : textColour,
          backgroundColor: tileColour,
          titleColor: webSkinTxtColour ? webSkinTxtColour : textColour,
          borderColor: textColour,
          classStr: classStr,
          errorTextColor:
            values.accessibilityChoice === '0' ? '#d83838' : webSkinTxtColour ? webSkinTxtColour : textColour,
          optionNumber: parseInt(values.accessibilityChoice),
          isSet: values.accessibilityChoice === '0' ? false : true,
        })
      );
    } else {
      dispatch(
        setAccessibilityData({
          textColor: textColour,
          backgroundColor: tileColour,
          titleColor: textColour,
          borderColor: textColour,
          classStr: classStr,
          errorTextColor:
            values.accessibilityChoice === '0' ? '#d83838' : textColour,
          optionNumber: parseInt(values.accessibilityChoice),
          isSet: values.accessibilityChoice === '0' ? false : true,
        })
      );
    }
    handleCloseModal();
  };

  const modalStyle = {
    '--modalTitleColor': '#036C8F',
    '--footerBackgroundColor': '#f5f5f5',
    '--footerBorderColor': '#f5f5f5',
    '--primaryButtonBackgroundColor': '#36705B',
    '--tertiaryButtonBackgroundColor': '#d5d5d5',
  } as React.CSSProperties;

  return (
    <div className="AssessmentModal Accessibility" style={modalStyle}>
      <Formik
        initialValues={{
          accessibilityChoice: accessibilityData.optionNumber.toString(),
        }}
        onSubmit={handleSubmit}
      >
        {({ isValid, isSubmitting }) => (
          <Form>
            <div className="TileRowInner">
              <FormRadioButtonsField
                fieldName={'accessibilityChoice'}
                label={''}
                options={options}
                key={`AccessibilityOptionsKey`}
              />
            </div>
            <div className="ModalFooter">
              <Button
                className="Rounded"
                priority="tertiary"
                type="button"
                onClick={handleCloseModal}
              >
                Cancel
              </Button>
              <Button
                className="Discard Rounded"
                priority="primary"
                type="submit"
              >
                Apply
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
