import React, { ReactComponentElement, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from './Modal';
import { selectModalState } from 'reducers/modal';
import { DashboardModal } from 'components/pages/joyAtWork/JoyAtWorkModal';
import { TwoFactorModal } from 'components/pages/settings/TwoFactorModal';
import { AccountChangePasswordModal } from 'components/pages/account/AccountChangePasswordModal';
import { AccountDeleteModal } from 'components/pages/account/AccountDeleteModal';
import { IconGuideModal } from 'components/pages/viewApplication/IconGuideModal';
import { WithdrawApplicationModal } from 'components/pages/viewApplication/WithdrawApplicationModal';
import { ContactEmployerModal } from 'components/pages/viewApplication/ContactEmployerModal';
import { InteractiveFeedbackDownloadReportModal } from 'components/pages/interactiveFeedback/components/InteractiveFeedbackDownloadReportModal';
import { ExpressInterestModal } from 'components/pages/jobBoard/ExpressInterestModal';
import { VirtualAssessmentsAccessibilityModal } from 'components/pages/virtualAssessments/VirtualAssessmentsAccesibilityModal';
import { VirtualAssessmentsTimeoutModal } from 'components/pages/virtualAssessments/VirtualAssessmentsTimeoutModal';
import { VirtualAssessmentsAdjustmentModal } from 'components/pages/virtualAssessments/VirtualAssessmentsAdjustmentModal';
import { VirtualAssessmentsBeforeYouBeginModal } from 'components/pages/virtualAssessments/VirtualAssessmentsBeforeYouBeginModal';
import { AbilityTestsAccessibilityModal } from 'components/pages/abilityTests/abilityTestResources/AbilityTestsAccessibilityModal';
import { AbilityTestsTimeoutModal } from 'components/pages/abilityTests/abilityTestResources/AbilityTestsTimeoutModal';
import { AbilityTestsAdjustmentModal } from 'components/pages/abilityTests/abilityTestResources/AbilityTestsAdjustmentModal';
import { AbilityTestsBeforeYouBeginModal } from 'components/pages/abilityTests/abilityTestResources/AbilityTestsBeforeYouBeginModal';
import { AbilityTestsTxtModal } from 'components/pages/abilityTests/abilityTestResources/AbilityTestsTxtModal';
import { AbilityTestsResetModal } from 'components/pages/abilityTests/abilityTestResources/AbilityTestsResetModal';
import { SJTAccessibilityModal } from 'components/pages/situationalJudgementTest/SJTResources/SJTAccessibilityModal';
import { SJTTimeoutModal } from 'components/pages/situationalJudgementTest/SJTResources/SJTTimeoutModal';
import { SJTTestTxtModal } from 'components/pages/situationalJudgementTest/SJTResources/SJTTestTxtModal';
import { SJTBeforeYouBeginModal } from 'components/pages/situationalJudgementTest/SJTResources/SJTBeforeYouBeginModal';
import { SJTAdjustmentModal } from 'components/pages/situationalJudgementTest/SJTResources/SJTAdjustmentModal';
import { UndoWithdrawApplicationModal } from 'components/pages/viewApplication/UndoWithdrawApplicationModal';
import { ProjectIntroVideoModal } from 'components/pages/viewApplication/ProjectIntroVideoModal';
import { AssessmentBeforeYouBeginModal } from 'components/assessmentComponents/AssessmentBeforeYouBeginModal';
import { AssessmentAccessibilityModal } from 'components/assessmentComponents/AssessmentAccessibilityModal';
import { AssessmentScenarioModal } from 'components/assessmentComponents/AssessmentScenarioModal';
import { AssessmentLoadingAntiCheatingModal } from 'components/assessmentComponents/AssessmentLoadingAntiCheatingModal';

const ModalRouter = () => {
  const modalType = useSelector(selectModalState);
  const [modal, setModal] = useState<any>(null);
  const [width, setWidth] = useState<string>('');
  const [height, setHeight] = useState<string>('');
  const [isNotCloseable, setIsNotCloseable] = useState<any>(false);
  const [hideHeader, setHideHeader] = useState<boolean>(false);

  React.useEffect(() => {
    setWidth('');
    setHeight('');
    setIsNotCloseable(false);
    setHideHeader(false);

    switch (modalType.modalType) {
      case 'DASHBOARD':
        setModal(<DashboardModal data={modalType.data} />);
        break;
      case 'SETTINGS':
        setModal(<TwoFactorModal data={modalType.data} />);
        break;
      case 'ACCOUNT_CHANGE_PASSWORD':
        setModal(<AccountChangePasswordModal data={modalType.data} />);
        break;
      case 'ACCOUNT_DELETE':
        setModal(<AccountDeleteModal data={modalType.data} />);
        break;
      case 'ICON_GUIDE':
        setModal(<IconGuideModal data={modalType.data} />);
        break;
      case 'WITHDRAW_APPLICATION':
        setModal(<WithdrawApplicationModal data={modalType.data} />);
        break;
      case 'CONTACT_EMPLOYER':
        setModal(<ContactEmployerModal data={modalType.data} />);
        break;
      case 'UNDO_WITHDRAW_APPLICATION':
        setModal(<UndoWithdrawApplicationModal data={modalType.data} />);
        break;
      case 'DOWNLOAD_REPORT':
        setModal(
          <InteractiveFeedbackDownloadReportModal
            reportCode={modalType.data.reportCode}
            assessmentId={modalType.data.assessmentId}
            isCandidateReport={modalType.data.isCandidateReport}
          />
        );
        break;
      case 'ACCESSIBILITY':
        setModal(<VirtualAssessmentsAccessibilityModal />);
        break;
      case 'VIRTUAL_ASSESSMENTS_BEFORE_YOU_BEGIN':
        setModal(<VirtualAssessmentsBeforeYouBeginModal />);
        break;
      case 'VIRTUAL_ASSESSMENTS_ADJUSTMENT':
        setModal(<VirtualAssessmentsAdjustmentModal />);
        break;
      case 'VIRTUAL_ASSESSMENTS_TIMEOUT':
        setModal(<VirtualAssessmentsTimeoutModal />);
        break;
      case 'ABILITY_TESTS_ACCESSIBILITY':
        setModal(<AbilityTestsAccessibilityModal />);
        break;
      case 'ABILITY_TESTS_INSTRUCTIONS_MODAL_TEXT':
        setModal(<AbilityTestsTxtModal data={modalType.data} />);
        setWidth('ExtraWide');
        setHeight('ExtraTall');
        break;
      case 'ABILITY_TESTS_PRACTICE_MODAL_TEXT':
        setModal(<AbilityTestsTxtModal data={modalType.data} />);
        break;
      case 'ABILITY_TESTS_TEST_MODAL_TEXT':
        setModal(<AbilityTestsTxtModal data={modalType.data} />);
        break;
      case 'ABILITY_TESTS_BEFORE_YOU_BEGIN':
        setModal(<AbilityTestsBeforeYouBeginModal data={modalType.data} />);
        break;
      case 'ABILITY_TESTS_ADJUSTMENT':
        setModal(<AbilityTestsAdjustmentModal data={modalType.data} />);
        break;
      case 'ABILITY_TESTS_RESET':
        setModal(<AbilityTestsResetModal data={modalType.data} />);
        setIsNotCloseable(true);
        break;
      case 'ABILITY_TESTS_TIMEOUT':
        setModal(<AbilityTestsTimeoutModal />);
        break;
      case 'EXPRESS_INTEREST':
        setModal(<ExpressInterestModal data={modalType.data} />);
        break;
      case 'PROJECT_INTRO_VIDEO':
        setModal(<ProjectIntroVideoModal data={modalType.data} />);
        break;
      case 'SJT_TEST_TIMEOUT':
        setModal(<SJTTimeoutModal />);
        break;
      case 'SJT_TESTS_ACCESSIBILITY':
        setModal(<SJTAccessibilityModal />);
        break;
      case 'SJT_TESTS_INSTRUCTIONS_MODAL_TEXT':
        setModal(<SJTTestTxtModal data={modalType.data} />);
        break;
      case 'SJT_TESTS_TEST_MODAL_TEXT':
        setModal(<SJTTestTxtModal data={modalType.data} />);
        break;
      case 'SJT_TESTS_BEFORE_YOU_BEGIN':
        setModal(<SJTBeforeYouBeginModal data={modalType.data} />);
        break;
      case 'SJT_TESTS_ADJUSTMENT':
        setModal(<SJTAdjustmentModal data={modalType.data} />);
        break;
      case 'ASSESSMENT_BEFORE_YOU_BEGIN':
        setModal(<AssessmentBeforeYouBeginModal data={modalType.data} />);
        break;
      case 'ASSESSMENT_ACCESSIBILITY':
        setModal(<AssessmentAccessibilityModal />);
        break;
      case 'ASSESSMENT_SCENARIO':
        setModal(<AssessmentScenarioModal data={modalType.data} />);
        setWidth('ExtraWide');
        setHeight('ExtraTall');
        setIsNotCloseable(true);
        setHideHeader(true);
        break;
      case 'ASSESSMENT_LOADING_ANTI_CHEATING':
        setModal(<AssessmentLoadingAntiCheatingModal data={modalType.data} />);
        setIsNotCloseable(true);
        setHideHeader(true);
        break;
      default:
        setModal(null);
        break;
    }
  }, [modalType.modalType, modalType.data]);

  return (
    <>
      {modal ? (
        <Modal
          title={modalType.data ? modalType.data.title : ''}
          modalClasses={modalType.data ? modalType.data.modalClasses : ''}
          modalHeaderClasses={
            modalType.data ? modalType.data.modalHeaderClasses : ''
          }
          width={width}
          height={height}
          isNotCloseable={isNotCloseable}
          hideHeader={hideHeader}
        >
          {modal}
        </Modal>
      ) : null}
    </>
  );
};

export { ModalRouter };
