import { navigate } from '@reach/router';
import { Button } from 'components/button/Button';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAccessibilityData,
  setAccessibilityData,
} from 'reducers/accessibility';
import { closeModal, openModal } from 'reducers/modal';
import { FormRadioButtonsField } from '../../matchingProcess/matchingProcessPages/CustomFormFields/FormRadioButtonsField';

export const SJTAccessibilityModal = () => {
  const [selected, setSelected] = useState('');
  const dispatch = useDispatch();
  const accessibilityData = useSelector(selectAccessibilityData);

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const options = [
    {
      optionText: 'AaBbCcDdEeFf',
      optionId: 0,
      tileColour: '#ffffff',
      textColour: '#124a57',
      classStr: '',
    },
    {
      optionId: 1,
      optionText: 'AaBbCcDdEeFf',
      tileColour: '#000000',
      textColour: '#FFFF00',
      classStr: 'Yellow',
    },
    {
      optionId: 2,
      optionText: 'AaBbCcDdEeFf',
      tileColour: '#000000',
      textColour: '#ffffff',
      classStr: 'White',
    },
    {
      optionId: 3,
      optionText: 'AaBbCcDdEeFf',
      tileColour: '#FFFF99',
      textColour: '#000000',
      classStr: 'Black',
    },
  ];

  const handleSubmit = (values: any) => {
    const { textColour, tileColour, classStr } =
      options[parseInt(values.accessibilityChoice)];
    dispatch(
      setAccessibilityData({
        textColor: textColour,
        backgroundColor: tileColour,
        titleColor: textColour,
        borderColor: textColour,
        classStr: classStr,
        errorTextColor:
          values.accessibilityChoice === '0' ? '#d83838' : textColour,
        optionNumber: parseInt(values.accessibilityChoice),
        isSet: values.accessibilityChoice === '0' ? false : true,
      })
    );
    handleCloseModal();
  };

  const modalStyle = {
    '--modalTitleColor': '#036C8F',
    '--footerBackgroundColor': '#f5f5f5',
    '--footerBorderColor': '#f5f5f5',
    '--primaryButtonBackgroundColor': '#36705B',
    '--tertiaryButtonBackgroundColor': '#d5d5d5',
  } as React.CSSProperties;

  return (
    <div className="SJTAccessibilityModal" style={modalStyle}>
      <Formik
        initialValues={{
          accessibilityChoice: accessibilityData.optionNumber.toString(),
        }}
        onSubmit={handleSubmit}
      >
        {({ isValid, isSubmitting }) => (
          <Form>
            <div className="TileRowInner">
              <FormRadioButtonsField
                fieldName={'accessibilityChoice'}
                label={''}
                options={options}
                key={`AccessibilityOptionsKey`}
              />
            </div>
            <div className="ModalFooter">
              <Button
                className="Rounded"
                priority="tertiary"
                type="button"
                onClick={handleCloseModal}
              >
                Cancel
              </Button>
              <Button
                className="Discard Rounded"
                priority="primary"
                type="submit"
              >
                Apply
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
