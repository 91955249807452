import { Form, Formik } from 'formik';

type ModalProps = {
  data: {
    title: string;
    info: string;
  };
};

export const AbilityTestsTxtModal: React.FC<ModalProps> = ({ data }) => {
  const modalStyle = {
    '--modalTitleColor': '#036C8F',
    '--footerBackgroundColor': '#f5f5f5',
    '--footerBorderColor': '#f5f5f5',
    '--primaryButtonBackgroundColor': '#36705B',
    '--tertiaryButtonBackgroundColor': '#d5d5d5',
  } as React.CSSProperties;

  let _safariStr = navigator.userAgent.toLowerCase();
  let _safari = ((_safariStr.indexOf("safari") !== -1) && (_safariStr.indexOf("chrome") === -1)) ? true : false; 

  return (
    <div className={"AbilityTestsBeforeYouBeginModal" + (_safari ? " AltModal" : "")} style={modalStyle}>
      <Formik initialValues={{}} onSubmit={() => {}}>
        <Form>
          <div className="AbilityTestsBeforeYouBeginModal__content AbilityTestsModalContent">
            <div className="AbilityTestsBeforeYouBeginModal__message">
              <div
                dangerouslySetInnerHTML={{
                  __html: data.info,
                }}
              />            
            </div>
          </div>
        </Form>
      </Formik>
    </div>
  );
};
