import { useOnClickOutside } from '@avamae/hooks';
import React from 'react';
import { useDispatch } from 'react-redux';
import { closeLeftPanel } from 'reducers/leftPanel';

const LeftPanel: React.FC<any> = ({ children }) => {
    const dispatch = useDispatch();
    const handleClosePanel = () => {
        dispatch(closeLeftPanel());
    }
    const bind = useOnClickOutside(handleClosePanel, true);
    return (
        <div className="PanelBackground" onClick={closeLeftPanel}>
            <div className="LeftPanel" {...bind}>
                {children}
            </div>
        </div>
    )
}

export { LeftPanel }