import { Store } from './rootReducer';

/* STATE */
type State = {
  signInState: string | null;
};

const initialState: State = {
  signInState: null,
};

/* ACTIONS */
const SET_LINKEDIN_STATE = 'SET_LINKEDIN_STATE';

type Action = { type: typeof SET_LINKEDIN_STATE; payload: string };

/* REDUCER */
export default function reducer(state = initialState, action: Action): State {
  switch (action.type) {
    case SET_LINKEDIN_STATE:
      return {
        ...state,
        signInState: action.payload,
      };
    default:
      return state;
  }
}

/* ACTION CREATORS */
export const setLinkedInState = (payload: any): Action => {
  return {
    type: SET_LINKEDIN_STATE,
    payload,
  };
};

/* SELECTORS */
export const selectLinkedInState = (state: Store): string | null =>
  state.linkedInSignIn.signInState;
