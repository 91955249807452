import { Store } from 'reducers/rootReducer';

export type State = {
  projectId: number | null;
  assessmentId: number | null;
  applicationType: string | null;
};

const initialState: State = {
  projectId: null,
  assessmentId: null,
  applicationType: null,
};

// Actions
const SET_APPLICATION_PROJECT_ID = 'app/application/SET_APPLICATION_PROJECT_ID';
const SET_APPLICATION_ASESSMENT_ID =
  'app/application/SET_APPLICATION_ASESSMENT_ID';
const SET_APPLICATION_TYPE = 'app/application/SET_APPLICATION_TYPE';
const CLEAR_APPLICATION_DATA = 'app/application/CLEAR_APPLICATION_DATA';

// Reducer
type Action =
  | {
      type: typeof SET_APPLICATION_PROJECT_ID;
      payload: number;
    }
  | {
      type: typeof SET_APPLICATION_ASESSMENT_ID;
      payload: number;
    }
  | {
      type: typeof SET_APPLICATION_TYPE;
      payload: string;
    }
  | {
      type: typeof CLEAR_APPLICATION_DATA;
    };

export default function reducer(state = initialState, action: Action): State {
  switch (action.type) {
    case SET_APPLICATION_PROJECT_ID:
      return {
        ...state,
        projectId: action.payload,
      };
    case SET_APPLICATION_ASESSMENT_ID:
      return {
        ...state,
        assessmentId: action.payload,
      };
    case SET_APPLICATION_TYPE:
      return {
        ...state,
        applicationType: action.payload,
      };

    case CLEAR_APPLICATION_DATA:
      return {
        ...state,
        projectId: null,
        assessmentId: null,
        applicationType: null,
      };

    default:
      return state;
  }
}

// Action creators
export const setApplicationProjectId = (payload: number) => ({
  type: SET_APPLICATION_PROJECT_ID,
  payload,
});
export const setApplicationAssessmentId = (payload: number) => ({
  type: SET_APPLICATION_ASESSMENT_ID,
  payload,
});
export const setApplicationType = (payload: string) => ({
  type: SET_APPLICATION_TYPE,
  payload,
});
export const clearApplicationData = () => ({
  type: CLEAR_APPLICATION_DATA,
});

// Selectors
export const selectApplicationProjectId = (app: Store) =>
  app.application.projectId;
export const selectApplicationAssessmentId = (app: Store) =>
  app.application.assessmentId;
export const selectApplicationType = (app: Store) =>
  app.application.applicationType;
