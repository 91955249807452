import { Button } from 'components/button/Button';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { closeModal } from 'reducers/modal';

import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';
import { startBlendedTest } from '../pages/blendedTests/BlendedTestAPI';
import { startAT } from 'components/pages/abilityTests/AbilityTestApi';
import { setInstructionsUnderstood } from 'reducers/assessment';
import { isMobileOnly } from 'react-device-detect';

type ModalProps = {
  data: {
    title: string;
    info: string;
    prongID: number;
    prongCode: string;
  };
};

export const AssessmentBeforeYouBeginModal: React.FC<ModalProps> = ({
  data,
}) => {
  const [generalError, setGeneralError] = useState<string>();

  const dispatch = useDispatch();
  const { t } = useTranslation('Candidate+Journey+-+PQTest', { i18n });

  const getStartTest = (prongCode: string) => {
    switch (prongCode) {
      case 'BlendedTest':
        return startBlendedTest;
      case 'Numerical':
      case 'Verbal':
      case 'Abstract':
      case 'Mechanical':
      case 'Checking':
        return startAT;
      default:
        return () => {
          console.log('Start test function not found.');
        };
    }
  };

  const startTest = getStartTest(data.prongCode);

  useEffect(() => {
    dispatch(setInstructionsUnderstood(false));
  }, []);

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const handleStartAssessment = () => {
    (async () => {
      const result = await startTest({
        assessmentProngID: data.prongID,
      });

      if (result && result.response) {
        setGeneralError('');
        dispatch(setInstructionsUnderstood(true));
      } else {
        setGeneralError(t('pPQInstruction_General_Error'));
      }
    })();
  };

  const modalStyle = {
    '--modalTitleColor': '#036C8F',
    '--footerBackgroundColor': '#f5f5f5',
    '--footerBorderColor': '#f5f5f5',
    '--primaryButtonBackgroundColor': '#36705B',
    '--tertiaryButtonBackgroundColor': '#d5d5d5',
  } as React.CSSProperties;

  return (
    <div
      className={`AssessmentModal BeforeYouBegin ${
        isMobileOnly ? 'Mobile' : ''
      }`}
      style={modalStyle}
    >
      <div className="Content">
        <div
          dangerouslySetInnerHTML={{
            __html: data.info,
          }}
        />
      </div>
      <div className="ModalFooter">
        {generalError && <div className="GeneralError">{generalError}</div>}
        <Button
          className="Rounded"
          priority="tertiary"
          type="button"
          onClick={handleCloseModal}
        >
          Cancel
        </Button>
        <Button
          className="Discard Rounded"
          priority="primary"
          type="submit"
          onClick={handleStartAssessment}
        >
          I understand
        </Button>
      </div>
    </div>
  );
};
