import React from "react";
import classNames from "classnames";
import { Modal } from "components/modal/Modal";

type ButtonVariety = "full" | "small" | "extraSmall" | "blue" | "";

type ButtonColor = "blue";

const DEFAULT_CONFIRM_TITLE = "Confirm";
const DEFAULT_CONFIRM_MESSAGE = "Are you sure you want to take this action?";

type ButtonProps = JSX.IntrinsicElements["button"] & {
  color?: ButtonColor;
  variety?: ButtonVariety;
  withConfirm?: boolean;
  confirmTitle?: string;
  onConfirmOpen?: () => void;
  onConfirmClose?: () => void;
  confirmContent?: string | React.ReactNode;
};

const isString = (
  confirmContent: string | React.ReactNode
): confirmContent is string => {
  return typeof confirmContent === "string";
};

function varietyToClassname(v: ButtonVariety): string {
  switch (v) {
    case "full":
      return "BtnFull";
    case "small":
      return "BtnSmall";
    case "extraSmall":
      return "BtnExtraSmall";
    default:
      return "";
  }
}

function colorToClassname(c?: ButtonColor): string {
  switch (c) {
    case "blue":
      return "BtnBlue";
    default:
      return "";
  }
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      variety = "",
      color,
      className,
      withConfirm,
      onConfirmClose,
      onConfirmOpen,
      confirmTitle = DEFAULT_CONFIRM_TITLE,
      confirmContent = DEFAULT_CONFIRM_MESSAGE,
      onClick,
      ...props
    },
    ref
  ) => {
    const [open, setOpen] = React.useState(false);
    React.useEffect(() => {
      if (open) {
        onConfirmOpen && onConfirmOpen();
      } else {
        onConfirmClose && onConfirmClose();
      }
    }, [open, onConfirmClose, onConfirmOpen]);

    const buttonClassName = classNames(
      "Btn",
      colorToClassname(color),
      varietyToClassname(variety),
      className
    );

    if (withConfirm) {
      const openModal = (e: any) => {
        e.stopPropagation();
        setOpen(true);
      };
      const closeModal = () => {
        setOpen(false);
      };
      const handleConfirm: React.MouseEventHandler<HTMLButtonElement> = e => {
        e.stopPropagation();
        onConfirmClose && onConfirmClose();
        closeModal();
        onClick && onClick(e);
      };
      const confirmButton = (
        <button className="Btn BtnRed" onClick={handleConfirm} {...props}>
          Yes, I'm sure
        </button>
      );
      return (
        <>
          <button
            ref={ref}
            className={buttonClassName}
            onClick={openModal}
            {...props}
          />
          <Modal
            title={confirmTitle}
            open={open}
            closeModal={closeModal}
            actionButton={confirmButton}
          >
            {isString(confirmContent) ? (
              <h4>{confirmContent}</h4>
            ) : (
              confirmContent
            )}
          </Modal>
        </>
      );
    }

    return <button className={buttonClassName} onClick={onClick} {...props} />;
  }
);

export default Button;
