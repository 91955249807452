import React, { useContext, useEffect, useState } from 'react';
import { AbilityPracticeTestQuizContext } from './AbilityPracticeTest';
import {
  AbilityPracticeTestContext,
  AbilityPracticeTestQuizStep,
  AbilityPTAnswerList,
  AbilityPTQuestionList,
  AbilityPTSubmittedAnswers,
  AbilityPTTypeAnswers,
} from './models';
import DOMPurify from 'dompurify';
import { Button } from 'components/button/Button';
import { Spinner } from 'components/spinner/Spinner';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';

export const AbilityPracticeTestQuestions: React.FC = () => {
  const quizContext = useContext(
    AbilityPracticeTestQuizContext
  ) as AbilityPracticeTestContext;
  const [abilityTestId, setAbilityTestId] = useState<number | null>(null);
  const [answerDetails, setAnswerDetails] = useState<
    AbilityPTSubmittedAnswers[] | null
  >(null);
  const [testError, setTestError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation('Ability+Practice+Test+Questions', { i18n });

  const currentQuestionIndex = quizContext.answers.length;

  const currentQuestion =
    quizContext.answers.length < quizContext.quiz.questionTypes.length
      ? quizContext.quiz.questionTypes[currentQuestionIndex]
      : null;

  //set questions
  useEffect(() => {
    setAbilityTestId(currentQuestion?.id!);
  }, [currentQuestion]);

  //set question number links
  useEffect(() => {
    window.addEventListener('resize', qNumLinkMeasure);

    setTimeout(function () {
      qNumLinkMeasure();
    }, 0);
  }, []);

  //set answers
  useEffect(() => {
    if (!answerDetails) {
      const answersList = currentQuestion?.questions.map(function (
        question: AbilityPTQuestionList
      ) {
        return { questionId: question.id, answerId: null, validated: false };
      });

      answersList && setAnswerDetails(answersList);
    }
  }, [currentQuestion, answerDetails]);

  //measure links between question numbers
  const qNumLinkMeasure = () => {
    const questionList: HTMLCollectionOf<Element> =
      document.getElementsByClassName('GroupQuestion');
    const questionListArr: Element[] = Array.from(questionList);

    for (var i = 0; i < questionListArr.length; i++) {
      const thisQuestion: Element = questionList[i];
      const nextQuestion: Element = questionList[i + 1];
      const questionTrail: HTMLCollectionOf<Element> =
        questionList[i].getElementsByClassName('Trail');

      if (
        (window.navigator.userAgent.indexOf('MSIE ') > 0 ||
          !!navigator.userAgent.match(/Trident.*rv\:11\./)) &&
        navigator.userAgent.indexOf('Edge/') !== -1 &&
        questionTrail.length
      ) {
        questionTrail[0].classList.add('ieTrail');
      }

      if (nextQuestion) {
        const questionHeights: number =
          thisQuestion.clientHeight + nextQuestion.clientHeight;
        const thisQuestionQN: HTMLElement = thisQuestion.querySelector(
          '.QuestionNumber'
        ) as HTMLElement;
        const nextQuestionQN: HTMLElement = nextQuestion.querySelector(
          '.QuestionNumber'
        ) as HTMLElement;
        const questionNumHeights: number =
          nextQuestionQN.offsetTop - thisQuestionQN.offsetTop;
        const questionDiff: string = (
          questionHeights -
          questionNumHeights -
          5
        ).toString();
        const nextQuestionTrail: HTMLElement = nextQuestion.querySelector(
          '.Trail'
        ) as HTMLElement;
        //const bodyWidth: number = Math.max(document.documentElement["clientWidth"], document.body["scrollWidth"], document.documentElement["scrollWidth"], document.body["offsetWidth"], document.documentElement["offsetWidth"]);

        nextQuestionTrail.style.height = questionDiff + 'px';
        nextQuestionTrail.style.transform = 'translateY(-100%)';
      }
    }
  };

  //update answers
  const selectedAnswer = (question_id: number, answer_id: number) => {
    const updatedAnswers = answerDetails?.map(function (answer) {
      if (answer.questionId === question_id) {
        answer.answerId = answer_id;
      }

      return answer;
    });

    updatedAnswers && setAnswerDetails(updatedAnswers);
  };

  //highlight selected questions
  const checkQHighlight = (question_id: number) => {
    let questionAnswered = false;

    answerDetails?.map(function (answer) {
      if (answer.questionId === question_id && answer.answerId !== null) {
        questionAnswered = true;
      }

      return answer;
    });

    return questionAnswered;
  };

  //submit answers
  const submitTestAnswers = () => {
    if (currentQuestion) {
      setLoading(true);

      if (validateAnswers()) {
        const answersList: any = [];
        answerDetails?.map(function (answer: AbilityPTSubmittedAnswers) {
          answersList.push({
            questionId: answer.questionId,
            answerId: answer.answerId,
          });

          return answer;
        });
        const currentQAnswer: AbilityPTTypeAnswers = {
          id: abilityTestId,
          questionAnswers: answersList,
        };

        const newQuizAnswers: any = [...quizContext.answers, currentQAnswer];
        quizContext.setAnswers(newQuizAnswers);
        setAnswerDetails(null);

        if (
          quizContext.answers.length + 1 !==
          quizContext.quiz.questionTypes.length
        ) {
          setTimeout(function () {
            setLoading(false);
            qNumLinkMeasure();
          }, 1000);
        }

        if (
          quizContext.answers.length + 1 ===
          quizContext.quiz.questionTypes.length
        ) {
          quizContext.setCurrentQuizStep(AbilityPracticeTestQuizStep.RESULTS);
        }
      }
    }
  };

  //validate answers
  const validateAnswers = () => {
    let passedValidation = true;

    const updatedAnswers = answerDetails?.map(function (answer) {
      if (answer.answerId === null) {
        answer.validated = true;
        passedValidation = false;
      } else {
        answer.validated = false;
      }

      return answer;
    });

    updatedAnswers && setAnswerDetails(updatedAnswers);

    if (!passedValidation) {
      setLoading(false);

      setTestError(true);

      window.scrollTo(0, 0);

      setTimeout(function () {
        qNumLinkMeasure();
      }, 0);
    } else {
      setTestError(false);
    }

    return passedValidation;
  };

  //highlight validated questions
  const checkQValidation = (question_id: number) => {
    let questionValidated = false;

    answerDetails?.map(function (answer) {
      if (answer.questionId === question_id && answer.validated === true) {
        questionValidated = true;
      }

      return answer;
    });

    return questionValidated;
  };

  return (
    <div className="AbilityPracticeTest HeightContent">
      <div className="AbilityPracticeTestContent WidthContent">
        <div className="PracticeTestQuestions">
          {loading ? (
            <Spinner
              color={
                quizContext.isCustomer
                  ? '#429CC'
                  : quizContext.themes.defaultTheme.one
              }
            />
          ) : (
            <React.Fragment>
              {currentQuestion && (
                <React.Fragment>
                  <h1>{currentQuestion.header}</h1>

                  <div className="QuestionInfo">
                    <div className="LeftCol">
                      {currentQuestion && (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                              currentQuestion.content!
                            ),
                          }}
                        ></div>
                      )}
                    </div>
                    <div className="RightCol">
                      {currentQuestion && (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                              currentQuestion.questionIntro!
                            ),
                          }}
                        ></div>
                      )}

                      {testError && (
                        <div className="ValidationMessage1">
                          {t(
                            'SSCandidate_Ability_Practice_Test_Questions_Validation'
                          )}
                        </div>
                      )}

                      <div className="QuestionGroup">
                        {currentQuestion.questions.map(
                          (
                            value: AbilityPTQuestionList,
                            questionIndex: number
                          ) => {
                            return (
                              <div
                                className={
                                  'GroupQuestion' +
                                  (checkQHighlight(value.id)
                                    ? ' Highlighted'
                                    : '') +
                                  (checkQValidation(value.id)
                                    ? ' ValidationHighlighted'
                                    : '')
                                }
                                key={`Test_Q_${questionIndex}`}
                              >
                                <div className="QuestionNumber">
                                  {questionIndex > 0 && (
                                    <div className="Trail"></div>
                                  )}
                                  <div className="Number">
                                    <span>{questionIndex + 1}</span>
                                  </div>
                                </div>
                                <div className="Answers">
                                  <p>
                                    {value.question}{' '}
                                    <span className="Asterisk">*</span>
                                  </p>
                                  <fieldset>
                                    <legend>{questionIndex + 1}</legend>
                                    <div className="AnswerSet">
                                      {value.answers.map(
                                        (
                                          answer: AbilityPTAnswerList,
                                          answerIndex: number
                                        ) => {
                                          return (
                                            <div
                                              className="Answer"
                                              key={`Test_A_${questionIndex}_${answerIndex}`}
                                            >
                                              <div className="RadioButton">
                                                <input
                                                  type="radio"
                                                  name={`Test_Q_${questionIndex}`}
                                                  id={`Test_A_${questionIndex}_${answerIndex}`}
                                                  value={answer.id}
                                                  onClick={() => {
                                                    selectedAnswer(
                                                      value.id,
                                                      answer.id
                                                    );
                                                  }}
                                                />
                                                <label
                                                  className="CustomInput"
                                                  htmlFor={`Test_A_${questionIndex}_${answerIndex}`}
                                                >
                                                  <span className="Inner">
                                                    {answer.answer}
                                                  </span>
                                                </label>
                                              </div>
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  </fieldset>
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>

                      <Button
                        priority="primary"
                        type="submit"
                        onClick={() => {
                          submitTestAnswers();
                        }}
                      >
                        {t(
                          'SSCandidate_Ability_Practice_Test_Questions_Submit'
                        )}
                      </Button>
                    </div>
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};
