import { navigate, RouteComponentProps } from '@reach/router';
import { Button } from 'components/button/Button';
import { TextInput } from 'components/formFields/TextInput';
import { Spinner } from 'components/spinner/Spinner';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openModal } from 'reducers/modal';
import { AccountApi } from './accountApi';
import { IUserAccount } from './models';
import { useGetAccountInfo } from './useGetAccountInfo';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';
import { selectIsAssessmentOnlyCandidate } from 'reducers/auth';

const initialiseValues: IUserAccount = {
  firstName: '',
  lastName: '',
  emailAddress: '',
};
const Account: React.FC<RouteComponentProps> = () => {
  const [initialValues, setInitialValues] =
    useState<IUserAccount>(initialiseValues);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const dispatch = useDispatch();

  const { t } = useTranslation('Account', { i18n });

  const isAssessmentOnlyCandidate = useSelector(
    selectIsAssessmentOnlyCandidate
  );
  const initialise = useGetAccountInfo();
  React.useEffect(() => {
    setLoading(true);
    if (initialise) {
      setInitialValues(initialise);
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [initialise]);

  const handleChangePassword = () => {
    dispatch(
      openModal({
        modalType: 'ACCOUNT_CHANGE_PASSWORD',
        data: {
          title: t('SSCandidate_Account_Change_Password_Modal_Title'),
          buttonText: t(
            'SSCandidate_Account_Change_Password_Modal_Button_Text'
          ),
        },
      })
    );
  };

  const handleAccountDelete = () => {
    dispatch(
      openModal({
        modalType: 'ACCOUNT_DELETE',
        data: {
          title: t('SSCandidate_Account_Account_Delete_Modal_Title'),
          buttonText: t('SSCandidate_Account_Account_Delete_Modal_Button_Text'),
        },
      })
    );
  };

  const handleSubmit = (payload: IUserAccount) => {
    (async () => {
      const result = await AccountApi.updateAccountInfo(payload);
      if (result.data) {
        navigate('/my-joy');
      }
      if (result.error) {
        setErrorMessage(result.error.message[0].messageCode);
      }
    })();
  };

  if (loading) {
    return (
      <div className="PageSpinner">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="PageContent AccountPage">
      <div className="WidthContent HeightContent">
        <div className="AccountForm">
          <h1>{t('SSCandidate_Account_Account')}</h1>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            <Form>
              <TextInput
                name="firstName"
                label={t('SSCandidate_Account_First_Name')}
                labelLocation="left"
                disable={isAssessmentOnlyCandidate}
              />
              <TextInput
                name="lastName"
                label={t('SSCandidate_Account_Last_Name')}
                labelLocation="left"
                disable={isAssessmentOnlyCandidate}
              />
              <TextInput
                name="emailAddress"
                label={t('SSCandidate_Account_Email_Address')}
                labelLocation="left"
                disable={isAssessmentOnlyCandidate}
              />
              <div className="TextInputContainer LabelLeft">
                <label className="Label">
                  {t('SSCandidate_Account_Password')}
                </label>
                <div className="InputErrorContainer">
                  <Button
                    priority="secondary"
                    style={{ width: '100%' }}
                    onClick={handleChangePassword}
                    type="button"
                  >
                    {t('SSCandidate_Account_Change_Password')}
                  </Button>
                  <p className="ErrorText"></p>
                </div>
              </div>
              <Button
                priority="tertiary"
                style={{ width: '100%' }}
                onClick={handleAccountDelete}
                type="button"
              >
                {t('SSCandidate_Account_Delete_Account')}
              </Button>

              {!isAssessmentOnlyCandidate ? (
                <div className="SubmitBtnContainer">
                  <Button
                    priority="primary"
                    type="submit"
                    className="AccountSubmitBtn"
                  >
                    {t('SSCandidate_Account_Save')}
                  </Button>
                  {errorMessage && <p className="ErrorLabel">{errorMessage}</p>}
                </div>
              ) : null}
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default Account;
