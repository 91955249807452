import axios, { AxiosRequestConfig } from 'axios';
import { endpoints } from 'config';
import instance, { ApiResponse } from 'api';
import { get, post } from '../../../helpers/makeEndpointRequest';
import { post as newPost } from '../../../helpers/newMakeEndpointRequest';

type Error = {};

type ApiReturn<T = any> = {
  response: T | null;
  errors: Error[] | null;
};

export const getLiveApplicationDetails = async (params: any) => {
  const url = endpoints.jobApplication.getLiveApplicationDetails;
  return await get(url, params, true);
};

export const getHistoricApplicationDetails = async (params: any) => {
  const url = endpoints.jobApplication.getHistoricApplicationDetails;
  return await get(url, params, true);
};

export const getApplicationWorkflow = async (params: any) => {
  const url = endpoints.jobApplication.getApplicationWorkflow;
  return await get(url, params, true);
};

export const withdrawApplication = async (params: any) => {
  try {
    return instance.post(endpoints.jobApplication.withdrawApplication, params);
  } catch (e) {}
};

export const hideProjectIntroVideo = async (params: any) => {
  try {
    return instance.post(
      endpoints.jobApplication.hideProjectIntroVideo,
      params
    );
  } catch (e) {}
};

export const getWithdrawReasons = async (params: any) => {
  const url = endpoints.jobApplication.getWithdrawReasons;
  return await get(url, params, true);
};

export const cancelBooking = async (params: {
  eventId: number;
}): Promise<ApiReturn> => {
  const url = endpoints.bookings.cancelEvent;
  return await newPost(url, params);
};

export const getAssessmentFAQs = async (params: any) => {
  const url = endpoints.jobApplication.getfaq;
  return await get(url, params, true);
};

export const resendApplicationScores = async (params: {
  projectId: number;
  applicationReference?: string | number;
}): Promise<ApiReturn> => {
  const url = endpoints.jobApplication.resendScores;
  return await newPost(url, params);
};
