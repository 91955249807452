import { Form, Formik } from 'formik';

type ModalProps = {
  data: {
    title: string;
    info: string;
  };
};

export const SJTTestTxtModal: React.FC<ModalProps> = ({ data }) => {
  const modalStyle = {
    '--modalTitleColor': '#036C8F',
    '--footerBackgroundColor': '#f5f5f5',
    '--footerBorderColor': '#f5f5f5',
    '--primaryButtonBackgroundColor': '#36705B',
    '--tertiaryButtonBackgroundColor': '#d5d5d5',
  } as React.CSSProperties;

  return (
    <div className="SJTBeforeYouBeginModal" style={modalStyle}>
      <Formik initialValues={{}} onSubmit={() => {}}>
        <Form>
          <div className="SJTBeforeYouBeginModal__content SJTModalContent">
            <div className="SJTBeforeYouBeginModal__message">
              <div
                dangerouslySetInnerHTML={{
                  __html: data.info,
                }}
              />            
            </div>
          </div>
        </Form>
      </Formik>
    </div>
  );
};
