import i18n from 'i18next';
// NOTE: the import for chained backend plugin must be before other plugins to avoid type errors/conflicts.
import Backend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next';
import axios from 'api';
import Cookies from 'universal-cookie';

import { endpoints } from 'config';

// NOTE: Leave as require, do not import as it introduces noise (Module and .default)
const backupLanguageData = require('./backupTranslationsData/backupTranslationsData_en-GB.json');

const cookies = new Cookies();

let currentLanguage = 'en-GB';
let currentURL = window.location.pathname.split('/').pop();

const publicLanguageEndpoint =
  endpoints.language.getPublicLanguageResources.split('?')[0];

const startOfLanguageEndpoint =
  endpoints.language.getLanguageResources.split('?')[0];

try {
  currentLanguage = cookies.get('cultureInfo');

  if (currentLanguage === undefined) {
    currentLanguage = 'en-GB';
  }
} catch {
  currentLanguage = 'en-GB';
}

type APIResponse = {
  response: any | null;
  errors: any[] | null;
};

type LanguageListReponseDetails = {
  name: string;
  cultureInfo: string;
  imagePath: string;
  imageExtension: string;
  imageURL: string;
}[];

type supportedLanguagesData = {
  name: string;
  cultureInfo: string;
}[];

type SupportedLanguages = string[];

const parseLanguageList = (
  data: LanguageListReponseDetails
): supportedLanguagesData => {
  return data.map((item) => {
    return { name: item.name, cultureInfo: item.cultureInfo };
  });
};

const getLanguageList = async (): Promise<APIResponse> => {
  try {
    const response = await axios.get(endpoints.language.getLanguageList);

    if (
      response.data.status === '1' &&
      response.data.details &&
      response.data.details.length > 0
    ) {
      return {
        response: response.data.details,
        errors: null,
      };
    } else {
      return {
        response: null,
        errors: response.data.errors,
      };
    }
  } catch (err: any) {
    return {
      response: null,
      errors: err,
    };
  }
};

const getSupportedLanguages = async (): Promise<any> => {
  try {
    const data = await getLanguageList();

    if (data.response) {
      const parsedAPIData = parseLanguageList(data.response);

      const supportedLanguages = parsedAPIData.map((item) => {
        return item.cultureInfo;
      });

      initFunction(supportedLanguages);
    } else {
      console.log('Failed To Get Language List');

      initFunction(['en-GB']);
    }
  } catch (error) {
    console.error(error);

    initFunction(['en-GB']);
  }
};

const getLanguageResources = async (
  url: string,
  nameSpace: string
): Promise<APIResponse> => {
  try {
    const response = await axios.get(url, { withCredentials: true });

    if (
      response.data.status === '1' &&
      response.data.details &&
      response.data.details.length > 0
    ) {
      return {
        response: response.data.details,
        errors: null,
      };
    } else {
      return {
        response: null,
        errors: response.data.errors,
      };
    }
  } catch (err: any) {
    return {
      response: null,
      errors: err,
    };
  }
};

type APIData = {
  resourceName: string;
  resourceValue: string;
  pageKey: string;
  cultureInfo: string;
}[];

const getNameSpaceFromURL = (url: string) => {
  // NOTE: This will need to change if the API URL format changes

  // Method 1:
  return url.split('page=')[1].split('&')[0];

  // Method 2:
  // return url.split('?')[1].split('&')[0].split('=')[1];

  // Method 3:
  // const parsedURL = queryString.parse(url.split('?')[1]).page;

  // if (typeof parsedURL === 'string') {
  //   return parsedURL.split(' ').join('+');
  // } else {
  //   return '';
  // }
};

const getParamsFromURL = (url: string) => {
  return url.split('?')[1];
};

type parsedAPIDAata = {};

const parseAPIData = (aPIData: APIData, nameSpace: string): parsedAPIDAata => {
  // TODO: change type from "any"
  let parsedAPIData: any = {};

  aPIData.forEach((data) => {
    parsedAPIData[data.resourceName] = data.resourceValue;
  });

  return parsedAPIData;
};

// url = loadPath
// callback takes (err, res)
const httpBackendOptionsRequest = (
  options: any,
  url: any,
  payload: any,
  callback: any
) => {
  (async () => {
    try {
      // Check this is not for a backup JSON data ID with a local URL
      if (typeof url === 'string' && url.includes(startOfLanguageEndpoint)) {
        const nameSpace = getNameSpaceFromURL(url);

        const accessToken = localStorage.getItem('AT');

        const params = getParamsFromURL(url);

        let finalURL = '';

        if (!accessToken && currentURL !== 'interactive-feedback') {
          finalURL = `${publicLanguageEndpoint}?${params}`;
        } else {
          finalURL =
            currentURL === 'interactive-feedback'
              ? `${publicLanguageEndpoint}?${params}`
              : url;
        }

        // "translation" is react-i18next's default namespace which means we haven't specified our own namespace
        if (nameSpace !== 'translation') {
          const data = await getLanguageResources(finalURL, nameSpace);

          if (data.response && data.response.length > 0) {
            const parsedAPIData = parseAPIData(data.response, nameSpace);

            callback(null, {
              data: JSON.stringify(parsedAPIData),
              status: 200,
            });
          } else {
            // Status 404 is the response from the API for a namespace/page that doesn't match anything
            callback(null, { status: 404 });
          }
        } else {
          // Return an empty data object so it doesn't keep trying to call the API with the "translation" namespace
          callback(null, { status: 200, data: {} });
        }
      } else {
        // Return an empty data object, it will then try resourcesToBackend then parseMissingKeyHandler
        callback(null, { status: 200, data: {} });
      }
    } catch (error) {
      console.error(error);
    }
  })();
};

const httpBackendOptions = {
  loadPath: endpoints.language.getLanguageResources,
  request: httpBackendOptionsRequest,
};

const localStorageBackendOptions = { expirationTime: 1000 * 60 * 60 * 24 };

const resourcesToBackendOptions = { loadPath: '/locales/{{lng}}/{{ns}}.json' };

const backupJSONTranslations = (
  language: string,
  namespace: string,
  callback: any
) => {
  // METHOD 1:
  import(`./backupTranslationsData/backupTranslationsData_${language}.json`)
    .then((resources) => {
      // Replace "+"s with empty spaces in the namespaces
      const parsedNamespace = namespace.replace(/\+/g, ' ');

      callback(null, resources[parsedNamespace]);
    })
    .catch((error) => {
      callback(error, null);
    });

  // METHOD 2:
  // try {
  // // Replace "+"s with empty spaces in the namespaces
  // const parsedNamespace = namespace.replace(/\+/g, ' ');

  //   callback(null, backupLanguageData[parsedNamespace]);
  // } catch (error) {
  //   callback(error, null);
  // }
};

const addBackupDataItemToLocalStorage = (
  parsedKey: string,
  parsedNamespace: string
) => {
  const existingLocalStorage = localStorage.getItem(
    `i18next_res_en-GB-${parsedNamespace}`
  );

  if (typeof existingLocalStorage === 'string') {
    const parsedExistingLocalStorage = JSON.parse(existingLocalStorage);

    const cleanNamespace = parsedNamespace.replace(/\+/g, ' ');

    let backupValue = '';

    if (
      backupLanguageData.hasOwnProperty(cleanNamespace) &&
      backupLanguageData[cleanNamespace].hasOwnProperty(parsedKey)
    ) {
      backupValue = backupLanguageData[cleanNamespace][parsedKey];
    }

    if (backupValue !== '') {
      parsedExistingLocalStorage[parsedKey] = backupValue;

      localStorage.setItem(
        `i18next_res_en-GB-${parsedNamespace}`,
        JSON.stringify(parsedExistingLocalStorage)
      );
    }
    return backupValue;
  } else {
    return '';
  }
};

const getMissingKeyText = (key: string) => {
  const [parsedNamespace, parsedKey] = key.split(':');

  try {
    const backupValue = addBackupDataItemToLocalStorage(
      parsedKey,
      parsedNamespace // don't need to replace "+" with empty spaces yet
    );

    return backupValue;
  } catch (error) {
    console.log(
      'Failed to add backup data item to local storage.',
      `parsedNamespace: "${parsedNamespace}"`
    );

    return '';
  }
};

const initFunction = (supportedLanguagesInput: SupportedLanguages) => {
  i18n
    .use(Backend)
    .use(initReactI18next)
    .init(
      {
        lng: currentLanguage,
        // fallbackLng: 'en-GB',
        // fallbackLng: currentLanguage,
        supportedLngs: supportedLanguagesInput,
        // preload: ['en-GB'],
        // supportedLngs: ['en-GB'],
        // ns: ['ns1', 'ns2'],
        // NOTE: Do we need defaultNS?
        // defaultNS: 'My+Joy',
        react: { useSuspense: true },
        saveMissing: true,
        appendNamespaceToMissingKey: true,
        parseMissingKeyHandler: (key: string) => {
          const missingKeyText = getMissingKeyText(key);

          return missingKeyText;
        },
        // expirationTime = 24 hours
        // default store is window.localstorage
        backend: {
          backends: [
            LocalStorageBackend,
            HttpBackend,
            resourcesToBackend((language, namespace, callback) => {
              backupJSONTranslations('en-GB', namespace, callback);
            }),
          ],

          backendOptions: [
            localStorageBackendOptions,
            httpBackendOptions,
            resourcesToBackendOptions,
          ],
        },
        interpolation: {
          escapeValue: false, // react already safes from xss
          prefix: "[[",
          suffix: "]]",
        },
        // NOTE: set debug to false to stop console logs.
        debug: false,
      },
      (error, t) => {
        if (error) return console.error(error);
      }
    );
};

// INIT: ----------------------------------------------------------

getSupportedLanguages(); // Use if we want to get supportedLanguages from the API

// initFunction(["en-gb", "sv-SE", "fi-FI"]); // Use if we want to hardcode "supportedLanguages".

export default i18n;
