import React, { useState } from 'react';
import { InteractiveFeedbackMenuCardHub } from '../components/InteractiveFeedbackMenuCardHub';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';
import { Spinner } from 'components/spinner/Spinner';
import ErrorFallback from 'components/fallbacks/ErrorFallback';
import {
  MyCapabilitiesCardInnerOptions,
  MyCapabilitiesCardOptions,
  MyCapabilitiesMenuOptions,
  useGetMyCapabilitiesDetails,
} from '../hooks/useGetMyCapabilitiesDetails';
import useMedia from 'use-media';

type InteractiveFeedbackMyCapabilitiesProps = {
  sectionData: any;
};

export const InteractiveFeedbackMyCapabilities: React.FC<InteractiveFeedbackMyCapabilitiesProps> = ({
  sectionData,
}) => {
  const [innerRowName, setInnerRowName] = useState<string | null>(null);
  const [animateDismount, setAnimateDismount] = useState(false);
  const [alternateView, setAlternateView] = useState(false);
  const isTablet = useMedia('(max-width: 768px)');

  const { t } = useTranslation('Interactive+Feedback', { i18n });

  const [data, loading, errors] = useGetMyCapabilitiesDetails(
    setInnerRowName,
    setAnimateDismount
  );

  if (loading || data === undefined || data === null) return <Spinner />;

  if (errors) return <ErrorFallback />;

  const { capabilityMenuOptions, sjtMenuOptionsList, capabilityMenuCards } =
    data as {
      capabilityMenuOptions: MyCapabilitiesMenuOptions;
      sjtMenuOptionsList: Record<string, MyCapabilitiesMenuOptions>;
      capabilityMenuCards: MyCapabilitiesCardOptions;
    };

  const defaultCard = capabilityMenuCards.sjtCards.find(
    ({ heading }) => heading === innerRowName
  );
  if (defaultCard) {
    defaultCard.noButtons = true;
  }

  return (
    <div className="InteractiveFeedbackMyCapabilities WidthContent HeightContent">
      {((!alternateView && !isTablet) || !innerRowName) && (
        <InteractiveFeedbackMenuCardHub
          title={
            capabilityMenuOptions.rows?.length > 0
              ? t('SSCandidate_Interactive_Feedback_My_Capabilities')
              : ''
          }
          cardDetails={capabilityMenuCards.capabilityCards}
          rows={capabilityMenuOptions.rows}
          setAnimateDismount={setAnimateDismount}
          animateDismount={animateDismount}
          onAnimationEnd={() => {
            setAlternateView(true);
          }}
          hideStenScores={true}
          sectionData={sectionData}
        />
      )}
      {(isTablet || alternateView) &&
        innerRowName &&
        sjtMenuOptionsList[innerRowName] &&
        sjtMenuOptionsList[innerRowName].cards && (
          <InteractiveFeedbackMenuCardHub
            title={innerRowName}
            cardDetails={
              sjtMenuOptionsList[innerRowName]
                .cards as MyCapabilitiesCardInnerOptions[]
            }
            headingCloseClickHandler={() => {
              setInnerRowName(null);
              setAlternateView(false);
            }}
            rows={sjtMenuOptionsList[innerRowName].rows}
            defaultCard={defaultCard}
            score={defaultCard?.score}
            setAnimateDismount={setAnimateDismount}
            animateDismount={animateDismount}
            onAnimationEnd={() => {
              setAlternateView(false);
            }}
            reportCode={sjtMenuOptionsList[innerRowName].reportCode}
            assessmentID={sjtMenuOptionsList[innerRowName].assessmentID}
            hideStenScores={true}
            sectionData={sectionData}
          ></InteractiveFeedbackMenuCardHub>
        )}
    </div>
  );
};
