import { FieldAttributes, useField } from 'formik';
import React from 'react';

// useField field object type has values that aren't in HTMLInputField props.
// We create an intersection type so we can spread them on our <input /> element.
type FormikInputField = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> &
  FieldAttributes<string>;

type CheckboxInputProps = {
  label: string;
  isBordered?: boolean;
} & FieldAttributes<{}>;

export const CheckboxInput: React.FC<CheckboxInputProps> = ({
  label,
  isBordered = false,
  ...props
}) => {
  const [field] = useField(props);
  const checked = field.value;

  const containerClass = `CheckboxFieldContainer ${checked ? 'Active' : ''} ${
    isBordered ? 'Bordered' : ''
  } ${props.className ? props.className : ''}`;
  return (
    <div className={containerClass}>
      <label className="Label">
        <input
          {...(field as FormikInputField)}
          checked={checked}
          type="checkbox"
          className="CheckboxInput"
          name={field.name}
          onChange={props.onChange ? props.onChange : field.onChange}
          onClick={props.onClick ? props.onClick : () => {}}
        />
        <span className="Checkmark"></span>
        {label}
      </label>
    </div>
  );
};
