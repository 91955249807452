import { Button } from 'components/button/Button';
import { ProgressIndicator } from 'components/progressIndicator/progressIndicator';
import { Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { signOut } from 'reducers/auth';
import { closeModal } from 'reducers/modal';
import useCountdown from './useCountdown';

export const SJTTimeoutModal = () => {
  const dispatch = useDispatch();

  const initialTime = 300;

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const handleCloseAndSignOut = () => {
    handleCloseModal();
    dispatch(signOut());
  };

  const onTimeEnd = () => {
    handleCloseAndSignOut();
  };

  const { minutes, seconds, remainingSeconds } = useCountdown({
    initialSeconds: initialTime,
    onTimeEnd,
  });

  return (
    <div className="SJTTimeoutModal">
      <Formik initialValues={{}} onSubmit={() => {}}>
        <Form>
          <div className="SJTTimeoutModal__content SJTModalContent">
            <div className="SJTTimeoutModal__timeoutCountdown">
              <ProgressIndicator
                value={remainingSeconds}
                total={initialTime}
                radius={50}
                strokeColor="#429CCC"
                overrideText={`${minutes}:${
                  seconds < 10 ? `0${seconds}` : seconds
                }`}
              />
            </div>
            <div className="SJTTimeoutModal__message">
              You haven't used your session for a while, we will be logging you
              out soon
            </div>
          </div>
          <div className="ModalFooter">
            <Button
              priority="tertiary"
              type="button"
              onClick={handleCloseAndSignOut}
            >
              Sign out
            </Button>
            <Button
              className="Discard"
              priority="primary"
              type="submit"
              onClick={handleCloseModal}
            >
              Stay
            </Button>
          </div>
        </Form>
      </Formik>
    </div>
  );
};
