import axios from 'axios';
import { endpoints } from 'config';
import { get, post } from '../../../helpers/newMakeEndpointRequest';
import { format } from 'date-fns';

type RecentChat = {
  chatsId: number;
  title: string;
  description: string;
  imageUrl: string;
  lastActivityDate: string;
  lastMessageContent: string;
  bRequireParticipantAcceptance: boolean;
  unreadMessageCount: 0;
  status: string;
  participatingSiteUsersIds: number[];
};

type RecentChats = RecentChat[];

type ChatDetailsAttachments = {
  fileName: string;
  displayFileName: string;
};

type ChatDetailsMessages = {
  senderCommunicationsModuleIdentifier: number;
  senderName: string;
  content: string;
  type: string;
  addDate: string;
  attachments: ChatDetailsAttachments[];
  bUnread: boolean;
};

type ChatDetailsSenders = {
  senderCommunicationsModuleIdentifier: number;
  sendersLink: 'string';
};

type ChatDetails = {
  chatsId: number;
  communicationsModuleIdentifier: number;
  title: string;
  description: string;
  imageUrl: string;
  status: string;
  messages: ChatDetailsMessages[];
  senders: ChatDetailsSenders[];
  participatingSiteUsersIds: number[];
};

type UnreadMessageCount = {
  chatsId: number;
  count: number;
};

export const getRecentChat = async (params?: {}) => {
  const url = endpoints.messages.recentChats;
  return await get(url, params);
};

export type ParsedRecentChats = {
  chatsId: number;
  title: string;
  date: string;
  lastMessage: string;
};

export const parseGetRecentChats = (
  details: RecentChats
): ParsedRecentChats[] => {
  if (details && Array.isArray(details)) {
    return details.map(
      ({ chatsId, title, lastActivityDate, lastMessageContent }) => ({
        chatsId,
        title,
        date: format(new Date(lastActivityDate), `dd/MM/yyyy  h:mmaa`),
        lastMessage: lastMessageContent,
      })
    );
  } else {
    return [];
  }
};

type getChatDetailsProps = {
  ChatsId: number;
};

export const getChatDetails = async (params: getChatDetailsProps) => {
  const url = endpoints.messages.details;
  return await get(url, params);
};

export type ParsedGetChatDetails = {
  heading: string;
  subheading: string;
  messages: { date: string; message: string; senderName: string }[];
};

export const parseGetChatDetails = (
  details: ChatDetails
): ParsedGetChatDetails => {
  return {
    heading: details.title,
    subheading: details.description,
    messages:
      details.messages && Array.isArray(details.messages)
        ? details.messages.map(({ addDate, content, senderName }) => ({
            date: format(new Date(addDate), 'dd/MM/yyyy h:mmaa'),
            message: content,
            senderName,
          }))
        : [],
  };
};

// export const sendMessage = async ({ chatsId, Content, bSmsPush, Attachments }) => {
//     const formData = new FormData();
//     formData.append('chatsId', chatsId);
//     formData.append('Content', Content);
//     formData.append('bSmsPush', bSmsPush);
//     formData.append('Attachments', Attachments);

//     let configAndParams = helpers.axiosConfig();
//     configAndParams = {
//         ...configAndParams,
//         headers: { ...configAndParams.headers, 'Content-Type': 'multipart/form-data' },
//     };
//     const result = await axios.post(
//         config.endPoints.communicationsSendMessage,
//         formData,
//         configAndParams
//     );
//     console.log(result);
//     if (result.data.status === '1') {
//         return {
//             response: result.data.details,
//             errors: null,
//         };
//     } else {
//         return {
//             response: null,
//             errors: result.errors,
//         };
//     }
// };

// export const parseSendMessagePayload = (details, chatsId) => {
//   return {
//     chatsId,
//     Content: details.newMessage,
//     bSmsPush: details.pushToSMS,
//     Attachments: [],
//   };
// };

type getUnreadMessageCountParams = {
  MaxChatCount?: number;
};

export const getUnreadMessageCount = async (
  params: getUnreadMessageCountParams
) => {
  const url = endpoints.messages.getUnreadMessageCount;
  return await get(url, params);
};

export const parseGetUnreadMessageCount = (details: any) => {  
  if (!Array.isArray(details)) return 0;
  return details
    .map(({ count }) => count)
    .reduce((currCount, nextCount) => currCount + nextCount, 0);
};

export const getCandidateConsentDetails = async (params: {
  HiringManagerSearchCandidateInterestId: number;
}) => {
  const url = endpoints.chats.getCandidateConsentDetails;
  return await get(url, params);
};

export const getHiringManagerProfileDetails = async (params: {
  HiringManagerProfileId: number;
}) => {
  const url = endpoints.dashboard.getHiringManagerProfileDetails;
  return await get(url, params);
};

export const updateCandidateConsent = async (params: {
  consentToShareData: boolean;
  hiringManagerSearchCandidateInterestId: number;
}) => {
  const url = endpoints.chats.updateCandidateConsent;
  return await post(url, params);
};
