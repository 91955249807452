import Step1Img from 'assets/apply.clevry/Img_FindYourJoy_01.png';
import Step2Img from 'assets/apply.clevry/Img_FindYourJoy_02.png';
import Step3Img from 'assets/apply.clevry/Img_FindYourJoy_03.png';
import Step4Img from 'assets/apply.clevry/Img_FindYourJoy_04.png';
import ProfileImage from 'assets/new_skin/Img_NewFindYourJoy_03.png';
import { HorizontalBarChart } from 'components/horizontalBarChart/HorizontalBarChart';
import { PublicModal } from 'components/publicModal/PublicModal';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactWordcloud, { Word } from 'react-wordcloud';
import { selectAuthStatus } from 'reducers/auth';
import useMedia from 'use-media';
import { FormType } from '../signIn/Landing';
import { SignInForm } from '../signIn/SignInForm';
import { SignUpForm } from '../signUp/SignUpForm';
import { FindYourJoyQuizContext } from './FindYourJoy';
import { FindYourJoyApi } from './findYourJoyApi';
import { FindYourJoyJourneyPrompt } from './FindYourJoyJourneyPrompt';
import { LabeledValue } from './models';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';

const stepImages = [Step1Img, Step2Img, Step3Img, Step4Img];

enum DisplayedForm {
  SIGN_IN = 1,
  SIGN_UP,
}

export const FindYourJoyResults: React.FC = () => {
  const authStatus = useSelector(selectAuthStatus);
  const findYourJoyContext = useContext(FindYourJoyQuizContext);
  const [wordcloudData, setWordcloudData] = useState<Word[] | null>(null);
  const [profileResultsData, setProfileResultsData] = useState<
    (LabeledValue & { description: string })[] | null
  >(null);

  const [currentlyOpenForm, setCurrentlyOpenForm] = useState<FormType | null>(
    null
  );
  const [highestScoringProfile, setHighestScoringProfile] = useState<
    (LabeledValue & { description: string }) | null
  >(null);

  const { t } = useTranslation('Find+Your+Joy+Results', { i18n });

  useEffect(() => {
    if (findYourJoyContext?.answers) {
      FindYourJoyApi.fetchResults(
        findYourJoyContext.answers,
        findYourJoyContext.quiz.softSkillsQuizId
      ).then((response) => {
        if (response.data.status === '1') {
          const results = FindYourJoyApi.parseResults(response.data.details);
          setWordcloudData(results.wordcloud);
          setProfileResultsData(results.profile);
          setHighestScoringProfile(results.highestScoringProfileItem);
        }
      });
    }
  }, [findYourJoyContext]);

  const isTabletOrBelow = useMedia('(max-width:1024px)');

  return (
    <div className="FindYourJoyResults">
      {wordcloudData && profileResultsData && (
        <>
          <div>
            <h1 className="ResultsTitle">
              {t('SSCandidate_Find_Your_Joy_Results_Title')}
            </h1>
            <div className="Content">
              <h2 className="ResultsSubTitle">
                {t('SSCandidate_Find_Your_Joy_Results_Subtitle')}
              </h2>
              <hr />
              <div className="Section ProfileContainer">
                <h3 className="SectionTitle">
                  {t('SSCandidate_Find_Your_Joy_Results_Wordcloud_Title')}
                </h3>
                <p className="Description">
                  {t('SSCandidate_Find_Your_Joy_Results_Wordcloud_Subtitle')}
                </p>
                <div className="Flex">
                  <div className="Left">
                    <div className="WordCloudContainer">
                      <ReactWordcloud
                        words={wordcloudData}
                        options={{
                          padding: 5,
                          colors: ['white'],
                          rotations: 0,
                          fontSizes: isTabletOrBelow ? [10, 40] : [10, 60],
                          fontWeight: 'bold',
                        }}
                      />
                    </div>
                  </div>
                  <div className="Right">
                    <div className="ProfileBarChartContainer">
                      <HorizontalBarChart data={profileResultsData} />
                    </div>
                  </div>
                </div>
              </div>
              {authStatus === 'signed_in' && <hr />}
              <div
                className={`Section ProfileContainer${
                  authStatus !== 'signed_in' ? ' SignedIn' : ''
                }`}
              >
                <h3 className="SectionTitle Larger">
                  {t('SSCandidate_Find_Your_Joy_Results_Profile_Title')}
                </h3>
                <p className="Description">
                  {t('SSCandidate_Find_Your_Joy_Results_Profile_Subtitle_1')}{' '}
                  {highestScoringProfile?.label}.{' '}
                  {t('SSCandidate_Find_Your_Joy_Results_Profile_Subtitle_2')}
                </p>
                <div className="Flex">
                  <div className="Left">
                    <h2 className="Strength">
                      {highestScoringProfile ? highestScoringProfile.label : ''}
                    </h2>

                    {authStatus === 'signed_in' && (
                      <p className="Description">
                        {highestScoringProfile
                          ? highestScoringProfile.description
                          : ''}
                      </p>
                    )}
                  </div>
                  <div className="Right ImageContainer">
                    <img
                      src={ProfileImage}
                      alt={t(
                        'SSCandidate_Find_Your_Joy_Results_Alt_Profile_Image'
                      )}
                    />
                  </div>
                </div>
              </div>
              {authStatus === 'signed_in' && <hr />}
              <div className="GradientDiv"></div>
            </div>
            {authStatus !== 'signed_in' && (
              <FindYourJoyJourneyPrompt
                setCurrentlyOpenForm={setCurrentlyOpenForm}
              />
            )}
          </div>
          {currentlyOpenForm && (
            <PublicModal
              handleClose={() => setCurrentlyOpenForm(null)}
              className="FormModal"
            >
              {currentlyOpenForm === FormType.SIGN_IN ? (
                <SignInForm
                  switchToSignUp={() => setCurrentlyOpenForm(FormType.SIGN_UP)}
                />
              ) : (
                <SignUpForm
                  switchToSignIn={() => setCurrentlyOpenForm(FormType.SIGN_IN)}
                  hasCompletedQuiz
                />
              )}
            </PublicModal>
          )}
        </>
      )}
    </div>
  );
};
