import React from 'react';
import { CommunicationsModule } from '@avamae/communications-module';
import instance from 'api';
import { BASE_URL, endpoints } from 'config';
import { useSelector, useDispatch } from 'react-redux';

type MessagesCommunicationsModuleProps = {
  selectedChat: number | undefined;
  SMSPush: boolean;
  chatHeaderButtonHandler?: any;
  chatHeaderToggleHandler?: any;
};

const MessagesCommunicationsModule: React.FC<
  MessagesCommunicationsModuleProps
> = ({
  selectedChat,
  SMSPush,
  chatHeaderButtonHandler,
  chatHeaderToggleHandler,
}) => {
  const accessToken = localStorage.getItem('AT');

  const colorSchema = {
    primaryColor: '$MainColor35',
    secondaryColor: '#F5F5F5',
  };

  const allowedAttachments = [
    'text/csv',
    'text/css',
    'application/msword',
    'image/gif',
    'text/html',
    'image/jpeg',
    'audio/mpeg',
    'video/mpeg',
    'image/png',
    'application/vnd.ms-excel',
    'application/zip',
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  ];

  return (
    <>
      <CommunicationsModule
        instance={instance}
        baseURL={BASE_URL}
        accessToken={accessToken}
        signalRBaseURL={`${BASE_URL}/ClevrySoftSkillsChatsHub/?chatsId=`}
        endpoints={{ chats: endpoints.messages }}
        sendMessageFormType={'multipart'}
        sendMessageAdditionalParams={{ bSmsPush: SMSPush }}
        colorSchema={colorSchema}
        videoCall={false}
        phoneCall={false}
        allowAttachments={true}
        attachmentMaxSizeInMB={5}
        attachmentTypesAllowed={allowedAttachments}
        hasContactList={false}
        urlChatId={
          selectedChat
            ? typeof selectedChat === 'string'
              ? parseInt(selectedChat)
              : selectedChat
            : null
        }
        useDispatch={useDispatch}
        useSelector={useSelector}
        chatHeaderButtonHandler={chatHeaderButtonHandler}
        chatHeaderToggleHandler={chatHeaderToggleHandler}
      />
    </>
  );
};

export default React.memo(MessagesCommunicationsModule);
