import { Button } from 'components/button/Button';
import React from 'react';
import { UpdateProfileFooterData } from 'reducers/footer';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';

export const UpdateProfileFooter: React.FC<UpdateProfileFooterData> = ({
  secondaryButtonHandler,
  formikRef,
}) => {
  const { t } = useTranslation('Update+Profile+Footer', { i18n });

  if (formikRef && formikRef.current) {
    return (
      <div className="UpdateProfileFooter WidthContent">
        <Button priority="tertiary" onClick={secondaryButtonHandler}>
          {t('SSCandidate_Update_Profile_Footer_Cancel')}
        </Button>
        <Button
          priority="primary"
          type="submit"
          onClick={() => {
            formikRef.current.submitForm();
          }}
        >
          {t('SSCandidate_Update_Profile_Footer_Save')}
        </Button>
      </div>
    );
  }
  return null;
};
