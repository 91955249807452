import React, { useState } from 'react';
import Select from 'react-select';
import { MenuPlacement, ValueType } from 'react-select/src/types';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';
import { IndicatorSeparator } from 'react-select/src/components/indicators';
import { Tooltip } from './Tooltip';

export type SelectOption = { value: string; label: string };

type DropdownProps = {
  options: { value: string; label: string }[];
  onFocus?: () => void;
  onBlur?: () => void;
  value: any;
  onChange(selectedOption: ValueType<SelectOption, any>): void;
  roundedRectangle?: boolean;
  small?: boolean;
  defaultMenuIsOpen?: boolean;
  isMulti?: boolean;
  readOnly?: boolean;
  dropdownProps?: any;
  placeholder?: string;
  styles?: any;
  showTooltip?: boolean;
  tooltipText?: string;
};

type Props = DropdownProps;

const Dropdown: React.FC<Props> = ({
  options,
  value,
  onChange,
  roundedRectangle,
  small,
  defaultMenuIsOpen,
  isMulti,
  readOnly,
  dropdownProps,
  styles,
  placeholder,
  onFocus = () => {},
  onBlur = () => {},
  showTooltip = false,
  tooltipText,
}) => {
  const { t } = useTranslation('Dropdown', { i18n });

  return (
    <>
      <Select
        className={
          roundedRectangle
            ? small
              ? 'CustomSelect RoundedRect Small'
              : 'CustomSelect RoundedRect'
            : small
            ? 'CustomSelect Small'
            : 'CustomSelect'
        }
        defaultMenuIsOpen={defaultMenuIsOpen}
        classNamePrefix={readOnly ? 'CustomSelect Disabled' : 'CustomSelect'}
        options={options}
        value={value}
        isDisabled={readOnly}
        onChange={onChange}
        isMulti={isMulti}
        menuPlacement="auto"
        onBlur={onBlur}
        onFocus={onFocus}
        placeholder={
          placeholder ? placeholder : t('SSCandidate_Dropdown_Select')
        }
        styles={styles}
        components={showTooltip && { IndicatorSeparator: Tooltip }}
        tooltipText={tooltipText}
        {...dropdownProps}
      />
    </>
  );
};

export { Dropdown };
