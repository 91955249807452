import { Button } from 'components/button/Button';
import { Spinner } from 'components/spinner/Spinner';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectWelcomeVideoUrl } from 'reducers/auth';
import { closeModal } from 'reducers/modal';

type DashboardModalProps = {
  data: { buttonText: string; video: string };
};

const DashboardModal: React.FC<DashboardModalProps> = ({ data }) => {
  const dispatch = useDispatch();
  const welcomeVideoUrl = useSelector(selectWelcomeVideoUrl);

  const handleCloseModal = () => {
    document.body.style.overflow = '';
    dispatch(closeModal());
  };
  return (
    <div className="DashboardModal">
      <div className="Content">
        {data.video && data.video.length > 0 ? (
          <video controls>
            <source src={data.video} type="video/mp4" />
          </video>
        ) : (
          <Spinner />
        )}
      </div>
      <div className="ModalFooter">
        <Button priority="tertiary" onClick={handleCloseModal}>
          Cancel
        </Button>
        <Button priority="primary" onClick={handleCloseModal}>
          {data.buttonText}
        </Button>
      </div>
    </div>
  );
};

export { DashboardModal };
