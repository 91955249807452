import i18n from 'i18n/i18n';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { closeRightPanel } from 'reducers/rightPanel';
import defaultIcon from 'assets/apply.clevry/Img_ProfileEmpty.jpg';
import { Spinner } from 'components/spinner/Spinner';
import { Button } from 'components/button/Button';
import { getHiringManagerProfileDetails } from 'components/pages/messages/MessagesApi';
import { RouteComponentProps } from '@reach/router';

type MatchingConnectionReviewSidePanelProps = {
  data: { HiringManagerProfileId: number };
};

type HiringManagerProfileDetails = {
  hiringManagerFullName: string;
  companyName: string;
  emailAddress: string;
  mobileNumber: string;
  location: string;
  myRoleDescription: string;
  teamDescription: string;
  prioritiesAndFuturePlansDescription: string;
  profilePictureURL: string;
};

const HiringManagerProfileSidePanel: React.FC<
  MatchingConnectionReviewSidePanelProps
> = ({ data }) => {
  const { t } = useTranslation('Hiring+Manager+Profile+Side+Panel', { i18n });
  const dispatch = useDispatch();

  const [loading, setLoading] = useState<boolean>(false);
  const [details, setDetails] = useState<HiringManagerProfileDetails | null>(
    null
  );
  const [error, setError] = useState('');
  let HiringManagerProfileId: number | null = null;
  if (data?.HiringManagerProfileId) {
    HiringManagerProfileId = data?.HiringManagerProfileId;
  }

  const handleClosePanel = () => {
    dispatch(closeRightPanel());
  };

  const fetchDetails = async (HiringManagerProfileId: number) => {
    const result = await getHiringManagerProfileDetails({
      HiringManagerProfileId,
    });
    if (result?.response) {
      setLoading(false);
      setDetails(result.response);
    } else {
      setLoading(false);
      setError(t('SSCandidate_Hiring_Manager_Profile_Side_Panel_Error'));
    }
  };

  useEffect(() => {
    if (HiringManagerProfileId) {
      (async () => {
        fetchDetails(HiringManagerProfileId);
      })();
    }
  }, [HiringManagerProfileId]);

  if (loading) return <Spinner />;

  return (
    <>
      <div className="HiringManagerProfileSidePanel WidthContent HeightContent">
        {details && (
          <div className="HiringManagerProfileTop">
            <div className="HiringManagerProfilePic">
              <img
                src={
                  details?.profilePictureURL
                    ? details.profilePictureURL
                    : defaultIcon
                }
                alt="Hiring manager avatar"
              ></img>
            </div>
            <div className="HiringManagerProfileDetails">
              <div className="HiringManagerProfileTitle">
                <span className="HiringManagerName">
                  {!details?.hiringManagerFullName ||
                  details?.hiringManagerFullName === 'null'
                    ? ''
                    : details?.hiringManagerFullName}
                </span>
                {details?.companyName && (
                  <span className="HiringManagerCompany">{`works for ${details?.companyName}`}</span>
                )}
              </div>
              <div className="HiringManagerProfileBio1">
                <span>
                  {!details?.emailAddress || details?.emailAddress === 'null'
                    ? ''
                    : details?.emailAddress}
                </span>
                <span>
                  {!details?.mobileNumber || details?.mobileNumber === 'null'
                    ? ''
                    : details?.mobileNumber}
                </span>
              </div>
              <div className="HiringManagerProfileBio2">
                {!details?.location || details?.location === 'null'
                  ? ''
                  : details?.location}
              </div>
            </div>
          </div>
        )}

        <div className="HiringManagerProfileSummary">
          {details?.myRoleDescription &&
            details?.myRoleDescription !== 'null' && (
              <div className="HiringManagerProfileItem">
                <span className="HiringManagerProfileItem__Heading">
                  {t('SSCandidate_Hiring_Manager_Profile_Role_Description')}
                </span>
                {details?.myRoleDescription}
              </div>
            )}
          {details?.teamDescription && details?.teamDescription !== 'null' && (
            <div className="HiringManagerProfileItem">
              <span className="HiringManagerProfileItem__Heading">
                {t('SSCandidate_Hiring_Manager_Profile_Team_Description')}
              </span>
              {details?.teamDescription}
            </div>
          )}
          {details?.prioritiesAndFuturePlansDescription &&
            details?.prioritiesAndFuturePlansDescription !== 'null' && (
              <div className="HiringManagerProfileItem">
                <span className="HiringManagerProfileItem__Heading">
                  {t(
                    'SSCandidate_Hiring_Manager_Profile_Priorities_Future_Plans_Description'
                  )}
                </span>
                {details?.prioritiesAndFuturePlansDescription}
              </div>
            )}
        </div>
      </div>

      <div className="PanelFooter">
        <div className="Error">{error && error}</div>
        <Button
          className="Accept_Share"
          priority="primary"
          type="submit"
          onClick={handleClosePanel}
        >
          {t('SSCandidate_Hiring_Manager_Profile_Side_Panel_Close')}
        </Button>
      </div>
    </>
  );
};

export default HiringManagerProfileSidePanel;
