import { useState, useCallback, useEffect } from 'react';
import {
  InteractiveFeedbackCardProps,
  InteractiveFeedbackCardType,
} from '../components/InteractiveFeedbackCard';
import { InteractiveFeedbackMenuProps } from '../components/InteractiveFeedbackMenu';
import {
  getLeadershipStrengthsScores,
  GetMyLeadershipStrengthsScoresDetails,
  GetMyStrengthScoresDetails,
  GetMyTeamStrengthsScoresDetails,
  getStrengthScores,
  getTeamStrengthsScores,
} from '../InteractiveFeedbackApi';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';
import PeopleImage from 'assets/apply.clevry/IF_MyLeadershipStrengths_StrengthArea_01.jpg';
import TasksImage from 'assets/apply.clevry/IF_MyLeadershipStrengths_StrengthArea_02.jpg';
import WayImage from 'assets/apply.clevry/IF_MyLeadershipStrengths_StrengthArea_04.jpg';
import YourselfImage from 'assets/apply.clevry/IF_MyLeadershipStrengths_StrengthArea_03.jpg';

import AchievementImage from 'assets/apply.clevry/IF_MyLeadershipStrengths_StrengthDesc_06.jpg';
import ConfidenceSelfBeliefImage from 'assets/apply.clevry/IF_MyLeadershipStrengths_StrengthDesc_07.jpg';
import ConnectingNetworkingImage from 'assets/apply.clevry/IF_MyLeadershipStrengths_StrengthDesc_05.jpg';
import CopingPressureImage from 'assets/apply.clevry/IF_MyLeadershipStrengths_StrengthDesc_03.jpg';
import DecisionMakingAmbiguityImage from 'assets/apply.clevry/IF_MyLeadershipStrengths_StrengthDesc_02.jpg';
import EthicalStanceImage from 'assets/apply.clevry/IF_MyLeadershipStrengths_StrengthDesc_10.jpg';
import ImpactImage from 'assets/apply.clevry/IF_MyLeadershipStrengths_StrengthDesc_11.jpg';
import LearningDevelopingImage from 'assets/apply.clevry/IF_MyLeadershipStrengths_StrengthDesc_12.jpg';
import LookingAheadImage from 'assets/apply.clevry/IF_MyLeadershipStrengths_StrengthDesc_08.jpg';
import OpennessToChangeImage from 'assets/apply.clevry/IF_MyLeadershipStrengths_StrengthDesc_04.jpg';
import SupportingOthersImage from 'assets/apply.clevry/IF_MyLeadershipStrengths_StrengthDesc_09.jpg';
import WorkingWithOthersImage from 'assets/apply.clevry/IF_MyLeadershipStrengths_StrengthDesc_01.jpg';
import { selectDashboardInfo } from 'reducers/dashboardInfo';
import { selectAuthStatus, selectRedirectValues } from 'reducers/auth';
import { useSelector } from 'react-redux';
import { selectAssessmentId } from 'reducers/virtualAssessments';

export type MyLeadershipStrengthsCardInnerOptions = Pick<
  InteractiveFeedbackCardProps,
  'bottomColor' | 'cardHTML' | 'heading' | 'type' | 'noButtons'
> & { topImage?: string };

export type MyLeadershipStrengthsCardOptions = {
  leadershipStrengthsCards: MyLeadershipStrengthsCardInnerOptions[];
};
export type MyLeadershipStrengthsGraphCardOptions = {
  leadershipStrengthsGraphCards: MyLeadershipStrengthsCardInnerOptions[];
};

export type ReportDownloadFlags = {
  isReportDownloadable?: boolean;
};

export const useGetMyLeadershipStrengthsDetails = () => {
  const { t } = useTranslation('Interactive+Feedback', { i18n });
  const [data, setData] = useState<{
    leadershipStrengthsMenuCards: MyLeadershipStrengthsCardOptions;
    leadershipStrengthsGraphCards: MyLeadershipStrengthsGraphCardOptions;
    leadershipStrengthsWheelImage: string;
    reportCode: string;
    isCandidateReport: boolean;
    reportDownloadFlags: ReportDownloadFlags;
  } | null>(null);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState(false);
  const redirectValues = useSelector(selectRedirectValues);
  const dashboardInfo = useSelector(selectDashboardInfo);
  const myHistoryRouteAssessmentId = useSelector(selectAssessmentId);
  const authStatus = useSelector(selectAuthStatus);

  let cultureCode: string | null = null;
  if (dashboardInfo && dashboardInfo.cultureCode) {
    cultureCode = dashboardInfo.cultureCode;
  }

  let assessmentId: number | null = null;
  if (redirectValues && redirectValues.length > 0) {
    assessmentId = redirectValues.find(
      ({ label, value }) => label === 'AssessmentID'
    )?.value;
  } else if (myHistoryRouteAssessmentId) {
    assessmentId = myHistoryRouteAssessmentId;
  }

  const parseStrengthMenuCardOptions = () => {
    const leadershipStrengthsCards = [
      {
        type: InteractiveFeedbackCardType.IMAGE,
        heading: t('SSCandidate_Interactive_Feedback_Leading_People'),
        color: '#31C3EC',
        borderColor: '#205859',
        bottomColor: '#205859',
        topImage: PeopleImage,
        cardHTML: t(
          'SSCandidate_Interactive_Feedback_Leading_People_Description'
        ),
      },
      {
        type: InteractiveFeedbackCardType.IMAGE,
        heading: t('SSCandidate_Interactive_Feedback_Leading_Tasks'),
        color: '#31C3EC',
        borderColor: '#6FA0A7',
        bottomColor: '#6FA0A7',
        topImage: TasksImage,
        cardHTML: t(
          'SSCandidate_Interactive_Feedback_Leading_Tasks_Description'
        ),
      },
      {
        type: InteractiveFeedbackCardType.IMAGE,
        heading: t('SSCandidate_Interactive_Feedback_Leading_Yourself'),
        color: '#31C3EC',
        borderColor: '#11A6BC',
        bottomColor: '#11A6BC',
        topImage: YourselfImage,
        cardHTML: t(
          'SSCandidate_Interactive_Feedback_Leading_Yourself_Description'
        ),
      },
      {
        type: InteractiveFeedbackCardType.IMAGE,
        heading: t('SSCandidate_Interactive_Feedback_Leading_Way'),
        color: '#31C3EC',
        borderColor: '#6BA3C4',
        bottomColor: '#6BA3C4',
        topImage: WayImage,
        cardHTML: t('SSCandidate_Interactive_Feedback_Leading_Way_Description'),
      },
    ];

    return { leadershipStrengthsCards };
  };

  const parseGraphCardOptions = (
    details: GetMyLeadershipStrengthsScoresDetails
  ) => {
    const MyStrengthsScaleMaps = {
      Achievement: {
        color: '#31C3EC',
        borderColor: '#6FA0A7',
        bottomColor: '#6FA0A7',
        topImage: AchievementImage,
        cardHTML: t('SSCandidate_Interactive_Feedback_Achievement_Description'),
      },
      ConfidenceAndSelfBelief: {
        color: '#31C3EC',
        borderColor: '#11A6BC',
        bottomColor: '#11A6BC',
        topImage: ConfidenceSelfBeliefImage,
        cardHTML: t(
          'SSCandidate_Interactive_Feedback_Confidence_Self_Belief_Description'
        ),
      },
      ConnectingAndNetworking: {
        color: '#EACD60',
        borderColor: '#205859',
        bottomColor: '#205859',
        topImage: ConnectingNetworkingImage,
        cardHTML: t(
          'SSCandidate_Interactive_Feedback_Connecting_Networking_Description'
        ),
      },
      CopingWithPressure: {
        color: '#EACD60',
        borderColor: '#11A6BC',
        bottomColor: '#11A6BC',
        topImage: CopingPressureImage,
        cardHTML: t(
          'SSCandidate_Interactive_Feedback_Coping_With_Pressure_Description'
        ),
      },
      DecisionMakingAndAmbiguity: {
        color: '#732571',
        borderColor: '#6FA0A7',
        bottomColor: '#6FA0A7',
        topImage: DecisionMakingAmbiguityImage,
        cardHTML: t(
          'SSCandidate_Interactive_Feedback_Decision_Making_Ambiguity_Description'
        ),
      },
      EthicalStance: {
        color: '#732571',
        borderColor: '#6FA0A7',
        bottomColor: '#6FA0A7',
        topImage: EthicalStanceImage,
        cardHTML: t(
          'SSCandidate_Interactive_Feedback_Ethical_Stance_Description'
        ),
      },
      Impact: {
        color: '#4FAC80',
        borderColor: '#11A6BC',
        bottomColor: '#11A6BC',
        topImage: ImpactImage,
        cardHTML: t('SSCandidate_Interactive_Feedback_Impact_Description'),
      },
      LearningAndDeveloping: {
        color: '#146594',
        borderColor: '#6BA3C4',
        bottomColor: '#6BA3C4',
        topImage: LearningDevelopingImage,
        cardHTML: t(
          'SSCandidate_Interactive_Feedback_Learning_Developing_Description'
        ),
      },
      LookingAhead: {
        color: '#146594',
        borderColor: '#6BA3C4',
        bottomColor: '#6BA3C4',
        topImage: LookingAheadImage,
        cardHTML: t(
          'SSCandidate_Interactive_Feedback_Looking_Ahead_Description'
        ),
      },
      OpennessToChange: {
        color: '#146594',
        borderColor: '#6BA3C4',
        bottomColor: '#6BA3C4',
        topImage: OpennessToChangeImage,
        cardHTML: t(
          'SSCandidate_Interactive_Feedback_Openness_To_Change_Description'
        ),
      },
      SupportingOthers: {
        color: '#146594',
        borderColor: '#205859',
        bottomColor: '#205859',
        topImage: SupportingOthersImage,
        cardHTML: t(
          'SSCandidate_Interactive_Feedback_Supporting_Others_Description'
        ),
      },
      WorkingWithOthers: {
        color: '#146594',
        borderColor: '#205859',
        bottomColor: '#205859',
        topImage: WorkingWithOthersImage,
        cardHTML: t(
          'SSCandidate_Interactive_Feedback_Working_With_Others_Description'
        ),
      },
    };

    let orderedLeadershipCards = []; // The order of scores returned by endpoint are alphabetised by leadership code, so needs to be arranged to match the order in the return graph image (starting from 1st segment of top-right corner)

    for (let i = 0; i < details.scores.length; i++) {
      if (details.scores[i].leadershipCode === 'Achievement') {
        orderedLeadershipCards[1] = details.scores[i];
      }
      if (details.scores[i].leadershipCode === 'ConfidenceAndSelfBelief') {
        orderedLeadershipCards[4] = details.scores[i];
      }
      if (details.scores[i].leadershipCode === 'ConnectingAndNetworking') {
        orderedLeadershipCards[10] = details.scores[i];
      }
      if (details.scores[i].leadershipCode === 'CopingWithPressure') {
        orderedLeadershipCards[3] = details.scores[i];
      }
      if (details.scores[i].leadershipCode === 'DecisionMakingAndAmbiguity') {
        orderedLeadershipCards[0] = details.scores[i];
      }
      if (details.scores[i].leadershipCode === 'EthicalStance') {
        orderedLeadershipCards[2] = details.scores[i];
      }
      if (details.scores[i].leadershipCode === 'Impact') {
        orderedLeadershipCards[5] = details.scores[i];
      }
      if (details.scores[i].leadershipCode === 'LearningAndDeveloping') {
        orderedLeadershipCards[8] = details.scores[i];
      }
      if (details.scores[i].leadershipCode === 'LookingAhead') {
        orderedLeadershipCards[7] = details.scores[i];
      }
      if (details.scores[i].leadershipCode === 'OpennessToChange') {
        orderedLeadershipCards[6] = details.scores[i];
      }
      if (details.scores[i].leadershipCode === 'SupportingOthers') {
        orderedLeadershipCards[11] = details.scores[i];
      }
      if (details.scores[i].leadershipCode === 'WorkingWithOthers') {
        orderedLeadershipCards[9] = details.scores[i];
      }
    }

    const leadershipStrengthsGraphCards = orderedLeadershipCards.map(
      ({ name, leadershipCode }) => ({
        type: InteractiveFeedbackCardType.IMAGE,
        heading: name,
        cardHTML:
          MyStrengthsScaleMaps[
            leadershipCode as keyof typeof MyStrengthsScaleMaps
          ].cardHTML,
        bottomColor:
          MyStrengthsScaleMaps[
            leadershipCode as keyof typeof MyStrengthsScaleMaps
          ].bottomColor,
        topImage:
          MyStrengthsScaleMaps[
            leadershipCode as keyof typeof MyStrengthsScaleMaps
          ].topImage,
      })
    );

    return { leadershipStrengthsGraphCards };
  };

  const getOptions = useCallback(async () => {
    const result = await getLeadershipStrengthsScores({
      ...(assessmentId ? { AssessmentId: assessmentId } : {}),
      ...(cultureCode ? { LanguageCultureInfo: cultureCode } : {}),
    });
    if (result.response && result.response.details) {
      setLoading(false);

      const isCandidateReport = result.response.details.isCandidateReport;
      const reportCode = result.response.details.reportCode;

      const leadershipStrengthsMenuCards = parseStrengthMenuCardOptions();

      const leadershipStrengthsGraphCards = parseGraphCardOptions(
        result.response.details
      );

      const leadershipStrengthsWheelImage =
        result.response.details.wheelDownloadUri;

      const reportDownloadFlags = {
        isReportDownloadable: result.response.details.isReportDownloadable,
      };        

      setData({
        leadershipStrengthsMenuCards,
        leadershipStrengthsGraphCards,
        leadershipStrengthsWheelImage,
        reportCode,
        isCandidateReport,
        reportDownloadFlags,
      });
    }

    if (result.errors) {
      setLoading(false);
      setErrors(true);
    }
  }, []);

  useEffect(() => {
    if (authStatus === "signed_in") {
      getOptions();
    }
  }, [authStatus]);

  return [data, loading, errors];
};
