import { Button } from 'components/button/Button';
import React from 'react';
import { useDispatch } from 'react-redux';
import { closeModal } from 'reducers/modal';
import FieldInput from 'components/fieldInput/fieldInput';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';

type TwoFactorModalProps = {
  data: { buttonText: string };
};

const TwoFactorModal: React.FC<TwoFactorModalProps> = ({ data }) => {
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const { t } = useTranslation('Two+Factor+Modal', { i18n });

  return (
    <div className="TwoFactorModal">
      <Formik initialValues={{}} onSubmit={() => {}}>
        <Form>
          <div className="Content">
            <p>{t('SSCandidate_Two_Factor_Modal_Activate')}</p>

            <div className="SMSBlock">
              <FieldInput
                placeholder=""
                autoComplete="email"
                className="EditBox"
                name="EmailAddress"
                label={t('SSCandidate_Two_Factor_Modal_Enter_Code')}
              />
            </div>
          </div>
          <div className="ModalFooter">
            <Button priority="tertiary" onClick={handleCloseModal}>
              {t('SSCandidate_Two_Factor_Modal_Cancel')}
            </Button>
            <Button priority="primary" onClick={handleCloseModal}>
              {data.buttonText}
            </Button>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export { TwoFactorModal };
