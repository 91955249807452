import instance from "api";
import { endpoints } from "config";

export const getMatchConnectionDetails = async (payload: number) => {
    const params = {
        HiringManagerSearchCandidateInterestId: payload
      };

    try {
      const res = await instance.get(
        endpoints.dashboard.getMatchConnectionDetails,
        { params }
      );
      if (res.data.status === '1') {
        return {
          response: res.data,
          errors: null,
        };
      } else {
        return {
          response: null,
          errors: res.data.errors,
        };
      }
    } catch (res) {
      const error: any = res;
  
      if (error.response && error.response.data && error.response.data.errors) {
        return {
          response: null,
          errors: error.response.data.errors,
        };
      } else {
        return {
          response: null,
          errors: [],
        };
      }
    }
  };

export const expressInterest = async (payload: any) => {

  try {
    const res = await instance.post(
      endpoints.dashboard.expressInterest,
      payload
    );
    if (res.data.status === '1') {
      return {
        response: res.data,
        errors: null,
      };
    } else {
      return {
        response: null,
        errors: res.data.errors,
      };
    }
  } catch (res) {
    const error: any = res;

    if (error.response && error.response.data && error.response.data.errors) {
      return {
        response: null,
        errors: error.response.data.errors,
      };
    } else {
      return {
        response: null,
        errors: [],
      };
    }
  }
};

export const updateCandidateConsent = async (payload: any) => {

  try {
    const res = await instance.post(
      endpoints.dashboard.updateCandidateConsent,
      payload
    );
    if (res.data.status === '1') {
      return {
        response: res.data,
        errors: null,
      };
    } else {
      return {
        response: null,
        errors: res.data.errors,
      };
    }
  } catch (res) {
    const error: any = res;

    if (error.response && error.response.data && error.response.data.errors) {
      return {
        response: null,
        errors: error.response.data.errors,
      };
    } else {
      return {
        response: null,
        errors: [],
      };
    }
  }
};