import { RouteComponentProps } from '@reach/router';
import LandingImage from 'assets/new_skin/Img_NewFindYourJoy_LandingPage.png';
import Pointer from 'assets/new_skin/pointer.svg';
import { Button } from 'components/button/Button';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setOpenSignUpForm } from 'reducers/landingPage';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';
import { addHubspotTracking } from 'helpers/addHubspotTracking';

type APTestDetails = RouteComponentProps & {
  goToTest: Dispatch<SetStateAction<boolean>>;
};

const AbilityPracticeTestLanding: React.FC<APTestDetails> = ({ goToTest }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('Ability+Practice+Test+Landing', { i18n });

  useEffect(() => {
    const script = addHubspotTracking();

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="AbilityTestLandingPage">
      <div className="SignIn WidthContent HeightContent">
        <div className="Content Body">
          <h1 className="Title">
            {t('SSCandidate_Ability_Practice_Test_Landing_Title')}
          </h1>
          <h2 className="Subtitle">
            {t('SSCandidate_Ability_Practice_Test_Landing_Subtitle')}
          </h2>
          <div className="ButtonContainer">
            <Button
              priority="primary"
              isPublic
              className="LandingButton"
              onClick={() => goToTest(false)}
            >
              {t('SSCandidate_Ability_Practice_Test_Landing_Lets_Go')}
            </Button>
            <img
              src={Pointer}
              className="Pointer"
              alt={t('SSCandidate_Ability_Practice_Test_Landing_Pointer_Image')}
            />
          </div>
          {/* Or option
          <div className="OrLine">
            <span>{t('SSCandidate_Ability_Practice_Test_Landing_Or')}</span>
          </div>
          <span className="LinkToSignUp">
            {t('SSCandidate_Ability_Practice_Test_Landing_To_Sign_Up_1')}{' '}
            <button
              className="InlineLink SignUpInline"
              onClick={() => dispatch(setOpenSignUpForm())}
            >
              {t('SSCandidate_Ability_Practice_Test_Landing_To_Sign_Up_2')}
            </button>{' '}
            {t('SSCandidate_Ability_Practice_Test_Landing_To_Sign_Up_3')}
          </span>
          */}
        </div>
        <img
          src={LandingImage}
          alt={t('SSCandidate_Ability_Practice_Test_Landing_Landing_Image')}
          className="LandingImage"
        />
      </div>
    </div>
  );
};

export default AbilityPracticeTestLanding;
