import { Button } from 'components/button/Button';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { closeModal } from 'reducers/modal';
import { setInstructionsUnderstood } from 'reducers/abilityTests';
import { startSJT } from '../SJTApi';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';

type ModalProps = {
  data: {
    title: string;
    info: string;
    prongID: number;
  };
};

export const SJTBeforeYouBeginModal: React.FC<ModalProps> = ({ data }) => {
  const [generalError, setGeneralError] = useState<string>();

  const dispatch = useDispatch();
  const { t } = useTranslation('Candidate+Journey+-+PQTest', { i18n });

  useEffect(() => {
    dispatch(setInstructionsUnderstood(false));
  }, []);

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const handleStartSJT = () => {
    (async () => {
      const result = await startSJT({ assessmentProngID: data.prongID });

      if (result.response) {
        setGeneralError('');
        dispatch(setInstructionsUnderstood(true));
      } else {
        setGeneralError(t('pPQInstruction_General_Error'));
      }
    })();
  };

  const modalStyle = {
    '--modalTitleColor': '#036C8F',
    '--footerBackgroundColor': '#f5f5f5',
    '--footerBorderColor': '#f5f5f5',
    '--primaryButtonBackgroundColor': '#731447',
    '--tertiaryButtonBackgroundColor': '#d5d5d5',
  } as React.CSSProperties;

  return (
    <div className="SJTBeforeYouBeginModal" style={modalStyle}>
      <Formik initialValues={{}} onSubmit={() => {}}>
        <Form>
          <div className="SJTBeforeYouBeginModal__content SJTModalContent">
            <div className="SJTBeforeYouBeginModal__message">
              <div
                dangerouslySetInnerHTML={{
                  __html: data.info,
                }}
              />
            </div>
          </div>
          <div className="ModalFooter">
            {generalError && <div className="GeneralError">{generalError}</div>}
            <Button
              className="Rounded"
              priority="tertiary"
              type="button"
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
            <Button
              className="Discard Rounded"
              priority="primary"
              type="submit"
              onClick={handleStartSJT}
            >
              I understand
            </Button>
          </div>
        </Form>
      </Formik>
    </div>
  );
};
