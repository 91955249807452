import { useNavigate } from '@reach/router';
import { AppPath } from 'appConstants';
import { BaseApi } from 'baseApi';
import { Button } from 'components/button/Button';
import { LineProgressIndicator } from 'components/lineProgressIndicator/LineProgressIndicator';
import { GACompletedQuizOrderingStep } from 'helpers/gaEvents';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FindYourJoyQuizContext } from './FindYourJoy';
import { FindYourJoyApi } from './findYourJoyApi';
import {
  FindYourJoyContext,
  FindYourJoyQuestionAnswer,
  FindYourJoyQuizStep,
} from './models';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';

type Image = {
  src: string;
  id: string;
};

type Row = 'top' | 'bottom';

interface ISlot {
  row: Row;
  index: number;
}

type State = {
  top: (Image | null)[];
  bottom: (Image | null)[];
};

const generateId = (questionId: number, answerId: number) =>
  `${questionId}-${answerId}`;
const parseGeneratedId = (
  id: string
): { questionId: number; answerId: number } => {
  const [questionId, answerId] = id
    .split('-')
    .map((numString) => parseInt(numString));
  return { questionId, answerId };
};

export const FindYourJoyOrderImages = () => {
  const { t } = useTranslation('Find+Your+Joy+Order+Images', { i18n });

  const dispatch = useDispatch();
  const quizContext = useContext(FindYourJoyQuizContext) as FindYourJoyContext;
  const [quizIsComplete, setQuizIsComplete] = useState(false);
  const navigate = useNavigate();

  const images = useMemo(() => {
    return quizContext.answers.map<Image | null>((answer) => {
      const associatedQuestion = quizContext.quiz.questions.find(
        (question) => answer.questionId === question.id
      );
      const associatedAnswer = associatedQuestion?.answers.find(
        (option) => option.id === answer.answerId
      );
      if (associatedQuestion && associatedAnswer) {
        // Generate some arbritrary ID so react can identify the images.
        const imageId = generateId(answer.questionId, answer.answerId);
        return { src: associatedAnswer?.imageUrl2, id: imageId };
      }
      return null;
    });
  }, [quizContext]);

  const initialState = {
    top: images,
    bottom: new Array(images.length).fill(null),
  };

  const [currentlyActive, setCurrentlyActive] = useState<ISlot | null>(null);
  const [imageArrays, setImageArrays] = useState<State>(initialState);

  const handleDrop = (item: ISlot) => {
    if (currentlyActive) {
      setCurrentlyActive(null);
      const newState = swapArrayValues(
        currentlyActive.row,
        item.row,
        currentlyActive.index,
        item.index
      );
      setImageArrays(newState);
    }
  };

  const swapArrayValues = (
    sourceRow: Row,
    targetRow: Row,
    sourceIndex: number,
    targetIndex: number
  ): State => {
    const clonedSourceArray = [...imageArrays[sourceRow]];
    const clonedTargetArray = [...imageArrays[targetRow]];
    const sourceItemCopy = clonedSourceArray[sourceIndex];
    clonedSourceArray[sourceIndex] = clonedTargetArray[targetIndex];
    clonedTargetArray[targetIndex] = sourceItemCopy;
    // Need to update target row if source and target rows are the same because
    // we are handling them as separate rows and they do not match.
    if (sourceRow === targetRow) {
      clonedTargetArray[sourceIndex] = clonedSourceArray[targetIndex];
    }
    // Then we add targetRow after sourceRow so targetRows will overwrite if
    // source and target rows are the same.
    return {
      ...imageArrays,
      [sourceRow]: clonedSourceArray,
      [targetRow]: clonedTargetArray,
    };
  };

  const lowerImageRowIsFull = !imageArrays.bottom.includes(null);

  if (lowerImageRowIsFull && !quizIsComplete) {
    setQuizIsComplete(true);
  } else if (!lowerImageRowIsFull && quizIsComplete) {
    setQuizIsComplete(false);
  }

  const handleComplete = useCallback(() => {
    GACompletedQuizOrderingStep();
    const finalOrder = imageArrays.bottom as Image[];
    const newAnswers: FindYourJoyQuestionAnswer[] = finalOrder.map((image) => {
      return parseGeneratedId(image.id);
    });
    quizContext.setAnswers(newAnswers);
    if (quizContext.isCustomer) {
      FindYourJoyApi.postCustomerAnswers(
        newAnswers,
        quizContext.quiz.softSkillsQuizId
      ).then((response: any) => {
        if (BaseApi.isResponseSuccessful(response.data)) {
          navigate(AppPath.MY_JOY);
        }
      });
    } else {
      quizContext.setCurrentQuizStep(FindYourJoyQuizStep.RESULTS);
    }
  }, [imageArrays.bottom, quizContext, dispatch, navigate]);

  const handleDragStart = (item: ISlot) => {
    setCurrentlyActive(item);
  };

  // The top and bottom rows were switched in the original design (images start at top and drag
  // them down). Therefore the row names are swapped. Going forward it would be a good idea to
  // correct these to avoid confusion.

  return (
    <div
      className={`FindYourJoyOrderImages ${
        quizContext.isCustomer ? 'Customer' : ''
      }`}
    >
      {quizContext ? (
        <>
          <div className="HeadingContainer">
            <div className="ProgressIndicatorContainer">
              <LineProgressIndicator
                currentStep={quizContext.answers.length + 1}
                // +1 to include the ordering stage.
                steps={quizContext.quiz.questions.length + 1}
              />
            </div>
          </div>
          <h1 className="Title">
            {t('SSCandidate_Find_Your_Joy_Order_Images_Question_Prompt')}
          </h1>
          <h2 className="Prompt">
            {t('SSCandidate_Find_Your_Joy_Order_Images_Question_Subtext')}
          </h2>
          <div className="QuizPage OrderingPage">
            <div className="Grid Upper">
              {imageArrays.bottom.map((item, index) => (
                <React.Fragment key={item ? item.id : index}>
                  <Slot
                    row="bottom"
                    index={index}
                    image={item}
                    handleDragStart={handleDragStart}
                    handleDrop={handleDrop}
                    isBeingMoved={
                      currentlyActive?.row === 'bottom' &&
                      currentlyActive?.index === index
                    }
                    moveHasStarted={!!currentlyActive}
                    isMostImportant={index === 0}
                    isLeastImportant={index === imageArrays.bottom.length - 1}
                  />
                </React.Fragment>
              ))}
            </div>

            <div className="Grid Lower">
              {imageArrays.top.map((item, index) => (
                <React.Fragment key={item ? item.id : index}>
                  <Slot
                    row="top"
                    index={index}
                    image={item}
                    handleDragStart={handleDragStart}
                    handleDrop={handleDrop}
                    isBeingMoved={
                      currentlyActive?.row === 'top' &&
                      currentlyActive?.index === index
                    }
                    moveHasStarted={!!currentlyActive}
                  />
                </React.Fragment>
              ))}
            </div>
            <div className="SubmitButtonContainer">
              <Button
                priority="primary"
                disabled={!quizIsComplete}
                onClick={handleComplete}
                className="SubmitButton"
                isPublic
              >
                {quizContext.isCustomer
                  ? t(
                      'SSCandidate_Find_Your_Joy_Order_Images_Quiz_Button_Customer'
                    )
                  : t('SSCandidate_Find_Your_Joy_Order_Images_Quiz_Button')}
              </Button>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

type SlotProps = {
  row: Row;
  index: number;
  image: Image | null;
  handleDragStart: (item: ISlot) => void;
  handleDrop: (item: ISlot) => void;
  moveHasStarted: boolean;
  isBeingMoved: boolean;
  includeIndex?: boolean;
  isMostImportant?: boolean;
  isLeastImportant?: boolean;
};

const Slot: React.FC<SlotProps> = ({
  row,
  index,
  image,
  handleDragStart,
  handleDrop,
  moveHasStarted,
  isBeingMoved,
  isMostImportant,
  isLeastImportant,
}) => {
  const { t } = useTranslation('Find+Your+Joy+Order+Images', { i18n });

  let timeout: number;

  const handleTouchStart = (item: ISlot) => {
    timeout = window.setTimeout(() => {
      if (moveHasStarted) {
        handleDrop(item);
      } else {
        handleDragStart(item);
      }
    }, 100);
  };

  const handleTouchMove = () => {
    if (timeout) {
      window.clearTimeout(timeout);
      timeout = 0;
    }
  };

  return (
    <div className="GridItem">
      <div
        className={`GridImageSlot ${image ? '' : 'Empty'} ${
          isBeingMoved ? 'Active' : ''
        } ${row}`}
        onDragOver={(e) => e.preventDefault()}
        onTouchStart={() => handleTouchStart({ row, index })}
        onTouchMove={handleTouchMove}
        onDrop={() => handleDrop({ row, index })}
      >
        {image && (
          <div
            className="Image"
            style={{
              backgroundImage: `url(${image.src})`,
            }}
            draggable={true}
            onDragStart={() => handleDragStart({ row, index })}
            onTouchStart={() => handleTouchStart({ row, index })}
            onTouchMove={handleTouchMove}
            onDrop={() => handleDrop({ row, index })}
          />
        )}
        {row === 'bottom' && !image && (
          <span className="SlotNumber">{index + 1}</span>
        )}
        {!image &&
          (isMostImportant ? (
            <span className="ImportanceLabel">
              {t(
                'SSCandidate_Find_Your_Joy_Order_Images_Question_Most_Important'
              )}
            </span>
          ) : isLeastImportant ? (
            <span className="ImportanceLabel">
              {t(
                'SSCandidate_Find_Your_Joy_Order_Images_Question_Least_Important'
              )}
            </span>
          ) : null)}
      </div>
    </div>
  );
};
