import React from 'react';
import { useSelector } from 'react-redux';
import { selectAccessibilityData } from 'reducers/accessibility';

type ProgressIndicatorProps = {
  value: number;
  total: number;
  radius?: number;
  strokeWidth?: number;
  strokeColor?: string;
  overrideText?: string;
  strokeFill?: string;
  extraText?: string;
  fill?: string;
};

const DEFAULT_STROKE_COLOR = '#2b7ba0';
const DEFAULT_STROKE_FILL = 'transparent';

export const ProgressIndicator: React.FC<ProgressIndicatorProps> = ({
  value,
  total,
  overrideText,
  extraText,
  radius = 25,
  strokeWidth = 5,
  strokeColor = DEFAULT_STROKE_COLOR,
  strokeFill = DEFAULT_STROKE_FILL,
  fill,
}) => {
  // const strokeWidth = 5;
  // const radius = 25;

  const accessibilityData = useSelector(selectAccessibilityData);

  const style = accessibilityData.isSet
    ? ({
        '--fontColor': accessibilityData.textColor,
      } as React.CSSProperties)
    : {};

  const progressPercent = !value
    ? 0
    : value === total
    ? 100
    : Math.floor((value / total) * 100);
  const normalizedRadius = radius - strokeWidth;
  const circumference = normalizedRadius * 2 * Math.PI;

  // const strokeColor = DEFAULT_STROKE_COLOR;

  const strokeDashOffset =
    circumference - (progressPercent / 100) * circumference;

  return (
    <div className="ProgressIndicatorContainer">
      <svg height={radius * 2} width={radius * 2}>
        <circle
          className="BackgroundCircle"
          strokeWidth={strokeWidth}
          fill={strokeFill}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
          stroke={fill ? fill : 'transparent'}
        />
      </svg>
      <svg
        className="Bar"
        height={radius * 2}
        width={radius * 2}
        style={{ transform: 'rotate(-90deg)' }}
      >
        <circle
          className="ProgressCircle"
          stroke={strokeColor}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference + ' ' + circumference}
          fill="transparent"
          style={{ strokeDashoffset: strokeDashOffset }}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
      </svg>
      <p className={`InnerText${extraText ? ' HasExtra' : ''}`} style={style}>
        {overrideText !== null && overrideText !== undefined ? (
          <>{overrideText}</>
        ) : (
          <>
            {progressPercent}
            <sup>%</sup>
          </>
        )}
      </p>
      {extraText && <p className="ExtraText">{extraText}</p>}
    </div>
  );
};
