import React, { useState } from 'react';
import { InteractiveFeedbackMenuCardHub } from '../components/InteractiveFeedbackMenuCardHub';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';
import { Spinner } from 'components/spinner/Spinner';
import ErrorFallback from 'components/fallbacks/ErrorFallback';
import {
  MyStrengthsCardOptions,
  MyStrengthsFullProfileOptions,
  MyStrengthsMenuOptions,
  useGetMyStrengthsDetails,
} from '../hooks/useGetMyStrengthsDetails';
import useMedia from 'use-media';
import { InteractiveFeedbackFullProfile } from './InteractiveFeedbackFullProfile';

type InteractiveFeedbackMyStrengthsProps = {
  sectionData: any;
};

export const InteractiveFeedbackMyStrengths: React.FC<InteractiveFeedbackMyStrengthsProps> = ({
  sectionData,
}) => {
  const [animateDismount, setAnimateDismount] = useState(false);
  const [alternateView, setAlternateView] = useState(false);
  const isTablet = useMedia('(max-width: 768px)');

  const { t } = useTranslation('Interactive+Feedback', { i18n });

  const [data, loading, errors] = useGetMyStrengthsDetails();

  if (loading || data === undefined || data === null) return <Spinner />;

  if (errors) return <ErrorFallback />;

  const {
    strengthMenuOptions,
    strengthMenuCards,
    strengthScoreRows,
    reportCode,
    isCandidateReport,
  } = data as {
    strengthMenuOptions: MyStrengthsMenuOptions;
    strengthMenuCards: MyStrengthsCardOptions;
    strengthScoreRows: any;
    reportCode: any;
    isCandidateReport: any;
  };

  // console.log('strengthScoreRows', strengthScoreRows);

  return (
    <div className="InteractiveFeedbackMyStrengths WidthContent HeightContent">
      {!alternateView && (
        <InteractiveFeedbackMenuCardHub
          title={t('SSCandidate_Interactive_Feedback_Your_Strengths_Title')}
          cardDetails={strengthMenuCards.strengthsCards}
          rows={strengthMenuOptions.rows}
          setAnimateDismount={setAnimateDismount}
          animateDismount={animateDismount}
          onAnimationEnd={() => {
            setAlternateView(true);
          }}
          reportCode={reportCode}
          isCandidateReport={isCandidateReport}
          fullProfile={true}
          setAlternateView={setAlternateView}
          sectionData={sectionData}
        />
      )}
      {alternateView && (
        <InteractiveFeedbackFullProfile
          backArrowClickHandler={() => {
            setAlternateView(false);
          }}
          scoreDetails={strengthScoreRows}
        />
      )}
    </div>
  );
};
