import { Store } from './rootReducer';

//Types
export enum DevelopmentAssessmentAction {
  'SET_ASSESSMENT_URL' = 'SET_ASSESSMENT_URL',
}

export interface DevelopmentAssessment {
  url: string;
}

const initialState: DevelopmentAssessment = {
  url: '',
};

// Actions
export type Action = {
  type: DevelopmentAssessmentAction.SET_ASSESSMENT_URL;
  payload: DevelopmentAssessment;
};

// Reducer
export default function DevelopmentAssessmentReducer(
  state = initialState,
  action: Action
): DevelopmentAssessment {
  if (action.type === DevelopmentAssessmentAction.SET_ASSESSMENT_URL) {
    return { ...state, ...action.payload };
  }
  return state;
}

// Action creators
export const setDevelopmentAssessment = (payload: DevelopmentAssessment) => ({
  type: DevelopmentAssessmentAction.SET_ASSESSMENT_URL,
  payload,
});

// Selectors
export const selectDevelopmentAssessmentUrl = (state: Store) =>
  state.developmentAssessment.url;
