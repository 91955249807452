import { Store } from 'reducers/rootReducer';

type TrackerData = {
  currentQuestion: number;
  totalQuestions: number;
  message: string;
  onlyShowProgress: boolean;
} | null;

export type State = {
  projectId: number | null;
  assessmentId: number | null;
  applicationType: string | null;
  jobApplicationId: number | null;
  trackerData: TrackerData | null;
  instructionsUnderstood: boolean;
  hideScenarioModal: Record<string, boolean>;
  candidateName: string | null;
};

const initialState: State = {
  projectId: null,
  assessmentId: null,
  applicationType: null,
  jobApplicationId: null,
  trackerData: null,
  instructionsUnderstood: false,
  hideScenarioModal: {
    pq: false,
    at: false,
    sjt: false,
    all: false, // Added following request for "Don't show again" to apply to all test types
  },
  candidateName: null,
};

// Actions
const CLEAR_ASSESSMENT_DATA = 'app/assessment/CLEAR_ASSESSMENT_DATA';
const SET_PROJECT_ID = 'app/assessment/SET_PROJECT_ID';
const SET_APPLICATION_TYPE = 'app/assessment/SET_APPLICATION_TYPE';
const SET_ASSESSMENT_ID = 'app/assessment/SET_ASSESSMENT_ID';
const SET_JOB_APPLICATION_ID = 'app/assessment/SET_JOB_APPLICATION_ID';
const SET_TRACKER_DATA = 'app/assessment/SET_TRACKER_DATA';
const SET_INSTRUCTIONS_UNDERSTOOD =
  'app/assessment/SET_INSTRUCTIONS_UNDERSTOOD';
const SET_HIDE_SCENARIO_MODAL = 'app/assessment/SET_HIDE_SCENARIO_MODAL';
const SET_CANDIDATE_NAME = 'app/assessment/SET_CANDIDATE_NAME';

// Reducer
type Action =
  | {
      type: typeof CLEAR_ASSESSMENT_DATA;
      payload: null;
    }
  | {
      type: typeof SET_PROJECT_ID;
      payload: number;
    }
  | {
      type: typeof SET_APPLICATION_TYPE;
      payload: string;
    }
  | {
      type: typeof SET_ASSESSMENT_ID;
      payload: number;
    }
  | {
      type: typeof SET_JOB_APPLICATION_ID;
      payload: number;
    }
  | {
      type: typeof SET_TRACKER_DATA;
      payload: TrackerData;
    }
  | {
      type: typeof SET_INSTRUCTIONS_UNDERSTOOD;
      payload: boolean;
    }
  | {
      type: typeof SET_HIDE_SCENARIO_MODAL;
      payload: Record<string, boolean>;
    }
  | {
      type: typeof SET_CANDIDATE_NAME;
      payload: string;
    };

export default function reducer(state = initialState, action: Action): State {
  switch (action.type) {
    case CLEAR_ASSESSMENT_DATA:
      return initialState;
    case SET_PROJECT_ID:
      return {
        ...state,
        projectId: action.payload,
      };
    case SET_APPLICATION_TYPE:
      return {
        ...state,
        applicationType: action.payload,
      };
    case SET_ASSESSMENT_ID:
      return {
        ...state,
        assessmentId: action.payload,
      };
    case SET_JOB_APPLICATION_ID:
      return {
        ...state,
        jobApplicationId: action.payload,
      };
    case SET_TRACKER_DATA:
      return {
        ...state,
        trackerData: action.payload,
      };
    case SET_INSTRUCTIONS_UNDERSTOOD:
      return {
        ...state,
        instructionsUnderstood: action.payload,
      };
    case SET_HIDE_SCENARIO_MODAL:
      return {
        ...state,
        hideScenarioModal: action.payload,
      };
    case SET_CANDIDATE_NAME:
      return {
        ...state,
        candidateName: action.payload,
      };
    default:
      return state;
  }
}

// Action creators
export const clearAssessmentData = () => ({
  type: CLEAR_ASSESSMENT_DATA,
});
export const setProjectId = (payload: number) => ({
  type: SET_PROJECT_ID,
  payload,
});
export const setApplicationType = (payload: string) => ({
  type: SET_APPLICATION_TYPE,
  payload,
});
export const setAssessmentId = (payload: number | null) => ({
  type: SET_ASSESSMENT_ID,
  payload,
});
export const setjobApplicationId = (payload: number) => ({
  type: SET_JOB_APPLICATION_ID,
  payload,
});
export const setTrackerData = (payload: TrackerData) => ({
  type: SET_TRACKER_DATA,
  payload,
});
export const setInstructionsUnderstood = (payload: boolean) => ({
  type: SET_INSTRUCTIONS_UNDERSTOOD,
  payload,
});
export const setHideScenarioModal = (payload: Record<string, boolean>) => ({
  type: SET_HIDE_SCENARIO_MODAL,
  payload,
});
export const setCandidateName = (payload: string) => ({
  type: SET_CANDIDATE_NAME,
  payload,
});

// Selectors
export const selectProjectId = (app: Store) => app.assessment.projectId;
export const selectAssessmentId = (app: Store) => app.assessment.assessmentId;
export const selectJobApplicationId = (app: Store) =>
  app.assessment.jobApplicationId;
export const selectApplicationType = (app: Store) =>
  app.assessment.applicationType;
export const selectTrackerData = (app: Store) => app.assessment.trackerData;
export const selectInstructionsUnderstood = (app: Store) =>
  app.assessment.instructionsUnderstood;
export const selectHideScenarioModal = (app: Store) =>
  app.assessment.hideScenarioModal;
export const selectCandidateName = (app: Store) => app.assessment.candidateName;
