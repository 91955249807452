import axios from 'api';
import { AxiosRequestConfig } from 'axios';

type Error = {};

type ApiReturn<T = any> = {
  response: T | null;
  errors: Error[] | null;
};

export const get = async (
  url: string,
  payload: any,
  useWithCredentials: boolean = false
): Promise<ApiReturn> => {
  try {
    const config: AxiosRequestConfig = {
      params: {
        ...payload,
      },
      withCredentials: useWithCredentials,
    };

    const res = await axios.get(url, config);
    if (res.data.details.status === '1') {
      return {
        response: res.data.details,
        errors: null,
      };
    } else {
      return {
        response: res.data.details,
        errors: res.data.errors,
      };
    }
  } catch (err: any) {
    return {
      response: null,
      errors: err,
    };
  }
};

export const post = async (url: string, payload: any): Promise<ApiReturn> => {
  try {
    const res = await axios.post(url, payload);
    if (res.data.details.status === '1') {
      return {
        response: res.data.details,
        errors: null,
      };
    } else {
      return {
        response: res.data.details,
        errors: res.data.errors,
      };
    }
  } catch (err: any) {
    return {
      response: null,
      errors: err,
    };
  }
};
