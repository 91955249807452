import { useState, useCallback, useEffect } from 'react';
import {
  InteractiveFeedbackCardProps,
  InteractiveFeedbackCardType,
} from '../components/InteractiveFeedbackCard';
import { InteractiveFeedbackMenuProps } from '../components/InteractiveFeedbackMenu';
import {
  GetMyStrengthScoresDetails,
  getStrengthScores,
  GetTeamStrengthsScoresResponse,
} from '../InteractiveFeedbackApi';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';
import TalkativeIcon from 'assets/apply.clevry/IF_MyStrengths_Icon_01.png';
import TalkativeImage from 'assets/apply.clevry/IF_MyStrengths_FullProfile_Desc_03.jpg';
import ExpedientIcon from 'assets/apply.clevry/IF_MyStrengths_Icon_02.png';
import ExpedientImage from 'assets/apply.clevry/IF_MyStrengths_StrengthDesc_02.jpg';
import PragmatismIcon from 'assets/apply.clevry/IF_MyStrengths_Icon_03.png';
import PragmatismImage from 'assets/apply.clevry/IF_MyStrengths_StrengthDesc_03.jpg';
import DispassionateIcon from 'assets/apply.clevry/IF_MyStrengths_Icon_04.png';
import DispassionateImage from 'assets/apply.clevry/IF_MyStrengths_StrengthDesc_04.jpg';
import SensitivityIcon from 'assets/apply.clevry/IF_MyStrengths_Icon_05.png';
import SensitivityImage from 'assets/apply.clevry/IF_MyStrengths_StrengthDesc_05.jpg';
import { useSelector } from 'react-redux';
import { selectDashboardInfo } from 'reducers/dashboardInfo';
import { selectAuthStatus, selectRedirectValues } from 'reducers/auth';

import SupportiveImage from 'assets/apply.clevry/IF_MyStrengths_FullProfile_Desc_01.jpg';
import AssertiveImage from 'assets/apply.clevry/IF_MyStrengths_FullProfile_Desc_02.jpg';
import ListeningImage from 'assets/apply.clevry/IF_MyStrengths_FullProfile_Desc_04.jpg';
import SolitaryImage from 'assets/apply.clevry/IF_MyStrengths_FullProfile_Desc_05.jpg';
import GregariousImage from 'assets/apply.clevry/IF_MyStrengths_FullProfile_Desc_06.jpg';
import AcceptingImage from 'assets/apply.clevry/IF_MyStrengths_FullProfile_Desc_07.jpg';
import InfluentialImage from 'assets/apply.clevry/IF_MyStrengths_FullProfile_Desc_08.jpg';
import ReticentImage from 'assets/apply.clevry/IF_MyStrengths_FullProfile_Desc_09.jpg';
import PoisedImage from 'assets/apply.clevry/IF_MyStrengths_FullProfile_Desc_10.jpg';
import ConsistentImage from 'assets/apply.clevry/IF_MyStrengths_FullProfile_Desc_11.jpg';
import AdaptableImage from 'assets/apply.clevry/IF_MyStrengths_FullProfile_Desc_12.jpg';
import { selectAssessmentId } from 'reducers/virtualAssessments';
import { images } from 'config';

export type MyStrengthsMenuOptions = {
  rows: {
    text: string;
    icon: string;
    rowClick: (active: boolean) => void;
  }[];
  title: string;
};

export type ReportDownloadFlags = {
  isReportDownloadable?: boolean;
};

export type MyStrengthsCardInnerOptions = Pick<
  InteractiveFeedbackCardProps,
  'bottomColor' | 'cardHTML' | 'heading' | 'type' | 'noButtons'
> & { topImage?: string };

export type MyStrengthsCardOptions = {
  strengthsCards: MyStrengthsCardInnerOptions[];
};

export type MyStrengthsFullProfileOptions = {};

export const MyStrengthsScaleMaps = {
  Collaborative: {
    color: '#31C3EC',
    borderColor: '#AD5854',
    bottomColor: '#AD5854', // No inivision screen for colors
    topImage: SensitivityImage,
    menuIcon: TalkativeIcon,
  },
  Dispassionate: {
    color: '#31C3EC',
    borderColor: '#AD5854',
    bottomColor: '#AD5854',
    topImage: DispassionateImage,
    menuIcon: DispassionateIcon,
  },
  Expedient: {
    color: '#EACD60',
    borderColor: '#135770',
    bottomColor: '#135770',
    topImage: ExpedientImage,
    menuIcon: ExpedientIcon,
  },
  Freedom: {
    color: '#EACD60',
    borderColor: '#135770',
    bottomColor: '#135770', // No inivision screen for colors
    topImage: SensitivityImage,
    menuIcon: TalkativeIcon,
  },
  Pragmatism: {
    color: '#732571',
    borderColor: '#35192B',
    bottomColor: '#35192B',
    topImage: PragmatismImage,
    menuIcon: PragmatismIcon,
  },
  Reticent: {
    color: '#732571',
    borderColor: '#35192B',
    bottomColor: '#35192B', // No inivision screen for colors
    topImage: SensitivityImage,
    menuIcon: TalkativeIcon,
  },
  Sensitivity: {
    color: '#4FAC80',
    borderColor: '#6E2256',
    bottomColor: '#6E2256',
    topImage: SensitivityIcon,
    menuIcon: SensitivityIcon,
  },
  Talkative: {
    color: '#146594',
    borderColor: '#998064',
    bottomColor: '#998064',
    topImage: TalkativeImage,
    menuIcon: TalkativeIcon,
  },
};

export const useGetMyStrengthsDetails = () => {
  const { t } = useTranslation('Interactive+Feedback', { i18n });
  const [data, setData] = useState<{
    strengthMenuOptions: MyStrengthsMenuOptions;
    strengthMenuCards: MyStrengthsCardOptions;
    strengthScoreRows: any;
    reportCode: string;
    isCandidateReport: boolean;
    reportDownloadFlags: ReportDownloadFlags;
  } | null>(null);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState(false);
  const redirectValues = useSelector(selectRedirectValues);
  const dashboardInfo = useSelector(selectDashboardInfo);
  const myHistoryRouteAssessmentId = useSelector(selectAssessmentId);
  const authStatus = useSelector(selectAuthStatus);

  let cultureCode: string | null = null;
  if (dashboardInfo && dashboardInfo.cultureCode) {
    cultureCode = dashboardInfo.cultureCode;
  }

  let assessmentId: number | null = null;
  if (redirectValues && redirectValues.length > 0) {
    assessmentId = redirectValues.find(
      ({ label, value }) => label === 'AssessmentID'
    )?.value;
  } else if (myHistoryRouteAssessmentId) {
    assessmentId = myHistoryRouteAssessmentId;
  }

  const parseOuterMenuOptions = (
    details: GetMyStrengthScoresDetails,
    title: string
  ): MyStrengthsMenuOptions => {
    const rows = details.topFiveStrengths.map(({ scaleName, iconURL }) => ({
      text: scaleName ? scaleName : 'Text not loaded',
      icon: iconURL ? iconURL : images.interactiveFeedback.defaultMyStrengthsMenuIcon,
      rowClick: (active: boolean) => {},
    }));
    return {
      rows,
      title,
    };
  };

  const parseStrengthCardOptions = (details: GetMyStrengthScoresDetails) => {
    const strengthsCards = details.topFiveStrengths.map(
      ({ scaleName, scaleDescription, colour, imageURL }) => ({
        type: InteractiveFeedbackCardType.IMAGE,
        heading: scaleName,
        cardHTML: scaleDescription,
        bottomColor: colour ? colour : "#5D5642",
        topImage: imageURL ? imageURL : images.interactiveFeedback.defaultMyStrengthsCardImage,
      })
    );
    return { strengthsCards };
  };

  const parseStrengthScoreCardOptions = (
    details: GetMyStrengthScoresDetails
  ) => {
    const strengthsScoreCards = details.strengthScales.map(
      ({ scaleNameLow }) => ({})
    );
  };

  const organiseStrengthScores = (details: GetMyStrengthScoresDetails) => {
    const scales = details.strengthScales;

    let interpersonalScales = [];
    let thinkingScales = [];
    let emotionalScales = [];
    let motivationScales = [];
    let cultureFitScales = [];
    let responseScales = [];

    for (let i = 0; i < scales.length; i++) {
      switch (scales[i].scaleNameLow) {
        case 'Supportive':
        case 'Talkative':
        case 'Solitary':
        case 'Accepting':
        case 'Reticent':
        case 'Consistent':
          interpersonalScales.push(scales[i]);
          break;
        case 'Intuitive':
        case 'Considered':
        case 'Methodical':
        case 'Concrete':
        case 'Expedient':
          thinkingScales.push(scales[i]);
          break;
        case 'Wary':
        case 'Encouragement':
        case 'Sensitivity':
        case 'Composure':
        case 'Within comfort zone':
        case 'External control':
          emotionalScales.push(scales[i]);
          break;
        case 'Process':
        case 'Focus':
        case 'Boundaries':
        case 'Pragmatism':
        case 'Meaningfulness':
        case 'Dispassionate':
          motivationScales.push(scales[i]);
          break;
        case 'Collaborative':
        case 'Freedom':
        case 'Gravitas':
        case 'Stability':
        case 'Low profit focus':
        case 'Realism':
          cultureFitScales.push(scales[i]);
          break;
        default:
          responseScales.push(scales[i]);
          break;
      }
    }

    return {
      interpersonalScales,
      thinkingScales,
      emotionalScales,
      motivationScales,
      cultureFitScales,
      responseScales,
    };
  };

  const getOptions = useCallback(async () => {
    const result = await getStrengthScores({
      ...(assessmentId ? { AssessmentId: assessmentId } : {}),
      ...(cultureCode ? { LanguageCultureInfo: cultureCode } : {}),
    });
    if (result.response && result.response.details) {
      setLoading(false);

      const isCandidateReport = result.response.details.isCandidateReport;
      const reportCode = result.response.details.reportCode;

      const strengthMenuOptions = parseOuterMenuOptions(
        result.response.details,
        t('SSCandidate_Interactive_Feedback_My_Capabilities')
      );
      const strengthMenuCards = parseStrengthCardOptions(
        result.response.details
      );
      const strengthScoreRows = organiseStrengthScores(result.response.details);
      const reportDownloadFlags = {
        isReportDownloadable: result.response.details.isReportDownloadable,
      };

      setData({
        strengthMenuOptions,
        strengthMenuCards,
        strengthScoreRows,
        reportCode,
        isCandidateReport,
        reportDownloadFlags,
      });
      // setIsStrengthsData(true);
    }

    // if (result.response && result.response.details === null) {
    //   setIsStrengthsData(false);
    // }

    if (result.errors) {
      setLoading(false);
      setErrors(true);
    }
  }, []);

  useEffect(() => {
    if (authStatus === "signed_in") {
      getOptions();
    }
  }, [authStatus]);

  return [data, loading, errors];
};

// Scale map currently only includes images and card colours for the interpersonal scales based on InVision screens.
// TODO: update other scales once images/colours provided for them
export const MyStrengthsProfileScaleMaps = {
  //Interpersonal scales
  Supportive: {
    color: '#31C3EC',
    bottomColor: '#52463B',
    topImage: SupportiveImage,
  },
  Assertive: {
    color: '#31C3EC',
    bottomColor: '#2E411A',
    topImage: AssertiveImage,
  },
  Talkative: {
    color: '#31C3EC',
    bottomColor: '#55595C',
    topImage: TalkativeImage,
  },
  Listening: {
    color: '#31C3EC',
    bottomColor: '#3E364D',
    topImage: ListeningImage,
  },
  Solitary: {
    color: '#31C3EC',
    bottomColor: '#3C3B36',
    topImage: SolitaryImage,
  },
  Gregarious: {
    color: '#31C3EC',
    bottomColor: '#B04E4E',
    topImage: GregariousImage,
  },
  Accepting: {
    color: '#31C3EC',
    bottomColor: '#A08972',
    topImage: AcceptingImage,
  },
  Influential: {
    color: '#31C3EC',
    bottomColor: '#3A433B',
    topImage: InfluentialImage,
  },
  Reticent: {
    color: '#31C3EC',
    bottomColor: '#65635A',
    topImage: ReticentImage,
  },
  Poised: {
    color: '#31C3EC',
    bottomColor: '#784F3B',
    topImage: PoisedImage,
  },
  Consistent: {
    color: '#31C3EC',
    bottomColor: '#2C4F52',
    topImage: ConsistentImage,
  },
  Adaptable: {
    color: '#31C3EC',
    bottomColor: '#653822',
    topImage: AdaptableImage,
  },

  // Thinking scales
  Expedient: {
    color: '#31C3EC',
    bottomColor: '#52463B',
    topImage: SupportiveImage,
  },
  Rigorous: {
    color: '#31C3EC',
    bottomColor: '#2E411A',
    topImage: AssertiveImage,
  },
  Intuitive: {
    color: '#31C3EC',
    bottomColor: '#55595C',
    topImage: TalkativeImage,
  },
  Rational: {
    color: '#31C3EC',
    bottomColor: '#3E364D',
    topImage: ListeningImage,
  },
  Methodical: {
    color: '#31C3EC',
    bottomColor: '#3C3B36',
    topImage: SolitaryImage,
  },
  Creative: {
    color: '#31C3EC',
    bottomColor: '#B04E4E',
    topImage: GregariousImage,
  },
  Concrete: {
    color: '#31C3EC',
    bottomColor: '#A08972',
    topImage: AcceptingImage,
  },
  Strategic: {
    color: '#31C3EC',
    bottomColor: '#3A433B',
    topImage: InfluentialImage,
  },
  Considered: {
    color: '#31C3EC',
    bottomColor: '#65635A',
    topImage: ReticentImage,
  },
  Decisive: {
    color: '#31C3EC',
    bottomColor: '#784F3B',
    topImage: PoisedImage,
  },

  // Emotional scales
  Encouragement: {
    color: '#31C3EC',
    bottomColor: '#52463B',
    topImage: SupportiveImage,
  },
  'Self-belief': {
    color: '#31C3EC',
    bottomColor: '#2E411A',
    topImage: AssertiveImage,
  },
  'External control': {
    color: '#31C3EC',
    bottomColor: '#55595C',
    topImage: TalkativeImage,
  },
  'Internal control': {
    color: '#31C3EC',
    bottomColor: '#3E364D',
    topImage: ListeningImage,
  },
  'Within comfort zone': {
    color: '#31C3EC',
    bottomColor: '#3C3B36',
    topImage: SolitaryImage,
  },
  'Outside comfort zone': {
    color: '#31C3EC',
    bottomColor: '#B04E4E',
    topImage: GregariousImage,
  },
  Sensitivity: {
    color: '#31C3EC',
    bottomColor: '#A08972',
    topImage: AcceptingImage,
  },
  Resilience: {
    color: '#31C3EC',
    bottomColor: '#3A433B',
    topImage: InfluentialImage,
  },
  Wary: {
    color: '#31C3EC',
    bottomColor: '#65635A',
    topImage: ReticentImage,
  },
  Optimistic: {
    color: '#31C3EC',
    bottomColor: '#784F3B',
    topImage: PoisedImage,
  },
  Composure: {
    color: '#31C3EC',
    bottomColor: '#2C4F52',
    topImage: ConsistentImage,
  },
  Pressure: {
    color: '#31C3EC',
    bottomColor: '#653822',
    topImage: AdaptableImage,
  },

  // Motivation scales
  Meaningfulness: {
    color: '#31C3EC',
    bottomColor: '#52463B',
    topImage: SupportiveImage,
  },
  Status: {
    color: '#31C3EC',
    bottomColor: '#2E411A',
    topImage: AssertiveImage,
  },
  Boundaries: {
    color: '#31C3EC',
    bottomColor: '#55595C',
    topImage: TalkativeImage,
  },
  Friendship: {
    color: '#31C3EC',
    bottomColor: '#3E364D',
    topImage: ListeningImage,
  },
  Dispassionate: {
    color: '#31C3EC',
    bottomColor: '#3C3B36',
    topImage: SolitaryImage,
  },
  Caring: {
    color: '#31C3EC',
    bottomColor: '#B04E4E',
    topImage: GregariousImage,
  },
  Focus: {
    color: '#31C3EC',
    bottomColor: '#A08972',
    topImage: AcceptingImage,
  },
  Variety: {
    color: '#31C3EC',
    bottomColor: '#3A433B',
    topImage: InfluentialImage,
  },
  Pragmatism: {
    color: '#31C3EC',
    bottomColor: '#65635A',
    topImage: ReticentImage,
  },
  'Self-development': {
    color: '#31C3EC',
    bottomColor: '#784F3B',
    topImage: PoisedImage,
  },
  Process: {
    color: '#31C3EC',
    bottomColor: '#2C4F52',
    topImage: ConsistentImage,
  },
  Achievement: {
    color: '#31C3EC',
    bottomColor: '#653822',
    topImage: AdaptableImage,
  },

  // Culture fit scales
  Stability: {
    color: '#31C3EC',
    bottomColor: '#52463B',
    topImage: SupportiveImage,
  },
  Change: {
    color: '#31C3EC',
    bottomColor: '#2E411A',
    topImage: AssertiveImage,
  },
  Collaborative: {
    color: '#31C3EC',
    bottomColor: '#55595C',
    topImage: TalkativeImage,
  },
  Competitive: {
    color: '#31C3EC',
    bottomColor: '#3E364D',
    topImage: ListeningImage,
  },
  Freedom: {
    color: '#31C3EC',
    bottomColor: '#3C3B36',
    topImage: SolitaryImage,
  },
  Order: {
    color: '#31C3EC',
    bottomColor: '#B04E4E',
    topImage: GregariousImage,
  },
  Gravitas: {
    color: '#31C3EC',
    bottomColor: '#A08972',
    topImage: AcceptingImage,
  },
  Humour: {
    color: '#31C3EC',
    bottomColor: '#3A433B',
    topImage: InfluentialImage,
  },
  Realism: {
    color: '#31C3EC',
    bottomColor: '#65635A',
    topImage: ReticentImage,
  },
  Ethics: {
    color: '#31C3EC',
    bottomColor: '#784F3B',
    topImage: PoisedImage,
  },
  'Low profit focus': {
    color: '#31C3EC',
    bottomColor: '#2C4F52',
    topImage: ConsistentImage,
  },
  'Profit focus': {
    color: '#31C3EC',
    bottomColor: '#653822',
    topImage: AdaptableImage,
  },
};
