import React, { useState } from 'react';
import { InteractiveFeedbackMenuCardHub } from '../components/InteractiveFeedbackMenuCardHub';
import { InteractiveFeedbackCardType } from '../components/InteractiveFeedbackCard';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';

import { InteractiveFeedbackMenuRowType } from '../components/InteractiveFeedbackMenuRow';
import {
  InteractiveFeedbackMyDevelopmentCard,
  InteractiveFeedbackMyDevelopmentCardType,
} from '../components/InteractiveFeedbackMyDevelopmentCard';
import { useGetMyDevelopmentDetails } from '../hooks/useGetMyDevelopmentDetails';
import useMedia from 'use-media';
import { Spinner } from 'components/spinner/Spinner';
import ErrorFallback from 'components/fallbacks/ErrorFallback';

const CareerIcon =
  'https://psycruitplatform.blob.core.windows.net/findyourjoy-public/images/TeamStrengthsReports/TeamStrengthIcons/Guide_Summary_Icon.png';
const AdaptingIcon =
  'https://psycruitplatform.blob.core.windows.net/findyourjoy-public/images/TeamStrengthsReports/TeamStrengthIcons/Pragmatist_Summary_Icon.png';
const DevelopmentIcon =
  'https://psycruitplatform.blob.core.windows.net/findyourjoy-public/images/TeamStrengthsReports/TeamStrengthIcons/Producer_Summary_Icon.png';

type InteractiveFeedbackMyDevelopmentProps = {
  sectionData: any;
};

export const InteractiveFeedbackMyDevelopment: React.FC<InteractiveFeedbackMyDevelopmentProps> = ({
  sectionData,
}) => {
  const [showDevelopment, setShowDevelopment] = useState(false);
  const [showCareer, setShowCareer] = useState(false);
  const [showAdapting, setShowAdapting] = useState(false);
  const [animateDismount, setAnimateDismount] = useState(false);
  const [alternateView, setAlternateView] = useState(false);

  const isTablet = useMedia('(max-width: 768px)');

  const { t } = useTranslation('Interactive+Feedback', { i18n });

  const [data, loading, errors] = useGetMyDevelopmentDetails();

  if (loading || !data) return <Spinner />;

  if (errors) return <ErrorFallback />;

  const {
    developmentMenuOptions,
    adaptingMenuOptions,
    careerMenuOptions,
    developmentMenuCards,
    careerMenuCards,
    adaptingMenuCards,
    reportCode,
    isCandidateReport,
  } = data;

  const outerRows = [
    {
      text: t('SSCandidate_Interactive_Feedback_Development_Suggestions'),
      rowClick: () => {
        setShowDevelopment(true);
        setAnimateDismount(true);
      },
      icon: DevelopmentIcon,
      type: InteractiveFeedbackMenuRowType.TALL,
    },
    {
      text: t('SSCandidate_Interactive_Feedback_Your_Career_Suggestions'),
      rowClick: () => {
        setShowCareer(true);
        setAnimateDismount(true);
      },
      icon: CareerIcon,
      type: InteractiveFeedbackMenuRowType.TALL,
    },
    {
      text: t('SSCandidate_Interactive_Feedback_Adapting_Your_Strengths'),
      rowClick: () => {
        setShowAdapting(true);
        setAnimateDismount(true);
      },
      icon: AdaptingIcon,
      type: InteractiveFeedbackMenuRowType.TALL,
    },
  ];

  const outerCardDetails = [
    {
      type: InteractiveFeedbackCardType.ICON_LARGE,
      bottomColor: '#A9D98E',
      cardText: t(
        'SSCandidate_Interactive_Feedback_Development_Suggestions_Card'
      ),
      topImage: DevelopmentIcon,
      bottomFont: 'TTFirs-ExtraBold',
      bottomFontSize: '1.6rem',
      topColor: '#F1F8ED',
    },
    {
      type: InteractiveFeedbackCardType.ICON_LARGE,
      bottomColor: '#897015',
      cardText: t('SSCandidate_Interactive_Feedback_Career_Suggestions_Card'),
      topImage: CareerIcon,
      bottomFont: 'TTFirs-ExtraBold',
      bottomFontSize: '1.6rem',
      topColor: '#FDFBE6',
    },
    {
      type: InteractiveFeedbackCardType.ICON_LARGE,
      bottomColor: '#A9D98E',
      cardText: `${t(
        'SSCandidate_Interactive_Feedback_Adapting_Suggestions_Card_1'
      )}/n${t('SSCandidate_Interactive_Feedback_Adapting_Suggestions_Card_2')}`,
      topImage: AdaptingIcon,
      bottomFont: 'TTFirs-ExtraBold',
      bottomFontSize: '1.6rem',
      topColor: '#F1F8ED',
    },
  ];

  return (
    <div className="InteractiveFeedbackMyDevelopment WidthContent HeightContent">
      {((!alternateView && !isTablet) ||
        (isTablet && !showDevelopment && !showCareer && !showAdapting)) && (
        <InteractiveFeedbackMenuCardHub
          title={t('SSCandidate_Interactive_Feedback_My_Development')}
          cardDetails={[]}
          rows={outerRows}
          setAnimateDismount={setAnimateDismount}
          animateDismount={animateDismount}
          onAnimationEnd={() => {
            setAlternateView(true);
          }}
          reportCode={reportCode}
          isCandidateReport={isCandidateReport}
          sectionData={sectionData}
        />
      )}
      {(isTablet || alternateView) && showDevelopment && (
        <InteractiveFeedbackMenuCardHub
          title={t('SSCandidate_Interactive_Feedback_Development_Suggestions')}
          cardDetails={developmentMenuCards.cardsGeneral}
          headingCloseClickHandler={() => {
            setShowDevelopment(false);
            setAlternateView(false);
          }}
          defaultCard={outerCardDetails[0]}
          rows={developmentMenuOptions.rows}
          headingIcon={DevelopmentIcon}
          setAnimateDismount={setAnimateDismount}
          animateDismount={animateDismount}
          onAnimationEnd={() => {
            setAlternateView(true);
          }}
          sectionData={sectionData}
        >
          <InteractiveFeedbackMyDevelopmentCard
            myDevelopmentCardDetails={developmentMenuCards.cardsSpecific}
            type={InteractiveFeedbackMyDevelopmentCardType.DEVELOPMENT}
          />
        </InteractiveFeedbackMenuCardHub>
      )}
      {(isTablet || alternateView) && showCareer && (
        <InteractiveFeedbackMenuCardHub
          title={t('SSCandidate_Interactive_Feedback_Your_Career_Suggestions')}
          cardDetails={careerMenuCards.cardsGeneral}
          headingCloseClickHandler={() => {
            setShowCareer(false);
            setAlternateView(false);
          }}
          defaultCard={outerCardDetails[1]}
          rows={careerMenuOptions.rows}
          headingIcon={CareerIcon}
          setAnimateDismount={setAnimateDismount}
          animateDismount={animateDismount}
          onAnimationEnd={() => {
            setAlternateView(true);
          }}
          sectionData={sectionData}
        >
          <InteractiveFeedbackMyDevelopmentCard
            myDevelopmentCardDetails={careerMenuCards.cardsSpecific}
            type={InteractiveFeedbackMyDevelopmentCardType.CAREERS}
          />
        </InteractiveFeedbackMenuCardHub>
      )}
      {(isTablet || alternateView) && showAdapting && (
        <InteractiveFeedbackMenuCardHub
          title={t('SSCandidate_Interactive_Feedback_Adapting_Your_Strengths')}
          cardDetails={adaptingMenuCards.cardsGeneral}
          headingCloseClickHandler={() => {
            setShowAdapting(false);
            setAlternateView(false);
          }}
          defaultCard={outerCardDetails[2]}
          rows={adaptingMenuOptions.rows}
          headingIcon={AdaptingIcon}
          setAnimateDismount={setAnimateDismount}
          animateDismount={animateDismount}
          onAnimationEnd={() => {
            setAlternateView(true);
          }}
          sectionData={sectionData}
        >
          <InteractiveFeedbackMyDevelopmentCard
            myDevelopmentCardDetails={adaptingMenuCards.cardsSpecific}
            type={InteractiveFeedbackMyDevelopmentCardType.ADAPTING}
          />
        </InteractiveFeedbackMenuCardHub>
      )}
    </div>
  );
};
