import { Store } from './rootReducer';

export type Nullable<T> = T | null;

//Types
export enum LeftPanelActionType {
  JOB_BOARD_SIGN_IN = 'JOB_BOARD_SIGN_IN',
  JOB_BOARD_FILTERS = 'JOB_BOARD_FILTERS',
  MESSAGES = 'MESSAGES',
  OPEN_LEFT_PANEL = 'OPEN_LEFT_PANEL',
  CLOSE_LEFT_PANEL = 'CLOSE_LEFT_PANEL',
}

// State
export type PanelState =
  | {
      panelType: null;
      data: null;
    }
  | {
      panelType: LeftPanelActionType.JOB_BOARD_FILTERS;
      data: React.Component;
    }
  | {
      panelType: LeftPanelActionType.JOB_BOARD_SIGN_IN;
      data: React.Component;
    };

export const initialState: PanelState = {
  panelType: null,
  data: null,
};

// Actions
export type Action =
  | {
      type: LeftPanelActionType.OPEN_LEFT_PANEL;
      payload: OpenLeftPanel;
    }
  | {
      type: LeftPanelActionType.CLOSE_LEFT_PANEL;
      payload: null;
    };

// Reducer
export default function modalReducer(
  state = initialState,
  action: Action
): PanelState {
  switch (action.type) {
    case LeftPanelActionType.OPEN_LEFT_PANEL:
      return {
        ...state,
        panelType: action.payload.panelType,
        data: action.payload.data,
      };
    case LeftPanelActionType.CLOSE_LEFT_PANEL:
      return {
        ...state,
        panelType: null,
        data: null,
      };
    default:
      return state;
  }
}

export type OpenLeftPanel = {
  panelType: any | null;
  data: any | null;
};

// Action creators
export const openLeftPanel = (payload: OpenLeftPanel) => ({
  type: LeftPanelActionType.OPEN_LEFT_PANEL,
  payload,
});

export const closeLeftPanel = () => ({
  type: LeftPanelActionType.CLOSE_LEFT_PANEL,
});

// Selectors
export const selectLeftPanelState = (state: Store) => state.leftPanel;
export const selectLeftPanelType = (state: Store) => state.leftPanel.panelType;
