import React, { useState } from 'react';
import { InteractiveFeedbackMenuCardHub } from '../components/InteractiveFeedbackMenuCardHub';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';
import { Spinner } from 'components/spinner/Spinner';
import ErrorFallback from 'components/fallbacks/ErrorFallback';
import useMedia from 'use-media';
import {
  MyLeadershipStrengthsCardOptions,
  MyLeadershipStrengthsGraphCardOptions,
  useGetMyLeadershipStrengthsDetails,
} from '../hooks/useGetMyLeadershipStrengthsDetails';

type InteractiveFeedbackMyLeadershipStrengthsProps = {
  sectionData: any;
};

export const InteractiveFeedbackMyLeadershipStrengths: React.FC<InteractiveFeedbackMyLeadershipStrengthsProps> = ({
  sectionData,
}) => {
  const [animateDismount, setAnimateDismount] = useState(false);
  const [alternateView, setAlternateView] = useState(false);
  const isTablet = useMedia('(max-width: 768px)');

  const { t } = useTranslation('Interactive+Feedback', { i18n });

  const [data, loading, errors] = useGetMyLeadershipStrengthsDetails();

  if (loading || data === undefined || data === null) return <Spinner />;

  if (errors) return <ErrorFallback />;

  const {
    leadershipStrengthsWheelImage,
    leadershipStrengthsMenuCards,
    leadershipStrengthsGraphCards,
    reportCode,
    isCandidateReport,
  } = data as {
    leadershipStrengthsWheelImage: string;
    leadershipStrengthsMenuCards: MyLeadershipStrengthsCardOptions;
    leadershipStrengthsGraphCards: MyLeadershipStrengthsGraphCardOptions;
    reportCode: string;
    isCandidateReport: boolean;
  };

  return (
    <div className="InteractiveFeedbackMyLeadershipStrengths WidthContent HeightContent">
      <InteractiveFeedbackMenuCardHub
        title={t('SSCandidate_Interactive_Feedback_My_Leadership_Strengths')}
        cardDetails={leadershipStrengthsMenuCards.leadershipStrengthsCards}
        graphCardDetails={
          leadershipStrengthsGraphCards.leadershipStrengthsGraphCards
        }
        rows={[]}
        setAnimateDismount={setAnimateDismount}
        animateDismount={animateDismount}
        onAnimationEnd={() => {
          setAlternateView(true);
        }}
        reportCode={reportCode}
        isCandidateReport={isCandidateReport}
        wheelImage={leadershipStrengthsWheelImage}
        graphType={'LEADERSHIP'}
        sectionData={sectionData}
      />
    </div>
  );
};
