import React, { useEffect, useRef, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import dropDownArrow from '../../assets/apply.clevry/Icon_DropDown.png';
import { FilterBuilderMultiProps } from './models';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';

const FilterBuilderMulti: React.FC<any> = (props: FilterBuilderMultiProps) => {
  const multiChoiceWrapperRef = useRef<HTMLDivElement>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [dropDown, setDropdown] = useState(false);
  const [selected, setSelected] = useState<string[]>(
    props.selectedValues ?? []
  );

  const { t } = useTranslation('Filter+Builder+Multi', { i18n });
  const { onFocus = () => {}, onBlur = () => {} } = props;

  useEffect(() => {
    if (props.selectedValues) {
      setSelected(props.selectedValues);
    } else {
      setSelected([]);
    }
  }, [props.selectedValues]);

  const handleSearchChange = (e: any) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
  };

  const handleCheckboxChange = (e: any) => {
    const selectedId: string = e.target.id;
    if (selected.includes(selectedId)) {
      props.onChange(selected.filter((id) => id !== selectedId));
      setSelected(selected.filter((id) => id !== selectedId));
    } else {
      setSelected([...selected, selectedId]);
      props.onChange([...selected, selectedId]);
    }
  };

  const toggleDropdown = () => {
    if (!dropDown) {
      setDropdown(true);
    }
    if (multiChoiceWrapperRef.current) {
      multiChoiceWrapperRef.current.classList.add('Expanded');
    }
  };

  const menuNumber = 1;

  return (
    <React.Fragment>
      <div className="MultiChoiceWrapper" ref={multiChoiceWrapperRef}>
        <div className="MultiChoice">
          <div className="SearchWrapper" onClick={toggleDropdown}>
            <div className="FormFieldWithIcon LeftFFWI">
              <img className="DropDownArrow" src={dropDownArrow} />
              <span className="Icon_Field Icon_Field_14"></span>
              <input
                autoCapitalize="none"
                autoComplete="new-password"
                autoCorrect="off"
                className="EditBox SearchBox"
                id="SearchInputField"
                maxLength={200}
                name="namesearch"
                type="text"
                onChange={handleSearchChange}
                placeholder={t(
                  'SSCandidate_Filter_Builder_Multi_Placeholder_Please_Select'
                )}
                onFocus={onFocus}
                onBlur={onBlur}
              />
            </div>
          </div>
          {dropDown && (
            <Scrollbars style={{ width: 'calc(100% + 1px)' }}>
              {props.possibleValues
                .filter(
                  (entry) =>
                    searchTerm === '' ||
                    entry.label
                      .toLowerCase()
                      .indexOf(searchTerm.toLowerCase()) !== -1
                )
                .map((Row) => {
                  return (
                    <div className="RowWrapper">
                      <div className="RadioButton" key={Row.value}>
                        <input
                          type="checkbox"
                          id={
                            typeof Row.value === 'number'
                              ? Row.value.toString()
                              : Row.value
                          }
                          name={`multiSelectMenu${menuNumber}`}
                          checked={selected.some((selectedId) =>
                            typeof Row.value === 'string'
                              ? selectedId.toLowerCase() ===
                                Row.value.toLowerCase()
                              : selectedId.toLowerCase() ===
                                Row.value.toString().toLowerCase()
                          )}
                          onChange={handleCheckboxChange}
                        />
                        <label
                          className="CustomInput"
                          htmlFor={
                            typeof Row.value === 'number'
                              ? Row.value.toString()
                              : Row.value
                          }
                        >
                          {Row.label}
                        </label>
                      </div>
                    </div>
                  );
                })}
            </Scrollbars>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default FilterBuilderMulti;
