import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from '@reach/router';
import { selectAuthStatus } from 'reducers/auth';
import { AppPath } from 'appConstants';

const Private: React.FC = ({ children }) => {
  const authStatus = useSelector(selectAuthStatus);

  const applicationReference = sessionStorage.getItem('applicationReference');

  if (authStatus === 'signed_out') {
    return applicationReference ? (
      <Redirect noThrow to={AppPath.ASSESSMENTS_BROWSER_CHECKER} />
    ) : (
      <Redirect noThrow to={AppPath.ROOT} />
    );
  }

  return <React.Fragment>{children}</React.Fragment>;
};

export default Private;
