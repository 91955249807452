import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Form, Formik } from 'formik';
import LanguageSearchDropdown from './LanguageSearchDropdown';
import { useDispatch, useSelector } from 'react-redux';
import { LanguageSearchDropdownApi } from './LanguageSearchDropdownApi';
import { selectDashboardInfo, setDashboardInfo } from 'reducers/dashboardInfo';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';
import { useCookies } from 'react-cookie';
import { isProduction, isStaging, isUAT } from 'config';

import englishFlag from 'assets/lang_flags/en-GB.png';
import swedishFlag from 'assets/lang_flags/sv-SE.png';
import finnishFlag from 'assets/lang_flags/fi-FI.png';

const ONE_YEAR_IN_HOURS = 24 * 365;

type LanguageListReponseDetails = {
  name: string;
  cultureInfo: string;
  imagePath: string;
  imageExtension: string;
  imageUrl: any;
}[];

export type supportedLanguagesData = {
  value: string;
  label: string;
  image: any;
};

export type allSupportedLanguagesData = supportedLanguagesData[];

const LanguageSearchDropdownContainer: React.FC<RouteComponentProps> = () => {
  const dispatch = useDispatch();
  const dashboardInfo = useSelector(selectDashboardInfo);

  const emptyInitalLanguageValue = {
    value: '',
    label: '',
    image: null,
  };

  // Cookie Domain:
  let cookieDomain = '';

  if (isProduction || isStaging) {
    cookieDomain = '.clevry.com';
  } else if (isUAT) {
    cookieDomain = '.avamae.co.uk';
  }

  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [languageValue, setLanguageValue] = useState<any>(
    emptyInitalLanguageValue
  );
  const [languagesList, setLanguagesList] =
    useState<allSupportedLanguagesData | null>(null);

  const { t } = useTranslation("Interactive Feedback", { i18n });

  const [cookies, setCookie, removeCookie] = useCookies();

  const backupLanguageList: supportedLanguagesData[] = [
    {
      value: 'en-GB',
      label: t('SSCandidate_Settings_English'),
      image: englishFlag,
    },
    {
      value: 'sv-SE',
      label: t('SSCandidate_Settings_Swedish'),
      image: swedishFlag,
    },
    {
      value: 'fi-FI',
      label: t('SSCandidate_Settings_Finnish'),
      image: finnishFlag,
    },
  ];

  const handleLabelAndImage = (cultureCode: any) => {
    const findItem = languagesList
      ? languagesList.find((item) => item.value === cultureCode)
      : backupLanguageList.find((item) => item.value === cultureCode);
    if (findItem) {
      return [findItem.label, findItem.image];
    }
    return ['', null];
  };

  const [langDropdownToggle, setLangDropdownToggle] = useState<boolean>(false);

  function toggleLanguageDropdown() {
    setLangDropdownToggle(!langDropdownToggle);
  }

  const parseLanguageList = (
    data: LanguageListReponseDetails
  ): allSupportedLanguagesData => {
    return data.map((item) => {
      return {
        value: item.cultureInfo,
        label: item.name,
        image: item.imageUrl,
      };
    });
  };

  const handleLanguageDropdown = (e: any) => {
    setLanguageValue(e);
    (async () => {
      const result = await LanguageSearchDropdownApi.setLanguage(e.value);

      if (result.data === '1') {
        // Expire in 1 year:
        const cookieExpireTime = new Date();
        cookieExpireTime.setHours(
          cookieExpireTime.getHours() + ONE_YEAR_IN_HOURS
        );

        // staging:

        if (cookieDomain !== '') {
          setCookie('cultureInfo', e.value, {
            domain: cookieDomain,
            expires: cookieExpireTime,
          });
        }

        // localhost:
        // setCookie('cultureInfo', e.value, { expires: cookieExpireTime });

        i18n.changeLanguage(e.value);

        setSuccessMessage(t('SSCandidate_Settings_Language_Changed'));

        dispatch(setDashboardInfo({ ...dashboardInfo, cultureCode: e.value }));
      }
      if (result.error && result.error.length > 0) {
        setErrorMessage(result.error[0]);
      }
    })();

    toggleLanguageDropdown();
  };

  useEffect(() => {
    (async () => {
      // LanguagesList:
      try {
        const data = await LanguageSearchDropdownApi.getLanguageList();

        if (data.response && data.response.length > 0) {
          const parsedAPIData = parseLanguageList(data.response);

          // return parsedAPIData;
          setLanguagesList(parsedAPIData);
        } else {
          console.log('Failed To Get Language List');

          setLanguagesList([
            { value: 'en-GB', label: 'English', image: englishFlag },
          ]);
        }
      } catch (error) {
        console.error(error);

        setLanguagesList([
          { value: 'en-GB', label: 'English', image: englishFlag },
        ]);
      }

      // LanguageValue:
      try {
        if (cookies['cultureInfo']) {
          const cultureInfoCookie = cookies['cultureInfo'];

          const [languageLabel, languageImage] =
            handleLabelAndImage(cultureInfoCookie);

          if (languageLabel !== '') {
            setLanguageValue({
              value: cultureInfoCookie,
              label: languageLabel,
              image: languageImage,
            });
          } else {
            // NOTE: I don't think we want "English" translated here
            setLanguageValue({
              value: 'en-GB',
              label: 'English',
              image: englishFlag,
            });
          }

          // i18n.changeLanguage(cultureInfoCookie);
        } else {
          // NOTE: I don't think we want "English" translated here
          setLanguageValue({
            value: 'en-GB',
            label: 'English',
            image: englishFlag,
          });

          // i18n.changeLanguage('en-GB');
        }
      } catch {
        // NOTE: I don't think we want "English" translated here
        setLanguageValue({
          value: 'en-GB',
          label: 'English',
          image: englishFlag,
        });

        // i18n.changeLanguage('en-GB');
      }
    })();
  }, []);

  return (
    <div className="LanguageSearch">
      <Formik initialValues={{}} onSubmit={() => {}}>
        <Form>
          <div className="FormAndLabel">
            <div className="FormBlock">
              {languagesList && languageValue !== emptyInitalLanguageValue ? (
                <LanguageSearchDropdown
                  isOpen={langDropdownToggle}
                  options={languagesList}
                  value={languageValue}
                  onChange={handleLanguageDropdown}
                  toggleDropdown={() => {
                    toggleLanguageDropdown();
                  }}
                />
              ) : (
                <div className="LanguagesListLoading">
                  {' '}
                  {t('SSCandidate_Settings_Loading')}
                </div>
              )}
            </div>
          </div>
          {(successMessage || errorMessage) && (
            <div className="MessageContainer">
              {successMessage ? <p>{successMessage}</p> : null}
              {errorMessage ? (
                <p className="ErrorLabel">{errorMessage}</p>
              ) : null}
            </div>
          )}
        </Form>
      </Formik>
    </div>
  );
};

export default LanguageSearchDropdownContainer;
