import { MatchingProcessDeleteIcon } from '../../MatchingProcessDeleteIcon';
import { FormRadioButtonsField } from '../CustomFormFields/FormRadioButtonsField';
import RowIcon from 'assets/apply.clevry/Right_Answer.png';
import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import {
  CriticalHardSkillsMenuOptions,
  CriticalHardSkillsPageFormikValues,
} from './CriticalHardSkillsPage';

type CriticalHardSkillsPageRowProps = {
  rowNumber: number;
  remove?: <T>(index: number) => T | undefined;
  levels: CriticalHardSkillsMenuOptions['level'];
  // The field names are not consistent across hard skills endpoints, so the below is used to dynamically set field values
  fieldValueName?: string;
  valueLabelFieldName: string;
  valueSkillIdFieldName: string;
  valueLevelIdFieldName: string;
  //
  hideTickIcon?: boolean;
};

const CriticalHardSkillsPageRow = ({
  rowNumber,
  remove,
  levels,
  fieldValueName,
  valueLabelFieldName,
  valueSkillIdFieldName,
  valueLevelIdFieldName,
  hideTickIcon = false,
}: CriticalHardSkillsPageRowProps) => {
  const { setFieldValue, values } = useFormikContext<any>();

  useEffect(() => {
    setFieldValue(
      `${
        fieldValueName ? fieldValueName : ''
      }[${rowNumber}]${valueSkillIdFieldName}`,
      fieldValueName
        ? `${values[fieldValueName][rowNumber][valueSkillIdFieldName]}`
        : `${values[rowNumber][valueSkillIdFieldName]}`
    );
  }, [setFieldValue]);

  return (
    <div className="MatchingProcessPageRow CriticalHardSkillsPageRow">
      {!hideTickIcon ? (
        <img src={RowIcon} alt="" className="CriticalHardSkillsPageRow__icon" />
      ) : null}
      <div className="CriticalHardSkillsPageRow__label">
        {fieldValueName
          ? values[fieldValueName][rowNumber][valueLabelFieldName]
          : values[rowNumber][valueLabelFieldName]}
      </div>
      <FormRadioButtonsField
        fieldName={
          fieldValueName
            ? `[${fieldValueName}][${rowNumber}][${valueLevelIdFieldName}]`
            : `[${rowNumber}][${valueLevelIdFieldName}]`
        }
        label={'Competence level'}
        options={levels}
        key={`hardSkills-${rowNumber}`}
      />
      {remove ? (
        <MatchingProcessDeleteIcon
          onClick={() => {
            remove(rowNumber);
          }}
        />
      ) : null}
    </div>
  );
};

export default CriticalHardSkillsPageRow;
