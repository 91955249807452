import i18n from 'i18n/i18n';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { closeModal } from 'reducers/modal';
import { useTranslation } from 'react-i18next';
import JoytronIcon from 'assets/apply.clevry/Img_Robot_Joybuster.png';
import HourglassIcon from 'assets/apply.clevry/Icon_Hourglass.jpg';
import InfoIcon from 'assets/apply.clevry/Icon_Password_Info.png';

type AssessmentLoadingAntiCheatingModalProps = {
  data: {
    timeoutFunction: () => void;
    overrideCloseModal?: boolean;
  };
};

export const AssessmentLoadingAntiCheatingModal: React.FC<
  AssessmentLoadingAntiCheatingModalProps
> = ({ data }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('Assessment+-+Countermeasures+Modal', { i18n });

  useEffect(() => {
    setTimeout(() => handleTimeout(), 3400);
  }, []);

  const handleTimeout = () => {
    data.timeoutFunction();
    // if (!data.overrideCloseModal) {
    //   dispatch(closeModal());
    // }
  };

  const modalStyle = {
    '--modalTitleColor': '#036C8F',
    '--footerBackgroundColor': '#f5f5f5',
    '--footerBorderColor': '#f5f5f5',
    '--primaryButtonBackgroundColor': '#36705B',
    '--tertiaryButtonBackgroundColor': '#d5d5d5',
  } as React.CSSProperties;

  return (
    <div className="AssessmentModal AntiCheating" style={modalStyle}>
      <div className="Content">
        <div className="MainIcon">
          <img src={JoytronIcon} alt="Joytron robot" />
        </div>
        <h2>{t('SSCandidate_Countermeasures_Modal_Launching')}</h2>
        <div className="LoadingIcon">
          <img src={HourglassIcon} alt="Loading" />
        </div>

        <div className="InfoContainer">
          {t('SSCandidate_Countermeasures_Modal_Whats_This')}
          <div className="MoreInfo">
            <img src={InfoIcon} alt="More info" />
            <div className="Message">
              {t('SSCandidate_Countermeasures_Modal_Whats_This_Tooltip')}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
