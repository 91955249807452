import { TextInput } from 'components/formFields/TextInput';
import LocationSelector from 'components/LocationSelector/LocationSelector';
import { FormSingleSelectCustomField } from 'components/pages/jobApplication/CustomElementForm/CustomFormFields/FormSingleSelectCustomField';
import { MatchingProcessDeleteIcon } from '../../MatchingProcessDeleteIcon';
import { CheckboxInput } from 'components/formFields/CheckboxInput';

type menuOptionsInner = {
  label: string;
  value: number | string;
}[];

type ExperienceRowProps = {
  rowNumber: number;
  remove: <T>(index: number) => T | undefined;
  menuOptions: {
    roleTitle: menuOptionsInner;
    roleLevel: menuOptionsInner;
    industries: menuOptionsInner;
    headcountValues: menuOptionsInner;
  };
  setFieldValue: (field: string, value: any, shouldValidate?: any) => void;
  location: string;
  values: any;
};

const ExperienceRow = ({
  rowNumber,
  remove,
  menuOptions,
  setFieldValue,
  location,
  values,
}: ExperienceRowProps) => {
  const handleCurrentPositionSelection = (
    values: any,
    idx: number,
    e: any,
    setFieldValue: any
  ) => {
    if (e.target.checked === true) {
      for (let i = 0; i < values.length; i++) {
        if (values[i] !== idx) {
          setFieldValue(`experience.${i}.isCurrentPosition`, false);
        }
      }
    }
  };

  return (
    <div className="MatchingProcessPageRow">
      <FormSingleSelectCustomField
        fieldName={`experience.${rowNumber}.roleTitle`}
        label={`Role title`}
        options={menuOptions.roleTitle}
        useNumberForValue={true}
        showError={false}
      />

      <FormSingleSelectCustomField
        fieldName={`experience.${rowNumber}.roleLevel`}
        label={`Role level`}
        options={menuOptions.roleLevel}
        useNumberForValue={true}
        showError={false}
      />
      <TextInput
        name={`experience.${rowNumber}.years`}
        label={`Years of exp.`}
        maxLength={20}
        noTouch={true}
        isNumber
        additionalClassName="ExperienceYear"
      />
      <FormSingleSelectCustomField
        fieldName={`experience.${rowNumber}.sectorID`}
        label={`Industry`}
        options={menuOptions.industries}
        useNumberForValue={true}
        showError={false}
      />
      <TextInput
        name={`experience.${rowNumber}.companyName`}
        label={`Company name`}
        maxLength={20}
        noTouch={true}
      />
      <FormSingleSelectCustomField
        fieldName={`experience.${rowNumber}.companyHeadcount`}
        label={`Company headcount`}
        options={menuOptions.headcountValues}
        useNumberForValue={true}
        showError={false}
      />

      <div className="FormBox FormSideBySide">
        <div className="FormLabel">Location</div>
        <LocationSelector
          locationChoice={location}
          updateLocation={(location, longitude, latitude) => {
            setFieldValue(`experience.${rowNumber}.location`, location);
            setFieldValue(`experience.${rowNumber}.longitude`, longitude);
            setFieldValue(`experience.${rowNumber}.latitude`, latitude);
          }}
          readOnly={false}
          push={() => {}}
        />
      </div>

      <CheckboxInput
        label={'Current position'}
        name={`experience.${rowNumber}.isCurrentPosition`}
        className="ExperienceCheckboxInput"
        isBordered={true}
        onClick={(e: any) =>
          handleCurrentPositionSelection(
            values.experience,
            rowNumber,
            e,
            setFieldValue
          )
        }
      />

      <MatchingProcessDeleteIcon
        onClick={() => {
          remove(rowNumber);
        }}
      />
    </div>
  );
};

export default ExperienceRow;
