import { Store } from './rootReducer';

export type Nullable<T> = T | null;

//Types
export enum RightPanelActionType {
  JOB_BOARD_SIGN_IN = 'JOB_BOARD_SIGN_IN',
  MATCHING_EDIT_DETAILS = 'MATCHING_EDIT_DETAILS',
  JOB_BOARD_FILTERS = 'JOB_BOARD_FILTERS',
  MATCH_CONNECTION_VIEW_MESSAGE = 'MATCH_CONNECTION_VIEW_MESSAGE',
  MATCH_CONNECTION = 'MATCH_CONNECTION',
  MESSAGES = 'MESSAGES',
  OPEN_RIGHT_PANEL = 'OPEN_RIGHT_PANEL',
  CLOSE_RIGHT_PANEL = 'CLOSE_RIGHT_PANEL',
  HIRING_MANAGER_PROFILE = 'HIRING_MANAGER_PROFILE',
}

// State
export type PanelState =
  | {
      panelType: null;
      data: null;
    }
  | {
      panelType: RightPanelActionType.JOB_BOARD_FILTERS;
      data: React.Component;
    }
  | {
      panelType: RightPanelActionType.JOB_BOARD_SIGN_IN;
      data: React.Component;
    }
  | {
      panelType: RightPanelActionType.MATCHING_EDIT_DETAILS;
      data: any; // Temporary
    }
  | {
      panelType: RightPanelActionType.MATCH_CONNECTION_VIEW_MESSAGE;
      data: any; // Temporary
    }
  | {
      panelType: RightPanelActionType.MATCH_CONNECTION;
      data: any; // Temporary
    }
  | {
      panelType: RightPanelActionType.HIRING_MANAGER_PROFILE;
      data: any; // Temporary
    };

export const initialState: PanelState = {
  panelType: null,
  data: null,
};

// Actions
export type Action =
  | {
      type: RightPanelActionType.OPEN_RIGHT_PANEL;
      payload: OpenRightPanel;
    }
  | {
      type: RightPanelActionType.CLOSE_RIGHT_PANEL;
      payload: null;
    };

// Reducer
export default function modalReducer(
  state = initialState,
  action: Action
): PanelState {
  switch (action.type) {
    case RightPanelActionType.OPEN_RIGHT_PANEL:
      return {
        ...state,
        panelType: action.payload.panelType,
        data: action.payload.data,
      };
    case RightPanelActionType.CLOSE_RIGHT_PANEL:
      return {
        ...state,
        panelType: null,
        data: null,
      };
    default:
      return state;
  }
}

export type OpenRightPanel = {
  panelType: any | null;
  data: any | null;
};

// Action creators
export const openRightPanel = (payload: OpenRightPanel) => ({
  type: RightPanelActionType.OPEN_RIGHT_PANEL,
  payload,
});

export const closeRightPanel = () => ({
  type: RightPanelActionType.CLOSE_RIGHT_PANEL,
});

// Selectors
export const selectRightPanelState = (state: Store) => state.rightPanel;
export const selectRightPanelType = (state: Store) =>
  state.rightPanel.panelType;
