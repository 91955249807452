import { useRef } from 'react';
import { toast, ToastPosition } from 'react-toastify';
import uniqid from 'uniqid';

export enum ToastType {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export const useToast = ({
  message,
  color,
  type,
}: {
  message: string;
  color?: string;
  type?: ToastType;
}) => {
  const isToastOpen = useRef(false);
  const toastConfig = {
    position: 'bottom-center' as ToastPosition,
    autoClose: 5000,
    closeOnClick: true,
    pauseOnHover: true,
    className: 'GeneralToast',
    onOpen: () => {
      isToastOpen.current = true;
    },
    onClose: () => {
      isToastOpen.current = false;
    },
    style: {
      backgroundColor:
        type === ToastType.SUCCESS
          ? '#124a57'
          : type === ToastType.ERROR
          ? '#c77979'
          : color
          ? color
          : '#124a57',
    },
    toastId: uniqid(),
  };

  const toastChecks = !isToastOpen.current && window && window.location;

  const ToastMessage = () => {
    if (toastChecks) {
      toast.success(<span>{message}</span>, toastConfig);
    }
  };

  return ToastMessage;
};
