import { decryptApplicationLink } from 'components/pages/signUp/signUpApi';
import { AppPath, paths } from 'appConstants';
import { navigate } from '@reach/router';
import {
  applyForJob,
  getApplicationStatus,
} from 'components/pages/jobBoard/JobBoardApi';
import { Action, AuthStatus, setApplicationLinkToken } from 'reducers/auth';
import { Dispatch, SetStateAction } from 'react';
import { getApplicationFormDetails } from './projectTokenApi';
import { getApplicationWorkflow } from 'components/pages/viewApplication/ViewApplicationApi';
import { ACTIONS } from 'reducers/landingPage';
import { setApplicationType, setProjectId } from 'reducers/virtualAssessments';

type ProjectTokenResponse = {
  projectID: number;
  jobApplicationID: number;
  isApplicationFormComplete: boolean;
  isApplicationLive: boolean;
};

const navigateToApplicationForm = async (data: ProjectTokenResponse) => {
  if (data.jobApplicationID === 0) {
    // The user has not applied to project

    navigate(`/job-description/${data.projectID}`);
  } else if (data.jobApplicationID > 0) {
    if (data.isApplicationLive) {
      // The user is in the application process

      if (data.isApplicationFormComplete) {
        // The user has completed the application, go to my applications

        navigate(`/view-application/${data.jobApplicationID}/live/stages`, {
          state: { projectId: data.projectID },
        });
      } else {
        // The user has not finished applying, go to getapplicationformdetails

        navigate(`/job-application`, {
          state: {
            applicationFormId: data.jobApplicationID,
            projectId: data.projectID,
          },
        });
      }
    } else {
      // The user has either withdrawn or been rejected, go to browse opportunites and display a message
      navigate(paths.browseOpportunities.url, {
        state: { openJobNotAvailableModal: true },
      });
    }
  } else {
    // Fallback
    navigate(paths.browseOpportunities.url, {
      state: { openJobNotFoundModal: true },
    });
  }
};

export const handleProjectToken = (
  authStatus: AuthStatus,
  landingRedirectURL: string | null,
  applicationLinkToken: string | null,
  applicationLinkStarted: boolean,
  setApplicationLinkStarted: (payload: boolean) => Action,
  dispatch: Dispatch<any>,
  hasApplications: boolean,
  closeLandingPageForm: () => { type: ACTIONS },
  applicationType?: string | null,
  isAssessmentOnly?: boolean | null,
  projectID?: number | null,
  applyForJobTriggered?: boolean | null,
  setApplyForJobTriggered?: Dispatch<SetStateAction<boolean>> | null,
  assessmentOnlyCandidate?: boolean
) => {
  // const currentWebskin = window.sessionStorage.getItem('webskin')
  //   ? window.sessionStorage.getItem('webskin')
  //   : 'defaultTheme';

  if (authStatus === 'signed_in') {
    if (
      applicationLinkToken ||
      (applicationType &&
        applicationType === 'PUBLIC_JOB_APPLICATION' &&
        projectID)
    ) {
      setApplyForJobTriggered && setApplyForJobTriggered(true);
      dispatch(setApplicationLinkStarted(true));

      (async () => {
        let result;

        if (applicationLinkToken) {
          result = await decryptApplicationLink({
            encryptedToken: applicationLinkToken,
          });
        } else {
          result = await getApplicationStatus({
            ProjectId: projectID,
          });
        }

        if (
          result &&
          result.response &&
          ((result.errors && result.errors.length === 0) ||
            result.errors === null)
        ) {
          if (result.response.isApplicationFormComplete) {
            navigate(
              `/view-application/${result.response.jobApplicationID}/live/stages`,
              {
                state: { projectId: projectID },
              }
            );
          } else if (isAssessmentOnly && projectID && !applyForJobTriggered) {
            const result = await applyForJob({
              ProjectId: projectID,
            });

            if (result && result.response && result.response.status === '1') {
              const AssessmentId = result.response.details.assessmentID;

              navigate(`/view-application/${AssessmentId}/live/stages`, {
                state: { projectId: projectID },
              });
            }
          } else {
            navigateToApplicationForm(result.response);
          }
        } else {
          navigate(paths.joyAtWork.url);
        }

        dispatch(setApplicationLinkToken(null));
        dispatch(setApplicationLinkStarted(false));
        dispatch(setProjectId(null));
        dispatch(setApplicationType(null));
      })();
    } else if (
      applicationType &&
      applicationType === 'PUBLIC_EXPRESS_INTEREST'
    ) {
      navigate('/matching-process');
    } else if (assessmentOnlyCandidate) {
      navigate(paths.assessmentsOverview.url);
    } else if (landingRedirectURL) {
      navigate(landingRedirectURL);
    } else {
      navigate(AppPath.HOME);
    }

    closeLandingPageForm(); // prevent sign in & sign up forms rendering again after submit and re-running this function
  }
};
