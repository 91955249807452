import { Store } from './rootReducer';

export type Nullable<T> = T | null;

//Types
export enum ACTIONS {
  'OPEN_SIGN_IN' = 'OPEN_SIGN_IN',
  'OPEN_SIGN_UP' = 'OPEN_SIGN_UP',
  'CLOSE_FORM' = 'CLOSE_FORM',
}

// State
export type OpenFormState = {
  landingPageForm: string | null;
  redirectURL: string | null;
};

export const initialState: OpenFormState = {
  landingPageForm: null,
  redirectURL: null,
};

// Actions
export type Action =
  | {
      type: ACTIONS.OPEN_SIGN_IN;
    }
  | {
      type: ACTIONS.OPEN_SIGN_UP;
      payload: string | null;
    }
  | {
      type: ACTIONS.CLOSE_FORM;
    };

// Reducer
export default function modalReducer(
  state = initialState,
  action: Action
): OpenFormState {
  switch (action.type) {
    case ACTIONS.OPEN_SIGN_IN:
      return {
        ...state,
        landingPageForm: 'SIGN_IN',
      };
    case ACTIONS.OPEN_SIGN_UP:
      return {
        ...state,
        landingPageForm: 'SIGN_UP',
        redirectURL: action.payload,
      };
    case ACTIONS.CLOSE_FORM:
      return {
        ...state,
        landingPageForm: null,
        redirectURL: null,
      };

    default:
      return state;
  }
}

// Action creators
export const setOpenSignInForm = () => ({
  type: ACTIONS.OPEN_SIGN_IN,
});
export const setOpenSignUpForm = (payload?: string) => ({
  type: ACTIONS.OPEN_SIGN_UP,
  payload,
});
export const setCloseLandingPageForm = () => ({
  type: ACTIONS.CLOSE_FORM,
});
// Selectors
export const selectSignInFormState = (state: Store) =>
  state.landingPage.landingPageForm;
export const selectSignInRedirectURL = (state: Store) =>
  state.landingPage.redirectURL;
