import React from 'react';
import { OtherNavData } from 'reducers/navbar';
import backIcon from 'assets/job-board-widget/Icon_Back.png';
import { navigate } from '@reach/router';
import { AppPath } from 'appConstants';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';

type TitleAndBackNavBarProps = OtherNavData;

export const TitleAndBackNavBar: React.FC<TitleAndBackNavBarProps> = ({
  title,
}) => {
  const { t } = useTranslation('Title+And+Back+Nav+Bar', { i18n });

  const handleBackButtonClick = () => {
    navigate(AppPath.ROOT);
    window.scrollTo(0, 0);
  };

  return (
    <nav className="Nav LoggedOut">
      <div className="PageNavBar TitleAndBackNav">
        <button className="BackButton" onClick={handleBackButtonClick}>
          <div>
            <img
              src={backIcon}
              alt={t('SSCandidate_Title_And_Back_Nav_Bar_Alt_Go_Back')}
            />
          </div>
        </button>
        <h1 className="Title">{title}</h1>
        <div className="TitleAndBackDummyFlexElement"></div>
      </div>
    </nav>
  );
};
