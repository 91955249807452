import { ApiResponse } from '@avamae/formbuilder/dist/FormBuilder';
import { RouteComponentProps } from '@reach/router';
import instance from 'api';
import { Spinner } from 'components/spinner/Spinner';
import { endpoints } from 'config';
import { addHubspotTracking } from 'helpers/addHubspotTracking';
import React, { createContext, useEffect, useState } from 'react';
import { themes } from 'theme';
import { FindYourJoyOrderImages } from './FindYourJoyOrderImages';
import { FindYourJoyResults } from './FindYourJoyResults';
import { FindYourJoySelectImages } from './FindYourJoySelectImages';
import {
  FindYourJoyContext,
  FindYourJoyQuestionAnswer,
  FindYourJoyQuiz,
  FindYourJoyQuizStep,
} from './models';

export const FindYourJoyQuizContext = createContext<FindYourJoyContext | null>(
  null
);

type FindYourJoyProps = RouteComponentProps & { isCustomer?: boolean };

const FindYourJoy: React.FC<FindYourJoyProps> = ({ isCustomer }) => {
  const [quiz, setQuiz] = useState<null | FindYourJoyQuiz>(null);
  const [answers, setAnswers] = useState<FindYourJoyQuestionAnswer[]>([]);
  const [currentQuizStep, setCurrentQuizStep] = useState<FindYourJoyQuizStep>(
    FindYourJoyQuizStep.SELECT
  );

  useEffect(() => {
    const script = addHubspotTracking();

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    instance
      .get<ApiResponse<FindYourJoyQuiz>>(endpoints.findYourJoy.getQuestions, {
        // TODO(HC): Update this once localization implemented.
        params: { languageId: 1 },
      })
      .then((response) => {
        console.log(response);
        if (response.data.status === '1') {
          setQuiz(response.data.details);
        }
      });
  }, []);

  const providerValue = quiz
    ? {
        quiz,
        answers,
        setAnswers,
        currentQuizStep,
        setCurrentQuizStep,
        isCustomer: !!isCustomer,
      }
    : null;

  return (
    <FindYourJoyQuizContext.Provider value={providerValue}>
      <div className="FindYourJoy HeightContent">
        {providerValue ? (
          <div className="WidthContent FindYourJoyContent">
            {providerValue.currentQuizStep === FindYourJoyQuizStep.SELECT ? (
              <FindYourJoySelectImages />
            ) : providerValue.currentQuizStep === FindYourJoyQuizStep.ORDER ? (
              <FindYourJoyOrderImages />
            ) : providerValue.currentQuizStep ===
              FindYourJoyQuizStep.RESULTS ? (
              <FindYourJoyResults />
            ) : null}
          </div>
        ) : (
          <Spinner color={isCustomer ? '#429CC' : themes.defaultTheme.one} />
        )}
      </div>
    </FindYourJoyQuizContext.Provider>
  );
};

export default FindYourJoy;
