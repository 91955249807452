import instance, { ApiResponse } from 'api';
import { AxiosError, AxiosResponse } from 'axios';
import { BaseApi } from 'baseApi';
import { endpoints } from 'config';
import { Contact, UpdateContactFormData } from './models';

export class UpdateContactApi extends BaseApi {
  public static updateContactInfo = async (
    payload: UpdateContactFormData
  ): Promise<any> => {
    try {
      const formData = new window.FormData();
      for (const key in payload) {
        if (key !== 'homeAddress' && key !== 'profilePictureURL') {
          if (payload[key as keyof typeof payload]) {
            formData.append(key, payload[key as keyof typeof payload]);
          }
        }
      }

      for (const key in payload.homeAddress) {
        // Not including null values
        if (payload.homeAddress[key as keyof typeof payload.homeAddress]) {
          // can't seem to set number as value in FormData.
          const value = payload.homeAddress[
            key as keyof typeof payload.homeAddress
          ] as string;
          formData.set(`HomeAddress.${key}`, value);
        }
      }

      const result: AxiosResponse<ApiResponse<any>> = await instance.post(
        endpoints.account.updateContactInfo,
        formData,
        {
          headers: { 'content-type': 'multipart/form-data' },
        }
      );
      if (UpdateContactApi.isResponseSuccessful(result.data)) {
        return result;
      }
    } catch (error) {
      if ((error as any).response) {
        const errors = (error as any).response.errors;
        if (errors) {
        }
      }
    }
  };
  public static getContactInfo = async (): Promise<any> => {
    try {
      const result: AxiosResponse<ApiResponse<any>> = await instance.get(
        endpoints.account.viewContactInformation,
        { withCredentials: true }
      );
      if (UpdateContactApi.isResponseSuccessful(result.data)) {
        return UpdateContactApi.successResponse(result.data.details);
      }
    } catch (error) {
      if ((error as any).response) {
        const errors = (error as any).response.errors;
        if (errors) {
        }
      }
    }
  };
}
