import api, { ApiResponse, ErrorMessage } from 'api';
import { AxiosError, AxiosRequestConfig } from 'axios';
import { FindYourJoyQuestionAnswer } from 'components/pages/findYourJoy/models';
import { endpoints } from 'config';
import { addSeconds } from 'date-fns';
import { loadTokenResource, saveTokenResource, Scopes } from 'helpers/auth';
import { produce } from 'immer';
import { Store } from 'reducers/rootReducer';
import { combineEpics, ofType, StateObservable } from 'redux-observable';
import { createSelector } from 'reselect';
import { from, Observable, of } from 'rxjs';
import {
  catchError,
  ignoreElements,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import {
  GARegularSignUp,
  GASignIn,
  GASignUpAfterQuiz,
  GASignUpFromInvite,
  GASignUpFromTeamInvite,
} from 'helpers/gaEvents';
import { BooleanSchema } from 'yup';
import { navigate } from '@reach/router';
import { useDispatch } from 'react-redux';

const GENERIC_ERROR_MESSAGE_KEY = 'generic';

export type SignUpAttempt = {
  firstName: string;
  lastName: string;
  emailAddress: string;
  password: string;
  questionAnswers: FindYourJoyQuestionAnswer[] | null;
  preRegistrationTestType: string;
  inviteToken?: string | null;
  inviteType?: string | null;
  assessmentId?: number | null;
  gender?: string | null;
  bAssessmentOnlySignup?: boolean | null;
};

export type SignInAttempt = {
  username: string;
  password: string;
  rememberMe: boolean;
  inviteToken?: string | null;
};

export type SignInError = {
  errorType: string;
  fieldName: string;
  messageCode: string;
};

export type SignInOkayDetails = {
  accessToken: string;
  expiresIn: string;
  refreshToken: string;
  scopes?: Scopes;
  status?: string;
  tokenType?: string;
  welcomeVideoURL: string;
  bHasJobApplications: boolean;
  bHasPQTest?: boolean;
  bTwoFactorAuthEnabled?: boolean;
};

type SignInOkay = {
  details: SignInOkayDetails;
  status: string;
  errors: any[];
  id: number;
};

export type SignInResponse = SignInOkay | SignInError[];

export const isResponseOkay = (r: SignInResponse): r is SignInOkay =>
  (r as SignInOkay).status === 'Success' || (r as SignInOkay).status === '1';

/* STATE */
export type AuthStatus = 'signed_out' | 'signed_in' | 'pending';

export type RedirectValues = { label: string; value: any }[] | [];

type State = {
  appVisible: boolean;
  authStatus: AuthStatus;
  errorMessages: SignInError[];
  welcomeVideoUrl: any;
  invitedBy: string | null;
  inviteType: string | null;
  bCampaignSignUpSuccessful: boolean;
  applicationLinkToken: string | null;
  applicationLinkStarted: boolean;
  bHasJobApplications: boolean;
  bHasPQTest: boolean;
  bTwoFactorAuthEnabled: boolean;
  accessTokenRefreshing: boolean;
  redirectValues: RedirectValues;
  webskin: any;
  inIframe: boolean;
  isAssessmentOnlyCandidate: boolean;
  toggleNavDisplay: boolean;
};

const initialState: State = {
  authStatus: 'signed_out',
  appVisible: false,
  errorMessages: [],
  welcomeVideoUrl: '',
  invitedBy: null,
  inviteType: null,
  bCampaignSignUpSuccessful: false,
  applicationLinkToken: null,
  applicationLinkStarted: false,
  bHasJobApplications: true,
  bHasPQTest: false,
  bTwoFactorAuthEnabled: false,
  accessTokenRefreshing: false,
  redirectValues: [],
  webskin: 'defaultTheme',
  inIframe: false,
  isAssessmentOnlyCandidate: false,
  toggleNavDisplay: false,
};

/* ACTIONS */

const SIGN_OUT = 'app/auth/SIGN_OUT';
const SIGN_IN = 'app/auth/SIGN_IN';
const SIGN_IN_OKAY = 'app/auth/SIGN_IN_OKAY';
const CAMPAIGN_SIGN_UP_ERROR = 'app/auth/CAMPAIGN_SIGN_UP_ERROR';
const SIGN_IN_ERROR = 'app/auth/SIGN_IN_ERROR';
const SHOW_APP = 'app/auth/SHOW_APP';
const CHECK_SIGNED_IN = 'app/auth/CHECK_SIGNED_IN';
const SIGN_UP = 'app/auth/SIGN_UP';
const SET_MESSAGE_SIGN_OUT = 'app/auth/SET_MESSAGE_SIGN_OUT';
const CLEAR_MESSAGE = 'app/auth/CLEAR_MESSAGE';
const SET_INVITED_BY = 'app/auth/SET_INVITED_BY';
const SET_APPLICATION_LINK_TOKEN = 'app/auth/SET_APPLICATION_LINK_TOKEN';
const SET_APPLICATION_LINK_STARTED = 'app/auth/SET_APPLICATION_LINK_STARTED';
const SET_INVITE_TYPE = 'app/auth/SET_INVITE_TYPE';
const SET_CAMPAIGN_SIGN_UP_SUCCESSFUL =
  'app/auth/SET_CAMPAIGN_SIGN_UP_SUCCESSFUL';
const SET_ACCESS_TOKEN_REFRESHING = 'app/auth/SET_ACCESS_TOKEN_REFRESHING';
const SET_REDIRECT_VALUES = 'app/auth/SET_REDIRECT_VALUES';
const SET_WEBSKIN = 'app/auth/SET_WEBSKIN';
const SET_IN_IFRAME = 'app/auth/IN_IFRAME';
const SET_IS_ASSESSMENT_ONLY_CANDIDATE =
  'app/auth/IS_ASSESSMENT_ONLY_CANDIDATE';
const SET_TOGGLE_NAV_DISPLAY = 'app/auth/SET_TOGGLE_NAV_DISPLAY';

export type Action =
  | { type: typeof SIGN_IN; payload: SignInAttempt }
  | { type: typeof SIGN_OUT; signOutMarker: boolean }
  | { type: typeof CHECK_SIGNED_IN }
  | { type: typeof SHOW_APP; payload: boolean }
  | { type: typeof SIGN_IN_OKAY; payload: SignInOkay }
  | { type: typeof CAMPAIGN_SIGN_UP_ERROR; payload: SignInError[] }
  | { type: typeof SIGN_IN_ERROR; payload: SignInError[] }
  | { type: typeof SIGN_UP; payload: SignUpAttempt }
  | { type: typeof SET_MESSAGE_SIGN_OUT }
  | { type: typeof SET_INVITED_BY; payload: string }
  | { type: typeof SET_APPLICATION_LINK_TOKEN; payload: string | null }
  | { type: typeof SET_APPLICATION_LINK_STARTED; payload: boolean }
  | { type: typeof SET_INVITE_TYPE; payload: string }
  | { type: typeof SET_CAMPAIGN_SIGN_UP_SUCCESSFUL; payload: boolean }
  | { type: typeof CLEAR_MESSAGE }
  | { type: typeof SET_ACCESS_TOKEN_REFRESHING; payload: boolean }
  | { type: typeof SET_REDIRECT_VALUES; payload: RedirectValues }
  | { type: typeof SET_WEBSKIN; payload: string }
  | { type: typeof SET_IN_IFRAME; payload: boolean }
  | { type: typeof SET_IS_ASSESSMENT_ONLY_CANDIDATE; payload: boolean }
  | { type: typeof SET_TOGGLE_NAV_DISPLAY; payload: boolean };

/* REDUCER */

export default function reducer(state = initialState, action: Action): State {
  return produce(state, (draft) => {
    switch (action.type) {
      case SHOW_APP:
        draft.appVisible = true;
        // Is the user already signed UP?
        if (action.payload === true) draft.authStatus = 'signed_in';
        break;
      case SIGN_OUT:
        draft.authStatus = 'signed_out';
        draft.errorMessages = [];
        break;
      case SIGN_IN:
        draft.authStatus = 'pending';
        draft.errorMessages = [];
        break;
      case SIGN_IN_OKAY:
        draft.authStatus = 'signed_in';
        draft.welcomeVideoUrl = action.payload.details.welcomeVideoURL;
        draft.bHasJobApplications = action.payload.details.bHasJobApplications;
        draft.bHasPQTest = action.payload.details.bHasPQTest
          ? action.payload.details.bHasPQTest
          : false;
        draft.bTwoFactorAuthEnabled = action.payload.details
          .bTwoFactorAuthEnabled
          ? action.payload.details.bTwoFactorAuthEnabled
          : false;
        break;
      case CAMPAIGN_SIGN_UP_ERROR:
        draft.authStatus = 'signed_in'; // Currently set up to work with InteractiveFeedbackHub relying on "signed_in" for functionality, even through campaign feedback link
        draft.errorMessages = action.payload;
        break;
      case SIGN_IN_ERROR:
        draft.authStatus = 'signed_out';
        draft.errorMessages = action.payload;
        break;
      case SIGN_UP:
        draft.authStatus = 'pending';
        draft.errorMessages = [];
        break;
      case SET_MESSAGE_SIGN_OUT:
        draft.errorMessages = [];
        draft.authStatus = 'signed_out';
        break;
      case SET_INVITED_BY:
        draft.invitedBy = action.payload;
        break;
      case SET_APPLICATION_LINK_TOKEN:
        draft.applicationLinkToken = action.payload;
        break;
      case SET_APPLICATION_LINK_STARTED:
        draft.applicationLinkStarted = action.payload;
        break;
      case SET_INVITE_TYPE:
        draft.inviteType = action.payload;
        break;
      case SET_CAMPAIGN_SIGN_UP_SUCCESSFUL:
        draft.bCampaignSignUpSuccessful = action.payload;
        break;
      case SET_ACCESS_TOKEN_REFRESHING:
        draft.accessTokenRefreshing = action.payload;
        break;
      case SET_REDIRECT_VALUES:
        draft.redirectValues = action.payload;
        break;
      case SET_IN_IFRAME:
        draft.inIframe = action.payload;
        break;
      case CLEAR_MESSAGE:
        draft.errorMessages = [];
        break;
      case SET_WEBSKIN:
        draft.webskin = action.payload;
        break;
      case SET_IS_ASSESSMENT_ONLY_CANDIDATE:
        draft.isAssessmentOnlyCandidate = action.payload;
        break;
      case SET_TOGGLE_NAV_DISPLAY:
        draft.toggleNavDisplay = action.payload;
        break;
    }
  });
}

/* EPICS */

function makeRequest<T>(config: AxiosRequestConfig) {
  return from(api.request<T>(config));
}

function saveResponse(r: any) {
  if (isResponseOkay(r)) {
    const { accessToken, refreshToken, expiresIn, scopes } = r.details;
    const refreshTokenExpires = addSeconds(new Date(), parseInt(expiresIn, 10));
    saveTokenResource({
      accessToken,
      refreshToken,
      refreshTokenExpires: refreshTokenExpires,
      scopes: scopes ?? [],
    });
  }
}

export function checkResponse(r: SignInResponse) {
  if (isResponseOkay(r)) {
    return signInOkay(r);
  }
  return signInError(r);
}

/** Attempt to sign in and handle response, cancel if we sign out while a request pending. */
const signInEpic = (action$: Observable<any>): Observable<Action> =>
  action$.pipe(
    ofType(SIGN_IN),
    switchMap((attempt) =>
      makeRequest<SignInResponse>({
        url: endpoints.auth.login,
        data: attempt.payload,
        method: 'post',
        withCredentials: true,
      }).pipe(
        tap((res) => {
          GASignIn();
          saveResponse(res.data);
        }),
        map((res) => checkResponse(res.data)),
        catchError((err: AxiosError) => {
          return of(
            signInError(
              err.response?.data.errors.length > 0
                ? err.response?.data.errors
                : [
                    {
                      errorType: '',
                      fieldName: '',
                      messageCode: '',
                    },
                  ]
            )
          );
        })
      )
    )
  );

/** Clear the local storage on sign out, so we don't appear logged in on refresh. */
const signOutEpic = (action$: Observable<any>) =>
  action$.pipe(
    ofType(SIGN_OUT),
    tap((action) => {
      window && window.sessionStorage.removeItem('webskin');
      window && window.localStorage.clear();
      if (action.signOutMarker) {
        window && window.localStorage.setItem('signOutAlert', 'true');
      }
    }),
    ignoreElements()
  );

const checkSignedInEpic = (action$: Observable<any>) =>
  action$.pipe(
    ofType(CHECK_SIGNED_IN),
    map(loadTokenResource),
    map((r) => r !== null),
    map(showApp)
  );

const signUpEpic = (action$: Observable<any>, state$: StateObservable<any>) =>
  action$.pipe(
    ofType(SIGN_UP),
    switchMap((attempt) =>
      makeRequest<ApiResponse>({
        url: endpoints.registration.signup,
        method: 'POST',
        data: { ...attempt.payload },
      }).pipe(
        map((response) => {
          if (response.data.status === '1') {
            // Set flag for SignUpFormPage
            localStorage.setItem('newSignUpSuccessful', 'true');

            // If Ideagen/assessment-only project candidate, navigate to live applications page

            // If user has completed quiz before signing-up
            if (attempt.payload.questionAnswers.length > 0) {
              GASignUpAfterQuiz();
            } else {
              GARegularSignUp();
            }
            if (state$.value.auth.inviteType === 'team') {
              GASignUpFromTeamInvite();
            }
            if (state$.value.auth.inviteType === 'friend') {
              GASignUpFromInvite();
            }

            window && window.localStorage.removeItem('signOutAlert');

            if (attempt.payload.assessmentId) {
              localStorage.setItem('campaignSignUpSuccessful', 'true');
            }

            if (
              response.data.details.preRegistrationTestType === 'AbilityTest'
            ) {
              localStorage.setItem('preRegTest', 'AbilityTest');
            }

            if (
              response.data.details.preRegistrationTestType === 'SoftSkillsQuiz'
            ) {
              localStorage.setItem('preRegTest', 'SoftSkillsQuiz');
            }

            return signIn({
              username: attempt.payload.emailAddress,
              password: attempt.payload.password,
              rememberMe: false,
            });
          } else
            return {
              type: SET_MESSAGE_SIGN_OUT,
              payload: response.data.errors[0].messageCode,
            };
        }),
        catchError((err: AxiosError) => {
          if (attempt.payload.assessmentId) {
            return of(
              signUpError(
                err.response?.data.errors.length > 0
                  ? err.response?.data.errors
                  : [
                      {
                        errorType: '',
                        fieldName: '',
                        messageCode: '',
                      },
                    ]
              )
            );
          } else
            return of(
              signInError(
                err.response?.data.errors.length > 0
                  ? err.response?.data.errors
                  : [
                      {
                        errorType: '',
                        fieldName: '',
                        messageCode: '',
                      },
                    ]
              )
            );
        })
      )
    )
  );

export const authEpic = combineEpics(
  signInEpic,
  signOutEpic,
  checkSignedInEpic,
  signUpEpic
);

/* ACTION CREATORS */

export const signIn = (payload: SignInAttempt): Action => ({
  type: SIGN_IN,
  payload,
});

export const signOut = (marker = true): Action => ({
  type: SIGN_OUT,
  signOutMarker: marker,
});

export const signUp = (payload: SignUpAttempt): Action => {
  return {
    type: SIGN_UP,
    payload,
  };
};

export const clearAuthMessage = (): Action => {
  return {
    type: CLEAR_MESSAGE,
  };
};

export const checkSignedIn = (): Action => ({
  type: CHECK_SIGNED_IN,
});

export const signInOkay = (payload: SignInOkay): Action => ({
  type: SIGN_IN_OKAY,
  payload,
});

const signUpError = (payload: SignInError[]): Action => {
  return {
    type: CAMPAIGN_SIGN_UP_ERROR,
    payload,
  };
};

const signInError = (payload: SignInError[]): Action => {
  return {
    type: SIGN_IN_ERROR,
    payload,
  };
};

const showApp = (payload: boolean): Action => ({
  type: SHOW_APP,
  payload,
});
export const setInvitedBy = (id: string): Action => ({
  type: SET_INVITED_BY,
  payload: id,
});
export const setApplicationLinkToken = (payload: string | null): Action => ({
  type: SET_APPLICATION_LINK_TOKEN,
  payload,
});
export const setApplicationLinkStarted = (payload: boolean): Action => ({
  type: SET_APPLICATION_LINK_STARTED,
  payload,
});
export const setInviteType = (type: string): Action => ({
  type: SET_INVITE_TYPE,
  payload: type,
});
export const setCampaignSignUpSuccessful = (type: boolean): Action => ({
  type: SET_CAMPAIGN_SIGN_UP_SUCCESSFUL,
  payload: type,
});
export const setAccessTokenRefreshing = (payload: boolean): Action => ({
  type: SET_ACCESS_TOKEN_REFRESHING,
  payload,
});
export const setRedirectValues = (payload: RedirectValues): Action => ({
  type: SET_REDIRECT_VALUES,
  payload,
});
export const setWebskin = (payload: any): Action => ({
  type: SET_WEBSKIN,
  payload,
});
export const setInIframe = (payload: boolean): Action => ({
  type: SET_IN_IFRAME,
  payload,
});
export const setIsAssessmentOnlyCandidate = (payload: boolean): Action => ({
  type: SET_IS_ASSESSMENT_ONLY_CANDIDATE,
  payload,
});
export const setToggleNavDisplay = (payload: any): Action => ({
  type: SET_TOGGLE_NAV_DISPLAY,
  payload,
});

/* SELECTORS */

export const selectAuthStatus = (app: Store) => app.auth.authStatus;
export const selectWelcomeVideoUrl = (app: Store) => app.auth.welcomeVideoUrl;
export const selectSignedIn = createSelector(
  selectAuthStatus,
  (status) => status === 'signed_in'
);
export const selectAuthMessage = (app: Store) => app.auth.errorMessages;
export const selectAppVisible = (app: Store) => app.auth.appVisible;
export const selectInvitedBy = (app: Store) => app.auth.invitedBy;
export const selectApplicationLinkToken = (app: Store) =>
  app.auth.applicationLinkToken;
export const selectApplicationLinkStarted = (app: Store) =>
  app.auth.applicationLinkStarted;
export const selectInviteType = (app: Store) => app.auth.inviteType;
export const selectCampaignSignUpSuccessful = (app: Store) =>
  app.auth.bCampaignSignUpSuccessful;
export const selectHasApplications = (app: Store) =>
  app.auth.bHasJobApplications;
export const selectAccessTokenRefreshing = (app: Store) =>
  app.auth.accessTokenRefreshing;
export const selectRedirectValues = (app: Store) => app.auth.redirectValues;
export const selectWebskin = (app: Store) => app.auth.webskin;
export const selectInIframe = (app: Store) => app.auth.inIframe;
export const selectIsAssessmentOnlyCandidate = (app: Store) =>
  app.auth.isAssessmentOnlyCandidate;
export const selectToggleNavDisplay = (app: Store) => app.auth.toggleNavDisplay;
