import { useState, useCallback, useEffect } from 'react';
import {
  InteractiveFeedbackCardProps,
  InteractiveFeedbackCardType,
} from '../components/InteractiveFeedbackCard';
import { InteractiveFeedbackMenuProps } from '../components/InteractiveFeedbackMenu';
import {
  GetMySalesStyleScoresDetails,
  GetMyStrengthScoresDetails,
  GetMyTeamStrengthsScoresDetails,
  getSalesStyleScores,
  getStrengthScores,
  getTeamStrengthsScores,
} from '../InteractiveFeedbackApi';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';
import GritImage from 'assets/apply.clevry/IF_MySalesStyle_StrengthArea_01.jpg';
import EmpathyImage from 'assets/apply.clevry/IF_MySalesStyle_StrengthArea_02.jpg';
import FocusImage from 'assets/apply.clevry/IF_MySalesStyle_StrengthArea_03.jpg';
import AppetiteImage from 'assets/apply.clevry/IF_MySalesStyle_StrengthArea_04.jpg';
import { useSelector } from 'react-redux';
import { selectDashboardInfo } from 'reducers/dashboardInfo';
import { selectAuthStatus, selectRedirectValues } from 'reducers/auth';
import { selectAssessmentId } from 'reducers/virtualAssessments';

export type MySalesStyleCardInnerOptions = Pick<
  InteractiveFeedbackCardProps,
  'bottomColor' | 'cardHTML' | 'heading' | 'type' | 'noButtons'
> & { topImage?: string };

export type MySalesStyleCardOptions = {
  salesStyleCards: MySalesStyleCardInnerOptions[];
};

export type ReportDownloadFlags = {
  isReportDownloadable?: boolean;
};

export const useGetMySalesStyleDetails = () => {
  const { t } = useTranslation('Interactive+Feedback', { i18n });
  const [data, setData] = useState<{
    salesStyleMenuCards: MySalesStyleCardOptions;
    salesStyleWheelImage: string;
    reportCode: string;
    isCandidateReport: boolean;
    reportDownloadFlags: ReportDownloadFlags;
  } | null>(null);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState(false);
  const redirectValues = useSelector(selectRedirectValues);
  const dashboardInfo = useSelector(selectDashboardInfo);
  const myHistoryRouteAssessmentId = useSelector(selectAssessmentId);
  const authStatus = useSelector(selectAuthStatus);

  let cultureCode: string | null = null;
  if (dashboardInfo && dashboardInfo.cultureCode) {
    cultureCode = dashboardInfo.cultureCode;
  }

  let assessmentId: number | null = null;
  if (redirectValues && redirectValues.length > 0) {
    assessmentId = redirectValues.find(
      ({ label, value }) => label === 'AssessmentID'
    )?.value;
  } else if (myHistoryRouteAssessmentId) {
    assessmentId = myHistoryRouteAssessmentId;
  }

  const parseSalesStyleMenuCardOptions = () => {
    const salesStyleCards = [
      {
        type: InteractiveFeedbackCardType.IMAGE,
        heading: t('SSCandidate_Interactive_Feedback_Grit'),
        color: '#31C3EC',
        borderColor: '#762572',
        bottomColor: '#762572',
        topImage: GritImage,
        cardHTML: t('SSCandidate_Interactive_Feedback_Grit_Description'),
      },
      {
        type: InteractiveFeedbackCardType.IMAGE,
        heading: t('SSCandidate_Interactive_Feedback_Empathy'),
        color: '#31C3EC',
        borderColor: '#08A053',
        bottomColor: '#08A053',
        topImage: EmpathyImage,
        cardHTML: t('SSCandidate_Interactive_Feedback_Empathy_Description'),
      },
      {
        type: InteractiveFeedbackCardType.IMAGE,
        heading: t('SSCandidate_Interactive_Feedback_Focus'),
        color: '#31C3EC',
        borderColor: '#3CC1EA',
        bottomColor: '#3CC1EA',
        topImage: FocusImage,
        cardHTML: t('SSCandidate_Interactive_Feedback_Focus_Description'),
      },
      {
        type: InteractiveFeedbackCardType.IMAGE,
        heading: t('SSCandidate_Interactive_Feedback_Appetite'),
        color: '#31C3EC',
        borderColor: '#F2832A',
        bottomColor: '#F2832A',
        topImage: AppetiteImage,
        cardHTML: t('SSCandidate_Interactive_Feedback_Appetite_Description'),
      },
    ];

    return { salesStyleCards };
  };

  const getOptions = useCallback(async () => {
    const result = await getSalesStyleScores({
      ...(assessmentId ? { AssessmentId: assessmentId } : {}),
      ...(cultureCode ? { LanguageCultureInfo: cultureCode } : {}),
    });
    if (result.response && result.response.details) {
      setLoading(false);

      const isCandidateReport = result.response.details.isCandidateReport;
      const reportCode = result.response.details.reportCode;

      const salesStyleMenuCards = parseSalesStyleMenuCardOptions();

      const salesStyleWheelImage = result.response.details.wheelDownloadUri;

      const reportDownloadFlags = {
        isReportDownloadable: result.response.details.isReportDownloadable,
      };         

      setData({
        salesStyleMenuCards,
        salesStyleWheelImage,
        reportCode,
        isCandidateReport,
        reportDownloadFlags,
      });
    }

    if (result.errors) {
      setLoading(false);
      setErrors(true);
    }
  }, []);

  useEffect(() => {
    if (authStatus === "signed_in") {
      getOptions();
    }
  }, [authStatus]);

  return [data, loading, errors];
};
