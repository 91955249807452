import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { TextArea } from 'components/formFields/TextArea';
import { Spinner } from 'components/spinner/Spinner';
import {
  getWriteBioDetails,
  parseGetWriteBioDetails,
  submitWriteBioPayload,
} from '../../MatcingProcessApi';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';
import { useDispatch, useSelector } from 'react-redux';
import {
  MatchingProcessState,
  selectMatchingProcess,
  setMatchingProcessDataSubmittedToggle,
  setMatchingProcessSubmitSuccess,
} from 'reducers/matchingProcess';
import { GAMatchingProcessLatest } from 'helpers/gaEvents';

type WriteBioPageProps = {
  outerSubmitForm: MutableRefObject<
    (() => Promise<void>) & (() => Promise<any>)
  >;
  setNextStepDisabled: Dispatch<SetStateAction<boolean>>;
  setGeneralError: Dispatch<SetStateAction<string | undefined>>;
};

export type WriteBioPageFormikValues = {
  about: string;
};

const WriteBioPage: React.FC<WriteBioPageProps> = ({
  outerSubmitForm,
  setNextStepDisabled,
  setGeneralError,
}) => {
  const latestLocation = useRef('');

  if (
    window &&
    window.location &&
    window.location.href !== latestLocation.current
  ) {
    latestLocation.current = window.location.href;
    GAMatchingProcessLatest(window.location.href);
  }

  const { t } = useTranslation('Matching+Process', { i18n });

  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState<WriteBioPageFormikValues>({
    about: '',
  });

  const validationSchema = Yup.object({
    about: Yup.string().required(),
  });

  const dispatch = useDispatch();

  const matchingProcessDetails: MatchingProcessState = useSelector(
    selectMatchingProcess
  );

  const handleSubmit = (values: typeof initialValues) => {
    return (async () => {
      const result = await submitWriteBioPayload(values);
      if (result.response) {
        setGeneralError('');

        dispatch(setMatchingProcessSubmitSuccess(true));

        dispatch(
          setMatchingProcessDataSubmittedToggle(
            !matchingProcessDetails.dataSubmittedToggle
          )
        );

        return true;
      } else {
        dispatch(setMatchingProcessSubmitSuccess(false));

        setGeneralError(t('SSCandidate_Matching_Process_General_Error'));
        return false;
      }
    })();
  };

  const fetchDetails = async () => {
    const result = await getWriteBioDetails();
    if (result.response) {
      setInitialValues(parseGetWriteBioDetails(result.response));
      setGeneralError('');
      setLoading(false);
    } else {
      setLoading(false);
      setGeneralError(t('SSCandidate_Matching_Process_General_Error_Reload'));
    }
  };

  useEffect(() => {
    fetchDetails();
  }, []);

  if (loading) return <Spinner />;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
      validateOnMount
    >
      {({
        setFieldValue,
        values,
        errors,
        touched,
        setValues,
        submitForm,
        isValid,
      }) => {
        outerSubmitForm.current = submitForm;

        if (isValid) {
          setNextStepDisabled(false);
        } else {
          setNextStepDisabled(true);
        }

        const maxTextLength = 1000;

        return (
          <>
            <Form className="BioPage MatchingProcessForm MatchingProcessInnerPage">
              <div className="FormContainer">
                <div className="MatchingProcess__sectionHeader">
                  <div className="MatchingProcess__sectionHeading">
                    {t('SSCandidate_Matching_Process_Write_Bio_Heading')}
                  </div>
                  <div className="MatchingProcess__sectionSubheading">
                    {t('SSCandidate_Matching_Process_Write_Bio_SubHeading1')}
                  </div>
                </div>

                <div className="BiographyContainer">
                  <TextArea
                    className="BiographyInputField"
                    label={t(
                      'SSCandidate_Matching_Process_Write_Bio_Field_Label1'
                    )}
                    labelLocation="left"
                    charLimit={maxTextLength}
                    name="about"
                    defaultValue={initialValues.about}
                  />
                </div>
              </div>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

export default WriteBioPage;
