import { Word } from 'react-wordcloud';

interface FindYourJoyQuestion {
  id: number;
  prompt: string;
  text: string;
  answers: FindYourJoyOption[];
}

interface FindYourJoyOption {
  id: number;
  imageUrl1: string;
  imageUrl2: string;
}

export interface FindYourJoyQuiz {
  softSkillsQuizId: number;
  questions: FindYourJoyQuestion[];
}

export interface FindYourJoyQuestionAnswer {
  questionId: number;
  answerId: number;
}

export enum FindYourJoyQuizStep {
  SELECT = 1,
  ORDER,
  RESULTS,
}

export interface LabeledValue {
  label: string;
  value: number;
}

export interface FindYourJoyQuizResults {
  wordcloud: LabeledValue[];
  profile: (LabeledValue & { description: string })[];
  softSkillsShareURL: string;
}

export interface ParsedFindYourJoyQuizResults {
  wordcloud: Word[];
  profile: FindYourJoyQuizResults['profile'];
  highestScoringProfileItem: LabeledValue & { description: string };
}

export interface FindYourJoyContext {
  quiz: FindYourJoyQuiz;
  answers: FindYourJoyQuestionAnswer[];
  setAnswers: React.Dispatch<React.SetStateAction<FindYourJoyQuestionAnswer[]>>;
  currentQuizStep: FindYourJoyQuizStep;
  setCurrentQuizStep: React.Dispatch<React.SetStateAction<FindYourJoyQuizStep>>;
  isCustomer: boolean;
}
