import { isPast } from 'date-fns';
import { useEffect, useState, useMemo } from 'react';
import { selectSignedIn } from 'reducers/auth';
import { useSelector } from 'react-redux';

export type TokenResource = {
  accessToken: string;
  refreshToken: string;
  refreshTokenExpires: Date;
  scopes?: Scopes;
};

/**
 * Save token data in local storage.
 */
export function saveTokenResource(resource: TokenResource): void {
  if (window) {
    window.localStorage.setItem('AT', resource.accessToken);
    window.localStorage.setItem('RT', resource.refreshToken);
    window.localStorage.setItem('RTE', resource.refreshTokenExpires.toString());
    if (resource.scopes) {
      window.localStorage.setItem('SCOPES', JSON.stringify(resource.scopes));
    }
  }
}

export type LoadedTokenResource = {
  accessToken: string;
  refreshToken: string;
  refreshTokenExpires: Date;
  scopes?: string;
};

/**
 * Fetch token data from local storage.
 */
export function loadTokenResource(): null | LoadedTokenResource {
  try {
    const accessToken = window.localStorage.getItem('AT');
    const refreshToken = window.localStorage.getItem('RT');
    const expiry = window.localStorage.getItem('RTE');
    const scopes = window.localStorage.getItem('SCOPES');

    // If anything's missing, abort.
    if (accessToken == null || refreshToken == null || expiry == null)
      throw new Error();

    const refreshTokenExpires = new Date(expiry);

    if (isPast(refreshTokenExpires)) throw new Error();

    return {
      accessToken,
      refreshToken,
      refreshTokenExpires,
      scopes: scopes ?? '[]',
    };
  } catch (_error) {
    return null;
  }
}

export type AccessElement = {
  displayName: string;
  path: string;
  childElements?: Scopes;
};

export type Scopes = AccessElement[];

export function loadScopes(): string {
  try {
    const scopes = window.localStorage.getItem('SCOPES');
    if (scopes == null) throw new Error();

    return scopes;
  } catch (error) {
    return '[]';
  }
}

/**
 * Used to load navigation routes that are available to this user.
 * Effect runs on app load and sign-in.
 */
export function useLoadScopes(): Scopes {
  const [scopeString, setScopeString] = useState('[]');
  const isSignedIn = useSelector(selectSignedIn);

  useEffect(() => {
    if (isSignedIn) setScopeString(loadScopes());
  }, [isSignedIn]);

  const scopes: Scopes = useMemo(() => JSON.parse(scopeString), [scopeString]);

  return scopes;
}
