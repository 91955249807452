import { navigate, RouteComponentProps } from '@reach/router';
import { Spinner } from 'components/spinner/Spinner';
import React, { createContext, useEffect, useState } from 'react';
import { themes } from 'theme';
import {
  AbilityPracticeTestContext,
  AbilityPTPostAnswers,
  AbilityPracticeTestQuiz,
  AbilityPracticeTestQuizStep,
} from './models';
import AbilityPracticeTestLanding from './AbilityPracticeTestLanding';
import { AbilityPracticeTestQuestions } from './AbilityPracticeTestQuestions';
import { AppPath } from 'appConstants';
import { AbilityPracticeTestResults } from './AbilityPracticeTestResults';
import { AbilityPracticeTestApi } from './abilityPracticeTestApi';

export const AbilityPracticeTestQuizContext =
  createContext<AbilityPracticeTestContext | null>(null);

type AbilityPracticeTestProps = RouteComponentProps & { isCustomer?: boolean };

const AbilityPracticeTest: React.FC<AbilityPracticeTestProps> = ({
  isCustomer,
}) => {
  const [onLanding, setOnLanding] = useState<boolean>(true);
  const [quiz, setQuiz] = useState<AbilityPracticeTestQuiz | null>(null);
  const [answers, setAnswers] = useState<AbilityPTPostAnswers[]>([]);
  const [currentQuizStep, setCurrentQuizStep] =
    useState<AbilityPracticeTestQuizStep>(AbilityPracticeTestQuizStep.SELECT);

  useEffect(() => {
    AbilityPracticeTestApi.getQuestion(1)
      .then((response) => {
        if (response.data.status === '1') {
          setQuiz(response.data.details);
        }
      })
      .catch((error) => {
        navigate(AppPath.NOT_FOUND);
      });
  }, []);

  const providerValue = quiz
    ? {
        quiz,
        answers,
        setAnswers,
        currentQuizStep,
        setCurrentQuizStep,
        isCustomer: !!isCustomer,
        themes,
      }
    : null;

  return (
    <AbilityPracticeTestQuizContext.Provider value={providerValue}>
      <React.Fragment>
        {providerValue ? (
          <>
            {onLanding ? (
              <AbilityPracticeTestLanding goToTest={setOnLanding} />
            ) : !onLanding &&
              providerValue.currentQuizStep ===
                AbilityPracticeTestQuizStep.SELECT ? (
              <AbilityPracticeTestQuestions />
            ) : !onLanding &&
              providerValue.currentQuizStep ===
                AbilityPracticeTestQuizStep.RESULTS ? (
              <AbilityPracticeTestResults />
            ) : null}
          </>
        ) : (
          <div className="AbilityPracticeTest HeightContent">
            <Spinner color={isCustomer ? '#429CC' : themes.defaultTheme.one} />
          </div>
        )}
      </React.Fragment>
    </AbilityPracticeTestQuizContext.Provider>
  );
};

export default AbilityPracticeTest;
