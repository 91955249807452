export interface AbilityPTTypeAnswers {
  id: number | null;
  questionAnswers: AbilityPTPostAnswers[];
};

export interface AbilityPTQuestionTypes {
  id: number;
  header: null | string;
  content: null | string;
  questionIntro: null | string;
  questions: AbilityPTQuestionList[];
};

export interface AbilityPTQuestionList {
  id: number;
  question: null | string;
  answers: AbilityPTAnswerList[];
};

export interface AbilityPTAnswerList {
  id: number;
  answer: null | string;
};

export interface AbilityPTSubmittedAnswers {
  questionId: number;
  answerId: null | number;
  validated: boolean;
};

export interface AbilityPTPostAnswers {
  questionId: number;
  answerId: number;
};

export interface AbilityPracticeTestQuiz {
  id: number;
  questionTypes: AbilityPTQuestionTypes[];
};

export enum AbilityPracticeTestQuizStep {
  SELECT = 1,
  RESULTS,
};

export interface AbilityPracticeTestContext {
  quiz: AbilityPracticeTestQuiz;
  answers: AbilityPTPostAnswers[];
  setAnswers: React.Dispatch<React.SetStateAction<AbilityPTPostAnswers[]>>;
  currentQuizStep: AbilityPracticeTestQuizStep;
  setCurrentQuizStep: React.Dispatch<React.SetStateAction<AbilityPracticeTestQuizStep>>;
  isCustomer: boolean;
  themes: any;
};
