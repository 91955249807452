import { FormSingleSelectCustomField } from 'components/pages/jobApplication/CustomElementForm/CustomFormFields/FormSingleSelectCustomField';
import { MatchingProcessDeleteIcon } from '../../MatchingProcessDeleteIcon';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';
import { LanguagesYouSpeakFomValues } from './LanguagesYouSpeakPage';

type menuOptionsInner = {
  label: string;
  value: number;
}[];

type LanguagesYouSpeakRowProps = {
  rowNumber: number;
  remove: <T>(index: number) => T | undefined;
  menuOptionsLanguages: menuOptionsInner;
  menuOptionsProficiency: menuOptionsInner;
  values: { languagesSpoken: LanguagesYouSpeakFomValues };
};

const LanguagesYouSpeakRow = ({
  rowNumber,
  remove,
  menuOptionsLanguages,
  menuOptionsProficiency,
  values,
}: LanguagesYouSpeakRowProps) => {
  const { t } = useTranslation('Matching+Process', { i18n });

  let filteredLanguageOptions = menuOptionsLanguages;
  let chosenIds: number[] = [];
  if (
    values &&
    Array.isArray(values.languagesSpoken) &&
    values.languagesSpoken.length > 0
  ) {
    chosenIds = values.languagesSpoken
      .filter((item) => typeof item.languageRequirement === 'number')
      .map(({ languageRequirement }) => {
        return languageRequirement!;
      });
    filteredLanguageOptions = menuOptionsLanguages.filter(
      ({ value }) =>
        !chosenIds.includes(value) ||
        value === values.languagesSpoken[rowNumber].languageRequirement
    );
  }

  return (
    <div className="MatchingProcessPageRow">
      <FormSingleSelectCustomField
        fieldName={`languagesSpoken.${rowNumber}.languageRequirement`}
        label={t('SSCandidate_Matching_Process_Language_Label')}
        options={filteredLanguageOptions}
        useNumberForValue={true}
        showError={false}
      />
      <FormSingleSelectCustomField
        fieldName={`languagesSpoken.${rowNumber}.proficiency`}
        label={t('SSCandidate_Matching_Process_Proficiency_Label')}
        options={menuOptionsProficiency}
        useNumberForValue={true}
        showError={false}
      />
      <MatchingProcessDeleteIcon
        onClick={() => {
          remove(rowNumber);
        }}
      />
    </div>
  );
};

export default LanguagesYouSpeakRow;
