import { ProgressIndicator } from 'components/progressIndicator/progressIndicator';
import useMedia from 'use-media';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectTrackerData } from 'reducers/virtualAssessments';
import { selectAccessibilityData } from 'reducers/accessibility';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';

export const VirtualAssessmentsHeaderTracker = () => {
  const trackerData = useSelector(selectTrackerData);
  const isPhone = useMedia('(max-width: 768px)');
  const accessibilityData = useSelector(selectAccessibilityData);
  const { t } = useTranslation('Candidate+Journey+-+PQTest', { i18n });

  const getMessage = (
    question: number,
    totalQuestions: number,
    finalQuestionOnPage: number
  ) => {
    if (totalQuestions === 0) return t('pPQTest_lblStart');
    const percentageComplete = (question / totalQuestions) * 100;
    const isFinalPage = finalQuestionOnPage === totalQuestions;

    if (!isFinalPage) {
      if (percentageComplete === 0) return t('pPQTest_lblStart');
      if (percentageComplete > 0 && percentageComplete <= 25)
        return t('pPQTest_hidKeepGoing1');
      if (percentageComplete > 25 && percentageComplete <= 50)
        return t('pPQTest_hidMoreThanQuarter');
      if (percentageComplete > 50 && percentageComplete <= 60)
        return t('pPQTest_hidHalfwayThere');
      if (percentageComplete > 60 && percentageComplete <= 75)
        return t('pPQTest_hidKeepGoing2');
      if (percentageComplete > 75 && !isFinalPage)
        return t('pPQTest_hidNearlyThere');
    } else {
      if (percentageComplete === 100) return t('pPQTest_lblFinish');
      else return t('pPQTest_hidFinalFewPages');
    }
  };

  if (!trackerData) return <div></div>;

  const areQuestions =
    trackerData.currentQuestion !== null &&
    trackerData.currentQuestion !== undefined &&
    trackerData.questionsOnPage !== null &&
    trackerData.questionsOnPage !== undefined &&
    trackerData.totalQuestions !== null &&
    trackerData.totalQuestions !== undefined;
  const questionDetails = isPhone
    ? `${trackerData.currentQuestion} / ${trackerData.totalQuestions}`
    : `Question ${trackerData.currentQuestion} ${
        trackerData.currentQuestion < trackerData.questionsOnPage
          ? `of ${trackerData.questionsOnPage}`
          : ''
      } out of ${trackerData.totalQuestions}`;

  const motivationalMessage = getMessage(
    trackerData.currentQuestion,
    trackerData.totalQuestions,
    trackerData.questionsOnPage
  );

  return (
    <div className="VirtualAssessmentsHeaderTracker">
      {/* {areQuestions &&
        !trackerData.message &&
        !trackerData.onlyShowProgress && (
          <div className="VirtualAssessmentsHeaderTracker__questionTracker">
            {questionDetails}
          </div>
        )} */}
      {!trackerData.onlyShowProgress && (
        <div className="VirtualAssessmentsHeaderTracker__motivationalMessage">
          {motivationalMessage && !isPhone && motivationalMessage}
        </div>
      )}
      {(areQuestions || trackerData.message) && (
        <ProgressIndicator
          value={trackerData.currentQuestion}
          total={trackerData.totalQuestions}
          strokeColor={
            accessibilityData.isSet
              ? accessibilityData.errorTextColor
              : '$MainColor35'
          }
          radius={25}
          fill={!accessibilityData.isSet ? '#F5F5F5' : ''}
        />
      )}
    </div>
  );
};
