import { getOpportunityDetails } from 'reducers/opportunityDetails';
import { useSelector } from 'react-redux';

enum GenericActivityHeaderType {
  'VIRTUAL_ASSESSMENT',
  'ABILITY_TEST',
}

const findTrackerDataPresent = (activityType: number) => {
  switch(activityType) {
    case GenericActivityHeaderType.VIRTUAL_ASSESSMENT:
      return true;
    case GenericActivityHeaderType.ABILITY_TEST:
      return true;
    default:
      return false;
  } 
};

const GetClientLogo = (props: any) => {
  const selectorDetails = useSelector(getOpportunityDetails);
  const trackerDataPresent = findTrackerDataPresent(props.activityType);

  if (selectorDetails && selectorDetails.opportunityDetails && selectorDetails.opportunityDetails.logoURL) {
    return (
      <img 
        className={"ClientLogo" + (trackerDataPresent ? " MoveAside" : "")} 
        src={selectorDetails.opportunityDetails.logoURL} 
        alt={selectorDetails.opportunityDetails.name} 
      />
    );
  } else {
    return null;
  }
};

export { GetClientLogo };