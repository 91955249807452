import { Store } from 'reducers/rootReducer';

type TrackerData = {
  currentQuestion: number;
  questionsOnPage: number;
  totalQuestions: number;
  message: string;
  onlyShowProgress: boolean;
} | null;

export type State = {
  projectId: number | null;
  assessmentId: number | null;
  applicationType: string | null;
  jobApplicationId: number | null;
  trackerData: TrackerData | null;
  instructionsUnderstood: boolean;
};

const initialState: State = {
  projectId: null,
  assessmentId: null,
  applicationType: null,
  jobApplicationId: null,
  trackerData: null,
  instructionsUnderstood: false,
};

// Actions
const SET_PROJECT_ID = 'app/abilityTests/SET_PROJECT_ID';
const SET_APPLICATION_TYPE = 'app/abilityTests/SET_APPLICATION_TYPE';
const SET_ASSESSMENT_ID = 'app/abilityTests/SET_ASSESSMENT_ID';
const SET_JOB_APPLICATION_ID = 'app/abilityTests/SET_JOB_APPLICATION_ID';
const SET_TRACKER_DATA = 'app/abilityTests/SET_TRACKER_DATA';
const SET_INSTRUCTIONS_UNDERSTOOD =
  'app/abilityTests/SET_INSTRUCTIONS_UNDERSTOOD';

// Reducer
type Action =
  | {
      type: typeof SET_PROJECT_ID;
      payload: number;
    }
  | {
      type: typeof SET_APPLICATION_TYPE;
      payload: string;
    }
  | {
      type: typeof SET_ASSESSMENT_ID;
      payload: number;
    }
  | {
      type: typeof SET_JOB_APPLICATION_ID;
      payload: number;
    }
  | {
      type: typeof SET_TRACKER_DATA;
      payload: TrackerData;
    }
  | {
      type: typeof SET_INSTRUCTIONS_UNDERSTOOD;
      payload: boolean;
    };

export default function reducer(state = initialState, action: Action): State {
  switch (action.type) {
    case SET_PROJECT_ID:
      return {
        ...state,
        projectId: action.payload,
      };
    case SET_APPLICATION_TYPE:
      return {
        ...state,
        applicationType: action.payload,
      };
    case SET_ASSESSMENT_ID:
      return {
        ...state,
        assessmentId: action.payload,
      };
    case SET_JOB_APPLICATION_ID:
      return {
        ...state,
        jobApplicationId: action.payload,
      };
    case SET_TRACKER_DATA:
      return {
        ...state,
        trackerData: action.payload,
      };
    case SET_INSTRUCTIONS_UNDERSTOOD:
      return {
        ...state,
        instructionsUnderstood: action.payload,
      };
    default:
      return state;
  }
}

// Action creators
export const setProjectId = (payload: number) => ({
  type: SET_PROJECT_ID,
  payload,
});
export const setApplicationType = (payload: string) => ({
  type: SET_APPLICATION_TYPE,
  payload,
});
export const setAssessmentId = (payload: number | null) => ({
  type: SET_ASSESSMENT_ID,
  payload,
});
export const setjobApplicationId = (payload: number) => ({
  type: SET_JOB_APPLICATION_ID,
  payload,
});
export const setTrackerData = (payload: TrackerData) => ({
  type: SET_TRACKER_DATA,
  payload,
});
export const setInstructionsUnderstood = (payload: boolean) => ({
  type: SET_INSTRUCTIONS_UNDERSTOOD,
  payload,
});

// Selectors
export const selectProjectId = (app: Store) => app.abilityTests.projectId;
export const selectAssessmentId = (app: Store) => app.abilityTests.assessmentId;
export const selectJobApplicationId = (app: Store) =>
  app.abilityTests.jobApplicationId;
export const selectApplicationType = (app: Store) =>
  app.abilityTests.applicationType;
export const selectTrackerData = (app: Store) => app.abilityTests.trackerData;
export const selectInstructionsUnderstood = (app: Store) =>
  app.abilityTests.instructionsUnderstood;
