import { useState } from 'react';

export const Tooltip = ({ text, image, ...props }: any) => {
  const [isHovering, setIsHovering] = useState(false);

  const tooltipText = props.selectProps?.tooltipText
    ? props.selectProps.tooltipText
    : text;

  return (
    <div className="Tooltip">
      <div
        className={`TooltipIcon`}
        onMouseEnter={(e) => setIsHovering(true)}
        onMouseLeave={(e) => setIsHovering(false)}
      >
        {image ? <img src={image} alt={'Icon'} /> : '?'}
      </div>

      <div className={`TooltipText ${isHovering ? '' : 'Hidden'}`}>
        {tooltipText}
      </div>
    </div>
  );
};
