import { Store } from './rootReducer';

export type MatchingProcessState = {
  updateProfileToggle: boolean;
  panelType: string | null;
  dataSubmittedToggle: boolean;
  submitSuccess: boolean;
  matchingProcessCall: boolean;
  expressedInterest: boolean;
  blockedInterest: boolean;
};

type UpdateProfile = {
  updateProfileToggle: boolean;
};

type PanelType = {
  panelType: boolean;
};

type DataSubmitted = {
  dataSubmittedToggle: boolean;
};

type SubmitSuccess = {
  submitSuccess: boolean;
};

const initialState: MatchingProcessState = {
  updateProfileToggle: false,
  panelType: null,
  dataSubmittedToggle: false,
  submitSuccess: false,
  matchingProcessCall: false,
  expressedInterest: false,
  blockedInterest: false,
};

//Types
export enum MatchingProcessActionType {
  UPDATE_PROFILE = 'UPDATE_PROFILE',
  PANEL_TYPE = 'PANEL_TYPE',
  DATA_SUBMITTED = 'DATA_SUBMITTED',
  SUBMIT_SUCCESS = 'SUBMIT_SUCCESS',
  SET_MATCHING_PROCESS_CALL = 'SET_MATCHING_PROCESS_CALL',
  SET_EXPRESSED_INTEREST = 'SET_EXPRESSED_INTEREST',
  SET_BLOCKED_INTEREST = 'SET_BLOCKED_INTEREST',
}
//   const UPDATE_PROFILE = 'app/reviewCandidate/UPDATE_PROFILE';

/* ACTIONS */

// Actions
export type Action =
  | {
      type: null;
      updateProfileToggle: false;
      panelType: null;
    }
  | {
      type: MatchingProcessActionType.UPDATE_PROFILE;
      payload: UpdateProfile;
    }
  | {
      type: MatchingProcessActionType.PANEL_TYPE;
      payload: PanelType;
    }
  | {
      type: MatchingProcessActionType.DATA_SUBMITTED;
      payload: DataSubmitted;
    }
  | {
      type: MatchingProcessActionType.SUBMIT_SUCCESS;
      payload: SubmitSuccess;
    }
  | {
      type: MatchingProcessActionType.SET_MATCHING_PROCESS_CALL;
      payload: boolean;
    }
  | {
      type: MatchingProcessActionType.SET_EXPRESSED_INTEREST;
      payload: boolean;
    }
  | {
      type: MatchingProcessActionType.SET_BLOCKED_INTEREST;
      payload: boolean;
    };

/* REDUCER */
export default function matchingProcess(state = initialState, action: Action) {
  switch (action.type) {
    case MatchingProcessActionType.UPDATE_PROFILE:
      return {
        ...state,
        updateProfileToggle: action.payload.updateProfileToggle,
      };
    case MatchingProcessActionType.PANEL_TYPE:
      return {
        ...state,
        panelType: action.payload,
      };
    case MatchingProcessActionType.DATA_SUBMITTED:
      return {
        ...state,
        dataSubmittedToggle: action.payload,
      };
    case MatchingProcessActionType.SUBMIT_SUCCESS:
      return {
        ...state,
        submitSuccess: action.payload,
      };
    case MatchingProcessActionType.SET_MATCHING_PROCESS_CALL:
      return {
        ...state,
        matchingProcessCall: action.payload,
      };
    case MatchingProcessActionType.SET_EXPRESSED_INTEREST:
      return {
        ...state,
        expressedInterest: action.payload,
      };
    case MatchingProcessActionType.SET_BLOCKED_INTEREST:
      return {
        ...state,
        blockedInterest: action.payload,
      };

    default:
      return state;
  }
}

/* ACTION CREATORS */
export const setMatchingProcessUpdateProfileToggle = (
  payload: UpdateProfile
) => ({
  type: MatchingProcessActionType.UPDATE_PROFILE,
  payload,
});

export const setMatchingProcessPanelType = (payload: string) => ({
  type: MatchingProcessActionType.PANEL_TYPE,
  payload,
});

export const setMatchingProcessDataSubmittedToggle = (payload: boolean) => ({
  type: MatchingProcessActionType.DATA_SUBMITTED,
  payload,
});
export const setMatchingProcessSubmitSuccess = (payload: boolean) => ({
  type: MatchingProcessActionType.SUBMIT_SUCCESS,
  payload,
});
export const setMatchingProcessCall = (payload: boolean) => ({
  type: MatchingProcessActionType.SET_MATCHING_PROCESS_CALL,
  payload,
});

export const setExpressedInterest = (payload: boolean) => ({
  type: MatchingProcessActionType.SET_EXPRESSED_INTEREST,
  payload,
});

export const setBlockedInterest = (payload: boolean) => ({
  type: MatchingProcessActionType.SET_BLOCKED_INTEREST,
  payload,
});

/* SELECTORS */
export const selectMatchingProcess = (state: Store) => state.matchingProcess;
