import { Button } from 'components/button/Button';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { closeModal } from 'reducers/modal';
import Icon_Warning from 'assets/apply.clevry/Icon_Warning.png';
import { Dropdown } from 'components/helpers/Dropdown';
import { getWithdrawReasons, withdrawApplication } from './ViewApplicationApi';
import { navigate } from '@reach/router';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';

type WithdrawApplicationModalProps = {
  data: {
    title: string;
    modalClasses: string;
    modalHeaderClasses: string;
    projectId: number;
  };
};

const WithdrawApplicationModal: React.FC<WithdrawApplicationModalProps> = ({
  data,
}) => {
  const { t } = useTranslation('Withdraw+Application+Modal', { i18n });

  const [selectedValue, setSelectedValue] = useState<number>();
  const [error, setError] = useState(false);
  const [dropdownOptions, setDropdownOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [selectedDisplayValue, setDisplayValue] = useState<{
    label: string;
    value: string;
  }>({
    label: t('SSCandidate_Withdraw_Application_Modal_Please_Select'),
    value: '',
  });
  const dispatch = useDispatch();

  const getDropdownOptions = (
    options: { reasonName: string; reasonId: number }[]
  ) => {
    return options.map((option) => ({
      label: option.reasonName,
      value: option.reasonId.toString(),
    }));
  };

  useEffect(() => {
    (async () => {
      const result: any = await getWithdrawReasons({});
      if (result && result.errors?.length === 0) {
        const reasons: { reasonName: string; reasonId: number }[] =
          result.response.reasons;
        setDropdownOptions(getDropdownOptions(reasons));
      }
      if (result && result.errors && result.errors.length > 0) {
        setError(true);
      }
    })();
  }, []);

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const onChange = ({ label, value }: { label: string; value: string }) => {
    setSelectedValue(parseInt(value));
    setDisplayValue({ label, value });
  };

  const handleSubmit = () => {
    if (selectedValue) {
      (async () => {
        const result:
          | { data: { errors: []; id: number; status: string } }
          | undefined = await withdrawApplication({
          ProjectId: data.projectId,
          withdrawReasonId: selectedValue,
        });
        if (result && result.data.errors.length === 0) {
          handleCloseModal();
          navigate('/historic-applications');
        }
        if (result && result.data.errors.length > 0) {
          setError(true);
        }
      })();
    } else {
      setError(true);
    }
  };

  return (
    <div className="WithdrawApplicationModal FullWidth">
      <div className="Content">
        <div className="Paragraph Title">
          {t('SSCandidate_Withdraw_Application_Modal_Permanently_Withdraw')}
        </div>

        <div className="Paragraph">
          {t('SSCandidate_Withdraw_Application_Modal_Are_You_Sure')}
        </div>
        <div className="Paragraph Warning">
          <img
            className="WarningIcon"
            src={Icon_Warning}
            alt={t('SSCandidate_Withdraw_Application_Modal_Alt_Warning_Icon')}
          />
          {t('SSCandidate_Withdraw_Application_Modal_Cannot_Be_Undone')}
        </div>
        <div className="Paragraph ReasonLabel">
          {t('SSCandidate_Withdraw_Application_Modal_Please_Select_Reason')}
        </div>
        <div className="ReasonDropdown">
          <Dropdown
            options={dropdownOptions}
            value={selectedDisplayValue}
            onChange={onChange}
            roundedRectangle={false}
          />
          {error && (
            <div className="Paragraph WithdrawalError">
              {selectedValue
                ? t(
                    'SSCandidate_Withdraw_Application_Modal_Withdrawal_Unsuccessful'
                  )
                : t(
                    'SSCandidate_Withdraw_Application_Modal_Missing_Reason_Error'
                  )}
            </div>
          )}
        </div>
      </div>
      <div className="ModalFooter">
        <Button priority="tertiary" type="button" onClick={handleCloseModal}>
          {t('SSCandidate_Withdraw_Application_Modal_Cancel')}
        </Button>
        <Button
          className="Discard"
          priority="primary"
          type="submit"
          onClick={handleSubmit}
        >
          {t('SSCandidate_Withdraw_Application_Modal_Confirm')}
        </Button>
      </div>
    </div>
  );
};

export { WithdrawApplicationModal };
