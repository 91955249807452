import React, { useEffect, useRef, useState } from 'react';
import uniqid from 'uniqid';
import {
  InteractiveFeedbackCard,
  InteractiveFeedbackCardProps,
} from './InteractiveFeedbackCard';
import {
  InteractiveFeedbackMenu,
  InteractiveFeedbackMenuProps,
} from './InteractiveFeedbackMenu';
import { InteractiveFeedbackMenuRowProps } from './InteractiveFeedbackMenuRow';
import { InteractiveFeedbackMenuScoreRowProps } from './InteractiveFeedbackMenuScoreRow';
import { InteractiveFeedbackMenuScores } from './InteractiveFeedbackMenuScores';
import useMedia from 'use-media';
import { InteractiveFeedbackGraph } from './InteractiveFeedbackGraph';

type InteractiveFeedbackMenuCardDetails = Omit<
  InteractiveFeedbackCardProps,
  'active' | 'setActive' | 'numberOfItems' | 'setHeight'
>;

type InteractiveFeedbackMenuCardHubProps = Omit<
  InteractiveFeedbackMenuProps,
  'active' | 'setActive' | 'height' | 'rows'
> & {
  rows?: Pick<
    InteractiveFeedbackMenuRowProps,
    'icon' | 'text' | 'rowClick' | 'type'
  >[];
  wheelImage?: any;
  cardDetails: InteractiveFeedbackMenuCardDetails[];
  graphCardDetails?: InteractiveFeedbackMenuCardDetails[];
  defaultCard?: InteractiveFeedbackMenuCardDetails;
  scoreRowDetails?: Omit<
    InteractiveFeedbackMenuScoreRowProps,
    | 'primaryButtonActive'
    | 'secondaryButtonActive'
    | 'setActive'
    | 'height'
    | 'rowNumber'
    | 'numberOfItems'
  >[];
  backArrowClickHandler?: () => void;
  score?: number;
  onAnimationEnd?: () => void;
  animateDismount?: boolean;
  setAnimateDismount?: React.Dispatch<React.SetStateAction<boolean>>;
  downloadReport?: boolean;
  fullProfile?: boolean;
  setAlternateView?: React.Dispatch<React.SetStateAction<boolean>>;
  reportCode?: string;
  assessmentID?: number;
  isCandidateReport?: boolean;
  graphType?: string;
  previousMenuSectionHandler?: () => void;
  nextMenuSectionHandler?: () => void;
  hideStenScores?: boolean;
  sectionData?: any;
};

export const InteractiveFeedbackMenuCardHub: React.FC<
  InteractiveFeedbackMenuCardHubProps
> = ({
  cardDetails,
  graphCardDetails,
  graphType,
  rows,
  wheelImage,
  title,
  headingCloseClickHandler,
  headingIcon,
  defaultCard,
  scoreRowDetails,
  backArrowClickHandler,
  score,
  setAnimateDismount,
  animateDismount,
  onAnimationEnd,
  downloadReport,
  fullProfile,
  setAlternateView,
  reportCode,
  assessmentID,
  isCandidateReport,
  previousMenuSectionHandler,
  nextMenuSectionHandler,
  children,
  hideStenScores,
  sectionData,
}) => {
  const [active, setActive] = useState<number | null>(null);
  const [graphActive, setGraphActive] = useState<boolean>(false);
  const [activeCard, setActiveCard] = useState<number | null>(null);
  const [height, setHeight] = useState(0);
  const [cardToggle, setCardToggle] = useState(true);
  const isTablet = useMedia('(max-width: 768px)');

  let childrenWithProps = null;

  if (children && active !== null) {
    childrenWithProps = React.Children.map(children, (child) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(
          child as React.ReactElement<{ active: number }>,
          { active }
        );
      }
      return child;
    });
  }

  useEffect(() => {
    setCardToggle((toggle) => !toggle);
    setTimeout(() => {
      setActiveCard(active);
    }, 300);
  }, [active, graphActive]);

  return (
    <div
      className={`InteractiveFeedbackMenuCardHub ${
        animateDismount ? 'ExitRight' : ''
      }`}
      onAnimationEnd={() => {
        if (animateDismount && onAnimationEnd && setAnimateDismount) {
          setAnimateDismount(false);
          onAnimationEnd();
        }
      }}
    >
      {!scoreRowDetails &&
        rows &&
        rows.length === 0 &&
        wheelImage &&
        graphType && (
          <InteractiveFeedbackGraph
            active={active}
            setActive={setActive}
            title={title}
            headingIcon={headingIcon}
            headingCloseClickHandler={headingCloseClickHandler}
            score={score}
            height={height}
            reportCode={reportCode}
            isCandidateReport={isCandidateReport ? isCandidateReport : false}
            wheelImage={wheelImage}
            graphActive={graphActive}
            setGraphActive={setGraphActive}
            graphType={graphType}
            sectionData={sectionData}
          />
        )}
      {!scoreRowDetails && rows && !wheelImage && (
        <InteractiveFeedbackMenu
          active={active}
          setActive={setActive}
          rows={rows}
          title={title}
          headingIcon={headingIcon}
          headingCloseClickHandler={headingCloseClickHandler}
          score={score}
          height={height}
          downloadReport={downloadReport}
          fullProfile={fullProfile}
          setAlternateView={setAlternateView}
          reportCode={reportCode}
          assessmentID={assessmentID}
          isCandidateReport={isCandidateReport}
          hideStenScores={hideStenScores}
          sectionData={sectionData}
        />
      )}

      {scoreRowDetails && !rows && (
        <InteractiveFeedbackMenuScores
          active={active}
          setActive={setActive}
          rows={scoreRowDetails}
          height={height}
          title={title}
          backArrowClickHandler={backArrowClickHandler}
          numberOfItems={cardDetails.length}
          previousMenuSectionHandler={previousMenuSectionHandler}
          nextMenuSectionHandler={nextMenuSectionHandler}
          sectionData={sectionData}
        />
      )}
      {!isTablet &&
        active !== null &&
        activeCard !== null &&
        cardDetails &&
        cardDetails[active] &&
        cardDetails[activeCard] &&
        graphActive === false && (
          <div className="InteractiveFeedbackMenuCardHub__flipCard">
            <div
              className={`InteractiveFeedbackMenuCardHub__flipCardInner`}
              style={{
                transform: `rotateY(${cardToggle ? 180 : 0}deg) ${
                  cardToggle ? 'scale(-1, 1)' : ''
                } `,
              }}
            >
              <InteractiveFeedbackCard
                active={active}
                setActive={setActive}
                key={uniqid()}
                bottomColor={cardDetails[activeCard ?? active].bottomColor}
                bottomFont={cardDetails[activeCard ?? active].bottomFont}
                bottomFontSize={
                  cardDetails[activeCard ?? active].bottomFontSize
                }
                topColor={cardDetails[activeCard ?? active].topColor}
                heading={cardDetails[activeCard ?? active].heading}
                topImage={cardDetails[activeCard ?? active].topImage}
                noButtons={cardDetails[activeCard ?? active].noButtons}
                score={cardDetails[activeCard ?? active].score}
                type={cardDetails[activeCard ?? active].type}
                cardText={cardDetails[activeCard ?? active].cardText}
                cardHTML={cardDetails[activeCard ?? active].cardHTML}
                numberOfItems={cardDetails.length}
                setHeight={setHeight}
                hideStenScores={hideStenScores}
              >
                {childrenWithProps && childrenWithProps}
              </InteractiveFeedbackCard>
            </div>
          </div>
        )}
      {!isTablet &&
        active !== null &&
        graphActive === true &&
        graphCardDetails &&
        graphCardDetails[active] && (
          <div className="InteractiveFeedbackMenuCardHub__flipCard">
            <div
              className={`InteractiveFeedbackMenuCardHub__flipCardInner`}
              style={{
                transform: `rotateY(${cardToggle ? 180 : 0}deg) ${
                  cardToggle ? 'scale(-1, 1)' : ''
                } `,
              }}
            >
              <InteractiveFeedbackCard
                active={active}
                setActive={setActive}
                key={uniqid()}
                bottomColor={graphCardDetails[activeCard ?? active].bottomColor}
                bottomFont={graphCardDetails[activeCard ?? active].bottomFont}
                bottomFontSize={
                  graphCardDetails[activeCard ?? active].bottomFontSize
                }
                topColor={graphCardDetails[activeCard ?? active].topColor}
                heading={graphCardDetails[activeCard ?? active].heading}
                topImage={graphCardDetails[activeCard ?? active].topImage}
                noButtons={graphCardDetails[activeCard ?? active].noButtons}
                score={graphCardDetails[activeCard ?? active].score}
                type={graphCardDetails[activeCard ?? active].type}
                cardText={graphCardDetails[activeCard ?? active].cardText}
                cardHTML={graphCardDetails[activeCard ?? active].cardHTML}
                numberOfItems={graphCardDetails.length}
                setHeight={setHeight}
                hideStenScores={hideStenScores}
              >
                {childrenWithProps && childrenWithProps}
              </InteractiveFeedbackCard>
            </div>
          </div>
        )}

      {isTablet && active !== null && cardDetails && cardDetails.length > 0 && (
        <InteractiveFeedbackCard
          active={active}
          setActive={setActive}
          key={uniqid()}
          bottomColor={
            graphCardDetails && graphActive
              ? graphCardDetails[active].bottomColor
              : cardDetails[active].bottomColor
          }
          bottomFont={
            graphCardDetails && graphActive
              ? graphCardDetails[active].bottomFont
              : cardDetails[active].bottomFont
          }
          bottomFontSize={
            graphCardDetails && graphActive
              ? graphCardDetails[active].bottomFontSize
              : cardDetails[active].bottomFontSize
          }
          topColor={
            graphCardDetails && graphActive
              ? graphCardDetails[active].topColor
              : cardDetails[active].topColor
          }
          heading={
            graphCardDetails && graphActive
              ? graphCardDetails[active].heading
              : cardDetails[active].heading
          }
          topImage={
            graphCardDetails && graphActive
              ? graphCardDetails[active].topImage
              : cardDetails[active].topImage
          }
          noButtons={
            graphCardDetails && graphActive
              ? graphCardDetails[active].noButtons
              : cardDetails[active].noButtons
          }
          score={
            graphCardDetails && graphActive
              ? graphCardDetails[active].score
              : cardDetails[active].score
          }
          type={
            graphCardDetails && graphActive
              ? graphCardDetails[active].type
              : cardDetails[active].type
          }
          cardText={
            graphCardDetails && graphActive
              ? graphCardDetails[active].cardText
              : cardDetails[active].cardText
          }
          cardHTML={
            graphCardDetails && graphActive
              ? graphCardDetails[active].cardHTML
              : cardDetails[active].cardHTML
          }
          numberOfItems={
            graphCardDetails && graphActive
              ? graphCardDetails.length
              : cardDetails.length
          }
          setHeight={setHeight}
          hideStenScores={hideStenScores}
        >
          {childrenWithProps && childrenWithProps}
        </InteractiveFeedbackCard>
      )}
      {!isTablet && defaultCard && active === null && (
        <div className="InteractiveFeedbackMenuCardHub__flipCard">
          <div
            className={`InteractiveFeedbackMenuCardHub__flipCardInner`}
            style={{
              transform: `rotateY(${cardToggle ? 180 : 0}deg) ${
                cardToggle ? 'scale(-1, 1)' : ''
              } `,
            }}
          >
            <InteractiveFeedbackCard
              active={active}
              setActive={setActive}
              bottomColor={defaultCard.bottomColor}
              bottomFont={defaultCard.bottomFont}
              bottomFontSize={defaultCard.bottomFontSize}
              topColor={defaultCard.topColor}
              heading={defaultCard.heading}
              topImage={defaultCard.topImage}
              noButtons={defaultCard.noButtons}
              score={defaultCard.score}
              type={defaultCard.type}
              cardText={defaultCard.cardText}
              cardHTML={defaultCard.cardHTML}
              numberOfItems={cardDetails.length}
              setHeight={setHeight}
              hideStenScores={hideStenScores}
            >
              {childrenWithProps && childrenWithProps}
            </InteractiveFeedbackCard>
          </div>
        </div>
      )}

      {isTablet && defaultCard && active === null && (
        <InteractiveFeedbackCard
          active={active}
          setActive={setActive}
          bottomColor={defaultCard.bottomColor}
          bottomFont={defaultCard.bottomFont}
          bottomFontSize={defaultCard.bottomFontSize}
          topColor={defaultCard.topColor}
          heading={defaultCard.heading}
          topImage={defaultCard.topImage}
          noButtons={defaultCard.noButtons}
          score={defaultCard.score}
          type={defaultCard.type}
          cardText={defaultCard.cardText}
          cardHTML={defaultCard.cardHTML}
          numberOfItems={cardDetails.length}
          setHeight={setHeight}
          hideStenScores={hideStenScores}
        >
          {childrenWithProps && childrenWithProps}
        </InteractiveFeedbackCard>
      )}
    </div>
  );
};
