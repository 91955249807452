import { Store } from './rootReducer';

//Types
export enum AbilityTestSummaryAction {
  'SET_ABILITY_TEST_SUMMARY_PAGE' = 'SET_ABILITY_TEST_SUMMARY_PAGE',
  'CLOSE_ABILITY_TEST_SUMMARY_PAGE' = 'CLOSE_ABILITY_TEST_SUMMARY_PAGE',
}

export interface AbilityTestSummary {
  id: number | null;
  title: string | null;
}

const initialState: AbilityTestSummary = {
  id: null,
  title: null,
};

// Actions
export type Action = {
  type: AbilityTestSummaryAction.SET_ABILITY_TEST_SUMMARY_PAGE;
  payload: AbilityTestSummary;
};

// Reducer
export default function AbilityTestSummaryReducer(
  state = initialState,
  action: Action
): AbilityTestSummary {
  if (action.type === AbilityTestSummaryAction.SET_ABILITY_TEST_SUMMARY_PAGE) {
    return { ...state, ...action.payload };
  }
  if (
    action.type === AbilityTestSummaryAction.CLOSE_ABILITY_TEST_SUMMARY_PAGE
  ) {
    return { id: null, title: null };
  }
  return state;
}

// Action creators
export const setAbilityTestSummary = (payload: AbilityTestSummary) => ({
  type: AbilityTestSummaryAction.SET_ABILITY_TEST_SUMMARY_PAGE,
  payload,
});

export const closeAbilityTestSummary = () => ({
  type: AbilityTestSummaryAction.SET_ABILITY_TEST_SUMMARY_PAGE,
});

// Selectors
export const selectAbilityTestSummary = (state: Store) =>
  state.abilityTestSummary;
