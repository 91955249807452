import { Link } from '@reach/router';
import { AppPath } from 'appConstants';
import Icon from 'assets/apply.clevry/Icon_CTA_01.png';
import { getDashboardInfo } from 'components/pages/joyAtWork/JoyAtWorkApi';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { signOut } from 'reducers/auth';
import { selectDashboardInfo, setDashboardInfo } from 'reducers/dashboardInfo';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';

export const JoyDropdownMenu = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const dashboardInfo = useSelector(selectDashboardInfo);

  const { t } = useTranslation('Joy+Dropdown+Menu', { i18n });

  const toggleIsOpen = () => {
    setIsOpen((oldIsOpen) => !oldIsOpen);
  };

  const handleSignOut = () => {
    dispatch(signOut());
  };

  const joyMenuRef = useRef<any>();

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (joyMenuRef.current && !joyMenuRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [joyMenuRef]);

  useEffect(() => {
    if (!dashboardInfo.isFetched) {
      getDashboardInfo().then((result) => {
        if (result.response) {
          dispatch(setDashboardInfo(result.response));
        }
      });
    }
  }, []);

  return (
    <div
      className={`UserMenu ${isOpen ? 'Highlighted' : ''} Joy`}
      ref={joyMenuRef}
      onClick={toggleIsOpen}
    >
      <button className="UserMenuOpen">
        <img src={Icon} alt={t('SSCandidate_Joy_Dropdown_Menu_Alt_Icon')} />
        <div className={`UserMenuContent ${isOpen ? 'Open' : ''}`}>
          <Link to={`${AppPath.MY_SUCCESS}`} className="MenuLink">
            {t('SSCandidate_Joy_Dropdown_Menu_Clevry_Test_Help')}
          </Link>
          <Link to={AppPath.MY_SOFT_SKILLS} className="MenuLink">
            {t('SSCandidate_Joy_Dropdown_Menu_My_Soft_Skills')}
          </Link>
          <Link to={AppPath.MY_DEVELOPMENT} className="MenuLink">
            {t('SSCandidate_Joy_Dropdown_Menu_Practice_Test')}
          </Link>
          <Link to={AppPath.MY_JOY} className="MenuLink">
            {t('SSCandidate_Joy_Dropdown_Menu_Joy_Quiz')}
          </Link>
          <Link to={AppPath.COMPARE_SOFT_SKILLS} className="MenuLink">
            {t('SSCandidate_Joy_Dropdown_Menu_Compare_With_Friends')}
          </Link>
          <Link to={AppPath.DAILY_JOY_TRACKER} className="MenuLink">
            {t('SSCandidate_Joy_Dropdown_Menu_Joy_Tracker')}
          </Link>
          <Link to={AppPath.HOW_JOYFUL} className="MenuLink">
            {t('SSCandidate_Joy_Dropdown_Menu_Joy_At_Work_Levels')}
          </Link>
          <Link to={AppPath.MY_GROWTH} className="MenuLink">
            {t('SSCandidate_Joy_Dropdown_Menu_My_Growth')}
          </Link>
        </div>
      </button>
    </div>
  );
};
