import { navigate } from '@reach/router';
import { AppPath } from 'appConstants';
import ClevryLogo from 'assets/apply.clevry/Logo2.png';
// import psycruitLogoWhite from 'assets/apply.clevry/Logo_White_02.png';
import { Button } from 'components/button/Button';
import { SignInForm } from 'components/pages/signIn/SignInForm';
import { SignUpForm } from 'components/pages/signUp/SignUpForm';
import { PublicModal } from 'components/publicModal/PublicModal';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BurgerIcon from 'assets/apply.clevry/Icon_Menu.png';
import {
  selectSignInFormState,
  setCloseLandingPageForm,
  setOpenSignInForm,
  setOpenSignUpForm,
} from 'reducers/landingPage';
import { NavBarContext } from './NavBar';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';
import { NamedFeatureFlag, selectFeatureFlags } from 'reducers/featureFlags';
import { PublicNavItem } from './LoggedInNavBar';
import { NavLink } from './NavLink';
import { themes } from 'theme';
import {
  selectInIframe,
  selectWebskin,
  selectIsAssessmentOnlyCandidate,
} from 'reducers/auth';
import { getClientLogo } from 'components/pages/jobBoard/JobBoardApi';
import { checkIncludesClevrySubdomain } from 'helpers/checkIsClevrySubdomain';

export const LoginNavBar: React.FC = () => {
  const { t } = useTranslation('Login+Nav+Bar', { i18n });
  const currentWebskin = window.sessionStorage.getItem('webskin')
    ? window.sessionStorage.getItem('webskin')
    : 'defaultTheme';

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [logo, setLogo] = useState<string>();
  const [loading, setLoading] = useState<boolean>(true);

  const insideIframe = useSelector(selectInIframe);
  const selectedWebskin = useSelector(selectWebskin);
  const isAssessmentOnlyCandidate = useSelector(
    selectIsAssessmentOnlyCandidate
  );

  const navItems: PublicNavItem[] = [
    {
      path: AppPath.ROOT,
      label: t('SSCandidate_Login_Nav_Bar_Nav_Item_1'),
    },
    // {
    //   path: AppPath.FIND_YOUR_JOY,
    //   label: t('SSCandidate_Login_Nav_Bar_Nav_Item_2'),
    // },
    {
      path: AppPath.PUBLIC_BROWSE_OPPORTUNITIES,
      label: t('SSCandidate_Login_Nav_Bar_Nav_Item_3'),
    },
  ];

  const handleLogoClick = () => navigate(AppPath.ROOT);
  const dispatch = useDispatch();
  const signInFormState = useSelector(selectSignInFormState);

  useEffect(() => {
    if (signInFormState !== null) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    return () => {
      if (document.body.style.overflow) {
        document.body.style.overflow = '';
      }
    };
  }, [signInFormState]);

  useEffect(() => {
    setLoading(true);

    const storedJobBoardClientName = localStorage.getItem('jobBoardClientName');

    // if (
    //   storedJobBoardClientName &&
    //   !checkIncludesClevrySubdomain(storedJobBoardClientName)
    // ) {
    //   (async () => {
    //     const payload = {
    //       CompanyName: storedJobBoardClientName,
    //     };
    //     const clientLogo = await getClientLogo(payload);

    //     if (clientLogo && clientLogo.response) {
    //       const parsedImage = URL.createObjectURL(clientLogo.response);
    //       setLogo(parsedImage);
    //     }
    //   })();

    //   setLoading(false);
    // } else {
    if (selectedWebskin && selectedWebskin.siteImages) {
      const webskinLogo = selectedWebskin.siteImages.find(
        (item: any) => item.code === 'SI_Logo'
      ).imageDownloadURI;
      setLogo(webskinLogo);
    } else {
      setLogo(ClevryLogo);
    }
    setLoading(false);
    // }
  }, [selectedWebskin]);

  if (loading) {
    return null;
  }

  return (
    <>
      <nav className="Nav LoggedOut">
        <div
          className={`PageNavBar LoginNav ${
            currentWebskin !== 'defaultTheme' ? 'Webskin' : ''
          }`}
        >
          <button
            className="BurgerMenuButton"
            onClick={() => setMenuIsOpen(true)}
          >
            <img
              src={BurgerIcon}
              className="BurgerIcon"
              alt={t('SSCandidate_Logged_In_Nav_Bar_Alt_Open_Menu')}
            />
          </button>
          <button
            onClick={handleLogoClick}
            className={`UnstyledButton ${insideIframe ? 'Disabled' : ''}`}
          >
            {currentWebskin !== 'defaultTheme' ? (
              <img
                src={logo}
                alt={t('SSCandidate_Login_Nav_Bar_Alt_Company_Logo')}
                className="CompanyLogo"
              />
            ) : logo ? (
              <img
                src={logo}
                alt={t('SSCandidate_Login_Nav_Bar_Alt_Company_Logo')}
                className="CompanyLogo"
              />
            ) : null}
          </button>
          <div
            className={`NavBarContentBackground ${menuIsOpen ? '' : 'Hidden'}`}
            onClick={() => setMenuIsOpen(false)}
          ></div>
          <div
            className={`NavBarContent LoggedInNavBarContent ${
              menuIsOpen ? '' : 'Hidden'
            }`}
          >
            <div className="Links">
              {navItems &&
                (!currentWebskin || currentWebskin === 'defaultTheme') &&
                !isAssessmentOnlyCandidate && (
                  <>
                    {navItems.map((item: any, index) => {
                      return (
                        <div
                          className="LinkContainer"
                          key={`${index}_${item.path}`}
                        >
                          <NavLink
                            path={item.path}
                            onClick={() => setMenuIsOpen(false)}
                            key={index}
                          >
                            {item.label}
                          </NavLink>
                        </div>
                      );
                    })}
                  </>
                )}
            </div>
            <div className="LoginButtons">
              {currentWebskin === 'defaultTheme' ? (
                <Button
                  priority="primary"
                  className="Btn SignUpNavBtn"
                  isPublic
                  onClick={() =>
                    currentWebskin !== 'defaultTheme'
                      ? navigate(AppPath.SIGN_UP_PAGE)
                      : dispatch(setOpenSignUpForm(AppPath.MATCHING_PROFILE))
                  }
                >
                  Sign up
                </Button>
              ) : (
                <Button
                  priority="secondary"
                  className="Btn SignUpNavBtn"
                  isPublic
                  onClick={() => navigate(AppPath.SIGN_UP_PAGE)}
                >
                  Sign up
                </Button>
              )}

              <Button
                priority="primary"
                className="Btn LoginNavBtn"
                isPublic
                onClick={() => {
                  dispatch(setOpenSignInForm());
                }}
              >
                {currentWebskin === 'defaultTheme'
                  ? t('SSCandidate_Login_Nav_Bar_Sign_In')
                  : 'Sign in'}
              </Button>
            </div>
          </div>
        </div>
      </nav>
      {signInFormState !== null && (
        <PublicModal
          handleClose={() => dispatch(setCloseLandingPageForm())}
          className="FormModal"
        >
          {signInFormState === 'SIGN_IN' ? (
            <SignInForm switchToSignUp={() => dispatch(setOpenSignUpForm())} />
          ) : (
            <SignUpForm switchToSignIn={() => dispatch(setOpenSignInForm())} />
          )}
        </PublicModal>
      )}
    </>
  );
};
