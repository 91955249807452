import { navigate } from '@reach/router';
import { Button } from 'components/button/Button';
import React from 'react';
import Icon_Warning from '../../assets/apply.clevry/Icon_Warning.png';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';

type ErrorFallbackProps = {
  customHeading?: string;
  customError?: string;
  customBtnText?: string;
  showIcon?: boolean;
  customBtnOnClick?: () => void;
};

const ErrorFallback: React.FC<ErrorFallbackProps> = ({
  customHeading,
  customError,
  customBtnText,
  customBtnOnClick,
  showIcon = true,
}) => {
  const { t } = useTranslation('Error+Fallback', { i18n });

  return (
    <div className="ErrorPage">
      <div className="ErrorCell">
        {showIcon && (
          <div className="Img">
            <img
              src={Icon_Warning}
              alt={t('SSCandidate_Error_Fallback_Alt_Error')}
            />
          </div>
        )}
        <div className="Txt">
          <h1>
            {customHeading
              ? customHeading
              : t('SSCandidate_Error_Fallback_Something_Went_Wrong')}
          </h1>
          <p>
            {customError
              ? customError
              : t('SSCandidate_Error_Fallback_Try_Again')}
          </p>
        </div>
        <Button
          priority={'primary'}
          onClick={
            customBtnOnClick
              ? customBtnOnClick
              : () => {
                  navigate('/home');
                }
          }
        >
          {customBtnText
            ? customBtnText
            : t('SSCandidate_Error_Fallback_Return_Home')}
        </Button>
      </div>
    </div>
  );
};

export default ErrorFallback;
