import React from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';
import { VirtualAssessmentsHeaderTracker } from 'components/pages/virtualAssessments/VirtualAssessmentsHeaderTracker';
import { AbilityTestHeaderTracker } from 'components/pages/abilityTests/abilityTestResources/AbilityTestHeaderTracker';
import { SJTHeaderTracker } from 'components/pages/situationalJudgementTest/SJTResources/SJTHeaderTracker';
import { Button } from 'components/button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { openModal } from 'reducers/modal';
import { selectAccessibilityData } from 'reducers/accessibility';
import { GetClientLogo } from '../../helpers/GetClientLogo';
import { AssessmentHeaderTracker } from 'components/assessmentComponents/AssessmentHeaderTracker';

interface GenericActivityHeaderProps {
  title: string;
  activityType: string | null;
  type?: GenericActivityHeaderType;
}

export enum GenericActivityHeaderType {
  'VIRTUAL_ASSESSMENT',
  'ABILITY_TEST',
  'SJT',
  'BLENDED_TEST',
  'ASSESSMENT_PRACTICE',
}

const GenericActivityHeader: React.FC<GenericActivityHeaderProps> = ({
  title,
  activityType,
  type,
}) => {
  const { t } = useTranslation('Generic+Activity+Header', { i18n });
  const dispatch = useDispatch();

  const accessibilityData = useSelector(selectAccessibilityData);

  let translatedTitle = '';
  if (activityType && activityType === 'RECORDED_INTERVIEW') {
    translatedTitle = t(
      'SSCandidate_Generic_Activity_Header_Recorded_Interview_Title'
    );
  } else if (activityType && activityType === 'PRESENTATION_ACTIVITY') {
    translatedTitle = t(
      'SSCandidate_Generic_Activity_Header_Presentation_Activity_Title'
    );
  } else if (activityType && activityType === 'BUSINESS_CASE_ACTIVITY') {
    translatedTitle = t(
      'SSCandidate_Generic_Activity_Header_Business_Case_Activity_Title'
    );
  } else if (activityType && activityType === 'VIRTUAL_TEAM_DISCUSSION') {
    translatedTitle = t(
      'SSCandidate_Generic_Activity_Header_Virtual_Team_Discussion_Title'
    );
  } else if (activityType && activityType === 'LIVE_INTERVIEW') {
    translatedTitle = t(
      'SSCandidate_Generic_Activity_Header_Live_Interview_Title'
    );
  } else if (activityType && activityType === 'VIRTUAL_MEETING') {
    translatedTitle = t(
      'SSCandidate_Generic_Activity_Header_Virtual_Meeting_Title'
    );
  } else if (activityType && activityType === 'MATCHING_PROCESS') {
    translatedTitle = 'Matching process';
  }

  const headerStyle = accessibilityData.isSet
    ? ({
        '--backgroundColor': accessibilityData.backgroundColor,
        '--titleColor': accessibilityData.titleColor,
        '--fontColor': accessibilityData.textColor,
        '--borderColor': accessibilityData.borderColor,
      } as React.CSSProperties)
    : ({
        '--backgroundColor': accessibilityData.backgroundColor,
        '--titleColor': accessibilityData.titleColor,
        '--fontColor': accessibilityData.textColor,
        '--borderColor': '#D5D5D5',
      } as React.CSSProperties);

  const setBorderColor = accessibilityData.isSet ? accessibilityData.borderColor : "#D5D5D5";

  // return <div className="GenericActivityHeader">{title}</div>;

  return (
    <div
      className={`GenericActivityHeader 
        ${
          type === GenericActivityHeaderType.VIRTUAL_ASSESSMENT
            ? 'VirtualAssessment'
            : ''
        } 
        ${type === GenericActivityHeaderType.ABILITY_TEST ? 'AbilityTest' : ''}
        ${type === GenericActivityHeaderType.SJT ? 'SJTHdr' : ''}
        ${
          type === GenericActivityHeaderType.BLENDED_TEST ||
          type === GenericActivityHeaderType.ASSESSMENT_PRACTICE
            ? 'Assessment'
            : ''
        }
        ${accessibilityData.classStr}
        `}
      style={headerStyle}
    >
      <div className="WidthContent">
        {(type === GenericActivityHeaderType.VIRTUAL_ASSESSMENT ||
          type === GenericActivityHeaderType.ABILITY_TEST ||
          type === GenericActivityHeaderType.SJT ||
          type === GenericActivityHeaderType.BLENDED_TEST ||
          type === GenericActivityHeaderType.ASSESSMENT_PRACTICE) && (
          <Button
            className="SideMenuBtn Rounded AccessibilityBtn"
            priority={'tertiary'}
            disabled={false}
            addTabIndex={3}
            onClick={() => {
              dispatch(
                openModal({
                  modalType:
                    type === GenericActivityHeaderType.VIRTUAL_ASSESSMENT
                      ? 'ACCESSIBILITY'
                      : type === GenericActivityHeaderType.ABILITY_TEST
                      ? 'ABILITY_TESTS_ACCESSIBILITY'
                      : type === GenericActivityHeaderType.SJT
                      ? 'SJT_TESTS_ACCESSIBILITY'
                      : type === GenericActivityHeaderType.BLENDED_TEST ||
                        type === GenericActivityHeaderType.ASSESSMENT_PRACTICE
                      ? 'ASSESSMENT_ACCESSIBILITY'
                      : 'ACCESSIBILITY',
                  data: {
                    title: 'Accessibility settings',
                    modalHeaderClasses: 'VirtualAssessment',
                  },
                })
              );
            }}
            style={
              {
                borderColor: setBorderColor,
              } as React.CSSProperties
            }
          >
            Accessibility
          </Button>
        )}
        <span>
          {translatedTitle && translatedTitle !== ''
            ? translatedTitle
            : title && title !== ''
            ? title
            : ''}
        </span>
        <div className="Header__Right">
          {type === GenericActivityHeaderType.VIRTUAL_ASSESSMENT && (
            <VirtualAssessmentsHeaderTracker />
          )}
          {type === GenericActivityHeaderType.ABILITY_TEST && (
            <AbilityTestHeaderTracker />
          )}
          {type === GenericActivityHeaderType.SJT && <SJTHeaderTracker />}
          {type === GenericActivityHeaderType.BLENDED_TEST && (
            <AssessmentHeaderTracker />
          )}
          <GetClientLogo activityType={type} />
        </div>
      </div>
    </div>
  );
};

export { GenericActivityHeader };
