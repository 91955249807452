import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { BASE_URL, endpoints } from 'config';
import {
  isResponseOkay,
  SignInAttempt,
  SignInError,
  SignInOkayDetails,
  SignInResponse,
} from '../../../reducers/auth';
import api from 'api';

const linkedInRedirect = endpoints.auth.linkedInRedirect;
const linkedInSignInEndpoint = endpoints.auth.linkedInSignIn;

type ApiReturn<T = any> = {
  response: T | null;
  errors: Error[] | null;
};

type SignInForRedirectReturn = {
  response: null | SignInOkayDetails;
  error: null | SignInError[];
};
// Used rather than epic for the redirect - need to handle the response/errors differently
export const signInForRedirect = async (
  payload: SignInAttempt
): Promise<SignInForRedirectReturn> => {
  try {
    const res = await api.post<SignInResponse>(endpoints.auth.login, payload);

    if (isResponseOkay(res.data) && res.data.status === '1') {
      return {
        response: res.data.details,
        error: null,
      };
    } else if (!isResponseOkay(res.data)) {
      return {
        response: null,
        error: res.data,
      };
    } else throw new Error('Unknown Error');
  } catch (err: any) {
    return {
      response: null,
      error: err?.message,
    };
  }
};

export const redirectToLinkedIn = async (payload: {
  scope: string[];
}): Promise<ApiReturn> => {
  try {
    const params = new URLSearchParams();
    payload.scope.forEach((scope) => params.append('scope', scope));
    const config: AxiosRequestConfig = {
      params,
    };
    const res = await axios.get(linkedInRedirect, config);
    return {
      response: res,
      errors: null,
    };
  } catch (err: any) {
    return {
      response: null,
      errors: err,
    };
  }
};

export const linkedInSignIn = async (payload: {
  code: string;
  redirectURL: string;
}): Promise<ApiReturn> => {
  try {
    const params = new URLSearchParams();
    params.append('code', payload.code);
    params.append('redirectURL', payload.redirectURL);
    const config: AxiosRequestConfig = {
      params,
    };
    const res = await axios.get(linkedInSignInEndpoint, config);
    return {
      response: res,
      errors: null,
    };
  } catch (err: any) {
    return {
      response: null,
      errors: err,
    };
  }
};
