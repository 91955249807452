import React, { ErrorInfo } from "react";
import { isRedirect } from "@reach/router";
import * as Sentry from "@sentry/browser";

type State = { hasError: boolean };
type Props = { fallback: React.ReactNode; children: React.ReactNode };

export default class ErrorBoundary extends React.Component<Props, State> {
  static defaultProps = {
    fallback: null,
  };

  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    if (isRedirect(error)) {
      throw error;
    } else {
      /**
       * Sentry error logging
       * URL to Sentry for this project:
       * DEV: https://sentry.io/organizations/avamae-software-solutions/issues/?project=1801833
       * PROD: https://sentry.io/organizations/avamae-software-solutions/issues/?project=1801843
       */
      Sentry.withScope(scope => {
        // weird type conversion required to satisfy new setExtras type constraint
        // this was not necessary in previous versions
        scope.setExtras((errorInfo as unknown) as Record<string, unknown>);
        Sentry.captureException(error);
      });
    }
  }

  render() {
    if (this.state.hasError) return this.props.fallback;

    return this.props.children;
  }
}
