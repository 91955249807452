import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import queryString from 'query-string';
import { Spinner } from 'components/spinner/Spinner';
import { paths } from 'appConstants';
import { useDispatch } from 'react-redux';
import { StringIterator } from 'lodash';

type ExplorePageCardProps = {
  title: string;
  text: string;
  backgroundImage: string;
  pageList: string[];
  isData?: boolean;
  setCurrentPageNumber: Dispatch<SetStateAction<number>>;
  setCurrentPage: Dispatch<SetStateAction<string | null>>;
};

const ExplorePageCard: React.FC<ExplorePageCardProps> = ({
  title,
  text,
  backgroundImage,
  pageList,
  isData,
  setCurrentPageNumber,
  setCurrentPage,
}) => {
  const convertCardTitleToPageName = (title: string) => {
    const formattedString = title.replace(/\s+/g, '_').toUpperCase();

    return formattedString;
  };

  const handlePageSelection = () => {
    setTimeout(() => {
      const pageName = convertCardTitleToPageName(title);

      const filteredPageIndex = pageList.indexOf(pageName);

      setCurrentPageNumber(filteredPageIndex);
      setCurrentPage(pageList[filteredPageIndex]);
    }, 100);
  };

  return (
    <>
      <div
        className={`ExplorePageCard ${isData ? '' : 'disabled'}`}
        style={{
          backgroundImage: `linear-gradient(0deg, #36705B 15%, rgba(238,238,238,0) 100%), url(${backgroundImage})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
        onClick={handlePageSelection}
      >
        <div className="ExplorePageCard__TextContainer">
          <div className="ExplorePageCard__title">{title ? title : null}</div>
          <div className="ExplorePageCard__text">{text ? text : null}</div>
        </div>
      </div>
    </>
  );
};

export default ExplorePageCard;
