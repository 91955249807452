import instance from 'api';
import { AxiosRequestConfig } from 'axios';
import { endpoints } from 'config';

// Get SJT test details
export const getSJTDetails = async (AssessmentProngID: number) => {
  const config: AxiosRequestConfig = {
    params: {
      AssessmentProngID,
    },
  };

  try {
    const res = await instance.get(endpoints.sjt.getDetails, config);
    if (res.data.status === '1') {
      return {
        response: res.data,
        errors: null,
      };
    } else {
      return {
        response: null,
        errors: res.data.errors,
      };
    }
  } catch (res) {
    const error: any = res;

    if (error.response && error.response.data && error.response.data.errors) {
      return {
        response: null,
        errors: error.response.data.errors,
      };
    } else {
      return {
        response: null,
        errors: [],
      };
    }
  }
};

// Confirm test started to note start for assessment timer
export const startSJT = async (payload: any) => {
  try {
    const res = await instance.post(endpoints.sjt.startTest, payload);
    if (res.data.status === '1') {
      return {
        response: res.data,
        errors: null,
      };
    } else {
      return {
        response: null,
        errors: res.data.errors,
      };
    }
  } catch (res) {
    const error: any = res;

    if (error.response && error.response.data && error.response.data.errors) {
      return {
        response: null,
        errors: error.response.data.errors,
      };
    } else {
      return {
        response: null,
        errors: [],
      };
    }
  }
};

// Submit PQ answers on given page
export const submitRegularSJTAnswers = async (payload: any) => {
  try {
    const res = await instance.post(endpoints.sjt.setRegularAnswers, payload);
    if (res.data.status === '1') {
      return {
        response: res.data,
        errors: null,
      };
    } else {
      return {
        response: null,
        errors: res.data.errors,
      };
    }
  } catch (res) {
    const error: any = res;

    if (error.response && error.response.data && error.response.data.errors) {
      return {
        response: null,
        errors: error.response.data.errors,
      };
    } else {
      return {
        response: null,
        errors: [],
      };
    }
  }
};

// Submit PQ answers on given page
export const submitOneToFiveSJTAnswers = async (payload: any) => {
  try {
    const res = await instance.post(endpoints.sjt.setOneToFiveAnswers, payload);
    if (res.data.status === '1') {
      return {
        response: res.data,
        errors: null,
      };
    } else {
      return {
        response: null,
        errors: res.data.errors,
      };
    }
  } catch (res) {
    const error: any = res;

    if (error.response && error.response.data && error.response.data.errors) {
      return {
        response: null,
        errors: error.response.data.errors,
      };
    } else {
      return {
        response: null,
        errors: [],
      };
    }
  }
};

// Fully submit SJT once all questions are completed and end the assessment
export const completeSJT = async (payload: any) => {
  try {
    const res = await instance.post(endpoints.sjt.completeTest, payload);
    if (res.data.status === '1') {
      return {
        response: res.data,
        errors: null,
      };
    } else {
      return {
        response: null,
        errors: res.data.errors,
      };
    }
  } catch (res) {
    const error: any = res;

    if (error.response && error.response.data && error.response.data.errors) {
      return {
        response: null,
        errors: error.response.data.errors,
      };
    } else {
      return {
        response: null,
        errors: [],
      };
    }
  }
};

// Get list of reasons for requesting an assessment reset
export const getResetReasons = async () => {
  try {
    const res = await instance.get(endpoints.sjt.getResetReasons);
    if (res.data.status === '1') {
      return {
        response: res.data,
        errors: null,
      };
    } else {
      return {
        response: null,
        errors: res.data.errors,
      };
    }
  } catch (res) {
    const error: any = res;

    if (error.response && error.response.data && error.response.data.errors) {
      return {
        response: null,
        errors: error.response.data.errors,
      };
    } else {
      return {
        response: null,
        errors: [],
      };
    }
  }
};

// Submit a request for an assessment reset
export const requestReset = async (payload: any) => {
  try {
    const res = await instance.post(endpoints.sjt.requestReset, payload);
    if (res.data.status === '1') {
      return {
        response: res.data,
        errors: null,
      };
    } else {
      return {
        response: null,
        errors: res.data.errors,
      };
    }
  } catch (res) {
    const error: any = res;

    if (error.response && error.response.data && error.response.data.errors) {
      return {
        response: null,
        errors: error.response.data.errors,
      };
    } else {
      return {
        response: null,
        errors: [],
      };
    }
  }
};

// Get list of reasons for requesting a reasonable adjustment
export const getReasonableAdjustmentReasons = async () => {
  try {
    const res = await instance.get(endpoints.sjt.getResonableAdjustmentReasons);
    if (res.data.status === '1') {
      return {
        response: res.data,
        errors: null,
      };
    } else {
      return {
        response: null,
        errors: res.data.errors,
      };
    }
  } catch (res) {
    const error: any = res;

    if (error.response && error.response.data && error.response.data.errors) {
      return {
        response: null,
        errors: error.response.data.errors,
      };
    } else {
      return {
        response: null,
        errors: [],
      };
    }
  }
};

// Submit a request for a reasonable adjustment
export const requestReasonableAdjustment = async (payload: any) => {
  try {
    const res = await instance.post(
      endpoints.sjt.requestResonableAdjustment,
      payload
    );
    if (res.data.status === '1') {
      return {
        response: res.data,
        errors: null,
      };
    } else {
      return {
        response: null,
        errors: res.data.errors,
      };
    }
  } catch (res) {
    const error: any = res;

    if (error.response && error.response.data && error.response.data.errors) {
      return {
        response: null,
        errors: error.response.data.errors,
      };
    } else {
      return {
        response: null,
        errors: [],
      };
    }
  }
};
