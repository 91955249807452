/** @jsxImportSource @emotion/react */
import { Link, navigate, Redirect } from '@reach/router';
import { paths } from 'appConstants';
import { ThreeBounce } from 'better-react-spinkit';
import { Button } from 'components/button/Button';
import { CheckboxInput } from 'components/formFields/CheckboxInput';
import { TextInput } from 'components/formFields/TextInput';
import { FieldAttributes, Form, Formik, FormikProps, useField } from 'formik';
import { storeInLocalStorage } from 'helpers/cache';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearAuthMessage,
  selectApplicationLinkStarted,
  selectApplicationLinkToken,
  selectAuthMessage,
  selectAuthStatus,
  selectHasApplications,
  selectInvitedBy,
  setApplicationLinkStarted,
  signIn,
  SignInAttempt,
  SignInError,
} from 'reducers/auth';
import { setLinkedInState } from 'reducers/linkedInSignIn';
import * as Yup from 'yup';
import { redirectToLinkedIn } from './SignInApi';
import LinkedInIcon from '../../../assets/apply.clevry/Icon_Social_00.png';
import { decryptApplicationLink } from '../signUp/signUpApi';
import { applyForJob } from '../jobBoard/JobBoardApi';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';
import { handleProjectToken } from 'components/projectToken/projectToken';
import {
  selectSignInRedirectURL,
  setCloseLandingPageForm,
} from 'reducers/landingPage';
import {
  selectApplicationType,
  selectProjectId,
} from 'reducers/virtualAssessments';

// function RememberMeInput<T>(props: FieldAttributes<T>) {
//   const [field] = useField(props);
//   const { className, ...rest } = props;

//   return <input {...(field as any)} {...rest} className={className} />;
// }

type SignInFormProps = {
  switchToSignUp: () => void;
  inviteType?: string | null;
};

const SignInForm: React.FC<SignInFormProps> = ({ switchToSignUp }) => {
  const { t } = useTranslation('Sign+In+Form', { i18n });

  const formikRef = useRef<FormikProps<SignInAttempt>>(null);

  const dispatch = useDispatch();
  const authStatus = useSelector(selectAuthStatus);
  const signInErrors = useSelector(selectAuthMessage);
  const invitedById = useSelector(selectInvitedBy);
  const applicationLinkToken = useSelector(selectApplicationLinkToken);
  const applicationLinkStarted = useSelector(selectApplicationLinkStarted);
  const hasApplications = useSelector(selectHasApplications);
  const applicationType = useSelector(selectApplicationType);
  const projectID = useSelector(selectProjectId);
  const landingRedirectURL = useSelector(selectSignInRedirectURL);

  const closeLandingPageForm = () => dispatch(setCloseLandingPageForm());

  useEffect(() => {
    dispatch(clearAuthMessage());
  }, [dispatch]);

  useEffect(() => {
    if (
      formikRef.current &&
      signInErrors &&
      Array.isArray(signInErrors) &&
      signInErrors.length > 0
    ) {
      signInErrors.forEach((errorItem: SignInError) => {
        const parsedFieldName =
          errorItem.fieldName[0].toLowerCase() +
          (errorItem.fieldName.length > 1 ? errorItem.fieldName.slice(1) : '');

        const translatedMessage = errorMessages[
          errorItem.messageCode as keyof typeof errorMessages
        ]
          ? errorMessages[errorItem.messageCode as keyof typeof errorMessages]
          : errorItem.messageCode;

        formikRef.current?.setFieldError(parsedFieldName, translatedMessage);
      });
    }
  }, [signInErrors]);

  useEffect(() => {
    handleProjectToken(
      authStatus,
      landingRedirectURL,
      applicationLinkToken,
      applicationLinkStarted,
      setApplicationLinkStarted,
      dispatch,
      hasApplications,
      closeLandingPageForm, // prevent sign in form rendering again after submit
      applicationType,
      null,
      projectID,
      null,
      null,
      false
    );
  }, [authStatus, applicationLinkStarted, hasApplications]);

  const handleSubmit = (values: SignInAttempt) => {
    dispatch(
      signIn({
        ...values,
        inviteToken: invitedById ? invitedById : undefined,
      })
    );
  };
  const initialValues: SignInAttempt = {
    username: '',
    password: '',
    rememberMe: false,
  };

  const validationSchema = Yup.object({
    username: Yup.string()
      .required(t('SSCandidate_Sign_In_Form_Validation_Email_Address_Required'))
      .email(t('SSCandidate_Sign_In_Form_Validation_Email_Address_Valid')),
    password: Yup.string().required(
      t('SSCandidate_Sign_In_Form_Validation_Password')
    ),
    rememberMe: Yup.boolean(),
  });

  const makeId = (length: number): string => {
    let result = '';
    let characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const handleLinkedIn = async () => {
    const result = await redirectToLinkedIn({
      scope: ['r_liteprofile', 'r_emailaddress'],
    });
    const state = makeId(15);
    const currentURL = new URL(window.location.href);
    const protocol = currentURL.protocol;
    const host = currentURL.host;
    const redirectURL = `${protocol}//${host}/candidate-linkedin-login/callback`;
    storeInLocalStorage(state, 'linkedInSignIn');
    storeInLocalStorage(redirectURL, 'redirectURL');

    navigate(
      `${result.response.data}&state=${state}&redirect_uri=${redirectURL}`
    );
  };

  const errorMessages = {
    required: t('SSCandidate_Sign_In_Form_Errors_Required'),
    generic: t('SSCandidate_Sign_In_Form_Errors_Generic'),
    applicationError: t('SSCandidate_Sign_In_Form_Errors_Application_Error'),
    Account_In_Use: t('SSCandidate_Sign_In_Form_Errors_Account_In_Use'),
    Invalid_Password_Length: t(
      'SSCandidate_Sign_In_Form_Errors_Invalid_Password_Length'
    ),
    Password_Policy_No_Match: t(
      'SSCandidate_Sign_In_Form_Errors_Password_Policy_No_Match'
    ),
    Account_Locked: t('SSCandidate_Sign_In_Form_Errors_Account_Locked'),
    Username_Or_Password_Incorrect: t(
      'SSCandidate_Sign_In_Form_Errors_Username_Or_Password_Incorrect'
    ),
    EmailAddress_In_Use: t(
      'SSCandidate_Sign_In_Form_Errors_Email_Address_In_Use'
    ),
  };

  return (
    <div className="LoginTable">
      <div className="LoginCell SignInCell">
        <h2 className="FormTitle">
          {t('SSCandidate_Sign_In_Form_Sign_In_Sign_In_Panel_Title')}
        </h2>
        <div className="LoginForm">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            innerRef={formikRef}
          >
            {(props) => (
              <Form>
                <div className="FormBox">
                  <TextInput
                    autoComplete="email"
                    className="EditBox"
                    name="username"
                    label={t('SSCandidate_Sign_In_Form_Label_Email_Address')}
                  />
                </div>
                <div className="FormBox">
                  <TextInput
                    autoComplete="current-password"
                    className="EditBox"
                    name="password"
                    type="password"
                    label={t('SSCandidate_Sign_In_Form_Label_Password')}
                    isPassword={true}
                  />
                </div>
                <div className="SignInConfig">
                  <div>
                    <CheckboxInput
                      label={t('SSCandidate_Sign_In_Form_Label_Remember_Me')}
                      name="rememberMe"
                      id="rememberMe"
                      className="RememberMe"
                      isBordered={false}
                    />
                  </div>
                  <Link to="/forgotten-password">
                    <span className="ForgottenPassword">
                      {t('SSCandidate_Sign_In_Form_Forgotten_Password')}
                    </span>
                  </Link>
                </div>
                {/* <div>
                  <p className="SignInErrorMessage">
                    {message &&
                    errorMessages[message as keyof typeof errorMessages]
                      ? errorMessages[message as keyof typeof errorMessages]
                      : message}
                  </p>
                </div> */}
                <Button
                  priority="primary"
                  type="submit"
                  disabled={authStatus === 'pending'}
                  className="Btn"
                  isPublic
                >
                  {authStatus === 'pending' ? (
                    <ThreeBounce color="white" size={15} />
                  ) : (
                    t('SSCandidate_Sign_In_Form_Button_Sign_In')
                  )}
                </Button>
              </Form>
            )}
          </Formik>
          <div className="LoginFormDivider">
            <div className="LoginFormDivider__line"></div>
            <div className="LoginFormDivider__text">Or</div>
            <div className="LoginFormDivider__line"></div>
          </div>
          <div className="LinkedInSignInContainer">
            <img
              className="LinkedInSignInContainer__icon"
              src={LinkedInIcon}
              alt={t('SSCandidate_Sign_In_Form_Alt_Linked_In_Sign_In')}
            />
            <Button
              onClick={handleLinkedIn}
              priority="primary"
              className="Btn"
              isPublic
            >
              {t('SSCandidate_Sign_In_Form_Sign_In_Panel_Linked_In')}
            </Button>
          </div>
          <div className="SignUpLinkContainer">
            <p>{t('SSCandidate_Sign_In_Form_Sign_In_Panel_No_Account')}</p>
            <button onClick={switchToSignUp} className="InlineLink">
              {t('SSCandidate_Sign_In_Form_Sign_In_Panel_Sign_Up')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export { SignInForm };
