import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import MatchingProcessRadioCard from '../../MatchingProcessRadioCard';
import MP_Skills_Situation_01 from 'assets/apply.clevry/MP_Skills_Situation_01.png';
import MP_Skills_Situation_02 from 'assets/apply.clevry/MP_Skills_Situation_02.png';
import MP_Skills_Situation_03 from 'assets/apply.clevry/MP_Skills_Situation_03.png';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import {
  getCurrentSituationDetails,
  parseCurrentSituationDetails,
  parseCurrentSituationPayload,
  submitCurrentSituationPayload,
} from '../../MatcingProcessApi';
import { Spinner } from 'components/spinner/Spinner';
import i18n from 'i18n/i18n';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  MatchingProcessState,
  selectMatchingProcess,
  setMatchingProcessDataSubmittedToggle,
  setMatchingProcessSubmitSuccess,
} from 'reducers/matchingProcess';
import { GAMatchingProcessLatest } from 'helpers/gaEvents';

type CurrentSituationPageProps = {
  outerSubmitForm: MutableRefObject<
    (() => Promise<void>) & (() => Promise<any>)
  >;
  setNextStepDisabled: Dispatch<SetStateAction<boolean>>;
  setGeneralError: Dispatch<SetStateAction<string | undefined>>;
};

type formikValues = {
  candidateStatusTypeId: string | null;
};

const CurrentSituationPage: React.FC<CurrentSituationPageProps> = ({
  outerSubmitForm,
  setNextStepDisabled,
  setGeneralError,
}) => {
  const latestLocation = useRef('');

  if (
    window &&
    window.location &&
    window.location.href !== latestLocation.current
  ) {
    latestLocation.current = window.location.href;
    GAMatchingProcessLatest(window.location.href);
  }

  const { t } = useTranslation('Matching+Process', { i18n });

  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState<formikValues>({
    candidateStatusTypeId: null,
  });
  const [situationOptions, setSituationOptions] = useState<any>([]);

  const validationSchema = Yup.object({
    candidateStatusTypeId: Yup.string().required(),
  });

  const dispatch = useDispatch();

  const matchingProcessDetails: MatchingProcessState = useSelector(
    selectMatchingProcess
  );

  const handleSubmit = (values: any) => {
    const payload = parseCurrentSituationPayload(values);

    return (async () => {
      const result = await submitCurrentSituationPayload(payload);
      if (result.response) {
        setGeneralError('');

        dispatch(setMatchingProcessSubmitSuccess(true));

        dispatch(
          setMatchingProcessDataSubmittedToggle(
            !matchingProcessDetails.dataSubmittedToggle
          )
        );

        return true;
      } else {
        dispatch(setMatchingProcessSubmitSuccess(false));

        setGeneralError(t('SSCandidate_Matching_Process_General_Error'));
        return false;
      }
    })();
  };

  const fetchDetails = async () => {
    const result = await getCurrentSituationDetails();
    if (result.response) {
      const parsedCurrentSituationDetails = parseCurrentSituationDetails(
        result.response
      );

      if (parsedCurrentSituationDetails.candidateStatusTypeId) {
        setInitialValues({
          candidateStatusTypeId:
            parsedCurrentSituationDetails.candidateStatusTypeId.toString(),
        });
      }
      setSituationOptions(parsedCurrentSituationDetails.candidateStatusTypes);

      setLoading(false);
    } else {
      setLoading(false);
      setGeneralError(t('SSCandidate_Matching_Process_General_Error_Reload'));
    }
  };

  useEffect(() => {
    fetchDetails();
  }, []);

  if (loading) return <Spinner />;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
      validateOnMount
    >
      {({
        setFieldValue,
        values,
        errors,
        touched,
        setValues,
        submitForm,
        isValid,
      }) => {
        outerSubmitForm.current = submitForm;

        if (isValid) {
          setNextStepDisabled(false);
        } else {
          setNextStepDisabled(true);
        }

        return (
          <>
            <Form className="CurrentSituationPage MatchingProcessForm MatchingProcessInnerPage">
              <div className="FormContainer">
                <div className="Header">
                  <h1>
                    {t('SSCandidate_Matching_Process_Your_Situation_Heading')}
                  </h1>
                </div>

                <div className="CurrentSituationContainer">
                  <label>
                    <Field
                      type="radio"
                      name="candidateStatusTypeId"
                      value={situationOptions[0].id.toString()}
                      className="CardInputElement"
                    />
                    <MatchingProcessRadioCard
                      image={MP_Skills_Situation_01}
                      text={situationOptions[0].name}
                      // subtext={t(
                      //   'SSCandidate_Matching_Process_Your_Situation_DefaultSubText'
                      // )}
                    />
                  </label>

                  <label>
                    <Field
                      type="radio"
                      name="candidateStatusTypeId"
                      value={situationOptions[1].id.toString()}
                      className="CardInputElement"
                    />
                    <MatchingProcessRadioCard
                      image={MP_Skills_Situation_02}
                      text={situationOptions[1].name}
                      // subtext={t(
                      //   'SSCandidate_Matching_Process_Your_Situation_DefaultSubText'
                      // )}
                    />
                  </label>

                  <label>
                    <Field
                      type="radio"
                      name="candidateStatusTypeId"
                      value={situationOptions[2].id.toString()}
                      className="CardInputElement"
                    />
                    <MatchingProcessRadioCard
                      image={MP_Skills_Situation_03}
                      text={situationOptions[2].name}
                      // subtext={t(
                      //   'SSCandidate_Matching_Process_Your_Situation_DefaultSubText'
                      // )}
                    />
                  </label>
                </div>
              </div>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

export default CurrentSituationPage;
