import { Button } from 'components/button/Button';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { closeModal } from 'reducers/modal';
import CalculatorLogo from 'assets/apply.clevry/Icon_Guide_01.png';
import WatchLogo from 'assets/apply.clevry/Icon_Guide_02.png';
import PaperLogo from 'assets/apply.clevry/Icon_Guide_03.png';
import MobileLogo from 'assets/apply.clevry/Icon_Guide_04.png';
import DesktopLogo from 'assets/apply.clevry/Icon_Guide_05.png';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';

type IconGuideModalProps = {
  data: { title: string; modalClasses: string; modalHeaderClasses: string };
};

const IconGuideModal: React.FC<IconGuideModalProps> = ({ data }) => {
  const { t } = useTranslation('Icon+Guide+Modal', { i18n });

  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  return (
    <div className="IconGuideModal FullWidth">
      <div className="Content">
        <div className="Paragraph">
          {t('SSCandidate_Icon_Guide_Modal_Intro')}
        </div>
        <div className="Paragraph">
          <img
            src={CalculatorLogo}
            alt={t('SSCandidate_Icon_Guide_Modal_Calculator_Icon')}
          />
          <div className="IconGuideText">
            <span className="IconGuideLabel">
              {t('SSCandidate_Icon_Guide_Modal_Calculator_Icon')}
            </span>{' '}
            - {t('SSCandidate_Icon_Guide_Modal_Calculator')}
          </div>
        </div>
        <div className="Paragraph">
          <img
            src={WatchLogo}
            alt={t('SSCandidate_Icon_Guide_Modal_Time_Limit_Icon')}
          />
          <div className="IconGuideText">
            <span className="IconGuideLabel">
              {t('SSCandidate_Icon_Guide_Modal_Time_Limit_Icon')}
            </span>{' '}
            - {t('SSCandidate_Icon_Guide_Modal_Time_Limit')}
          </div>
        </div>
        <div className="Paragraph">
          <img
            src={MobileLogo}
            alt={t('SSCandidate_Icon_Guide_Modal_Phone_Icon')}
          />

          <div className="IconGuideText">
            <span className="IconGuideLabel">
              {t('SSCandidate_Icon_Guide_Modal_Phone_Icon')}
            </span>{' '}
            - {t('SSCandidate_Icon_Guide_Modal_Mobile')}
          </div>
        </div>
        <div className="Paragraph">
          <img
            src={DesktopLogo}
            alt={t('SSCandidate_Icon_Guide_Modal_Desktop_Icon')}
          />

          <div className="IconGuideText">
            <span className="IconGuideLabel">
              {t('SSCandidate_Icon_Guide_Modal_Desktop_Icon')}
            </span>{' '}
            - {t('SSCandidate_Icon_Guide_Modal_Desktop')}
          </div>
        </div>
      </div>
      <div className="ModalFooter">
        <Button priority="tertiary" type="button" onClick={handleCloseModal}>
          {t('SSCandidate_Icon_Guide_Modal_Cancel')}
        </Button>
        <Button priority="primary" type="submit" onClick={handleCloseModal}>
          {t('SSCandidate_Icon_Guide_Modal_Close')}
        </Button>
      </div>
    </div>
  );
};

export { IconGuideModal };
