import { FieldAttributes, FormikErrors, useField } from 'formik';
import React, { useEffect, useState } from 'react';
import 'react-phone-number-input/style.css';
import PhoneInput, {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from 'react-phone-number-input';
import { capitalizeFirstLetter } from 'helpers/capitalizeFirstLetter';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';
import { getUserLocale } from 'get-user-locale';
import { useOnClickOutside } from '@avamae/hooks/dist/hooks/useOnClickOutside';
import SearchIcon from 'assets/job-board-widget/Icon_Search.png';

const CountryOptions = ({ options, handleOnClick }: any) => {
  const [filteredOptions, setFilteredOptions] = useState<any>(options);

  const updateOptionList = (e: any) => {
    const { value } = e.target;
    console.log('value is ', value);

    const filteredOptionList = options.filter((item: any) => {
      return item.label.toLowerCase().includes(value.toLowerCase());
    });

    setFilteredOptions(filteredOptionList);
  };

  return (
    <>
      <div className="EditBox">
        <input
          onChange={updateOptionList}
          placeholder={'Search here'}
          type="text"
        />
        <img className="SearchIcon" src={SearchIcon} alt={'Alt'}></img>
      </div>
      <div className="CountryOptions">
        {filteredOptions &&
          Array.isArray(filteredOptions) &&
          filteredOptions.length > 0 &&
          filteredOptions.map((option: any) => {
            return (
              <div
                className="Option"
                onClick={() => {
                  if (option.value) {
                    handleOnClick(option.value);
                  }
                }}
              >
                {option.label && option.label}
              </div>
            );
          })}
      </div>
    </>
  );
};

const CustomCountryOptions = ({ props, ...helpers }: any) => {
  const [showOptions, setShowOptions] = useState<boolean>(false);

  const handleOnClick = (value: string) => {
    if (value && value.toLowerCase() !== 'international' && helpers.onChange) {
      setShowOptions(false);

      helpers.onChange(value);
    }
  };

  const bind = useOnClickOutside(() => setShowOptions(false), true);

  return (
    <div className="CustomCountryOptions">
      <div className="Icon" onClick={() => setShowOptions(true)}>
        <helpers.iconComponent
          country={helpers.value ? helpers.value : undefined}
        />
      </div>

      <div className={`Options ${!showOptions ? 'Disabled' : ''}`} {...bind}>
        {helpers.options && showOptions && (
          <CountryOptions
            options={helpers.options}
            handleOnClick={handleOnClick}
          />
        )}
      </div>
    </div>
  );
};

// useField field object type has values that aren't in HTMLInputField props.
// We create an intersection type so we can spread them on our <input /> element.
type FormikInputField = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> &
  FieldAttributes<string>;

type PhoneNumberFieldProps = {
  label: string;
  labelLocation?: 'top' | 'left';
  optional?: boolean;
  additionalClassName?: string;
  containerClass?: string;
  includeErrorText?: boolean;
  noTouch?: boolean;
  errors: FormikErrors<any>;
  setErrors: (errors: FormikErrors<any>) => void;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  values: any;
} & FieldAttributes<{}>;

// const PhoneNumberField: React.FC<PhoneNumberFieldProps> = ({ ...props }) => {
const PhoneNumberField: React.FC<PhoneNumberFieldProps> = ({
  label,
  labelLocation = 'top',
  optional = false,
  containerClass,
  includeErrorText = true,
  noTouch = false,
  errors,
  values,
  setErrors,
  setFieldValue,
  ...props
}) => {
  const [field, { error, touched }] = useField(props);
  const errorText = error && (touched || noTouch) ? error : '';
  const [isFocused, setIsFocused] = useState(false);

  // `value` will be the parsed phone number in E.164 format.
  // Example: "+12133734253".
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>();

  const containerClassName = `TextInputContainer Label${capitalizeFirstLetter(
    labelLocation
  )} ${containerClass} ${!includeErrorText ? 'NoErrorText' : ''}`;

  const labelStyle = `Label ${isFocused ? 'Focused' : ''} ${
    errorText && !isFocused ? 'Error' : ''
  }`;

  const { t } = useTranslation('Text+Input', { i18n });

  const updateErrors = (newError: string | undefined) => {
    let tempErrors = { ...errors };
    tempErrors[props.name] = newError;

    if (!newError && tempErrors.hasOwnProperty(props.name)) {
      delete tempErrors[props.name];
    }

    setErrors(tempErrors);
  };

  useEffect(() => {
    if (
      values &&
      typeof values === 'object' &&
      values.hasOwnProperty(props.name)
    ) {
      setPhoneNumber(values[props.name]);
    }
  }, []);

  useEffect(() => {
    if (phoneNumber && props.name) {
      setFieldValue(props.name, phoneNumber);
    }
  }, [phoneNumber]);

  return (
    <div
      className={`PhoneNumberField ${containerClassName} ${
        props.additionalClassName ? props.additionalClassName : ''
      }`}
    >
      <label className={labelStyle} htmlFor={field.name}>
        {label}{' '}
        {optional ? (
          <span className="OptionalLabel">
            - {t('SSCandidate_Text_Input_Optional')}
          </span>
        ) : null}
      </label>

      <PhoneInput
        // {...props}
        {...field}
        defaultCountry="GB"
        // displayInitialValueAsLocalNumber={true} // Doesn't seem to work
        placeholder="Enter phone number"
        value={phoneNumber}
        onChange={setPhoneNumber}
        error={
          phoneNumber
            ? // ? isValidPhoneNumber(phoneNumber)
              isPossiblePhoneNumber(phoneNumber)
              ? updateErrors(undefined)
              : updateErrors('Invalid phone number')
            : updateErrors('Phone number required')
        }
        international
        countrySelectComponent={CustomCountryOptions}
        // internationalIcon={} // You can add a custom international number icon using this
        // countrySelectProps={{ style: { height: '50px', maxHeight: '50px' } }}
      />

      {errors && errors.hasOwnProperty(props.name) && touched && (
        <div className="Error">{errors[props.name]}</div>
      )}
    </div>
  );
};

export default PhoneNumberField;
