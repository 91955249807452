import React from 'react';
import { ThreeBounce } from 'better-react-spinkit';

interface SpinnerProps {
  size?: number;
  color?: string;
  scaleStart?: number;
  scaleEnd?: number;
  positionAbsolute?: boolean;
  height?: string;
}

const Spinner: React.FC<SpinnerProps> = ({
  size = 15,
  color = '#036C8F',
  height,
}) => {
  return (
    <div className="Spinner" style={{ height }}>
      <ThreeBounce size={size} color={color} />
    </div>
  );
};

export { Spinner };
