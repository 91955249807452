import instance, { ApiResponse } from 'api';
import { endpoints } from 'config';
import {
  AbilityPracticeTestQuiz,
  AbilityPTPostAnswers
} from './models';

class AbilityPracticeTestApiModel {
  getQuestion(language_id: number){
    return instance.get<ApiResponse<AbilityPracticeTestQuiz>>(endpoints.abilityPracticeTest.getQuestions, {
      params: { languageId: language_id }
    });
  };

  fetchTestResults(language_id: number, ability_test_id: number, question_type_answers: AbilityPTPostAnswers[]){
    return instance.post<ApiResponse>(endpoints.abilityPracticeTest.testResults, {
      languageId: language_id,
      id: ability_test_id,
      questionTypeAnswers: question_type_answers,
    });
  }
}

export const AbilityPracticeTestApi = new AbilityPracticeTestApiModel();
