import { RouteComponentProps } from '@reach/router';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Spinner } from 'components/spinner/Spinner';
import ExplorePageCard from '../ExplorePageCard';
import IF_MyStrengths_Small from 'assets/apply.clevry/IF_MyStrengths_Small.jpg';
import IF_MyDevelopment_Small from 'assets/apply.clevry/IF_MyDevelopment_Small.jpg';
import IF_MyCapabilities_Small from 'assets/apply.clevry/IF_MyCapabilities_Small.jpg';
import IF_MyTeamStrengths_Small from 'assets/apply.clevry/IF_MyTeamStrengths_Small.jpg';
import IF_MyLeadershipStrengths_Small from 'assets/apply.clevry/IF_MyLeadershipStrengths_Small.jpg';
import IF_MySalesStyle_Small from 'assets/apply.clevry/IF_MySalesStyle_Small.jpg';
import { useWindowDimensions } from 'helpers/useGetWindowDimensions';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';
import { images, StringObjects } from 'config';

type ExplorePageProps = {
  pageList: string[];
  setCurrentPageNumber: Dispatch<SetStateAction<number>>;
  setCurrentPage: Dispatch<SetStateAction<string | null>>;
  strengthsData: any;
  developmentData: any;
  capabilitiesData: any;
  teamStrengthsData: any;
  leadershipStrengthsData: any;
  salesStyleData: any;
};

const ExplorePage: React.FC<ExplorePageProps> = ({
  pageList,
  setCurrentPage,
  setCurrentPageNumber,
  strengthsData,
  developmentData,
  capabilitiesData,
  teamStrengthsData,
  leadershipStrengthsData,
  salesStyleData,
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const [strengthsTileImage, setStrengthsTileImage] = useState<string | null>(
    null
  );
  const [developmentTileImage, setDevelopmentTileImage] = useState<
    string | null
  >(null);
  const [capabilitiesTileImage, setCapabilitiesTileImage] = useState<
    string | null
  >(null);
  const [teamStrengthsTileImage, setTeamStrengthsTileImage] = useState<
    string | null
  >(null);
  const [leadershipStrengthsTileImage, setLeadershipStrengthsTileImage] =
    useState<string | null>(null);
  const [salesStyleImage, setSalesStyleTileImage] = useState<string | null>(
    null
  );

  const { t } = useTranslation('Interactive+Feedback', { i18n });
  const { width } = useWindowDimensions();

  // To expand the pool of images randomly pulled from for each tile background, add the image under the corresponding images[key] in config.ts (after adding to the images repo)
  // Keep the new object key name to the format "tile_X", with "X" being the latest image number (ensure it matches the name + path on the images repo)
  useEffect(() => {
    setStrengthsTileImage(pullTileImageFromPool('myStrengthsReportTiles'));
    setDevelopmentTileImage(pullTileImageFromPool('myDevelopmentReportTiles'));
    setCapabilitiesTileImage(
      pullTileImageFromPool('myCapabilitiesReportTiles')
    );
    setTeamStrengthsTileImage(
      pullTileImageFromPool('myTeamStrengthsReportTiles')
    );
    setLeadershipStrengthsTileImage(
      pullTileImageFromPool('myLeadershipStrengthsReportTiles')
    );
    setSalesStyleTileImage(
      pullTileImageFromPool('mySalesStrengthsReportTiles')
    );
  }, []);

  const pullTileImageFromPool = (field: string) => {
    let imageKey = field as keyof typeof images;
    const tilePoolObjectSize = Object.keys(images[imageKey])?.length;

    const randomisedPoolIndex = Math.floor(
      Math.random() * tilePoolObjectSize + 1
    );

    const pulledTileImage =
      images[imageKey][
        `tile_${randomisedPoolIndex < 10 ? '0' : ''}${randomisedPoolIndex}`
      ] ?? null;

    return pulledTileImage;
  };

  if (loading) return <Spinner />;

  return (
    <>
      <div className="InteractiveFeedback ExplorePage">
        <div className="InteractiveFeedback__sectionHeader">
          <div className="InteractiveFeedback__sectionHeading">
            {width > 768
              ? t('SSCandidate_Interactive_Feedback_Expore_Unique')
              : t('SSCandidate_Interactive_Feedback_What_Unique')}
          </div>
          <div className="InteractiveFeedback__sectionSubheading">
            {width > 768
              ? t('SSCandidate_Interactive_Feedback_Discover_Insights')
              : t('SSCandidate_Interactive_Feedback_Explore_Strengths')}
          </div>
        </div>

        <div className="MainContent">
          <ExplorePageCard
            title={t('SSCandidate_Interactive_Feedback_My_Strengths')}
            text={t('SSCandidate_Interactive_Feedback_My_Strengths_Card_Text')}
            backgroundImage={strengthsTileImage ?? IF_MyStrengths_Small}
            pageList={pageList}
            setCurrentPageNumber={setCurrentPageNumber}
            setCurrentPage={setCurrentPage}
            isData={strengthsData ? true : false}
          />
          <ExplorePageCard
            title={t('SSCandidate_Interactive_Feedback_My_Development')}
            text={t(
              'SSCandidate_Interactive_Feedback_My_Development_Card_Text'
            )}
            backgroundImage={developmentTileImage ?? IF_MyDevelopment_Small}
            pageList={pageList}
            setCurrentPageNumber={setCurrentPageNumber}
            setCurrentPage={setCurrentPage}
            isData={developmentData ? true : false}
          />
          <ExplorePageCard
            title={t('SSCandidate_Interactive_Feedback_My_Capabilities')}
            text={t(
              'SSCandidate_Interactive_Feedback_My_Capabilities_Card_Text'
            )}
            backgroundImage={capabilitiesTileImage ?? IF_MyCapabilities_Small}
            pageList={pageList}
            setCurrentPageNumber={setCurrentPageNumber}
            setCurrentPage={setCurrentPage}
            isData={capabilitiesData ? true : false}
          />
          <ExplorePageCard
            title={t('SSCandidate_Interactive_Feedback_My_Team_Strengths')}
            text={t(
              'SSCandidate_Interactive_Feedback_My_Team_Strengths_Card_Text'
            )}
            backgroundImage={teamStrengthsTileImage ?? IF_MyTeamStrengths_Small}
            pageList={pageList}
            setCurrentPageNumber={setCurrentPageNumber}
            setCurrentPage={setCurrentPage}
            isData={teamStrengthsData ? true : false}
          />
          <ExplorePageCard
            title={t(
              'SSCandidate_Interactive_Feedback_My_Leadership_Strengths'
            )}
            text={t(
              'SSCandidate_Interactive_Feedback_My_Leadership_Strengths_Card_Text'
            )}
            backgroundImage={
              leadershipStrengthsTileImage ?? IF_MyLeadershipStrengths_Small
            }
            pageList={pageList}
            setCurrentPageNumber={setCurrentPageNumber}
            setCurrentPage={setCurrentPage}
            isData={leadershipStrengthsData ? true : false}
          />
          <ExplorePageCard
            title={t('SSCandidate_Interactive_Feedback_My_Sales_Style')}
            text={t(
              'SSCandidate_Interactive_Feedback_My_Sales_Style_Card_Text'
            )}
            backgroundImage={salesStyleImage ?? IF_MySalesStyle_Small}
            pageList={pageList}
            setCurrentPageNumber={setCurrentPageNumber}
            setCurrentPage={setCurrentPage}
            isData={salesStyleData ? true : false}
          />
        </div>
      </div>
    </>
  );
};

export default ExplorePage;
