import { Field, FieldArray, Form, Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import i18n from 'i18n/i18n';
import { useTranslation } from 'react-i18next';

import Icon_SoftSkills_01 from 'assets/apply.clevry/Icon_SoftSkills_01.png';
import Icon_SoftSkills_02 from 'assets/apply.clevry/Icon_SoftSkills_02.png';
import Icon_SoftSkills_03 from 'assets/apply.clevry/Icon_SoftSkills_03.png';
import Icon_SoftSkills_04 from 'assets/apply.clevry/Icon_SoftSkills_04.png';
import Icon_SoftSkills_05 from 'assets/apply.clevry/Icon_SoftSkills_05.png';
import {
  getGlobalSoftSkillsDetails,
  getGlobalSoftSkillsOptions,
  parseGlobalSoftSkillsDetails,
  parseGlobalSoftSkillsOptions,
  parseGlobalSoftSkillsPayload,
  submitGlobalSoftSkillsPayload,
} from '../../MatcingProcessApi';
import { Spinner } from 'components/spinner/Spinner';
import {
  MatchingProcessState,
  selectMatchingProcess,
  setMatchingProcessDataSubmittedToggle,
  setMatchingProcessSubmitSuccess,
} from 'reducers/matchingProcess';
import { useDispatch, useSelector } from 'react-redux';
import { GAMatchingProcessLatest } from 'helpers/gaEvents';

type CardContent = {
  id: number;
  image?: string;
  // text: string;
  // subtext: string;
  name: string;
  description: string;
};

type Row = 'top' | 'bottom';

interface ISlot {
  row: Row;
  index: number;
}

type State = {
  top: (CardContent | null)[];
  bottom: (CardContent | null)[];
};

type GlobalSoftSkillsPageProps = {
  outerSubmitForm: MutableRefObject<
    (() => Promise<void>) & (() => Promise<any>)
  >;
  setNextStepDisabled: Dispatch<SetStateAction<boolean>>;
  setGeneralError: Dispatch<SetStateAction<string | undefined>>;
};

type formikValues = {
  globalSoftskills:
    | [
        {
          questionId: number;
          answerId: number | null;
        }
      ]
    | null;
};

const GlobalSoftSkillsPage: React.FC<GlobalSoftSkillsPageProps> = ({
  outerSubmitForm,
  setNextStepDisabled,
  setGeneralError,
}) => {
  const latestLocation = useRef('');

  if (
    window &&
    window.location &&
    window.location.href !== latestLocation.current
  ) {
    latestLocation.current = window.location.href;
    GAMatchingProcessLatest(window.location.href);
  }

  const { t } = useTranslation('Matching+Process', { i18n });

  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState<any>({
    globalSoftSkills: [],
  });
  const [globalSoftSkillsDetails, setGlobalSoftSkillsDetails] =
    useState<any>(null);
  const [globalSoftSkillsOptions, setGlobalSoftSkillsOptions] = useState<any>(
    []
  );
  const [currentlyActive, setCurrentlyActive] = useState<ISlot | null>(null);

  const [imageArrays, setImageArrays] = useState<State>({
    top: globalSoftSkillsOptions,
    bottom: new Array(5).fill(null),
  });

  const getSlotImage = (globalSoftSkillName: string | null) => {
    let slotImage = null;

    switch (globalSoftSkillName) {
      case 'Drive':
        slotImage = Icon_SoftSkills_01;
        break;
      case 'Relationships':
        slotImage = Icon_SoftSkills_02;
        break;
      case 'Communication':
        slotImage = Icon_SoftSkills_03;
        break;
      case 'Learning agility':
        slotImage = Icon_SoftSkills_04;
        break;
      case 'Emotional management':
        slotImage = Icon_SoftSkills_05;
        break;
      default:
        slotImage = null;
        break;
    }

    return slotImage;
  };

  const handleDragStart = (item: ISlot) => {
    setCurrentlyActive(item);
  };

  const swapArrayValues = (
    sourceRow: Row,
    targetRow: Row,
    sourceIndex: number,
    targetIndex: number
  ): State => {
    const clonedSourceArray = [...imageArrays[sourceRow]];
    const clonedTargetArray = [...imageArrays[targetRow]];
    const sourceItemCopy = clonedSourceArray[sourceIndex];
    clonedSourceArray[sourceIndex] = clonedTargetArray[targetIndex];
    clonedTargetArray[targetIndex] = sourceItemCopy;
    // Need to update target row if source and target rows are the same because
    // we are handling them as separate rows and they do not match.
    if (sourceRow === targetRow) {
      clonedTargetArray[sourceIndex] = clonedSourceArray[targetIndex];
    }
    // Then we add targetRow after sourceRow so targetRows will overwrite if
    // source and target rows are the same.
    return {
      ...imageArrays,
      [sourceRow]: clonedSourceArray,
      [targetRow]: clonedTargetArray,
    };
  };

  const validationSchema = Yup.object({
    globalSoftSkills: Yup.array().min(5).required(),
  });

  const dispatch = useDispatch();

  const matchingProcessDetails: MatchingProcessState = useSelector(
    selectMatchingProcess
  );

  const handleSubmit = (values: any) => {
    const payload = parseGlobalSoftSkillsPayload(values);
    return (async () => {
      const result = await submitGlobalSoftSkillsPayload(payload);
      if (result.response) {
        setGeneralError('');

        dispatch(setMatchingProcessSubmitSuccess(true));

        dispatch(
          setMatchingProcessDataSubmittedToggle(
            !matchingProcessDetails.dataSubmittedToggle
          )
        );

        return true;
      } else {
        dispatch(setMatchingProcessSubmitSuccess(false));

        setGeneralError('Something went wrong, please try again.');
        return false;
      }
    })();
  };

  const fetchDetails = async () => {
    const globalSoftSkillsDetails = await getGlobalSoftSkillsDetails();
    const globalSoftSkillsOptions = await getGlobalSoftSkillsOptions();

    if (!globalSoftSkillsDetails.response && globalSoftSkillsOptions.response) {
      const parsedGlobalSoftSkillsOptions = parseGlobalSoftSkillsOptions(
        globalSoftSkillsOptions.response
      );
      setImageArrays({
        ...imageArrays,
        top: parsedGlobalSoftSkillsOptions,
      });
      setLoading(false);
    }

    if (globalSoftSkillsDetails.response) {
      const parsedGlobalSoftSkillsDetails = parseGlobalSoftSkillsDetails(
        globalSoftSkillsDetails.response
      );
      setImageArrays({
        ...imageArrays,
        top: [null, null, null, null, null],
        bottom: parsedGlobalSoftSkillsDetails?.forDisplay,
      });
      setInitialValues({
        globalSoftSkills: parsedGlobalSoftSkillsDetails?.forForm,
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDetails();
  }, []);

  if (loading) return <Spinner />;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
      validateOnMount
    >
      {({
        setFieldValue,
        values,
        errors,
        touched,
        setValues,
        submitForm,
        isValid,
      }) => {
        const handleDrop = (item: ISlot) => {
          const questionIDs = [1000, 1001, 1002, 1003, 1004];
          setFieldValue('globalSoftSkills', null);
          if (currentlyActive) {
            setCurrentlyActive(null);
            const newState = swapArrayValues(
              currentlyActive.row,
              item.row,
              currentlyActive.index,
              item.index
            );
            const newValues: any = [];
            newState.bottom
              .filter((slot) => slot)
              .forEach((slot, idx) => {
                newValues.push({
                  questionId: questionIDs[idx],
                  answerId: slot?.id,
                });
              });
            setFieldValue('globalSoftSkills', newValues);

            setImageArrays(newState);
          }
        };

        outerSubmitForm.current = submitForm;

        if (isValid) {
          setNextStepDisabled(false);
        } else {
          setNextStepDisabled(true);
        }

        return (
          <>
            <Form className="GlobalSoftSkillsPage MatchingProcessInnerPage">
              <div className="FormContainer">
                <div className="MatchingProcess__sectionHeader">
                  <div className="MatchingProcess__sectionHeading">
                    {t('SSCandidate_Matching_Process_Soft_Skills_Heading')}
                  </div>
                  <div className="MatchingProcess__sectionSubheading">
                    {t('SSCandidate_Matching_Process_Soft_Skills_SubHeading1')}
                  </div>
                </div>

                <div className="GlobalSoftSkillsContainer">
                  <div className="Grid Upper">
                    {imageArrays.top.map((item, index) => (
                      <React.Fragment key={item ? item.id : index}>
                        <Slot
                          row="top"
                          index={index}
                          image={getSlotImage(item && item.name)}
                          text={item && item.name}
                          subtext={item && item.description}
                          handleDragStart={handleDragStart}
                          handleDrop={handleDrop}
                          isBeingMoved={
                            currentlyActive?.row === 'top' &&
                            currentlyActive?.index === index
                          }
                          moveHasStarted={!!currentlyActive}
                        />
                      </React.Fragment>
                    ))}
                  </div>

                  <div className="Grid Lower">
                    {imageArrays.bottom.map((item, index) => (
                      <React.Fragment key={item ? item.id : index}>
                        <Slot
                          row="bottom"
                          index={index}
                          image={getSlotImage(item && item.name)}
                          text={item && item.name}
                          subtext={item && item.description}
                          handleDragStart={handleDragStart}
                          handleDrop={handleDrop}
                          isBeingMoved={
                            currentlyActive?.row === 'bottom' &&
                            currentlyActive?.index === index
                          }
                          moveHasStarted={!!currentlyActive}
                          isMostImportant={index === 0}
                          isLeastImportant={
                            index === imageArrays.bottom.length - 1
                          }
                        />
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              </div>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

type SlotProps = {
  row: Row;
  index: number;
  image: string | null;
  text: string | null;
  subtext: string | null;
  handleDragStart: (item: ISlot) => void;
  handleDrop: (item: ISlot) => void;
  moveHasStarted: boolean;
  isBeingMoved: boolean;
  includeIndex?: boolean;
  isMostImportant?: boolean;
  isLeastImportant?: boolean;
};

const Slot: React.FC<SlotProps> = ({
  row,
  index,
  image,
  text,
  subtext,
  handleDragStart,
  handleDrop,
  moveHasStarted,
  isBeingMoved,
  isMostImportant,
  isLeastImportant,
}) => {
  const { t } = useTranslation('Matching+Process', { i18n });

  let timeout: number;

  const handleTouchStart = (item: ISlot) => {
    timeout = window.setTimeout(() => {
      if (moveHasStarted) {
        handleDrop(item);
      } else {
        handleDragStart(item);
      }
    }, 100);
  };

  const handleTouchMove = () => {
    if (timeout) {
      window.clearTimeout(timeout);
      timeout = 0;
    }
  };

  const getPositionSuffix = (index: number) => {
    let positionEnding = '';

    switch (index + 1) {
      case 1:
        positionEnding = 'st';
        break;
      case 2:
        positionEnding = 'nd';
        break;
      case 3:
        positionEnding = 'rd';
        break;
      case 4:
      case 5:
        positionEnding = 'th';
        break;
      default:
        positionEnding = '';
        break;
    }

    return positionEnding;
  };

  return (
    <div className="GridItem">
      <div
        className={`GridSlot ${text ? '' : 'Empty'} ${
          isBeingMoved ? 'Active' : ''
        } ${row}`}
        draggable={text ? true : false}
        onDragStart={() => handleDragStart({ row, index })}
        onDragOver={(e) => e.preventDefault()}
        onTouchStart={() => handleTouchStart({ row, index })}
        onTouchMove={handleTouchMove}
        onDrop={() => handleDrop({ row, index })}
      >
        {text && (
          <div
            className=""
            // draggable={true}
            // onDragStart={() => handleDragStart({ row, index })}
            onTouchStart={() => handleTouchStart({ row, index })}
            onTouchMove={handleTouchMove}
            onDrop={() => handleDrop({ row, index })}
          >
            <div className="GridImage">
              <img src={image ? image : ''} alt="" />
            </div>
            <div>
              <h3>{text}</h3>
              <p>{subtext}</p>
            </div>
          </div>
        )}

        {row === 'bottom' && !text && (
          <>
            <h4>
              {index + 1}
              {getPositionSuffix(index)}{' '}
              {t('SSCandidate_Matching_Process_Soft_Skills_Priority')}
            </h4>

            {isMostImportant ? (
              <p className="ImportanceLabel">
                {t('SSCandidate_Matching_Process_Soft_Skills_Strongest')}
              </p>
            ) : isLeastImportant ? (
              <p className="ImportanceLabel">
                {t('SSCandidate_Matching_Process_Soft_Skills_Weakest')}
              </p>
            ) : null}
          </>
        )}
      </div>
    </div>
  );
};

export default GlobalSoftSkillsPage;
