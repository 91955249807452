import { navigate, RouteComponentProps } from '@reach/router';
import { AppPath } from 'appConstants';
import * as Yup from 'yup';
import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { Form, Formik } from 'formik';
import { Button } from 'components/button/Button';
import { TextInput } from 'components/formFields/TextInput';
import { PasswordStrengthMeter } from 'components/formFields/PasswordStrengthMeter';
import {
  selectAuthStatus,
  setCampaignSignUpSuccessful,
  setInviteType,
  signIn,
  SignInAttempt,
  signOut,
} from 'reducers/auth';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from '../../../components/spinner/Spinner';
import { AccountDetails } from '../candidateSignUp/canidateSignUpLanding';
import { getCandidateDetailsApi } from '../candidateSignUp/getCandidateDetailsApi';
import { registerCandidateApi } from './registerCandidateApi';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';
import { GARedirectSignUp } from 'helpers/gaEvents';
import { ExistingUserDetails, RegisterCandidatePayload } from './models';
import { signInForRedirect } from '../signIn/SignInApi';
import { saveTokenResource } from '../../../helpers/auth';
import { addSeconds } from 'date-fns';
import { getPQResults } from '../learnYourStrengths/learnYourStrengthsApi';
import { BaseApi } from 'baseApi';
import { addHubspotTracking } from 'helpers/addHubspotTracking';

interface CandidateSignUpForm extends RouteComponentProps {
  campaignAssessmentId: number;
}

const CanidateSignUpForm: React.FC<CandidateSignUpForm> = ({
  campaignAssessmentId,
}) => {
  const [getCandidateDetails, setGetCandidateDetails] =
    useState<boolean>(false);
  const [newUser, setNewUser] = useState<boolean>(true);
  const [existingUserDetails, setExistingUserDetails] =
    useState<ExistingUserDetails | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadForm, setLoadForm] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<boolean>(false);
  const [errorCode, setErrorCode] = useState<any | null>(null);
  const [userDetails, setUserDetails] = useState<AccountDetails | null>(null);
  const [disableForm, setDisableForm] = useState<boolean>(false);
  const authStatus = useSelector(selectAuthStatus);

  const { t } = useTranslation(['Candidate+Sign+Up+Form', 'Errors'], { i18n });
  const dispatch = useDispatch();

  //get candidate login token and new e-mail flag
  const search = window.location ? window.location.search : '';
  const parsed = queryString.parse(search);
  const candidateLoginToken = parsed.token ? (parsed.token as string) : '';

  const assessmentId = parsed.assessmentID ? +parsed.assessmentID : null;

  const freshSignUp = parsed.currentemail
    ? (parsed.currentemail as string)
    : '';
  const checkEmail = freshSignUp === 'false' ? false : true;

  useEffect(() => {
    const script = addHubspotTracking();

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // Check is user is a new user or has an account on 1 of the 3 platforms:
  useEffect(() => {
    if (
      userDetails?.isAssessor ||
      userDetails?.isClientUser ||
      userDetails?.isJoyAtWorkCandidate === true
    ) {
      // console.log('Setting new user to false');
      // setNewUser(false);
      const numberofExistingAccounts = [
        userDetails?.isAssessor,
        userDetails?.isClientUser,
        userDetails?.isJoyAtWorkCandidate,
      ].filter((item) => item === true).length;
      // console.log('numberofExistingAccounts: ', numberofExistingAccounts);
      setExistingUserDetails({
        count: numberofExistingAccounts,
        isAssessor: userDetails?.isAssessor,
        isClientUser: userDetails?.isClientUser,
        isJoyAtWorkCandidate: userDetails?.isJoyAtWorkCandidate,
      });
    } else {
      // console.log('Setting new user to true');
      // setNewUser(true);
      setExistingUserDetails(null);
    }
  }, [userDetails]);

  //check url on whether to proceed and show the form
  useEffect(() => {
    //if no value, redirect

    if (campaignAssessmentId) {
      setUserDetails({
        firstName: '',
        lastName: '',
        emailAddress: '',
        isAssessor: false,
        isClientUser: false,
        isJoyAtWorkCandidate: false,
        newPassword: '',
      });

      setLoading(false);
    } else if (
      candidateLoginToken === null ||
      freshSignUp === null ||
      authStatus !== 'signed_in'
    ) {
      navigate(AppPath.NOT_FOUND);
    } else {
      //if not true or false, redirect
      if (freshSignUp !== 'false' && freshSignUp !== 'true') {
        navigate(AppPath.NOT_FOUND);
      } else {
        //update form with users details
        const updateForm = freshSignUp === 'false' ? false : true;
        setDisableForm(updateForm);

        if (updateForm) {
          setGetCandidateDetails(true);
        } else {
          setUserDetails({
            firstName: '',
            lastName: '',
            emailAddress: '',
            isAssessor: false,
            isClientUser: false,
            isJoyAtWorkCandidate: false,
            newPassword: '',
          });

          setLoading(false);
        }
      }
    }
  }, []);

  //get existing candidate details
  useEffect(() => {
    // if (getCandidateDetails) {
    if (getCandidateDetails && userDetails === null) {
      (async () => {
        const result = await getCandidateDetailsApi();

        if (result.response) {
          setUserDetails({
            firstName: result.response.firstName,
            lastName: result.response.lastName,
            emailAddress: result.response.emailAddress + ' ', //add space to stop formik auto filling password field
            isAssessor: result.response.isAssessor,
            isClientUser: result.response.isClientUser,
            isJoyAtWorkCandidate: result.response.isJoyAtWorkCandidate,
            newPassword: '',
          });
        }

        if (result.errors && result.errors.length > 0) {
          setErrorMessage(true);
        }

        setLoading(false);
      })();
    }
  }, [authStatus, getCandidateDetails]);

  //submit form
  const handleSubmit = (values: AccountDetails) => {
    const payload: RegisterCandidatePayload = {
      firstName: values.firstName,
      lastName: values.lastName,
      emailAddress: values.emailAddress,
      newPassword: values.newPassword!,
      assessmentId: campaignAssessmentId ? campaignAssessmentId : assessmentId,
    };

    makeSignupCall(payload);
  };

  const makeSignupCall = async (payload: RegisterCandidatePayload) => {
    //add loading
    setLoadForm(true);

    //remove added space from e-mail to stop formil auto filling password field
    if (checkEmail) {
      payload.emailAddress = payload.emailAddress.trim();
    }

    //sign up endpoint
    const result = await registerCandidateApi(payload);

    if (result.response) {
      // If signed up after viewing campaign interactive feedback: clear limited signed-in redux values
      if (campaignAssessmentId) {
        dispatch(setInviteType(''));
      }

      // Now we've signed up we need to generate new tokens by calling the login endpoint
      loginUser(payload);
    }

    if (result.errors && result.errors.length > 0) {
      setErrorCode(result.errors[0].messageCode ?? null);
      setLoadForm(false);
    }
  };

  const errors = {
    required: t('SSCandidate_Errors_Required', { ns: 'Errors' }),
    generic: t('SSCandidate_Errors_Generic', { ns: 'Errors' }),
    applicationError: t('SSCandidate_Errors_Application_Error', {
      ns: 'Errors',
    }),
    Account_In_Use: t('SSCandidate_Errors_Account_In_Use', { ns: 'Errors' }),
    // Invalid_Password_Length: 'Minimum password length is 8 characters and maximum is x',
    Invalid_Password_Length: t('SSCandidate_Errors_Invalid_Password_Length', {
      ns: 'Errors',
    }),
    Password_Policy_No_Match: t('SSCandidate_Errors_Password_Policy_No_Match', {
      ns: 'Errors',
    }),
    Account_Locked: t('SSCandidate_Errors_Account_Locked', { ns: 'Errors' }),
    Username_Or_Password_Incorrect: t(
      'SSCandidate_Errors_Username_Or_Password_Incorrect',
      { ns: 'Errors' }
    ),
    EmailAddress_In_Use: t('SSCandidate_Errors_Email_In_Use', { ns: 'Errors' }),
    Invalid_Email_Or_Password: t(
      'SSCandidate_Errors_Invalid_Email_Or_Password',
      { ns: 'Errors' }
    ),
  };

  const loginUser = async (payload: RegisterCandidatePayload) => {
    // Call auth login user
    const signInPayload: SignInAttempt = {
      username: payload.emailAddress,
      password: payload.newPassword,
      rememberMe: false,
    };
    const { response, error } = await signInForRedirect(signInPayload);

    // save response locally
    if (response && !error) {
      const { accessToken, refreshToken, expiresIn, scopes } = response;
      const refreshTokenExpires = addSeconds(
        new Date(),
        parseInt(expiresIn, 10)
      );
      saveTokenResource({
        accessToken,
        refreshToken,
        refreshTokenExpires,
        scopes: scopes ?? [],
      });

      // If the user has answered any PQs: redirect to my history
      if (campaignAssessmentId) {
        // Triggers call for feature flags endpoint so logged in nav bar displays items
        // Must happen after new access token is generated and saved to avoid 403 error from using limited access token
        dispatch(setCampaignSignUpSuccessful(true));
        //

        navigate(AppPath.HISTORIC_APPLICATIONS);
        return;
      }

      GARedirectSignUp();

      // If the user has answered any PQs: redirect to my soft skills
      // If not: redirect to dashboard
      getPQResults([])
        .then((response) => {
          if (BaseApi.isResponseSuccessful(response.data)) {
            if (
              response.data &&
              response.data.details &&
              response.data.details.categories &&
              response.data.details.categories.length >= 1
            ) {
              navigate(AppPath.MY_SOFT_SKILLS);
            } else {
              navigate(AppPath.MY_JOY);
            }
          } else {
            navigate(AppPath.MY_JOY);
          }
        })
        .catch(() => {
          navigate(AppPath.MY_JOY);
        });
    } else {
      console.log('error', error);
      if (typeof error === 'string') setErrorCode(error);
      else setErrorCode(errors.generic);
    }
  };

  const validationSchema = !checkEmail
    ? Yup.object({
        firstName: Yup.string().required(
          t(
            'SSCandidate_Candidate_Candidate_Sign_Up_Form_Validation_Errors_Required'
          )
        ),
        lastName: Yup.string().required(
          t(
            'SSCandidate_Candidate_Candidate_Sign_Up_Form_Validation_Errors_Required'
          )
        ),
        emailAddress: Yup.string()
          .required(
            t(
              'SSCandidate_Candidate_Candidate_Sign_Up_Form_Validation_Email_Address_Required'
            )
          )
          .email(
            t(
              'SSCandidate_Candidate_Candidate_Sign_Up_Form_Validation_Email_Address_Valid'
            )
          ),
        newPassword: Yup.string().required(
          t(
            'SSCandidate_Candidate_Candidate_Sign_Up_Form_Validation_Provide_Password'
          )
        ),
      })
    : Yup.object({
        newPassword: Yup.string().required(
          t(
            'SSCandidate_Candidate_Candidate_Sign_Up_Form_Validation_Provide_Password'
          )
        ),
      });

  const handleExistingUserSignIn = (values: {
    existingPassword: string;
    emailAddress: string;
  }) => {
    // console.log('EXISTING USER SUBMIT');

    const payload: RegisterCandidatePayload = {
      firstName: userDetails!.firstName,
      lastName: userDetails!.lastName,
      emailAddress: values.emailAddress,
      newPassword: values.existingPassword,
      assessmentId,
    };

    makeSignupCall(payload);
  };

  const existingUserValidationSchema = Yup.object({
    existingPassword: Yup.string().required(
      t('SSCandidate_Candidate_Candidate_Sign_Up_Form_Enter_Existing_Password')
    ),
  });

  const userExistsInitial = {
    existingPassword: '',
    emailAddress: userDetails?.emailAddress ?? '',
  };

  const parseErrorCode = (userExists: boolean, code: string) => {
    const message = errors[code as keyof typeof errors]
      ? errors[code as keyof typeof errors]
      : code;

    if (message === errors.Invalid_Email_Or_Password && userExists)
      return 'Incorrect password';
    else return message;
  };

  return (
    <div
      className={`CandidateSignUpForm ${campaignAssessmentId ? 'Popup' : ''}`}
    >
      <div className="SignUp WidthContent HeightContent">
        {loading ? (
          <div className="PageSpinner">
            <Spinner color="#067889" />
          </div>
        ) : errorMessage ? (
          <div className="MsgBlock">
            <h1>
              {t(
                'SSCandidate_Candidate_Candidate_Sign_Up_Form_Errors_Application_Error'
              )}
            </h1>
            <p>
              {t('SSCandidate_Candidate_Candidate_Sign_Up_Form_Errors_Generic')}
            </p>
            <Button
              priority="primary"
              isPublic
              onClick={() => navigate(AppPath.ROOT)}
            >
              {t(
                'SSCandidate_Candidate_Candidate_Sign_Up_Form_Misc_Go_To_Home_Page'
              )}
            </Button>
          </div>
        ) : (
          userDetails && (
            <div className="Content Body">
              <div className="FormCol">
                {loadForm && (
                  <div className="FormSpinner">
                    <Spinner color="#067889" />
                  </div>
                )}

                {existingUserDetails === null ? (
                  <div className="NewUserContainer">
                    <h1 className="Title">
                      {t('SSCandidate_Candidate_Sign_Up_Form_New_User_Title')}
                    </h1>
                    <div className="Description">
                      {t(
                        'SSCandidate_Candidate_Sign_Up_Form_New_User_Description'
                      )}
                    </div>
                    <div className="SignUpForm">
                      <Formik
                        initialValues={userDetails}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                      >
                        {(props) => (
                          <Form>
                            <div className="FormBox SideBySide">
                              <TextInput
                                className="EditBox"
                                name="firstName"
                                label={t(
                                  'SSCandidate_Candidate_Sign_Up_Form_Sign_Up_Panel_First_Name'
                                )}
                                disable={disableForm}
                              />
                              <TextInput
                                className="EditBox"
                                name="lastName"
                                label={t(
                                  'SSCandidate_Candidate_Sign_Up_Form_Sign_Up_Panel_Last_Name'
                                )}
                                disable={disableForm}
                              />
                            </div>
                            <div className="FormBox">
                              <TextInput
                                autoComplete="email"
                                className="EditBox"
                                name="emailAddress"
                                label={t(
                                  'SSCandidate_Candidate_Sign_Up_Form_Sign_Up_Panel_Email_Address'
                                )}
                                disable={disableForm}
                              />
                            </div>
                            <div className="FormBox PWWithIcon">
                              <TextInput
                                autoComplete="current-password"
                                className="EditBox"
                                name="newPassword"
                                type="password"
                                label={t(
                                  'SSCandidate_Candidate_Sign_Up_Form_Sign_Up_Panel_Password'
                                )}
                                isPassword={true}
                                togglePasswordView={true}
                              />
                              <PasswordStrengthMeter
                                fieldName="newPassword"
                                showRatingBar={true}
                              />
                            </div>
                            <div>
                              <Button
                                isPublic
                                priority="primary"
                                type="submit"
                                className="Btn CandidateSignUpSubmit"
                              >
                                {t(
                                  'SSCandidate_Candidate_Sign_Up_Form_SIgn_In_Panel_Sign_Up'
                                )}
                              </Button>

                              {errorCode && (
                                <p className="SignInErrorMessage">
                                  {parseErrorCode(false, errorCode)}
                                </p>
                              )}
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>

                    <div className="PrivacyBlock">
                      {t('SSCandidate_Candidate_Sign_Up_Form_Privacy_Block', {
                        val1: (
                          <a href="/terms-and-conditions" target="_blank">
                            {t(
                              'SSCandidate_Candidate_Sign_Up_Form_Privacy_Block_Val_1'
                            )}
                          </a>
                        ),
                        val2: t(
                          'SSCandidate_Candidate_Sign_Up_Form_Privacy_Block_Val_2'
                        ),
                        val3: (
                          <a
                            href="https://www.clevry.com/en/about/privacy"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {t(
                              'SSCandidate_Candidate_Sign_Up_Form_Privacy_Block_Val_3'
                            )}
                          </a>
                        ),
                      })}
                    </div>
                  </div>
                ) : (
                  <div className="ExistingUserContainer">
                    <h1 className="Title">
                      {t(
                        'SSCandidate_Candidate_Sign_Up_Form_Existing_User_Title'
                      )}
                    </h1>
                    <div className="Description">
                      {t(
                        'SSCandidate_Candidate_Sign_Up_Form_Existing_User_Description'
                      )}
                    </div>

                    <div className="ExisitingUserSignInContainer">
                      <Formik
                        initialValues={userExistsInitial}
                        validationSchema={existingUserValidationSchema}
                        onSubmit={handleExistingUserSignIn}
                      >
                        {(props) => (
                          <Form>
                            <div className="FormBox SideBySide ExisitingUserSignIn">
                              <div className="FormBox">
                                <TextInput
                                  autoComplete="email"
                                  className="EditBox"
                                  name="emailAddress"
                                  label={t(
                                    'SSCandidate_Candidate_Sign_Up_Form_Sign_Up_Panel_Email_Address'
                                  )}
                                  disable={disableForm}
                                />
                              </div>
                              <div className="FormBox PWWithIcon">
                                <TextInput
                                  autoComplete="current-password"
                                  className="EditBox"
                                  name="existingPassword"
                                  type="password"
                                  label={t(
                                    'SSCandidate_Candidate_Sign_Up_Form_Existing_Password'
                                  )}
                                  isPassword={true}
                                  togglePasswordView={true}
                                />
                              </div>

                              {errorCode && (
                                <p className="SignInErrorMessage">
                                  {parseErrorCode(true, errorCode)}
                                </p>
                              )}
                              <Button
                                isPublic
                                priority="primary"
                                type="submit"
                                className="Btn CandidateSignInSubmit"
                              >
                                {t(
                                  'SSCandidate_Candidate_Sign_Up_Form_Sign_In'
                                )}
                              </Button>

                              <div className="ForgottenPasswordContainer">
                                <a
                                  href="/forgotten-password"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="ForgottenPassword"
                                >
                                  {t(
                                    'SSCandidate_Candidate_Sign_Up_Form_Forgotten_Password'
                                  )}
                                </a>
                              </div>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default CanidateSignUpForm;
