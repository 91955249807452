import React from 'react';
import { useDropzone, DropzoneOptions, DropEvent } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';

// Icons
import UploadIcon from 'assets/apply.clevry/Icon_DragDrop.png';
import WarningIcon from 'assets/apply.clevry/Icon_Warning.png';

type DropzoneProps = {
  children?(isDragActive: boolean): React.ReactNode;
  uploadedImage: string | ArrayBuffer | null;
  dropzoneOptions: DropzoneOptions;
  dropzoneTitle?: string;
  showWarningMessage?: boolean;
};

type Props = DropzoneProps;

const Dropzone: React.FC<Props> = ({
  uploadedImage,
  dropzoneOptions,
  children,
  dropzoneTitle,
  showWarningMessage = false,
}) => {
  const { getRootProps, getInputProps, isDragActive } =
    useDropzone(dropzoneOptions);

  return (
    <div
      className={uploadedImage ? 'Dropzone Disabled' : 'Dropzone'}
      {...getRootProps()}
      style={{ backgroundImage: `url(${uploadedImage})` }}
    >
      {!uploadedImage && !showWarningMessage && (
        <>
          <input
            {...getInputProps({
              multiple: false,
              accept: 'image/jpg, image/jpeg, image/png',
            })}
          />
          {children ? (
            children(isDragActive)
          ) : (
            <>
              <img src={UploadIcon} alt="Upload" />
              <p className="Bold">
                {dropzoneTitle ? dropzoneTitle : 'Project Master Plan Image'}
              </p>
              {isDragActive ? (
                <p>Drop here to upload!</p>
              ) : (
                <p>Click or Drag to Upload</p>
              )}
            </>
          )}
        </>
      )}
      {showWarningMessage && (
        <span className="WarningMessage">
          <img className="WarningIcon" src={WarningIcon} alt="Warning" /> Please
          only upload one image
        </span>
      )}
    </div>
  );
};

/** test new dropzone */
export type DropzoneProps2 = {
  children?(isDragActive: boolean): React.ReactNode;
  uploadedFile?: File;
  fileB64?: string;
  onDrop<T extends File>(
    acceptedFiles: T[],
    fileRejections: any[],
    event: DropEvent
  ): void;
  dropzoneOptions?: DropzoneOptions;
  dropzoneTitle?: string;
  showWarningMessage?: boolean;
  name: string;
  readOnly?: boolean;
  warningIcon?: any;
  uploadIcon?: any;
  previouslyUploadedImage?: string;
  fullSizePreviousImage?: boolean;
  maxSize?: number;
};

type Props2 = DropzoneProps2;

const FileDropzone: React.FC<Props2> = ({
  uploadedFile,
  fileB64,
  dropzoneOptions,
  children,
  name,
  onDrop,
  dropzoneTitle,
  showWarningMessage = false,
  readOnly,
  warningIcon,
  uploadIcon,
}) => {
  const initial = fileB64 ?? '';
  const [imageBase64, setImageBase64] = React.useState<string>(initial);

  if (uploadedFile && !fileB64) {
    if (isImage(uploadedFile)) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const imageBase64 = reader.result;
        setImageBase64(imageBase64 as string);
      };
      reader.readAsDataURL(uploadedFile);
    } else {
      // non image file
    }
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    ...dropzoneOptions,
    onDrop,
    disabled: readOnly,
  });

  return (
    <div
      className={uploadedFile ? 'Dropzone Disabled' : 'Dropzone'}
      {...getRootProps()}
    >
      {/* If no files uploaded */}
      {!uploadedFile && !showWarningMessage && (
        <>
          <input
            {...getInputProps({
              multiple: false,
              name: name,
              accept: 'image/jpg, image/jpeg, image/png',
            })}
          />
          {children ? (
            children(isDragActive)
          ) : (
            <>
              <img src={UploadIcon} alt="Upload" />
              <p className="Bold">
                {dropzoneTitle ? dropzoneTitle : 'Drag and drop or click here'}
              </p>
              {isDragActive ? (
                <p>Drop here to upload!</p>
              ) : (
                <p>to upload your profile picture</p>
              )}
            </>
          )}
        </>
      )}

      {/* If file uploaded, is an image and does not exceed max size then display it */}
      {uploadedFile &&
        (!isImage(uploadedFile) ? (
          <span className="WarningMessage NoImage">
            <img className="WarningIcon" src={WarningIcon} alt="Warning" />{' '}
            Please select an image file
          </span>
        ) : !checkSize(uploadedFile) ? (
          <span className="WarningMessage NoImage">
            <img className="WarningIcon" src={WarningIcon} alt="Warning" />{' '}
            Image exceeds 10MB size limit
          </span>
        ) : (
          <div
            className="DropzoneImage"
            style={{ backgroundImage: `url(${imageBase64})` }}
          />
        ))}

      {/* Warning message - only upload one file */}
      {showWarningMessage && (
        <span className="WarningMessage NoImage">
          <img className="WarningIcon" src={WarningIcon} alt="Warning" /> Please
          only upload one image
        </span>
      )}
    </div>
  );
};
const Dropzone2: React.FC<Props2> = ({
  uploadedFile,
  fileB64,
  dropzoneOptions,
  children,
  name,
  onDrop,
  dropzoneTitle,
  showWarningMessage = false,
  readOnly,
  warningIcon,
  uploadIcon,
  previouslyUploadedImage,
  fullSizePreviousImage,
  maxSize,
}) => {
  const initial = fileB64 ?? '';
  const [imageBase64, setImageBase64] = React.useState<string>(initial);

  const { t } = useTranslation('Dropzone', { i18n });

  if (uploadedFile && !fileB64) {
    if (isImage(uploadedFile)) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const imageBase64 = reader.result;
        setImageBase64(imageBase64 as string);
      };
      reader.readAsDataURL(uploadedFile);
    } else {
      // non image file
    }
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    ...dropzoneOptions,
    onDrop,
    disabled: readOnly,
  });

  return (
    <div
      className={uploadedFile ? 'Dropzone Disabled' : 'Dropzone'}
      {...getRootProps()}
    >
      {/* If no files uploaded */}
      {!uploadedFile && !showWarningMessage && (
        <>
          <input
            {...getInputProps({
              multiple: false,
              name: name,
              accept: 'image/jpg, image/jpeg, image/png',
            })}
          />
          {children ? (
            children(isDragActive)
          ) : !previouslyUploadedImage ? (
            <>
              <img
                src={UploadIcon}
                alt={t('SSCandidate_Dropzone_2_Alt_Upload')}
              />
              <p className="Bold">
                {dropzoneTitle
                  ? dropzoneTitle
                  : t('SSCandidate_Dropzone_2_Drag_And_Drop')}
              </p>
              {isDragActive ? (
                <p>{t('SSCandidate_Dropzone_2_Drop_Here')}</p>
              ) : (
                <p>{t('SSCandidate_Dropzone_2_To_Upload')}</p>
              )}
            </>
          ) : (
            <>
              <img
                className={`PreviouslyUploadedImage ${
                  fullSizePreviousImage ? 'FullSize' : ''
                }`}
                src={previouslyUploadedImage}
                alt={t('SSCandidate_Dropzone_2_Alt_Upload')}
              />
              {!fullSizePreviousImage && (
                <>
                  <p className="Bold">
                    {dropzoneTitle
                      ? dropzoneTitle
                      : t('SSCandidate_Dropzone_2_Drag_And_Drop')}
                  </p>
                  {isDragActive ? (
                    <p>{t('SSCandidate_Dropzone_2_Drop_Here')}</p>
                  ) : (
                    <p>{t('SSCandidate_Dropzone_2_To_Upload')}</p>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}

      {/* If file uploaded, is an image and does not exceed max size then display it */}
      {uploadedFile &&
        (!isImage(uploadedFile) ? (
          <span className="WarningMessage NoImage">
            <img
              className="WarningIcon"
              src={WarningIcon}
              alt={t('SSCandidate_Dropzone_2_Select_Alt_Warning')}
            />{' '}
            {t('SSCandidate_Dropzone_2_Select_Image')}
          </span>
        ) : !checkSize(uploadedFile, maxSize) ? (
          <span className="WarningMessage NoImage">
            <img
              className="WarningIcon"
              src={WarningIcon}
              alt={t('SSCandidate_Dropzone_2_Select_Alt_Warning')}
            />{' '}
            {t('SSCandidate_Dropzone_2_Image_Exceeds_Size_Limit')}
          </span>
        ) : (
          <div
            className="DropzoneImage"
            style={{ backgroundImage: `url(${imageBase64})` }}
          />
        ))}

      {/* Warning message - only upload one file */}
      {showWarningMessage && (
        <span className="WarningMessage NoImage">
          <img
            className="WarningIcon"
            src={WarningIcon}
            alt={t('SSCandidate_Dropzone_2_Select_Alt_Warning')}
          />
          {t('SSCandidate_Dropzone_2_Only_Upload')}
        </span>
      )}
    </div>
  );
};

export type DropzoneProps3 = {
  children?(isDragActive: boolean): React.ReactNode;
  uploadedFile?: File;
  fileB64?: string;
  onDrop<T extends File>(
    acceptedFiles: T[],
    fileRejections: any[],
    event: DropEvent
  ): void;
  dropzoneOptions?: DropzoneOptions;
  dropzoneTitle?: string;
  showWarningMessage?: boolean;
  name: string;
  readOnly?: boolean;
  warningIcon?: any;
  uploadIcon?: any;
  previouslyUploadedImage?: string;
  fullSizePreviousImage?: boolean;
  accept: string;
  allowMultipleFiles: boolean;
};

type Props3 = DropzoneProps3;

const Dropzone3: React.FC<Props3> = ({
  uploadedFile,
  fileB64,
  dropzoneOptions,
  children,
  name,
  onDrop,
  dropzoneTitle,
  showWarningMessage = false,
  readOnly,
  warningIcon,
  uploadIcon,
  previouslyUploadedImage,
  fullSizePreviousImage,
  accept,
  allowMultipleFiles,
}) => {
  const initial = fileB64 ?? '';
  const [imageBase64, setImageBase64] = React.useState<string>(initial);

  const { t } = useTranslation('Dropzone', { i18n });

  if (uploadedFile && !fileB64) {
    if (isImage(uploadedFile)) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const imageBase64 = reader.result;
        setImageBase64(imageBase64 as string);
      };
      reader.readAsDataURL(uploadedFile);
    } else {
      // non image file
    }
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    ...dropzoneOptions,
    onDrop,
    disabled: readOnly,
  });

  return (
    <div
      className={uploadedFile ? 'Dropzone Disabled' : 'Dropzone'}
      {...getRootProps()}
    >
      {/* If no files uploaded */}
      {!uploadedFile && !showWarningMessage && (
        <>
          <input
            {...getInputProps({
              multiple: allowMultipleFiles,
              name: name,
              accept: accept,
            })}
          />
          {children ? (
            children(isDragActive)
          ) : !previouslyUploadedImage ? (
            <>
              <img
                src={UploadIcon}
                alt={t('SSCandidate_Dropzone_2_Alt_Upload')}
              />
              <p className="Bold">
                {dropzoneTitle
                  ? dropzoneTitle
                  : t('SSCandidate_Dropzone_2_Drag_And_Drop')}
              </p>
              {isDragActive ? (
                <p>{t('SSCandidate_Dropzone_2_Drop_Here')}</p>
              ) : (
                <p>{t('SSCandidate_Dropzone_2_To_Upload')}</p>
              )}
            </>
          ) : (
            <>
              <img
                className={`PreviouslyUploadedImage ${
                  fullSizePreviousImage ? 'FullSize' : ''
                }`}
                src={previouslyUploadedImage}
                alt={t('SSCandidate_Dropzone_2_Alt_Upload')}
              />
              {!fullSizePreviousImage && (
                <>
                  <p className="Bold">
                    {dropzoneTitle
                      ? dropzoneTitle
                      : t('SSCandidate_Dropzone_2_Drag_And_Drop')}
                  </p>
                  {isDragActive ? (
                    <p>{t('SSCandidate_Dropzone_2_Drop_Here')}</p>
                  ) : (
                    <p>{t('SSCandidate_Dropzone_2_To_Upload')}</p>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}

      {/* If file uploaded, is an image and does not exceed max size then display it */}
      {uploadedFile &&
        (!isImage(uploadedFile) ? (
          <span className="WarningMessage NoImage">
            <img
              className="WarningIcon"
              src={WarningIcon}
              alt={t('SSCandidate_Dropzone_2_Select_Alt_Warning')}
            />{' '}
            {t('SSCandidate_Dropzone_2_Select_Image')}
          </span>
        ) : !checkSize(uploadedFile) ? (
          <span className="WarningMessage NoImage">
            <img
              className="WarningIcon"
              src={WarningIcon}
              alt={t('SSCandidate_Dropzone_2_Select_Alt_Warning')}
            />{' '}
            {t('SSCandidate_Dropzone_2_Image_Exceeds')}
          </span>
        ) : (
          <div
            className="DropzoneImage"
            style={{ backgroundImage: `url(${imageBase64})` }}
          />
        ))}

      {/* Warning message - only upload one file */}
      {showWarningMessage && (
        <span className="WarningMessage NoImage">
          <img
            className="WarningIcon"
            src={WarningIcon}
            alt={t('SSCandidate_Dropzone_2_Select_Alt_Warning')}
          />
          {t('SSCandidate_Dropzone_2_Only_Upload')}
        </span>
      )}
    </div>
  );
};

// Helpers
const isImage = (file: File) => file.type.search('image') > -1;
const checkSize = (file: File, maxSize: number = 10000000) => {
  return file.size < maxSize;
};

export { Dropzone, Dropzone2, Dropzone3, FileDropzone };
