import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import { createEpicMiddleware } from 'redux-observable';
import { createStore, applyMiddleware } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import { rootReducer, rootEpic } from 'reducers/rootReducer';
import { Location } from '@reach/router';
import { composeWithDevTools } from 'redux-devtools-extension';
import LoadingFallback from 'components/fallbacks/LoadingFallback';

const epicMiddleware = createEpicMiddleware();

const configureStore = () => {
  const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(epicMiddleware))
  );
  epicMiddleware.run(rootEpic);
  return store;
};

export const store = configureStore();

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<LoadingFallback />}>
      <Provider store={store}>
        <Location>{({ location }) => <App location={location} />}</Location>
      </Provider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);
