import instance, { ApiResponse } from 'api';
import { endpoints } from 'config';
import {
  FindYourJoyQuestionAnswer,
  FindYourJoyQuizResults,
  ParsedFindYourJoyQuizResults,
} from './models';
import { Word } from 'react-wordcloud';

export const scaleWordcloudValue = (value: number) => Math.pow(value, 3) + 10;
class FindYourJoyApiModel {
  fetchResults(answers: FindYourJoyQuestionAnswer[], quizId: number) {
    return instance.post<ApiResponse<any, FindYourJoyQuizResults>>(
      endpoints.findYourJoy.results,
      // TODO(HC): Include languageId once localized.
      { questionAnswers: answers, softSkillsTestId: quizId, languageId: 1 }
    );
  }

  parseResults(results: FindYourJoyQuizResults): ParsedFindYourJoyQuizResults {
    const wordcloud = results.wordcloud.map<Word>(({ label, value }) => ({
      text: label,
      value: scaleWordcloudValue(value),
    }));

    const highestScoringProfileItem = results.profile.reduce(
      (highest, current) => {
        if (current.value > highest.value) {
          return current;
        }
        return highest;
      },
      results.profile[0]
    );

    return {
      wordcloud,
      profile: results.profile,
      highestScoringProfileItem: highestScoringProfileItem,
    };
  }

  postCustomerAnswers(answers: FindYourJoyQuestionAnswer[], quizId: number) {
    return instance.post<ApiResponse>(endpoints.findYourJoy.saveAnswers, {
      questionAnswers: answers,
      softSkillsTestId: quizId,
      languageId: 1,
    });
  }
}

export const FindYourJoyApi = new FindYourJoyApiModel();
