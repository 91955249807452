import { endpoints } from 'config';
import { get, post } from '../../../helpers/newMakeEndpointRequest';

type ApiReturn<T = any> = {
  response: T | null;
  errors: any;
};

export const decryptApplicationLink = async (params: {
  encryptedToken: string;
}): Promise<ApiReturn> => {
  const url = endpoints.jobBoard.getProjectIdToken;
  return await get(url, params);
};

export const decryptApplicationLinkPublic = async (params: {
  encryptedToken: string;
}): Promise<ApiReturn> => {
  const url = endpoints.jobBoard.getProjectIdTokenPublic;
  return await get(url, params);
};

export const getWebskin = async (params: {
  DomainURL: string;
}): Promise<ApiReturn> => {
  const url = endpoints.webskin.getWebskinDetails;
  return await get(url, params);
};
