import psycruitLogo from 'assets/apply.clevry/Logo2.png';
// import psycruitLogoWhite from 'assets/apply.clevry/Logo_White.png';
import React, { useContext } from 'react';
import { NavBarContext } from './NavBar';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';

export const StandardNavBar: React.FC = () => {
  const { t } = useTranslation('Standard+Nav+Bar', { i18n });

  const isThemed = useContext(NavBarContext);

  return (
    <>
      <nav className="Nav LoggedOut">
        <div className="PageNavBar LoginNav">
          <img
            // src={isThemed ? psycruitLogoWhite : psycruitLogo}
            src={psycruitLogo}
            alt={t('SSCandidate_Standard_Nav_Bar_Alt_Company_Logo')}
            className="CompanyLogo"
          />
        </div>
      </nav>
    </>
  );
};
