import { Store } from './rootReducer';

//Types
export enum FeatureFlagsAction {
  'SET_ALL_FEATURE_FLAGS' = 'SET_ALL_FEATURE_FLAGS',
}

export interface NamedFeatureFlag {
  feature: string;
  publicKey: string;
  bAllowed: boolean;
}
export interface FeatureFlag {
  publicKey: string;
  bAllowed: boolean;
}

export interface FeatureFlags {
  allFeatureFlags: NamedFeatureFlag[] | null;
}

const initialState: FeatureFlags = {
  allFeatureFlags: null,
};

// Actions
export type Action = {
  type: FeatureFlagsAction.SET_ALL_FEATURE_FLAGS;
  payload: FeatureFlags;
};

// Reducer
export default function FeatureFlagsReducer(
  state = initialState,
  action: Action
): FeatureFlags {
  if (action.type === FeatureFlagsAction.SET_ALL_FEATURE_FLAGS) {
    return { ...state, ...action.payload };
  }
  return state;
}

// Action creators
export const setAllFeatureFlags = (payload: FeatureFlags) => ({
  type: FeatureFlagsAction.SET_ALL_FEATURE_FLAGS,
  payload,
});

// Selectors
export const selectFeatureFlags = (state: Store) => state.featureFlags;
