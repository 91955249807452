import * as Yup from 'yup';
import { FieldArray, Form, Formik } from 'formik';
import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import MP_Profile_Language from 'assets/apply.clevry/MP_Profile_Language_01.png';
import IconAdd from 'assets/apply.clevry/Icon_Add_DarkBlue.png';
import {
  getLanguagesYouSpeakDetails,
  getLanguagesYouSpeakNameOptions,
  getLanguagesYouSpeakProficiencyOptions,
  parseLanguagesYouSpeakDetails,
  parseLanguagesYouSpeakNameOptions,
  parseLanguagesYouSpeakPayload,
  parseLanguagesYouSpeakProficiencyOptions,
  submitLanguagesYouSpeakPayload,
} from '../../MatcingProcessApi';
import { Spinner } from 'components/spinner/Spinner';
import { Button } from 'components/button/Button';
import LanguagesYouSpeakRow from './LanguagesYouSpeakRow';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';
import { useDispatch, useSelector } from 'react-redux';
import {
  MatchingProcessState,
  selectMatchingProcess,
  setMatchingProcessDataSubmittedToggle,
  setMatchingProcessSubmitSuccess,
} from 'reducers/matchingProcess';
import { GAMatchingProcessLatest } from 'helpers/gaEvents';

type LanguagesYouSpeakPageProps = {
  outerSubmitForm: MutableRefObject<
    (() => Promise<void>) & (() => Promise<any>)
  >;
  setNextStepDisabled: Dispatch<SetStateAction<boolean>>;
  setGeneralError: Dispatch<SetStateAction<string | undefined>>;
};

export type LanguagesYouSpeakFomValues = {
  proficiency: number | null;
  languageRequirement: number | null;
}[];
type formikValues = { languagesSpoken: LanguagesYouSpeakFomValues };

type LanguagesYouSpeakMenuOptions = {
  languagesSpoken: {
    languageRequirement: { label: string; value: number }[];
    proficiency: { label: string; value: number }[];
  };
};

const LanguagesYouSpeakPage: React.FC<LanguagesYouSpeakPageProps> = ({
  outerSubmitForm,
  setNextStepDisabled,
  setGeneralError,
}) => {
  const latestLocation = useRef('');

  if (
    window &&
    window.location &&
    window.location.href !== latestLocation.current
  ) {
    latestLocation.current = window.location.href;
    GAMatchingProcessLatest(window.location.href);
  }

  const { t } = useTranslation('Matching+Process', { i18n });

  const blankRow: {
    languageRequirement: number | null;
    proficiency: number | null;
  } = {
    languageRequirement: null,
    proficiency: null,
  };

  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState({
    languagesSpoken: [blankRow],
  });
  const [menuOptions, setMenuOptions] = useState<LanguagesYouSpeakMenuOptions>({
    languagesSpoken: {
      languageRequirement: [],
      proficiency: [],
    },
  });

  const validationSchema = Yup.object().shape({
    languagesSpoken: Yup.array().of(
      Yup.object({
        languageRequirement: Yup.number().required(''),
        proficiency: Yup.date().required(),
      })
    ),
  });

  const dispatch = useDispatch();

  const matchingProcessDetails: MatchingProcessState = useSelector(
    selectMatchingProcess
  );

  const handleSubmit = (values: formikValues) => {
    const payload = parseLanguagesYouSpeakPayload(values);

    return (async () => {
      const result = await submitLanguagesYouSpeakPayload(payload);
      if (result.response) {
        setGeneralError('');

        dispatch(setMatchingProcessSubmitSuccess(true));

        dispatch(
          setMatchingProcessDataSubmittedToggle(
            !matchingProcessDetails.dataSubmittedToggle
          )
        );

        return true;
      } else {
        dispatch(setMatchingProcessSubmitSuccess(false));

        setGeneralError(t('SSCandidate_Matching_Process_General_Error'));
        return false;
      }
    })();
  };

  const fetchDetails = async () => {
    const result = await getLanguagesYouSpeakDetails();
    // console.log('result', result);
    if (result && result.response && result.response.details) {
      const parsedResult = parseLanguagesYouSpeakDetails(
        result.response.details
      );
      if (
        parsedResult &&
        Array.isArray(parsedResult.languagesSpoken) &&
        parsedResult.languagesSpoken.length > 0
      ) {
        setInitialValues(parsedResult); // Rendered field array depends on Formik values - if getLanguagesYouSpeakDetails returns null for candidateLanguages, it is not set to initial values
      }
      setGeneralError('');
      setLoading(false);
    } else {
      setLoading(false);
      setGeneralError(t('SSCandidate_Matching_Process_General_Error_Reload'));
    }
  };

  const fetchOptions = async () => {
    const languageNameOptions = await getLanguagesYouSpeakNameOptions();
    const languageProficiencyOptions =
      await getLanguagesYouSpeakProficiencyOptions();
    if (languageNameOptions.response && languageProficiencyOptions.response) {
      const parsedLanguageNameOptions = parseLanguagesYouSpeakNameOptions(
        languageNameOptions.response
      );
      const parsedLanguageProficiencyOptions =
        parseLanguagesYouSpeakProficiencyOptions(
          languageProficiencyOptions.response
        );
      setMenuOptions({
        languagesSpoken: {
          languageRequirement: parsedLanguageNameOptions,
          proficiency: parsedLanguageProficiencyOptions,
        },
      });
    }
  };

  useEffect(() => {
    fetchDetails();
    fetchOptions();
  }, []);

  if (loading) return <Spinner />;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
      validateOnMount
    >
      {({
        setFieldValue,
        values,
        errors,
        touched,
        setValues,
        submitForm,
        isValid,
        dirty,
        initialTouched,
      }) => {
        outerSubmitForm.current = submitForm;
        if (isValid) {
          setNextStepDisabled(false);
        } else {
          setNextStepDisabled(true);
        }

        return (
          <div className="LanguagesYouSpeakPage MatchingProcessInnerPage">
            <div className="MatchingProcess__sectionHeader">
              <div className="MatchingProcess__sectionHeading">
                {t('SSCandidate_Matching_Process_Languages_You_Speak_Heading')}
              </div>
              <div className="MatchingProcess__sectionSubheading">
                <span>
                  {t(
                    'SSCandidate_Matching_Process_Languages_You_Speak_Subheading'
                  )}
                </span>
              </div>
            </div>
            <Form className="JobApplicationForm MatchingProcessForm">
              <FieldArray
                name="languagesSpoken"
                render={({ push, remove }) => {
                  return (
                    <>
                      {values.languagesSpoken &&
                        Array.isArray(values.languagesSpoken) &&
                        values.languagesSpoken.map((item, idx) => (
                          <LanguagesYouSpeakRow
                            key={`languagesSpoken${idx}`}
                            rowNumber={idx}
                            remove={remove}
                            menuOptionsLanguages={
                              menuOptions.languagesSpoken.languageRequirement
                            }
                            menuOptionsProficiency={
                              menuOptions.languagesSpoken.proficiency
                            }
                            values={values}
                          />
                        ))}
                      <Button
                        icon={IconAdd}
                        priority={'secondary'}
                        onClick={(e) => {
                          e.preventDefault();
                          push(blankRow);
                        }}
                      >
                        {t(
                          'SSCandidate_Matching_Process_Languages_You_Speak_Button'
                        )}
                      </Button>
                    </>
                  );
                }}
              />
            </Form>
            <img
              src={MP_Profile_Language}
              alt=""
              className="LanguagesYouSpeakPage__icon"
            />
          </div>
        );
      }}
    </Formik>
  );
};

export default LanguagesYouSpeakPage;
