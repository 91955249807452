import { Store } from './rootReducer';

//Types
export enum NavType {
  'TITLE_AND_BACK' = 'TITLE_AND_BACK',
  'JOB_BOARD' = 'JOB_BOARD',
  'LOGIN' = 'LOGIN',
  'CANDIDATE_SIGN_UP' = 'CANDIDATE_SIGN_UP',
  'CANDIDATE_SIGN_UP_FORM' = 'CANDIDATE_SIGN_UP_FORM',
  'APPLY_PSYCRUIT' = 'APPLY_PSYCRUIT',
  'ACTIVITY' = 'ACTIVITY',
  'VIRTUAL_ASSESSMENT' = 'VIRTUAL_ASSESSMENT',
  'ABILITY_TEST' = 'ABILITY_TEST',
  'SJT' = 'SJT',
  'ASSESSMENT' = 'ASSESSMENT',
  'ASSESSMENT_PRACTICE' = 'ASSESSMENT_PRACTICE',
  'CHANGE_NAV_TYPE' = 'CHANGE_NAV_TYPE',
  'LOGO' = 'LOGO',
  'NONE' = 'NONE',
}

export type OtherNavData = {
  title: string;
  activityType?: string;
};

// State
export type NavState =
  | {
      navType: null;
      data: null;
    }
  | {
      navType: NavType.TITLE_AND_BACK;
      data: OtherNavData;
    }
  | {
      navType: NavType.JOB_BOARD;
      data: null;
    }
  | {
      navType: NavType.LOGIN;
      data: null;
    }
  | {
      navType: NavType.CANDIDATE_SIGN_UP;
      data: null;
    }
  | {
      navType: NavType.CANDIDATE_SIGN_UP_FORM;
      data: null;
    }
  | {
      navType: NavType.APPLY_PSYCRUIT;
      data: null;
    }
  | {
      navType: NavType.ACTIVITY;
      data: OtherNavData;
    }
  | {
      navType: NavType.VIRTUAL_ASSESSMENT;
      data: OtherNavData;
    }
  | {
      navType: NavType.ABILITY_TEST;
      data: OtherNavData;
    }
  | {
      navType: NavType.SJT;
      data: OtherNavData;
    }
  | {
      navType: NavType.ASSESSMENT;
      data: OtherNavData;
    }
  | {
      navType: NavType.ASSESSMENT_PRACTICE;
      data: OtherNavData;
    }
  | {
      navType: NavType.LOGO;
      data: null;
    }
  | {
      navType: NavType.NONE;
      data: null;
    };

export const initialState: NavState = {
  navType: null,
  data: null,
};

// Actions
export type Action = {
  type: NavType.CHANGE_NAV_TYPE;
  payload: ChangeNavType;
};

export type ChangeNavType = {
  navType: any | null;
  data: OtherNavData | null;
};

// Reducer
export default function navReducer(
  state = initialState,
  action: Action
): NavState {
  switch (action.type) {
    case NavType.CHANGE_NAV_TYPE:
      return {
        ...state,
        navType: action.payload.navType,
        data: action.payload.data,
      };
    default:
      return state;
  }
}

// Action creators
export const changeNavType = (payload: ChangeNavType) => ({
  type: NavType.CHANGE_NAV_TYPE,
  payload,
});

//Selectors
export const selectNavType = (state: Store) => state.navbar.navType;
export const selectNavData = (state: Store) => state.navbar.data;
