import { Store } from './rootReducer';

//Types
export enum DashboardInfoAction {
  'SET_DASHBOARD_INFO' = 'SET_DASHBOARD_INFO',
  'SET_DASHBOARD_INFO_MATCHING_PROFILE_COMPLETE' = 'SET_DASHBOARD_INFO_MATCHING_PROFILE_COMPLETE',
  'SET_CULTURE_CODE' = 'SET_CULTURE_CODE',
}

export interface DashboardInfo {
  personalityQuestionnaireUrl: string;
  completionPercentage: number;
  bCompletedFindYourJoyQuiz: boolean;
  bCompletedPersonalityQuestionnaire: boolean;
  bCompletedShareYourJoy: boolean;
  bViewedDevelopSoftSkills: boolean;
  bViewedLearnYourStrengths: boolean;
  profilePictureURL: string | null;
  isFetched: boolean;
  bMatchingProfileComplete: boolean | null;
  loggedInCandidateId: number;
  cultureCode: string;
  lastMatchingPageVisited: string | null;
}

const initialState: DashboardInfo = {
  personalityQuestionnaireUrl: '',
  completionPercentage: 0,
  bCompletedFindYourJoyQuiz: false,
  bCompletedPersonalityQuestionnaire: false,
  bCompletedShareYourJoy: false,
  bViewedDevelopSoftSkills: false,
  bViewedLearnYourStrengths: false,
  profilePictureURL: null,
  bMatchingProfileComplete: null,
  isFetched: false,
  loggedInCandidateId: 0,
  cultureCode: 'en-GB', //temporary
  lastMatchingPageVisited: null,
};

// Actions
export type Action =
  | {
      type: DashboardInfoAction.SET_DASHBOARD_INFO;
      payload: DashboardInfo;
    }
  | {
      type: DashboardInfoAction.SET_DASHBOARD_INFO_MATCHING_PROFILE_COMPLETE;
      payload: boolean;
    };

// Reducer
export default function DashboardInfoReducer(
  state = initialState,
  action: Action
): DashboardInfo {
  if (action.type === DashboardInfoAction.SET_DASHBOARD_INFO) {
    return { ...state, ...action.payload, isFetched: true };
  }
  if (
    action.type ===
    DashboardInfoAction.SET_DASHBOARD_INFO_MATCHING_PROFILE_COMPLETE
  ) {
    return { ...state, bMatchingProfileComplete: action.payload };
  }
  return state;
}

// Action creators
export const setDashboardInfo = (payload: DashboardInfo) => ({
  type: DashboardInfoAction.SET_DASHBOARD_INFO,
  payload,
});

export const setDashboardInfoMatchingProfileComplete = (payload: boolean) => ({
  type: DashboardInfoAction.SET_DASHBOARD_INFO_MATCHING_PROFILE_COMPLETE,
  payload,
});

// Selectors
export const selectDashboardInfo = (state: Store) => state.dashboardInfo;
export const selectCultureCode = (state: Store) =>
  state.dashboardInfo.cultureCode;
