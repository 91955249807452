import { Link } from '@reach/router';
import { AppPath } from 'appConstants';
import BurgerIcon from 'assets/apply.clevry/Icon_Menu.png';
import ClevryLogo from 'assets/apply.clevry/Logo2.png';
import { themes } from 'theme';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NamedFeatureFlag, selectFeatureFlags } from 'reducers/featureFlags';
import { NavLink } from './NavLink';
import { UserDropdownMenu } from './UserDropdownMenu';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';
import {
  selectInviteType,
  selectIsAssessmentOnlyCandidate,
  selectWebskin,
  selectToggleNavDisplay,
} from 'reducers/auth';
import LoggedInHeaderNotificationsIcon from './LoggedInHeaderNotificationsIcon';
import LoggedInHeaderMessagesIcon from './LoggedInMessagesIcon';
import { JoyDropdownMenu } from './JoyDropdownMenu';
import DropdownIcon from 'assets/apply.clevry/Icon_DropDown_Cropped.png';
import { MatchingProfileDropdownMenu } from './MatchingProfileDropdownMenu';

export type PublicNavItem = {
  path: AppPath;
  label: string;
  isMenuOpen?: boolean;
  toggleMenu?: any;
  subSections?: {
    path: string;
    label: string;
  }[];
};

export const LoggedInNavBar: React.FC = () => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [logo, setLogo] = useState<string>();
  const currentWebskin = window.sessionStorage.getItem('webskin')
    ? window.sessionStorage.getItem('webskin')
    : 'defaultTheme';

  const featureFlags = useSelector(selectFeatureFlags);
  const inviteType = useSelector(selectInviteType);
  const selectedWebskin = useSelector(selectWebskin);
  const isAssessmentOnlyCandidate =
    useSelector(selectIsAssessmentOnlyCandidate) ||
    currentWebskin !== 'defaultTheme';
  const toggleNavDisplay = useSelector(selectToggleNavDisplay);

  const { t } = useTranslation('Logged+In+Nav+Bar', { i18n });

  useEffect(() => {
    if (selectedWebskin && selectedWebskin.siteImages) {
      const webskinLogo = selectedWebskin.siteImages.find(
        (item: any) => item.code === 'SI_Logo'
      ).imageDownloadURI;
      setLogo(webskinLogo);
    } else {
      setLogo(ClevryLogo);
    }
  }, [setLogo, selectedWebskin]);

  const softSkillsCandidateFeature =
    featureFlags.allFeatureFlags &&
    featureFlags.allFeatureFlags.find(
      (item: NamedFeatureFlag) => item.feature === 'softSkillsCandidate'
    );

  const preSoftSkillsNavItems: PublicNavItem[] = [
    {
      path: AppPath.MY_SOFT_SKILLS,
      label: t('SSCandidate_Logged_In_Nav_Bar_Navigation_Bar_Nav_Item_4'),
    },

    {
      path: AppPath.MY_JOY,
      label: t('SSCandidate_Logged_In_Nav_Bar_Navigation_Bar_Nav_Item_3'),
    },
    {
      path: AppPath.MY_GROWTH,
      label: t('SSCandidate_Logged_In_Nav_Bar_Navigation_Bar_Nav_Item_5'),
    },
  ];
  const navItems: PublicNavItem[] = [
    // {
    //   path: AppPath.DASHBOARD,
    //   label: t('SSCandidate_Logged_In_Nav_Bar_Navigation_Bar_Nav_Item_1'),
    // },
    // // { path: AppPath.JOY_AT_WORK, label: t("SSCandidate_Logged_In_Nav_Bar_Navigation_Bar_Nav_Item_2") },
    // {
    //   path: AppPath.MY_SOFT_SKILLS,
    //   label: t('SSCandidate_Logged_In_Nav_Bar_Navigation_Bar_Nav_Item_4'),
    // },
    // {
    //   path: AppPath.MY_JOY,
    //   label: t('SSCandidate_Logged_In_Nav_Bar_Navigation_Bar_Nav_Item_3'),
    // },
    // {
    //   path: AppPath.MY_GROWTH,
    //   label: t('SSCandidate_Logged_In_Nav_Bar_Navigation_Bar_Nav_Item_5'),
    // },
    // // { path: AppPath.MY_DEVELOPMENT, label: t('SSCandidate_Logged_In_Nav_Bar_Navigation_Bar_Nav_Item_6') },
    // {
    //   path: AppPath.BROWSE_OPPORTUNITIES,
    //   label: t('SSCandidate_Logged_In_Nav_Bar_Navigation_Bar_Nav_Item_7'),
    // },
    // {
    //   path: AppPath.LIVE_APPLICATIONS,
    //   label: t('SSCandidate_Logged_In_Nav_Bar_Navigation_Bar_Nav_Item_8'),
    // },
    // {
    //   path: AppPath.HISTORIC_APPLICATIONS,
    //   label: t('SSCandidate_Logged_In_Nav_Bar_Navigation_Bar_Nav_Item_9'),
    // },
    // {
    //   path: AppPath.MY_SUCCESS,
    //   label: t('SSCandidate_Logged_In_Nav_Bar_Navigation_Bar_Nav_Item_12'),
    // },
    // {
    //   path: AppPath.MESSAGES,
    //   label: 'Messages',
    // },
    {
      path: AppPath.HOME,
      label: 'Home',
    },
    {
      path: AppPath.ASSESSMENTS,
      label: t('SSCandidate_Logged_In_Nav_Bar_Item_1'),
    },
    // {
    //   path: AppPath.MATCHING_PROFILE,
    //   label: t('SSCandidate_Logged_In_Nav_Bar_Item_2'),
    //   // isMenuOpen: isMatchingProfileMenuOpen,
    //   // toggleMenu: setIsMatchingProfileMenuOpen(!isMatchingProfileMenuOpen),
    //   subSections: [
    //     {
    //       path: AppPath.MATCHING_PROFILE_OVERVIEW,
    //       label: t('SSCandidate_Logged_In_Nav_Bar_Item_2_Sub_Item_1'),
    //     },
    //     {
    //       path: AppPath.MATCHING_PROFILE + '/bio-contact',
    //       label: t('SSCandidate_Logged_In_Nav_Bar_Item_2_Sub_Item_2'),
    //     },
    //     {
    //       path: AppPath.MATCHING_PROFILE + '/my-situation',
    //       label: t('SSCandidate_Logged_In_Nav_Bar_Item_2_Sub_Item_3'),
    //     },
    //     {
    //       path: AppPath.MATCHING_PROFILE + '/personality-questionnaire',
    //       label: t('SSCandidate_Logged_In_Nav_Bar_Item_2_Sub_Item_4'),
    //     },
    //     {
    //       path: AppPath.MATCHING_PROFILE + '/soft-hard-skills',
    //       label: t('SSCandidate_Logged_In_Nav_Bar_Item_2_Sub_Item_5'),
    //     },
    //     {
    //       path: AppPath.MATCHING_PROFILE + '/cv',
    //       label: t('SSCandidate_Logged_In_Nav_Bar_Item_2_Sub_Item_6'),
    //     },
    //   ],
    // },
    {
      path: AppPath.BROWSE_OPPORTUNITIES,
      label: t('SSCandidate_Logged_In_Nav_Bar_Item_3'),
    },
    {
      path: AppPath.JOB_APPLICATIONS,
      label: t('SSCandidate_Logged_In_Nav_Bar_Item_4'),
      // isMenuOpen: isJobApplicationsMenuOpen,
      // toggleMenu: setIsJobApplicationsMenuOpen(!isJobApplicationsMenuOpen),
      subSections: [
        {
          path: AppPath.JOB_APPLICATIONS,
          label: t('SSCandidate_Logged_In_Nav_Bar_Item_4_Sub_Item_1'),
        },
        {
          path: AppPath.JOB_APPLICATIONS_LIVE,
          label: t('SSCandidate_Logged_In_Nav_Bar_Item_4_Sub_Item_2'),
        },
        {
          path: AppPath.JOB_APPLICATIONS_HISTORIC,
          label: t('SSCandidate_Logged_In_Nav_Bar_Item_4_Sub_Item_3'),
        },
      ],
    },
  ];
  const assessmentOnlyNavItems: PublicNavItem[] = [
    {
      path: AppPath.ASSESSMENTS,
      label: t('SSCandidate_Logged_In_Nav_Bar_Item_1'),
    },
  ];

  const selectedNavItems =
    isAssessmentOnlyCandidate === true ? assessmentOnlyNavItems : navItems;

  return (
    <>
      <nav className="Nav LoggedIn">
        <div className="PageNavBar LoggedInNav">
          {!toggleNavDisplay &&
            <button
              className="BurgerMenuButton"
              onClick={() => setMenuIsOpen(true)}
            >
              <img
                src={BurgerIcon}
                className="BurgerIcon"
                alt={t('SSCandidate_Logged_In_Nav_Bar_Alt_Open_Menu')}
              />
            </button>
          }
          <div
            className={`NavBarContentBackground ${menuIsOpen ? '' : 'Hidden'}`}
            onClick={() => setMenuIsOpen(false)}
          ></div>
          <Link
            className={!toggleNavDisplay ? "" : "CenterLogo"}
            to={
              currentWebskin !== 'defaultTheme'
                ? AppPath.ASSESSMENTS
                : inviteType !== 'LIMITED_ACCESS'
                ? '#'
                : softSkillsCandidateFeature &&
                  softSkillsCandidateFeature.bAllowed
                ? AppPath.ASSESSMENTS
                : AppPath.MY_JOY
            }
          >
            <img
              src={logo}
              className="CompanyLogo"
              alt={t('SSCandidate_Logged_In_Nav_Bar_Alt_Company_Logo')}
            />
          </Link>
          {!toggleNavDisplay &&
            <div className={`NavBarContent ${menuIsOpen ? '' : 'Hidden'}`}>
              <div className="Links">
                {selectedNavItems &&
                  preSoftSkillsNavItems &&
                  softSkillsCandidateFeature && (
                    <>
                      {softSkillsCandidateFeature.bAllowed
                        ? selectedNavItems.map((item: any, index) => {
                            return (
                              <div className="LinkContainer">
                                <NavLink
                                  path={item.path}
                                  onClick={
                                    item.toggleMenu ? item.toggleMenu : () => {}
                                  }
                                  isDropdownMenu={item.subSections ? true : false}
                                  key={`NavLink${index}`}
                                >
                                  {item.label}
                                  {item.subSections && (
                                    <img
                                      src={DropdownIcon}
                                      alt="NavBarDropdown"
                                    />
                                  )}
                                </NavLink>
                                {item.subSections ? (
                                  <div className={`DropdownMenu`}>
                                    {item.subSections.map(
                                      (subItem: any, subIndex: number) => {
                                        return (
                                          <NavLink
                                            path={subItem.path}
                                            onClick={() => {}}
                                            key={`NavLinkSub${index}_${subIndex}`}
                                          >
                                            {subItem.label}
                                          </NavLink>
                                        );
                                      }
                                    )}
                                  </div>
                                ) : null}{' '}
                              </div>
                            );
                          })
                        : preSoftSkillsNavItems.map((item: any, index) => {
                            return (
                              <NavLink
                                path={item.path}
                                onClick={
                                  item.toggleMenu ? item.toggleMenu : () => {}
                                }
                                key={index}
                              >
                                {item.label}
                              </NavLink>
                            );
                          })}
                    </>
                  )}
              </div>
            </div>          
          }
          {(inviteType !== 'LIMITED_ACCESS') && (
            <div className="AccountContainer">
              <>
                {!toggleNavDisplay &&
                  <>
                    {!isAssessmentOnlyCandidate && (
                      <>
                        <div className="AccountItem DropdownIcon">
                          <MatchingProfileDropdownMenu />
                        </div>
                        <div className="AccountItem NotificationsIcon">
                          <Link className="MenuIconLink" to="/notifications-stack">
                            <LoggedInHeaderNotificationsIcon />
                          </Link>
                        </div>
                        <div className="AccountItem MessagesIcon">
                          <Link className="MenuIconLink" to="/messages">
                            <LoggedInHeaderMessagesIcon />
                          </Link>
                        </div>
                        <div className="AccountItem DropdownIcon">
                          <JoyDropdownMenu />
                        </div>
                      </>
                    )}

                    <div className="AccountItem DropdownIcon">
                      <UserDropdownMenu />
                    </div>                  
                  </>
                }
              </>
            </div>
          )}
        </div>
      </nav>
    </>
  );
};
