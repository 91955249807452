import { Button } from 'components/button/Button';
import React from 'react';

export type InteractiveFeedbackMenuScoreRowProps = {
  onLeftButtonClick: (active: boolean) => void;
  leftText: string;
  onRightButtonClick: (active: boolean) => void;
  rightText: string;
  primaryButtonActive: boolean;
  secondaryButtonActive: boolean;
  setActive: React.Dispatch<React.SetStateAction<number | null>>;
  rowNumber: number;
  score: number;
  numberOfItems: number;
  noScaleNames: boolean;
};

export const InteractiveFeedbackMenuScoreRow = ({
  onLeftButtonClick,
  onRightButtonClick,
  leftText,
  rightText,
  setActive,
  primaryButtonActive,
  secondaryButtonActive,
  rowNumber,
  score,
  numberOfItems,
  noScaleNames = false,
}: InteractiveFeedbackMenuScoreRowProps) => {
  return (
    <div
      className={`InteractiveFeedbackMenuScoreRow ${
        noScaleNames ? 'NoScaleName' : ''
      }`}
    >
      <Button
        priority="tertiary"
        className={`InteractiveFeedbackMenuScoreRow__button ${
          primaryButtonActive ? 'Active' : ''
        } ${noScaleNames ? 'NoScaleName' : ''}`}
        onClick={() => {
          setActive(rowNumber * 2);
          onLeftButtonClick(primaryButtonActive);
        }}
      >
        {leftText}
      </Button>
      <div
        className={`InteractiveFeedbackMenuScoreRow__circleContainer ${
          noScaleNames ? 'Small' : ''
        }`}
      >
        {Array.from({ length: 10 }, (_, i) => (
          <div
            key={i}
            className={`InteractiveFeedbackMenuScoreRow__circle ${
              score === i + 1 ? 'SelectedScore' : ''
            }`}
          />
        ))}
      </div>
      <Button
        priority="tertiary"
        className={`InteractiveFeedbackMenuScoreRow__button ${
          secondaryButtonActive ? 'Active' : ''
        } ${noScaleNames ? 'NoScaleName' : ''}`}
        onClick={() => {
          setActive(rowNumber * 2 + 1);
          onRightButtonClick(secondaryButtonActive);
        }}
      >
        {rightText}
      </Button>
    </div>
  );
};
