import React from 'react';
import { ErrorMessage } from 'formik';
import { useSelector } from 'react-redux';
import { selectAccessibilityData } from 'reducers/accessibility';

const ErrorMInner = ({ msg }: { msg: string }) => {
  const accessibilityData = useSelector(selectAccessibilityData);

  return (
    <div
      style={
        {
          '--backgroundColor': `${accessibilityData.backgroundColor}`,
          '--fontColor': `${accessibilityData.textColor}`,
          '--errorTextColor': `${accessibilityData.errorTextColor}`,
        } as React.CSSProperties
      }
      className="ErrorLabel"
    >
      {msg}
    </div>
  );
};

const ErrorM: React.FC<{ name: string }> = (props) => {
  return (
    <ErrorMessage name={props.name}>
      {(msg) => <ErrorMInner msg={msg} />}
    </ErrorMessage>
  );
};

export { ErrorM };
