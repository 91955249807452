import { Store } from './rootReducer';

/* STATE */
type State = {
  cultureCode: string | null;
};

const initialState: State = {
  cultureCode: null,
};

/* ACTIONS */
const SET_CULTURE_CODE = 'app/ui/SET_CULTURE_CODE';

type Action = { type: typeof SET_CULTURE_CODE; payload: string };

/* REDUCER */
export default function reducer(state = initialState, action: Action): State {
  switch (action.type) {
    case SET_CULTURE_CODE:
      return {
        ...state,
        cultureCode: action.payload,
      };
    default:
      return state;
  }
}

/* ACTION CREATORS */
export const setCultureCode = (payload: any): Action => {
  return {
    type: SET_CULTURE_CODE,
    payload,
  };
};

/* SELECTORS */
export const selectCultureCode = (state: Store): string | null =>
  state.language.cultureCode;
