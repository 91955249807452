import { navigate } from '@reach/router';
import { Button } from 'components/button/Button';
import { Dropdown } from 'components/dropdown';
import { ProgressIndicator } from 'components/progressIndicator/progressIndicator';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { closeModal, openModal } from 'reducers/modal';
import { FormRadioButtonsField } from '../../matchingProcess/matchingProcessPages/CustomFormFields/FormRadioButtonsField';
import useCountdown from './useCountdown';
import * as Yup from 'yup';
import { ErrorM } from 'helpers/ErrorM';
import {
  getReasonableAdjustmentReasons,
  requestReasonableAdjustment,
} from '../SJTApi';
import { Spinner } from 'components/spinner/Spinner';
import { TextArea } from 'components/formFields/TextArea';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';

type ModalProps = {
  data: {
    title: string;
    prongID: number;
    assessmentId: number;
    projectId: number;
  };
};

type FormikValues = {
  requestReasonID: number | null;
  note: string;
};

export const SJTAdjustmentModal: React.FC<ModalProps> = ({ data }) => {
  const [loading, setLoading] = useState(true);
  const [adjustmentReasons, setAdjustmentReasons] = useState([]);
  const [generalError, setGeneralError] = useState<string>();
  const [initialValues, setInitialValues] = useState<FormikValues>({
    requestReasonID: null,
    note: '',
  });
  const [selected, setSelected] = useState('');

  const dispatch = useDispatch();
  const { t } = useTranslation('Candidate+Journey+-+PQTest', { i18n });

  const initialTime = 610;

  const onTimeEnd = () => {
    console.log('Finished');
  };

  const { minutes, seconds, remainingSeconds } = useCountdown({
    initialSeconds: initialTime,
    onTimeEnd,
  });

  const handleSubmit = (values: FormikValues) => {
    const payload = {
      assessmentProngID: data.prongID,
      requestReasonID: values.requestReasonID,
      note: values.note,
    };
    return (async () => {
      const result = await requestReasonableAdjustment(payload);
      if (result.response) {
        setGeneralError('');
        handleCloseModal();
        navigate(`/view-application/${data.assessmentId}/live/stages`, {
          state: { projectId: data.projectId },
        });
        return true;
      } else {
        setGeneralError(t('pPQInstruction_General_Error'));

        return false;
      }
    })();
  };

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const validationSchema = Yup.object({
    requestReasonID: Yup.string()
      .required('Please select a reason for the assessment adjustment')
      .typeError('Please select a reason for the assessment adjustment'),
  });

  const parseAdjustmentReasons = (reasonList: any) => {
    const parsedReasonList = reasonList.map((item: any) => {
      return {
        label: item.reason,
        value: item.reasonableAdjustmentReasonsID,
      };
    });

    return parsedReasonList;
  };

  useEffect(() => {
    const fetchAdjustmentReasons = async () => {
      const result = await getReasonableAdjustmentReasons();

      if (result.response && result.response.details) {
        const unparsedReasons = result.response.details.reasons
          ? result.response.details.reasons
          : null;

        if (unparsedReasons) {
          const parsedReasons = parseAdjustmentReasons(unparsedReasons);
          setAdjustmentReasons(parsedReasons);
        }

        setLoading(false);
      } else {
        setLoading(false);
      }
    };

    fetchAdjustmentReasons();
  }, []);

  const modalStyle = {
    '--modalTitleColor': '#036C8F',
    '--footerBackgroundColor': '#f5f5f5',
    '--footerBorderColor': '#f5f5f5',
    '--primaryButtonBackgroundColor': '#731447',
    '--tertiaryButtonBackgroundColor': '#d5d5d5',
  } as React.CSSProperties;

  if (loading) return <Spinner />;

  return (
    <div className="SJTAdjustmentModal" style={modalStyle}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ isValid, isSubmitting, values, setFieldValue }) => (
          <Form>
            <div className="SJTAdjustmentModal__content SJTModalContent Content">
              <div className="SJTAdjustmentModal__title">
                Request adjustment to assessment
              </div>

              <div className="Paragraph">
                Are you sure you wish to request an adjustment to this
                assessment?
              </div>

              <div className="SJTAdjustmentModal__dropdown">
                <Field
                  label="Please selected the reason you wish to adjust your assessment"
                  name="requestReasonID"
                  component={Dropdown}
                  options={adjustmentReasons}
                  value={
                    values.requestReasonID
                      ? adjustmentReasons.find(
                          ({ value }) => value === values.requestReasonID
                        )
                      : values.requestReasonID
                  }
                  onChange={({
                    label,
                    value,
                  }: {
                    label: string;
                    value: number;
                  }) => {
                    setFieldValue('requestReasonID', value);
                  }}
                  roundedRectangle={false}
                />
                <ErrorM name="requestReasonID" />
                {generalError && (
                  <span className="ErrorLabel">{generalError}</span>
                )}
              </div>

              <div className="SJTAdjustmentModal__textinput">
                <TextArea
                  label="Additional notes"
                  name="note"
                  charLimit={100}
                />
              </div>
            </div>
            <div className="ModalFooter">
              <Button
                className="Rounded"
                priority="tertiary"
                type="button"
                onClick={handleCloseModal}
              >
                Cancel
              </Button>
              <Button
                className="Discard Rounded"
                priority="primary"
                type="submit"
                onClick={() => {}}
              >
                Submit
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
