import { endpoints } from 'config';
import { get, post } from 'helpers/newMakeEndpointRequest';

export type ContactEmployerParams = {
  assessmentID: number;
  subjectID: number;
  message: string;
};

export const contactEmployer = async (params: ContactEmployerParams) => {
  const url = endpoints.jobApplication.contactEmployer;
  return await post(url, params);
};

export type getContactEmployerParams = {
  assessmentID: number;
};

export type getContactEmployerDetailsResponse = {
  subjects: {
    contactEmployerSubjectTypesID: number;
    contactEmployerSubjectType: string;
  }[];
};

export const getContactEmployerDetails = async (
  params: getContactEmployerParams
) => {
  const url = endpoints.jobApplication.getContactEmployerDetails;
  return await get(url, params);
};

export const ParseGetContactEmployerDetails = ({
  subjects,
}: getContactEmployerDetailsResponse) => {
  const subjectOptions = Array.isArray(subjects)
    ? subjects.map(
        ({ contactEmployerSubjectType, contactEmployerSubjectTypesID }) => ({
          label: contactEmployerSubjectType,
          value: contactEmployerSubjectTypesID.toString(),
        })
      )
    : [];
  return { subjectOptions };
};
