import { navigate } from '@reach/router';
import { Button } from 'components/button/Button';
import { Dropdown } from 'components/dropdown';
import { ProgressIndicator } from 'components/progressIndicator/progressIndicator';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { closeModal, openModal } from 'reducers/modal';
import { FormRadioButtonsField } from '../../matchingProcess/matchingProcessPages/CustomFormFields/FormRadioButtonsField';
import useCountdown from '../../situationalJudgementTest/SJTResources/useCountdown';
import * as Yup from 'yup';
import { ErrorM } from 'helpers/ErrorM';
import {
  completeAT,
  getResetReasons,
  requestReasonableAdjustment,
  requestReset,
} from '../AbilityTestApi';
import { Spinner } from 'components/spinner/Spinner';
import { TextArea } from 'components/formFields/TextArea';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';

type ModalProps = {
  data: {
    title: string;
    prongID: number;
    assessmentId: number;
    projectId: number;
  };
};

type FormikValues = {
  requestReason: string | null;
};

export const AbilityTestsResetModal: React.FC<ModalProps> = ({ data }) => {
  const [loading, setLoading] = useState(true);
  const [resetReasons, setResetReasons] = useState([]);
  const [generalError, setGeneralError] = useState<string>();
  const [initialValues, setInitialValues] = useState<FormikValues>({
    requestReason: null,
  });

  const dispatch = useDispatch();
  const { t } = useTranslation('Candidate+Journey+-+PQTest', { i18n });

  const parseResetReasons = (reasonList: any) => {
    const parsedReasonList = reasonList.map((item: any) => {
      return {
        label: item.resetReason,
        value: item.resetReason,
      };
    });

    return parsedReasonList;
  };

  useEffect(() => {
    const fetchResetReasons = async () => {
      const result = await getResetReasons();

      if (result.response && result.response.details) {
        const unparsedReasons = result.response.details.reasons
          ? result.response.details.reasons
          : null;

        if (unparsedReasons) {
          const parsedReasons = parseResetReasons(unparsedReasons);
          setResetReasons(parsedReasons);
        }

        setLoading(false);
      } else {
        setLoading(false);
      }
    };

    fetchResetReasons();
  }, []);

  const handleSubmit = (values: FormikValues) => {
    const payload = {
      assessmentProngID: data.prongID,
      requestReason: values.requestReason,
    };
    return (async () => {
      const result = await requestReset(payload);
      if (result.response) {
        setGeneralError('');
        handleCloseModal();
        return true;
      } else {
        setGeneralError(t('pPQInstruction_General_Error'));

        return false;
      }
    })();
  };

  const handleCloseModal = () => {
    dispatch(closeModal());
    navigate(`/view-application/${data.assessmentId}/live/stages`, {
      state: { projectId: data.projectId },
    });
  };

  const handleCompleteAT = () => {
    return (async () => {
      const result = await completeAT({ assessmentProngID: data.prongID });
      if (result.response) {
        setGeneralError('');
        handleCloseModal();
      } else {
        setGeneralError(t('pPQInstruction_General_Error'));
      }
    })();
  };

  const validationSchema = Yup.object({
    requestReason: Yup.string()
      .required('Please select a reason for the assessment reset request')
      .typeError('Please select a reason for the assessment reset request'),
  });

  const modalStyle = {
    '--modalTitleColor': '#036C8F',
    '--footerBackgroundColor': '#f5f5f5',
    '--footerBorderColor': '#f5f5f5',
    '--primaryButtonBackgroundColor': '#731447',
    '--tertiaryButtonBackgroundColor': '#d5d5d5',
  } as React.CSSProperties;

  if (loading) return <Spinner />;

  return (
    <div className="AbilityTestsAdjustmentModal" style={modalStyle}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ isValid, isSubmitting, values, setFieldValue }) => (
          <Form>
            <div className="AbilityTestsAdjustmentModal__content AbilityTestsModalContent Content">
              <div className="AbilityTestsAdjustmentModal__title">
                Request reset to retake assessment
              </div>

              <div className="Paragraph">
                Unfortunately, you have exceeded the time limit to complete this
                assessment.
              </div>
              <div className="Paragraph">
                To retake the assessment, please submit a reason for the request
                below. Selecting "Decline" will instead end the assessment at
                the last completed page.
              </div>

              <div className="AbilityTestsAdjustmentModal__dropdown">
                <Field
                  label="I was unable to complete the assessment within the set time because:"
                  name="requestReasonID"
                  component={Dropdown}
                  options={resetReasons}
                  value={
                    values.requestReason
                      ? resetReasons.find(
                          ({ value }) => value === values.requestReason
                        )
                      : values.requestReason
                  }
                  onChange={({
                    label,
                    value,
                  }: {
                    label: string;
                    value: number;
                  }) => {
                    setFieldValue('requestReason', value);
                  }}
                  roundedRectangle={false}
                />
                <ErrorM name="requestReason" />
                {generalError && (
                  <span className="ErrorLabel">{generalError}</span>
                )}
              </div>

              <div className="Paragraph">
                If this is the first request for this assessment, you will
                automatically be able to retake. However, any further requests
                will be subject to approval.
              </div>
            </div>
            <div className="ModalFooter">
              <Button
                className="Rounded"
                priority="tertiary"
                type="button"
                onClick={handleCompleteAT}
              >
                Decline
              </Button>
              <Button
                className="Discard Rounded"
                priority="primary"
                type="submit"
                onClick={() => handleSubmit}
              >
                Submit
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
