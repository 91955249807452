import React from 'react';
import CloseIcon from 'assets/apply.clevry/Icon_Close.png';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';

type PublicModalProps = {
  handleClose: () => void;
  className?: string;
};

export const PublicModal: React.FC<PublicModalProps> = ({
  children,
  handleClose,
  className,
}) => {
  const { t } = useTranslation('Public+Modal', { i18n });

  return (
    <div className="PublicModalBackground">
      <div
        className={`PublicModal BreakColorTheme ${className ? className : ''}`}
      >
        <button className="UnstyledButton" onClick={handleClose}>
          <img
            src={CloseIcon}
            alt={t('SSCandidate_Public_Modal_Alt_Open_Close')}
            className="OpenClose"
          />
        </button>
        {children}
      </div>
    </div>
  );
};
