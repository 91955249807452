import { Button } from 'components/button/Button';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { closeModal } from 'reducers/modal';
import FieldInput from 'components/fieldInput/fieldInput';
import { Form, Formik } from 'formik';
import { TextInput } from 'components/formFields/TextInput';
import { PasswordStrengthMeter } from 'components/formFields/PasswordStrengthMeter';
import { AccountApi } from './accountApi';
import { IChangePassword } from './models';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';

type AccountChangePasswordModalProps = {
  data: { buttonText: string };
};

const AccountChangePasswordModal: React.FC<AccountChangePasswordModalProps> = ({
  data,
}) => {
  const [initialValues, setInitialValues] = useState<IChangePassword>({
    oldPassword: '',
    newPassword: '',
  });
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const dispatch = useDispatch();

  const { t } = useTranslation('Account+Change+Password+Modal', { i18n });

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const handleChangePassword = (payload: IChangePassword) => {
    (async () => {
      const result = await AccountApi.updatePassword(payload);
      if (result.data) {
        //if call succeeds, close modal
        dispatch(closeModal());
      }
      if (result.error) {
        setErrorMessage(result.error.message[0].messageCode);
      }
    })();
  };

  return (
    <div className="AccountChangePasswordModal FullWidth">
      <Formik initialValues={initialValues} onSubmit={handleChangePassword}>
        <Form className="FullWidth">
          <div className="Content">
            <TextInput
              name="oldPassword"
              label={t(
                'SSCandidate_Account_Change_Password_Modal_Current_Password'
              )}
              isPassword
            />
            <div className="FormBox">
              <TextInput
                name="newPassword"
                label={t(
                  'SSCandidate_Account_Change_Password_Modal_New_Password'
                )}
                isPassword
                togglePasswordView
              />
              <PasswordStrengthMeter fieldName="newPassword" />
            </div>
            {errorMessage && <p className="ErrorLabel">{errorMessage}</p>}
          </div>
          <div className="ModalFooter">
            <Button
              priority="tertiary"
              type="button"
              onClick={handleCloseModal}
            >
              {t('SSCandidate_Account_Change_Password_Modal_Misc_Cancel')}
            </Button>
            <Button priority="primary" type="submit">
              {data.buttonText}
            </Button>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export { AccountChangePasswordModal };
