import React from 'react';
import Icon_DownloadReport from 'assets/apply.clevry/Icon_DownloadReport.png';
import { useDispatch } from 'react-redux';
import { openModal } from 'reducers/modal';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';

export const InteractiveFeedbackDownloadReportButton = ({
  text,
  reportCode,
  assessmentID,
  isCandidateReport,
}: {
  text: string;
  reportCode: string;
  assessmentID: number;
  isCandidateReport?: boolean;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('Interactive+Feedback', { i18n });

  const downloadReportClickHandler = () => {
    dispatch(
      openModal({
        modalType: 'DOWNLOAD_REPORT',
        data: {
          title: t('SSCandidate_Interactive_Feedback_DownloadReport'),
          reportCode,
          assessmentId: assessmentID,
          isCandidateReport,
        },
      })
    );
  };

  return (
    <div
      className="InteractiveFeedbackDownloadReportButton"
      onClick={downloadReportClickHandler}
    >
      <img src={Icon_DownloadReport} alt="Download Report Button" />
      <div className="InteractiveFeedbackDownloadReportButton__text">
        {text}
      </div>
    </div>
  );
};
