import { navigate, Redirect, RouteComponentProps, Router } from '@reach/router';
import instance from 'api';
import { AppPath, paths } from 'appConstants';
import ErrorBoundary from 'components/ErrorBoundary';
import ErrorFallback from 'components/fallbacks/ErrorFallback';
import LoadingFallback from 'components/fallbacks/LoadingFallback';
import { ModalRouter } from 'components/modal/ModalRouter';
import Account from 'components/pages/account/Account';
import { LeftPanelRouter } from 'components/panels/leftPanel/LeftPanelRouter';
import { endpoints } from 'config';
import {
  addExpiryDate,
  getItemFromLocalStorage,
  storeInLocalStorage,
  useLocalStorageContentOnlyWithExpiryDate,
} from 'helpers/cache';
import { useCheckSignedIn } from 'helpers/useCheckSignedIn';
import { useSignOutAcrossTabs } from 'helpers/useSignOutAcrossTabs';
import React, { Suspense, useEffect, useRef, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  setApplicationLinkToken,
  selectAppVisible,
  selectAuthStatus,
  selectCampaignSignUpSuccessful,
  setWebskin,
  setInIframe,
  setIsAssessmentOnlyCandidate,
} from 'reducers/auth';
import { FooterType } from 'reducers/footer';
import { themes } from 'theme';
import { JobDescriptionProps } from './components/pages/jobDescription/JobDescription';
import Page from './components/page/Page';
import { RightPanelRouter } from 'components/panels/rightPanel/RightPanelRouter';
import IFramedProng from 'components/pages/iframedProng/IFramedProng';
import { selectIFramedProngState } from 'reducers/iframedProng';
import { registerUserLocale } from 'helpers/userLocale';
import { lazyWithRetry } from 'helpers/lazyWithRetry';
import {
  FeatureFlag,
  NamedFeatureFlag,
  // selectFeatureFlags,
  setAllFeatureFlags,
} from 'reducers/featureFlags';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './i18n/i18n';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';
import InteractiveFeedbackHub from 'components/pages/interactiveFeedback/InteractiveFeedbackHub';
import { getQueryParam } from 'helpers/getQueryParam';
import {
  decryptApplicationLinkPublic,
  getWebskin,
} from 'components/pages/signUp/signUpApi';
import { Spinner } from 'components/spinner/Spinner';
// import { ProductFruits } from 'react-product-fruits';
import { AccountApi } from './components/pages/account/accountApi';
import { setCandidateName } from 'reducers/assessment';

const languageListUrl = endpoints.language.getLanguageList;

const NotFound = lazyWithRetry(
  () => import('./components/pages/notFound/NotFound')
);
const TermsAndConditions = lazyWithRetry(
  () => import('./components/pages/termsAndConditions/TermsAndConditions')
);
const PrivacyPolicy = lazyWithRetry(
  () => import('./components/pages/privacyPolicy/PrivacyPolicy')
);
const Landing = lazyWithRetry(
  () => import('./components/pages/signIn/Landing')
);
const Home = lazyWithRetry(() => import('./components/pages/dashboard/Home'));
const SignUp = lazyWithRetry(() => import('./components/pages/signUp/SignUp'));
const SignUpForm = lazyWithRetry(
  () => import('./components/pages/signUp/SignUpFormPage') // Used instead of SignUpForm to preserve named export yet allow lazy loading
);
const FindYourJoy = lazyWithRetry(
  () => import('./components/pages/findYourJoy/FindYourJoy')
);
const CanidateSignUpLanding = lazyWithRetry(
  () => import('./components/pages/candidateSignUp/canidateSignUpLanding')
);
const CanidateSignUpForm = lazyWithRetry(
  () => import('./components/pages/candidateSignUpForm/canidateSignUpForm')
);
const AbilityPracticeTest = lazyWithRetry(
  () => import('./components/pages/abilityPracticeTest/AbilityPracticeTest')
);
const AbilityTest = lazyWithRetry(
  () => import('./components/pages/abilityTests/abilityTests/AbilityTest')
);
const SJT = lazyWithRetry(
  () => import('./components/pages/situationalJudgementTest/SJT/SJT')
);
const BlendedTest = lazyWithRetry(
  () => import('./components/pages/blendedTests/blendedTests/BlendedTest')
);
const LearnYourStrengths = lazyWithRetry(
  () => import('./components/pages/learnYourStrengths/LearnYourStrengths')
);
const MyJoy = lazyWithRetry(() => import('./components/pages/myJoy/MyJoy'));
const MySoftSkills = lazyWithRetry(
  () => import('./components/pages/mySoftSkills/MySoftSkills')
);
const CompareSoftSkills = lazyWithRetry(
  () => import('./components/pages/mySoftSkills/CompareSoftSkills')
);
const MyGrowth = lazyWithRetry(
  () => import('./components/pages/myGrowth/MyGrowth')
);
const MySuccess = lazyWithRetry(
  () => import('./components/pages/mySuccess/MySuccess')
);
const MySuccessBlog = lazyWithRetry(
  () => import('./components/pages/mySuccess/MySuccessBlog')
);
const ScaleReport = lazyWithRetry(
  () => import('./components/pages/scaleReport/ScaleReport')
);
const TeamStrengthsGroupReport = lazyWithRetry(
  () =>
    import(
      './components/pages/teamStrengthsGroupReport/TeamStrengthsGroupReport'
    )
);
const ScaleIframe = lazyWithRetry(
  () => import('./components/pages/scaleIframe/ScaleIframe')
);
const AbilityTestResultIframe = lazyWithRetry(
  () =>
    import('./components/pages/abilityTestResultIframe/AbilityTestResultIframe')
);
// const AbilityTestSummary = lazyWithRetry(
//   () => import('./components/pages/abilityTestSummary/AbilityTestSummary')
// );
const JoyAtWorkProfileReport = lazyWithRetry(
  () =>
    import('./components/pages/joyAtWorkProfileReport/JoyAtWorkProfileReport')
);
const ForgottenPassword = lazyWithRetry(
  () => import('./components/forgottenPassword/forgottenPassword')
);
// const Dashboard = lazyWithRetry(
//   () => import('./components/pages/dashboard/Dashboard')
// );
const JoyAtWork = lazyWithRetry(
  () => import('./components/pages/joyAtWork/JoyAtWork')
);
const BuyPQTests = lazyWithRetry(
  () => import('./components/pages/buyPQTests/BuyPQTests')
);
const PersonalityQuestionnaire = lazyWithRetry(
  () => import('./components/pages/pqIframe/PersonalityQuestionnaire')
);
const MyDevelopment = lazyWithRetry(
  () => import('./components/pages/myDevelopment/MyDevelopment')
);
const JobBoard = lazyWithRetry(
  () => import('./components/pages/jobBoard/JobBoard')
);
const JobDescription = lazyWithRetry(
  () => import('./components/pages/jobDescription/JobDescription')
);

const ViewApplication = lazyWithRetry(
  () => import('./components/pages/viewApplication/ViewApplication')
);
const JobApplication = lazyWithRetry(
  () => import('./components/pages/jobApplication/JobApplication')
);
const JobApplicationsOverview = lazyWithRetry(
  () =>
    import('./components/pages/jobApplicationsOverview/JobApplicationsOverview')
);
const AssessmentsOverview = lazyWithRetry(
  () => import('./components/pages/assessments/AssessmentsOverview')
);
const AssessmentsPractice = lazyWithRetry(
  () => import('./components/pages/assessments/AssessmentsPractice')
);
const AssessmentsPracticeTest = lazyWithRetry(
  () => import('./components/pages/assessments/AssessmentPracticeTest')
);
const AssessmentsBrowserChecker = lazyWithRetry(
  () => import('./components/pages/assessments/AssessmentsBrowserChecker')
);
const MatchingProfile = lazyWithRetry(
  () => import('./components/pages/matchingProfile/MatchingProfile')
);
const MatchingProfileSection = lazyWithRetry(
  () => import('./components/pages/matchingProfile/MatchingProfile')
);
const MatchingProfileOverview = lazyWithRetry(
  () => import('./components/pages/matchingProfile/MatchingProfileOverview')
);
const DevelopmentAssessment = lazyWithRetry(
  () =>
    import(
      './components/pages/developmentAssessmentIframe/DevelopmentAssessment'
    )
);
const Profile = lazyWithRetry(
  () => import('./components/pages/profile/Profile')
);
const Settings = lazyWithRetry(
  () => import('./components/pages/settings/Settings')
);
const Notifications = lazyWithRetry(
  () => import('./components/pages/notifications/Notifications')
);
const NotificationsStack = lazyWithRetry(
  () => import('./components/pages/stackNotifications/NotificationsStack')
);
const Payments = lazyWithRetry(
  () => import('./components/pages/payments/Payments')
);
const UpdateAbout = lazyWithRetry(
  () => import('./components/pages/updateAbout/UpdateAbout')
);
const UpdateContact = lazyWithRetry(
  () => import('./components/pages/updateContact/UpdateContact')
);
const UpdateExperience = lazyWithRetry(
  () => import('./components/pages/updateExperience/UpdateExperience')
);
const UpdateEducation = lazyWithRetry(
  () => import('./components/pages/updateEducation/UpdateEducation')
);
const UpdateReferences = lazyWithRetry(
  () => import('./components/pages/updateReferences/UpdateReferences')
);
const MatchingProcess = lazyWithRetry(
  () => import('./components/pages/matchingProcess/MatchingProcess')
);
const Resources = lazyWithRetry(
  () => import('./components/pages/resources/Resources')
);
const SharableReport = lazyWithRetry(
  () => import('./components/pages/sharableReport/SharableReport')
);
const ResetPassword = lazyWithRetry(
  () => import('./components/pages/resetPassword/ResetPassword')
);
const ShareTheJoy = lazyWithRetry(
  () => import('./components/pages/shareTheJoy/ShareTheJoy')
);
const LiveApplications = lazyWithRetry(
  () => import('./components/pages/liveApplications/LiveApplications')
);
const HistoricApplications = lazyWithRetry(
  () => import('./components/pages/historicApplications/HistoricApplications')
);
const VirtualTeamDiscussion = lazyWithRetry(
  () => import('./components/pages/virtualTeamDiscussion/VirtualTeamDiscussion')
);
const RecordedInterview = lazyWithRetry(
  () => import('./components/pages/RecordedInterview/RecordedInterview')
);
const PresentationActivity = lazyWithRetry(
  () => import('./components/pages/presentationActivity/PresentationActivity')
);
const VirtualTeamDiscussionActivity = lazyWithRetry(
  () =>
    import(
      './components/pages/virtualTeamDiscussionActivity/VirtualTeamDiscussion'
    )
);
const BusinessCaseActivity = lazyWithRetry(
  () => import('./components/pages/businessCaseActivity/BusinessCaseActivity')
);
const CandidateAutoLogin = lazyWithRetry(
  () => import('./components/pages/candidateAutoLogin/CandidateAutoLogin')
);
const AssessorAutoLogin = lazyWithRetry(
  () => import('./components/pages/assessorAutoLogin/AssessorAutoLogin')
);
const LinkedInAutoLogin = lazyWithRetry(
  () => import('./components/pages/linkedInAutoLogin/LinkedInAutoLogin')
);
const RedirectLogin = lazyWithRetry(
  () => import('./components/pages/redirectLogin/RedirectLogin')
);
const WidgetRedirect = lazyWithRetry(
  () => import('./components/pages/widgetRedirect/WidgetRedirect')
);
const Bookings = lazyWithRetry(
  () => import('./components/pages/bookings/Bookings')
);
const HowJoyful = lazyWithRetry(
  () => import('./components/pages/howJoyful/HowJoyful')
);
const DailyJoyTracker = lazyWithRetry(
  () => import('./components/pages/howJoyful/DailyJoy')
);
const QuizResults = lazyWithRetry(
  () => import('./components/pages/quizResults/QuizResults')
);
const SoftSkillsReflectionDiagnostic = lazyWithRetry(
  () =>
    import(
      './components/pages/softSkillsReflectionDiagnostic/SoftSkillsReflectionDiagnostic'
    )
);
const YourSoftSkillsStrengthsReflections = lazyWithRetry(
  () =>
    import(
      './components/pages/yourSoftSkillsStrengthsReflections/YourSoftSkillsStrengthsReflections'
    )
);
const Messages = lazyWithRetry(
  () => import('./components/pages/messages/MessagesMain')
);
const LiveInterview = lazyWithRetry(
  () => import('./components/pages/liveInterview/LiveInterview')
);
const JobInterview = lazyWithRetry(
  () => import('./components/pages/jobInterview/JobInterview')
);
const VirtualMeeting = lazyWithRetry(
  () => import('./components/pages/virtualMeeting/VirtualMeeting')
);
const VirtualAssessments = lazyWithRetry(
  () => import('./components/pages/virtualAssessments/VirtualAssessments')
);
const SignOutIntegrationClient = lazyWithRetry(
  () => import('./components/pages/signOut/SignOutIntegrationClient')
);

type PageProps = RouteComponentProps & {
  navType: string;
  footerType: FooterType;
  navData: { title: string; activityType?: string } | null;
};
const NotFoundPage = (props: PageProps) => (
  <Page
    isPublic
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
  >
    <NotFound {...props} />
  </Page>
);
const TermsAndConditionsPage = (props: PageProps) => (
  <Page
    isPublic
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
  >
    <TermsAndConditions {...props} />
  </Page>
);
const PrivacyPolicyPage = (props: PageProps) => (
  <Page
    isPublic
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
  >
    <PrivacyPolicy {...props} />
  </Page>
);
const LandingPage = (props: PageProps) => (
  <Page
    isPublic
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
  >
    <Landing {...props} />
  </Page>
);
const HomePage = (props: PageProps) => (
  <Page
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
  >
    <Home {...props} />
  </Page>
);
const CanidateSignUpLandingPage = (props: PageProps) => (
  <Page
    isPublic
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
  >
    <CanidateSignUpLanding {...props} />
  </Page>
);
const CanidateSignUpFormPage = (props: PageProps) => (
  <Page
    isPublic
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
  >
    <CanidateSignUpForm {...props} />
  </Page>
);
const AbilityPracticeTestPage = (props: PageProps) => (
  <Page
    isPublic
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
  >
    <AbilityPracticeTest {...props} />
  </Page>
);
const AbilityTestPage = (props: PageProps) => (
  <Page
    isPublic
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
    themed={false}
  >
    <AbilityTest {...props} />
  </Page>
);
const SJTPage = (props: PageProps) => (
  <Page
    isPublic
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
    themed={false}
  >
    <SJT {...props} />
  </Page>
);
const BlendedTestPage = (props: PageProps) => (
  <Page
    isPublic
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
    themed={false}
  >
    <BlendedTest {...props} />
  </Page>
);
const SignUpPage = (props: PageProps) => (
  <Page
    isPublic
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
  >
    <SignUp {...props} />
  </Page>
);
const GenericSignUpPage = (props: PageProps) => (
  <Page
    isPublic
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
  >
    <SignUpForm {...props} />
  </Page>
);
const FindYourJoyPage = (props: PageProps) => (
  <Page
    isPublic
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
  >
    <FindYourJoy {...props} />
  </Page>
);

const PublicJobBoardPage = (props: PageProps) => (
  <Page
    isPublic
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
    themed={false}
  >
    <JobBoard {...props} />
  </Page>
);

const FindYourJoyCustomerPage = (props: PageProps) => (
  <Page
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
  >
    <FindYourJoy {...props} isCustomer />
  </Page>
);
const LearnYourStrengthsPage = (props: PageProps) => (
  <Page
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
  >
    <LearnYourStrengths {...props} />
  </Page>
);
const MyJoyPage = (props: PageProps) => (
  <Page
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
  >
    <MyJoy {...props} />
  </Page>
);
const MyGrowthPage = (props: PageProps) => (
  <Page
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
  >
    <MyGrowth {...props} />
  </Page>
);
const MySoftSkillsPage = (props: PageProps) => (
  <Page
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
  >
    <MySoftSkills {...props} />
  </Page>
);
const CompareSoftSkillsPage = (props: PageProps) => (
  <Page
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
  >
    <CompareSoftSkills {...props} />
  </Page>
);
const MySuccessPage = (props: PageProps) => (
  <Page
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
  >
    <MySuccess {...props} />
  </Page>
);
const MySuccessBlogPage = (props: PageProps) => (
  <Page
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
  >
    <MySuccessBlog {...props} />
  </Page>
);
const ScaleReportPage = (props: PageProps) => (
  <Page
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
  >
    <ScaleReport {...props} />
  </Page>
);
const ScaleIframePage = (props: PageProps) => (
  <Page
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
  >
    <ScaleIframe {...props} />
  </Page>
);
const TeamStrengthsGroupReportPage = (props: PageProps) => (
  <Page
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
  >
    <TeamStrengthsGroupReport {...props} />
  </Page>
);
const AbilityTestResultIframePage = (props: PageProps) => (
  <Page
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
  >
    <AbilityTestResultIframe {...props} />
  </Page>
);
const JoyAtWorkProfileReportPage = (props: PageProps) => (
  <Page
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
  >
    <JoyAtWorkProfileReport {...props} />
  </Page>
);
const ForgottenPasswordPage = (props: PageProps) => (
  <Page
    isPublic
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
  >
    <ForgottenPassword {...props} />
  </Page>
);
// const DashboardPage = (props: PageProps) => (
//   <Page
//     navType={props.navType}
//     footerType={props.footerType}
//     navData={props.navData}
//   >
//     <Dashboard {...props} />
//   </Page>
// );
const JoyAtWorkPage = (props: PageProps) => (
  <Page
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
  >
    <JoyAtWork {...props} />
  </Page>
);
const BuyPQTestsPage = (props: PageProps) => (
  <Page
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
  >
    <BuyPQTests {...props} />
  </Page>
);
const MyDevelopmentPage = (props: PageProps) => (
  <Page
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
  >
    <MyDevelopment {...props} />
  </Page>
);
const JobBoardPage = (props: PageProps) => (
  <Page
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
  >
    <JobBoard {...props} />
  </Page>
);
const JobDescriptionPage = (props: PageProps & JobDescriptionProps) => (
  <Page
    navType={props.navType}
    navData={props.navData}
    footerType={props.footerType}
  >
    <JobDescription {...props} isPublic={false} />
  </Page>
);
const PublicJobDescriptionPage = (props: PageProps & JobDescriptionProps) => (
  <Page
    navType={props.navType}
    navData={props.navData}
    footerType={props.footerType}
    isPublic
    themed={false}
  >
    <JobDescription {...props} isPublic={true} />
  </Page>
);
const ViewApplicationPage = (props: PageProps) => (
  <Page
    navType={props.navType}
    navData={props.navData}
    footerType={props.footerType}
  >
    <ViewApplication {...props} />
  </Page>
);
const JobApplicationPage = (props: PageProps) => (
  <Page
    navType={props.navType}
    navData={props.navData}
    footerType={props.footerType}
  >
    <JobApplication {...props} />
  </Page>
);
const AssessmentsOverviewPage = (props: PageProps) => (
  <Page
    navType={props.navType}
    navData={props.navData}
    footerType={props.footerType}
  >
    <AssessmentsOverview {...props} />
  </Page>
);
const AssessmentsPracticePage = (props: PageProps) => (
  <Page
    navType={props.navType}
    navData={props.navData}
    footerType={props.footerType}
  >
    <AssessmentsPractice {...props} />
  </Page>
);
const AssessmentsPracticeRedirectPage = (props: PageProps) => (
  <Page
    navType={props.navType}
    navData={props.navData}
    footerType={props.footerType}
  >
    <AssessmentsPractice {...props} />
  </Page>
);
const AssessmentsPracticeTestPage = (props: PageProps) => (
  <Page
    navType={props.navType}
    navData={props.navData}
    footerType={props.footerType}
  >
    <AssessmentsPracticeTest {...props} />
  </Page>
);
const AssessmentsBrowserCheckerPage = (props: PageProps) => (
  <Page
    navType={props.navType}
    navData={props.navData}
    footerType={props.footerType}
  >
    <AssessmentsBrowserChecker {...props} />
  </Page>
);
const JobApplicationsOverviewPage = (props: PageProps) => (
  <Page
    navType={props.navType}
    navData={props.navData}
    footerType={props.footerType}
  >
    <JobApplicationsOverview {...props} />
  </Page>
);
const MatchingProfilePage = (props: PageProps) => (
  <Page
    navType={props.navType}
    navData={props.navData}
    footerType={props.footerType}
  >
    <MatchingProfile {...props} />
  </Page>
);
const MatchingProfileSectionPage = (props: PageProps) => (
  <Page
    navType={props.navType}
    navData={props.navData}
    footerType={props.footerType}
  >
    <MatchingProfileSection {...props} />
  </Page>
);
const MatchingProfileOverviewPage = (props: PageProps) => (
  <Page
    navType={props.navType}
    navData={props.navData}
    footerType={props.footerType}
  >
    <MatchingProfileOverview {...props} />
  </Page>
);
const IFramedProngPage = (props: PageProps) => {
  const iframeData: any = useSelector(selectIFramedProngState);
  return (
    <Page
      navType={props.navType}
      footerType={props.footerType}
      navData={props.navData}
    >
      <IFramedProng
        {...props}
        iframeUrl={iframeData.iframeUrl}
        redirectUrl={iframeData.redirectUrl}
        redirectUrlProps={iframeData.redirectUrlProps}
      />
    </Page>
  );
};
const DevelopmentAssessmentPage = (props: PageProps) => (
  <Page
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
  >
    <DevelopmentAssessment {...props} />
  </Page>
);
const PersonalityQuestionnairePage = (props: PageProps) => (
  <Page
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
  >
    <PersonalityQuestionnaire {...props} />
  </Page>
);
const ProfilePage = (props: PageProps) => (
  <Page
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
  >
    <Profile {...props} />
  </Page>
);
const AccountPage = (props: PageProps) => (
  <Page
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
  >
    <Account {...props} />
  </Page>
);
const SettingsPage = (props: PageProps) => (
  <Page
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
  >
    <Settings {...props} />
  </Page>
);
const NotificationsPage = (props: PageProps) => (
  <Page
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
  >
    <Notifications {...props} />
  </Page>
);
const NotificationsStackPage = (props: PageProps) => (
  <Page
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
  >
    <NotificationsStack {...props} />
  </Page>
);
const PaymentsPage = (props: PageProps) => (
  <Page
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
  >
    <Payments {...props} />
  </Page>
);
const UpdateAboutPage = (props: PageProps) => (
  <Page
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
  >
    <UpdateAbout {...props} />
  </Page>
);
const UpdateContactPage = (props: PageProps) => (
  <Page
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
  >
    <UpdateContact {...props} />
  </Page>
);
const UpdateExperiencePage = (props: PageProps) => (
  <Page
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
  >
    <UpdateExperience {...props} />
  </Page>
);
const UpdateEducationPage = (props: PageProps) => (
  <Page
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
  >
    <UpdateEducation {...props} />
  </Page>
);
const UpdateReferencesPage = (props: PageProps) => (
  <Page
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
  >
    <UpdateReferences {...props} />
  </Page>
);
const MatchingProcessPage = (props: PageProps) => (
  <Page
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
  >
    <MatchingProcess {...props} />
  </Page>
);
const InteractiveFeedbackHubPage = (props: PageProps) => (
  <Page
    isPublic
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
    themed={false}
  >
    <InteractiveFeedbackHub {...props} />
  </Page>
);
const ResourcesPage = (props: PageProps) => (
  <Page
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
  >
    <Resources {...props} />
  </Page>
);
const SharableReportPage = (props: PageProps) => (
  <Page
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
    isPublic
  >
    <SharableReport {...props} />
  </Page>
);
const ResetPasswordPage = (props: PageProps) => (
  <Page
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
    isPublic
  >
    <ResetPassword {...props} />
  </Page>
);
const ShareTheJoyPage = (props: PageProps) => (
  <Page
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
  >
    <ShareTheJoy {...props} />
  </Page>
);
const LiveApplicationsPage = (props: PageProps) => (
  <Page
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
  >
    <LiveApplications {...props} />
  </Page>
);
const HistoricApplicationsPage = (props: PageProps) => (
  <Page
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
  >
    <HistoricApplications {...props} />
  </Page>
);
const VirtualTeamDiscussionPage = (props: PageProps) => (
  <Page
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
  >
    <VirtualTeamDiscussion {...props} />
  </Page>
);
const RecordedInterviewPage = (props: PageProps) => (
  <Page
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
  >
    <RecordedInterview {...props} />
  </Page>
);
const PresentationActivityPage = (props: PageProps) => (
  <Page
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
  >
    <PresentationActivity {...props} />
  </Page>
);
const BusinessCaseActivityPage = (props: PageProps) => (
  <Page
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
  >
    <BusinessCaseActivity {...props} />
  </Page>
);
const VirtualTeamDiscussionActivityPage = (props: PageProps) => (
  <Page
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
  >
    <VirtualTeamDiscussionActivity {...props} />
  </Page>
);
const CandidateAutoLoginPage = (props: PageProps) => (
  <Page
    isPublic
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
  >
    <CandidateAutoLogin {...props} />
  </Page>
);
const AssessorAutoLoginPage = (props: PageProps) => (
  <Page
    isPublic
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
  >
    <AssessorAutoLogin {...props} />
  </Page>
);
const LinkedInAutoLoginPage = (props: PageProps) => (
  <Page
    isPublic
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
  >
    <LinkedInAutoLogin {...props} />
  </Page>
);
const RedirectLoginPage = (props: PageProps) => (
  <Page
    isPublic
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
  >
    <RedirectLogin {...props} />
  </Page>
);
const WidgetRedirectPage = (props: PageProps) => (
  <Page
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
    isPublic
  >
    <WidgetRedirect {...props} />
  </Page>
);
const HowJoyfulPage = (props: PageProps) => (
  <Page
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
  >
    <HowJoyful {...props} />
  </Page>
);
const DailyJoyTrackerPage = (props: PageProps) => (
  <Page
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
  >
    <DailyJoyTracker {...props} />
  </Page>
);
const QuizResultsPage = (props: PageProps) => (
  <Page
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
  >
    <QuizResults {...props} />
  </Page>
);
const SoftSkillsReflectionDiagnosticPage = (props: PageProps) => (
  <Page
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
  >
    <SoftSkillsReflectionDiagnostic {...props} />
  </Page>
);
const YourSoftSkillsStrengthsReflectionsPage = (props: PageProps) => (
  <Page
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
  >
    <YourSoftSkillsStrengthsReflections {...props} />
  </Page>
);
const MessagesPage = (props: PageProps) => (
  <Page
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
  >
    <Messages {...props} />
  </Page>
);

const LiveInterviewPage = (props: PageProps) => (
  <Page
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
  >
    <LiveInterview {...props} />
  </Page>
);
const JobInterviewPage = (props: PageProps) => (
  <Page
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
  >
    <JobInterview {...props} />
  </Page>
);
const VirtualMeetingPage = (props: PageProps) => (
  <Page
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
  >
    <VirtualMeeting {...props} />
  </Page>
);
const VirtualAssessmentsPage = (props: PageProps) => (
  <Page
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
  >
    <VirtualAssessments {...props} />
  </Page>
);
const SignOutIntegrationClientPage = (props: PageProps) => (
  <Page
    isPublic
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
  >
    <SignOutIntegrationClient {...props} />
  </Page>
);

const BookingsPage = (props: PageProps) => (
  <Page
    navType={props.navType}
    footerType={props.footerType}
    navData={props.navData}
  >
    <Bookings {...props} />
  </Page>
);

// function usePrevious(value: string) {
//   const ref = useRef('');
//   useEffect(() => {
//     ref.current = value;
//   });
//   return ref.current;
// }

const App: React.FC<RouteComponentProps> = ({ location }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [
    ,
    // userInfo,
    setUserInfo,
  ] = useState<any>(null);
  const dispatch = useDispatch();
  const cachedLanguageList = getItemFromLocalStorage('langList');
  const authStatus = useSelector(selectAuthStatus);
  // const featureFlags = useSelector(selectFeatureFlags);
  // const prevURL = usePrevious(window.location.href);

  const { t, ready } = useTranslation('App+Constants', { i18n });

  // const saveCultureCode = useCallback(
  //   (cultureCode: string) => {
  //     storeCultureCodeInLocalStorage(cultureCode);
  //     dispatch(setCultureCode(cultureCode));
  //     setBackEndLanguage(cultureCode);
  //   },
  //   [dispatch]
  // );

  const parseFeatureFlags = (data: FeatureFlag[]): NamedFeatureFlag[] => {
    const parsed = data.map((f: FeatureFlag) => {
      if (f.publicKey === '27a5c818-3cad-4279-972a-aea8cf6e7e4c') {
        return { ...f, feature: 'softSkillsCandidate' };
      }
      if (f.publicKey === '3d46fdf7-27fc-40b8-981e-e7b4e9472ac6') {
        return { ...f, feature: 'softSkills-v1' };
      }
      if (f.publicKey === '6890eb46-d731-47cd-a438-5209708fbae8') {
        return { ...f, feature: 'testingFeature' };
      }
      return { ...f, feature: 'featureNameNotSpecified' };
    });
    return parsed;
  };

  const campaignSignUpSuccessful = useSelector(selectCampaignSignUpSuccessful);

  const appThemeRef = useRef<any>(null);

  const setJobBoardClientName = async (clientName: string) => {
    localStorage.setItem('jobBoardClientName', clientName);
  };

  // Extract the client name from the domain to make the job board client-specific
  // Note that on staging, extractedClientName will be of the format "staging-clientName" whereas on production it'll just be the client name
  useEffect(() => {
    const URL = window.location.href;

    (async () => {
      const strippedURLScheme = URL.split('//')[1]; // Removes https:// or http://
      let extractedClientName = strippedURLScheme.split('.')[0]; // Remove 2nd level domain onwards

      if (extractedClientName.toLowerCase().includes('localhost:3000')) {
        extractedClientName = 'criterion-psycruit-findyourjoy-v1';
      }

      await setJobBoardClientName(extractedClientName);
    })();
  }, [authStatus]);

  //webskin setup
  useEffect(() => {
    setLoading(true);
    const URL = window.location.href;

    (async () => {
      // Endpoint to return a client webskin (if available) takes in the entire page URL
      const webskinResult = await getWebskin({
        // DomainURL: 'www.exampledomain.co.uk',
        DomainURL: URL,
      });

      if (
        webskinResult.response &&
        ((webskinResult.errors && webskinResult.errors.length === 0) ||
          webskinResult.errors === null)
      ) {
        window.sessionStorage.setItem(
          'webskin',
          webskinResult.response.skinName
        );

        const webskinTheme = webskinResult.response;

        appThemeRef.current = {
          //Site colours
          '--primaryButton-backgroundColour': webskinTheme.siteColours.find(
            (item: any) => item.code === 'SC_PrimaryButton_Background'
          )?.colourValue,
          '--secondaryButton-backgroundColour': webskinTheme.siteColours.find(
            (item: any) => item.code === 'SC_SecondaryButton_Background'
          )?.colourValue,
          '--tertiaryButton-backgroundColour': webskinTheme.siteColours.find(
            (item: any) => item.code === 'SC_TertiaryButton_Background'
          )?.colourValue,
          '--active-backgroundColour': webskinTheme.siteColours.find(
            (item: any) => item.code === 'SC_Active_Background'
          )?.colourValue,
          '--active-borderColour': webskinTheme.siteColours.find(
            (item: any) => item.code === 'SC_Active_Border'
          )?.colourValue,
          '--error-backgroundColour': webskinTheme.siteColours.find(
            (item: any) => item.code === 'SC_Error_Background'
          )?.colourValue,
          '--error-borderColour': webskinTheme.siteColours.find(
            (item: any) => item.code === 'SC_Error_Border'
          )?.colourValue,
          '--progressTracker-completeColour': webskinTheme.siteColours.find(
            (item: any) => item.code === 'SC_ProgressTracker_Complete'
          )?.colourValue,
          '--highlightedButton-backgroundColour': webskinTheme.siteColours.find(
            (item: any) => item.code === 'SC_HighlightedButton_Background'
          )?.colourValue,
          '--highlightedButton-borderColour': webskinTheme.siteColours.find(
            (item: any) => item.code === 'SC_HighlightedButton_Border'
          )?.colourValue,

          // Text colours
          '--generalText-Colour': webskinTheme.textColours.find(
            (item: any) => item.code === 'TC_General'
          )?.colourValue,
          '--activeText-textColour': webskinTheme.textColours.find(
            (item: any) => item.code === 'TC_Active'
          )?.colourValue,
          '--errorText-textColour': webskinTheme.textColours.find(
            (item: any) => item.code === 'TC_Error'
          )?.colourValue,
          '--primaryButton-textColour': webskinTheme.textColours.find(
            (item: any) => item.code === 'TC_PrimaryButton'
          )?.colourValue,
          '--secondaryButton-textColour': webskinTheme.textColours.find(
            (item: any) => item.code === 'TC_SecondaryButton'
          )?.colourValue,
          '--tertiaryButton-textColour': webskinTheme.textColours.find(
            (item: any) => item.code === 'TC_TertiaryButton'
          )?.colourValue,
          '--assessmentPage-textColour': webskinTheme.textColours.find(
            (item: any) => item.code === 'TC_AssessmentPage'
          )?.colourValue,
        };

        dispatch(setWebskin(webskinTheme));

        setLoading(false);
        // console.log('appThemeRef.current is ', appThemeRef.current);
      } else {
        window.sessionStorage.setItem('webskin', 'defaultTheme');
        setLoading(false);
      }
    })();
  }, [dispatch]);

  useEffect(() => {
    if (authStatus === 'signed_in') {
      (async () => {
        // Get user info details
        const userInfoResult = await AccountApi.getAccountInfo();

        if (userInfoResult && userInfoResult.data) {
          setUserInfo({
            username: userInfoResult.data.emailAddress,
            email: userInfoResult.data.emailAddress,
            firstname: userInfoResult.data.firstName,
            lastname: userInfoResult.data.lastName,
          });
        }

        dispatch(setCandidateName(userInfoResult.data.firstName));
      })();
    }
  }, [authStatus]);

  //checking if site is being iframed and redirect to public job board if domain is in the URL
  useEffect(() => {
    const inIframe = () => {
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    };
    dispatch(setInIframe(inIframe()));

    const isAppConstant = (extractedString: string) => {
      return (
        Object.values(AppPath).filter((value) =>
          value.includes(extractedString)
        ).length > 0
      );
    };

    // localStorage.setItem('jobBoardClientName', 'asda');

    if (
      window.location.href.indexOf('jobs-clevry') !== -1 ||
      window.location.href.indexOf('jobs.clevry') !== -1
    ) {
      let extractedClientName = null;
      if (window.location.href.indexOf('.co.uk/') !== -1) {
        extractedClientName = window.location.href.split('.co.uk/');
      } else if (window.location.href.indexOf('.com/') !== -1) {
        extractedClientName = window.location.href.split('.com/');
      }

      let parsedClientName = null;
      if (extractedClientName && Array.isArray(extractedClientName))
        parsedClientName =
          extractedClientName[1] && !isAppConstant(extractedClientName[1])
            ? extractedClientName[1].toLowerCase().replaceAll('-', '')
            : null;

      if (parsedClientName) {
        (async () => {
          await setJobBoardClientName(parsedClientName);
          navigate(AppPath.PUBLIC_BROWSE_OPPORTUNITIES);
        })();
      }
    }
  }, [dispatch]);

  //feature-flagging
  useEffect(() => {
    const applicationLinkToken = getQueryParam('jobtoken');
    if (applicationLinkToken) {
      dispatch(setApplicationLinkToken(applicationLinkToken));
    }

    const assessmentOnlyRedirect =
      window.location.pathname.includes('sign-up-page');

    if (assessmentOnlyRedirect) {
      window.localStorage.setItem('assessmentOnlyRedirect', 'true');
    }

    if (applicationLinkToken && !assessmentOnlyRedirect) {
      (async () => {
        const result = await decryptApplicationLinkPublic({
          encryptedToken: applicationLinkToken,
        });

        if (
          result.response &&
          ((result.errors && result.errors.length === 0) ||
            result.errors === null)
        ) {
          const id = result.response.id
            ? result.response.id
            : result.response.projectID;

          navigate('/public-job-description/' + id, {
            state: {
              redirectFromJobToken: true,
            },
          });
        } else {
          navigate('/not-found');
        }
      })();
    }

    if (authStatus === 'signed_in') {
      instance
        .get(endpoints.featureFlags.getAll)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.status === '1') {
              dispatch(
                setAllFeatureFlags({
                  allFeatureFlags: parseFeatureFlags(
                    res.data.details.listFeatures
                  ),
                })
              );
            }
          }
        })
        .catch((err) => {});
    }
  }, [authStatus, campaignSignUpSuccessful, dispatch]);

  useEffect(() => {
    registerUserLocale();
  }, []);

  //check if user is assessment only to keep settings on same session
  useEffect(() => {
    if (localStorage.getItem('assessmentOnlyCandidate') === 'true') {
      dispatch(setIsAssessmentOnlyCandidate(true));
    }
  }, []);

  const useLocalStorageLangList =
    useLocalStorageContentOnlyWithExpiryDate(cachedLanguageList);

  // Get language list to populate language dropdown
  useEffect(() => {
    if (useLocalStorageLangList) return;

    instance
      .get(languageListUrl)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status === '1') {
            const contentToCache = addExpiryDate({ list: res.data.details });
            storeInLocalStorage(contentToCache, 'langList');
          }
        }
      })
      .catch((err) => {});
  });

  const isAppVisible = useSelector(selectAppVisible);

  useCheckSignedIn();
  // If we sign out here, we should sign out in other open windows.
  useSignOutAcrossTabs();

  // At startup, we check to see if we should display a signin page or the content.
  // Return nothing, so that we don't flash a view of signin if unneccessary.
  if (!isAppVisible) return null;

  let themeType = 'defaultTheme';

  const currentWebskin = window.sessionStorage.getItem('webskin')
    ? window.sessionStorage.getItem('webskin')
    : 'defaultTheme';

  if (currentWebskin && currentWebskin !== '') {
    themeType = currentWebskin;
  }

  const appTheme = themes[`${themeType}`]
    ? ({
        '--color-one': themes[`${themeType}`][`one`],
        '--color-two': themes[`${themeType}`][`two`],
        '--color-three': themes[`${themeType}`][`three`],
        '--color-four': themes[`${themeType}`][`four`],
        '--color-five': themes[`${themeType}`][`five`],
        '--color-six': themes[`${themeType}`][`six`],
        '--color-seven': themes[`${themeType}`][`seven`],
        '--color-eight': themes[`${themeType}`][`eight`],
        '--color-nine': themes[`${themeType}`][`nine`],
        '--color-ten': themes[`${themeType}`][`ten`],
      } as React.CSSProperties)
    : '';

  if (loading) {
    return <Spinner />;
  }

  if (ready) {
    return (
      <ErrorBoundary fallback={<ErrorFallback />}>
        <Suspense fallback={<LoadingFallback />}>
          <div
            className={`App ${
              currentWebskin && currentWebskin !== 'defaultTheme'
                ? 'Webskin'
                : ''
            }`}
            style={appThemeRef.current ? appThemeRef.current : appTheme}
          >
            {/* {userInfo && (
              <ProductFruits
                workspaceCode="RfdRwVe1bLDE3W9M"
                language="en"
                user={userInfo}
                lifeCycle={'unmount'}
              />
            )} */}

            <ToastContainer
              position="bottom-center"
              className="ToastMessage"
              autoClose={5000}
              hideProgressBar={true}
              pauseOnHover={true}
              pauseOnFocusLoss={false}
              icon={false}
              transition={Slide}
              closeButton={() => <div className="ToastCloseButton"></div>}
            />

            <ModalRouter />

            <Router id="RouterContainer" primary={false}>
              {/* <CultureCodeContainer path={'/en-GB'}> */}
              <Redirect
                from={`${AppPath.JOY_AT_WORK}`}
                to={`${AppPath.HOME}`}
                noThrow
              />
              <TermsAndConditionsPage
                path={paths.tAndCs.url}
                navType={paths.tAndCs.navType}
                footerType={paths.tAndCs.footerType}
                navData={{
                  title: t(
                    'SSCandidate_App_Constants_Paths_T_And_Cs_Data_Title'
                  ),
                }}
              />
              <NotFoundPage
                default
                path={paths.notFound.url}
                navType={paths.notFound.navType}
                footerType={paths.notFound.footerType}
                navData={null}
              />
              {/* NOTE: Is this a duplicate of the TermsAndConditionsPage above without the necessary navData? */}
              <TermsAndConditionsPage
                path={`${paths.tAndCs.url}`}
                navType={paths.tAndCs.navType}
                footerType={paths.tAndCs.footerType}
                navData={null}
              />
              <PrivacyPolicyPage
                path={`${paths.privacyPolicy.url}`}
                navType={paths.privacyPolicy.navType}
                footerType={paths.privacyPolicy.footerType}
                navData={{
                  title: t(
                    'SSCandidate_App_Constants_Privacy_Policy_Data_Title'
                  ),
                }}
              />
              <LandingPage
                path={`${paths.landing.url}`}
                navType={paths.landing.navType}
                footerType={paths.landing.footerType}
                navData={null}
              />
              <HomePage
                path={`${paths.home.url}`}
                navType={paths.home.navType}
                footerType={paths.home.footerType}
                navData={null}
              />
              <CanidateSignUpLandingPage
                path={`${paths.canidateSignUpLanding.url}`}
                navType={paths.canidateSignUpLanding.navType}
                footerType={paths.canidateSignUpLanding.footerType}
                navData={null}
              />
              <CanidateSignUpFormPage
                path={`${paths.canidateSignUpForm.url}`}
                navType={paths.canidateSignUpForm.navType}
                footerType={paths.canidateSignUpForm.footerType}
                navData={null}
              />
              <AbilityPracticeTestPage
                path={`${paths.abilityPracticeTest.url}`}
                navType={paths.abilityPracticeTest.navType}
                footerType={paths.abilityPracticeTest.footerType}
                navData={paths.abilityPracticeTest.data}
              />
              <AbilityTestPage
                path={`${paths.abilityTest.url}`}
                navType={paths.abilityTest.navType}
                footerType={paths.abilityTest.footerType}
                navData={paths.abilityTest.data}
              />
              <SJTPage
                path={`${paths.sjt.url}`}
                navType={paths.sjt.navType}
                footerType={paths.sjt.footerType}
                navData={paths.sjt.data}
              />
              <BlendedTestPage
                path={`${paths.blendedTest.url}`}
                navType={paths.blendedTest.navType}
                footerType={paths.blendedTest.footerType}
                navData={paths.blendedTest.data}
              />
              <SignUpPage
                path={`${paths.signUp.url}`}
                navType={paths.signUp.navType}
                footerType={paths.signUp.footerType}
                navData={null}
              />
              <GenericSignUpPage
                path={`${paths.signUpPage.url}`}
                navType={paths.signUpPage.navType}
                footerType={paths.signUpPage.footerType}
                navData={null}
              />
              {/* <DashboardPage
                path={`${paths.dashboard.url}`}
                navType={paths.dashboard.navType}
                footerType={paths.dashboard.footerType}
                navData={null}
              /> */}
              <MessagesPage
                path={paths.messages.url}
                navType={paths.messages.navType}
                footerType={paths.messages.footerType}
                navData={null}
              />
              <FindYourJoyPage
                path={`${paths.findYourJoy.url}`}
                navType={paths.findYourJoy.navType}
                footerType={paths.findYourJoy.footerType}
                navData={null}
              />
              <FindYourJoyCustomerPage
                path={`${paths.findYourJoyCustomer.url}`}
                navType={paths.findYourJoyCustomer.navType}
                footerType={paths.findYourJoyCustomer.footerType}
                navData={{
                  title: t(
                    'SSCandidate_App_Constants_Find_Your_Joy_Customer_Data_Title'
                  ),
                }}
              />
              <LearnYourStrengthsPage
                path={`${paths.learnYourStrengths.url}`}
                navType={paths.learnYourStrengths.navType}
                footerType={paths.learnYourStrengths.footerType}
                navData={null}
              />
              <MyJoyPage
                path={`${paths.myJoy.url}`}
                navType={paths.myJoy.navType}
                footerType={paths.myJoy.footerType}
                navData={null}
              />
              <MyGrowthPage
                path={`${paths.myGrowth.url}`}
                navType={paths.myGrowth.navType}
                footerType={paths.myGrowth.footerType}
                navData={null}
              />
              <MySoftSkillsPage
                path={`${paths.mySoftSkills.url}`}
                navType={paths.mySoftSkills.navType}
                footerType={paths.mySoftSkills.footerType}
                navData={null}
              />
              <CompareSoftSkillsPage
                path={`${paths.compareSoftSkills.url}`}
                navType={paths.compareSoftSkills.navType}
                footerType={paths.compareSoftSkills.footerType}
                navData={null}
              />
              <MySuccessPage
                path={`${paths.mySuccess.url}`}
                navType={paths.mySuccess.navType}
                footerType={paths.mySuccess.footerType}
                navData={null}
              />
              <MySuccessBlogPage
                path={`${paths.mySuccessBlog.url}`}
                navType={paths.mySuccessBlog.navType}
                footerType={paths.mySuccessBlog.footerType}
                navData={null}
              />
              <ScaleReportPage
                path={`${paths.scaleReport.url}`}
                navType={paths.scaleReport.navType}
                footerType={paths.scaleReport.footerType}
                navData={null}
              />
              <IFramedProngPage
                path={`${paths.iframedProng.url}`}
                navType={paths.iframedProng.navType}
                footerType={paths.iframedProng.footerType}
                navData={null}
              />
              <AbilityTestResultIframePage
                path={`${paths.abilityTestReport.url}`}
                navType={paths.abilityTestReport.navType}
                footerType={paths.abilityTestReport.footerType}
                navData={null}
              />
              <ScaleIframePage
                path={`${paths.scaleIframe.url}`}
                navType={paths.scaleIframe.navType}
                footerType={paths.scaleIframe.footerType}
                navData={null}
              />
              <TeamStrengthsGroupReportPage
                path={paths.teamStrengthsGroupReport.url}
                navType={paths.teamStrengthsGroupReport.navType}
                footerType={paths.teamStrengthsGroupReport.footerType}
                navData={null}
              />
              <JoyAtWorkProfileReportPage
                path={`${paths.joyAtWorkProfileReport.url}`}
                navType={paths.joyAtWorkProfileReport.navType}
                footerType={paths.joyAtWorkProfileReport.footerType}
                navData={null}
              />
              <ForgottenPasswordPage
                path={`${paths.forgottenPassword.url}`}
                navType={paths.forgottenPassword.navType}
                footerType={paths.forgottenPassword.footerType}
                navData={{
                  title: t(
                    'SSCandidate_App_Constants_Forgotten_Password_Data_Title'
                  ),
                }}
              />
              <JoyAtWorkPage
                path={`${paths.joyAtWork.url}`}
                navType={paths.joyAtWork.navType}
                footerType={paths.joyAtWork.footerType}
                navData={null}
              />
              <PersonalityQuestionnairePage
                path={`${paths.personalityQuestionnaire.url}`}
                navType={paths.personalityQuestionnaire.navType}
                footerType={paths.personalityQuestionnaire.footerType}
                navData={null}
              />
              <BuyPQTestsPage
                path={`${paths.buyPQTests.url}`}
                navType={paths.buyPQTests.navType}
                footerType={paths.buyPQTests.footerType}
                navData={null}
              />
              <MyDevelopmentPage
                path={`${paths.myDevelopment.url}`}
                navType={paths.myDevelopment.navType}
                footerType={paths.myDevelopment.footerType}
                navData={null}
              />
              <JobBoardPage
                path={`${paths.browseOpportunities.url}`}
                navType={paths.browseOpportunities.navType}
                footerType={paths.browseOpportunities.footerType}
                navData={null}
              />
              <PublicJobBoardPage
                path={paths.publicBrowseOpportunities.url}
                navType={paths.publicBrowseOpportunities.navType}
                footerType={paths.publicBrowseOpportunities.footerType}
                navData={null}
              />
              <JobDescriptionPage
                path={paths.jobDescription.url}
                navType={paths.jobDescription.navType}
                footerType={paths.myDevelopment.footerType}
                navData={{
                  title: t(
                    'SSCandidate_App_Constants_Paths_Job_Description_Data_Title'
                  ),
                }}
              />
              <JobDescriptionPage
                path={paths.opportunityDescription.url}
                navType={paths.jobDescription.navType}
                footerType={paths.myDevelopment.footerType}
                navData={{
                  title: t(
                    'SSCandidate_App_Constants_Paths_Job_Description_Data_Title'
                  ),
                }}
              />
              <PublicJobDescriptionPage
                path={paths.publicJobDescription.url}
                navType={paths.publicJobDescription.navType}
                footerType={paths.myDevelopment.footerType}
                navData={{
                  title: t(
                    'SSCandidate_App_Constants_Paths_Job_Description_Data_Title'
                  ),
                }}
              />
              <PublicJobDescriptionPage
                path={paths.publicOpportunityDescription.url}
                navType={paths.publicJobDescription.navType}
                footerType={paths.myDevelopment.footerType}
                navData={{
                  title: t(
                    'SSCandidate_App_Constants_Paths_Job_Description_Data_Title'
                  ),
                }}
              />

              <BusinessCaseActivityPage
                path={paths.businessCaseActivity.url}
                navType={paths.businessCaseActivity.navType}
                footerType={paths.businessCaseActivity.footerType}
                navData={{
                  title: t(
                    'SSCandidate_App_Constants_Paths_Business_Case_Activity_Title'
                  ),
                  activityType: 'BUSINESS_CASE_ACTIVITY',
                }}
              />

              <VirtualTeamDiscussionActivityPage
                path={paths.virtualTeamDiscussionActivity.url}
                navType={paths.virtualTeamDiscussionActivity.navType}
                footerType={paths.virtualTeamDiscussionActivity.footerType}
                navData={{
                  title: t(
                    'SSCandidate_App_Constants_Paths_Virtual_Team_Discussion_Activity_Title'
                  ),
                  activityType: 'VIRTUAL_TEAM_DISCUSSION',
                }}
              />

              <BookingsPage
                path={paths.bookings.url}
                navType={paths.bookings.navType}
                footerType={paths.bookings.footerType}
                navData={null}
              />

              <LiveInterviewPage
                path={paths.liveInterview.url}
                navType={paths.liveInterview.navType}
                footerType={paths.liveInterview.footerType}
                navData={{
                  title: t(
                    'SSCandidate_App_Constants_Paths_Live_Interview_Title'
                  ),
                  activityType: 'LIVE_INTERVIEW',
                }}
              />

              <JobInterviewPage
                path={paths.jobInterview.url}
                navType={paths.jobInterview.navType}
                footerType={paths.jobInterview.footerType}
                navData={{
                  title: t(
                    'SSCandidate_App_Constants_Paths_Job_Interview_Title'
                  ),
                }}
              />

              <VirtualMeetingPage
                path={paths.virtualMeeting.url}
                navType={paths.virtualMeeting.navType}
                footerType={paths.virtualMeeting.footerType}
                navData={{
                  title: t(
                    'SSCandidate_App_Constants_Paths_Virtual_Meeting_Title'
                  ),
                  activityType: 'VIRTUAL_MEETING',
                }}
              />

              <VirtualAssessmentsPage
                path={paths.virtualAssessment.url}
                navType={paths.virtualAssessment.navType}
                footerType={paths.virtualAssessment.footerType}
                navData={{
                  title: 'Virtual Assessments',
                  activityType: 'VIRTUAL_ASSESSMENT',
                }}
              />

              <ViewApplicationPage
                path={paths.viewApplication.url}
                navType={paths.viewApplication.navType}
                footerType={paths.viewApplication.footerType}
                navData={null}
              />
              <JobApplicationPage
                path={paths.jobApplication.url}
                navType={paths.jobApplication.navType}
                footerType={paths.jobApplication.footerType}
                navData={null}
              />
              <AssessmentsOverviewPage
                path={paths.assessmentsOverview.url}
                navType={paths.assessmentsOverview.navType}
                footerType={paths.assessmentsOverview.footerType}
                navData={null}
              />
              <AssessmentsPracticePage
                path={paths.assessmentsPractice.url}
                navType={paths.assessmentsPractice.navType}
                footerType={paths.assessmentsPractice.footerType}
                navData={null}
              />
              <AssessmentsPracticeRedirectPage
                path={paths.assessmentsPracticeRedirect.url}
                navType={paths.assessmentsPracticeRedirect.navType}
                footerType={paths.assessmentsPracticeRedirect.footerType}
                navData={null}
              />
              <AssessmentsPracticeTestPage
                path={paths.assessmentsPracticeTest.url}
                navType={paths.assessmentsPracticeTest.navType}
                footerType={paths.assessmentsPracticeTest.footerType}
                navData={null}
              />
              <AssessmentsBrowserCheckerPage
                path={paths.assessmentsBrowserChecker.url}
                navType={paths.assessmentsBrowserChecker.navType}
                footerType={paths.assessmentsBrowserChecker.footerType}
                navData={null}
              />
              <JobApplicationsOverviewPage
                path={paths.jobApplicationsOverview.url}
                navType={paths.jobApplicationsOverview.navType}
                footerType={paths.jobApplicationsOverview.footerType}
                navData={null}
              />
              <MatchingProfilePage
                path={paths.matchingProfile.url}
                navType={paths.matchingProfile.navType}
                footerType={paths.matchingProfile.footerType}
                navData={null}
              />
              <MatchingProfileSectionPage
                path={paths.matchingProfileSection.url}
                navType={paths.matchingProfileSection.navType}
                footerType={paths.matchingProfileSection.footerType}
                navData={null}
              />
              <MatchingProfileOverviewPage
                path={paths.matchingProfileOverview.url}
                navType={paths.matchingProfileOverview.navType}
                footerType={paths.matchingProfileOverview.footerType}
                navData={null}
              />
              <DevelopmentAssessmentPage
                path={`${paths.developmentAssessment.url}`}
                navType={paths.developmentAssessment.navType}
                footerType={paths.developmentAssessment.footerType}
                navData={null}
              />
              <ProfilePage
                path={`${paths.profile.url}`}
                navType={paths.profile.navType}
                footerType={paths.profile.footerType}
                navData={null}
              />
              <AccountPage
                path={`${paths.account.url}`}
                navType={paths.account.navType}
                footerType={paths.account.footerType}
                navData={null}
              />
              <SettingsPage
                path={`${paths.settings.url}`}
                navType={paths.settings.navType}
                footerType={paths.settings.footerType}
                navData={null}
              />
              <NotificationsPage
                path={`${paths.notifications.url}`}
                navType={paths.notifications.navType}
                footerType={paths.notifications.footerType}
                navData={null}
              />
              <NotificationsStackPage
                path={`${paths.notificationsStack.url}`}
                navType={paths.notificationsStack.navType}
                footerType={paths.notificationsStack.footerType}
                navData={null}
              />
              <PaymentsPage
                path={`${paths.payments.url}`}
                navType={paths.payments.navType}
                footerType={paths.payments.footerType}
                navData={null}
              />
              <UpdateAboutPage
                path={`${paths.updateAbout.url}`}
                navType={paths.updateAbout.navType}
                footerType={paths.updateAbout.footerType}
                navData={null}
              />
              <UpdateContactPage
                path={`${paths.updateContact.url}`}
                navType={paths.updateContact.navType}
                footerType={paths.updateContact.footerType}
                navData={null}
              />
              <UpdateExperiencePage
                path={`${paths.updateExperience.url}`}
                navType={paths.updateExperience.navType}
                footerType={paths.updateExperience.footerType}
                navData={null}
              />
              <UpdateEducationPage
                path={`${paths.updateEducation.url}`}
                navType={paths.updateEducation.navType}
                footerType={paths.updateEducation.footerType}
                navData={null}
              />
              <UpdateReferencesPage
                path={`${paths.updateReferences.url}`}
                navType={paths.updateReferences.navType}
                footerType={paths.updateReferences.footerType}
                navData={null}
              />
              <MatchingProcessPage
                path={`${paths.matchingProcess.url}`}
                navType={paths.matchingProcess.navType}
                footerType={paths.matchingProcess.footerType}
                navData={{
                  title: 'Matching process',
                  activityType: 'MATCHING_PROCESS',
                }}
              />
              <InteractiveFeedbackHubPage
                path={`${paths.interactiveFeedback.url}`}
                navType={
                  authStatus === 'signed_out' ? 'NONE' : 'APPLY_PSYCRUIT'
                }
                footerType={paths.interactiveFeedback.footerType}
                navData={null}
              />
              <ResourcesPage
                path={`${paths.resources.url}`}
                navType={paths.resources.navType}
                footerType={paths.resources.footerType}
                navData={null}
              />
              <SharableReportPage
                path={`${paths.sharableReport.url}`}
                navType={paths.sharableReport.navType}
                footerType={paths.sharableReport.footerType}
                navData={null}
              />
              <ResetPasswordPage
                path={`${paths.resetPassword.url}`}
                navType={paths.resetPassword.navType}
                footerType={paths.resetPassword.footerType}
                navData={null}
              />
              <ShareTheJoyPage
                path={`${paths.shareTheJoy.url}`}
                navType={paths.shareTheJoy.navType}
                footerType={paths.shareTheJoy.footerType}
                navData={null}
              />
              <LiveApplicationsPage
                path={`${paths.liveApplications.url}`}
                navType={paths.liveApplications.navType}
                footerType={paths.liveApplications.footerType}
                navData={null}
              />
              <HistoricApplicationsPage
                path={`${paths.historicApplications.url}`}
                navType={paths.historicApplications.navType}
                footerType={paths.historicApplications.footerType}
                navData={null}
              />
              <VirtualTeamDiscussionPage
                path={paths.virtualTeamDiscussion.url}
                navType={paths.virtualTeamDiscussion.navType}
                footerType={paths.virtualTeamDiscussion.footerType}
                navData={null}
              />
              <RecordedInterviewPage
                path={paths.recordedInterview.url}
                navType={paths.recordedInterview.navType}
                footerType={paths.recordedInterview.footerType}
                navData={{
                  title: t(
                    'SSCandidate_App_Constants_Recorded_Interview_Data_Title'
                  ),
                  activityType: 'RECORDED_INTERVIEW',
                }}
              />
              <PresentationActivityPage
                path={paths.presentationActivity.url}
                navType={paths.presentationActivity.navType}
                footerType={paths.presentationActivity.footerType}
                navData={{
                  title: t(
                    'SSCandidate_App_Constants_Presentation_Activity_Data_Title'
                  ),
                  activityType: 'PRESENTATION_ACTIVITY',
                }}
              />
              <CandidateAutoLoginPage
                path={paths.candidateAutoLogin.url}
                navType={paths.candidateAutoLogin.navType}
                footerType={paths.candidateAutoLogin.footerType}
                navData={null}
              />
              <AssessorAutoLoginPage
                path={paths.assessorAutoLogin.url}
                navType={paths.assessorAutoLogin.navType}
                footerType={paths.assessorAutoLogin.footerType}
                navData={null}
              />
              <LinkedInAutoLoginPage
                path={paths.linkedinAutoLogin.url}
                navType={paths.linkedinAutoLogin.navType}
                footerType={paths.linkedinAutoLogin.footerType}
                navData={null}
              />
              <RedirectLoginPage
                path={paths.redirectLogin.url}
                navType={paths.redirectLogin.navType}
                footerType={paths.redirectLogin.footerType}
                navData={null}
              />
              <WidgetRedirectPage
                path={paths.widgetRedirect.url}
                navType={paths.widgetRedirect.navType}
                footerType={paths.widgetRedirect.footerType}
                navData={null}
              />
              <HowJoyfulPage
                path={paths.howJoyful.url}
                navType={paths.howJoyful.navType}
                footerType={paths.howJoyful.footerType}
                navData={null}
              />
              <DailyJoyTrackerPage
                path={paths.dailyJoyTracker.url}
                navType={paths.dailyJoyTracker.navType}
                footerType={paths.dailyJoyTracker.footerType}
                navData={null}
              />
              <QuizResultsPage
                path={paths.quizResults.url}
                navType={paths.quizResults.navType}
                footerType={paths.quizResults.footerType}
                navData={null}
              />
              <SoftSkillsReflectionDiagnosticPage
                path={paths.softSkillsReflectionDiagnostic.url}
                navType={paths.softSkillsReflectionDiagnostic.navType}
                footerType={paths.softSkillsReflectionDiagnostic.footerType}
                navData={null}
              />
              <YourSoftSkillsStrengthsReflectionsPage
                path={paths.yourSoftSkillsStrengthsReflections.url}
                navType={paths.yourSoftSkillsStrengthsReflections.navType}
                footerType={paths.yourSoftSkillsStrengthsReflections.footerType}
                navData={null}
              />
              <SignOutIntegrationClientPage
                path={paths.signOut.url}
                navType={paths.signOut.navType}
                footerType={paths.signOut.footerType}
                navData={null}
              />

              <Redirect from={`${AppPath.SIGN_IN}`} to={`${AppPath.ROOT}`} />
              {/* </CultureCodeContainer> */}
            </Router>
            <LeftPanelRouter />
            <RightPanelRouter />
            <div id="ModalPortal" />
          </div>
        </Suspense>
      </ErrorBoundary>
    );
  } else {
    return null;
  }
};

export default App;
