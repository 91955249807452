import React from 'react';
import { MyCapabilitiesScoreMaps } from '../hooks/useGetMyCapabilitiesDetails';

export const InteractiveFeedbackScoreIcon = ({ score }: { score: number }) => {
  return (
    <div
      style={{
        ...(score
          ? {
              borderColor:
                MyCapabilitiesScoreMaps[
                  score as keyof typeof MyCapabilitiesScoreMaps
                ].borderColor,
            }
          : {}),
        ...(score
          ? {
              color:
                MyCapabilitiesScoreMaps[
                  score as keyof typeof MyCapabilitiesScoreMaps
                ].color,
            }
          : {}),
      }}
      className="InteractiveFeedbackCard__ScoreIcon"
    >
      {score}
    </div>
  );
};
