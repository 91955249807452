import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import MatchingProcessRadioCard from '../../MatchingProcessRadioCard';
import MP_Skills_JobType_01 from 'assets/apply.clevry/MP_Skills_Job-type_01.png';
import MP_Skills_JobType_02 from 'assets/apply.clevry/MP_Skills_Job-type_02.png';
import MP_Skills_JobType_03 from 'assets/apply.clevry/MP_Skills_Job-type_03.png';
import MP_Skills_JobType_04 from 'assets/apply.clevry/MP_Skills_Job-type_04.png';
import {
  getHowLikeToWorkDetails,
  parseHowLikeToWorkDetails,
  parseHowLikeToWorkPayload,
  submitHowLikeToWorkPayload,
} from '../../MatcingProcessApi';
import { Spinner } from 'components/spinner/Spinner';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';
import { useDispatch, useSelector } from 'react-redux';
import {
  MatchingProcessState,
  selectMatchingProcess,
  setMatchingProcessDataSubmittedToggle,
  setMatchingProcessSubmitSuccess,
} from 'reducers/matchingProcess';
import { GAMatchingProcessLatest } from 'helpers/gaEvents';

type HowLikeToWorkPageProps = {
  outerSubmitForm: MutableRefObject<
    (() => Promise<void>) & (() => Promise<any>)
  >;
  setNextStepDisabled: Dispatch<SetStateAction<boolean>>;
  setGeneralError: Dispatch<SetStateAction<string | undefined>>;
};

type formikValues = {
  candidateWorkSchedules: any;
  selectedSchedules: string[];
};

const HowLikeToWorkPage: React.FC<HowLikeToWorkPageProps> = ({
  outerSubmitForm,
  setNextStepDisabled,
  setGeneralError,
}) => {
  const latestLocation = useRef('');

  if (
    window &&
    window.location &&
    window.location.href !== latestLocation.current
  ) {
    latestLocation.current = window.location.href;
    GAMatchingProcessLatest(window.location.href);
  }

  const { t } = useTranslation('Matching+Process', { i18n });

  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState<formikValues>({
    candidateWorkSchedules: [
      {
        candidateWorkSchedulesId: null,
        workSchedulesId: null,
      },
    ],
    selectedSchedules: [],
  });
  const [situationOptions, setSituationOptions] = useState<any>([]);
  const [testSelectionState, setTestSelectionState] = useState<any>([]);

  const validationSchema = Yup.object({
    selectedSchedules: Yup.array().min(1).required(),
  });

  const dispatch = useDispatch();

  const matchingProcessDetails: MatchingProcessState = useSelector(
    selectMatchingProcess
  );

  const handleSubmit = (values: typeof initialValues) => {
    const payload = parseHowLikeToWorkPayload(values);

    return (async () => {
      const result = await submitHowLikeToWorkPayload(payload);
      if (result.response) {
        setGeneralError('');

        dispatch(setMatchingProcessSubmitSuccess(true));

        dispatch(
          setMatchingProcessDataSubmittedToggle(
            !matchingProcessDetails.dataSubmittedToggle
          )
        );

        return true;
      } else {
        dispatch(setMatchingProcessSubmitSuccess(false));

        setGeneralError(t('SSCandidate_Matching_Process_General_Error'));
        return false;
      }
    })();
  };

  useEffect(() => {
    const fetchDetails = async () => {
      const result = await getHowLikeToWorkDetails();
      if (result.response) {
        const parsedCurrentSituationDetails = parseHowLikeToWorkDetails(
          result.response
        );

        if (parsedCurrentSituationDetails.candidateWorkSchedules)
          setInitialValues({
            ...initialValues,
            candidateWorkSchedules:
              parsedCurrentSituationDetails.candidateWorkSchedules,
            selectedSchedules:
              parsedCurrentSituationDetails.previousySelectedSchedules,
          });
        setSituationOptions(parsedCurrentSituationDetails.workSchedules);

        setLoading(false);
      } else {
        setLoading(false);
        setGeneralError(t('SSCandidate_Matching_Process_General_Error_Reload'));
      }
    };

    fetchDetails();
  }, []);

  if (loading) return <Spinner />;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
      validateOnMount
    >
      {({
        setFieldValue,
        values,
        errors,
        touched,
        setValues,
        submitForm,
        isValid,
      }) => {
        outerSubmitForm.current = submitForm;

        if (isValid) {
          setNextStepDisabled(false);
        } else {
          setNextStepDisabled(true);
        }

        const handleSelection = (newFieldValue: any) => {
          let fieldValues = values.selectedSchedules;

          if (values.selectedSchedules.length >= 3) {
            fieldValues = [];
            fieldValues.push(newFieldValue);
          } else if (!values.selectedSchedules.includes(newFieldValue)) {
            fieldValues.push(newFieldValue);
          } else {
            fieldValues = fieldValues.filter((el: any) => el !== newFieldValue);
          }
          setFieldValue('selectedSchedules', fieldValues);
        };

        const handleUpForAnything = () => {
          let fieldValues = values.selectedSchedules;

          if (values.selectedSchedules.length >= 3) {
            fieldValues = [];
            setFieldValue('selectedSchedules', fieldValues);
          } else {
            fieldValues = [];
            fieldValues.push(situationOptions[0].id.toString());
            fieldValues.push(situationOptions[1].id.toString());
            fieldValues.push(situationOptions[2].id.toString());
            setFieldValue('selectedSchedules', fieldValues);
          }
        };

        return (
          <>
            <Form className="HowLikeToWorkPage MatchingProcessForm MatchingProcessInnerPage">
              <div className="FormContainer">
                <div className="MatchingProcess__sectionHeader">
                  <div className="MatchingProcess__sectionHeading">
                    {' '}
                    {t('SSCandidate_Matching_Process_How_Like_To_Work_Heading')}
                  </div>
                  <div className="MatchingProcess__sectionSubheading">
                    {t(
                      'SSCandidate_Matching_Process_How_Like_To_Work_SubHeading1'
                    )}
                  </div>
                </div>

                <div className="HowLikeToWorkContainer">
                  <div
                    className={`CheckboxCard ${
                      values.selectedSchedules.length < 3 &&
                      values.selectedSchedules.includes(
                        situationOptions[0].id.toString()
                      )
                        ? 'Checked'
                        : ''
                    }`}
                    onClick={() =>
                      handleSelection(situationOptions[0].id.toString())
                    }
                  >
                    {/* <Field
                      type="checkbox"
                      name="candidateWorkSchedules"
                      value={situationOptions[0].id}
                      className="CardInputElement"
                    /> */}
                    <MatchingProcessRadioCard
                      image={MP_Skills_JobType_01}
                      text={situationOptions[0].name}
                      // subtext={t(
                      //   'SSCandidate_Matching_Process_Your_Situation_DefaultSubText'
                      // )}
                    />
                  </div>

                  <div
                    className={`CheckboxCard ${
                      values.selectedSchedules.length < 3 &&
                      values.selectedSchedules.includes(
                        situationOptions[1].id.toString()
                      )
                        ? 'Checked'
                        : ''
                    }`}
                    onClick={() =>
                      handleSelection(situationOptions[1].id.toString())
                    }
                  >
                    {/* <Field
                      type="checkbox"
                      name="candidateWorkSchedules"
                      value={situationOptions[1].id}
                      className="CardInputElement"
                    /> */}
                    <MatchingProcessRadioCard
                      image={MP_Skills_JobType_02}
                      text={situationOptions[1].name}
                      // subtext={t(
                      //   'SSCandidate_Matching_Process_Your_Situation_DefaultSubText'
                      // )}
                    />
                  </div>

                  <div
                    className={`CheckboxCard ${
                      values.selectedSchedules.length < 3 &&
                      values.selectedSchedules.includes(
                        situationOptions[2].id.toString()
                      )
                        ? 'Checked'
                        : ''
                    }`}
                    onClick={() =>
                      handleSelection(situationOptions[2].id.toString())
                    }
                  >
                    {/* <Field
                      type="checkbox"
                      name="candidateWorkSchedules"
                      value={situationOptions[2].id}
                      className="CardInputElement"
                    /> */}
                    <MatchingProcessRadioCard
                      image={MP_Skills_JobType_03}
                      text={situationOptions[2].name}
                      // subtext={t(
                      //   'SSCandidate_Matching_Process_Your_Situation_DefaultSubText'
                      // )}
                    />
                  </div>

                  <div
                    className={`CheckboxCard ${
                      values.selectedSchedules.length === 3 ? 'Checked' : ''
                    }`}
                    onClick={() => handleUpForAnything()}
                  >
                    {/* <Field
                      type="checkbox"
                      name="candidateWorkSchedules"
                      className="CardInputElement"
                    /> */}
                    <MatchingProcessRadioCard
                      image={MP_Skills_JobType_04}
                      text="Up for anything"
                      // subtext={t(
                      //   'SSCandidate_Matching_Process_Your_Situation_DefaultSubText'
                      // )}
                    />
                  </div>
                </div>
              </div>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

export default HowLikeToWorkPage;
