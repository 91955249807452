import React, { useState, useEffect } from 'react';
import Select, {
  components,
  InputProps,
  ControlProps,
  MenuProps,
} from 'react-select';
import Down_Chevron from '../../../../assets/apply.clevry/Icon_DropDown.png';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';
import { supportedLanguagesData } from 'components/pages/settings/Settings';

export type SelectOption = { value: string; label: string };

type SearchDropdownProps = {
  options: supportedLanguagesData[];
  value: supportedLanguagesData;
  isOpen: boolean;
  onChange: (value: any) => void;
  toggleDropdown: () => void;
};

type Props = SearchDropdownProps;

/* TODO(HC): Language axios endpoint to be hooked up */
const LanguageSearchDropdown: React.FC<Props> = ({
  options,
  value,
  isOpen,
  onChange,
  toggleDropdown,
}) => {
  const selectStyles = {
    control: (base: any) => ({
      ...base,
      backgroundColor: '#F9F9FB',

      '&:hover': {
        backgroundColor: '#E1E5E8',
      },
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#A5A5A5' : '#F9F9FB',
      color: '#000000',
    }),
  };

  const Dropdown = ({ children, isOpen, target, onClose }: any) => (
    <div className="DropdownLanguageToggleContainer">
      {target}
      {isOpen ? <ContainerMenu>{children}</ContainerMenu> : null}
    </div>
  );

  const ContainerMenu = (props: any) => {
    return <div className="RegionToggleSubMenu" {...props}></div>;
  };

  const Control = ({ children, ...props }: ControlProps<any, any>) => (
    <div className="CustomControl">
      <components.Control {...props} className="InputField">
        {children}
      </components.Control>
    </div>
  );

  const Input = ({ ...props }: InputProps) => (
    <div className="CustomRegionInput">
      <components.Input {...props} />
    </div>
  );

  const Menu = ({ ...props }: MenuProps<any, any>) => (
    <div className="CustomMenuListContainer">
      <div className="CustomMenuContainer">
        <components.Menu {...props} className="CustomMenu">
          {props.children}
        </components.Menu>
      </div>
    </div>
  );

  // useEffect(() => {
  //   setHasImage(value.image !== null);
  // }, []);

  return (
    <div className="FeedbackLanguageSettings">
      <Dropdown
        isOpen={isOpen}
        onClose={() => toggleDropdown()}
        target={
          <div
            className="LanguageSelectValueContainer"
            onClick={() => toggleDropdown()}
          >
            <div className="LangFlagContainer">
              <img className="LangFlag" src={value.image} alt="language-flag" />
            </div>
            <div className="LangText">{value.label}</div>
            <div className="DownChevronContainer">
              <img
                src={Down_Chevron}
                alt="Language Dropdown Chevron"
                className="DownChevron"
              />
            </div>
          </div>
        }
      >
        <Select
          isSearchable
          autoFocus={true}
          onBlur={(e) => {
            e.preventDefault();
            toggleDropdown();
          }}
          backspaceRemovesValue={false}
          controlShouldRenderValue={false}
          hideSelectedOptions={false}
          isClearable={false}
          menuIsOpen
          maxMenuHeight={125}
          onChange={onChange}
          options={options}
          formatOptionLabel={({
            value,
            label,
            image,
          }: supportedLanguagesData) => (
            <div className="CustomOption">
              <div className="LangFlagContainer">
                <img className="LangFlag" src={image} alt="language-flag" />
              </div>

              <div className="LangText">{label}</div>
            </div>
          )}
          placeholder=""
          tabSelectsValue={false}
          styles={selectStyles}
          value={value}
          components={{
            Control: (inputProps: ControlProps<any, any>) => (
              <Control {...inputProps} />
            ),
            Input: (inputProps: InputProps) => <Input {...inputProps} />,
            DropdownIndicator: null,
            Menu: (inputProps: MenuProps<any, any>) => <Menu {...inputProps} />,
          }}
        />
      </Dropdown>
    </div>
  );
};

export default LanguageSearchDropdown;
