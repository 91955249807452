import { FieldAttributes, useField } from 'formik';
import { capitalizeFirstLetter } from 'helpers/capitalizeFirstLetter';
import React, { useState } from 'react';
import Icon_Eye_Open from 'assets/apply.clevry/Icon_Form_Password.png';
import Icon_Eye_Closed from 'assets/apply.clevry/Icon_Form_Password_02.png';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';

// useField field object type has values that aren't in HTMLInputField props.
// We create an intersection type so we can spread them on our <input /> element.
type FormikInputField = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> &
  FieldAttributes<string>;

type TextInputProps = {
  label: string;
  labelLocation?: 'top' | 'left';
  optional?: boolean;
  containerClass?: string;
  includeErrorText?: boolean;
  icon?: string;
  placeholder?: string;
  autoComplete?: string;
  isPassword?: boolean;
  isNumber?: boolean;
  togglePasswordView?: boolean;
  disable?: boolean;
  readOnly?: boolean;
  maxLength?: number;
  customErrorText?: string;
  noTouch?: boolean;
  additionalClassName?: string;
} & FieldAttributes<{}>;

export const TextInput: React.FC<TextInputProps> = ({
  label,
  optional = false,
  labelLocation = 'top',
  containerClass,
  includeErrorText = true,
  icon,
  placeholder = '',
  autoComplete,
  isPassword,
  isNumber,
  togglePasswordView,
  disable,
  readOnly,
  maxLength,
  customErrorText,
  noTouch = false,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [togglePassword, setTogglePassword] = useState<boolean>(true);

  const { t } = useTranslation('Text+Input', { i18n });

  const [field, { error, touched }] = useField(props);
  const errorText = error && (touched || noTouch) ? error : '';
  const containerClassName = `TextInputContainer Label${capitalizeFirstLetter(
    labelLocation
  )} ${containerClass} ${!includeErrorText ? 'NoErrorText' : ''}`;
  const inputClassName = `TextInput ${props.className ? props.className : ''} ${
    errorText ? 'Error' : ''
  } ${icon ? 'WithIcon' : ''}`;

  const inputStyle = icon ? { backgroundImage: `url(${icon}` } : null;

  const labelStyle = `Label ${isFocused ? 'Focused' : ''} ${
    errorText && !isFocused ? 'Error' : ''
  }`;

  const onFocus = () => setIsFocused(true);
  const onBlur = () => setIsFocused(false);

  return (
    <div
      className={`${containerClassName} ${
        props.additionalClassName ? props.additionalClassName : ''
      }`}
    >
      <label className={labelStyle} htmlFor={field.name}>
        {label}{' '}
        {optional ? (
          <span className="OptionalLabel">
            - {t('SSCandidate_Text_Input_Optional')}
          </span>
        ) : null}
      </label>
      <div className="InputErrorContainer">
        <input
          {...(field as FormikInputField)}
          className={inputClassName}
          style={inputStyle}
          placeholder={placeholder}
          onFocus={onFocus}
          onBlur={onBlur}
          autoComplete={autoComplete ? autoComplete : 'off'}
          type={
            isPassword && togglePassword
              ? 'password'
              : isNumber
              ? 'number'
              : 'text'
          }
          disabled={disable && disable}
          readOnly={readOnly}
          onChange={props.onChange ? props.onChange : field.onChange}
          maxLength={maxLength}
        />
        {isPassword && togglePasswordView ? (
          <img
            src={togglePassword ? Icon_Eye_Open : Icon_Eye_Closed}
            alt="toggle password"
            className="TogglePassword"
            onClick={() => {
              setTogglePassword(!togglePassword);
            }}
          />
        ) : null}
        {includeErrorText ? (
          <p className="ErrorText">
            {customErrorText ? customErrorText : errorText}
          </p>
        ) : null}
      </div>
    </div>
  );
};
