import { FormSingleSelectField } from '@avamae/formbuilder';
import { Button } from 'components/button/Button';
import { Spinner } from 'components/spinner/Spinner';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { closeModal } from 'reducers/modal';
import { getReportLanguageOptions } from '../InteractiveFeedbackApi';
import { AxiosRequestConfig } from 'axios';
import instance from 'api';
import { endpoints } from 'config';
import { extractFileName } from 'helpers/extractFileName';
import { ToastType, useToast } from 'hooks/useToast';
import { useSelector } from 'react-redux';
import { selectRedirectValues } from 'reducers/auth';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';
import { selectAssessmentId } from 'reducers/virtualAssessments';

export type InteractiveFeedbackDownloadReportModalData = {
  reportCode: string;
  assessmentId?: number;
  isCandidateReport?: boolean;
};

type InitialValues = { languageId: number | null };

export const InteractiveFeedbackDownloadReportModal = ({
  reportCode,
  assessmentId,
  isCandidateReport,
}: InteractiveFeedbackDownloadReportModalData) => {
  const dispatch = useDispatch();
  const [languageOptions, setLanguageOptions] =
    useState<{ label: string; value: number }[]>();
  const [reportDownloading, setReportDownloading] = useState(false);

  // Set assessment id
  const redirectValues = useSelector(selectRedirectValues);
  const myHistoryAssessmentId = useSelector(selectAssessmentId);
  let externalAssessmentId: number | null = null;
  if (redirectValues && redirectValues.length > 0) {
    externalAssessmentId = redirectValues.find(
      ({ label, value }) => label === 'AssessmentID'
    )?.value;
  }
  if (externalAssessmentId === null && myHistoryAssessmentId) {
    externalAssessmentId = myHistoryAssessmentId;
  }
  const finalAssessmentId = assessmentId ?? externalAssessmentId;

  const { t } = useTranslation('Interactive+Feedback', { i18n });

  const handleSuccess = useToast({
    message: t('SSCandidate_Interactive_Feedback_Report_Downloaded'),
  });
  const handleError = useToast({
    message: t('SSCandidate_Interactive_Feedback_Error_Downloading'),
    type: ToastType.ERROR,
  });

  useEffect(() => {
    if (!isCandidateReport || finalAssessmentId) {
      (async () => {
        const params = finalAssessmentId
          ? { assessmentId: finalAssessmentId }
          : {};
        const result = await getReportLanguageOptions(params);
        if (result.response && result.response.reportLanguageOptions) {
          setLanguageOptions(
            result.response.reportLanguageOptions.map(
              ({ languageId, languageName }) => ({
                label: languageName,
                value: languageId,
              })
            )
          );
        } else {
        }
      })();
    }
  }, []);

  const initialValues: InitialValues = { languageId: null };

  const validationSchema = Yup.object({
    ...(!isCandidateReport || finalAssessmentId
      ? {
          languageId: Yup.number()
            .required(t('SSCandidate_Interactive_Feedback_Select_Language'))
            .typeError(t('SSCandidate_Interactive_Feedback_Select_Language')),
        }
      : {}),
  });

  const handleSuccessfulDownload = (response: any) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.style.display = 'none';
    link.setAttribute(
      'download',
      extractFileName(response.headers['content-disposition'])
    );
    if (typeof link.download === 'undefined') {
      //safari workaround
      link.setAttribute('target', '_blank');
    }
    document.body.appendChild(link);
    link.click();
  };

  const handleDownloadReportWithoutId = (reportCode: string) => {
    setReportDownloading(true);
    const config: AxiosRequestConfig = {
      params: {
        reportCode,
      },
      responseType: 'blob',
      timeout: 240000,
    };
    instance
      .get(endpoints.myGrowth.downloadReport, config)
      .then((response: any) => {
        handleSuccessfulDownload(response);
        handleSuccess();
        handleCloseModal();
        setReportDownloading(false);
      })
      .catch((err) => {
        handleError();
        setReportDownloading(false);
      });
  };

  const handleDownloadReport = (payload: {
    assessmentId?: number | undefined;
    languageId: number;
    reportCode: string;
  }) => {
    setReportDownloading(true);
    const config: AxiosRequestConfig = {
      responseType: 'blob',
      timeout: 240000,
    };
    instance
      .post(endpoints.interactiveFeedback.downloadReport, payload, config)
      .then((response: any) => {
        handleSuccessfulDownload(response);
        handleSuccess();
        handleCloseModal();
        setReportDownloading(false);
      })
      .catch((err) => {
        handleError();
        setReportDownloading(false);
      });
  };

  const handleSubmit = ({ languageId }: InitialValues) => {
    const payload = {
      languageId: languageId as number,
      reportCode,
      ...(finalAssessmentId ? { assessmentId: finalAssessmentId } : {}),
    };
    if (!isCandidateReport || finalAssessmentId) {
      handleDownloadReport(payload);
    } else {
      handleDownloadReportWithoutId(payload.reportCode);
    }
  };

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  return (
    <div className="InteractiveFeedbackDownloadReportModal">
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        <Form className="FullWidth">
          {!languageOptions && (!isCandidateReport || finalAssessmentId) ? (
            <Spinner />
          ) : languageOptions && (!isCandidateReport || finalAssessmentId) ? (
            <div className="Content">
              <FormSingleSelectField
                label={t('SSCandidate_Interactive_Feedback_Select_Language')}
                fieldName="languageId"
                options={languageOptions}
              />
            </div>
          ) : null}

          <div className="ModalFooter">
            <Button
              priority="tertiary"
              type="button"
              onClick={handleCloseModal}
            >
              {t('SSCandidate_Interactive_Feedback_Cancel')}
            </Button>
            {reportDownloading ? (
              <Spinner />
            ) : (
              <Button priority="primary" type="submit">
                {t('SSCandidate_Interactive_Feedback_DownloadReport')}
              </Button>
            )}
          </div>
        </Form>
      </Formik>
    </div>
  );
};
