import { JobBoardFilters } from 'components/pages/jobBoard/JobBoardFilters';
import { LeftPanelFilters } from 'components/pages/jobBoard/LeftPanelFilters';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectLeftPanelState } from 'reducers/leftPanel';
import { LeftPanel } from './LeftPanel';
import { LeftPanelSignIn } from './LeftPanelSignIn';

const LeftPanelRouter = () => {
  const leftPanelType = useSelector(selectLeftPanelState);
  const [panel, setPanel] = useState<any>(null);
  React.useEffect(() => {
    switch (leftPanelType.panelType) {
      case 'JOB_BOARD_SIGN_IN':
        setPanel(<LeftPanelSignIn />);
        break;
      case 'JOB_BOARD_FILTERS':
        setPanel(<LeftPanelFilters data={leftPanelType.data} />);
        break;
      default:
        setPanel(null);
        break;
    }
  }, [leftPanelType.panelType, leftPanelType.data]);

  return <>{panel ? <LeftPanel>{panel}</LeftPanel> : null}</>;
};

export { LeftPanelRouter };
