import { handleNavigate } from 'components/pageComponents/navBar/PublicNavBar';
import React from 'react';
import { useDispatch } from 'react-redux';

const RightPanelSignIn: React.FC<any> = ({ children }) => {
  const dispatch = useDispatch();
  return (
    <div>
      <div className="Btn LightBtn" onClick={handleNavigate('/sign-in')}>
        Sign in
      </div>
    </div>
  );
};

export { RightPanelSignIn };
