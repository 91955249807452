import { FormSingleSelectCustomField } from 'components/pages/jobApplication/CustomElementForm/CustomFormFields/FormSingleSelectCustomField';
import React from 'react';
import clearIcon from 'assets/apply.clevry/Icon_FAQ_02.png';
import { useField, useFormikContext } from 'formik';

type menuOptionsInner = {
  label: string;
  value: number;
}[];

type UltimateDreamJobPageRowProps = {
  rowNumber: number;
  menuOptions: { roleTitle: menuOptionsInner; roleLevel: menuOptionsInner };
};

const UltimateDreamJobPageRow = ({
  rowNumber,
  menuOptions,
}: UltimateDreamJobPageRowProps) => {
  const useNumberForValue = true;
  const allowEmptyLabels = true;

  const formik = useFormikContext<keyof string>();

  const handleClearRow = () => {
    formik.setFieldValue(`RoleTitle${rowNumber}`, undefined);
    formik.setFieldValue(`RoleLevel${rowNumber}`, undefined);
  };

  return (
    <div className="MatchingProcessPageRow">
      <FormSingleSelectCustomField
        fieldName={`RoleTitle${rowNumber}`}
        label="Job title"
        options={menuOptions.roleTitle}
        useNumberForValue={useNumberForValue}
        allowEmptyLabels={true}
      />
      <FormSingleSelectCustomField
        fieldName={`RoleLevel${rowNumber}`}
        label="Role level"
        options={menuOptions.roleLevel}
        useNumberForValue={useNumberForValue}
        allowEmptyLabels={true}
      />

      <div className="ClearRowButton" onClick={handleClearRow}>
        <img src={clearIcon} alt="clear row" />
      </div>
    </div>
  );
};

export default UltimateDreamJobPageRow;
