import { LineProgressIndicator } from 'components/lineProgressIndicator/LineProgressIndicator';
import { GACompletedQuizSelectionStep } from 'helpers/gaEvents';
import React, { useContext } from 'react';
import { FindYourJoyQuizContext } from './FindYourJoy';
import {
  FindYourJoyContext,
  FindYourJoyQuestionAnswer,
  FindYourJoyQuizStep,
} from './models';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';

export const FindYourJoySelectImages: React.FC = () => {
  const { t } = useTranslation('Find+Your+Joy+Select+Images', { i18n });

  const quizContext = useContext(FindYourJoyQuizContext) as FindYourJoyContext;

  const currentQuestionIndex = quizContext.answers.length;

  const currentQuestion =
    quizContext.answers.length < quizContext.quiz.questions.length
      ? quizContext.quiz.questions[currentQuestionIndex]
      : null;

  const handleImageSelect = (id: number) => {
    if (currentQuestion) {
      const newAnswer: FindYourJoyQuestionAnswer = {
        answerId: id,
        questionId: currentQuestion.id,
      };
      const newQuizAnswers = [...quizContext.answers, newAnswer];
      quizContext.setAnswers(newQuizAnswers);
      GACompletedQuizSelectionStep(quizContext.answers.length + 1);
    }
    // setAnswers won't append answer to current state until after the handler
    // has run, so +1 in the equality check to account for that.
    if (quizContext.answers.length + 1 === quizContext.quiz.questions.length) {
      quizContext.setCurrentQuizStep(FindYourJoyQuizStep.ORDER);
    }
  };

  return (
    <div className="FindYourJoySelectImages">
      {currentQuestion && (
        <>
          <div className="HeadingContainer">
            <div className="ProgressIndicatorContainer">
              <LineProgressIndicator
                currentStep={quizContext.answers.length + 1}
                // +1 to include the ordering stage.
                steps={quizContext.quiz.questions.length + 1}
              />
            </div>
          </div>
          <h1 className="Title">
            {t(
              'SSCandidate_Find_Your_Joy_Select_Images_Find_Your_Joy_Sorting_Question_Prompt'
            )}
          </h1>
          <h2 className="Prompt">
            {t(
              'SSCandidate_Find_Your_Joy_Select_Images_Find_Your_Joy_Sorting_Question_Sub_Text'
            )}
          </h2>
          <div className="AnswersContainer">
            {currentQuestion.answers.map((option) => (
              <img
                key={option.id}
                className="Image"
                src={option.imageUrl1}
                alt={t(
                  'SSCandidate_Find_Your_Joy_Select_Images_Alt_Answer_Img'
                )}
                onClick={() => {
                  handleImageSelect(option.id);
                }}
              />
            ))}
            <div className="AnswerOr BreakColorTheme">
              <span>{t('SSCandidate_Find_Your_Joy_Select_Images_Or')}</span>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
