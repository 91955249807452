import { TextInput } from 'components/formFields/TextInput';
import { FormSingleSelectCustomField } from 'components/pages/jobApplication/CustomElementForm/CustomFormFields/FormSingleSelectCustomField';
import React, { useEffect, useState } from 'react';
import { MatchingProcessDeleteIcon } from '../../MatchingProcessDeleteIcon';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';
import {
  formikValues,
  MenuOption,
  MenuOptions,
} from './WhatHaveYouStudiedPage';

type menuOptionsInner = {
  label: string;
  value: any;
}[];

type RowValues = {
  educationLevelID: number | null;
  educationSubjectsID: number | null;
  yearAwarded: number | null;
};

type WhatHaveYouStudiedPageRowProps = {
  rowNumber: number;
  remove: <T>(index: number) => T | undefined;
  menuOptions: {
    educationSubjects: menuOptionsInner;
    educationLevels: menuOptionsInner;
  };
  values: formikValues;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate: boolean | undefined
  ) => void;
  highSchoolDiplomaIDData: MenuOption | null;
  generalHighSchoolStudiesIDData: MenuOption | null;
};

const WhatHaveYouStudiedPageRow = ({
  rowNumber,
  remove,
  menuOptions,
  values,
  setFieldValue,
  highSchoolDiplomaIDData,
  generalHighSchoolStudiesIDData,
}: WhatHaveYouStudiedPageRowProps) => {
  const { t } = useTranslation('Matching+Process', { i18n });

  const [parsedMenuOptions, setParsedMenuOptions] =
    useState<MenuOptions>(menuOptions);

  useEffect(() => {
    setParsedMenuOptions(menuOptions);
  }, [menuOptions]);

  const parseMenuOptions = (rowValues: RowValues) => {
    // If general high school studies is selected in the subject dropdown, filter menu options for education levels just to high school diploma
    if (
      rowValues.educationSubjectsID &&
      highSchoolDiplomaIDData &&
      highSchoolDiplomaIDData.label &&
      highSchoolDiplomaIDData.value &&
      generalHighSchoolStudiesIDData &&
      generalHighSchoolStudiesIDData.label &&
      generalHighSchoolStudiesIDData.value &&
      rowValues.educationSubjectsID === generalHighSchoolStudiesIDData.value
    ) {
      setParsedMenuOptions({
        ...menuOptions,
        educationLevels: [
          {
            label: highSchoolDiplomaIDData.label,
            value: highSchoolDiplomaIDData.value,
          },
        ],
      });
    } else if (
      // If general high school studies is not selected in the subject dropdown, filter menu options for education levels to not include high school diploma
      rowValues.educationSubjectsID &&
      highSchoolDiplomaIDData &&
      highSchoolDiplomaIDData.label &&
      highSchoolDiplomaIDData.value &&
      generalHighSchoolStudiesIDData &&
      generalHighSchoolStudiesIDData.label &&
      generalHighSchoolStudiesIDData.value &&
      rowValues.educationSubjectsID !== generalHighSchoolStudiesIDData.value
    ) {
      let tempmenuOptions = { ...menuOptions };
      tempmenuOptions.educationLevels = tempmenuOptions.educationLevels.filter(
        (item) => item.value !== highSchoolDiplomaIDData.value
      );

      setParsedMenuOptions({ ...tempmenuOptions });
    } else {
      setParsedMenuOptions({ ...menuOptions });
    }
  };

  const filterEducationData = () => {
    if (values) {
      const tempRowValues = values.educationLevels[rowNumber];

      if (
        highSchoolDiplomaIDData &&
        highSchoolDiplomaIDData.label &&
        highSchoolDiplomaIDData.value &&
        generalHighSchoolStudiesIDData &&
        generalHighSchoolStudiesIDData.label &&
        generalHighSchoolStudiesIDData.value
      ) {
        if (
          tempRowValues.educationSubjectsID &&
          tempRowValues.educationSubjectsID ===
            generalHighSchoolStudiesIDData.value &&
          tempRowValues.educationLevelID !== highSchoolDiplomaIDData.value
        ) {
          // Auto set Education Level to High School Diploma when the Subject: General High School Studies is selected
          setFieldValue(
            `educationLevels.[${rowNumber}].educationLevelID`,
            highSchoolDiplomaIDData.value,
            true
          );
        }
      }
      parseMenuOptions(tempRowValues);
    }
  };

  useEffect(() => {
    filterEducationData();
  }, [
    values,
    highSchoolDiplomaIDData,
    generalHighSchoolStudiesIDData,
    menuOptions,
  ]);

  return (
    <div className="MatchingProcessPageRow">
      <FormSingleSelectCustomField
        fieldName={`educationLevels.${rowNumber}.educationSubjectsID`}
        label={t('SSCandidate_Matching_Process_Field_Of_Study_Label')}
        options={parsedMenuOptions.educationSubjects}
        useNumberForValue={true}
        showError={false}
        allowEmptyLabels={true}
      />
      <FormSingleSelectCustomField
        fieldName={`educationLevels.${rowNumber}.educationLevelID`}
        label={t('SSCandidate_Matching_Process_Education_Level_Label')}
        options={parsedMenuOptions.educationLevels}
        useNumberForValue={true}
        showError={false}
        allowEmptyLabels={true}
      />
      <TextInput
        name={`educationLevels.${rowNumber}.yearAwarded`}
        label={t('SSCandidate_Matching_Process_Year_Label')}
        maxLength={4}
        isNumber
        noTouch
        additionalClassName="WhatHaveYouStudiedYear"
      />
      <MatchingProcessDeleteIcon
        onClick={() => {
          remove(rowNumber);
        }}
      />
    </div>
  );
};

export default WhatHaveYouStudiedPageRow;
