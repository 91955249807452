import { Button } from 'components/button/Button';
import React from 'react';
import { NavigationFooterData } from 'reducers/footer';

export const NavigationFooter: React.FC<NavigationFooterData> = ({
  primaryButtonCopy,
  primaryButtonHandler,
  primaryButtonIsActive,
}) => {
  return (
    <div className="NavigationFooter WidthContent">
      <Button
        priority="primary"
        disabled={!primaryButtonIsActive}
        onClick={primaryButtonHandler}
      >
        {primaryButtonCopy}
      </Button>
    </div>
  );
};
