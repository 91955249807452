import axios from 'api';
import { AxiosRequestConfig } from 'axios';

type Error = {
  errorType: string;
  fieldName: string;
  messageCode: string;
};

type ApiReturn<T = any> = {
  response: T | null;
  errors: Error[] | null;
};

export const get = async (url: string, payload: any): Promise<ApiReturn> => {
  try {
    const config: AxiosRequestConfig = {
      params: {
        ...payload,
      },
    };
    const res = await axios.get(url, config);
    if (res && res.data && res.data.status === '1') {
      if (res.data.details) {
        return {
          response: res.data.details,
          errors: null,
        };
      } else {
        return {
          response: res.data,
          errors: null,
        };
      }
    } else {
      return {
        response: null,
        errors: res.data.errors,
      };
    }
  } catch (err: any) {
    return {
      response: null,
      errors: err,
    };
  }
};

export const post = async (url: string, payload: any): Promise<ApiReturn> => {
  try {
    const res = await axios.post(url, payload);

    if (res && res.data && res.data.status === '1') {
      if (res.data.details) {
        return {
          response: res.data.details,
          errors: null,
        };
      } else {
        return {
          response: res.data,
          errors: null,
        };
      }
    } else {
      return {
        response: null,
        errors: res.data.errors,
      };
    }
  } catch (err: any) {
    if (err.response) {
      return {
        response: null,
        errors: err.response,
      };
    }
    return {
      response: null,
      errors: err,
    };
  }
};

export const newPost = async (
  url: string,
  payload: any
): Promise<ApiReturn> => {
  try {
    const res = await axios.post(url, payload);

    if (res && res.data && res.data.status === '1') {
      if (res.data.details) {
        return {
          response: res.data.details,
          errors: null,
        };
      } else {
        return {
          response: res.data,
          errors: null,
        };
      }
    } else {
      return {
        response: null,
        errors: res.data.errors,
      };
    }
  } catch (err: any) {
    if (err.response) {
      console.log('ERR.response: ', err.response);

      return {
        response: null,
        errors: err.response.data.errors,
      };
    } else {
      console.log('ERR: ', err);
      return { response: null, errors: err };
    }
  }
};
