import { navigate, RouteComponentProps } from '@reach/router';
import React, { useEffect, useRef, useState } from 'react';
import queryString from 'query-string';
import { Spinner } from 'components/spinner/Spinner';
import { AppPath, paths } from 'appConstants';
import { useDispatch, useSelector } from 'react-redux';
import ExplorePage from './interactiveFeedbackPages/ExplorePage';
import { InteractiveFeedbackNavigationButtons } from './components/InteractiveFeedbackNavigationButtons';
import { InteractiveFeedbackMyDevelopment } from './pages/InteractiveFeedbackMyDevelopment';
import { InteractiveFeedbackMyCapabilities } from './pages/InteractiveFeedbackMyCapabilities';
import { InteractiveFeedbackMyStrengths } from './pages/InteractiveFeedbackMyStrengths';
import { InteractiveFeedbackMyTeamStrengths } from './pages/InteractiveFeedbackMyTeamStrengths';
import { InteractiveFeedbackMyLeadershipStrengths } from './pages/InteractiveFeedbackMyLeadershipStrengths';
import { InteractiveFeedbackMySalesStyle } from './pages/InteractiveFeedbackMySalesStyle';
import IF_MyStrengths_Big from 'assets/apply.clevry/IF_MyStrengths_Big.jpg';
import IF_MyDevelopment_Big from 'assets/apply.clevry/IF_MyDevelopment_Big.jpg';
import IF_MyCapabilities_Big from 'assets/apply.clevry/IF_MyCapabilities_Big.jpg';
import IF_MyTeamStrengths_Big from 'assets/apply.clevry/IF_MyTeamStrengths_Big.jpg';
import IF_MyLeadershipStrengths_Big from 'assets/apply.clevry/IF_MyLeadershipStrengths_Big.jpg';
import IF_MySalesStyle_Big from 'assets/apply.clevry/IF_MySalesStyle_Big.jpg';
import { useWindowDimensions } from 'helpers/useGetWindowDimensions';
import {
  checkResponse,
  selectAuthStatus,
  selectCampaignSignUpSuccessful,
  selectInviteType,
  setInviteType,
  setRedirectValues,
  signOut,
} from 'reducers/auth';
import { getInteractiveFeedbackAccessToken } from './InteractiveFeedbackApi';
import { saveTokenResource } from 'helpers/auth';
import { addSeconds } from 'date-fns';
import LanguageSearchDropdownContainer from './LanguageSearchDropdown/LanguageSearchDropdownContainer';
import useMedia from 'use-media';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';
import { useGetMyStrengthsDetails } from './hooks/useGetMyStrengthsDetails';
import { useGetMyTeamStrengthsDetails } from './hooks/useGetMyTeamStrengthsDetails';
import { useGetMyDevelopmentDetails } from './hooks/useGetMyDevelopmentDetails';
import { useGetMyCapabilitiesDetails } from './hooks/useGetMyCapabilitiesDetails';
import { useGetMyLeadershipStrengthsDetails } from './hooks/useGetMyLeadershipStrengthsDetails';
import { useGetMySalesStyleDetails } from './hooks/useGetMySalesStyleDetails';
import { getQueryParam } from 'helpers/getQueryParam';
import { setAssessmentId } from 'reducers/virtualAssessments';
import { changeNavType } from 'reducers/navbar';
import { Button } from 'components/button/Button';
import { setOpenSignUpForm } from 'reducers/landingPage';
import { PublicModal } from 'components/publicModal/PublicModal';
import { SignUpForm } from '../signUp/SignUpForm';
import jwt_decode from 'jwt-decode';
import CanidateSignUpForm from '../candidateSignUpForm/canidateSignUpForm';
import { selectFeatureFlags } from 'reducers/featureFlags';

interface Token {
  sub: string;
  jti: string;
  iat: string;
  Scopes: string;
  AssessmentId: string;
  nbf: number;
  exp: number;
}

const swapObjectKeysAndValues = (object: { [key: string]: string }) => {
  var ret: { [key: string]: string } = {};
  for (let key in object) {
    ret[object[key]] = key;
  }
  return ret;
};

// Order of pages is important and determines the actual order of the pages
const pages = [
  'EXPLORE',
  'MY_STRENGTHS',
  'MY_DEVELOPMENT',
  'MY_CAPABILITIES',
  'MY_TEAM_STRENGTHS',
  'MY_LEADERSHIP_STRENGTHS',
  'MY_SALES_STYLE',
];

// Convert query params to page names
const queryParamsToPageNames: { [key: string]: string } = {
  explore: 'EXPLORE',
  'my-strengths': 'MY_STRENGTHS',
  'my-development': 'MY_DEVELOPMENT',
  'my-capabilities': 'MY_CAPABILITIES',
  'my-team-strengths': 'MY_TEAM_STRENGTHS',
  'my-leadership-strengths': 'MY_LEADERSHIP_STRENGTHS',
  'my-sales-style': 'MY_SALES_STYLE',
};

// Convert page names to query params
const pageNamesToQueryParams = swapObjectKeysAndValues(queryParamsToPageNames);

const InteractiveFeedbackHubPage: React.FC<RouteComponentProps> = ({
  location,
}) => {
  const previousAssessmentId = useRef<string>();
  const dispatch = useDispatch();
  const assessmentId = getQueryParam('assessmentId');
  if (assessmentId && assessmentId !== previousAssessmentId.current) {
    dispatch(setAssessmentId(parseInt(assessmentId)));
    previousAssessmentId.current = assessmentId;
  }
  const authStatus = useSelector(selectAuthStatus);
  const inviteType = useSelector(selectInviteType);
  const campaignSignUpSuccessful = useSelector(selectCampaignSignUpSuccessful);

  const { t } = useTranslation('Interactive+Feedback', { i18n });

  const hasSignedInWithToken = useRef<boolean>(false);
  const redirectURLToken = useRef<string>('');

  const [innerRowName, setInnerRowName] = useState<string | null>(null);
  const [animateDismount, setAnimateDismount] = useState(false);

  const [showSignUpForm, setShowSignUpForm] = useState(false);
  const [campaignAssessmentId, setCampaignAssessmentId] = useState<
    number | null
  >(null);

  const [strengthsData] = useGetMyStrengthsDetails();
  const [developmentData] = useGetMyDevelopmentDetails();
  const [capabilitiesData] = useGetMyCapabilitiesDetails(
    setInnerRowName,
    setAnimateDismount
  );
  const [teamStrengthsData] = useGetMyTeamStrengthsDetails();
  const [leadershipStrengthsData] = useGetMyLeadershipStrengthsDetails();
  const [salesStyleData] = useGetMySalesStyleDetails();

  //get candidate login token
  const search = window.location ? window.location.search : '';
  const parsed = queryString.parse(search);
  const candidateLoginToken = parsed.token ? (parsed.token as string) : '';

  redirectURLToken.current = candidateLoginToken;

  const pagesData = [
    null,
    strengthsData,
    developmentData,
    capabilitiesData,
    teamStrengthsData,
    leadershipStrengthsData,
    salesStyleData,
  ];

  useEffect(() => {
    return () => {
      dispatch(setAssessmentId(null));
    };
  }, [assessmentId]);

  useEffect(() => {
    const search = window.location ? window.location.search : '';
    const parsed = queryString.parse(search);
    const Token = parsed.token ? (parsed.token as string) : '';

    if (authStatus === 'signed_in' && Token && !hasSignedInWithToken.current) {
      dispatch(signOut());
    }

    if (authStatus === 'signed_out') {
      if (Token) {
        (async () => {
          const result = await getInteractiveFeedbackAccessToken({ Token });
          if (result.response && result.response.details) {
            const expiresIn = result.response.details.expiresIn;
            const refreshTokenExpires = addSeconds(
              new Date(),
              parseInt(expiresIn, 10)
            );
            const resourceToken = {
              accessToken: result.response.details.accessToken,
              refreshTokenExpires,
              refreshToken: '999999999999999999999999999999',
            };

            const parsedToken = jwt_decode(resourceToken.accessToken);
            const parsedAssessmentId = (parsedToken as Token).AssessmentId
              ? (parsedToken as Token).AssessmentId
              : null;

            if (parsedAssessmentId) {
              setCampaignAssessmentId(parseInt(parsedAssessmentId));
            }

            saveTokenResource(resourceToken);
            hasSignedInWithToken.current = true;
            dispatch(checkResponse(result.response));
            dispatch(setInviteType('LIMITED_ACCESS'));
          }
        })();
      } else {
        navigate(AppPath.ROOT);
      }
    }
  }, [authStatus]);

  useEffect(() => {
    const cleanup = () => {
      if (inviteType === 'LIMITED_ACCESS') {
        dispatch(setInviteType(''));
        window && window.localStorage.clear();
        dispatch(signOut());
      }
    };

    window.addEventListener('beforeunload', cleanup);

    return () => {
      window.removeEventListener('beforeunload', cleanup);
    };
  }, [inviteType]);

  const createInteractiveFeedbackURL = (pageName: string) => {
    const assessmentId = getQueryParam('assessmentId');

    const url = `${paths.interactiveFeedback.url}?page=${pageName}${
      assessmentId ? `&assessmentId=${assessmentId}` : ''
    }`;

    return url;
  };

  const INITIAL_PAGE = pages[0];
  const INITIAL_PAGE_NUMBER = 0;
  const INITIAL_PAGE_NAME = pageNamesToQueryParams[INITIAL_PAGE];
  const INITIAL_PAGE_URL = createInteractiveFeedbackURL(INITIAL_PAGE_NAME);
  const LAST_PAGE = pages[pages.length - 1];

  const [currentPageNumber, setCurrentPageNumber] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<string | null>(null);
  const [currentPageUrl, setCurrentPageUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const [settingsMobShow, setSettingsMobShow] = useState<boolean>(false);

  const isTabletOrBelow = useMedia('(max-width:1024px)');

  const outerSubmitForm = useRef<any>();

  const { width, height } = useWindowDimensions();

  const calculatePageDetails = () => {
    let page = INITIAL_PAGE;
    let pageNumber = INITIAL_PAGE_NUMBER;
    let pageName = INITIAL_PAGE_NAME;
    let pageURL = INITIAL_PAGE_URL;

    if (
      location &&
      location.search &&
      location.pathname &&
      location.pathname === paths.interactiveFeedback.url
    ) {
      const queryParams: { page?: string } = queryString.parse(location.search);

      if (queryParams.page && typeof queryParams.page === 'string') {
        const pageQueryParam = queryParams.page;

        if (
          typeof pageQueryParam === 'string' &&
          queryParamsToPageNames[pageQueryParam]
        ) {
          page = queryParamsToPageNames[pageQueryParam];
          const pageIndex = pages.indexOf(page);

          // equals -1 if cannot find index
          if (pageIndex !== -1) {
            pageNumber = pageIndex;

            pageName = pageQueryParam;

            pageURL = createInteractiveFeedbackURL(pageName);
          }
        }
      }

      if (location.search !== `?page=${pageName}`) {
        setCurrentPageUrl(pageURL);
        navigate(pageURL);
      }

      setCurrentPageNumber(pageNumber);

      setLoading(false);
    } else {
      navigate(createInteractiveFeedbackURL(pageName));
      setCurrentPageNumber(pageNumber);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (authStatus !== 'signed_out') {
      calculatePageDetails();
    }
  }, [authStatus]);

  useEffect(() => {
    if (pages.length - 1 >= currentPageNumber) {
      const newCurrentPage = pages[currentPageNumber];

      setCurrentPage(newCurrentPage);

      const newCurrentPageUrl = createInteractiveFeedbackURL(
        pageNamesToQueryParams[newCurrentPage]
      );

      setCurrentPageUrl(newCurrentPageUrl);

      if (
        location &&
        location.pathname &&
        location.pathname === paths.interactiveFeedback.url &&
        location.search !== `?page=${newCurrentPage}` &&
        authStatus !== 'signed_out' &&
        !campaignSignUpSuccessful
        // !localStorage.getItem('campaignSignUpSuccessful')
      ) {
        navigate(newCurrentPageUrl);
      }
    }

    // Cleanup function
    // return () => {};
  }, [currentPageNumber, authStatus]);

  useEffect(() => {
    if (
      location &&
      location.pathname &&
      location.pathname === paths.interactiveFeedback.url &&
      location.href &&
      location.href !== currentPageUrl &&
      authStatus !== 'signed_out' &&
      !campaignSignUpSuccessful
      // !localStorage.getItem('campaignSignUpSuccessful')
    ) {
      calculatePageDetails();
    }

    // Cleanup function
    // return () => {};
  }, [location?.search, location?.pathname, authStatus]);

  const doesPageHaveData = (newPageNumber: number) => {
    if (pagesData[newPageNumber]) {
      return true;
    } else return false;
  };

  const decrementPage = () => {
    if (currentPageNumber > 0) {
      const newPageNumber = currentPageNumber - 1;

      const hasPageData = doesPageHaveData(newPageNumber);
      if (hasPageData) {
        setCurrentPageNumber(newPageNumber);
        setCurrentPage(pages[newPageNumber]);
      } else {
        setCurrentPageNumber(0);
        setCurrentPage(pages[0]);
      }
    }
  };

  const incrementPage = () => {
    if (currentPageNumber < pages.length - 1) {
      const newPageNumber = currentPageNumber + 1;

      const hasPageData = doesPageHaveData(newPageNumber);

      if (hasPageData) {
        setCurrentPageNumber(newPageNumber);
        setCurrentPage(pages[newPageNumber]);
      } else {
        setCurrentPageNumber(0);
        setCurrentPage(pages[0]);
      }
    } else {
      setCurrentPageNumber(0);
      setCurrentPage(pages[0]);
    }
  };

  if (authStatus === 'signed_out') return <Spinner />;

  const initialPage = currentPageNumber === 0;

  // Page-specific items: navigation button text, background
  let leftNavigationButtonText = t('SSCandidate_Interactive_Feedback_Previous');
  let rightNavigationButtonText = t('SSCandidate_Interactive_Feedback_Next');
  let pageBackground = null;

  if (currentPage === 'MY_STRENGTHS') {
    leftNavigationButtonText = t('SSCandidate_Interactive_Feedback_Home');
    rightNavigationButtonText = developmentData
      ? t('SSCandidate_Interactive_Feedback_My_Development')
      : t('SSCandidate_Interactive_Feedback_Home');
    pageBackground = IF_MyStrengths_Big;
  }
  if (currentPage === 'MY_DEVELOPMENT') {
    leftNavigationButtonText = strengthsData
      ? t('SSCandidate_Interactive_Feedback_My_Strengths')
      : t('SSCandidate_Interactive_Feedback_Home');
    rightNavigationButtonText = capabilitiesData
      ? t('SSCandidate_Interactive_Feedback_My_Capabilities')
      : t('SSCandidate_Interactive_Feedback_Home');
    pageBackground = IF_MyDevelopment_Big;
  }
  if (currentPage === 'MY_CAPABILITIES') {
    leftNavigationButtonText = developmentData
      ? t('SSCandidate_Interactive_Feedback_My_Development')
      : t('SSCandidate_Interactive_Feedback_Home');
    rightNavigationButtonText = teamStrengthsData
      ? t('SSCandidate_Interactive_Feedback_My_Team_Strengths')
      : t('SSCandidate_Interactive_Feedback_Home');
    pageBackground = IF_MyCapabilities_Big;
  }
  if (currentPage === 'MY_TEAM_STRENGTHS') {
    leftNavigationButtonText = capabilitiesData
      ? t('SSCandidate_Interactive_Feedback_My_Capabilities')
      : t('SSCandidate_Interactive_Feedback_Home');
    rightNavigationButtonText = leadershipStrengthsData
      ? t('SSCandidate_Interactive_Feedback_My_Leadership_Strengths')
      : t('SSCandidate_Interactive_Feedback_Home');
    pageBackground = IF_MyTeamStrengths_Big;
  }
  if (currentPage === 'MY_LEADERSHIP_STRENGTHS') {
    leftNavigationButtonText = teamStrengthsData
      ? t('SSCandidate_Interactive_Feedback_My_Team_Strengths')
      : t('SSCandidate_Interactive_Feedback_Home');
    rightNavigationButtonText = salesStyleData
      ? t('SSCandidate_Interactive_Feedback_My_Sales_Style')
      : t('SSCandidate_Interactive_Feedback_Home');
    pageBackground = IF_MyLeadershipStrengths_Big;
  }
  if (currentPage === 'MY_SALES_STYLE') {
    leftNavigationButtonText = leadershipStrengthsData
      ? t('SSCandidate_Interactive_Feedback_My_Leadership_Strengths')
      : t('SSCandidate_Interactive_Feedback_Home');
    rightNavigationButtonText = t('SSCandidate_Interactive_Feedback_Home');
    pageBackground = IF_MySalesStyle_Big;
  }
  //

  if (loading) {
    return (
      <div className="InteractiveFeedbackOuter">
        <div
          className={`InteractiveFeedback PageContent WidthContent HeightContent ${
            initialPage ? 'InitialPage' : ''
          }
          ${currentPage === 'EXPLORE' ? 'ExplorePage' : ''}`}
        >
          <Spinner />
        </div>
      </div>
    );
  }

  return (
    <div
      className="InteractiveFeedbackOuter"
      style={
        currentPage !== 'EXPLORE' && width > 1200
          ? {
              backgroundImage: `linear-gradient(
                90deg,             
                rgba(255, 255, 255, 1) 0%,
                rgba(255, 255, 255, 1) 45%,
                rgba(255, 255, 255, 0.5) 60%,
                rgba(255, 255, 255, 0) 70%
                ), url(${pageBackground})`,
              backgroundRepeat: 'no-repeat',
            }
          : {}
      }
    >
      {inviteType === 'LIMITED_ACCESS' && (
        <>
          <div
            className={
              'SettingsContainer' +
              (settingsMobShow && isTabletOrBelow ? ' MobileView' : '')
            }
          >
            <LanguageSearchDropdownContainer />
            <div
              className="MobSettingsIcon"
              onClick={() => setSettingsMobShow(!settingsMobShow)}
            ></div>
          </div>

          <div className="SignUpButtonContainer">
            <Button
              priority="primary"
              isPublic
              onClick={() => setShowSignUpForm(true)}
            >
              {t('SSCandidate_Interactive_Feedback_Sign_Up')}
            </Button>
          </div>

          {showSignUpForm && campaignAssessmentId ? (
            <PublicModal
              handleClose={() => {
                setShowSignUpForm(false);
              }}
              className="FormModal"
            >
              <CanidateSignUpForm campaignAssessmentId={campaignAssessmentId} />
            </PublicModal>
          ) : null}
        </>
      )}
      <div
        className={`InteractiveFeedback PageContent WidthContent HeightContent ${
          initialPage ? 'InitialPage' : ''
        }
        ${currentPage === 'EXPLORE' ? 'ExplorePage' : ''}`}
      >
        {currentPage === 'EXPLORE' && (
          <ExplorePage
            pageList={pages}
            setCurrentPageNumber={setCurrentPageNumber}
            setCurrentPage={setCurrentPage}
            strengthsData={strengthsData}
            developmentData={developmentData}
            capabilitiesData={capabilitiesData}
            teamStrengthsData={teamStrengthsData}
            leadershipStrengthsData={leadershipStrengthsData}
            salesStyleData={salesStyleData}
          />
        )}
        {currentPage === 'MY_STRENGTHS' && (
          <InteractiveFeedbackMyStrengths 
            sectionData={strengthsData}
          />
        )}
        {currentPage === 'MY_DEVELOPMENT' && (
          <InteractiveFeedbackMyDevelopment 
            sectionData={developmentData}
          />
        )}
        {currentPage === 'MY_CAPABILITIES' && (
          <InteractiveFeedbackMyCapabilities 
            sectionData={capabilitiesData}
          />
        )}
        {currentPage === 'MY_TEAM_STRENGTHS' && (
          <InteractiveFeedbackMyTeamStrengths 
            sectionData={teamStrengthsData}
          />
        )}
        {currentPage === 'MY_LEADERSHIP_STRENGTHS' && (
          <InteractiveFeedbackMyLeadershipStrengths 
            sectionData={leadershipStrengthsData}
          />
        )}
        {currentPage === 'MY_SALES_STYLE' && (
          <InteractiveFeedbackMySalesStyle 
            sectionData={salesStyleData}
          />
        )}
      </div>

      {currentPage !== 'EXPLORE' && (
        <InteractiveFeedbackNavigationButtons
          leftNavigationButtonText={leftNavigationButtonText}
          rightNavigationButtonText={rightNavigationButtonText}
          incrementPage={incrementPage}
          decrementPage={decrementPage}
        />
      )}
    </div>
  );
};

export default InteractiveFeedbackHubPage;
