import React, { useState } from 'react';
import { useField, FieldAttributes, ErrorMessage } from 'formik';
import classNames from 'classnames';

const ErrorM: React.FC<{ name: string }> = (props) => (
  <ErrorMessage name={props.name}>
    {(msg) => <div className="ErrorLabel">{msg}</div>}
  </ErrorMessage>
);

function FieldInput<T>(
  props: FieldAttributes<T> & { placeholder: string; label: string }
) {
  const { placeholder, ...fieldProps } = props;

  const [isFocused, setIsFocused] = useState(false);

  const [field, { touched, error }] = useField(
    fieldProps as FieldAttributes<T>
  );
  const showError = touched && error;
  const errorText = error && touched ? error : '';

  const { className: existingClasses, ...rest } = props;
  const className = classNames(existingClasses, {
    ErrorInput: showError,
  });

  const errorInputClassName = `${errorText ? 'Error' : ''}`;
  const labelStyle = `${isFocused ? 'Focused' : ''} ${
    errorText && !isFocused ? 'Error' : ''
  }`;

  const onFocus = () => setIsFocused(true);
  const onBlur = () => setIsFocused(false);

  return (
    <React.Fragment>
      <div className="FormLabel">
        <label className={labelStyle}>{props.label}</label>
      </div>
      <div className="FormField">
        <input
          {...(field as any)}
          {...rest}
          className={`${className} ${errorInputClassName}`}
          placeholder={placeholder}
          onFocus={onFocus}
          onBlur={onBlur}
        />
        <ErrorM name={props.name} />
      </div>
    </React.Fragment>
  );
}

export default FieldInput;
