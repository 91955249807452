import { NavType } from './reducers/navbar';
import Img_MatchingQuiz_01 from 'assets/job-board-widget/Img_MatchingQuiz_01.jpg';
import Img_MatchingQuiz_02 from 'assets/job-board-widget/Img_MatchingQuiz_02.jpg';
import Img_ApplyNow_01 from 'assets/job-board-widget/Img_ApplyNow_01.jpg';
import Img_ApplyNow_02 from 'assets/job-board-widget/Img_ApplyNow_02.jpg';
import { FooterType } from 'reducers/footer';

type Path = {
  url: AppPath;
  navType: NavType;
  footerType: FooterType;
  data: any;
};

interface IndexedPaths {
  [key: string]: Path;
}

export const modals = {
  softSkills: {
    title: 'Matching quiz',
    questions: [
      {
        image: Img_MatchingQuiz_01,
        text: 'Take our quick job matching quiz to find your perfect role',
        buttonText: 'Start',
      },
      {
        image: Img_MatchingQuiz_02,
        text: 'Come back to the quiz later',
        buttonText: 'Skip',
      },
    ],
  },
  signIn: {
    title: 'Apply now',
    questions: [
      {
        image: Img_ApplyNow_01,
        text: 'I already have a password',
        buttonText: 'Sign in',
      },
      {
        image: Img_ApplyNow_02,
        text: 'This is my first time',
        buttonText: 'Sign up',
      },
    ],
  },
};

export enum AppPath {
  ROOT = '/',
  NOT_FOUND = '/not-found',
  HOME = '/home',
  SOFT_SKILLS_QUIZ = '/soft-skills-quiz',
  TERMS_AND_CONDITIONS = '/terms-and-conditions',
  PRIVACY_POLICY = '/privacy-policy',
  JOB_DESCRIPTION = '/job-description/:jobId',
  PUBLIC_JOB_DESCRIPTION = '/public-job-description/:jobId',
  PUBLIC_OPPORTUNITY_DESCRIPTION = '/public-opportunity-description/:opportunityId',
  OPPORTUNITY_DESCRIPTION = '/opportunity-description/:opportunityId',
  VIEW_APPLICATION = '/view-application/:assessmentId/:applicationType/:pageType',
  JOB_APPLICATION = '/job-application',
  SIGN_IN = '/sign-in',
  SIGN_UP = '/sign-up',
  SIGN_UP_PAGE = '/sign-up-page',
  CANDIDATE_SIGN_UP = '/signup-with-token',
  CANDIDATE_SIGN_UP_FORM = '/signup-with-token-form',
  FIND_YOUR_JOY = '/find-your-joy',
  FIND_YOUR_JOY_CUSTOMER = '/find-your-joy-quiz',
  ABILITY_PRACTICE = '/ability-practice',
  // ABILITY_TEST_VERBAL = '/ability-test/verbal',
  // ABILITY_TEST_NUMERICAL = '/ability-test/numerical',
  ABILITY_TEST = '/ability-test',
  SJT = '/situational-judgement-test',
  BLENDED_TEST = '/blended-test',
  FORGOTTEN_PASSWORD = '/forgotten-password',
  // DASHBOARD = '/dashboard',
  JOY_AT_WORK = '/joy-at-work',
  PERSONALITY_QUESTIONNAIRE = '/personality-questionnaire',
  IFRAMED_PRONG = '/application-stage',
  DEVELOPMENT_ASSESSMENT = '/development-assessment',
  BUY_PQ_TESTS = '/buy-pq-tests',
  HISTORIC_APPLICATIONS = '/historic-applications',
  BROWSE_OPPORTUNITIES = '/browse-opportunities',
  PUBLIC_BROWSE_OPPORTUNITIES = '/public-browse-opportunities',
  MESSAGES = '/messages',
  MY_DEVELOPMENT = '/my-development',
  PROFILE = '/profile',
  ACCOUNT = '/account',
  SETTINGS = '/settings',
  NOTIFICATIONS = '/notifications',
  NOTIFICATIONS_STACK = '/notifications-stack',
  PAYMENTS = '/payments',
  UPDATE_ABOUT = '/update-about',
  UPDATE_CONTACT = '/update-contact',
  UPDATE_EXPERIENCE = '/update-experience',
  UPDATE_EDUCATION = '/update-education',
  UPDATE_REFERENCES = '/update-references',
  VIRTUAL_TEAM_DISCUSSION = '/virtual-team-discussion',
  BUSINESS_CASE = '/busines-case',
  VIRTUAL_TEAM_DISCUSSION_ACTIVITY = '/virtual-team-discussion-activity',
  RECORDED_INTERVIEW = '/recorded-interview',
  PRESENTATION_ACTIVITY = '/presentation-activity',
  BUSINESS_CASE_ACTIVITY = '/business-case-activity',
  CANDIDATE_AUTO_LOGIN = '/candidate-auto-login',
  ASSESSOR_AUTO_LOGIN = '/assessor-auto-login',
  LINKEDIN_AUTO_LOGIN = '/candidate-linkedin-login/callback',
  REDIRECT_LOGIN = '/redirect-login',
  LEARN_YOUR_STRENGTHS = '/learn-your-strengths',
  MY_JOY = '/my-joy',
  MY_SOFT_SKILLS = '/my-soft-skills',
  MY_GROWTH = '/my-growth',
  MY_SUCCESS = '/my-success',
  MY_SUCCESS_BLOG = '/my-success-blog/:blogId/',
  SCALE_REPORT = '/scale-report',
  SCALE_IFRAME = '/scale-iframe',
  TEAM_STRENGTHS_GROUP_REPORT = '/team-strengths-group-report',
  JOY_AT_WORK_PROFILE_REPORT = '/joy-at-work-profile-report',
  ABILITY_TEST_RESULTS_PAGE = '/ability-test-report',
  MATCHING_PROCESS = '/matching-process',
  INTERACTIVE_FEEDBACK = '/interactive-feedback',
  RESOURCES = '/resources',
  SHARABLE_REPORT = '/sharable-report',
  RESET_PASSWORD = '/reset-password',
  SHARE_THE_JOY = '/share-the-joy',
  LIVE_APPLICATIONS = '/live-applications',
  MY_ASSESSMENTS = '/my-assessments',
  DAILY_JOY_TRACKER = '/daily-joy-tracker',
  WIDGET_REDIRECT = '/sign-up-redirect',
  BOOKINGS = '/live-applications/bookings',
  HOW_JOYFUL = '/how-joyful',
  QUIZ_RESULTS = '/quiz-results',
  SOFT_SKILLS_REFLECTION_DIAGNOSTIC = '/soft-skills-reflection-diagnostic',
  YOUR_SOFT_SKILLS_STRENGTHS_REFLECTIONS = '/your-soft-skills-strengths-reflections',
  LIVE_INTERVIEW = '/live-interview',
  JOB_INTERVIEW = '/job-interview',
  VIRTUAL_MEETING = '/virtual-meeting',
  VIRTUAL_ASSESSMENT = '/virtual-assessments',
  ASSESSMENTS = '/assessments',
  ASSESSMENTS_PRACTICE = '/assessments-practice',
  ASSESSMENTS_PRACTICE_REDIRECT = '/assessments-practice/:redirectAssessmentId/:projectId',
  ASSESSMENTS_PRACTICE_TEST = '/assessments-practice-test/:testType/',
  ASSESSMENTS_BROWSER_CHECKER = '/assessments-browser-checker',
  JOB_APPLICATIONS = '/job-applications',
  JOB_APPLICATIONS_LIVE = '/job-applications/live',
  JOB_APPLICATIONS_HISTORIC = '/job-applications/historic',
  MATCHING_PROFILE = '/matching-profile',
  MATCHING_PROFILE_OVERVIEW = '/matching-profile/overview',
  MATCHING_PROFILE_SECTION = '/matching-profile/:profileSection',
  COMPARE_SOFT_SKILLS = '/compare-soft-skills',
  SIGN_OUT_INTEGRATION_CLIENT = '/sign-out',
}

export const paths = {
  notFound: {
    url: AppPath.NOT_FOUND,
    // navType: NavType.NONE,
    // navType: NavType.CANDIDATE_SIGN_UP,
    navType: NavType.LOGO,
    // navType: NavType.TITLE_AND_BACK,
    // footerType: FooterType.NONE,
    // footerType: FooterType.COPYRIGHT_ONLY,
    footerType: FooterType.COPYRIGHT_ONLY_RIGHT,
    // footerType: FooterType.PUBLIC_ALL_ELEMENTS,
    // data: null,
  },
  softSkillsQuiz: {
    url: AppPath.SOFT_SKILLS_QUIZ,
    navType: NavType.TITLE_AND_BACK,
    footerType: FooterType.NONE,
  },
  learnYourStrengths: {
    url: AppPath.LEARN_YOUR_STRENGTHS,
    navType: NavType.APPLY_PSYCRUIT,
    footerType: FooterType.LOGGED_IN,
  },
  myJoy: {
    url: AppPath.MY_JOY,
    navType: NavType.APPLY_PSYCRUIT,
    footerType: FooterType.LOGGED_IN,
  },
  myGrowth: {
    url: AppPath.MY_GROWTH,
    navType: NavType.APPLY_PSYCRUIT,
    footerType: FooterType.LOGGED_IN,
  },
  mySoftSkills: {
    url: AppPath.MY_SOFT_SKILLS,
    navType: NavType.APPLY_PSYCRUIT,
    footerType: FooterType.LOGGED_IN,
  },
  mySuccess: {
    url: AppPath.MY_SUCCESS,
    navType: NavType.APPLY_PSYCRUIT,
    footerType: FooterType.LOGGED_IN,
  },
  mySuccessBlog: {
    url: AppPath.MY_SUCCESS_BLOG,
    navType: NavType.APPLY_PSYCRUIT,
    footerType: FooterType.LOGGED_IN,
  },
  scaleReport: {
    url: AppPath.SCALE_REPORT,
    navType: NavType.NONE,
    footerType: FooterType.NONE,
  },
  scaleIframe: {
    url: AppPath.SCALE_IFRAME,
    navType: NavType.NONE,
    footerType: FooterType.NONE,
  },
  abilityTestReport: {
    url: AppPath.ABILITY_TEST_RESULTS_PAGE,
    navType: NavType.NONE,
    footerType: FooterType.NONE,
  },
  teamStrengthsGroupReport: {
    url: AppPath.TEAM_STRENGTHS_GROUP_REPORT,
    navType: NavType.NONE,
    footerType: FooterType.NONE,
  },
  jobDescription: {
    url: AppPath.JOB_DESCRIPTION,
    navType: NavType.APPLY_PSYCRUIT,
    footerType: FooterType.LOGGED_IN,
  },
  publicJobDescription: {
    url: AppPath.PUBLIC_JOB_DESCRIPTION,
    navType: NavType.LOGIN,
    footerType: FooterType.PUBLIC_ALL_ELEMENTS,
  },
  opportunityDescription: {
    url: AppPath.OPPORTUNITY_DESCRIPTION,
    navType: NavType.APPLY_PSYCRUIT,
    footerType: FooterType.LOGGED_IN,
  },
  publicOpportunityDescription: {
    url: AppPath.PUBLIC_OPPORTUNITY_DESCRIPTION,
    navType: NavType.LOGIN,
    footerType: FooterType.PUBLIC_ALL_ELEMENTS,
  },
  joyAtWorkProfileReport: {
    url: AppPath.JOY_AT_WORK_PROFILE_REPORT,
    navType: NavType.NONE,
    footerType: FooterType.NONE,
  },
  tAndCs: {
    url: AppPath.TERMS_AND_CONDITIONS,
    navType: NavType.TITLE_AND_BACK,
    footerType: FooterType.PUBLIC_ALL_ELEMENTS,
  },
  privacyPolicy: {
    url: AppPath.PRIVACY_POLICY,
    navType: NavType.TITLE_AND_BACK,
    footerType: FooterType.PUBLIC_ALL_ELEMENTS,
  },
  landing: {
    url: AppPath.ROOT,
    navType: NavType.LOGIN,
    footerType: FooterType.PUBLIC_ALL_ELEMENTS,
  },
  home: {
    url: AppPath.HOME,
    navType: NavType.APPLY_PSYCRUIT,
    footerType: FooterType.LOGGED_IN,
  },
  canidateSignUpLanding: {
    url: AppPath.CANDIDATE_SIGN_UP,
    navType: NavType.CANDIDATE_SIGN_UP,
    footerType: FooterType.PUBLIC_ALL_ELEMENTS,
  },
  canidateSignUpForm: {
    url: AppPath.CANDIDATE_SIGN_UP_FORM,
    navType: NavType.CANDIDATE_SIGN_UP,
    footerType: FooterType.PUBLIC_ALL_ELEMENTS,
  },
  abilityPracticeTest: {
    url: AppPath.ABILITY_PRACTICE,
    navType: NavType.LOGIN,
    footerType: FooterType.PUBLIC_ALL_ELEMENTS,
    data: null,
  },
  abilityTest: {
    url: AppPath.ABILITY_TEST,
    navType: NavType.ABILITY_TEST,
    footerType: FooterType.NONE,
    data: null,
  },
  sjt: {
    url: AppPath.SJT,
    navType: NavType.SJT,
    footerType: FooterType.NONE,
    data: null,
  },
  blendedTest: {
    url: AppPath.BLENDED_TEST,
    navType: NavType.ASSESSMENT,
    footerType: FooterType.NONE,
    data: null,
  },
  signUp: {
    url: AppPath.SIGN_UP,
    navType: NavType.LOGIN,
    footerType: FooterType.PUBLIC_ALL_ELEMENTS,
  },
  signUpPage: {
    url: AppPath.SIGN_UP_PAGE,
    navType: NavType.LOGIN,
    footerType: FooterType.PUBLIC_ALL_ELEMENTS,
  },

  findYourJoy: {
    url: AppPath.FIND_YOUR_JOY,
    navType: NavType.LOGIN,
    footerType: FooterType.PUBLIC_ALL_ELEMENTS,
  },
  findYourJoyCustomer: {
    url: AppPath.FIND_YOUR_JOY_CUSTOMER,
    navType: NavType.TITLE_AND_BACK,
    footerType: FooterType.PUBLIC_ALL_ELEMENTS,
  },
  forgottenPassword: {
    url: AppPath.FORGOTTEN_PASSWORD,
    navType: NavType.TITLE_AND_BACK,
    footerType: FooterType.COPYRIGHT_ONLY,
  },
  // dashboard: {
  //   url: AppPath.DASHBOARD,
  //   navType: NavType.APPLY_PSYCRUIT,
  //   footerType: FooterType.LOGGED_IN,
  // },
  joyAtWork: {
    url: AppPath.JOY_AT_WORK,
    navType: NavType.APPLY_PSYCRUIT,
    footerType: FooterType.LOGGED_IN,
  },
  personalityQuestionnaire: {
    url: AppPath.PERSONALITY_QUESTIONNAIRE,
    navType: NavType.NONE,
    footerType: FooterType.NONE,
  },
  iframedProng: {
    url: AppPath.IFRAMED_PRONG,
    navType: NavType.NONE,
    footerType: FooterType.NONE,
  },
  developmentAssessment: {
    url: AppPath.DEVELOPMENT_ASSESSMENT,
    navType: NavType.NONE,
    footerType: FooterType.NONE,
  },
  buyPQTests: {
    url: AppPath.BUY_PQ_TESTS,
    navType: NavType.APPLY_PSYCRUIT,
    footerType: FooterType.LOGGED_IN,
  },
  messages: {
    url: AppPath.MESSAGES,
    navType: NavType.APPLY_PSYCRUIT,
    footerType: FooterType.NONE,
  },
  myDevelopment: {
    url: AppPath.MY_DEVELOPMENT,
    navType: NavType.APPLY_PSYCRUIT,
    footerType: FooterType.LOGGED_IN,
  },
  browseOpportunities: {
    url: AppPath.BROWSE_OPPORTUNITIES,
    navType: NavType.APPLY_PSYCRUIT,
    footerType: FooterType.LOGGED_IN,
  },
  publicBrowseOpportunities: {
    url: AppPath.PUBLIC_BROWSE_OPPORTUNITIES,
    navType: NavType.LOGIN,
    footerType: FooterType.PUBLIC_ALL_ELEMENTS,
  },
  profile: {
    url: AppPath.PROFILE,
    navType: NavType.APPLY_PSYCRUIT,
    footerType: FooterType.LOGGED_IN,
  },
  account: {
    url: AppPath.ACCOUNT,
    navType: NavType.APPLY_PSYCRUIT,
    footerType: FooterType.LOGGED_IN,
  },
  settings: {
    url: AppPath.SETTINGS,
    navType: NavType.APPLY_PSYCRUIT,
    footerType: FooterType.LOGGED_IN,
  },
  payments: {
    url: AppPath.PAYMENTS,
    navType: NavType.APPLY_PSYCRUIT,
    footerType: FooterType.LOGGED_IN,
  },
  notifications: {
    url: AppPath.NOTIFICATIONS,
    navType: NavType.APPLY_PSYCRUIT,
    footerType: FooterType.LOGGED_IN,
  },
  notificationsStack: {
    url: AppPath.NOTIFICATIONS_STACK,
    navType: NavType.APPLY_PSYCRUIT,
    footerType: FooterType.LOGGED_IN,
  },
  updateAbout: {
    url: AppPath.UPDATE_ABOUT,
    navType: NavType.APPLY_PSYCRUIT,
    footerType: FooterType.NONE,
  },
  updateContact: {
    url: AppPath.UPDATE_CONTACT,
    navType: NavType.APPLY_PSYCRUIT,
    footerType: FooterType.NONE,
  },
  updateExperience: {
    url: AppPath.UPDATE_EXPERIENCE,
    navType: NavType.APPLY_PSYCRUIT,
    footerType: FooterType.NONE,
  },
  updateEducation: {
    url: AppPath.UPDATE_EDUCATION,
    navType: NavType.APPLY_PSYCRUIT,
    footerType: FooterType.NONE,
  },
  updateReferences: {
    url: AppPath.UPDATE_REFERENCES,
    navType: NavType.APPLY_PSYCRUIT,
    footerType: FooterType.NONE,
  },
  matchingProcess: {
    url: AppPath.MATCHING_PROCESS,
    navType: NavType.ACTIVITY,
    footerType: FooterType.NONE,
  },
  interactiveFeedback: {
    url: AppPath.INTERACTIVE_FEEDBACK,
    navType: NavType.APPLY_PSYCRUIT,
    footerType: FooterType.LOGGED_IN,
  },
  resources: {
    url: AppPath.RESOURCES,
    navType: NavType.APPLY_PSYCRUIT,
    footerType: FooterType.LOGGED_IN,
  },
  sharableReport: {
    url: AppPath.SHARABLE_REPORT,
    navType: NavType.NONE,
    footerType: FooterType.NONE,
  },
  resetPassword: {
    url: AppPath.RESET_PASSWORD,
    navType: NavType.LOGIN,
    footerType: FooterType.COPYRIGHT_ONLY,
  },
  shareTheJoy: {
    url: AppPath.SHARE_THE_JOY,
    navType: NavType.APPLY_PSYCRUIT,
    footerType: FooterType.LOGGED_IN,
  },
  liveApplications: {
    url: AppPath.JOB_APPLICATIONS_LIVE,
    navType: NavType.APPLY_PSYCRUIT,
    footerType: FooterType.LOGGED_IN,
  },
  myAssessments: {
    url: AppPath.MY_ASSESSMENTS,
    navType: NavType.APPLY_PSYCRUIT,
    footerType: FooterType.LOGGED_IN,
  },
  historicApplications: {
    url: AppPath.JOB_APPLICATIONS_HISTORIC,
    navType: NavType.APPLY_PSYCRUIT,
    footerType: FooterType.LOGGED_IN,
  },
  viewApplication: {
    url: AppPath.VIEW_APPLICATION,
    navType: NavType.APPLY_PSYCRUIT,
    footerType: FooterType.LOGGED_IN,
  },
  jobApplication: {
    url: AppPath.JOB_APPLICATION,
    navType: NavType.APPLY_PSYCRUIT,
    footerType: FooterType.LOGGED_IN,
  },
  virtualTeamDiscussion: {
    url: AppPath.VIRTUAL_TEAM_DISCUSSION,
    navType: NavType.NONE,
    footerType: FooterType.NONE,
  },
  recordedInterview: {
    url: AppPath.RECORDED_INTERVIEW,
    navType: NavType.ACTIVITY,
    footerType: FooterType.NONE,
  },
  presentationActivity: {
    url: AppPath.PRESENTATION_ACTIVITY,
    navType: NavType.ACTIVITY,
    footerType: FooterType.NONE,
  },
  businessCaseActivity: {
    url: AppPath.BUSINESS_CASE_ACTIVITY,
    navType: NavType.ACTIVITY,
    footerType: FooterType.NONE,
  },
  virtualTeamDiscussionActivity: {
    url: AppPath.VIRTUAL_TEAM_DISCUSSION_ACTIVITY,
    navType: NavType.ACTIVITY,
    footerType: FooterType.NONE,
  },
  candidateAutoLogin: {
    url: AppPath.CANDIDATE_AUTO_LOGIN,
    navType: NavType.NONE,
    footerType: FooterType.NONE,
  },
  assessorAutoLogin: {
    url: AppPath.ASSESSOR_AUTO_LOGIN,
    navType: NavType.NONE,
    footerType: FooterType.NONE,
  },
  linkedinAutoLogin: {
    url: AppPath.LINKEDIN_AUTO_LOGIN,
    navType: NavType.NONE,
    footerType: FooterType.NONE,
  },
  redirectLogin: {
    url: AppPath.REDIRECT_LOGIN,
    navType: NavType.NONE,
    footerType: FooterType.NONE,
  },
  dailyJoyTracker: {
    url: AppPath.DAILY_JOY_TRACKER,
    navType: NavType.APPLY_PSYCRUIT,
    footerType: FooterType.LOGGED_IN,
  },
  howJoyful: {
    url: AppPath.HOW_JOYFUL,
    navType: NavType.APPLY_PSYCRUIT,
    footerType: FooterType.LOGGED_IN,
  },
  quizResults: {
    url: AppPath.QUIZ_RESULTS,
    navType: NavType.APPLY_PSYCRUIT,
    footerType: FooterType.LOGGED_IN,
  },
  widgetRedirect: {
    url: AppPath.WIDGET_REDIRECT,
    navType: NavType.NONE,
    footerType: FooterType.NONE,
  },
  bookings: {
    url: AppPath.BOOKINGS,
    navType: NavType.APPLY_PSYCRUIT,
    footerType: FooterType.LOGGED_IN,
  },
  softSkillsReflectionDiagnostic: {
    url: AppPath.SOFT_SKILLS_REFLECTION_DIAGNOSTIC,
    navType: NavType.APPLY_PSYCRUIT,
    footerType: FooterType.LOGGED_IN,
  },
  yourSoftSkillsStrengthsReflections: {
    url: AppPath.YOUR_SOFT_SKILLS_STRENGTHS_REFLECTIONS,
    navType: NavType.APPLY_PSYCRUIT,
    footerType: FooterType.LOGGED_IN,
  },
  liveInterview: {
    url: AppPath.LIVE_INTERVIEW,
    navType: NavType.ACTIVITY,
    footerType: FooterType.NONE,
  },
  jobInterview: {
    url: AppPath.JOB_INTERVIEW,
    navType: NavType.ACTIVITY,
    footerType: FooterType.NONE,
  },
  virtualMeeting: {
    url: AppPath.VIRTUAL_MEETING,
    navType: NavType.ACTIVITY,
    footerType: FooterType.NONE,
  },
  virtualAssessment: {
    url: AppPath.VIRTUAL_ASSESSMENT,
    navType: NavType.VIRTUAL_ASSESSMENT,
    footerType: FooterType.NONE,
  },
  assessmentsOverview: {
    url: AppPath.ASSESSMENTS,
    navType: NavType.APPLY_PSYCRUIT,
    footerType: FooterType.LOGGED_IN,
  },
  assessmentsPractice: {
    url: AppPath.ASSESSMENTS_PRACTICE,
    navType: NavType.APPLY_PSYCRUIT,
    footerType: FooterType.LOGGED_IN,
  },
  assessmentsPracticeRedirect: {
    url: AppPath.ASSESSMENTS_PRACTICE_REDIRECT,
    navType: NavType.APPLY_PSYCRUIT,
    footerType: FooterType.LOGGED_IN,
  },
  assessmentsPracticeTest: {
    url: AppPath.ASSESSMENTS_PRACTICE_TEST,
    navType: NavType.ASSESSMENT_PRACTICE,
    footerType: FooterType.NONE,
  },
  assessmentsBrowserChecker: {
    url: AppPath.ASSESSMENTS_BROWSER_CHECKER,
    navType: NavType.APPLY_PSYCRUIT,
    footerType: FooterType.LOGGED_IN,
  },
  jobApplicationsOverview: {
    url: AppPath.JOB_APPLICATIONS,
    navType: NavType.APPLY_PSYCRUIT,
    footerType: FooterType.LOGGED_IN,
  },
  matchingProfile: {
    url: AppPath.MATCHING_PROFILE,
    navType: NavType.APPLY_PSYCRUIT,
    footerType: FooterType.LOGGED_IN,
  },
  matchingProfileSection: {
    url: AppPath.MATCHING_PROFILE_SECTION,
    navType: NavType.APPLY_PSYCRUIT,
    footerType: FooterType.LOGGED_IN,
  },
  matchingProfileOverview: {
    url: AppPath.MATCHING_PROFILE_OVERVIEW,
    navType: NavType.APPLY_PSYCRUIT,
    footerType: FooterType.LOGGED_IN,
  },
  compareSoftSkills: {
    url: AppPath.COMPARE_SOFT_SKILLS,
    navType: NavType.APPLY_PSYCRUIT,
    footerType: FooterType.LOGGED_IN,
  },
  signOut: {
    url: AppPath.SIGN_OUT_INTEGRATION_CLIENT,
    navType: NavType.NONE,
    footerType: FooterType.NONE,
  },
};
