import * as Yup from 'yup';

import { FieldArray, Form, Formik } from 'formik';
import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import ProcessStepTextAndImageRow from '../ProcessStepTextAndImageRow';
import RowIcon from 'assets/apply.clevry/MP_Skills_Where_01.png';
import { MatchingProcessDeleteIcon } from '../../MatchingProcessDeleteIcon';
import { Spinner } from 'components/spinner/Spinner';
import {
  getWhereToWorkDetails,
  parseWhereToWorkOptions,
  parseWhereToWorkPayload,
  submitWhereToWorkPayload,
} from '../../MatcingProcessApi';

import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';
import {
  MatchingProcessState,
  selectMatchingProcess,
  setMatchingProcessDataSubmittedToggle,
  setMatchingProcessSubmitSuccess,
} from 'reducers/matchingProcess';
import { useDispatch, useSelector } from 'react-redux';
import LocationSelector from 'components/LocationSelector/LocationSelector';
import { GAMatchingProcessLatest } from 'helpers/gaEvents';

type WhereToWorkPageProps = {
  outerSubmitForm: MutableRefObject<
    (() => Promise<void>) & (() => Promise<any>)
  >;
  setNextStepDisabled: Dispatch<SetStateAction<boolean>>;
  setGeneralError: Dispatch<SetStateAction<string | undefined>>;
};

type formikValues = {
  canWorkRemotely: boolean;
  locations: locationOptionProps[] | null;
};

type locationOptionProps = {
  label: string;
  data: {
    longitude: string;
    latitude: string;
  };
};

const WhereToWorkPage: React.FC<WhereToWorkPageProps> = ({
  outerSubmitForm,
  setNextStepDisabled,
  setGeneralError,
}) => {
  const latestLocation = useRef('');

  if (
    window &&
    window.location &&
    window.location.href !== latestLocation.current
  ) {
    latestLocation.current = window.location.href;
    GAMatchingProcessLatest(window.location.href);
  }
  const { t } = useTranslation('Matching+Process', { i18n });

  const [loading, setLoading] = useState<boolean>(true);
  const [initialValues, setInitialValues] = useState<formikValues>({
    canWorkRemotely: false,
    locations: null,
  });

  const validationSchema = Yup.object().shape({
    locationsToStudy: Yup.array()
      .of(
        Yup.object({
          locationName: Yup.string().required(''),
          locationID: Yup.number().required(),
        })
      )
      .min(1),
  });

  const dispatch = useDispatch();

  const matchingProcessDetails: MatchingProcessState = useSelector(
    selectMatchingProcess
  );

  const handleSubmit = (values: typeof initialValues) => {
    const payload = parseWhereToWorkPayload(values);

    return (async () => {
      const result = await submitWhereToWorkPayload(payload);
      if (result.response) {
        setGeneralError('');

        dispatch(setMatchingProcessSubmitSuccess(true));

        dispatch(
          setMatchingProcessDataSubmittedToggle(
            !matchingProcessDetails.dataSubmittedToggle
          )
        );

        return true;
      } else {
        dispatch(setMatchingProcessSubmitSuccess(false));

        setGeneralError(t('SSCandidate_Matching_Process_General_Error'));
        return false;
      }
    })();
  };

  const fetchSelectedInfo = async () => {
    const result = await getWhereToWorkDetails();

    if (result.response && !result.errors) {
      const parsedResult = parseWhereToWorkOptions(result.response.details);
      setInitialValues(parsedResult);

      setLoading(false);
    } else {
      setLoading(false);
      setGeneralError(t('SSCandidate_Matching_Process_General_Error_Reload'));
    }
  };

  useEffect(() => {
    fetchSelectedInfo();
  }, []);

  const [location, setLocation] = useState<string>('');

  const updateLocation = (
    location: string,
    longitude: any,
    latitude: any,
    push: (obj: any) => void
  ) => {
    setLocation(location);

    if (location && longitude && latitude) {
      push({
        label: location,

        data: {
          longitude,
          latitude,
        },
      });
    }
  };

  if (loading) return <Spinner />;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
      validateOnMount
    >
      {({
        setFieldValue,
        values,
        errors,
        touched,
        setValues,
        submitForm,
        isValid,
      }) => {
        outerSubmitForm.current = submitForm;
        if (isValid) {
          setNextStepDisabled(false);
        } else {
          setNextStepDisabled(true);
        }

        return (
          <>
            <Form className="WhereToWorkPage MatchingProcessForm MatchingProcessInnerPage">
              <div className="MatchingProcess__sectionHeader">
                <div className="MatchingProcess__sectionHeading">
                  {t('SSCandidate_Matching_Process_Where_To_Work_Heading')}
                </div>
                <div className="MatchingProcess__sectionSubheading">
                  {t('SSCandidate_Matching_Process_Where_To_Work_SubHeading1')}
                </div>
              </div>
              <ProcessStepTextAndImageRow icon={RowIcon}>
                <div
                  className={`CheckboxFieldContainer ${
                    values.canWorkRemotely ? 'Active' : ''
                  }`}
                >
                  <label className="Label">
                    <input
                      type="checkbox"
                      className="CheckboxInput"
                      name={'canWorkRemotely'}
                      checked={values.canWorkRemotely}
                      onChange={() => {
                        setFieldValue(
                          'canWorkRemotely',
                          !values.canWorkRemotely
                        );
                      }}
                    />
                    <span className="Checkmark"></span>
                    {t('SSCandidate_Matching_Process_Where_To_Work_Checkbox')}
                  </label>
                </div>

                <FieldArray
                  name="locations"
                  render={({ push, remove }) => {
                    return (
                      <React.Fragment>
                        <div className="FormSearchDropdown">
                          <LocationSelector
                            locationChoice={location}
                            updateLocation={updateLocation}
                            readOnly={false}
                            push={push}
                          />
                        </div>

                        {(() => {
                          return true;
                        })() &&
                          values.locations &&
                          Array.isArray(values.locations) &&
                          values.locations.map((item, index) => (
                            <div
                              className="LocationInfoBlock"
                              key={'Selected_Loation_' + index}
                            >
                              <div className="Inner">
                                <div className="Location">{item.label}</div>
                                <div className="RemoveLocation">
                                  <MatchingProcessDeleteIcon
                                    onClick={() => {
                                      remove(index);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          ))}
                      </React.Fragment>
                    );
                  }}
                />
              </ProcessStepTextAndImageRow>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

export default WhereToWorkPage;
