import React, { useState } from 'react';
import { TableInfo } from 'api';
import { ColumnDetail } from '@avamae/table';
import { Formik, Form, useField, FormikProps } from 'formik';
import { Dropdown } from 'components/dropdown/Dropdown';
import { format } from 'date-fns';
import DatePicker from 'react-datepicker';
import * as Yup from 'yup';
import { PanelButtons } from '../panelHelpers/PanelButtons';
import {
  FilterBuilderInner,
  FilterBuilderInnerProps,
} from './FilterBuilderInner';
import { SelectOption } from 'components/dropdown/model';
// import { ClearFieldButton } from './ClearFieldButton';
import FilterBuilderMulti from './FilterBuilderMulti';
import {
  clearAllFilters,
  getFilter,
  setFilter,
  setSingleFilter,
} from 'reducers/setFilter';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';
import { closeRightPanel } from 'reducers/rightPanel';

export type Operator =
  | 'EQ'
  | 'NEQ'
  | 'SW'
  | 'EW'
  | 'CONTAINS'
  | 'GT'
  | 'GEQ'
  | 'LT'
  | 'LEQ'
  | 'INSTRINGARRAY'
  | 'BETWEEN';

export type OperatorOptions = { label: string; key: Operator };

export type Filter =
  | {
      columnKey: string;
      operator: Omit<Operator, 'INSTRINGARRAY'>;
      value: string;
    }
  | {
      columnKey: string;
      operator: 'INSTRINGARRAY';
      value: string[];
    };
export type Filters = {
  columnKey: string;
  filters: Filter[];
}[];

export type FilterMetadata = { details: any | null; filterType: string };

type FilterBuilderProps = {
  table: TableInfo;
  onDone(payload: any): void;
  hiddenFields?: string[];
  noCancel?: boolean;
  defaultOperators?: { [key: string]: { value: string; label: string } };
  externalFormik?: boolean;
  additionalData?: { [key: string]: string };
};

export const FilterBuilder: React.FC<FilterBuilderProps> = ({
  table,
  onDone,
  hiddenFields,
  defaultOperators,
  externalFormik,
  noCancel,
  additionalData,
}) => {
  const [resetFlag, setResetFlag] = useState(false);
  const columns: ColumnDetail[] =
    table && table.data ? table.data.details.columns : [];
  const [keyVal, setKeyVal] = React.useState(0);
  const dispatch = useDispatch();

  const { t } = useTranslation('Filter+Builder', { i18n });

  function incrementKeys() {
    setKeyVal((x) => x + 1);
  }

  const hidden: string[] = hiddenFields
    ? hiddenFields.map((c) => c.toLowerCase())
    : [];

  const [metadata, initialValues] = React.useMemo(() => {
    let meta: Record<string, FilterMetadata> = {};
    let values: Record<string, { columnKey: string; filters: Filter[] }> = {};
    table.data?.details.columns.forEach((entry) => {
      if (entry.bFilterable) meta[entry.columnKey] = entry.filterMetadata;
      if (entry.bFilterable)
        values[entry.columnKey] = table.data?.details.filters.find((f) => {
          return (
            f.columnKey.toLowerCase() === (entry.columnKey as any).toLowerCase()
          );
        }) ?? { columnKey: entry.columnKey, filters: [] };
    });
    return [meta, values];
  }, [table]);

  function handleSubmit(
    values: Record<string, { columnKey: string; filters: Filter[] }>
  ) {
    const filters: Filter[] = Object.values(values).flatMap(
      (filt) => filt.filters
    );
    // table.data?.actions.setFilters(filters);
    onDone(filters);
  }

  const clearFilters = (formik: FormikProps<any>) => {
    if (
      additionalData &&
      Object.values(additionalData).some((val) => val !== '')
    ) {
      const { locationFilter, search } = additionalData;
      table.data?.actions.setQueryParams({
        ...(search ? { search } : {}),
        ...(locationFilter
          ? { filters: `Project__Location CONTAINS ${locationFilter}` }
          : {}),
      });
      if (locationFilter) {
        dispatch(
          setSingleFilter([
            {
              columnKey: 'Project__Location',
              operator: 'CONTAINS',
              value: locationFilter,
            },
          ])
        );
      } else if (search) {
        table.data?.actions.setFilters([]);
        dispatch(clearAllFilters());
      }
    } else {
      table.data?.actions.setFilters([]);
      dispatch(clearAllFilters());
    }
    formik.resetForm(initialValues);
    // setResetFlag(!resetFlag);
    incrementKeys();
  };

  const filterBuilderInnerProps: FilterBuilderInnerProps = {
    columns,
    hidden,
    keyVal,
    metadata,
    resetFlag,
    defaultOperators,
  };

  if (columns) {
    if (externalFormik) {
      return <FilterBuilderInner {...filterBuilderInnerProps} />;
    }

    const handleClearFilters = (formik: any) => {
      if (
        table.data?.details?.filters &&
        table.data?.details?.filters.length > 0
      ) {
        return () => {
          clearFilters(formik);
          dispatch(closeRightPanel());
        };
      }

      return undefined;
    };

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {(formik) => (
          <Form>
            <div className="Content">
              <div className="Form">
                <FilterBuilderInner {...filterBuilderInnerProps} />
              </div>
              {columns.length > 0 ? (
                <PanelButtons
                  noCancel={noCancel}
                  close={onDone}
                  submitButtonText={t(
                    'SSCandidate_Filter_Builder_Submit_Button_Text_Filter'
                  )}
                  clearFilters={handleClearFilters(formik)}
                />
              ) : (
                ''
              )}
            </div>
          </Form>
        )}
      </Formik>
    );
  }

  return <div className="Filters"></div>;
};

const numberValidator = Yup.number().integer();
// const numericalOperators = [
//   { value: 'CONTAINS', label: 'Contains' },
//   { value: 'EQ', label: 'Equals' },
//   { value: 'GT', label: 'Greater than' },
//   { value: 'GEQ', label: 'Greater or equal to' },
//   { value: 'LT', label: 'Less than' },
//   { value: 'LEQ', label: 'Less than or equal to' },
// ];

export const AmountField: React.FC<{
  name: string;
  label: string;
  defaultOperator?: { value: string; label: string } | undefined;
}> = ({ name, label, defaultOperator }) => {
  const { t } = useTranslation('Filter+Builder', { i18n });

  const [field, meta, helpers] = useField(name);

  const numericalOperators = [
    {
      value: 'CONTAINS',
      label: t('SSCandidate_Filter_Builder_Contains_Label'),
    },
    { value: 'EQ', label: t('SSCandidate_Filter_Builder_EQ_Label') },
    { value: 'GT', label: t('SSCandidate_Filter_Builder_GT_Label') },
    { value: 'GEQ', label: t('SSCandidate_Filter_Builder_GEQ_Label') },
    { value: 'LT', label: t('SSCandidate_Filter_Builder_LT_Label') },
    { value: 'LEQ', label: t('SSCandidate_Filter_Builder_LEQ_Label') },
  ];

  const [operator, setOperator] = useState(
    defaultOperator ?? numericalOperators[0]
  );
  const [isFocused, setIsFocused] = useState(false);

  const { filters } = field?.value ?? { filters: [] };

  const storedFilters = useSelector(getFilter);

  React.useEffect(() => {
    const filters: Filter[] = storedFilters.length > 0 ? storedFilters : [];

    if (field.value) {
      const filterValue = field.value?.filters[0]?.value ?? '';
      if (filterValue) {
        filters.push({
          columnKey: field.value.columnKey,
          operator: operator.value,
          value: filterValue,
        });
      }
      helpers.setValue({
        columnKey: field.value.columnKey,
        filters,
      });
    }
  }, [operator]);

  const value = filters[0]?.value ?? '';

  function handleChange(e: React.ChangeEvent<any>) {
    e.preventDefault();
    const newValue = e.target.value;
    if (e.target.value !== '' && !numberValidator.isValidSync(e.target.value))
      return;
    const filters: Filter[] = [];

    if (newValue.length > 0) {
      filters.push({
        columnKey: field.value.columnKey,
        operator: operator.value,
        value: newValue,
      });
    }
    helpers.setValue({
      columnKey: field.value.columnKey,
      filters,
    });
  }

  const clearField = () => {
    helpers.setValue({
      columnKey: field.value.columnKey,
      filters: [],
    });
  };

  const initialOperator =
    !meta.touched && field.value?.filters[0]?.operator
      ? numericalOperators.find(
          (o) => o.value === field.value?.filters[0]?.operator
        )
      : null;

  const handleOperatorChange = (e: SelectOption) => {
    setOperator(e);
    if (!meta.touched) {
      helpers.setTouched(true);
    }
  };

  const onFocus = () => setIsFocused(true);
  const onBlur = () => setIsFocused(false);

  return (
    <div className="FormBox FormSideBySide">
      <div className={`FormLabel`}>
        <label className={`${isFocused ? 'Focused' : ''}`}>{label}</label>
      </div>
      {!defaultOperator && (
        <div className={`FormDropdownField`}>
          <div className={'FormField'}>
            <Dropdown
              key={`${name}Operator`}
              value={initialOperator ?? operator}
              options={numericalOperators}
              onChange={handleOperatorChange}
              roundedRectangle
            />
          </div>
        </div>
      )}
      <div className="FormField">
        <input
          key={`${name}Value`}
          className="EditBox"
          {...field}
          value={value}
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={handleChange}
        />
        {/* {value && <ClearFieldButton onClick={clearField} />} */}
      </div>
    </div>
  );
};

const textOperators = [
  { value: 'CONTAINS', label: 'Contains' },
  { value: 'EQ', label: 'Equals' },
];

export const TextContainsField: React.FC<{
  name: string;
  label: string;
  defaultOperator?: { value: string; label: string } | undefined;
}> = ({ name, label, defaultOperator }) => {
  const [operator, setOperator] = useState(defaultOperator ?? textOperators[0]);

  const { t } = useTranslation('Filter+Builder', { i18n });

  const [field, meta, helpers] = useField(name);
  const storedFilters = useSelector(getFilter);

  const numericalOperators = [
    {
      value: 'CONTAINS',
      label: t('SSCandidate_Filter_Builder_Contains_Label'),
    },
    { value: 'EQ', label: t('SSCandidate_Filter_Builder_EQ_Label') },
    { value: 'GT', label: t('SSCandidate_Filter_Builder_GT_Label') },
    { value: 'GEQ', label: t('SSCandidate_Filter_Builder_GEQ_Label') },
    { value: 'LT', label: t('SSCandidate_Filter_Builder_LT_Label') },
    { value: 'LEQ', label: t('SSCandidate_Filter_Builder_LEQ_Label') },
  ];
  const [isFocused, setIsFocused] = useState(false);

  const onFocus = () => setIsFocused(true);
  const onBlur = () => setIsFocused(false);

  React.useEffect(() => {
    const filters: Filter[] = storedFilters.length > 0 ? storedFilters : [];

    if (field.value) {
      const filterValue = field.value?.filters[0]?.value ?? '';
      if (filterValue) {
        filters.push({
          columnKey: field.value.columnKey,
          operator: operator.value,
          value: filterValue,
        });
      }
    }
    if (field.value) {
      helpers.setValue({
        columnKey: field.value.columnKey,
        filters,
      });
    }
  }, [operator]);

  const { filters } = field?.value ?? { filters: [] };
  const value = filters[0]?.value ?? '';

  function handleChange(e: React.ChangeEvent<any>) {
    const newValue = e.target.value;
    const filters: Filter[] = [];

    if (newValue.length > 0) {
      filters.push({
        columnKey: field.value.columnKey,
        operator: operator.value,
        value: newValue,
      });
    }
    helpers.setValue({
      columnKey: field.value.columnKey,
      filters,
    });
  }

  const clearField = () => {
    helpers.setValue({
      columnKey: field.value.columnKey,
      filters: [],
    });
  };

  const initialOperator =
    !meta.touched && field.value?.filters[0]?.operator
      ? numericalOperators.find(
          (o) => o.value === field.value?.filters[0]?.operator
        )
      : null;

  const handleOperatorChange = (e: SelectOption) => {
    setOperator(e);
    if (!meta.touched) {
      helpers.setTouched(true);
    }
  };

  return (
    <div className="FormBox FormSideBySide">
      <div className={`FormLabel`}>
        <label className={`${isFocused ? 'Focused' : ''}`}>{label}</label>
      </div>
      {!defaultOperator && (
        <div className={`FormDropdownField`}>
          <div className={'FormField'}>
            <Dropdown
              key={`${name}Operator`}
              value={initialOperator ?? operator}
              options={textOperators}
              onChange={handleOperatorChange}
              roundedRectangle
            />
          </div>
        </div>
      )}
      <div className="FormField">
        <input
          className="EditBox"
          {...field}
          value={value}
          onChange={handleChange}
          onFocus={onFocus}
          onBlur={onBlur}
        />
        {/* {value && <ClearFieldButton onClick={clearField} />} */}
      </div>
    </div>
  );
};

export function SingleChoiceField(props: {
  name: string;
  label: string;
  keyVal: number;
  possibleValues: { label: string; value: React.ReactText }[];
}) {
  const [field, , helpers] = useField(props.name);

  const { filters } = field?.value ?? { filters: [] };
  const value = filters[0]?.value;

  function handleChange(selectedOption: SelectOption) {
    const filters: Filter[] = [];

    if (selectedOption.value != null)
      filters.push({
        columnKey: field.value.columnKey,
        operator: 'EQ',
        value: String(selectedOption.value),
      });
    helpers.setValue({
      columnKey: field.value.columnKey,
      filters,
    });
  }

  const clearField = () => {
    helpers.setValue({
      columnKey: field.value.columnKey,
      filters: [],
    });
  };

  const selectedValue =
    props.possibleValues.find((v) => v.value === value) ?? null;

  return (
    <div className="FormBox FormSideBySide">
      <div className="FormLabel">
        <label>{props.label}</label>
      </div>
      <div className="FormField">
        <Dropdown
          key={`${props.name}${props.keyVal}`}
          value={selectedValue}
          options={props.possibleValues}
          onChange={handleChange}
          roundedRectangle
        />
        {/* {selectedValue && <ClearFieldButton onClick={clearField} />} */}
      </div>
    </div>
  );
}

export function MultiChoiceField(props: {
  name: string;
  label: string;
  keyVal: number;
  resetFlag?: boolean;
  possibleValues: { label: string; value: React.ReactText }[];
}) {
  const [isFocused, setIsFocused] = useState(false);
  const [field, , helpers] = useField(props.name);
  const storedFilters = useSelector(getFilter);
  const storedFilterSelection = Object.values(storedFilters).filter(
    (storedFilter) => storedFilter.columnKey === props.name
  );
  let selectedValues;
  if (storedFilterSelection.length > 0) {
    selectedValues = storedFilterSelection[0].value;
  }

  const { filters } = field?.value ?? { filters: [] };
  const value = filters[0]?.value;

  function handleChange(selectedOptions: string[]) {
    selectedOptions = selectedOptions ?? [];
    const filters = [
      {
        columnKey: field.value.columnKey,
        operator: 'INSTRINGARRAY',
        value: selectedOptions,
      },
    ];

    helpers.setValue({
      columnKey: field.value.columnKey,
      filters,
    });
  }

  const onBlur = () => setIsFocused(false);
  const onFocus = () => setIsFocused(true);

  return (
    <React.Fragment>
      <div className="FormLabel">
        <label className={`${isFocused ? 'Focused' : ''}`}>{props.label}</label>
      </div>
      <FilterBuilderMulti
        selectedValues={selectedValues}
        resetFlag={props.resetFlag}
        onChange={handleChange}
        possibleValues={props.possibleValues}
        onBlur={onBlur}
        onFocus={onFocus}
      />
    </React.Fragment>
  );
}

export function DateTimeRangeField(props: {
  name: string;
  label: string;
  range?: boolean;
  placeholderText1: string;
  placeholderText2: string;
}) {
  const [field, , helpers] = useField(props.name);

  const { filters } = field?.value ?? { filters: [] };
  const value = filters[0]?.value ?? '';
  let values = value.split('TO');
  if (values.length !== 2) values = ['NULL', 'NULL'];

  function handleChangeFirst(date: Date | null) {
    const filters: Filter[] = [];

    const formattedDate = date ? format(date, 'yyyy-MM-dd') : 'NULL';
    filters.push({
      columnKey: field.value.columnKey,
      operator: 'BETWEEN',
      value: `${formattedDate}TO${values[1]}`,
    });
    helpers.setValue({
      columnKey: field.value.columnKey,
      filters,
    });
  }

  function handleChangeSecond(date: Date | null) {
    const filters: Filter[] = [];

    const formattedDate = date ? format(date, 'yyyy-MM-dd') : 'NULL';
    filters.push({
      columnKey: field.value.columnKey,
      operator: 'BETWEEN',
      value: `${values[0]}TO${formattedDate}`,
    });
    helpers.setValue({
      columnKey: field.value.columnKey,
      filters,
    });
  }

  const clearField = (target: 'startDate' | 'endDate') => () => {
    const filters = [
      {
        columnKey: field.value.columnKey,
        operator: 'BETWEEN',
        value:
          target === 'startDate'
            ? `NULLTO${values ? values[1] : 'NULL'}`
            : `${values ? values[0] : 'NULL'}TONULL`,
      },
    ];
    helpers.setValue({
      columnKey: field.value.columnKey,
      filters,
    });
  };
  const startDate = values[0] === 'NULL' ? null : new Date(values[0]);
  const endDate = values[1] === 'NULL' ? null : new Date(values[1]);

  return (
    <div className="FormBox FormSideBySide">
      <div className="FormLabel">
        <label>{props.label}</label>
      </div>
      <div className="FormField">
        <div className="DateFields Column">
          <div style={{ position: 'relative' }}>
            <DatePicker
              className="EditBox"
              placeholderText={props.placeholderText1}
              selected={startDate}
              onChange={handleChangeFirst}
              startDate={startDate}
              endDate={endDate}
            />
            {/* {startDate && (
              // <ClearFieldButton onClick={clearField('startDate')} />
            )} */}
          </div>
          <div style={{ position: 'relative' }}>
            <DatePicker
              className="EditBox"
              placeholderText={props.placeholderText2}
              selected={endDate}
              onChange={handleChangeSecond}
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
            />
            {/* {endDate && <ClearFieldButton onClick={clearField('endDate')} />} */}
          </div>
        </div>
      </div>
    </div>
  );
}
