import instance from "api";
import { AxiosRequestConfig } from "axios";
import { endpoints } from "config";


// Get PQ questions
export const getATDetails = async (AssessmentProngID: number) => {
  const config: AxiosRequestConfig = {
    params: {
      AssessmentProngID
    }
  }

  try {
    const res = await instance.get(
      endpoints.abilityTest.getDetails,
      config
    );
    if (res.data.status === '1') {
      return {
        response: res.data,
        errors: null,
      };
    } else {
      return {
        response: null,
        errors: res.data.errors,
      };
    }
  } catch (res) {
    const error: any = res;

    if (error.response && error.response.data && error.response.data.errors) {
      return {
        response: null,
        errors: error.response.data.errors,
      };
    } else {
      return {
        response: null,
        errors: [],
      };
    }
  }
};

// Confirm test started to note start for assessment timer
export const startAT = async (payload: any) => {
  try {
    const res = await instance.post(
      endpoints.abilityTest.startTest,
      payload
    );
    if (res.data.status === '1') {
      return {
        response: res.data,
        errors: null,
      };
    } else {
      return {
        response: null,
        errors: res.data.errors,
      };
    }
  } catch (res) {
    const error: any = res;

    if (error.response && error.response.data && error.response.data.errors) {
      return {
        response: null,
        errors: error.response.data.errors,
      };
    } else {
      return {
        response: null,
        errors: [],
      };
    }
  }
};

// Submit PQ answers on given page
export const submitATAnswers = async (payload: any) => {
  try {
    const res = await instance.post(
      endpoints.abilityTest.setAnswers,
      payload
    );
    if (res.data.status === '1') {
      return {
        response: res.data,
        errors: null,
      };
    } else {
      return {
        response: null,
        errors: res.data.errors,
      };
    }
  } catch (res) {
    const error: any = res;

    if (error.response && error.response.data && error.response.data.errors) {
      return {
        response: null,
        errors: error.response.data.errors,
      };
    } else {
      return {
        response: null,
        errors: [],
      };
    }
  }
};

// Fully submit PQ once all questions are completed and end the assessment
export const completeAT = async (payload: any) => {
  try {
    const res = await instance.post(
      endpoints.abilityTest.completeTest,
      payload
    );
    if (res.data.status === '1') {
      return {
        response: res.data,
        errors: null,
      };
    } else {
      return {
        response: null,
        errors: res.data.errors,
      };
    }
  } catch (res) {
    const error: any = res;

    if (error.response && error.response.data && error.response.data.errors) {
      return {
        response: null,
        errors: error.response.data.errors,
      };
    } else {
      return {
        response: null,
        errors: [],
      };
    }
  }
};

// Get list of reasons for requesting an assessment reset
export const getResetReasons = async () => {
  try {
    const res = await instance.get(
      endpoints.abilityTest.getResetReasons
    );
    if (res.data.status === '1') {
      return {
        response: res.data,
        errors: null,
      };
    } else {
      return {
        response: null,
        errors: res.data.errors,
      };
    }
  } catch (res) {
    const error: any = res;

    if (error.response && error.response.data && error.response.data.errors) {
      return {
        response: null,
        errors: error.response.data.errors,
      };
    } else {
      return {
        response: null,
        errors: [],
      };
    }
  }
};

// Submit a request for an assessment reset
export const requestReset = async (payload: any) => {
  try {
    const res = await instance.post(
      endpoints.abilityTest.requestReset,
      payload
    );
    if (res.data.status === '1') {
      return {
        response: res.data,
        errors: null,
      };
    } else {
      return {
        response: null,
        errors: res.data.errors,
      };
    }
  } catch (res) {
    const error: any = res;

    if (error.response && error.response.data && error.response.data.errors) {
      return {
        response: null,
        errors: error.response.data.errors,
      };
    } else {
      return {
        response: null,
        errors: [],
      };
    }
  }
};

// Get list of reasons for requesting a reasonable adjustment
export const getReasonableAdjustmentReasons = async () => {
  try {
    const res = await instance.get(
      endpoints.abilityTest.getResonableAdjustmentReasons
    );
    if (res.data.status === '1') {
      return {
        response: res.data,
        errors: null,
      };
    } else {
      return {
        response: null,
        errors: res.data.errors,
      };
    }
  } catch (res) {
    const error: any = res;

    if (error.response && error.response.data && error.response.data.errors) {
      return {
        response: null,
        errors: error.response.data.errors,
      };
    } else {
      return {
        response: null,
        errors: [],
      };
    }
  }
};

// Submit a request for a reasonable adjustment
export const requestReasonableAdjustment = async (payload: any) => {
  try {
    const res = await instance.post(
      endpoints.abilityTest.requestResonableAdjustment,
      payload
    );
    if (res.data.status === '1') {
      return {
        response: res.data,
        errors: null,
      };
    } else {
      return {
        response: null,
        errors: res.data.errors,
      };
    }
  } catch (res) {
    const error: any = res;

    if (error.response && error.response.data && error.response.data.errors) {
      return {
        response: null,
        errors: error.response.data.errors,
      };
    } else {
      return {
        response: null,
        errors: [],
      };
    }
  }
};