import instance, { ApiResponse } from 'api';
import { AxiosResponse } from 'axios';
import { BaseApi } from 'baseApi';
import { endpoints } from 'config';
import { IAccountDelete, IChangePassword, IUserAccount } from './models';

export class AccountApi extends BaseApi {
  public static getAccountInfo = async (): Promise<any> => {
    try {
      const result: AxiosResponse<ApiResponse<any>> = await instance.get(
        endpoints.account.viewAccountInfo
      );
      if (AccountApi.isResponseSuccessful(result.data)) {
        const data = result.data.details;
        return AccountApi.successResponse(data);
      }
    } catch (error) {
      if ((error as any).response) {
        const errors = (error as any).response.data.errors;

        if (errors) {
          return AccountApi.errorResponse(errors);
        }
      }
    }
  };
  public static updateAccountInfo = async (
    payload: IUserAccount
  ): Promise<any> => {
    try {
      const result: AxiosResponse<ApiResponse<any>> = await instance.post(
        endpoints.account.updateAccountInfo,
        payload
      );
      if (AccountApi.isResponseSuccessful(result.data)) {
        return AccountApi.successResponse(result.data);
      }
    } catch (error) {
      if ((error as any).response) {
        const errors = (error as any).response.data.errors;

        if (errors) {
          return AccountApi.errorResponse(errors);
        }
      }
    }
  };
  public static updatePassword = async (
    payload: IChangePassword
  ): Promise<any> => {
    try {
      const result: AxiosResponse<ApiResponse<any>> = await instance.post(
        endpoints.account.updatePassword,
        payload
      );
      if (AccountApi.isResponseSuccessful(result.data)) {
        return AccountApi.successResponse(result.data);
      }
    } catch (error) {
      if ((error as any).response) {
        const errors = (error as any).response.data.errors;

        if (errors) {
          return AccountApi.errorResponse(errors);
        }
      }
    }
  };
  public static deleteAccount = async (
    payload: IAccountDelete
  ): Promise<any> => {
    try {
      const result: AxiosResponse<ApiResponse<any>> = await instance.post(
        endpoints.account.deleteAccount,
        payload
      );
      if (AccountApi.isResponseSuccessful(result.data)) {
        return AccountApi.successResponse(result.data);
      }
    } catch (error) {
      if ((error as any).response) {
        const errors = (error as any).response.data.errors;
        if (errors) {
          return AccountApi.errorResponse(errors);
        }
      }
    }
  };
}
